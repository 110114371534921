// eslint-disable-next-line
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";
// import externalTooltipHandler from "./customTooltip";
import "chartjs-adapter-moment";

const graphData = (labels, data, labelDesc) => {
  return {
    labels,
    datasets: [
      {
        label: labelDesc,
        data,
        fill: "start",
        backgroundColor: (context) => {
          const ctx = context.chart.ctx;
          const gradient = ctx.createLinearGradient(0, 0, 0, 600);
          gradient.addColorStop(0, "rgba(42, 77, 194, 0.5)");
          gradient.addColorStop(1, "rgba(42, 77, 194, 0)");
          return gradient;
        },
        borderColor: "#2A4DC2",
      },
    ],
  };
};

const options = (data) => {
  let yMax = Math.max(...data);

  return {
    interaction: {
      mode: "index",
      intersect: false,
    },
    hover: {
      mode: "nearest",
      intersect: true,
    },
    responsive: true,
    plugins: {
      legend: {
        onClick: null,
        labels: {
          usePointStyle: true,
          boxWidth: 12,
        },
        position: "bottom",
      },
      // tooltip: {
      //   enabled: false,
      //   position: 'nearest',
      //   external: (context) =>externalTooltipHandler(context,"Line")
      // }
    },
    scales: {
      yAxis: {
        min: 0,
        max: Math.ceil((yMax + yMax * 0.25) / 5) * 5,
      },
      x: {
        type: "time",
        time: {
          unit: "day",
        },
      },
    },
  };
};

const RenderLineChart = (labels, data, dateObj, labelDesc) => {
  // zero for data range with no values

  let { fromDate, toDate } = dateObj;
  fromDate = fromDate.replace(/^([0-9]{4})([0-9]{2})([0-9]{2})/, "$1-$2-$3");
  toDate = toDate.replace(/^([0-9]{4})([0-9]{2})([0-9]{2})/, "$1-$2-$3");
  labels = labels.map((date) => date.toISOString().slice(0, 10));

  let isFromDateinLabels = labels.includes(fromDate);
  let isToDateinLabels = labels.includes(toDate);

  labels = [
    ...(isFromDateinLabels ? [] : [fromDate]),
    ...labels,
    ...(isToDateinLabels ? [] : [toDate]),
  ];

  data = [
    ...(isFromDateinLabels ? [] : [null]),
    ...data,
    ...(isToDateinLabels ? [] : [null]),
  ];

  return (
    <div style={{ padding: "0px 32px 0px 32px" }}>
      <Line data={graphData(labels, data, labelDesc)} options={options(data)} />
      <hr />
    </div>
  );
};

export default RenderLineChart;
