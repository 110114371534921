import React, { useState } from 'react';
import {
    Backdrop, Box, 
    Modal, Fade, 
    FormControl, RadioGroup,
    Radio, FormControlLabel
} from '@mui/material';
import Select from 'react-select';
import {
    XCircle
} from 'react-feather';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

export default function EscalatorDirectionModal({openModal, onCloseModal, onSuccess}) {

    const [ radioOption, setRadioOption ] = useState('both');

    const options = [
        {
            label: 'Up',
            value: 'up'
        },
        {
            label: 'Down',
            value: 'down'
        }
    ]

    const [ option, setOption ] = useState({
        label: 'Both',
        value: 'both'
    });

    const handleChange = (e) => {
        setRadioOption(e.target.value);
        if(e.target.value === 'single') {
            setOption(options[0])
        } else {
            setOption({
                label: 'Both',
                value: 'both'
            })
        }
    }

    const handleOptionChange = (optn) => {
        setOption(optn)
    }

    const handleSuccess = () => {
        onSuccess(option.value);
    }

    const handleClose = (e, reason) => {
        if(reason && reason === 'backdropClick') return
        onCloseModal()
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal}/>
                        <div className='obModal'>
                            <div className='obModal--dirCard'>
                                <h4 className='obModal--dirCard--title'>Choose the directional type that suits your escalator</h4>
                                <ul className='obModal--dirCard--list'>
                                    <li>For a single escalator that is not located beside its opposing match, select "Single Direction". In this case, you will need one pin at each end of the escalator.</li>
                                    <li>For two escalators located beside each other, select "Dual Direction". With this option, only one pin is needed at each end of the escalator pair.</li>
                                </ul>
                                <FormControl>
                                    <div className='obModal--dirCard--imgCont'>
                                        <RadioGroup row value={radioOption} onChange={handleChange}>
                                            <div className={`obModal--dirCard--card obModal--dirCard--card--single ${radioOption === 'single' && 'obModal--dirCard--card--active'}`}>
                                                <FormControlLabel value="single" control={<Radio color='accentBlue' />} label="Single Direction" className='obModal--dirCard--card--label'/>
                                                <div>
                                                    {radioOption === 'single' 
                                                        ?<div className='obModal--dirCard--card--selectDiv'>
                                                            <Select 
                                                                value={option}
                                                                options={options}
                                                                onChange={handleOptionChange}
                                                            />
                                                        </div>
                                                        :<span className='obModal--dirCard--card--subText'>Up or Down</span>
                                                    }
                                                </div>
                                            </div>
                                            <div className={`obModal--dirCard--card obModal--dirCard--card--both ${radioOption === 'both' && 'obModal--dirCard--card--active'}`}>
                                                <FormControlLabel value="both" control={<Radio color='accentBlue'  />} label="Dual Direction" className='obModal--dirCard--card--label'/>
                                                <span className='obModal--dirCard--card--subText'>Up & Down</span>
                                            </div>
                                        </RadioGroup>
                                        </div>
                                </FormControl>
                                <div className='obModal--dirCard--btnsDiv'>
                                    <button className='obModal--dirCard--btnsDiv--btnOutlined' onClick={onCloseModal}>Cancel</button>
                                    <button className='obModal--dirCard--btnsDiv--btn' onClick={handleSuccess}>Select</button>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}