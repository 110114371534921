import React from "react";
import { 
    Backdrop, Box, 
    Modal, Fade,Button
} from "@mui/material";
import { useDispatch } from "react-redux";
import { XCircle } from "react-feather";
import {
    deleteConnectorGroups,
} from "../../../../../features/webstudio/buildingSlice";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80% !important",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    borderRadius: "16px",
    overflowY: 'auto',
};

const DeleteGroupModal = ({ openModal, onCloseModal, modalData }) => {
    const dispatch = useDispatch();

    const handleClose = (e, reason) => {
        if(reason && reason === 'backdropClick') return;
        onCloseModal();
    }

    const handleDelete = () => {
        dispatch(
            deleteConnectorGroups({groupId: modalData.group.id, buildingId: modalData.buildingId})
        ).then(() => handleClose())
    }

    if(!modalData) return null;

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={handleClose}
                        />
                        <div className='cgModal'>
                            <div className='cgModal--cgCard'>
                                    <h4 className='cgModal--cgCard--title'>Delete "{modalData?.group?.name}" Group</h4>
                                    <div className='cgModal--cgCard--headerDel'> If you delete group "{modalData?.group?.name}" all the pins within this group will be deleted. </div>
                                    <div className='cgModal--cgCard--headerDel'>Do you want to delete this group?</div>
                                    <div className="publishModalBtnsDiv publishModalBtnsDiv--mt20">
                                        <Button variant='outlined' color='primary' onClick={handleClose}>Cancel</Button>
                                        <Button variant='contained' color='errorRed' className='mapdetails__mapModalBtnText' onClick={handleDelete}>Delete</Button>
                                    </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default DeleteGroupModal;
