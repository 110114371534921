import * as THREE from 'three';
import EditorExperience from '../../MinEditorExperience';
import { 
    fromPosObjectToVec3Pos,
    fromScaleObjectToVec3Scale,
    fromQuaternionObjectToQuaternion
} from '../../../threeUtils/TransformConversions';

export default  class LocationPin {
    constructor(destProps, mode) {
        this.destProps = destProps;

        this.editor = new EditorExperience();
        this.navMode = mode;

        this.name = destProps.name
        this.description = destProps.description || ""
        this.id = destProps.id

        this.position = destProps.position ? fromPosObjectToVec3Pos(destProps.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = destProps.rotation ? fromQuaternionObjectToQuaternion(destProps.rotation) : new THREE.Quaternion(0, 0, 0, 0);
        this.scale = destProps.scale ? fromScaleObjectToVec3Scale(destProps.scale) : new THREE.Vector3(1, 1, 1);
        
        this.pathColor = 'pathColor' in destProps ? destProps.pathColor : '#FD7A02';

        this.mesh = {};

        this.setUpDestination();
    }

    setUpDestination = () => {
        this.mesh = this.editor.mapPinObject.clone();
        this.mesh.position.copy(this.position);
        this.mesh.scale.set(0.007, 0.007, 0.007);
        this.mesh.name = this.name;
        this.mesh.userData['id'] = this.id;
        
        this.updateMaterialColor();
    }

    hexToDec = (hex) => parseInt(hex.substring(1), 16)

    updateMaterialColor = () => {
        const newColorMat = new THREE.MeshStandardMaterial().copy(this.mesh.material);
        newColorMat.color.setHex(this.hexToDec(this.pathColor), 'srgb-linear')

        this.mesh.material = newColorMat;
        this.mesh.material.needsUpdate = true;
        this.mesh.children[0].material = newColorMat;
        this.mesh.children[0].material.needsUpdate = true;
    }
}
