import React, { useState } from "react";
import Router from "./router";
import { ThemeProvider } from "@mui/material";
import theme from "./theme";

//Action
import { logout } from "../features/auth/authSlice";
import { clearAlerts } from "../features/common/alertSlice";
import { useDispatch } from "react-redux";

const versionCompare = (v1, v2) => {
  let vnum1 = 0,
    vnum2 = 0;
  for (let i = 0, j = 0; i < v1.length || j < v2.length; ) {
    while (i < v1.length && v1[i] !== ".") {
      vnum1 = vnum1 * 10 + (v1[i] - "0");
      i++;
    }
    while (j < v2.length && v2[j] !== ".") {
      vnum2 = vnum2 * 10 + (v2[j] - "0");
      j++;
    }

    if (vnum1 > vnum2) return 1;
    if (vnum2 > vnum1) return -1;

    vnum1 = vnum2 = 0;
    i++;
    j++;
  }
  return 0;
};

const App = () => {
  const dispatch = useDispatch();
  const [calledConstructor, setCalledConstructor] = useState(false);

  const constructor = (props) => {
    if (calledConstructor) return;
    window.onload = (e) => {
      storeActiveTabEntry();
      if (e.currentTarget.performance.navigation.type === 0) {
        if (localStorage.getItem("venueCreated")) {
          dispatch(clearAlerts());
          localStorage.setItem("activeSession", true);
          localStorage.removeItem("venueCreated");
        } else if (
          localStorage.getItem("activeSession") === "false" &&
          !localStorage.getItem("storeSession")
        ) {
          localStorage.setItem("activeSession", null);
          dispatch(logout());
          storeActiveTabEntry();
        }
      } else if (e.currentTarget.performance.navigation.type === 1) {
        dispatch(clearAlerts());
        localStorage.setItem("activeSession", true);
      }
    };
    window.onunload = (e) => {
      let hash = sessionStorage.getItem("TabHash");
      let tabs = JSON.parse(localStorage.getItem("TabsOpen") || "{}");
      delete tabs[hash];
      localStorage.setItem("TabsOpen", JSON.stringify(tabs));

      if (
        Object.keys(JSON.parse(localStorage.getItem("TabsOpen") || "{}"))
          .length === 0
      ) {
        localStorage.setItem("activeSession", false);
      }
    };

    //To hide all the consoles in prod!
    if (process.env.REACT_APP_NODE_ENV === "production") {
      console.log = () => {};
    }
    console.log(process.env);

    if (process.env.REACT_APP_VERSION) {
      //set/check App versions
      const APP_VERSION = process.env.REACT_APP_VERSION;

      if (
        typeof localStorage.APP_VERSION === "undefined" ||
        localStorage.APP_VERSION === null
      ) {
        localStorage.setItem("APP_VERSION", APP_VERSION);
      } else {
        if (versionCompare(APP_VERSION, localStorage.APP_VERSION) > 0) {
          console.log(
            `version mismatch local - ${localStorage.APP_VERSION} upgraded - ${APP_VERSION} clearing cache and logging out`
          );
          if (caches) {
            // Service worker cache should be cleared with caches.delete()
            caches.keys().then((names) => {
              for (const name of names) {
                caches.delete(name);
              }
            });
          }
          //delete all local & session data of app
          dispatch(logout());
          window.location.replace("/auth/login");
        }
      }
    }
    setCalledConstructor(true);
  };

  constructor();

  const storeActiveTabEntry = () => {
    let hash = "tab_" + +new Date();
    sessionStorage.setItem("TabHash", hash);
    let tabs = JSON.parse(localStorage.getItem("TabsOpen") || "{}");
    tabs[hash] = true;
    localStorage.setItem("TabsOpen", JSON.stringify(tabs));
  };

  return (
    <ThemeProvider theme={theme}>
      <Router />
    </ThemeProvider>
  );
};

export default App;
