import * as THREE from "three";
import EditorExperience from "../EditorExperience";
import EventEmitter from "./EventEmitter";
import { isEqual } from "lodash";
import {
    getTransformed3DPos,
    getTransformed3DPosV1,
} from "../threeUtils/TransformConversions";

class MiniMapNavigator extends EventEmitter {
    constructor(resizer) {
        super();

        this.editor = new EditorExperience();
        this.selectorDom = null;

        // bounds!
        this.bounds = null;
        this.isResizeOn = false;
        this.isDragOn = false;

        this.resizer = resizer;
    }

    setUpSelector = (parent, x, y, width, height) => {
        this.bounds = {
            x,
            y,
            minWidth: parseInt(width) / 2,
            minHeight: parseInt(height) / 2,
            height: parseInt(height),
            width: parseInt(width),
        };

        this.selectorDom = document.createElement("div");
        this.selectorDom.classList.add("wsNavigator--selectorDom");
        this.selectorDom.style.width = `${width}px`;
        this.selectorDom.style.height = `${height}px`;
        this.selectorDom.style.top = `${y}px`;
        this.selectorDom.style.left = `${x}px`;

        // bounds to resize!
        this.selectorDom.style.maxWidth = `${width}px`;
        this.selectorDom.style.maxHeight = `${height}px`;
        this.selectorDom.style.minWidth = `${width / 4}px`;
        this.selectorDom.style.minHeight = `${height / 4}px`;

        parent.appendChild(this.selectorDom);

        this.initDrag();
        this.initResize();
    };

    initDrag = () => {
        let dX, dY, dW, dH;
        let cZIndex = 100;

        const scope = this;

        const stopDragElement = () => {
            /* stop moving when mouse button is released:*/
            this.isDragOn = false;
            document.onmouseup = null;
            document.onmousemove = null;
            this.computeCameraZoom();
            this.computeSizeBox();
        };

        const onDragElement = (e) => {
            if (!scope.selectorDom) {
                return;
            }

            e = e || window.event;
            // calculate the new cursor position:
            dX = dW - e.clientX;
            dY = dH - e.clientY;
            dW = e.clientX;
            dH = e.clientY;
            // set the element's new position:
            const newTop = scope.selectorDom.offsetTop - dY;
            const newLeft = scope.selectorDom.offsetLeft - dX;

            const sW = parseInt(
                    document.defaultView.getComputedStyle(scope.selectorDom)
                        .width,
                    10
                ),
                sH = parseInt(
                    document.defaultView.getComputedStyle(scope.selectorDom)
                        .height,
                    10
                );

            const limitTop = scope.bounds.y + scope.bounds.height;
            if (newTop >= scope.bounds.y && newTop + sH <= limitTop) {
                scope.selectorDom.style.top = newTop + "px";
            }

            const limitLeft = scope.bounds.x + scope.bounds.width;
            if (newLeft >= scope.bounds.x && newLeft + sW <= limitLeft) {
                scope.selectorDom.style.left = newLeft + "px";
            }
        };

        const onDragMouseDown = (e) => {
            scope.selectorDom.style.zIndex = "" + ++cZIndex;

            e = e || window.event;
            // get the mouse cursor position at startup:
            dW = e.clientX;
            dH = e.clientY;
            document.onmouseup = stopDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = onDragElement;
        };

        this.selectorDom.onmousedown = function () {
            if (scope.isResizeOn) return;
            this.isDragOn = true;
            scope.selectorDom.style.zIndex = "" + ++cZIndex;

            onDragMouseDown();
        };
    };

    initResize = () => {
        let sX, sY, sW, sH;

        const onDrag = (e) => {
            const newWidth = sW + e.clientX - sX;
            const newHeight = sH + e.clientY - sY;

            const dL = parseInt(
                    document.defaultView.getComputedStyle(this.selectorDom)
                        .left,
                    10
                ),
                dT = parseInt(
                    document.defaultView.getComputedStyle(this.selectorDom).top,
                    10
                );

            const limitHeight = this.bounds.y + this.bounds.height;
            const limitWidth = this.bounds.x + this.bounds.width;

            if (dL + newWidth <= limitWidth) {
                this.selectorDom.style.width = newWidth + "px";
            }

            if (dT + newHeight <= limitHeight) {
                this.selectorDom.style.height = newHeight + "px";
            }
        };

        const stopDrag = () => {
            this.isResizeOn = false;
            window.removeEventListener("pointermove", onDrag, false);
            window.removeEventListener("pointerup", stopDrag, false);
            this.computeCameraZoom();
            this.computeSizeBox();
        };

        const initDrag = (e) => {
            if (this.isDragOn) return;

            this.isResizeOn = true;
            sX = e.clientX;
            sY = e.clientY;
            sW = parseInt(
                document.defaultView.getComputedStyle(this.selectorDom).width,
                10
            );
            sH = parseInt(
                document.defaultView.getComputedStyle(this.selectorDom).height,
                10
            );
            window.addEventListener("pointermove", onDrag, false);
            window.addEventListener("pointerup", stopDrag, false);
        };

        var right = document.createElement("div");
        right.className = "wsNavigator--resizer-right";
        this.selectorDom.appendChild(right);
        right.addEventListener("pointerdown", initDrag, false);

        var bottom = document.createElement("div");
        bottom.className = "wsNavigator--resizer-bottom";
        this.selectorDom.appendChild(bottom);
        bottom.addEventListener("pointerdown", initDrag, false);

        var both = document.createElement("div");
        both.className = "wsNavigator--resizer-both";
        this.selectorDom.appendChild(both);
        both.addEventListener("pointerdown", initDrag, false);
    };

    resetDOM = () => {
        if (!this.bounds) return;
        this.selectorDom.style.width = `${this.bounds.width}px`;
        this.selectorDom.style.height = `${this.bounds.height}px`;
        this.selectorDom.style.top = `${this.bounds.y}px`;
        this.selectorDom.style.left = `${this.bounds.x}px`;
        this.computeSizeBox();
    };

    computeCameraZoom = () => {
        const dX = parseInt(
                document.defaultView.getComputedStyle(this.selectorDom).left,
                10
            ),
            dY = parseInt(
                document.defaultView.getComputedStyle(this.selectorDom).top,
                10
            ),
            dWidth = parseInt(
                document.defaultView.getComputedStyle(this.selectorDom).width,
                10
            ),
            dHeight = parseInt(
                document.defaultView.getComputedStyle(this.selectorDom).height,
                10
            );

        const { x, y, width, height } = this.bounds;
        if (!isEqual(width, dWidth) || !isEqual(height, dHeight)) {
            const { imgHeight, imgWidth } = this.editor.floorData;

            const vectorOne = {
                posX: 0,
                posY: 0,
            };
            const vectorTwo = {
                posX: 0,
                posY: 0,
            };

            vectorOne.posX = THREE.MathUtils.mapLinear(
                dX,
                x,
                x + width,
                0,
                imgWidth
            );
            vectorOne.posY = THREE.MathUtils.mapLinear(
                dY,
                y,
                y + height,
                0,
                imgHeight
            );

            vectorTwo.posX = THREE.MathUtils.mapLinear(
                dX + dWidth,
                x,
                x + width,
                0,
                imgWidth
            );
            vectorTwo.posY = THREE.MathUtils.mapLinear(
                dY + dHeight,
                y,
                y + height,
                0,
                imgHeight
            );

            const vecStart = this.getTransformedPos(vectorOne);
            const vecEnd = this.getTransformedPos(vectorTwo);

            const viewportBox = new THREE.Box3().setFromPoints([
                vecStart,
                vecEnd,
            ]);
            this.editor.trigger("setCameraAroundBox3", [viewportBox, 0.7]);
        }
    };

    getTransformedPos = (pos) => {
        const { imgWidth, imgHeight } = this.editor.floorData;
        const worldMatrix = this.editor.floorWorldMatrix.clone();
        return this.editor.floorplanVersion !== 2.6
            ? getTransformed3DPos(worldMatrix, imgWidth, imgHeight, pos)
            : getTransformed3DPosV1(worldMatrix, imgWidth, imgHeight, pos);
    };

    computeSizeBox = () => {
        const dWidth = parseInt(
                document.defaultView.getComputedStyle(this.selectorDom).width,
                10
            ),
            dHeight = parseInt(
                document.defaultView.getComputedStyle(this.selectorDom).height,
                10
            );
        const { width, height } = this.bounds;
        const total = width * height;
        const resultant = dWidth * dHeight;

        this.resizer(parseInt((resultant / total) * 100));
    };

    resizeSizeBoxByPercentage = (percent) => {
        this.selectorDom.style.width = `${
            this.bounds.width * (percent / 100)
        }px`;
        this.selectorDom.style.height = `${
            this.bounds.height * (percent / 100)
        }px`;
        this.selectorDom.style.top = `${this.bounds.y}px`;
        this.selectorDom.style.left = `${this.bounds.x}px`;
        this.computeCameraZoom();
    };
}

export { MiniMapNavigator };
