import React from "react";
import warningIcon from "../../../../assets/pngs/studio/importMaps/warning.png";

function Cancel({ continueImportMaps, closeModal }) {
  return (
    <div className="d-flex align-items-center flex-column">
      <img
        alt="delIcon"
        src={warningIcon}
        style={{
          marginRight: "1rem",
          marginLeft: "-1rem",
          marginBottom: "1rem",
        }}
      ></img>
      <h2>Cancel Venue Map Creation</h2>
      <p className="import-maps-subtext pt-4">
        All maps will revert back to their original state and all progress will
        be lost.
      </p>
      <div className="row justify-content-between mt-5">
        <button
          className="col-sm cancel-btn cat-btn"
          onClick={(e) => {
            e.preventDefault();
            continueImportMaps();
          }}
        >
          No, Continue
        </button>
        <button
          className="col-sm del-btn cat-btn"
          style={{ marginLeft: "40px" }}
          onClick={(e) => {
            e.preventDefault();
            closeModal();
          }}
        >
          Yes, Cancel!
        </button>
      </div>
    </div>
  );
}

export default Cancel;
