import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../../api';
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";

const getAccounts = createAsyncThunk('manageaccounts/getAccounts', async ({ pageNumber, pageSize }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/list?limit=${pageSize}&page=${pageNumber}&includePlanDetails=true`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getAccountsForCSV = createAsyncThunk('manageaccounts/getAccountsForCSV', async (pageSize, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/list?limit=${pageSize}&page=1&includePlanDetails=true`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});


const searchAccount = createAsyncThunk('manageaccounts/searchAccount', async ({ searchText, pageSize, pageNumber }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/list?limit=${pageSize}&page=${pageNumber}&q=${encodeURIComponent(searchText)}&includePlanDetails=true`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getAccountUserInfo = createAsyncThunk('manageaccounts/getAccountUserInfo', async (userId, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/user/${userId}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getAccountPlanInfo = createAsyncThunk('manageaccounts/getAccountPlanInfo', async (userEmail, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/analytics/${userEmail}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const updateWatermark = createAsyncThunk('adminanalytics/updateWatermark', async (params, { dispatch }) => {
    try {
        const response = await Server.post('/v1/account/watermarkstatus', params);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const initialState = {
    accounts: [],
    searchedAccounts: [],
    csvAccounts: [],
    accountDetails: null,
    totalPages: null,
    totalResults: null,
    accountsLog: null,
    accountPlanDetails: null,
};

const manageAccountsSlice = createSlice({
    name: 'manageaccounts',
    initialState,
    reducers: {
        clearSearchedAccounts: (state) => {
            return { ...state, searchedAccounts: [] }
        },
        clearLog: (state) => {
            return { ...state, analyticsLog: null }
        },
        resetAccountPlanState: (state) => {
            return { ...state, accountPlanDetails: null }
        },
    },
    extraReducers: {
        'common/resetState': () => {
            return { ...initialState }
        },
        [getAccounts.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                accounts: payload.data,
                totalPages: payload.totalPages,
                totalResults: payload.totalResults,
                searchedAccounts: [],
                accountsLog: null,
                accountDetails: null,
            })
        },
        [getAccountsForCSV.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                csvAccounts: payload.data,
                accountsLog: null
            })
        },
        [searchAccount.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                searchedAccounts: payload.data,
                accountsLog: null
            })
        },
        [getAccountUserInfo.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                accountDetails: payload.data,
                accountsLog: null
            })
        },
        [getAccountPlanInfo.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                accountPlanDetails: payload,
                accountsLog: null
            })
        },
        [updateWatermark.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                accountsLog: payload.status,
            })
        },
    }
});

export {
    getAccounts,
    searchAccount,
    getAccountsForCSV,
    getAccountUserInfo,
    getAccountPlanInfo,
    updateWatermark,
}

export const { clearSearchedAccounts, clearLog, resetAccountPlanState } = manageAccountsSlice.actions;

export default manageAccountsSlice.reducer;