import React, { useState, useEffect } from "react";
import { 
    Backdrop, Box, 
    Modal, Fade,
    TextField, Button
} from "@mui/material";

import Select from "react-select";

import {
    createConnectorGroups,
} from "../../../../../features/webstudio/buildingSlice";
import { useDispatch, useSelector } from "react-redux";
import { XCircle } from "react-feather";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80% !important",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    borderRadius: "16px",
    overflowY: 'auto',
};

const ConnectorModal = ({ openModal, onCloseModal, modalData }) => {
    const dispatch = useDispatch();
    const buildingLog = useSelector(state => state.building.buildingLog);

    const options = [
        {label: 'Elevators', value: 'elevator'},
        {label: 'Escalators', value: 'escalator'},
        {label: 'Stairs', value: 'stairs'},
        {label: 'Bridges', value: 'bridge'},
    ]

    const [ groupName, setGroupName ] = useState('');
    const [ errName, setErrName ] = useState(null);
    const [ save, setSave ] = useState(false);
    const [ activeType, setActiveType ] = useState(options[0]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(groupName && groupName.trim().length) {
            setSave(true);
        } else {
            setErrName('Please enter group name!')
        }
    }

    const handleClose = (e, reason) => {
        if(reason && reason === 'backdropClick') return;
        setGroupName('');
        setErrName(null);
        onCloseModal();
    }

    const handleChange = (e) => {
        setGroupName(e.target.value);
        e.target.value.length ? setErrName(null) : setErrName('Please enter group name!');
    }

    useEffect(() => {
        if(save) {
            const connectObj = {
                name: groupName,
                connectorType: activeType.value
            }
            dispatch(
                createConnectorGroups({connectObj, buildingId: modalData.buildingId})
            ).then(() => {
                handleClose();
                setSave(false)
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ save ])

    useEffect(() => {
        if(buildingLog && buildingLog === 'success') {
            setGroupName('');
            setErrName(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingLog])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={handleClose}
                        />
                        <div className='cgModal'>
                            <div className='cgModal--cgCard'>
                                <form onSubmit={handleSubmit}>
                                    <h4 className='cgModal--cgCard--title'>Create New Connector Group</h4>
                                    <div className='cgModal--cgCard--header'> To maintain consistency and clarity, we recommend naming both the connector group and the pins within the group with the
                                    same name. You may only group one type of connector together.</div>
                                    <div className='cgModal--cgCard--inputCont'>
                                        <div className="cgModal--cgCard--subHeader">Enter Group Details</div>
                                        <span className="cgModal--cgCard--label">Group Name</span>
                                        <TextField 
                                            label="Group Name" 
                                            id="groupName"
                                            value={groupName}
                                            onChange={handleChange}
                                            size="small"
                                            fullWidth
                                            className={`settingshome__acDetailsCard--formControl settingshome__acDetailsCard--formControl--active`}
                                            autoComplete="off"
                                            inputProps={
                                                { maxLength: 50 }
                                            }
                                        />
                                        {errName ? <span className='settingshome__acDetailsCard--errorMsg'>{errName}</span> : null}
                                    </div>
                                    <div className='cgModal--cgCard--inputCont'>
                                        <span className="cgModal--cgCard--label">Type</span>
                                        <Select
                                            styles={{
                                                control: (baseStyles, state) => ({
                                                    ...baseStyles,
                                                    marginTop: '20px',
                                                    marginBottom: '20px',
                                                }),
                                            }}
                                            value={activeType}
                                            options={options}
                                            onChange={(e) => setActiveType(e)}
                                        />
                                    </div>
                                    <div className="publishModalBtnsDiv">
                                        <Button variant='outlined' color='primary' onClick={handleClose}>Cancel</Button>
                                        <Button variant='contained' color='primaryColors' className='mapdetails__mapModalBtnText' type='submit'>Create</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default ConnectorModal;
