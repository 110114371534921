const navList = [
    {
        id: 0,
        title: `Overview`,
        tabLabel: 'Overview',
    },
    {
        id: 1,
        title: 'Visitor Behavior',
        tabLabel: 'Visitor Behavior',
    }
]

export { navList }