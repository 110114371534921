import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../../api';
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";

const getReportedMaps = createAsyncThunk('mapreports/getReportedMaps', async ( { pageNumber, pageSize }, {dispatch}) => {
    try{
        const response = await Server.get(`/v1/map/report?limit=${pageSize}&page=${pageNumber}`);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const changeReportActivtyStatus = createAsyncThunk('mapreports/changeReportActivtyStatus', async (params, { dispatch }) => {
    try{
        const response = await Server.put('/v1/map/report', params);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
}); 

const initialState = {
    reportedMaps:[],
    totalPages: null,
    pageNumber: 1,
    mapReportsLog: null
};

const mapReportsSlice = createSlice({
    name: 'mapreports',
    initialState,
    reducers: {
        changePageNumber: (state, action) =>{
            return({...state, pageNumber: action.payload})
        },
        resetReportedMaps: (state, action) =>{
            return({...state, reportedMaps: []})
        },
        resetPageNumber: (state, action) =>{
            return({...state, pageNumber: 1, reportedMaps: []})
        },
    },
    extraReducers: {
        'common/resetState': () => {
            return{ ...initialState }
        },
        [getReportedMaps.fulfilled]: (state, {payload}) => {
            return({
                ...state,
                reportedMaps: payload.data,
                totalPages: payload.totalPages,
                mapReportsLog: null
            })
        },
        [changeReportActivtyStatus.fulfilled]: (state, { payload }) => {
            return({
                ...state,
                mapReportsLog: payload
            })
        }
    }
});

export const {
    changePageNumber,
    resetReportedMaps,
    resetPageNumber
} = mapReportsSlice.actions;
export {
    getReportedMaps,
    changeReportActivtyStatus
};

export default mapReportsSlice.reducer;