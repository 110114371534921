import * as THREE from "three";
import EditorExperience from "../EditorExperience";
import EventEmitter from "./EventEmitter";

class ZoomSelector extends EventEmitter {
    constructor() {
        super();

        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera.instance;
        this.controls = this.editor.camera.controls;

        this.raycaster = new THREE.Raycaster();

        this.zoomDom = null;

        //zoomBox
        this.zoomBox = {};

        this.editor.on(
            "cameraInteractionModeChanged",
            this.onCameraModeChanged
        );

        this.editor.on("setCameraAroundBox3", this.zoomCameraAroundBox3);
    }

    setUpZoomPointer = () => {
        document.body.classList.add("wsZoomSelection_cursor");
        this.clearDOM();
        window.addEventListener(
            "pointerdown",
            this.createSelectionAreaDiv,
            false
        );
    };

    createSelectionAreaDiv = (e) => {
        e.preventDefault();
        this.clearDOM();

        this.zoomBox.x0 = e.offsetX;
        this.zoomBox.y0 = e.offsetY;

        this.zoomDom = document.createElement("div");
        this.zoomDom.classList.add("wsZoomDOM");
        this.zoomDom.style.left = `${this.zoomBox.x0}px`;
        this.zoomDom.style.top = `${this.zoomBox.y0}px`;
        this.zoomDom.style.width = 0;
        this.zoomDom.style.height = 0;

        document.body.append(this.zoomDom);

        window.addEventListener("pointermove", this.updateZoomSelection, false);
        window.addEventListener("pointerup", this.stopZoomSelection, false);
    };

    updateZoomSelection = (e) => {
        document.body.classList.remove("wsZoomSelection_cursor");
        document.body.classList.add("wsZoom_cursor");

        this.zoomBox.x1 = e.offsetX;
        this.zoomBox.y1 = e.offsetY;

        this.width = e.offsetX - this.zoomBox.x0;
        this.height = e.offsetY - this.zoomBox.y0;
        this.zoomDom.style.left =
            this.width < 0
                ? this.zoomBox.x0 + this.width + "px"
                : this.zoomBox.x0 + "px";
        this.zoomDom.style.top =
            this.height < 0
                ? this.zoomBox.y0 + this.height + "px"
                : this.zoomBox.y0 + "px";
        this.zoomDom.style.width = Math.abs(this.width) + "px";
        this.zoomDom.style.height = Math.abs(this.height) + "px";
    };

    stopZoomSelection = (e) => {
        if (this.zoomDom) {
            this.zoomDom.remove();
            this.zoomDom = null;
            document.body.classList.remove("wsZoomSelection_cursor");
            document.body.classList.remove("wsZoom_cursor");

            window.removeEventListener(
                "pointerdown",
                this.createSelectionAreaDiv,
                false
            );
            window.removeEventListener(
                "pointermove",
                this.updateZoomSelection,
                false
            );
            window.removeEventListener(
                "pointerup",
                this.stopZoomSelection,
                false
            );

            this.editor.trigger("cameraInteractionModeChanged", [null]);
            this.calculateWorldZoomBox();
            this.zoomBox = {};
        }
    };

    calculateWorldZoomBox = () => {
        if (Object.keys(this.zoomBox).length === 4) {
            const viewportStart = this.getProjectedPoint(
                this.zoomBox.x0,
                this.zoomBox.y0
            );
            const viewportEnd = this.getProjectedPoint(
                this.zoomBox.x1,
                this.zoomBox.y1
            );

            const viewportBox = new THREE.Box3().setFromPoints([
                viewportStart,
                viewportEnd,
            ]);
            this.zoomCameraAroundBox3(viewportBox, 0.7);
        }
    };

    getProjectedPoint = (x, y) => {
        let viewport = new THREE.Vector2();
        const rect = this.canvas.getBoundingClientRect();
        viewport.x = ((x - rect.left) / rect.width) * 2 - 1;
        viewport.y = -(((y - rect.top) / rect.height) * 2) + 1;

        this.raycaster.setFromCamera(viewport, this.camera);
        const intersects = this.raycaster.intersectObjects(
            this.scene.children,
            true
        );
        let intersect =
            intersects.length > 0 ? intersects[0].point : new THREE.Vector3();
        return intersect.clone();
    };

    zoomCameraAroundBox3 = (box3, fitOffset = 1) => {
        const bCenter = box3.getCenter(new THREE.Vector3());
        const bSize = box3.getSize(new THREE.Vector3());

        const maxSize = Math.max(bSize.x, bSize.y, bSize.z);
        const fitHeightDistance =
            maxSize / (2 * Math.atan((Math.PI * this.camera.fov) / 360));
        const fitWidthDistance = fitHeightDistance / this.camera.aspect;

        let maxDistance = Math.max(fitHeightDistance, fitWidthDistance);

        // for smaller make the max distance 10 units
        if(maxDistance < 20) {
            maxDistance = 10
        }

        let distance =
            fitOffset * maxDistance;

        distance = distance === 0 ? 1 : distance;

        const direction = this.controls.target
            .clone()
            .sub(this.camera.position)
            .normalize()
            .multiplyScalar(distance);

        this.controls.target.copy(bCenter);

        this.camera.updateProjectionMatrix();

        this.camera.position.copy(this.controls.target).sub(direction);

        this.controls.update();
    };

    onCameraModeChanged = (mode) => {
        if (mode === "zoomSelect") {
            this.setUpZoomPointer();
        } else {
            this.stopZoomSelection();
        }
    };

    clearDOM = () => {
        const elements = document.getElementsByClassName("wsZoomDOM");
        if(elements.length) {
            for(var i = 0; i < elements.length; i++) {
                elements[i].remove();
            }
        }
    }
}

export default ZoomSelector;
