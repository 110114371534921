import React, { useState, useEffect } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Menu,
  MenuItem,
  Avatar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "../logo";

/* import {
    HelpCircle
} from 'react-feather' */
//import action
import { D_SETTINGS, DASHBOARD_HOME } from "../../_utils/routes";
import items from "../../common/sidebar/menu";
import { logout, logoutImpersonate } from "../../../features/auth/authSlice";
import { retainNavState } from "../../../features/common/commonSlice";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    width: 30,
    height: 30,
  },
  avatarText: {
    fontWeight: "bolder",
  },
  contDiv: {
    cursor: "pointer",
  },
  menuItem: {
    width: "100px",
  },
  menuItemText: {
    fontWeight: "400",
    fontSize: "16px",
  },
  toolbar: {
    paddingLeft: "8px !important",
  },
}));

const TopBar = ({ className, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const isImpersonateUser = JSON.parse(sessionStorage.getItem("impersonateUser"));

  let loggedUserName =
    useSelector((state) => state.auth.loggedUserName) || "User";
  const loggedUserType =
    useSelector((state) => state.auth.loggedUserType) || "user";

  useEffect(() => {}, [loggedUserName]);

  const handleNavigation = (link) => {
    const findIndxArr = items[loggedUserType];
    let activeIndx = 0;
    findIndxArr.forEach((ele, index) => {
      if (link === ele.link) activeIndx = index;
    });
    navigate(link);
    dispatch(retainNavState(activeIndx));
  };

  const onLogout = () => {
    if(isImpersonateUser) {
      dispatch(logoutImpersonate);
      window.close()
    } else {
      dispatch(logout());
      navigate("/auth/login", { replace: true });
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <div
          onClick={() => handleNavigation(DASHBOARD_HOME)}
          className={classes.contDiv}
        >
          <Logo 
            style={{ width: "36px", height: "36px", }} 
            title={loggedUserType === 'admin' ? "ARway Admin Portal" : "ARway"}
            />
        </div>
        <Box flexGrow={1} />
        {/* <IconButton color="inherit">
                        <HelpCircle />
                    </IconButton> */}
        <Box sx={{ flexGrow: 0 }}>
          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} id="button-userAvatar">
              <Avatar alt={loggedUserName} src="/static/images/avatar/2.jpg" />
            </IconButton>
          </Tooltip>
          <Menu
            sx={{ mt: "40px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {isImpersonateUser || loggedUserType === 'admin' ? null :<MenuItem
              className={classes.menuItem}
              key={"Profile"}
              onClick={() => handleNavigation(D_SETTINGS)}
              id="button-profile"
            >
              <div className={classes.menuItemText}>Profile</div>
            </MenuItem>}
            <MenuItem
              className={classes.menuItem}
              key={"Logout"}
              onClick={onLogout}
              id="button-logout"
            >
              <div className={classes.menuItemText}>Logout</div>
            </MenuItem>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
