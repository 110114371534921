// Creates waypoinbt destination and a line to draw between desired points!
import * as THREE from 'three';
import EditorExperience from '../EditorExperience';
import EventEmitter from '../utils/EventEmitter';

/* LINE UTILS */
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';

class NavigationHelperLine extends EventEmitter {
    constructor() {
        super();
        this.editor = new EditorExperience();
        this.points = [];
        this.lineGeo = new LineGeometry();
        this.lineMat = new LineMaterial({
            vertexColors: true,
            dashed: false,
            alphaToCoverage: true,
        });

        this.lineMat.onBeforeCompile = shader => {
            shader.vertexShader = `${shader.vertexShader}`.replace(`uniform float linewidth;`, `attribute float linewidth;`);
        }

        this.line = new Line2(this.lineGeo, this.lineMat);
        this.line.scale.set( 1, 1, 1 );
        

        //Attributes
        this.positions = [];    // Strides of 3
        this.colors = [];   // Strides of 3
        this.lineWidth = [];    // Strides of 1

        // line color
        this.color = new THREE.Color('#07D91E')

        this.editor.time.on('tick', this.updateLineRes)
    }

    addPoint = (point) => {
        this.points.push(point.position);
        if(this.points.length === 2) {
            this.line.name = `${point.waypointName} path`
            this.line.userData['id'] = `${point.waypointName}__arpath13`;
        }
        this.updateArrays(1);
        this.generateGeometry();
    }

    spliceLastPoint = () => {
        this.updateArrays(0);
        this.generateGeometry();
    }

    updateArrays = (iFlag) => {
        if(iFlag === 1) {
            //Add
            const size = (this.points.length - 1);
            const sizeIndex = size * 3
            this.positions[sizeIndex] = this.points[size].x
            this.positions[sizeIndex + 1] = this.points[size].y;
            this.positions[sizeIndex + 2] = this.points[size].z;

            this.colors[ sizeIndex ] = this.color.r;
            this.colors[ sizeIndex + 1 ] = this.color.g;
            this.colors[ sizeIndex + 2 ] = this.color.b;
            
            (this.points.length !== 2) && (this.lineWidth[size] = 10);
        } else {
            this.points.pop();
            this.positions.length = this.positions.length - 3;
            this.colors.length = this.colors.length - 3;
            this.lineWidth.length = this.lineWidth.length - 1;
        }
    }

    generateGeometry = () => {
        // console.log(this.lineGeo.attributes, this.points.length);
        this.lineGeo.setPositions(this.positions);
        this.lineGeo.setColors(this.colors);
        this.lineGeo.setAttribute('linewidth', new THREE.InstancedBufferAttribute(new Float32Array(this.lineWidth), 1)); 
        this.line.computeLineDistances();
        // this.line.updateMatrix();
    }

    updateLineRes = () => {
        this.lineMat.resolution.set( this.editor.sizes.width, this.editor.sizes.height );
    }
}

export default NavigationHelperLine;