import { Command } from './Command';

function SetRotation( editor, object, newRotation, optOldRotation, delayAutoSave) {
    Command.call(this, editor);

    this.type = 'SetRotation';
    this.name = 'Set Rotation';
    this.updatable = true;
    this.delayAutoSave = delayAutoSave;

    this.object = object;

    if(object !== undefined && newRotation !== undefined) {
        this.oldRotation = object.rotation.clone();
        this.newRotation = newRotation.clone();
    }

    if(optOldRotation !== undefined) {
        this.oldRotation = optOldRotation;
    }
}

SetRotation.prototype = {
    execute: function() {
        this.object.rotation.copy(this.newRotation);
        this.object.updateMatrixWorld();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave]);
        this.editor.trigger('sceneAreaChanged');
    },

    undo: function() {
        this.object.rotation.copy(this.oldRotation);
        this.object.updateMatrixWorld();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave]);
        this.editor.trigger('sceneAreaChanged');

    },

    update: function(command) {
        this.newRotation.copy(command.newRotation)
    },

    toJSON: function() {
        var output = Command.prototype.toJSON.call(this);

        output.objectUuid = this.object.uuid;
        output.oldRotation = this.oldRotation.toArray();
        output.newRotation = this.newRotation.toArray();

        return output;
    }
}

export { SetRotation };