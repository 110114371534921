import * as THREE from 'three';
import Server from '../../../../../api';

import EditorExperience from '../../2DEditorExperience';
import EventEmitter from '../../../utils/EventEmitter';
import { AddObject } from '../../../commands/AddObject';
import LocationPin from './Pin';

import { 
    fromVec3Pos2dToPosObject,
    getRandomIdCode,
    getTransformed3DPos,
    getTransformed3DPosV1
} from '../../../threeUtils/TransformConversions';
import { getPinSpriteTexture } from './PinUtils';


class LocationPinHelper extends EventEmitter {
    constructor() {
        super();
        
        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;
        this.time = this.editor.time;

        this.startNavigationTrack = false;

        this.pinFeature = 'regular';
        this.amenityData = null;

        this.intersectionPoint = new THREE.Vector3();
        this.raycaster = new THREE.Raycaster();

        this.raycastObjects = [];

        // this.editor.on('toggleMenuActive', this.onToggleMenuActive);
    }
    

    setUpControls = ( pinFeature, type, amenityData = null ) => {
        if(this.startNavigationTrack || this.editor.pathController.startNavigationTrack) return;

        this.scene.children.forEach((child) => {
            if(child.userData && child.userData.navHelper &&  child.userData.navHelper === 'floorplan') {
                this.raycastObjects.push(child)
            }
        })

        if(this.raycastObjects.length > 0) {
            this.startNavigationTrack = true;
            this.editor.trigger('navigationTracking', [true]);
            this.pinFeature = pinFeature;
            this.amenityData = amenityData;

            this.layoutNavPaths(0, type, new THREE.Vector3());
        } else {
            this.editor.callbacks.generateAlert({
                msg: "Add floor plan in map to create navigation.",
                alertType: "information"
            })
        }
    }

    layoutNavPaths = (index = -1, type, vec3Pos) => {

        this.currentHeader = this.cloneLocationHeader(index, type);
        this.currentHeader.position.copy(vec3Pos)
        this.editor.addObjects(this.currentHeader);

        this.canvas.addEventListener('mousemove', this.onMouseMove, false);
        this.canvas.addEventListener('mousedown', this.onMouseDown, false);
        document.addEventListener('keydown', this.onKeyDownExit, false);
    }

    onMouseMove = (e) => {
        if(this.startNavigationTrack) {
            let viewportDown = new THREE.Vector2();
            const rect = this.canvas.getBoundingClientRect();
            viewportDown.x =   ((( e.offsetX - rect.left) / rect.width ) * 2 ) - 1;
            viewportDown.y = - ((( e.offsetY - rect.top) / rect.height ) * 2 ) + 1;

            this.raycaster.setFromCamera(viewportDown, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(this.raycastObjects, true);

            if(intersects.length > 0) {
                const intersect = intersects[0];
                this.intersectionPoint.copy(intersect.point);
                this.currentHeader.position.set(this.intersectionPoint.x, this.intersectionPoint.y + this.currentHeader.scale.y * .4, this.intersectionPoint.z)
            }
        }
    }

    withinBounds = (e) => {
        let viewportBound = new THREE.Vector2();
        let raycasterBound = new THREE.Raycaster();
        const rect = this.canvas.getBoundingClientRect();
        viewportBound.x =   ((( e.offsetX - rect.left) / rect.width ) * 2 ) - 1;
        viewportBound.y = - ((( e.offsetY - rect.top) / rect.height ) * 2 ) + 1;

        raycasterBound.setFromCamera(viewportBound, this.camera.instance);
        const intersects = raycasterBound.intersectObjects(this.raycastObjects, true);

        return intersects.length > 0;
    }

    onMouseDown = (e) => {
        if(!this.withinBounds(e)) return;

        switch(e.which) {

            case 2:
                this.exitNavigationTracking();
            break;

            case 1:
                this.currentHeader.position.set(this.intersectionPoint.x, this.intersectionPoint.y + this.currentHeader.scale.y * .4, this.intersectionPoint.z)
                // consider tip of sprite as pos
                const tIntersectPt = this.intersectionPoint.clone();
                let wayObj = {
                    name: this.currentHeader.name,
                    position: fromVec3Pos2dToPosObject(tIntersectPt.clone()),
                    postion2D: {posX: tIntersectPt.x, posY: Math.abs(tIntersectPt.y)},
                    posTransformed: this.getTransformedPos({posX: tIntersectPt.x, posY: Math.abs(tIntersectPt.y)}),
                    id: this.currentHeader.userData['id'],
                    visible: true,
                    type: this.currentHeader.userData['type'],
                    pinFeature: this.pinFeature,
                    amenityData: this.amenityData,
                };
                // create pin call!
                this.createPin(wayObj);
            break;

            default:
                break;
        }
    }


    onKeyDownExit = (e) => {
        if(e.keyCode === 27) {
            this.exitNavigationTracking();
        }
    }

    exitNavigationTracking = () => {
        this.editor.removeObject(this.currentHeader);
        this.startNavigationTrack = false;
        this.editor.trigger('navigationTracking', [false]);
        this.editor.deselect()
        this.canvas.removeEventListener('mousemove', this.onMouseMove, false);  
        this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
        document.removeEventListener('keydown', this.onKeyDownExit, false);
    }

    cloneLocationHeader = (index, type) => {
        const spriteTex = new THREE.TextureLoader().load(getPinSpriteTexture(type, 0));
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        const spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            side: THREE.DoubleSide,
            transparent: true,
            depthTest: false,
            depthWrite: false,
        });

        let scaleXZ = type === 'location' ? this.editor.locScales.regScaleXZ: this.editor.locScales.amScaleXZ;
        let scaleY = this.editor.locScales.scaleY;

        // scaleXZ += (scaleXZ * this.editor.floorData.SCALE_SIZE);
        // scaleY += (scaleY * this.editor.floorData.SCALE_SIZE);

        scaleXZ /= (scaleXZ / (this.editor.floorData.MIN_SIZE * (type === 'location' ? .025 : .023)));
        scaleY /= (scaleY / (this.editor.floorData.MIN_SIZE * .03));

        let header = new THREE.Sprite(spriteMaterial);
        const randID = getRandomIdCode();
        header.name = `locationPin_${randID}`;
        header.userData['id'] = `pin_${getRandomIdCode()}`;
        header.userData['type'] = type;
        header.userData['zoomableScale'] = type;
        header.scale.set(scaleXZ, scaleY, scaleXZ);
        header.rotation.y = THREE.MathUtils.degToRad(180);
        return header;
    }

    getTransformedPos = (pos) => {
        const { worldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6 
            ? getTransformed3DPos(worldMatrix, imgWidth, imgHeight, pos, true)
            : getTransformed3DPosV1(worldMatrix, imgWidth, imgHeight, pos, true);
    }

    onToggleMenuActive = () => {
        if(this.startNavigationTrack) {
            this.exitNavigationTracking();
        }
    }

    createPin = (pinObj) => {
        const adSetting = {
            logo: null,
            file: null,
            email: null,
            mobile: null,
            note: null,
            websiteLabel: null,
            websiteLink: null,
        }
        const reqObj = {
            name: pinObj.name,
            description: "",
            pinType: pinObj.pinFeature,
            pinColor: "#36CCE7",
            visible: true, 
            navigationStyle: 'Default', 
            position: pinObj.posTransformed,
            position2d: pinObj.postion2D,
            images: [],
            categories: [],
            accessPath: []
        }

        reqObj['amenityPinTypes'] = pinObj?.amenityData?.id ? [pinObj.amenityData.id] : [];

        if(pinObj.pinFeature === 'regular') {
            reqObj['advanced'] = adSetting;
        } else {
            reqObj['advanced'] = {};
        }
        Server.post(`/v1/map/locationpin/${this.editor.editor3d.mapId}`, reqObj)
        .then((res) => {
            this.startNavigationTrack = false;
            this.editor.trigger('navigationTracking', [false]);
            this.editor.removeObject(this.currentHeader, true);
            if(res.status === 200) {
                const data = res.data.data;
                pinObj.pinFeature === 'amenity' && (data['amenityType'] = pinObj.amenityData)
                const locationPin = new LocationPin(data);
                this.editor.onCommand(new AddObject(this.editor, locationPin.mesh));
            } else {
                this.editor.editor3d.callbacks.generateAlert({msg: 'Error while creating pin!', alertType: 'information'});
            }
            // common steps!
            this.currentHeader = null; 
            this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
            this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
            document.removeEventListener('keydown', this.onKeyDownExit, false);

        }).catch(e => console.log(e.response));
    }
}

export default LocationPinHelper;