import EventEmitter from "../../utils/EventEmitter";

// import UI Components
import {
    UIButton,
    UIImage,
    UIPanel,
    UIRow,
    UIText,
    UITextHeader
} from '../ui.lib';

//Icons
import SaveSpinnerIcon from '../../../../assets/svgs/scenes/saving_spinner.svg';

class TopbarSave extends EventEmitter {

    constructor(editor) {
        super();
        this.editor = editor;
        
        this.dom = null;

        this.saveState = false;

        this.editor.on('autoSaveSceneGraphState', this.onAutoSaveSceneGraphState);
        this.editor.on('autoSaveComplete', this.onAUtoSaveComplete);


        this.initUI();
    }

    initUI = () => {
        var uiContainer = new UIPanel().addClass('ws_uiSaveContainer');

        const uiSaveContDiv = new UIPanel();

        this.uiSavingRow = new UIRow().addClass('ws_uiSavingRow');
        this.uiSavingRow.addChild(new UIImage(SaveSpinnerIcon, '28px', '24px').addClass('ws_uiImageSaving'));
        this.uiSavingRow.addChild(new UIText('80%', 'Auto Saving...').setId('span_autoSaving'));

        this.uiSavedRow = new UIRow().addClass('ws_uiSavingRow').addClass('ws_uiSavingRow--active');
        this.uiSavedRow.addChild(new UIPanel().addClass('ws_uiBullet'));
        const uiSaveTextCont = new UIPanel();
        uiSaveTextCont.addChild(new UIText('80%', 'Auto Saved').addClass('ws_uiSavedText').setId('span_autoSaved'))
        
        const uiTooltip = new UIPanel().addClass('ws_uiSaveTooltip');
        uiTooltip.addChild(new UITextHeader('100%', 'Auto Saving').addClass('ws_uiTextBlack'))
        uiTooltip.addChild(new UIText('100%', 'Every change you make is automatically saved in ARway Portal.').addClass('ws_uiTextBlack'))

        uiSaveTextCont.addChild(uiTooltip);
        this.uiSavedRow.addChild(uiSaveTextCont);

        uiSaveContDiv.addChild(this.uiSavingRow);
        uiSaveContDiv.addChild(this.uiSavedRow);

        uiContainer.addChild(uiSaveContDiv);


        this.saveBtn = new UIButton('Save Progress', 'tapbarBtn tapbarBtn--draft', true).setId('wsSaveButton').onClick(() => {
            if(!this.editor.inSavingState) {
                this.editor.trigger('invokeSaveMapState', ['draft']);
                this.editor.trigger('sceneAreaChanged');
            }
        });

        this.publishBtn = new UIButton('Publish Map', 'tapbarBtn', true).setId('wsPublishButton').onClick(() => {
            if(!this.editor.inSavingState) {
                // this.editor.trigger('invokeSaveMapState', ['publish']);
                this.editor.callbacks.onToggleConfirmPublishModal(true, this.editor.isFloorMap)
                this.editor.trigger('sceneAreaChanged');
            }
        });

        uiContainer.addChild(this.saveBtn);
        uiContainer.addChild(this.publishBtn);

        this.dom = uiContainer;
    }

    onAutoSaveSceneGraphState = () => {
        this.saveState = true;
        this.uiSavingRow.addClass('ws_uiSavingRow--active');
        this.uiSavedRow.removeClass('ws_uiSavingRow--active');

        // disable Btns
        this.saveBtn.addClass('tapbarBtn--disabled');
        this.publishBtn.addClass('tapbarBtn--disabled');
    }

    onAUtoSaveComplete = () => {
        if(this.saveState === false) return;

        this.saveState = false;
        this.uiSavedRow.addClass('ws_uiSavingRow--active');
        this.uiSavingRow.removeClass('ws_uiSavingRow--active');

        // enable Btns
        this.saveBtn.removeClass('tapbarBtn--disabled');
        this.publishBtn.removeClass('tapbarBtn--disabled');
    }
}

export { TopbarSave }