import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderTable from "../../analytics/Render/RenderTable";
import { resetLocationPins } from "../../../../../features/webstudio/locationSlice";
import Loader from "../../../../common/loader";

function createData(name, category) {
  return { name, category };
}

export default function LocationPinTable({ loading, searchText }) {
  let locationPins = useSelector(
    (state) => state.locationSlice?.locationPins?.locationPins
  );
  let searchedLocationPins = useSelector(
    (state) => state.locationSlice.searchedLocationPins?.locationPins
  );
  const dispatch = useDispatch();
  const [rows, setRows] = useState(null);
  useEffect(() => {
    return () => {
      setRows(null);
      dispatch(resetLocationPins());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchedLocationPins) {
      let rows = [];
      searchedLocationPins.forEach((lp, i) => {
        if (
          lp.pinType.toLowerCase() === "regular" ||
          lp.pinType.toLowerCase() === "amenity"
        ) {
          let category = lp.categories[0]?.name || null;
          rows.push(createData(lp.name, category));
        }
      });
      setRows(rows);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedLocationPins]);

  useEffect(() => {
    if (locationPins) {
      let rows = [];
      locationPins.forEach((lp, i) => {
        if (
          lp.pinType.toLowerCase() === "regular" ||
          lp.pinType.toLowerCase() === "amenity"
        ) {
          let category = lp.categories[0]?.name || null;
          rows.push(createData(lp.name, category));
        }
      });
      setRows(rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPins]);

  const locationPinHeaders = [
    { key: "name", label: "Location Pin" },
    { key: "category", label: "Category" },
  ];

  const loadingCSS = {
    height: "20px",
    margin: "10px",
  };

  return (
    <div className="pt-3">
      {rows?.length > 0 ? (
        <RenderTable thheaderData={locationPinHeaders} rowsData={rows} />
      ) : loading ? (
        <div
          style={{
            ...loadingCSS,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader
            loaderText="Fetching location pins..."
            height="100%"
            width="100%"
          />
        </div>
      ) : (
        <div
          className="shadow p-3 mb-3 bg-body rounded mapdetails__mapDefaultFloorplan"
          style={{ height: "50vh" }}
        >
          <div className="pb-4">{`No ${
            searchText.length > 0 ? "Searched" : ""
          } Location pins placed`}</div>
          <label
            className="content-type-select mt-4"
            style={{ lineHeight: "normal" }}
          >
            {`No ${
              searchText.length > 0 ? "Searched" : ""
            } Location pins yet. Your Location pin details will appear here.`}
          </label>
          <div className="pt-4"></div>
        </div>
      )}
    </div>
  );
}
