import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ARWayImage from "../../../common/CO/ARWayImage";
import { getPlaceholderThumbnail } from "../../../_utils/AssignPlaceholder";
import { convertInDuration } from "../../../_utils/dateFormatter";
import Checkbox from "@mui/material/Checkbox";
import { grey, blue } from "@mui/material/colors";
import Building from "../../../../assets/pngs/studio/importMaps/building.png";

export default function MapCard({
  map,
  isImport,
  isConnected,
  setSelectedMaps,
  onClickAction,
  isCheck = false,
  isDefault = false,
  defaultName = undefined,
}) {
  const imgRef = useRef(null);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [isChecked, setIsChecked] = useState(isCheck);

  useEffect(() => {
    if (isCheck) {
      setIsChecked(isCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCheck]);

  useEffect(() => {
    if (setSelectedMaps) {
      setSelectedMaps({
        mapId: map.mapId,
        name: map.mapName,
        isChecked,
        thumbnailImg: map.thumbnailImage,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChecked]);

  return (
    <div
      className="studiohome__mapCardWrapper"
      id="div-mapCard"
      onClick={onClickAction}
    >
      {isImport || isConnected ? (
        <div className="studiohome__mapCard">
          <div className="studiohome__mapImgCont" style={{ cursor: "pointer" }}>
            {isConnected ? null : (
              <Checkbox
                {...label}
                style={{ zIndex: 100, position: "absolute" }}
                sx={{
                  color: grey[800],
                  "&.Mui-checked": {
                    color: blue[100],
                  },
                }}
                checked={isChecked}
                onChange={() => {
                  setIsChecked(!isChecked);
                }}
                disabled={isDefault}
              />
            )}
            <ARWayImage
              src={map.thumbnailImage || getPlaceholderThumbnail()}
              alt="map-thumbnail"
              ref={imgRef}
              onError={() => (imgRef.current.src = getPlaceholderThumbnail())}
            />
            <div className="studiohome__mapOverlay" style={{ zIndex: 50 }}>
              <span
                className={`studiohome__mapOverlay--mapType ${
                  map.isPublished
                    ? "studiohome__mapOverlay--published"
                    : "studiohome__mapOverlay--draft"
                }`}
              >
                {map.isPublished ? "Published" : "Draft"}
              </span>
              {/* {map.mapCode?} */}
              {!map.isVenue && (
                <span className="studiohome__mapOverlay--mapCode">
                  Map Code: {map.mapCode}
                </span>
              )}
            </div>
          </div>
          <div className="studiohome__mapDetailsCont">
            {isConnected ? (
              <span className="studiohome__mapDataHeaderRow--subText">
                <abbr title={map.mapName} id="span-mapName">
                  {map.identifier}
                </abbr>
              </span>
            ) : (
              ""
            )}
            <span className="studiohome__mapDataHeaderRow">
              <span
                className="studiohome__mapDataHeaderRow--text"
                style={isConnected ? { width: "100% !important" } : {}}
              >
                <abbr title={map.mapName} id="span-mapName">
                  {defaultName
                    ? defaultName
                    : map.name
                    ? map.name
                    : map.mapName}
                </abbr>
              </span>
              {!isConnected ? (
                <span
                  className="studiohome__mapDataHeaderRow--textDuration"
                  id="span-mapDuration"
                >
                  {/* Edited {convertInDuration(map.modifiedOn)} ago */}
                </span>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      ) : (
        <Link
          to={`${
            map.isVenue
              ? `/dashboard/studio/venue/${map.mapId}`
              : `/dashboard/studio/${map.mapId}`
          }`}
          state={map.mapId}
          className="linkTagMap"
        >
          <div className="studiohome__mapCard">
            <div className="studiohome__mapImgCont">
              {map.isVenue ? (
                <img
                  src={Building}
                  style={{
                    zIndex: 100,
                    position: "absolute",
                    scale: "0.17",
                    top: "-60px",
                    right: "140px",
                  }}
                  alt="building_img"
                ></img>
              ) : null}

              <ARWayImage
                src={
                  map.thumbnailImage ||
                  map.thumbnail ||
                  getPlaceholderThumbnail()
                }
                alt="map-thumbnail"
                ref={imgRef}
                onError={() => (imgRef.current.src = getPlaceholderThumbnail())}
              />
              <div className="studiohome__mapOverlay" style={{ zIndex: 50 }}>
                <span
                  className={`studiohome__mapOverlay--mapType ${
                    map.isPublished
                      ? "studiohome__mapOverlay--published"
                      : "studiohome__mapOverlay--draft"
                  }`}
                >
                  {map.isPublished ? "Published" : "Draft"}
                </span>
                {!map.isVenue && (
                  <span className="studiohome__mapOverlay--mapCode">
                    Map Code: {map.mapCode}
                  </span>
                )}
              </div>
            </div>
            <div className="studiohome__mapDetailsCont">
              <span className="studiohome__mapDataHeaderRow">
                <span className="studiohome__mapDataHeaderRow--text">
                  <abbr title={map.mapName} id="span-mapName">
                    {map.isVenue ? map.name : map.mapName}
                  </abbr>
                </span>
                <span
                  className="studiohome__mapDataHeaderRow--textDuration"
                  id="span-mapDuration"
                >
                  Edited {convertInDuration(map.modifiedOn)} ago
                </span>
              </span>
            </div>
          </div>
        </Link>
      )}
    </div>
  );
}
