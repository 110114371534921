// import * as THREE from 'three';
import { has } from "lodash";
// loader
import EventEmitter from "../../utils/EventEmitter";
import EditorExperience from '../2DEditorExperience';
import Pin2d from '../threeUtils/Pins/Pin';
import Connector2d from '../threeUtils/Connectors/Connector';
import QRCode from "../threeUtils/QRCode";

export default class ObjectsLoader extends EventEmitter {
    
    constructor() {
        super();

        this.editor = new EditorExperience();
        this.sources = [];

        this.items = {};
        this.toLoad = 0;
        this.loaded = 0;
        this.readyToLoad = false;

        this.editor.on('clearAndReset', this.onClearAndReset);
        this.editor.on('initiateLoading', this.onInitiateLoding);

    }

    onClearAndReset = () => {
        this.loaded = 0;
        this.toLoad = 0;
        this.items = {};
        this.sources = [];
        this.readyToLoad = false;
    }

    initiateLoading = (sources) => {
        this.sources = this.toLinearArray(sources);
        this.toLoad =  this.sources.length;
        this.sources.length > 0 && this.readyToLoad ? this.startLoading() : this.isReady();
    }

    onInitiateLoding = () => {
        this.readyToLoad = true;
        this.sources.length > 0 && this.readyToLoad ? this.startLoading() : this.isReady();
    }

    toLinearArray = (sources) => {
        let arr = []
        for(const source in sources) {
            arr = [...arr, ...(sources[source].map(s => {return {...s, type: source}}))]
        }
        return arr;
    }    

    startLoading = () => {
        // console.log(this.sources);
        for(const source of this.sources) {
            if(source.type && source.type === 'paths') {
                if(!(has(this.items, source.id))){
                    const path = this.editor.pathController.loadPaths(source);
                    this.objectLoaded(source, source.id, "paths", path.mesh);
                    // console.log(path);
                }
            } 
            else if(source.type && source.type === 'pins') {
                if(!(has(this.items, source.id))){
                    let pinMesh;
                    if(source.pinType === 'connector') {
                        pinMesh = new Connector2d(source);
                    } else {
                        pinMesh = new Pin2d(source);
                    }
                    this.objectLoaded(source, source.id, "pins", pinMesh.mesh);
                }
            }
            else if(source.type && source.type === 'qrAnchors') {
                if(!(has(this.items, source.id))){
                    const qrCode = new QRCode(source);
                    this.objectLoaded(source, source.id, "qrAnchors", qrCode.mesh);
                }
            }
            else {
                //Skip this object
                this.objectLoaded(source, "SKIPPED_OBJECT", "SKIPPED_OBJECT", null);
            }
        }
    }

    objectLoaded = (source, id, type, file) => {
        if(id === 'SKIPPED_OBJECT') {
            this.loaded++;
        } else {
            if(has(this.items, source.id)) return;
            this.items[id] = {
                type,
                object: file
            };
            this.loaded++;

            if(file instanceof Array) {
                for(const obj of file) {
                    this.editor.addObjects(obj);
                }
            } else {
                this.editor.addObjects(file);
            }
        }

        // console.log("ITEMS", this.items, this.toLoad, this.loaded);

        this.isReady();
    }

    isReady = () => {
        if(this.loaded === this.toLoad) {
            this.trigger('ready');
        }
    }
}