import EventEmitter from "../../../utils/EventEmitter";

// import UI Components
import { UIImage, UIPanel, UITooltip } from "../../../ui/ui.lib";

import UndoIcon from "../../../../../assets/svgs/scenes/undo.svg";
import RedoIcon from "../../../../../assets/svgs/scenes/redo.svg";

class Menu2DHistory extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;

        this.dom = null;

        this.initUI();

        this.editor.on("historyChanged", this.onHistoryChanged);
        this.editor.resources.on("ready", this.onHistoryChanged);
        this.editor.on("navigationTracking", this.toggleNavigationTracking);
    }

    initUI = () => {
        var uiContainer = new UIPanel();
        uiContainer.addClass("wsHistoryContainer2D");

        this.uiUndoBtn = new UIPanel()
            .addClass("wsMenuContainer")
            .setId("ws2DMenuUndo")
            .onClick(() => {
                if (this.editor.history.undos.length === 0) return;
                this.editor.onUndo();
            });
        this.uiUndoBtn.addChild(new UIImage(UndoIcon, "30px", "30px"));
        this.uiUndoBtn.addChild(new UITooltip("Undo (Ctrl+z)"));

        this.uiRedoBtn = new UIPanel()
            .addClass("wsMenuContainer")
            .setId("ws2DMenuRedo")
            .onClick(() => {
                if (this.editor.history.redos.length === 0) return;
                this.editor.onRedo();
            });
        this.uiRedoBtn.addChild(new UIImage(RedoIcon, "30px", "30px"));
        this.uiRedoBtn.addChild(new UITooltip("Redo (Ctrl+y)"));

        uiContainer.addChild(this.uiUndoBtn);
        uiContainer.addChild(this.uiRedoBtn);

        this.dom = uiContainer.dom;
    };

    onHistoryChanged = () => {
        if (this.editor.history.undos.length === 0) {
            this.uiUndoBtn.addClass("wsHistoryContainer--inactive");
        } else {
            this.uiUndoBtn.removeClass("wsHistoryContainer--inactive");
        }

        if (this.editor.history.redos.length === 0) {
            this.uiRedoBtn.addClass("wsHistoryContainer--inactive");
        } else {
            this.uiRedoBtn.removeClass("wsHistoryContainer--inactive");
        }
    };

    toggleNavigationTracking = (bFlag) => {
        if(bFlag) {
            this.uiUndoBtn.addClass("wsHistoryContainer--inactive");
            this.uiRedoBtn.addClass("wsHistoryContainer--inactive");
        } else {
            this.uiUndoBtn.removeClass("wsHistoryContainer--inactive");
            this.uiRedoBtn.removeClass("wsHistoryContainer--inactive");
        }
    }
}

export { Menu2DHistory };
