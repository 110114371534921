import { useState, useEffect, useRef } from "react";


const useComponentVisible=(initialIsVisible)=> {
    const [isComponentVisible, setIsComponentVisible] = useState(
      initialIsVisible
    );
    const visRef = useRef(null);
  
    const handleHideDropdown = (event) => {
      if (event.key === "Escape") {
        // event.stopPropagation()
        setIsComponentVisible(false);
      }
    };
  
    const handleClickOutside = event => {
      if (visRef.current && !visRef.current.contains(event.target)) {
        setIsComponentVisible(false);
      }
    };
  
    useEffect(() => {
      document.addEventListener("keydown", handleHideDropdown, true);
      document.addEventListener("click", handleClickOutside, true);
      return () => {
        document.removeEventListener("keydown", handleHideDropdown, true);
        document.removeEventListener("click", handleClickOutside, true);
      };
    });
  
    return { visRef, isComponentVisible, setIsComponentVisible };
  }
export default useComponentVisible