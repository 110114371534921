import FloorIcon from '../../../../../assets/svgs/scenes/menu/floorplan.svg';
import LocationIcon from '../../../../../assets/svgs/scenes/menu/navigation.svg';
import WalkableIcon from '../../../../../assets/svgs/scenes/menu/walkable_path.svg';
import AllAccessIcon from '../../../../../assets/svgs/scenes/menu/allaccess_path.svg';
import ConnectorIcon from '../../../../../assets/svgs/scenes/menu/connector_pin.svg';
import QRIcon from '../../../../../assets/svgs/scenes/menu/qrcode.svg';

function ViewOptions2D () {

    var options = {
        '0' : {
            id: 0,
            icon: FloorIcon,
            name: 'Floor Plan',
            state: true,
            enable: false,
            type: 'floorplan',
        },
        '1' : {
            id: 1,
            icon: WalkableIcon,
            name: 'Walk-able Only',
            state: true,
            enable: false,
            type: 'walkable',
        },
        '2' : {
            id: 2,
            icon: AllAccessIcon,
            name: 'All Access',
            state: true,
            enable: false,
            type: 'allAccess',
        },
        '3' : {
            id: 3,
            icon: LocationIcon,
            name: 'Custom Pins',
            state: true,
            enable: false,
            type: 'regular',
        },
        '4' : {
            id: 4,
            icon: LocationIcon,
            name: 'Amenity Pins',
            state: true,
            enable: false,
            type: 'amenity',
        },
        '5' : {
            id: 5,
            icon: ConnectorIcon,
            name: 'Connector Pins',
            state: true,
            enable: false,
            type: 'connector',
        },
        '6' : {
            id: 6,
            icon: QRIcon,
            name: 'QR Codes',
            state: true,
            enable: false,
            type: 'qrAnchor',
        },
    }

    var breakIds = [ 3, 6 ]

    return {
        toArray: function() {
            return Object.values(options);
        },

        setState: function(id) {
            if(Object.keys(options).length >= id) {
                var lState = options[id].state;
                options[id].state = !lState;
                return 0;
            } else return -1;
        },

        setEnable: function(id) {
            if(Object.keys(options).length >= id) {
                var lEnable = options[id].enable;
                options[id].enable = !lEnable;
                return 0;
            } else return -1;
        },

        getOption: function(id) {
            return options[id] || null;
        },

        getTypes: function() {
            return Object.values(options).map(opt => opt.type).flat();
        },

        getBreakIds: function() {
            return breakIds;
        },

        resetAll: function() {
            for(var opt in options) {
                options[opt].enable = false;
                options[opt].state = true;
            }
        },
    }
}

export { ViewOptions2D }