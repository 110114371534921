import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Backdrop, Box, 
    Modal, Fade, 
    Grid, Button
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

import Input from './components/input';
import SwitchInput from './components/input-switch';
import { clearLog, createPlan } from '../../../../features/admin/plans/plansSlice';
import { setNewAlert } from '../../../../features/common/alertSlice';
import { isEmpty } from 'lodash';
import GenericSwitch from '../../../common/toggleSwitch/GenericSwitch';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 850,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

const planObj = {
    name: '',
    price: 0,
    annualPrice: 0,
    duration: 0,
    apiCalls: 0,
    isRecurring: false,
    storage: 0,
    mapsLimit: 0,
    activeMapDays: {
        enable: false,
        count: 0
    },
    publishedMap: {
        enable: false,
        count: 0
    },
    mapArea: {
        enable: false,
        size: 0
    }
}

export default function CreatePlanModal({openModal, onCloseModal}) {

    const [ planData, setPlanData ] = useState({...planObj});
    const [ planErrors, setErrors ] = useState({});
    const [ progress, setProgress ] = useState(false);
    const [areaSQFt, setAreaSQFt] = useState(false);


    const dispatch = useDispatch();

    const plansLog = useSelector(state => state.plans.createLog);

    useEffect(() => {
        if(plansLog !== null) {
            if(plansLog === 'success') {
                dispatch(setNewAlert({
                    msg: 'Plan Created Successfully!',
                    alertType: 'information'
                }));
                setProgress(false);
                handleClose();
            } else {
                setProgress(false);
                dispatch(clearLog());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plansLog]);

    useEffect(() => {
        if(planData) {
            validateChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planData]);

    const handleEdit = (e, type = 'text') => {
        setPlanData((planData) => ({
            ...planData,
            [e.target.id]: type === 'text' ? e.target.value :Number(e.target.value)
        }));
    }

    const handleObjectEdit = (key, id, value) => {
        setPlanData((planData) => ({
            ...planData,
            [key]: {
                ...planData[key],
                [id]: value
            }
        }))
    }

    const handleClose = () => {
        setPlanData({...planObj});
        onCloseModal();
    }

    const validateChanges = () => {
        let errFlag = true;
        let errors = {...planErrors};
        if(planData.name.trim().length <= 0) {
            errors = {
                ...errors,
                name: "Name shouldn't be empty."
            };
            errFlag = false;
        } else {
            delete errors["name"];
        }

        if(planData.price <= 0) {
            errors = {
                ...errors,
                price: "Price shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["price"];
        }

        if(planData.annualPrice <= 0) {
            errors = {
                ...errors,
                annualPrice: "Annual price shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["annualPrice"];
        }

        if(planData.duration <= 0) {
            errors = {
                ...errors,
                duration: "Duration shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["duration"];
        }

        if(planData.apiCalls <= 0) {
            errors = {
                ...errors,
                apiCalls: "API calls limit shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["apiCalls"];
        }

        if(planData.storage <= 0) {
            errors = {
                ...errors,
                storage: "Storage limit shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["storage"];
        }

        if(planData.mapsLimit <= 0) {
            errors = {
                ...errors,
                mapsLimit: "Map limit shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["mapsLimit"];
        }

        if(planData.activeMapDays.enable && planData.activeMapDays.count <= 0) {
            errors = {
                ...errors,
                activeMapDays: "Active map days shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["activeMapDays"];
        }

        if(planData.publishedMap.enable && planData.publishedMap.count <= 0) {
            errors = {
                ...errors,
                publishedMap: "Published map limit shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["publishedMap"];
        }

        if(planData.mapArea.enable && planData.mapArea.size <= 0) {
            errors = {
                ...errors,
                mapArea: "Map area size shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["mapArea"];
        }

        setErrors({...errors});
        return errFlag;
    }

    const handleCreate = () => {
        console.log(planData);
        setProgress(true);
        dispatch(createPlan(areaSQFt ? 
            {
                ...planData,
                mapArea: {
                    ...planData.mapArea,
                    size: (planData.mapArea.size / 10.764).toFixed(2)
                }
            } : planData)
        )
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={handleClose}/>
                        <div className='pmCreateModal__header'>Create a new plan</div>
                        <div>
                        <Grid container={true}>
                            <Grid item xs={6}>
                                <Input 
                                    inputType="text"
                                    label="Plan Name"
                                    id="name"
                                    value={planData.name}
                                    enabled={false}
                                    handleOnChange={handleEdit}
                                />
                                {planErrors && planErrors.name && <span className='pInput__error'>{planErrors.name}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <Input 
                                    inputType="number"
                                    label="Price"
                                    subLabel="USD"
                                    id="price"
                                    value={planData.price}
                                    enabled={false}
                                    handleOnChange={(e) => handleEdit(e, 'number')}
                                />
                                {planErrors && planErrors.price && <span className='pInput__error'>{planErrors.price}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <Input 
                                    inputType="number"
                                    label="Annual Price"
                                    subLabel="USD"
                                    id="annualPrice"
                                    value={planData.annualPrice}
                                    enabled={false}
                                    handleOnChange={(e) => handleEdit(e, 'number')}
                                />
                                {planErrors && planErrors.annualPrice && <span className='pInput__error'>{planErrors.annualPrice}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <Input 
                                    inputType="number"
                                    label="API Calls"
                                    id="apiCalls"
                                    value={planData.apiCalls}
                                    enabled={false}
                                    handleOnChange={(e) => handleEdit(e, 'number')}
                                    limitKeys={true}
                                />
                                {planErrors && planErrors.apiCalls && <span className='pInput__error'>{planErrors.apiCalls}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <Input 
                                    inputType="number"
                                    label="Storage"
                                    subLabel="MB"
                                    id="storage"
                                    value={planData.storage}
                                    enabled={false}
                                    handleOnChange={(e) => handleEdit(e, 'number')}
                                />
                                {planErrors && planErrors.storage && <span className='pInput__error'>{planErrors.storage}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <Input 
                                    inputType="number"
                                    label="Maps Limit"
                                    id="mapsLimit"
                                    value={planData.mapsLimit}
                                    enabled={false}
                                    handleOnChange={(e) => handleEdit(e, 'number')}
                                    limitKeys={true}
                                />
                                {planErrors && planErrors.mapsLimit && <span className='pInput__error'>{planErrors.mapsLimit}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <SwitchInput 
                                    inputType="number"
                                    label="Duration"
                                    subLabel="Days"
                                    id="duration"
                                    toggleLabel="Set Recurring"
                                    value={planData.duration}
                                    toggleValue={planData.isRecurring}
                                    enabled={false}
                                    linkedToggle={false}
                                    handleOnChange={(e) => handleEdit(e, 'number')}
                                    handleOnToggle={(e) => setPlanData((prev) => ({
                                        ...prev, isRecurring: e.target.checked
                                    }))}
                                    limitKeys={true}
                                />
                                {planErrors && planErrors.duration && <span className='pInput__error'>{planErrors.duration}</span>}
                            </Grid>
                            <Grid item xs={6} className='absErroCont'>
                                <SwitchInput 
                                    inputType="number"
                                    label="Maps Area"
                                    id="mapArea"
                                    toggleLabel="Set Limit"
                                    value={planData.mapArea.size}
                                    toggleValue={planData.mapArea.enable}
                                    enabled={false}
                                    linkedToggle={true}
                                    handleOnChange={(e) => handleObjectEdit('mapArea','size', e.target.value)}
                                    handleOnToggle={(e) => handleObjectEdit('mapArea', 'enable', e.target.checked)}
                                />
                                <div className='mapAreaUnitCont scaledmini' style={!planData.mapArea.enable ? {opacity: .4, paddingRight: '8px'} : {paddingRight: '8px'}}>
                                    <div className='mapAreaUnitDiv'>
                                        <div className='mapAreaUnitDiv__header'>Map Area Unit: </div>
                                        <div className='mapAreaUnitDiv__toggleCont'>
                                            <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Mtr</div>
                                            <GenericSwitch
                                                enabled={areaSQFt}
                                                onEnableToggle={(e) => {
                                                    setAreaSQFt(e.target.checked);
                                                    e.target.checked 
                                                        ? handleObjectEdit('mapArea','size', (planData.mapArea.size * 10.764).toFixed(2))
                                                        : handleObjectEdit('mapArea','size', (planData.mapArea.size / 10.764))
                                                }}
                                                disabled={!planData.mapArea.enable}
                                            />
                                            <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Ft</div>
                                        </div>
                                    </div>
                                </div>
                                {planErrors && planErrors.mapArea && <span className='pInput__error absError absError--t84'>{planErrors.mapArea}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <SwitchInput 
                                    inputType="number"
                                    label="Active Map Days"
                                    subLabel="Days"
                                    id="activeMapDays"
                                    toggleLabel="Enable"
                                    value={planData.activeMapDays.count}
                                    toggleValue={planData.activeMapDays.enable}
                                    enabled={false}
                                    linkedToggle={true}
                                    handleOnChange={(e) => handleObjectEdit('activeMapDays','count', e.target.value)}
                                    handleOnToggle={(e) => handleObjectEdit('activeMapDays', 'enable', e.target.checked)}
                                    limitKeys={true}
                                />
                                {planErrors && planErrors.activeMapDays && <span className='pInput__error'>{planErrors.activeMapDays}</span>}
                            </Grid>
                            <Grid item xs={6}>
                                <SwitchInput 
                                    inputType="number"
                                    label="Published Maps"
                                    id="publishedMap"
                                    toggleLabel="Set Limit"
                                    value={planData.publishedMap.count}
                                    toggleValue={planData.publishedMap.enable}
                                    enabled={false}
                                    linkedToggle={true}
                                    handleOnChange={(e) => handleObjectEdit('publishedMap','count', e.target.value)}
                                    handleOnToggle={(e) => handleObjectEdit('publishedMap', 'enable', e.target.checked)}
                                    limitKeys={true}
                                />
                                {planErrors && planErrors.publishedMap && <span className='pInput__error'>{planErrors.publishedMap}</span>}
                            </Grid>
                        </Grid>
                        </div>

                        <div className="pmCreateModal__btnsDiv">
                            <Button variant='outlined' color='primary' onClick={handleClose} disabled={progress} id="button-calcel">Cancel</Button>
                            <Button variant='contained' color='accentBlue' onClick={handleCreate} disabled={progress || !isEmpty(planErrors)} className='mapdetails__mapModalBtnText' >Create Plan</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}