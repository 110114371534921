import { Command } from './Command';

function SetScale( editor, object, newScale, optOldScale, delayAutoSave) {
    Command.call(this, editor);

    this.type = 'SetScale';
    this.name = 'Set Scale';
    this.updatable = true;
    this.delayAutoSave = delayAutoSave;

    this.object = object;

    if(object !== undefined && newScale !== undefined) {
        this.oldScale = object.scale.clone();
        this.newScale = newScale.clone();
    }

    if(optOldScale !== undefined) {
        this.oldScale = optOldScale;
    }
}

SetScale.prototype = {
    execute: function() {
        this.object.scale.copy(this.newScale);
        this.object.updateMatrixWorld();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave]);
        this.editor.trigger('sceneAreaChanged');
    },

    undo: function() {
        this.object.scale.copy(this.oldScale);
        this.object.updateMatrixWorld();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave]);
        this.editor.trigger('sceneAreaChanged');
    },

    update: function(command) {
        this.newScale.copy(command.newScale);
    },

    toJSON: function() {
        var output = Command.prototype.toJSON.call(this);

        output.objectUuid = this.object.uuid;
        output.oldScale = this.oldScale.toArray();
        output.newScale = this.newScale.toArray();

        return output;
    }
}

export { SetScale };