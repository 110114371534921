import * as React from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";

import AccessCheckSvg from "../../../../assets/svgs/access_check.svg";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//Routes
import { DASHBOARD_HOME, D_SETTINGS } from "../../../_utils/routes";

import items from "../../../common/sidebar/menu";
import { retainNavState } from "../../../../features/common/commonSlice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  minHeight: 400,
  height: "max-content",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none !important",
  outline: "none !important",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center !important",
  alignItems: "center !important",
  borderRadius: "16px",
};

export default function ConfirmModal({ openModal, onCloseModal }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loggedUserType =
    useSelector((state) => state.auth.loggedUserType) || "user";

  const handleNavigation = (link) => {
    const findIndxArr = items[loggedUserType];
    let activeIndx = 0;
    findIndxArr.forEach((ele, index) => {
      if (link === ele.link) activeIndx = index;
    });
    navigate(link);
    dispatch(retainNavState(activeIndx));
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={style}>
            <h4 className="accesshome__accessModalHeader">Access Granted</h4>
            <img
              className="accesshome__accessModalImg"
              src={AccessCheckSvg}
              alt="AccessCheckSvg"
            />
            <div className="accesshome__accessModalSubText">
              View your dashboard to get started or view your plan to see the
              features available to you.
            </div>
            <div className="accesshome__accessModalBtnsDiv">
              <button className="accesshome__accessModalBtn accesshome__accessModalBtn--dashboard">
                <Link to={DASHBOARD_HOME} className="accesshome__accessLinkTag">
                  View Dashboard
                </Link>
              </button>
              <button
                className="accesshome__accessModalBtn accesshome__accessModalBtn--plans"
                onClick={() => handleNavigation(D_SETTINGS)}
              >
                View My Plan
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
