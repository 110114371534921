import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Container,
    Box,
} from '@mui/material';
import {
    Search, X,
} from 'react-feather';
import {
    getAccounts, searchAccount,
    clearSearchedAccounts, getAccountsForCSV
} from '../../../../features/admin/manageaccounts/manageAccountsSlice';

import AccountsTable from './AccountsTable';
import Loader from '../../../common/loader';
import RenderPagination from '../../users/analytics/Render/RenderPagination';
import { CSVLink } from 'react-csv';
import { getFileNameWithTS } from '../../../_utils/DateUtils';
import noSearchIcon from '../../../../assets/svgs/no_search.svg';
import GenericSwitch from '../../../common/toggleSwitch/GenericSwitch';

export default function AccountsHome() {
    const dispatch = useDispatch();

    const [accounts, setAccounts] = useState([]);
    const [searchedAccounts, setSearchedAccounts] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [pageNumber, setPageNumber] = useState(1);
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(true);
    const [areaSQFt, setAreaSQFt] = useState(true);

    const [loadReport, setLoadReport] = useState(null);
    const [csvData, setCSVData] = useState([]);
    const csvLabels = [
        { key: 'email', label: 'Account' },
        { key: 'createdDate', label: 'Created On' },
        { key: 'lastActivityDate', label: 'Last Edited' },
        { key: 'planName', label: 'Plan' },
        { key: 'totalMapSize', label: 'Map Area' },
        { key: 'storageUsed', label: 'Storage Used' },
        { key: 'averageViews', label: 'Map Views' },
    ]

    const pageSize = 10;

    const accountList = useSelector((state) => state.manageaccounts.accounts);
    const accountListCSV = useSelector((state) => state.manageaccounts.csvAccounts);
    const searchedAccountList = useSelector((state) => state.manageaccounts.searchedAccounts);
    // const totalPages = useSelector((state) => state.manageaccounts.totalPages);
    const totalResults = useSelector((state) => state.manageaccounts.totalResults);


    useEffect(() => {
        setFetching(true);
        setAccounts([]);
        dispatch(getAccounts({ pageNumber, pageSize }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setFetching(true);
        dispatch(getAccounts({ pageNumber, pageSize }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        if (accountList.length) {
            setAccounts(accountList);
            setFetching(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountList]);

    useEffect(() => {
        setSearchedAccounts(searchedAccountList);
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchedAccountList]);

    useEffect(() => {
        if (accountListCSV?.length) {
            setCSVData(
                accountListCSV.map(account => {
                    let acData = {}
                    csvLabels.forEach(cl => (acData[cl.key] = account[cl.key] || '--'))
                    // console.log(acData);
                    return { ...acData };
                })
            )
            setLoadReport('gererated');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountListCSV]);

    useEffect(() => {
        if (searchText.length >= 3) {
            searchUsersNow();
        } else if (searchText.length <= 0) {
            handleClearSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText])

    const searchUsersNow = () => {
        setLoading(true);
        dispatch(searchAccount({ searchText, pageSize, pageNumber: 1 }))
    }

    const handleGenerateReport = () => {
        setLoadReport('loading');
        dispatch(getAccountsForCSV((totalResults)));
    }

    const handleSearchChange = (e) => {
        if (e.target.value.includes(" ") && e.target.value.length === 1) {
            e.target.value = e.target.value.replace(/\s/g, "");
        }

        const sText = e.target.value;
        var filter = /^([\w,:\s@.\-_]*)$/;
        let filteredSearchText = [...sText].map(c => filter.test(c) ? c : '').join('');
        setSearchText(filteredSearchText);
    }

    const handleClearSearch = () => {
        setSearchText('');
        setLoading(false);
        dispatch(clearSearchedAccounts());
    }

    const handleChange = (e, page) => {
        setPageNumber(page);
    }

    var crossClass = "searchbar__closeIcon--hide";
    if (searchText.length > 0) {
        crossClass = "";
    }

    const renderTable = () => {
        if (fetching || loading) {
            return <Loader loaderText={loading ? "Loading More Accounts..." : "Fetching Accounts..."} height="500px" width="100%" />
        } else if (accounts.length === 0) {
            return (
                <div className="illustration-container nodataCont">
                    <img src={noSearchIcon} alt="no_data" />
                    <h4>No Accounts Available!</h4>
                </div>
            )
        } else if (searchedAccounts && searchedAccounts.length > 0) {
            return <AccountsTable accounts={searchedAccounts} />;
        } else if (searchText.length >= 3 && (!searchedAccounts || searchedAccounts.length <= 0)) {
            return (
                <div className="illustration-container nodataCont" >
                    <img src={noSearchIcon} alt="no_data" />
                    <h4>No search results found for <br />“{searchText}”</h4>
                    <span>Make sure that all words are spelled correctly.</span>
                </div>
            );
        } else {
            return (
                <div>
                    <AccountsTable accounts={accounts} areaUnit={areaSQFt} />
                    <RenderPagination
                        count={Math.ceil(totalResults / pageSize)}
                        page={pageNumber}
                        limit={pageSize}
                        handleChange={handleChange}
                        cb={(page) => {
                            setPageNumber(page)
                        }}
                        totalResults={totalResults}
                    />
                </div>
            );
        }
    }

    return (
        <div style={{ padding: '40px' }}>
            <Container maxWidth={false} >
                <div className="pageWrapper">
                    <div>
                        <h4>Manage Accounts</h4>
                        <span>Select the account you would like to manage</span>
                    </div>
                    {loadReport === null ?
                        <button className='maccounts__reportButton' onClick={handleGenerateReport}>Generate Report</button>
                        : loadReport === 'loading' ?
                            <button className='maccounts__reportButton' disabled>Generating Report</button>
                            :
                            <CSVLink
                                headers={csvLabels}
                                data={csvData}
                                className='maccounts__reportButton'
                                filename={getFileNameWithTS(`userAccountsReport-`)}
                                onClick={() => setLoadReport(null)}
                                style={{ textDecoration: 'none', color: 'white' }}
                            >
                                Download Report
                            </CSVLink>}
                </div>
                <Box className="boxWrapper">
                    <div className="serachContRow mt-3">
                        <div className="searchbar searchbar--padded" style={{ width: "50%" }}>
                            <span className="searchbar__searchbarContainer">
                                <Search
                                    size={20}
                                    color="#353E5A"
                                    className="searchbar__searchIcon"
                                />
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Search with account email address"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    id="input-adminAnalyticsSearch"
                                />
                                <X
                                    size={20}
                                    color="#353E5A"
                                    onClick={handleClearSearch}
                                    className={`searchbar__closeIcon ${crossClass}`}
                                />
                            </span>
                        </div>
                        {(!loading && !fetching && ((accountList?.length && !searchText?.length) || searchedAccountList?.length)) ?
                            <div className='mapAreaUnitDiv'>
                                <div className='mapAreaUnitDiv__header'>Map Area Unit: </div>
                                <div className='mapAreaUnitDiv__toggleCont'>
                                    <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Mtr</div>
                                    <GenericSwitch
                                        enabled={areaSQFt}
                                        onEnableToggle={(e) => setAreaSQFt(e.target.checked)}
                                    />
                                    <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Ft</div>
                                </div>
                            </div>
                            : null}
                    </div>
                    <div>
                        {renderTable()}
                    </div>
                </Box>
            </Container>
        </div>
    )
}

