import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
import { UIPanel } from "../ui.lib";
import { SidebarContent } from "./Sidebar.Content";
import { SidebarObject } from "./Sidebar.Object";
import { SidebarObjectAccesss } from "./Sidebar.Object.Access";
import { SidebarObjectOverlay } from "./Sidebar.Object.Overlay";

class Sidebar extends EventEmitter {

    constructor(props) {
        super(props)
        this.editor = new EditorExperience();
        this.parentEle = props.dom;

        this.initUI();

        this.activeContentTab = null;

        this.editor.on('sceneHierarchyChanged', this.refreshTabsUI);
        this.editor.on('toggleMenuActive', this.refreshTabWithMenuItem);
        this.editor.on('mountObjectLoader', this.onMountObjectLoader);
        this.editor.on('sidebarUpdateClassList', this.onSidebarUpdateClassList);
    }

    initUI = () => {
        this.uiCont = new UIPanel();
        this.uiCont.addClass('wsSidebarContentsContainer');
        
        this.sidebarObject = new SidebarObject();
        this.sidebarObjOverlay = new SidebarObjectOverlay();
        this.sidebarObjAccess = new SidebarObjectAccesss();
        this.sidebarContent = new SidebarContent();

        this.uiCont.dom.appendChild(this.sidebarObject.dom);
        this.uiCont.dom.appendChild(this.sidebarObjOverlay.dom);
        this.uiCont.dom.appendChild(this.sidebarObjAccess.dom);
        this.uiCont.dom.appendChild(this.sidebarContent.dom);

        this.parentEle.append(this.uiCont.dom);
    }
    

    refreshTabsUI = () => {
        this.uiCont.dom.appendChild(this.sidebarObject.dom);
        this.uiCont.dom.appendChild(this.sidebarObjOverlay.dom);
        this.uiCont.dom.appendChild(this.sidebarContent.dom);
    }

    refreshTabWithMenuItem = (name) => {
        if(name === this.activeContentTab) return;
        this.activeContentTab = name;
    }

    onMountObjectLoader = (bFlag) => {
        if(bFlag) {
            this.uiCont.addClass('wsSidebarContentsContainer--noEvnts');
        } else {
            this.uiCont.removeClass('wsSidebarContentsContainer--noEvnts');
        }
    }

    onSidebarUpdateClassList = (bFlag = false) => {
        if(bFlag) {
            this.uiCont.addClass('wsSidebarContentsContainer--p2015');
        } else {
            this.uiCont.removeClass('wsSidebarContentsContainer--p2015');
        }
    }
}

export { Sidebar };