import EventEmitter from "../../../utils/EventEmitter";
import { UIPanel, UIImage, UITooltip } from "../../../ui/ui.lib";

import NavIcon from '../../../../../assets/svgs/scenes/menu/navigation.svg';

class Menu2DLocation extends EventEmitter {
    constructor(editor, category) {
        super();

        this.editor = editor;
        this.dom = null;
        this.name = 'Location';

        this.isActive = false;
        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('navigationTracking', this.onNavigationTracking);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('ws2DMenuContainer');
        this.uiCont.setId('wsMenuText');
        
        this.uiText = new UITooltip(this.name);
        this.uiImg = new UIImage(NavIcon, '28px', '29px', this.name);

        this.uiCont.addChild(this.uiImg);
        this.uiCont.addChild(this.uiText);

        this.uiCont.onClick(() => {
            this.setActivePin()
        })

        this.dom = this.uiCont.dom;
    }

    setActivePin = () => {
        this.makeActive();
        this.editor.locPinHelper.setUpControls('regular', 'location');
    }

    makeActive = () => {
        if(!this.isActive) {
            this.isActive = true;
            this.uiCont.addClass('ws2DMenuContainer--active');        
        }
        this.editor.trigger('toggleMenuActive', [this.name]);
    }

    onToggleMenuActive = (name) => {
        if(name !== this.name) {
            this.isActive = false;
            this.uiCont.removeClass('ws2DMenuContainer--active');
        }
    }

    onNavigationTracking = (bFlag) => {
        if(bFlag) {
            this.uiCont.addClass('ws2DMenuContainer--disable'); 
        } else {
            this.uiCont.removeClass('ws2DMenuContainer--disable');
        }
    }
}

export { Menu2DLocation };