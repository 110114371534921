import { useSelector } from "react-redux";
import AnchorQRCard from "./AnchorQRCard";
import { FormControlLabel } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { mapDetails } from "../../../../../features/user/studio/studioSlice";
import { useState } from "react";
import { isEmpty } from "lodash";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import * as htmlToImage from "html-to-image";
import { useDispatch } from "react-redux";
import { setNewAlert } from "../../../../../features/common/alertSlice";
import CustomModal from "../common/CustomModal";
import { PDFDownloader } from "../common/PDFDownloader";

const MultiQRViewport = ({ data, downloadProgressModal }) => {
  const anchors = useSelector(mapDetails)?.metadata?.anchors.slice(1);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedQRs, setSelectedQRs] = useState([]);
  const [downloadQRWidth, downloadQRHeight] = [1024, 1024];
  const [downloadSelectedModal, setDownloadSelectedModal] = useState(false);
  const [pngChecked, setpngChecked] = useState(true);
  const [pdfChecked, setpdfChecked] = useState(false);
  const dispatch = useDispatch();

  const downloadSelectedQR = async () => {
    const zip = new JSZip();
    downloadProgressModal.setLoaderModal(true);
    downloadProgressModal.setProgress(0);
    for (let i = 0; i < selectedQRs.length; i++) {
      if (selectedQRs[i].isChecked) {
        let node = document.getElementById(
          `stepper_qrparent_${selectedQRs[i].id?.slice(-6)}`
        );
        node.classList.remove("mapdetails__anchorcardQRBG");
        node.classList.remove("mapdetails__anchorcardDefaultQRBG");
        let qrnode = document.getElementById(`stepper_qrcode_${selectedQRs[i].id?.slice(-3)}`)
        qrnode.classList.remove("stepper__qrparent--qrlogobgunset")
        qrnode.classList.add("stepper__qrparent--qrlogobgwhite")
        await htmlToImage
          .toBlob(node, {
            canvasHeight: downloadQRHeight,
            canvasWidth: downloadQRWidth,
          })
          .then((blob) => {
            if (pdfChecked) {
              blob = PDFDownloader(
                window.URL.createObjectURL(blob),
                `${selectedQRs[i].name}_${selectedQRs[i].id?.slice(-4)}.pdf`,
                downloadQRWidth,
                downloadQRHeight,
                false
              );
            }
            zip.file(
              `${pdfChecked
                ? `${selectedQRs[i].name}_${selectedQRs[i].id?.slice(-4)}.pdf`
                : `${selectedQRs[i].name}_${selectedQRs[i].id?.slice(-4)}.png`
              }`,
              blob,
              { ...(pdfChecked ? { binary: true } : { type: "image/png" }) }
            );
          });
        downloadProgressModal.setProgress(i / selectedQRs.length);
        qrnode.classList.remove("stepper__qrparent--qrlogobgwhite")
        qrnode.classList.add("stepper__qrparent--qrlogobgunset")
        node.classList.add("mapdetails__anchorcardQRBG");
        node.classList.add("mapdetails__anchorcardDefaultQRBG");
      }
    }
    await zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, `${data.mapName}_${pdfChecked ? 'pdf' : 'png'}.zip`);
    });
    dispatch(
      setNewAlert({
        msg: "Selected QR Codes downloaded",
        alertType: "success",
      })
    );
    downloadProgressModal.setProgress(0);
    downloadProgressModal.setLoaderModal(false);
  };

  const generateAnchorQRCards = () => {
    return anchors.filter(anchorObj => anchorObj.isQrCode).map((anchorObj) => (
      <div className="col" key={anchorObj.id} style={{ width: "fit-content" }}>
        <AnchorQRCard
          anchorObj={anchorObj}
          data={data}
          isSelectAll={selectAll}
          setSelectedQRs={(cardDetails) => {
            if (
              isEmpty(
                selectedQRs.filter((QRObj) => QRObj.id === cardDetails.QRID)
              ) ||
              isEmpty(selectedQRs)
            ) {
              setSelectedQRs((prev) => [
                ...prev,
                {
                  id: cardDetails.QRID,
                  elementID: `stepper_qrparent_${cardDetails.QRID?.slice(-6)}`,
                  isChecked: cardDetails.isChecked,
                  name: cardDetails.name,
                },
              ]);
            } else {
              setSelectedQRs((prev) => {
                let IDs = prev.map((QRObj) => {
                  if (QRObj.id === cardDetails.QRID) {
                    let item = { ...QRObj, isChecked: cardDetails.isChecked };
                    return item;
                  }
                  return QRObj;
                });
                return IDs;
              });
            }
          }}
        />
      </div>
    ));
  };

  return (
    <div className="shadow p-3 mb-3 bg-body rounded">
      <div className="d-flex bd-highlight mb-3">
        <div className="p-2 bd-highlight">
          <div className="stepper__SectionHeader pb-4">QR Codes in Map</div>
        </div>
        <div className="ms-auto mt-3 me-2 bd-highlight">
          <div
            id="thumbnail"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectAll(!selectAll);
            }}
          >
            <div className="mapinfo__mapImageControlOverlay">
              <label>
                <span className="mapinfo__mapImageControlOverlay--text">
                  {selectAll ? "Deselect All" : "Select All"}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="row row-cols-1 row-cols-md-4">
        {generateAnchorQRCards()}
      </div>
      {selectedQRs.find((selected) => selected.isChecked) ? (
        <>
          <hr />
          <div className="d-flex justify-content-end">
            <div
              className="ps-3"
              style={{ cursor: "pointer" }}
              onClick={() => { }}
            >
              <div
                className="mapinfo__mapImageControlOverlay m-0"
                onClick={() => setDownloadSelectedModal(true)}
              >
                <label
                  style={{ cursor: "pointer" }}
                  className="dashboardpage__dpIntroCard--button"
                >
                  Download Selected
                </label>
              </div>
            </div>
          </div>
          <hr />
        </>
      ) : null}
      <CustomModal
        openModal={downloadSelectedModal}
        onCloseModal={() => setDownloadSelectedModal(false)}
        onSuccessModal={() => {
          downloadSelectedQR();
          setDownloadSelectedModal(false);
        }}
        modalHeader={`Download Selected QR Codes`}
        textSuccess="Download"
        textCancel="Cancel"
      >
        <label
          className="content-type-select mt-4"
          style={{ lineHeight: "normal", textAlign: "center" }}
        >
          {`You have selected [${selectedQRs.length}] QR codes.`}
          <br />
          How would you like to download them?
        </label>
        <div className="content-type-select mt-4 p-4 pt-0 pb-0">
          <FormGroup>
            <div className="stepper__qrDownloadModal--check">
              <FormControlLabel
                checked={pngChecked}
                onChange={() => {
                  setpngChecked(true);
                  setpdfChecked(false);
                }}
                control={<Checkbox id="checkbox-png" style={{ transform: "scale(1.3)" }} />}
              />
              <span className="stepper__qrDownloadModal--checkLabel"><strong>PNG</strong><br />Transparent background, Non-scalable, for smaller sized physical prints (A4 or Letter sized)</span>
            </div>
            <div className="stepper__qrDownloadModal--check">
              <FormControlLabel
                checked={pdfChecked}
                control={<Checkbox id="checkbox-pdf" style={{ transform: "scale(1.3)" }} />}
                onChange={() => {
                  setpngChecked(false);
                  setpdfChecked(true);
                }}
              />
              <span className="stepper__qrDownloadModal--checkLabel"><strong>PDF</strong><br />Non-scalable for smaller-sized physical prints (A4 or Letter-sized)</span>
            </div>
          </FormGroup>
        </div>
      </CustomModal>
    </div>
  );
};

export default MultiQRViewport;
