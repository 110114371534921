const pins = {
    'elevator': [
        '/static/sprites/elevator/elevator_pin_0.png',
        '/static/sprites/elevator/elevator_pin_1.png',
        '/static/sprites/elevator/elevator_pin_2.png',
        '/static/sprites/elevator/elevator_pin_3.png',
        '/static/sprites/elevator/elevator_pin_4.png',
        '/static/sprites/elevator/elevator_pin_5.png',
        '/static/sprites/elevator/elevator_pin_6.png',
        '/static/sprites/elevator/elevator_pin_7.png',
        '/static/sprites/elevator/elevator_pin_8.png',
        '/static/sprites/elevator/elevator_pin_9.png',
        '/static/sprites/elevator/elevator_pin_10.png',
        '/static/sprites/elevator/elevator_pin_11.png',
        '/static/sprites/elevator/elevator_pin_12.png',
        '/static/sprites/elevator/elevator_pin_13.png',
    ],
    'escalator': [
        '/static/sprites/escalator/escalator_pin_0.png',
        '/static/sprites/escalator/escalator_pin_1.png',
        '/static/sprites/escalator/escalator_pin_2.png',
        '/static/sprites/escalator/escalator_pin_3.png',
        '/static/sprites/escalator/escalator_pin_4.png',
        '/static/sprites/escalator/escalator_pin_5.png',
        '/static/sprites/escalator/escalator_pin_6.png',
        '/static/sprites/escalator/escalator_pin_7.png',
        '/static/sprites/escalator/escalator_pin_8.png',
        '/static/sprites/escalator/escalator_pin_9.png',
        '/static/sprites/escalator/escalator_pin_10.png',
        '/static/sprites/escalator/escalator_pin_11.png',
        '/static/sprites/escalator/escalator_pin_12.png',
        '/static/sprites/escalator/escalator_pin_13.png',
    ],
    'exit': [
        '/static/sprites/exit/exit_pin_0.png',
        '/static/sprites/exit/exit_pin_1.png',
        '/static/sprites/exit/exit_pin_2.png',
        '/static/sprites/exit/exit_pin_3.png',
        '/static/sprites/exit/exit_pin_4.png',
        '/static/sprites/exit/exit_pin_5.png',
        '/static/sprites/exit/exit_pin_6.png',
        '/static/sprites/exit/exit_pin_7.png',
        '/static/sprites/exit/exit_pin_8.png',
        '/static/sprites/exit/exit_pin_9.png',
        '/static/sprites/exit/exit_pin_10.png',
        '/static/sprites/exit/exit_pin_11.png',
        '/static/sprites/exit/exit_pin_12.png',
        '/static/sprites/exit/exit_pin_13.png',
    ],
    'location': [
        '/static/sprites/location/location_pin_0.png',
        '/static/sprites/location/location_pin_1.png',
        '/static/sprites/location/location_pin_2.png',
        '/static/sprites/location/location_pin_3.png',
        '/static/sprites/location/location_pin_4.png',
        '/static/sprites/location/location_pin_5.png',
        '/static/sprites/location/location_pin_6.png',
        '/static/sprites/location/location_pin_7.png',
        '/static/sprites/location/location_pin_8.png',
        '/static/sprites/location/location_pin_9.png',
        '/static/sprites/location/location_pin_10.png',
        '/static/sprites/location/location_pin_11.png',
        '/static/sprites/location/location_pin_12.png',
        '/static/sprites/location/location_pin_13.png',
    ],
    'restroom': [
        '/static/sprites/restroom/restroom_pin_0.png',
        '/static/sprites/restroom/restroom_pin_1.png',
        '/static/sprites/restroom/restroom_pin_2.png',
        '/static/sprites/restroom/restroom_pin_3.png',
        '/static/sprites/restroom/restroom_pin_4.png',
        '/static/sprites/restroom/restroom_pin_5.png',
        '/static/sprites/restroom/restroom_pin_6.png',
        '/static/sprites/restroom/restroom_pin_7.png',
        '/static/sprites/restroom/restroom_pin_8.png',
        '/static/sprites/restroom/restroom_pin_9.png',
        '/static/sprites/restroom/restroom_pin_10.png',
        '/static/sprites/restroom/restroom_pin_11.png',
        '/static/sprites/restroom/restroom_pin_12.png',
        '/static/sprites/restroom/restroom_pin_13.png',
    ],
    'security': [
        '/static/sprites/security/security_pin_0.png',
        '/static/sprites/security/security_pin_1.png',
        '/static/sprites/security/security_pin_2.png',
        '/static/sprites/security/security_pin_3.png',
        '/static/sprites/security/security_pin_4.png',
        '/static/sprites/security/security_pin_5.png',
        '/static/sprites/security/security_pin_6.png',
        '/static/sprites/security/security_pin_7.png',
        '/static/sprites/security/security_pin_8.png',
        '/static/sprites/security/security_pin_9.png',
        '/static/sprites/security/security_pin_10.png',
        '/static/sprites/security/security_pin_11.png',
        '/static/sprites/security/security_pin_12.png',
        '/static/sprites/security/security_pin_13.png',
    ],
    'stairs': [
        '/static/sprites/staircase/staircase_pin_0.png',
        '/static/sprites/staircase/staircase_pin_1.png',
        '/static/sprites/staircase/staircase_pin_2.png',
        '/static/sprites/staircase/staircase_pin_3.png',
        '/static/sprites/staircase/staircase_pin_4.png',
        '/static/sprites/staircase/staircase_pin_5.png',
        '/static/sprites/staircase/staircase_pin_6.png',
        '/static/sprites/staircase/staircase_pin_7.png',
        '/static/sprites/staircase/staircase_pin_8.png',
        '/static/sprites/staircase/staircase_pin_9.png',
        '/static/sprites/staircase/staircase_pin_10.png',
        '/static/sprites/staircase/staircase_pin_11.png',
        '/static/sprites/staircase/staircase_pin_12.png',
        '/static/sprites/staircase/staircase_pin_13.png',
    ],
    'informationreception': [
        '/static/sprites/info/info_pin_0.png',
        '/static/sprites/info/info_pin_1.png',
        '/static/sprites/info/info_pin_2.png',
        '/static/sprites/info/info_pin_3.png',
        '/static/sprites/info/info_pin_4.png',
        '/static/sprites/info/info_pin_5.png',
        '/static/sprites/info/info_pin_6.png',
        '/static/sprites/info/info_pin_7.png',
        '/static/sprites/info/info_pin_8.png',
        '/static/sprites/info/info_pin_9.png',
        '/static/sprites/info/info_pin_10.png',
        '/static/sprites/info/info_pin_11.png',
        '/static/sprites/info/info_pin_12.png',
        '/static/sprites/info/info_pin_13.png',
    ],
    'cafe': [
        '/static/sprites/cafe/cafe_pin_0.png',
        '/static/sprites/cafe/cafe_pin_1.png',
        '/static/sprites/cafe/cafe_pin_2.png',
        '/static/sprites/cafe/cafe_pin_3.png',
        '/static/sprites/cafe/cafe_pin_4.png',
        '/static/sprites/cafe/cafe_pin_5.png',
        '/static/sprites/cafe/cafe_pin_6.png',
        '/static/sprites/cafe/cafe_pin_7.png',
        '/static/sprites/cafe/cafe_pin_8.png',
        '/static/sprites/cafe/cafe_pin_9.png',
        '/static/sprites/cafe/cafe_pin_10.png',
        '/static/sprites/cafe/cafe_pin_11.png',
        '/static/sprites/cafe/cafe_pin_12.png',
        '/static/sprites/cafe/cafe_pin_13.png',
    ],
    'lostfound': [
        '/static/sprites/lost&found/lostfound_pin_0.png',
        '/static/sprites/lost&found/lostfound_pin_1.png',
        '/static/sprites/lost&found/lostfound_pin_2.png',
        '/static/sprites/lost&found/lostfound_pin_3.png',
        '/static/sprites/lost&found/lostfound_pin_4.png',
        '/static/sprites/lost&found/lostfound_pin_5.png',
        '/static/sprites/lost&found/lostfound_pin_6.png',
        '/static/sprites/lost&found/lostfound_pin_7.png',
        '/static/sprites/lost&found/lostfound_pin_8.png',
        '/static/sprites/lost&found/lostfound_pin_9.png',
        '/static/sprites/lost&found/lostfound_pin_10.png',
        '/static/sprites/lost&found/lostfound_pin_11.png',
        '/static/sprites/lost&found/lostfound_pin_12.png',
        '/static/sprites/lost&found/lostfound_pin_13.png',
    ],
    'mensrestroom': [
        '/static/sprites/mens/mens_pin_0.png',
        '/static/sprites/mens/mens_pin_1.png',
        '/static/sprites/mens/mens_pin_2.png',
        '/static/sprites/mens/mens_pin_3.png',
        '/static/sprites/mens/mens_pin_4.png',
        '/static/sprites/mens/mens_pin_5.png',
        '/static/sprites/mens/mens_pin_6.png',
        '/static/sprites/mens/mens_pin_7.png',
        '/static/sprites/mens/mens_pin_8.png',
        '/static/sprites/mens/mens_pin_9.png',
        '/static/sprites/mens/mens_pin_10.png',
        '/static/sprites/mens/mens_pin_11.png',
        '/static/sprites/mens/mens_pin_12.png',
        '/static/sprites/mens/mens_pin_13.png',
    ],
    'womensrestroom': [
        '/static/sprites/womens/womens_pin_0.png',
        '/static/sprites/womens/womens_pin_1.png',
        '/static/sprites/womens/womens_pin_2.png',
        '/static/sprites/womens/womens_pin_3.png',
        '/static/sprites/womens/womens_pin_4.png',
        '/static/sprites/womens/womens_pin_5.png',
        '/static/sprites/womens/womens_pin_6.png',
        '/static/sprites/womens/womens_pin_7.png',
        '/static/sprites/womens/womens_pin_8.png',
        '/static/sprites/womens/womens_pin_9.png',
        '/static/sprites/womens/womens_pin_10.png',
        '/static/sprites/womens/womens_pin_11.png',
        '/static/sprites/womens/womens_pin_12.png',
        '/static/sprites/womens/womens_pin_13.png',
    ],
    'familyrestroom': [
        '/static/sprites/family/family_pin_0.png',
        '/static/sprites/family/family_pin_1.png',
        '/static/sprites/family/family_pin_2.png',
        '/static/sprites/family/family_pin_3.png',
        '/static/sprites/family/family_pin_4.png',
        '/static/sprites/family/family_pin_5.png',
        '/static/sprites/family/family_pin_6.png',
        '/static/sprites/family/family_pin_7.png',
        '/static/sprites/family/family_pin_8.png',
        '/static/sprites/family/family_pin_9.png',
        '/static/sprites/family/family_pin_10.png',
        '/static/sprites/family/family_pin_11.png',
        '/static/sprites/family/family_pin_12.png',
        '/static/sprites/family/family_pin_13.png',
    ],
    'wheelchairaccessiblerestroom': [
        '/static/sprites/wheelchair/wheelchair_pin_0.png',
        '/static/sprites/wheelchair/wheelchair_pin_1.png',
        '/static/sprites/wheelchair/wheelchair_pin_2.png',
        '/static/sprites/wheelchair/wheelchair_pin_3.png',
        '/static/sprites/wheelchair/wheelchair_pin_4.png',
        '/static/sprites/wheelchair/wheelchair_pin_5.png',
        '/static/sprites/wheelchair/wheelchair_pin_6.png',
        '/static/sprites/wheelchair/wheelchair_pin_7.png',
        '/static/sprites/wheelchair/wheelchair_pin_8.png',
        '/static/sprites/wheelchair/wheelchair_pin_9.png',
        '/static/sprites/wheelchair/wheelchair_pin_10.png',
        '/static/sprites/wheelchair/wheelchair_pin_11.png',
        '/static/sprites/wheelchair/wheelchair_pin_12.png',
        '/static/sprites/wheelchair/wheelchair_pin_13.png',
    ]
}

const idNames = {
    'dev': {
        "643fa8fd47ab4a2de3ff7b15": "informationreception",
        "643fa94e47ab4a705cff7b25": "elevator",
        "643fa9b447ab4a94d4ff7b35": "restroom",
        "643fa9db47ab4ab5fcff7b3c": "mensrestroom",
        "645c9cdb9a922afe658792da": "womensrestroom",
        "645c9cfb9a922a900e879304": "restroom", //unisexs
        "645c9e319a922a255c87947f": "familyrestroom",
        "645c9e469a922a214d8794d0": "wheelchairaccessiblerestroom",
        "6459dc03ca02e25597fd379c": "escalator",
        "6459dc1dca02e20e4cfd37a7": "stairs",
        "645c9bb29a922a49398790af": "cafe",
        "645c9bd29a922a1a1d8790c3": "exit",
        "645c9beb9a922a322987912e": "lostfound",
        "645c9c079a922a681b87918b": "security",
    },
    'prod': {
        "649c0a03fa6e60260870cb29": "informationreception",
        "649c0a87fa6e60a6d570cb9b": "elevator",
        "649c0bc3fa6e60069770ce89": "restroom",
        "649c0be7fa6e600f1270cef6": "mensrestroom",
        "649c0c02fa6e60e45970cefd": "womensrestroom",
        "649c0c36fa6e6009f470cf04": "restroom", //unisex
        "649c0c54fa6e6041d170cf0b": "familyrestroom",
        "649c0c7bfa6e607e5670cf12": "wheelchairaccessiblerestroom",
        "649c0a9afa6e60345770cbe2": "escalator",
        "649c0aeefa6e601e2e70cd27": "stairs",
        "649c0b35fa6e60529370cde1": "cafe",
        "649c0ab4fa6e603eb370cc4e": "exit",
        "649c0b5efa6e609f7670ce15": "lostfound",
        "649c0b0cfa6e60029d70cda2": "security",
    }
}

export const pinColors = ['#36CCE7', '#FFE768', '#4A4A4A', '#6689FF', '#BC67FF', '#FF6CA2', '#F166FF', '#CFFF69', '#6BFF7B', '#FF8165', '#FFB16A', '#FF68D5', '#803D00', '#17181C'];

export const getPinColorIndex = (color) => {
    let pinIdx = pinColors.indexOf(color);
    return pinIdx >= 0 ? pinIdx : 0;
}

export const getTypeFromName = (name) => {
    let bFlag = false;
    let type = 'location';
    if (!name) return type;

    let tName = name.replace(/[^a-zA-Z0-9 ]/g, '').toLowerCase().replace(/\s/g, '');
    for (const t in pins) {
        if (t.match(tName) && !bFlag) {
            bFlag = true;
            type = t;
        } else if (tName === 'unisexgenderneutralrestroom') {
            bFlag = true;
            type = 'restroom';
        }
    }

    return type;
}

export const getTexNameFromId = (id) => {
    const prod = process.env.REACT_APP_NODE_ENV === "production";
    const envType = prod ? 'prod' : 'dev';
    return idNames[envType][id] || 'location';
}

export const getAmenityTypeFromId = (aCategories, id, needsParent = true) => {
    let obj = null;

    // console.log(aCategories, id);

    aCategories?.forEach(cat => {
        if (cat.id === id) {
            obj = cat;
        } else if (cat.children) {
            cat.children.forEach(child => {
                if (child.id === id) {
                    needsParent ? (obj = cat) : (obj = child)
                }
            })
        }
    })

    return obj;

}

export const getPinSpriteTexture = (type = 'location', index = 0) => {
    return type in pins ? pins[type][index] : pins.location[index];
}