import React from "react";
import { Switch } from '@mui/material';
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
    root: {
        width: "50px !important",
        height: "24px !important",
        padding: "0px !important"
    },
    switchBase: {
        color: "#6C707D !important",
        padding: "0px !important",
        "&$checked": {
        "& + $track": {
            backgroundColor: "#2A4DC2 !important"
        }
        }
    },
    thumb: {
        color: "white !important",
        width: "22px !important",
        height: "21px !important",
        margin: "1.5px !important"
    },
    track: {
        borderRadius: "20px !important",
        backgroundColor: "#6C707D !important",
        opacity: "1 !important",
        "&:after, &:before": {
            color: "white !important",
            fontSize: "10px !important",
            position: "absolute !important",
            top: "20% !important",
        },
        "&:after": {
        content: "'ON'",
            left: "5px !important"
        },
        "&:before": {
            content: "'OFF'",
            right: "5px !important"
        }
    },
    checked: {
        color: "#2A4DC2 !important",
        transform: "translateX(26px) !important"
    }
});

export default function Switches({ enabled, onEnableToggle, disabled = false, id = null}) {
    const classes = useStyles();

    return (
        <div>
            <Switch
                classes={{
                    root: classes.root,
                    switchBase: classes.switchBase,
                    thumb: classes.thumb,
                    track: classes.track,
                    checked: classes.checked
                }}
                disabled={disabled}
                checked={enabled}
                onChange={onEnableToggle}
                name="checkedA"
                inputProps={{ "aria-label": "secondary checkbox" }}
                id={id || "checkbox-toggle"}
                value={enabled}
            />
        </div>
    );
}
