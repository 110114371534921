import * as THREE from 'three';
import EditorExperience from '../EditorExperience';
import { 
    fromPosObjectToVec3Pos,
    fromScaleObjectToVec3Scale,
    fromQuaternionObjectToQuaternion
} from './TransformConversions';

export default  class MapMarker {
    constructor(markerData) {
        this.markerData = markerData;

        this.editor = new EditorExperience();

        this.name = markerData.name
        this.description = markerData.description || ""
        this.id = markerData.id

        this.position = markerData.position ? fromPosObjectToVec3Pos(markerData.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = markerData.rotation ? fromQuaternionObjectToQuaternion(markerData.rotation) : new THREE.Quaternion(0, 0, 0, 0);
        this.scale = markerData.scale ? fromScaleObjectToVec3Scale(markerData.scale) : new THREE.Vector3(1, 1, 1);
        
        this.visible = 'visible' in markerData ? markerData.visible : false;
        this.pathColor = 'pathColor' in markerData ? markerData.pathColor : '#36CCE7';

        this.mesh = {};

        this.editor.jsonMapMarkers.push(markerData)

        this.editor.on('objectChanged', this.onObjectChanged);

        this.setUpMarker();

        this.editor.markers.push(this);
    }

    setUpMarker = () => {

        this.mesh = this.editor.markerPin.clone();
        this.mesh.position.copy(this.position);
        // this.mesh.quaternion.copy(this.rotation);
        this.mesh.scale.set(0.7, 0.7, 0.7);
        this.mesh.name = this.name;
        this.mesh.userData['description'] = this.description;
        this.mesh.userData['type'] = 'mapMarker';
        this.mesh.userData['skipChild'] = true;
        this.mesh.userData['pathColor'] = this.pathColor;
        this.mesh.userData['transformation'] = "NO_TRANSFORM"; //only to hide transformation controls in edit mode
        this.mesh.userData['visible'] = this.visible;
        this.mesh.visible = this.visible;
        this.mesh.userData['id'] = this.id;

        this.updateMaterialColor();
    }

    onObjectChanged = (object, a, b, isView = false) => {
        if(this.mesh === object) {
            this.name = object.name;
            this.description = object.userData.description;
            this.position.copy(object.position);
            this.pathColor = object.userData.pathColor;
            this.updateJSONObject(object, isView);
            this.updateMaterialColor();
        }
    }

    hexToDec = (hex) => parseInt(hex.substring(1), 16)

    updateMaterialColor = () => {
        const newColorMat = new THREE.MeshStandardMaterial().copy(this.mesh.children[1].material);
        newColorMat.color.setHex(this.hexToDec(this.pathColor), 'srgb-linear')

        for(var i = 0; i < this.mesh.children.length; i++) {
            if(this.mesh.children[i].name.includes('_Mat')) {
                this.mesh.children[i].material = newColorMat;
                this.mesh.children[i].material.needsUpdate = true;
            }
        }
    }

    updateJSONObject = (object, isView) => {
        const idx = this.editor.getIndex('jsonMapMarkers',this.id);
        let reqObj = {...this.editor.jsonMapMarkers[idx]}
        reqObj.name = object.name;
        reqObj.description = object.userData.description;
        reqObj.pathColor = object.userData.pathColor;
        
        this.editor.jsonMapMarkers[idx] = reqObj;
        !isView && this.editor.toAutoSave && this.editor.trigger('autoSaveSceneGraphState');
    }

}
