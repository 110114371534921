import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { isEmpty, isNull, isUndefined } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import DatePicker from "../../../../../../_utils/DatePicker";
import { getUserAccountTopFiveVisited } from "../../../../../../../features/admin/analytics/adminAnalyticsSlice";
import { lastTEDays } from "../../../../../../_utils/DateUtils";
import RenderTable from "../../../../../users/analytics/Render/RenderTable";
import Loader from "../../../../../../common/loader";
import { MapAreaUnit } from "../MapAreaUnit";


const topFiveVisitedHeaders = [
    { key: "rank", label: "Rank" },
    { key: "mapName", label: "Map Name" },
    { key: "mapVisits", label: "Visits" },
    { key: "mapArea", label: "Map Area" },
    { key: "growth", label: "Growth" },
    { key: "country", label: "Country" },
];

const AccountTopFive = () => {
    const dispatch = useDispatch();

    const selectedAccount = useSelector((state) => state.adminanalytics.activeAccount);
    const [dateObj, setDateObj] = useState(lastTEDays);
    const [loading, setLoading] = useState(true);
    const [areaSQFt, setAreaSQFt] = useState(true);

    const topFiveVisited = useSelector((state) => state.adminanalytics.topFiveVisited);

    useEffect(() => {
        dispatch(getUserAccountTopFiveVisited({ limit: 5, ...dateObj, accountId: selectedAccount?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(selectedAccount && selectedAccount.id !== 'allAccounts') {
            setLoading(true);
            setDateObj(lastTEDays)
            dispatch(getUserAccountTopFiveVisited({ limit: 5, ...lastTEDays, accountId: selectedAccount?.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);

    useEffect(() => {
        if (topFiveVisited && loading) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topFiveVisited]);

    const getRowData = (data) => {
        return data.map(d => {
            return{
                rank: d.rank,
                mapName: d.mapName,
                mapVisits: d.mapVisits,
                mapArea: d.mapArea ? areaSQFt ? (d.mapArea) : (d.mapArea / 10.764).toFixed(2) : '--',
                growth: d.growth,
                country: d.country,
            }
        })
    }

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col-9">
                        <div className="analyticshome__anCard--contentRow--header">
                            Top 5 Most Visited Map
                        </div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker
                                cb={(dateObj) => {
                                    setDateObj(dateObj);
                                    setLoading(true);
                                    dispatch(getUserAccountTopFiveVisited({ limit: 5, ...dateObj, accountId: selectedAccount?.id }));
                                }}
                            />
                        </Box>
                    </div>
                </div>
                <hr />
                
                {((isEmpty(topFiveVisited) || isNull(topFiveVisited) || isUndefined(topFiveVisited)) && !loading) ?
                    (
                        <h5 className="text-center analyticshome__emptyStateText pt-3">
                            No Data available
                        </h5>
                    ) : loading ? (
                        <h5 className="text-center analyticshome__emptyStateText pt-3">
                            <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                        </h5>
                    ) : (
                        <>
                            <MapAreaUnit
                                areaSQFt={areaSQFt}
                                setAreaSQFt={setAreaSQFt}
                            />
                            <RenderTable
                                thheaderData={topFiveVisitedHeaders}
                                rowsData={getRowData(topFiveVisited)}
                            />
                        </>
                        
                    )}
            </div>
        </div>
    );
};

export default AccountTopFive;
