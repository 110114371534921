import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MapVisitsOvertimeByLocation from "./AllMaps/MapVisitsOvertimeByLocation";
import TopVisitedMapsCard from "./AllMaps/TopVisitedMapsCard";
import HighestPerformingMapsbyLocation from "./AllMaps/HighestPerformingMapsbyLocation";
import {
  getRegions,
  getLocationList,
  getLocationPopularSearches,
} from "../../../../../features/user/analytics/analyticsSlice";

import Location from "./IndividualMaps/Location";
import RenderTableCard from "../Render/RenderTableCard";
import HeatmapCard from "./IndividualMaps/HeatmapCard";
import AccessPointsWithMostActivationByMap from "./IndividualMaps/AccessPointsWithMostActivationByMap";

const TopvisitedMaps = (props) => {
  const dispatch = useDispatch();

  const selectedMap = useSelector((state) => state.analytics.selectedMap);

  useEffect(() => {
    dispatch(getRegions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {selectedMap?.mapId === "allMaps" ? (
        <div>
          <TopVisitedMapsCard
            topVisitedMapsCard={props.analytics.topVisitedMapsCard}
            topVisitedMapsMetaCard={props.analytics.topVisitedMapsMetaCard}
          />
          <MapVisitsOvertimeByLocation
            totalMapVisitsOverTimeByLocation={
              props.analytics.totalMapVisitsOverTimeByLocation
            }
          />
          <HighestPerformingMapsbyLocation
            highestPerformingMapsbyLocation={
              props.analytics.highestPerformingMapsbyLocation
            }
          />
        </div>
      ) : (
        <div>
          <Location />
          <AccessPointsWithMostActivationByMap />
          <RenderTableCard
            title={"Most Navigated To"}
            name={"topFiveLocationList"}
            tableHeaders={[
              { key: "rank", label: "Rank" },
              { key: "location", label: "Locations" },
              { key: "timespent", label: "Time spent at location" },
              { key: "growth", label: "Growth" },
              { key: "noOfNavigation", label: "# of navigations" },
            ]}
            isDateFieldRequired={true}
            actioncb={(args) => {
              dispatch(
                getLocationList({
                  ...args,
                  limit: 5,
                  mapId: selectedMap?.mapId,
                })
              );
            }}
          />
          <RenderTableCard
            title={"Most Popular Searches"}
            name={"locationPopularSearches"}
            tableHeaders={[
              { key: "rank", label: "Rank" },
              { key: "searchQuery", label: "Search Query" },
              { key: "growth", label: "Growth" },
              { key: "noOfSearches", label: "# of searches" },
            ]}
            isDateFieldRequired={true}
            actioncb={(args) => {
              dispatch(
                getLocationPopularSearches({
                  ...args,
                  mapId: selectedMap?.mapId,
                })
              );
            }}
          />
          <RenderTableCard
            title={"Location List"}
            name={"locationList"}
            tableHeaders={[
              { key: "rank", label: "Rank" },
              { key: "location", label: "Location Pin" },
              { key: "timespent", label: "Time spent at location" },
              { key: "growth", label: "Growth" },
              { key: "noOfNavigation", label: "# of navigations" },
            ]}
            isDateFieldRequired={true}
            isPaginationRequired={true}
            actioncb={(args) => {
              dispatch(
                getLocationList({
                  ...args,
                  mapId: selectedMap?.mapId,
                })
              );
            }}
          />
          <HeatmapCard />
        </div>
      )}
    </div>
  );
};

export default TopvisitedMaps;
