import * as THREE from 'three';

import { 
    fromPosObjectToVec3Pos,
    fromQuaternionObjectToQuaternion
} from '../TransformConversions';

class AnchorPoint{
    constructor(anchorProps) {
        this.anchorProps = anchorProps;
        this.position = anchorProps.position ? fromPosObjectToVec3Pos(anchorProps.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = anchorProps.rotation ? fromQuaternionObjectToQuaternion(anchorProps.rotation) : new THREE.Quaternion(0, 0, 0, 0);
        this.scale = new THREE.Vector3(0.5, 0.5, 0.5);
        this.pointArray = [-anchorProps.position.posX, anchorProps.position.posY, anchorProps.position.posZ];
        this.thumbnailURL = anchorProps.anchorthumbnail
    }
    // TODO: Methods to update & fetch
}

export { AnchorPoint };