import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//MUi
import { Grid, TextField } from "@mui/material";
//COmponents
import Loader from "../../../../common/loader";
//Actions
import {
  getTeamMembers,
  addNewTeamMember,
} from "../../../../../features/user/settings/settingsSlice";

//Icons
import { Plus } from "react-feather";

import MemberCard from "./MemberCard";
import MessageModal from "../../../../common/modal";

const TeamHome = () => {
  const dispatch = useDispatch();

  const team = useSelector((state) => state.settings.teamMembers);
  const settingsLog = useSelector((state) => state.settings.settingsLog);

  const [teamMembers, setTeamMembers] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [newRole, setNewRole] = useState("");
  const [createModal, setCreateModal] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFetching(true);
    dispatch(getTeamMembers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (team) {
      setFetching(false);
      setTeamMembers(team);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  useEffect(() => {
    if (settingsLog === "success") {
      setFetching(true);
      dispatch(getTeamMembers());

      setNewEmail("");
      setNewRole("");
      setErrors({});
      setCreateModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsLog]);

  const toggleCreateModal = () => {
    setCreateModal(!createModal);
    setNewEmail("");
    setNewRole("");
    setErrors({});
  };

  useEffect(() => {
    createModal && validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newEmail, newRole]);

  const validateForm = () => {
    let error = errors;
    let isValid = true;
    if (newEmail.trim().length === 0) {
      error["newEmail"] = "Email shouldn't be empty!";
      isValid = false;
    } else if (
      !String(newEmail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      error["newEmail"] = "Enter valid Email Address!";
      isValid = false;
    } else {
      delete error["newEmail"];
    }

    setErrors(error);
    return isValid;
  };

  const handleAddNewMember = (e) => {
    e.preventDefault();
    if (validateForm()) {
      const reqObj = {
        email: newEmail,
        role: "user",
      };
      dispatch(addNewTeamMember(reqObj));
    }
  };

  const renderTeamMembers = () => {
    return teamMembers.map((member, index) => (
      <React.Fragment key={index}>
        <MemberCard member={member} index={index} />
      </React.Fragment>
    ));
  };

  if (fetching || teamMembers === null) {
    return (
      <Loader
        loaderText="Fetching Members List..."
        height="100%"
        width="100%"
      />
    );
  }

  return (
    <div className="settingshome">
      <div className="settingshome__teamlistContainer">
        {renderTeamMembers()}
        <button className="settingshome__teambtn" onClick={toggleCreateModal}>
          <Plus
            size={20}
            color="#ffffff"
            className="settingshome__teambtn--icon"
          />
          Add More Team Members
        </button>
      </div>
      <MessageModal openModal={createModal} onCloseModal={toggleCreateModal}>
        <form onSubmit={handleAddNewMember} className="settingshome__tmAddForm">
          <span className="settingshome__tmafHeader">Add a team member</span>
          <Grid container={true} className="settingshome__tmafGrid">
            <Grid item xs={9}>
              <TextField
                label="Email Address"
                id="newEmail"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
                size="small"
                fullWidth
                className="settingshome__acDetailsCard--formControl settingshome__acDetailsCard--formControl--active"
                autoComplete="off"
              />
              {errors["newEmail"] ? (
                <span className="settingshome__acDetailsCard--errorMsgAbs">
                  {errors.newEmail}
                </span>
              ) : null}
            </Grid>
            <Grid item xs={3}>
              <button
                className="settingshome__tmafBtn"
                type="submit"
                disabled={Object.keys(errors).length > 0}
              >
                Send Invite
              </button>
            </Grid>
          </Grid>
        </form>
      </MessageModal>
    </div>
  );
};

export default TeamHome;
