import React from 'react';
import { Box } from '@mui/material';
import { Check, X } from 'react-feather';

const FeaturesCard = ({ myFeatures }) => {
    return (
        <Box className='accesshome__accessCard accesshome__accessCard--cardDash'>
            <div className='accesshome__accessCardHeaderCont'>
                <span className='accesshome__accessCardHeader'>My Features</span>
                <span className='settingshome__mpHeaderCont--planNameSmall'>{myFeatures.planName}</span>
            </div>
            <div className='accesshome__accessCardFeatures'>
                <div className='accesshome__acFeatureCont'>
                    <Check size={25} color="#2A4DC2" strokeWidth={1.5} className='accesshome__acFeatureIcon' />
                    <span className='accesshome__acFeatureText'>{myFeatures.planStorageLimit}MB of storage space</span>
                </div>
                <div className='accesshome__acFeatureCont'>
                    <Check size={25} color="#2A4DC2" strokeWidth={1.5} className='accesshome__acFeatureIcon' />
                    <span className='accesshome__acFeatureText'>Upto {myFeatures.planApiLimit} map views</span>
                </div>
                <div className='accesshome__acFeatureCont'>
                    <Check size={25} color="#2A4DC2" strokeWidth={1.5} className='accesshome__acFeatureIcon' />
                    <span className='accesshome__acFeatureText'>Upload custom 3D models</span>
                </div>
                <div className='accesshome__acFeatureCont'>
                    {!myFeatures.watermark ?
                        <Check size={25} color="#2A4DC2" strokeWidth={1.5} className='accesshome__acFeatureIcon' /> :
                        <X size={25} color="#BD0101" strokeWidth={1.5} className='accesshome__acFeatureIcon' />}
                    <span className='accesshome__acFeatureText'>Custom Branding</span>
                </div>
            </div>
        </Box>
    )
}

export default FeaturesCard;