
import React from 'react';
import { ExternalLink } from 'react-feather';
import { getRandomIdCode } from '../../../../../webstudio/threeUtils/TransformConversions';

export default function ImpUserTab({
    label,
    path,
    accountDetails
}) {

    const handleImpersonation = () => {
        let updatedAccount = {
            ...accountDetails,
            toNavigate: path
        }
        const userKey = getRandomIdCode();
        let impState = localStorage.getItem("impersonateState");
        if(impState === null) {
            //EMPTY
            impState = {}
            impState[userKey] = updatedAccount;
            localStorage.setItem('impersonateState', JSON.stringify(impState));           
        } else {
            impState = JSON.parse(impState);
            impState[userKey] = updatedAccount;
            localStorage.setItem('impersonateState', JSON.stringify(impState));          
        }
        // Now open
        window.open(`/impersonate/${userKey}`, '_blank');
    }

    return (
        <div className='maccounts__impUserHome'>
            <button className='maccounts__impUserHome--btn' onClick={handleImpersonation}>
                <ExternalLink size="18" color='white'/>
                {label}
            </button>
        </div>
    )
}
