import * as THREE from 'three';
import EventEmitter from '../../utils/EventEmitter';
import EditorExperice from '../../EditorExperience';
import { PathNode } from './PathNode';
import { PathEdge } from './PathEdge';
import { fromPosObjectToVec3Pos, fromVec3PosToPosObject } from '../TransformConversions';
import { RemoveObject } from '../../commands/RemoveObject';

class PathGraph extends EventEmitter {

    constructor(pathData) {
        super();

        // console.log(pathData);
        this.editor = new EditorExperice();

        this.id = pathData.id;
        this.name = pathData.name || `path_${pathData.id}`
        this.type = pathData.pathType;

        this.pathColor = this.type === 'walkable' ? '#FFDC26' : '#2A4DC2';
        this.visible = pathData.visible || true;
        this.pathStroke = pathData.pathStroke || 4;

        this.nodes = new Map();
        this.nodePath = pathData.nodePath || [];

        this.mesh = new THREE.Group();
        this.mesh.name = this.name;
        this.mesh.userData['id'] = this.id;
        this.mesh.userData['type'] = this.type;
        this.mesh.userData['transformation'] = "NO_TRANSFORM"; //only to hide transformation controls in edit mode
        this.mesh.userData['skipChild'] = true;
        this.mesh.userData['visible'] = this.visible;
        this.mesh.userData['interactive2D'] = true;
        this.mesh.userData['excludeArea'] = true;
        this.mesh.visible = this.visible;

        this.mesh.position.y = this.mesh.position.y >= 0 ? this.mesh.position.y + 0.01 : this.mesh.position.y - 0.01;

        pathData?.nodes && this.setNodes(pathData.nodes);

        // add Paths to json array
        this.editor.jsonNavPaths.push({...pathData});

        // remove listener
        this.editor.on('removePathsFromScene', this.onRemovePathsFromScene);
        this.editor.on('objectRemoved', this.onObjectRemoved);
        this.editor.on('updateObject3DPositionsWithNewFloorMatrix', this.onUpdateObject3DPositions);
        this.editor.on('callDestructor', this.onCallDestructor);

    }

    // add node into graph
    addNode = (id, data) => {
        if (this.nodes.has(id)) {
            return this.nodes.get(id);
        }

        const node = new PathNode(data, this.pathColor, this.pathStroke);
        this.nodes.set(id, node);
        // in 3d too ! no node mesh!
        this.mesh.add(node.nodeMesh);
        return node;
    }

    getNode = (id) => {
        if (this.nodes.has(id)) {
            return this.nodes.get(id);
        }
        return null;
    }

    addEdge(srcId, destId) {
        const srcNode = this.getNode(srcId);
        const destNode = this.getNode(destId);

        if(srcNode && destNode) {
            srcNode.addAdjacent(destNode);
            destNode.addAdjacent(srcNode);
    
            return [srcNode, destNode];
        }
        return [];
    }

    // 3D functions
    setNodes(nodes) {
        // set nodes first
        nodes.forEach((node) => {
            this.addNode(node.id, node);
        })
        // add edges frm backend / auto 
        nodes.forEach((node, idx) => {
            if('adjacentNodes' in node && node.adjacentNodes.length) {
                node.adjacentNodes.forEach(aNode => this.addEdge(node.id, aNode));
            } else {
                if(idx > 0) {
                    this.addEdge(node.id, nodes[idx - 1].id);
                }
            }
        })
        // setEdges and draw them!
        this.setEdges();
    }

    setEdges = () => {
        Array.from(this.nodes.values()).forEach(node => {
            node.adjacents.forEach(adNode => {
                if(!node.edgesDrawn.has(adNode.id) && !adNode.edgesDrawn.has(node.id)) {
                    let edge = new PathEdge({
                        vertices: [node.position.clone(), adNode.position.clone()],
                        pathColor: this.pathColor,
                        pathStroke: this.pathStroke,
                    })

                    node.edgesDrawn.set(adNode.id, edge);
                    adNode.edgesDrawn.set(node.id, edge);

                    this.mesh.add(edge.mesh);
                }
            })
        })
    }

    calculateDistance = (ptStart, ptEnd) => {
        const vec1 = ptStart.position.clone();
        const vec2 = ptEnd.position.clone();
        let lineDistance = vec1.distanceTo(vec2);
        let points = [];

        points.push({position: fromVec3PosToPosObject(ptStart.position.clone())});

        while(lineDistance.toFixed(2) > 1.00) {
            let subV = new THREE.Vector3();
            let vecPrev = fromPosObjectToVec3Pos(points[points.length - 1].position);
            subV.subVectors(vec2, vecPrev).setLength(1).add(vecPrev);

            points.push({ position: fromVec3PosToPosObject(subV.clone()) });
            lineDistance = subV.distanceTo(vec2);
        }
        // lineDistance.toFixed(2) < 1.00 && points.push({ position: fromVec3PosToPosObject(vec2.clone())});

        return([...points])
    }

    // helper functions!
    computeNodePath = () => {
        let pathNodes = [];

        // if all nodes are deleted then just update empty node path!
        if(!Array.from(this.nodes.values()).length) {
            this.nodePath = 0;
            return;
        }
        // BFS to traverse!
        let toVisitQueue = [Array.from(this.nodes.values())[0].id];
        let visited = {};

        while(toVisitQueue.length) {
            const startPt = this.nodes.get(toVisitQueue.shift())
            const adjacentes = startPt.getAdjacents();
            adjacentes.forEach(adNode => {
                if(!visited[adNode.id]) {
                    visited[adNode.id] = true;
                    // compute node paths
                    pathNodes.push(...this.calculateDistance(startPt, adNode))
                    // add to visit Next for adjacents
                    toVisitQueue.push(adNode.id);
                }
            })
        }
        this.nodePath = [...pathNodes];
    }

    computeUpdatedNodePositions = () => {
        Array.from(this.nodes.values()).forEach( node => node.computeTransformed3D())
    }

    onUpdateObject3DPositions = () => {
        const idx = this.editor.getIndex('jsonNavPaths', this.id);
        if(idx === -1) return;
        this.computeUpdatedNodePositions();
        this.computeNodePath();
        const { id, nodes, nodePath } = this;
        const updateNodes = Array.from(nodes.values());
        let reqObj = {
            id: id, 
            nodes: updateNodes.map( node => {
                return {
                    id: node.id, 
                    position2d: {posX: node.position2d.x, posY: Math.abs(node.position2d.y)},
                    position: fromVec3PosToPosObject(node.position),
                }
            }),
            nodePath: nodePath,
        }

        this.editor.pathReadjustObj.push(reqObj);
        this.editor.trigger('requestPinPathReadjustment');
    }

    toJSON = () => {
        this.computeNodePath();
        return {
            id: this.id,
            pathStroke: this.pathStroke,
            pathType: this.currentType,
            nodes: Array.from(this.nodes.values()).map(node => {
                return {
                    id: node.id,
                    position2d: {posX: node.position2d.x, posY: Math.abs(node.position2d.y)},
                    position: fromVec3PosToPosObject(node.position),
                    adjacentNodes: Array.from(node.adjacents.values()).map( ad => ad.id),
                }
            }),
            nodePath: this.nodePath,
        }
    }

    onRemovePathsFromScene = () => {
        this.editor.onCommand(new RemoveObject(this.editor, this.mesh, true), 'RESTRICT_UNDO');
    }

    onObjectRemoved = (object) => {
        if(this.mesh === object) {
            const idx = this.editor.getIndex('jsonNavPaths', this.id);
            this.editor.jsonNavPaths.splice(idx, 1);
            this.onCallDestructor(object);
        }
    }

    onCallDestructor = (object) => {
        if(object === this.mesh) {
            this.editor.stop('removePathsFromScene', this.onRemovePathsFromScene);
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            this.editor.stop('updateObject3DPositionsWithNewFloorMatrix', this.onUpdateObject3DPositions);
            this.editor.stop('callDestructor', this.onCallDestructor);
        }
    }
}

export default PathGraph;