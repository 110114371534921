import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    TableContainer,
    Table, TableHead,
    TableRow, TableCell,
    TableBody, Container
} from '@mui/material';
import {
    Search, X
} from 'react-feather';
import { convertToDate } from '../../../_utils/dateFormatter';
import { makeStyles } from '@mui/styles';
import { ChevronLeft } from 'react-feather';
import {
    getAccountsByPlan, searchAccountsByPlan,
    clearSearchedAccounts, resetPlanAccountList
} from '../../../../features/admin/plans/plansSlice';

import Loader from '../../../common/loader';
import RenderPagination from '../../users/analytics/Render/RenderPagination';
import GenericSwitch from '../../../common/toggleSwitch/GenericSwitch';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderSpacing: '0px 15px',
            borderCollapse: 'separate',
            // border: "transparent",
        },
        "& tr": {
            backgroundColor: 'white',
            boxShadow: '0px 2px 16px 0px #28293D14',
            padding: '6px 0px !important'
        }
    }
});

export default function UserTable({ planInfo, handleBack }) {

    const classes = useStyles();
    const dispatch = useDispatch();

    const [accounts, setAccounts] = useState([]);
    const [searchedAccounts, setSearchedAccounts] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [loading, setLoading] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [areaSQFt, setAreaSQFt] = useState(true);

    const pageSize = 10;

    const planAccountList = useSelector(state => state.plans.planAccountList);
    const totalResults = useSelector((state) => state.plans.totalResults);
    const searchedAccountList = useSelector((state) => state.plans.searchedAccountList);

    useEffect(() => {
        if (planInfo) {
            setFetching(true);
            setAccounts([]);
            getAccounts();
        }
        return (() => {
            setAccounts([]);
            dispatch(resetPlanAccountList());
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (planAccountList) {
            setFetching(false);
            setAccounts(planAccountList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planAccountList]);

    useEffect(() => {
        setFetching(true);
        getAccounts()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        setSearchedAccounts(searchedAccountList);
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchedAccountList]);

    useEffect(() => {
        if (searchText.length >= 3) {
            searchUsersNow();
        } else if (searchText.length <= 0) {
            handleClearSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    const searchUsersNow = () => {
        setLoading(true);
        dispatch(searchAccountsByPlan({ searchText, pageSize, pageNumber: 1, planId: planInfo.id }))
    }

    const handleSearchChange = (e) => {
        if (e.target.value.includes(" ") && e.target.value.length === 1) {
            e.target.value = e.target.value.replace(/\s/g, "");
        }

        const sText = e.target.value;
        var filter = /^([\w,:\s@.\-_]*)$/;
        let filteredSearchText = [...sText].map(c => filter.test(c) ? c : '').join('');
        setSearchText(filteredSearchText);
    }

    const handleClearSearch = () => {
        setSearchText('');
        setLoading(false);
        dispatch(clearSearchedAccounts());
    }

    const getAccounts = () => dispatch(getAccountsByPlan({ pageSize, pageNumber, planId: planInfo.id }));

    const handleChange = (e, page) => {
        setPageNumber(page);
    }

    var crossClass = "searchbar__closeIcon--hide";
    if (searchText.length > 0) {
        crossClass = "";
    }

    const columns = [
        { id: 'email', label: 'Account', minWidth: 200, isName: true },
        { id: 'createdDate', label: 'Created On', minWidth: 100, isTime: true },
        { id: 'lastActivityDate', label: 'Last Edited', minWidth: 100, isTime: true },
        // { id: 'planName', label: 'Plan', minWidth: 100 },
        { id: 'totalMapSize', label: 'Map Area', minWidth: 100, isArea: true, unit: areaSQFt ? 'SqFt' : 'SqM' },
        { id: 'storageUsed', label: 'Storage Used', minWidth: 100, isNumber: true, unit: 'MB' },
        { id: 'averageViews', label: 'Avg. Map Views', minWidth: 100, isNumber: true, },
    ]

    const renderNameDiv = (row) => {
        return (
            <div className='maccounts__tableName'>
                <span className='maccounts__tableName--name'>{row.name || '--'}</span>
                <span className='maccounts__tableName--email'>{row.email}</span>
            </div>
        )
    }

    const renderTableData = (data) => {
        return (
            <TableContainer className={classes.table} style={{ backgroundColor: 'inherit' }} /* sx={{ maxHeight: 550 }} */>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={'left'}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <span className='maccounts__tableHeader'>{column.label}</span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((account) => {
                            return (
                                <TableRow hover sx={{ m: 6 }} key={account.id}>
                                    {columns.map(col => {
                                        const value = account[col.id]
                                        return (
                                            <TableCell key={col.id} align={'left'} className='maccounts__tableCell'>
                                                {
                                                    col.isName ? renderNameDiv(account) :
                                                        col.isTime ? value ? convertToDate(value) : '- -' :
                                                            col.isNumber ? value !== null ? `${parseFloat(value).toFixed(2)} ${col.unit ? col.unit : ''}` : '- -' :
                                                                col.isArea ? value !== null ? `${areaSQFt ? Math.round(value * 10.764) : Math.round(value)} ${col.unit ? col.unit : ''}` : '- -' :
                                                                    <span className='maccounts__tableText'>{value}</span>
                                                }
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const renderTable = () => {
        if ((fetching && accounts.length === 0) || loading) {
            return <Loader loaderText={loading ? "Loading More Accounts..." : "Fetching Accounts..."} height="500px" width="100%" />
        }
        if (accounts.length === 0) {
            return (
                <div className="illustration-container" style={{ height: '500px' }}>
                    <h4>No Accounts Available!</h4>
                </div>
            )
        } else if (searchedAccounts && searchedAccounts.length > 0) {
            return renderTableData(searchedAccounts);
        } else if (searchText.length >= 3 && (!searchedAccounts || searchedAccounts.length <= 0)) {
            return (
                <div className="illustration-container" style={{ height: '500px' }}>
                    <h4>No matches found.</h4>
                </div>
            );
        } else {
            return (
                <div>
                    {renderTableData(accounts)}
                    <RenderPagination
                        count={Math.ceil(totalResults / pageSize)}
                        page={pageNumber}
                        limit={pageSize}
                        handleChange={handleChange}
                        cb={(page) => {
                            setPageNumber(page)
                        }}
                        totalResults={totalResults}
                    />
                </div>
            );
        }
    }

    return (
        <div className="pmhome">
            <Container maxWidth={false} >
                <div onClick={handleBack} className='pmhome__backBtn'>
                    <ChevronLeft size={18} /> Back to All Plans
                </div>
                <div className="pageWrapper">
                    <h4>Accounts in {planInfo.name}</h4>
                </div>
                <div className="serachContRow mt-3">
                    <div className="searchbar searchbar--padded" style={{ width: "50%" }}>
                        <span className="searchbar__searchbarContainer">
                            <Search
                                size={20}
                                color="#353E5A"
                                className="searchbar__searchIcon"
                            />
                            <input
                                type="text"
                                autoComplete="off"
                                placeholder="Search with account email address"
                                value={searchText}
                                onChange={handleSearchChange}
                                id="input-adminAnalyticsSearch"
                            />
                            <X
                                size={20}
                                color="#353E5A"
                                onClick={handleClearSearch}
                                className={`searchbar__closeIcon ${crossClass}`}
                            />
                        </span>
                    </div>
                    {(!loading && !fetching && ((accounts.length && !searchText.length) || searchedAccounts.length)) ?
                        <div className='mapAreaUnitDiv'>
                            <div className='mapAreaUnitDiv__header'>Map Area Unit: </div>
                            <div className='mapAreaUnitDiv__toggleCont'>
                                <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Mtr</div>
                                <GenericSwitch
                                    enabled={areaSQFt}
                                    onEnableToggle={(e) => setAreaSQFt(e.target.checked)}
                                />
                                <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Ft</div>
                            </div>
                        </div>
                        : null}
                </div>
                <div className='maccounts__tableCont'>
                    {renderTable()}
                </div>
            </Container>
        </div>

    )
}
