//Icons
import MyMaps from '../../../../assets/svgs/dash-landing/d_mymaps.svg';
import ContentLibrary from '../../../../assets/svgs/dash-landing/d_content_library.svg';
import Analytics from '../../../../assets/svgs/dash-landing/d_analytics.svg';
import MyAccount from '../../../../assets/svgs/dash-landing/d_user.svg';

//Links
import {
    D_STUDIO, D_ANALYTICS, 
    D_SETTINGS, D_CONTENT_LIBRARY
} from '../../../_utils/routes';

export const linksCardData = [
    {
        id: '1',
        title: 'My Maps',
        icon: MyMaps,
        content: 'View all of the maps I’ve created with ARway App',
        link: D_STUDIO,
        linkText: 'View My Maps'
    },
    {
        id: '2',
        title: 'Content Library',
        icon: ContentLibrary,
        content: 'View and manage all of the uploaded content',
        link: D_CONTENT_LIBRARY,
        linkText: 'View Content Library'
    },
    {
        id: '3',
        title: 'Analytics',
        icon: Analytics,
        content: 'Explore how my maps are being viewed and where viewer interest is at',
        link: D_ANALYTICS,
        linkText: 'View Analytics'
    },
    {
        id: '4',
        title: 'My Accounts',
        icon: MyAccount,
        content: 'Edit profile info and payment info, view plan usage and invoices',
        link: D_SETTINGS,
        linkText: 'View My Account'
    }
]

/* 
{
    id: '3',
    title: 'Analytics',
    icon: Analytics,
    content: 'Explore how my maps are being viewed and where viewer interest is at.',
    link: D_ANALYTICS,
    linkText: 'View Analytics'
},
*/