import React from "react";
import { useSelector } from "react-redux";
import VisitorBehaviourCard from "./VisitorBehaviourCard";

const VisitorBehaviour = (props) => {
  const selectedMap = useSelector((state) => state.analytics.selectedMap);

  return (
    <div>
      {selectedMap.mapId === "allMaps" ? (
        <div>
          <VisitorBehaviourCard
            visitorBehaviourCard={props.analytics.visitorBehaviourCard}
            visitorBehaviourMetaCard={props.analytics.visitorBehaviourMetaCard}
          />
        </div>
      ) : null}
    </div>
  );
};

export default VisitorBehaviour;
