import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { Edit2 } from "react-feather";
//MUi
import { Grid, TextField, Box, Tooltip } from "@mui/material";

import { Copy, ArrowRight, Check } from "react-feather";

import AppCard from "./AppCard";
import Loader from "../../../common/loader";

//Route
import { D_DOWNLOADS } from "../../../_utils/routes";
import items from "../../../common/sidebar/menu";

//Actions!
import {
    getAppList,
    createApp,
} from "../../../../features/user/developer/developerSlice";
import {
    getUserPlanDetails,
    updateAccount,
} from "../../../../features/user/settings/settingsSlice";
import { retainNavState } from "../../../../features/common/commonSlice";
import DomainModal from "./DomainModal";
import { useNavigate } from "react-router-dom";
import { setNewAlert } from "../../../../features/common/alertSlice";

const DevelopersHome = () => {
    const [focused, setFocused] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [domainName, setDomainName] = useState("");
    const [appName, setAppName] = useState("");
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [isDomainEdit, setIsDomainEdit] = useState(false);
    const [domainSubmitDisabled, setDomainSubmitDisabled] = useState(false);
    const [showDomainModal, setShowDomainModal] = useState(false);
    const [domainData, setDomainData] = useState(null);
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        activeAccountId,
        appsList,
        devLog,
        deleteLog,
        updateLog,
        loggedUserType,
        domain,
        settingsLog,
    } = useSelector((state) => ({
        activeAccountId: state.auth.activeAccount || "",
        appsList: state.developer.appsList,
        devLog: state.developer.devLog,
        deleteLog: state.developer.deleteLog,
        updateLog: state.developer.updateLog,
        loggedUserType: state.auth.loggedUserType || "user",
        domain: state.settings.planDetails?.domain || "",
        settingsLog: state.settings.settingsLog,
    }));

    useEffect(() => {
        setFetching(true);
        dispatchGetAppList();
        dispatchGetUserPlanDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (domain !== domainName) setDomainName(domain);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [domain]);

    // just to turn off the loader
    useEffect(() => {
        if (appsList) setFetching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appsList]);

    useEffect(() => {
        if (devLog === "success") {
            dispatchGetAppList();
            setFetching(false);
            setAppName("");
            setSubmitDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [devLog]);

    useEffect(() => {
        if (deleteLog === "success") {
            dispatchGetAppList();
            setFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteLog]);

    useEffect(() => {
        if (updateLog === "success") {
            dispatchGetAppList();
            setFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateLog]);

    useEffect(() => {
        if (settingsLog === "success") {
            dispatchGetUserPlanDetails();
            setFetching(false);
            setIsDomainEdit(false);
            setDomainSubmitDisabled(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsLog]);

    const dispatchGetAppList = () => {
        dispatch(getAppList());
    };

    const dispatchGetUserPlanDetails = () => {
        dispatch(getUserPlanDetails());
    };

    const handleAppName = (event) => {
        const { value } = event.target;
        setAppName(value);
    };

    const handleDomainName = (event) => {
        const { value } = event.target;
        setDomainName(value);
    };

    const toggleDomainModal = (bFlag = undefined, domainData = null) => {
        if (bFlag) {
            setShowDomainModal(bFlag);
        } else {
            setShowDomainModal(!showDomainModal);
        }

        setDomainData(domainData);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (e.target.id === "createApp" && appName.length > 0) {
            const reqObj = {
                name: appName,
            };
            setSubmitDisabled(true);
            dispatch(createApp(reqObj));
        }
    };

    const handleNavigate = (link) => {
        const findIndxArr = items[loggedUserType];
        let activeIndx = 0;

        findIndxArr.forEach((ele, index) => {
            if (link === ele.link) activeIndx = index;
        });

        navigate(link);
        dispatch(retainNavState(activeIndx));
    };

    const validateLink = (domain) => {
        var expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const regex = new RegExp(expression);
        const d = "https://" + domain
        return d.match(regex);
    };

    const handleUpdateDomain = () => {
        let domain = domainName;
        while (domain[domain.length - 1] === "/") {
            domain = domain.slice(0, -1);
        }
        if (validateLink(domain)) {
            setDomainName(domain);
            dispatch(updateAccount({ domain }));
        } else {
            dispatch(
                setNewAlert({
                    msg: "Enter Valid Domain!",
                    alertType: "information",
                })
            );
        }
    };

    const renderAppsList = () => {
        if (fetching && appsList.length === 0) {
            return (
                <Loader
                    loaderText="Fetching Apps..."
                    height="100%"
                    width="100%"
                />
            );
        }
        if (!appsList || ( appsList && appsList.length === 0)) {
            return <div>No App Available, create New!</div>;
        } else {
            return appsList.map((app, index) => (
                <div
                    key={index + app.id + app.name}
                    className="developerhome__appCardContainer"
                >
                    <AppCard
                        key={index + app.id + app.name}
                        appData={app}
                        index={index}
                    />
                    {!(index === appsList.length - 1) && (
                        <div className="developerhome__acLineSeparator"></div>
                    )}
                </div>
            ));
        }
    };

    return (
        <div className="developerhome">
            <Box padding="40px 60px">
                <div className="developerhome__mainRow">
                    <span className="developerhome__dhHeader">
                        For Developers
                    </span>
                </div>
                <div className="developerhome__contentRow">
                    <span className="developerhome__contentRow--header">
                        Your Apps
                    </span>
                    <Grid container={true}>
                        <Grid
                            item
                            xs={8}
                            className="developerhome__dhAppListRow"
                        >
                            <div className="developerhome__dhApplistCard">
                                {renderAppsList()}
                            </div>
                            <div className="developerhome__dhApplistCard">
                                <span className="developerhome__dhApplistCard--header">
                                    Downloads
                                </span>
                                <span className="developerhome__dhApplistCard--subHeader">
                                    View sample apps and download our SDKs to
                                    start building your own location-persistent
                                    apps
                                </span>
                                <div
                                    className="developerhome__dhApplistCard--sectionRow"
                                    onClick={() => handleNavigate(D_DOWNLOADS)}
                                    id="link-toDownloads"
                                >
                                    <span className="developerhome__dhApplistCard--linkText">
                                        Go to Downloads
                                    </span>
                                    <ArrowRight
                                        size={18}
                                        color="#2A4DC2"
                                        className="developerhome__dhApplistCard--linkIcon"
                                    />
                                </div>
                            </div>
                        </Grid>
                        <Grid
                            item
                            xs={4}
                            className="developerhome__dhCreateRow"
                        >
                            <div className="developerhome__dhAccountCard">
                                <span className="developerhome__dhAccountCard--header">
                                    Account ID
                                </span>
                                <div className="developerhome__dhAccountCard--sectionRow">
                                    <span className="developerhome__dhAccountCard--idText">
                                        {activeAccountId}
                                    </span>
                                    <Tooltip
                                        title="Copied to Clipboard"
                                        placement="bottom"
                                        open={isTooltipOpen}
                                    >
                                        {isTooltipOpen ? (
                                            <Check
                                                className="developerhome__dhAccountCard--idIcon"
                                                id="button-copiedAppId"
                                                size={18}
                                                color="green"
                                            />
                                        ) : (
                                            <Copy
                                                size={18}
                                                color="#2A4DC2"
                                                className="developerhome__dhAccountCard--idIcon"
                                                id="button-copyAppID"
                                                onClick={() => {
                                                    setIsTooltipOpen(
                                                        !isTooltipOpen
                                                    );
                                                    setTimeout(() => {
                                                        setIsTooltipOpen(false);
                                                    }, 1000);
                                                    navigator.clipboard.writeText(
                                                        activeAccountId
                                                    );
                                                }}
                                            />
                                        )}
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="developerhome__dhAccountCard">
                                <span className="developerhome__dhAccountCard--header">
                                    Create a New App
                                </span>
                                <span className="developerhome__dhAccountCard--subHeader">
                                    Start creating your own apps using our
                                    provided SDKs
                                </span>
                                <form onSubmit={handleSubmit} id="createApp">
                                    <TextField
                                        label="App Name"
                                        id="appName"
                                        value={appName}
                                        onChange={handleAppName}
                                        size="small"
                                        fullWidth
                                        className="mapdetails__mapFormControl mapdetails__mapFormControl--active"
                                        autoComplete="off"
                                        inputProps={{ maxLength: 15 }}
                                    />
                                    <button
                                        type="submit"
                                        className={
                                            appName.length > 0
                                                ? "developerhome__dhAccountCard--button"
                                                : "developerhome__dhAccountCard--button developerhome__dhAccountCard--btnDisabled"
                                        }
                                        disabled={
                                            appName.length <= 0 ||
                                            submitDisabled
                                        }
                                        id="button-createApp"
                                    >
                                        Create App
                                    </button>
                                </form>
                            </div>
                            <div className="developerhome__dhAccountCard">
                                <span className="developerhome__dhAccountCard--header">
                                    Deeplink Domain
                                </span>
                                <span className="developerhome__dhAccountCard--subHeader">
                                    Add a custom domain to point QR code scans
                                    to your own app
                                </span>
                                <form onSubmit={handleSubmit} id="domainName">
                                    {!isEmpty(domain) && !isDomainEdit ? (
                                        <div className="d-flex flex-row">
                                            <span className="developerhome__dhAccountCard--idText">
                                                https://{domain}
                                            </span>
                                            <Edit2
                                                style={{
                                                    cursor: "pointer",
                                                    scale: "0.8",
                                                }}
                                                onClick={() => {
                                                    setIsDomainEdit(
                                                        !isDomainEdit
                                                    );
                                                    setDomainName(domain);
                                                }}
                                                id="button-editMapName"
                                            />
                                        </div>
                                    ) : (
                                        <div className="d-flex flex-row">
                                            <span
                                                className="developerhome__dhAccountCard--idText"
                                                style={{
                                                    width: "87px",
                                                    paddingTop: "26px",
                                                }}
                                            >
                                                https://
                                            </span>
                                            <TextField
                                                error={
                                                    domainName.trim().length ===
                                                        0 && focused
                                                        ? true
                                                        : false
                                                }
                                                onFocus={() => setFocused(true)}
                                                onBlur={() => setFocused(false)}
                                                label="Domain Name"
                                                id="domainName"
                                                value={domainName}
                                                onChange={handleDomainName}
                                                size="small"
                                                fullWidth
                                                className="mapdetails__mapFormControl mapdetails__mapFormControl--active"
                                                autoComplete="off"
                                                inputProps={{
                                                    maxLength: 30,
                                                    minLength: 1,
                                                }}
                                                helperText={
                                                    domainName.trim().length ===
                                                        0 && focused
                                                        ? "Donmain name is empty"
                                                        : ""
                                                }
                                            />
                                        </div>
                                    )}
                                    <div className="d-flex flex-row">
                                        <Grid
                                            item
                                            xs={6}
                                            className="mapinfo__separator"
                                        >
                                            <button
                                                // type="submit"
                                                className={
                                                    domain
                                                        ? "developerhome__dhAccountCard--cancel"
                                                        : "developerhome__dhAccountCard--button developerhome__dhAccountCard--btnDisabled"
                                                }
                                                disabled={!domain.length > 0}
                                                id="button-domainName"
                                                onClick={() => {
                                                    toggleDomainModal(true, {
                                                        header: " Remove Deep linking from the app?",
                                                        subHeader:
                                                            "Removing the domain name will point all newly generated QR codes to ARway app",
                                                        cb: () => {
                                                            dispatch(
                                                                updateAccount({
                                                                    domain: null,
                                                                })
                                                            );
                                                            setDomainName("");
                                                        },
                                                    });
                                                }}
                                            >
                                                Remove
                                            </button>
                                        </Grid>
                                        <Grid
                                            item
                                            xs={6}
                                            className="mapinfo__separator"
                                        >
                                            <button
                                                // type="submit"
                                                className={
                                                    domainName.trim().length > 0
                                                        ? "developerhome__dhAccountCard--button"
                                                        : "developerhome__dhAccountCard--button developerhome__dhAccountCard--btnDisabled"
                                                }
                                                disabled={
                                                    domainName.trim().length <=
                                                        0 ||
                                                    domainSubmitDisabled
                                                }
                                                id="button-domainName"
                                                onClick={() => {
                                                    toggleDomainModal(true, {
                                                        header: "Update Deep linking domain for the app?",
                                                        subHeader:
                                                            " Updating the domain name will point all newly generated QR codes to the new app",
                                                        cb: () =>
                                                            handleUpdateDomain(),
                                                    });
                                                }}
                                            >
                                                Save
                                            </button>
                                        </Grid>
                                    </div>
                                </form>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </Box>
            <DomainModal
                openModal={showDomainModal}
                onCloseModal={() => {
                    toggleDomainModal(false);
                }}
                showClose={false}
                modalData={domainData}
            />
        </div>
    );
};

export default DevelopersHome;
