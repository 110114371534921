import React from 'react';
import { TextField } from '@mui/material';

export default function Input({
    id,
    value,
    label,
    subLabel,
    handleOnChange,
    inputType,
    enabled,
    limitKeys
}) {
    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    return (
        <div className='pInput'>
            <span className={`pInput__label ${enabled && 'pInput__label--disabled'}`}>
                {label}
                { subLabel && <span className='pInput__subLabel'>({subLabel})</span>}
            </span>
            <TextField
                type={inputType}
                value={value?.toString()}
                id={id}
                onChange={handleOnChange}
                size="small"
                className={`pInput__input`}
                autoComplete="off"
                disabled={enabled}
                onKeyDown={e => limitKeys !== undefined && exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
        </div>
    )
}
