import React, { useState } from "react";
import ReactMapGL, { Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { REACT_APP_MAPBOX_ACCESS } from '../../../../values/constants';
import MapPin from '../../../../assets/icons/locationmark.png'
import 'mapbox-gl/dist/mapbox-gl.css';

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const MapBox = (props) => {

    let [viewState, setViewState] = useState({
        latitude: props.lat,
        longitude: props.lon,
        zoom: 10,
        width: '100%',
        height: '46vh'
    });

    return (
        <div className="mapinfo__mapboxContainer">
            <ReactMapGL
                style={{width: '100%', height: '100%'}}
                mapStyle="mapbox://styles/mapbox/streets-v11"
                mapboxAccessToken={REACT_APP_MAPBOX_ACCESS}
                onMove={(evt) => setViewState(evt.viewState)}
                {...viewState}
            >
                <Marker latitude={props.lat} longitude={props.lon} anchor="bottom">
                    <img src={MapPin} width={viewState.zoom * 3} height={viewState.zoom * 5} alt="mapbox-pin"/>
                </Marker>
            </ReactMapGL>
        </div>
    );
} 


export default MapBox;