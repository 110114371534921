import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html } from "@react-three/drei";
import GltfModel from "./glft";
import Loader from "../loader";

const ModelViewer = ({
  modelPath,
  scale = 20,
  position = [0, 0, 0],
  height = 150,
}) => {
  return (
    <Canvas style={{ height, marginTop: 30 }}>
      <ambientLight intensity={1} />
      <spotLight position={[-10, -10, -10]} angle={0.15} penumbra={1} />
      <pointLight position={[-10, -10, -10]} />
      <Suspense
        fallback={
          <Html>
            <div
              style={{
                position: "absolute",
                top: "-150px",
                left: "-80px",
              }}
            >
              <Loader loaderText="Loading..." height="100%" width="100%" />
            </div>
          </Html>
        }
      >
        <GltfModel modelPath={modelPath} scale={scale} position={position} />
        <OrbitControls />
      </Suspense>
    </Canvas>
  );
};

export default ModelViewer;
