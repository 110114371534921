import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Server from '../../api';
import { HandleExceptionWithSecuredCatch } from '../CombineCatch';
import { resetState } from '../common/commonSlice';
import { setNewAlert } from "../common/alertSlice";

//Impor tlogin
import { login, updateUsername, verify2FA } from './authSlice';

const updateUserProfile = createAsyncThunk('access/updateUserProfile', async ({params, userId}, { dispatch }) => {
    try{
        const response = await Server.put(`/v1/user/${userId}`, params);
        dispatch(updateUsername(params));
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const accessWithPrivateKey = createAsyncThunk('access/accessWithPrivateKey', async (keyDetails, { dispatch }) => {
    try{
        const response = await Server.post("/v1/account/activateplan", keyDetails);
        return response.data;
        
    } catch(e) {
        if(e.response && e.response.status === 403 && e.response.data) {
            return dispatch(setNewAlert({msg: "Please Enter Valid Access Code!", alertType: 'information'}))
        } else {
            return dispatch(HandleExceptionWithSecuredCatch(e))
        }
    }
});

const initialState = {
    accessLog: null,
    activeSetupState: 0,
    profileAuthrized: null,
    accessAuthrized: null,
    accessGranted: null
};

const commonSlice = createSlice({
    name: 'access',
    initialState,
    reducers: {
        changeSetupState: (state, action) =>{
            return({...state, activeSetupState: action.payload})
        },
        resetAccessState: (state, action) =>{
            return({...state, accessLog: null, activeSetupState: 0, profileAuthrized: null,})
        },
        resetPrivateAccessState: (state, action) => {
            return({...state, accessAuthrized: null});
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(updateUserProfile.fulfilled, (state, { payload }) => {
            return({
                ...state,
                profileAuthrized: payload.status,
                activeSetupState: 2
            })
        })
        .addCase(accessWithPrivateKey.fulfilled, (state, {payload}) => {
            return{
                ...state,
                accessAuthrized: payload.status,
            }
        })
        .addCase(login.fulfilled, (state, { payload }) => {
            return{...state, accessGranted: payload.user && !payload.user.lastname ? "NEW_USER" : "EXISTING_USER"}
        })
        .addCase(verify2FA.fulfilled, (state, { payload }) => {
            return{...state, accessGranted: payload.user && !payload.user.lastname ? "NEW_USER" : "EXISTING_USER"}
        })
        .addCase(resetState, (state, { payload }) => {
            return{...initialState}
        })
    }
});

export const { changeSetupState, resetAccessState, resetPrivateAccessState } = commonSlice.actions;
export {
    updateUserProfile,
    accessWithPrivateKey
}
export default commonSlice.reducer;