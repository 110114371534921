import * as THREE from 'three';
import { omit }from 'lodash';
import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";

//import axios server
import Server from '../../../../api';

import {
    fromPosObjectToVec3Pos,
    fromQuaternionObjectToQuaternion,
    fromQuaternionToQuaternionObject,
    fromScaleObjectToVec3Scale,
    fromVec3PosToPosObject,
    fromVec3ScaleToScaleObject,
} from "../TransformConversions";

import FloorplanPeg from './FloorPeg';
import { RemoveObject } from '../../commands/RemoveObject';
import { dispose } from '@react-three/fiber';

export default class Floorplan extends EventEmitter {
    constructor(floorData, type) {
        super();

        this.floorData = omit(floorData, ['createdOn', 'modifiedOn', 'alignedResponse']);
        this.editor = new EditorExperience();

        // console.log("FLOOR", floorData);

        this.position = floorData.position ? fromPosObjectToVec3Pos(floorData.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = floorData.rotation ? fromQuaternionObjectToQuaternion(floorData.rotation) : new THREE.Quaternion(-0.7071068, 0, 0, 0.7071068);
        this.scale = floorData.scale ? fromScaleObjectToVec3Scale(floorData.scale) : new THREE.Vector3(1, 1, 1);
        this.imageMesh = null;
        this.pegMesh = new THREE.Group();

        this.type = type || 'floorplan';
        this.name = floorData.name ? floorData.name.substring(0, 20) : '';
        this.id = floorData.id;
        this.assetLink = floorData.link;
        this.showAsIcon = floorData.showAsIcon || false;
        this.visible = 'visible' in floorData ? floorData.visible : true;
        this.alignmentStatus = 'alignmentStatus' in floorData ? floorData.alignmentStatus : false;

        this.pegs = 'pegs' in floorData ? floorData.pegs : [];

        this.texLoader = new THREE.TextureLoader();

        this.editor.on('objectRemoved', this.onObjectRemoved);
        this.editor.on('objectChanged', this.onObjectChanged);
        this.editor.on('MetaObjectChanged', this.onMetaObjectChanged);
        this.editor.on('ReplaceFloorplan', this.onReplaceFloorplan);
        this.editor.on('floorplanUpdateSuccess', this.onFloorplanUpdateSuccess);
        this.editor.on('updateFloorplanAlignedState', this.onUpdateFloorplanAlignedState);
        this.editor.on('saveAdjustFloorChanges', this.makeUpdateAPICall);


        this.editor.jsonFloors.push({ ...this.floorData, visible: this.visible });

        this.createLoaderTex();
        this.isLoading = true;
        this.setupObject();
    }

    setupObject = () => {
        this.imgMat = new THREE.MeshBasicMaterial({ map: this.loaderTexture, side: THREE.DoubleSide, transparent: true });

        let imgTex = this.texLoader.load(this.assetLink, (tex) => {
            // on load
            this.imgMat.map = tex;
            this.imgMat.transparent = false;
            this.imgMat.needsUpdate = true;
            this.isLoading = false;
            //update floorData
            this.editor.floorData['imgWidth'] = tex.image.width;
            this.editor.floorData['imgHeight'] = tex.image.height;
            this.editor.floorData['hasFloor'] = true;
            this.editor.floorData['assetLink'] = this.assetLink;

            this.editor.trigger('floorDataReady');

            clearInterval(this.loadIntv);
        });
        imgTex.minFilter = imgTex.magFilter = THREE.LinearFilter;
        imgTex.colorSpace = THREE.SRGBColorSpace;
        imgTex.anisotropy = 4;
        if (this.alignmentStatus) {
            imgTex.flipY = false;
            imgTex.center.set(0.5, 0.5);
            imgTex.repeat.set(1, 1);
        }

        let imgGeo = new THREE.PlaneGeometry(10, 10, 32, 32);

        this.imgMesh = new THREE.Mesh(imgGeo, this.imgMat);
        if (this.alignmentStatus) {
            this.imgMesh.position.set(5, 0, 5);
            this.imgMesh.rotation.x = THREE.MathUtils.degToRad(90);
        }
        this.imgMesh.name = this.name.substring(0, 20);

        // new wrapper mesh
        this.imgWrapper = new THREE.Group();
        this.imgWrapper.add(this.imgMesh);
        this.imgWrapper.quaternion.copy(this.rotation);
        this.imgWrapper.position.copy(this.position);
        this.imgWrapper.scale.copy(this.scale);

        this.imageMesh = new THREE.Group();
        this.imageMesh.add(this.imgWrapper);
        this.imageMesh.name = this.name.substring(0, 20);
        this.imageMesh.renderOrder = 999;
        this.imageMesh.userData['id'] = this.id;
        this.imageMesh.userData['link'] = this.assetLink;
        this.imageMesh.userData['visible'] = this.visible;
        this.imageMesh.userData['skipChild'] = "floorplan"; // only to hide  child hirarchy in scene content if any
        this.imageMesh.userData['navHelper'] = 'floorplan';
        this.imageMesh.userData['metaFloorplan'] = 'floorplan';
        this.imageMesh.userData['alignmentStatus'] = this.alignmentStatus;
        this.imageMesh.userData['assetLink'] = this.assetLink;
        this.imageMesh.userData['type'] = this.type;
        this.imageMesh.userData['MetaObject'] = true;
        this.imageMesh.userData['floorWarning'] = true;
        this.imageMesh.userData['excludeArea'] = true;

        this.imageMesh.userData['floorplanPosition'] = this.position;
        this.imageMesh.userData['floorplanScale'] = this.scale;
        this.imageMesh.userData['floorplanQuaternion'] = this.imgWrapper.rotation.clone();

        //to hide UI transform
        this.imageMesh.userData['transformation'] = "NO_TRANSFORM";
        this.imageMesh.userData['floorAdjust'] = true;

        this.imageMesh.visible = this.visible;

        // calculate pivot
        if (this.alignmentStatus) {
            var box = new THREE.Box3().setFromObject(this.imageMesh);
            this.editor.transformPivot = box.getCenter(new THREE.Vector3())
            // console.log(this.editor.transformPivot);
        }

        // Setup Pegs!
        this.pegMesh.name = `${this.name} Pegs`;
        this.pegMesh.userData['type'] = 'floorplanPeg';
        this.pegMesh.userData['FloorPegsGroup'] = true;
        this.pegMesh.userData['transformation'] = "NO_TRANSFORM";
        this.pegMesh.userData['visible'] = true;

        let pegsPosY = [];

        this.pegs.forEach((peg) => {
            let fPeg = new FloorplanPeg(peg);
            this.editor.uiObjects.push(fPeg);
            this.pegMesh.add(fPeg.mesh);

            pegsPosY.push(fPeg.position.y);
        });

        let bFlag = pegsPosY.length ? true : false;

        if (bFlag && this.imageMesh.position.y === -1) {
            // when -1, it's the values from alignment response)
            let min = Math.min(...pegsPosY);
            this.imageMesh.position.y = min;
            this.editor.floorY = min;
        } else {
            this.editor.floorY = this.imageMesh.position.y;
        }

        // world matrix
        this.imgWrapper.updateMatrixWorld();
        this.editor.floorWorldMatrix = this.imgWrapper.matrixWorld.clone();

    }

    createLoaderTex = () => {
        var canvas = document.createElement('canvas'),
            ctx = canvas.getContext('2d');
        canvas.width = 512;
        canvas.height = 512;

        const scope = this;

        // load image and draw it on canvas
        const img = document.createElement("img");
        img.onload = () => {
            let rot = 0;
            this.loadIntv = setInterval(() => {
                ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
                ctx.save();
                ctx.translate(canvas.width / 2, canvas.height / 2);
                ctx.rotate(rot * Math.PI / 180);
                ctx.drawImage(img, -img.width / 2, -img.width / 2);
                ctx.restore();

                if (rot >= 360) rot = 0;
                else rot += 15;

                scope.isLoading && scope.imgMesh && scope.imgMesh.material && (scope.imgMesh.material.map.needsUpdate = true)
            }, 100)

        }
        img.src = '/static/textures/loader.png';
        this.loaderTexture = new THREE.CanvasTexture(canvas);
    }

    setLink = (link) => {
        this.assetLink = link;
        this.imageMesh.userData['link'] = this.assetLink;
    }

    onReplaceFloorplan = (object, property, value) => {
        this.editor.onToggleFloorplanInfoModal(true, { name: object.name, object: object, type: 'update', property, value })
    }

    onObjectRemoved = (object, delayAutosave, isVersionChange = false) => {
        if (!isVersionChange && this.imageMesh === object) {
            const idx = this.editor.getIndex('jsonFloors', this.id);
            const floor = this.editor.jsonFloors.splice(idx, 1)
            this.editor.onCommand(new RemoveObject(this.editor, this.pegMesh, false), 'RESTRICT_UNDO');
            this.editor.onFloorplanAPICalls(floor[0]?.id, 'DELETE');
        } else if (isVersionChange && this.imageMesh === object) {
            this.onCallDestructor(object);
        }
    }

    onObjectChanged = (object) => {
        if (this.imageMesh === object) {
            const idx = this.editor.getIndex('jsonFloors', this.id);

            this.imgWrapper.updateMatrixWorld();
            this.imgWrapper.getWorldPosition(this.position);
            this.imgWrapper.getWorldScale(this.scale);
            this.imgWrapper.getWorldQuaternion(this.rotation);

            this.imageMesh.userData['floorplanPosition'] = this.position;
            this.imageMesh.userData['floorplanScale'] = this.scale;
            this.imageMesh.userData['floorplanQuaternion'] = this.rotation;

            let reqObj = { ...this.editor.jsonFloors[idx] };

            reqObj.position = fromVec3PosToPosObject(this.position);
            reqObj.scale = fromVec3ScaleToScaleObject(this.scale);
            reqObj.rotation = fromQuaternionToQuaternionObject(this.rotation);
            reqObj.manualAligned = '3d_change';
            this.editor.jsonFloors[idx] = reqObj;
        }
    }

    onMetaObjectChanged = (object, property, value) => {
        // Handle Image Change!
        const scope = this;
        if (object === this.imageMesh) {
            const idx = this.editor.getIndex('jsonFloors', this.id);
            let reqObj = { ...this.editor.jsonFloors[idx] };

            const proceedSave = () => {
                this.editor.jsonFloors[idx] = reqObj;
                this.editor.onFloorplanAPICalls(reqObj, 'UPDATE');
            }

            if (property === 'name') {
                reqObj.name = value;
                this.name = value;
                this.imageMesh.name = value;
            }
            if (property === 'assetLink') {
                // Update Loader
                this.editor.trigger('mountObjectLoader', [true]);
                this.editor.trigger('toggleLoaderProgress', ['', 'Uploading Floorplan']);

                let reqParams = new FormData();
                reqParams.append('file', value);
                reqParams.append("contentType", "floorplans");
                reqParams.append("mapId", this.editor.mapId);
                reqParams.append("compressFile", true);
                this.editor.manualAligned = '2d_change';
                Server.post('/v1/asset/upload', reqParams, { headers: { "Content-Type": "multipart/form-data" } })
                    .then((response) => {
                        if (response.status === 200) {
                            const link = response.data.data?.compressedFile || response.data.data.file;
                            reqObj.link = link;
                            reqObj.alignmentStatus = false;
                            reqObj.pegs = [];
                            reqObj.position = { "posX": 0, "posY": 0, "posZ": 0 }
                            reqObj.rotation = { "rotX": -0.7071068, "rotY": 0, "rotZ": 0, "rotW": 0.7071068 }
                            reqObj.scale = { "scaX": 1, "scaY": 1, "scaZ": 1 }
                            reqObj.manualAligned = '2d_change';
                            scope.assetLink = '' // MAKE LINK EMPTY
                            proceedSave();
                        }
                    }).catch(e => {
                        proceedSave();
                    }
                    ).catch(e => this.editor.callbacks.handleExceptionCatch(e));
            } else { proceedSave() }
        }
    }

    onUpdateFloorplanAlignedState = (bFlag) => {
        const idx = this.editor.getIndex('jsonFloors', this.id);
        if (idx !== -1) {
            let reqObj = { ...this.editor.jsonFloors[idx] };
            reqObj.manualAligned = bFlag;
            this.editor.jsonFloors[idx] = reqObj;
            this.editor.onFloorplanAPICalls(reqObj, 'UPDATE');
        }
    }

    getIdx = (arr, id) => {
        let idx = -1;
        arr.forEach((e, i) => {
            if (e.id === id) idx = i;
        })
        return idx;
    }

    makeUpdateAPICall = (object) => {
        if (this.imageMesh === object) {
            const idx = this.editor.getIndex('jsonFloors', this.id);

            let reqObj = { ...this.editor.jsonFloors[idx] };
            reqObj.manualAligned = '3d_change';

            this.editor.manualAligned = '3d_change';
            this.imgWrapper.updateMatrixWorld();
            this.editor.floorWorldMatrix = this.imgWrapper.matrixWorld.clone();
            this.editor.jsonFloors[idx] = reqObj;
            this.editor.onFloorplanAPICalls(reqObj, 'UPDATE');
        }
    }

    onFloorplanUpdateSuccess = (object, changes) => {
        if (this.imageMesh === object) {
            // update localstorage json 
            let tmetadata = { ...this.editor.mapData.metadata };
            const idx = this.getIdx(tmetadata.floors, this.id)
            let tfloors = [...tmetadata.floors];
            tfloors[idx] = { ...changes }
            tmetadata.floors = [...tfloors];
            // console.log({...this.editor.mapData, metadata: {...tmetadata}});
            this.editor.callbacks.updateMapDetails({ ...this.editor.mapData, metadata: { ...tmetadata } });

            if (changes.link !== this.assetLink) {
                this.assetLink = changes.link;
                this.editor.loader.addFloorplan(this, object, changes);
                // remove paths only if floorplan is replaced
                this.editor.onRequestPathBulkDelete();
            }

            // invoke readjustment on 3d change
            if (changes.manualAligned === '3d_change') {
                this.editor.invokePathsAndPinsReAdjustment();
            }

            this.editor.resetFloorplanStates();
        }
    }

    onCallDestructor = (object) => {
        if (object === this.imageMesh) {
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            this.editor.stop('objectChanged', this.onObjectChanged);
            this.editor.stop('MetaObjectChanged', this.onMetaObjectChanged);
            this.editor.stop('ReplaceFloorplan', this.onReplaceFloorplan);
            this.editor.stop('floorplanUpdateSuccess', this.onFloorplanUpdateSuccess);
            this.editor.stop('updateFloorplanAlignedState', this.onUpdateFloorplanAlignedState);
            this.editor.stop('saveAdjustFloorChanges', this.makeUpdateAPICall);
            dispose(this.imageMesh);
        }
    }
}