import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Grid,
    TextField,
} from '@mui/material';
import { Calendar, UserCheck } from 'react-feather';

import UserAvtarIcon from '../../../../../../assets/icons/user_avtar.png';
import Loader from '../../../../../common/loader';
import Switch from '../../../../../common/toggleSwitch';
import { convertToDate } from '../../../../../_utils/dateFormatter';
import { getAccountPlanInfo, updateWatermark } from '../../../../../../features/admin/manageaccounts/manageAccountsSlice';
import { setNewAlert } from '../../../../../../features/common/alertSlice';

export default function UserInfoTab({ accountDetails, accountId }) {

    const toEdit = false;
    const dispatch = useDispatch();

    const [plan, setPlan] = useState(null);
    const [isArwayBrand, setArwayBrand] = useState(false);
    const [loading, setLoading] = useState(false);

    const planDetails = useSelector(state => state.manageaccounts.accountPlanDetails);
    const accountsLog = useSelector(state => state.manageaccounts.accountsLog);

    useEffect((() => {
        accountDetails && dispatch(getAccountPlanInfo(accountDetails.email));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [accountDetails]);

    useEffect((() => {
        if (planDetails && planDetails.plan) {
            setPlan(planDetails.plan);
            setArwayBrand(planDetails.plan.watermark);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [planDetails]);

    useEffect((() => {
        if(accountsLog && accountsLog === 'success' && isArwayBrand !== plan?.watermark) {
            setLoading(false);
            dispatch(setNewAlert({
                msg: 'Watermark status updated successfully!',
                alertType: 'information'
            }));
            dispatch(getAccountPlanInfo(accountDetails.email));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [accountsLog]);

    const handleBrandingUpdate = () => {
        if (isArwayBrand !== plan.watermark) {
            setLoading(true);
            dispatch(updateWatermark({ accountId, status: isArwayBrand }));
        };
    }

    if (!accountDetails) return <Loader loaderText={"Fetching Account Details..."} height="500px" width="100%" />;

    return (
        <div className='settingshome'>
            <Grid container={true} className="settingshome__acGridContainer">
                <Grid item xs={4} className="settingshome__acCardContainer">
                    <div className="settingshome__acCard">
                        <img src={UserAvtarIcon} alt="user_avtar" className='settingshome__acCard--userAvtarImg' draggable='false' />
                        <div className="settingshome__acCard__infoCont">
                            <div className="settingshome__acCard__infoCont--inforRow">
                                <UserCheck size="18" />
                                <span className="settingshome__acCard__infoCont--inforRow--text">Role:</span>
                                <span className="settingshome__acCard__infoCont--inforRow--data">{accountDetails.role}</span>
                            </div>
                            <div className="settingshome__acCard__infoCont--inforRow">
                                <Calendar size="18" />
                                <span className="settingshome__acCard__infoCont--inforRow--text">Created on:</span>
                                <span className="settingshome__acCard__infoCont--inforRow--data">{convertToDate(accountDetails.accounts[0].createdOn) || '--'}</span>
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={8} className="settingshome__acDetailsCardContainer">
                    <div className="settingshome__acDetailsCard">
                        <div className="settingshome__acDetailsCard--header">Profile Information</div>
                        <form>
                            <Grid container={true}>
                                <Grid item xs={12} className="settingshome__acDetailsCard--formInputContainer">
                                    <TextField
                                        label="User Email"
                                        id="email"
                                        value={accountDetails.email || ""}
                                        size="small"
                                        fullWidth
                                        className={`settingshome__acDetailsCard--formControl`}
                                        inputProps={
                                            { readOnly: !toEdit }
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container={true}>
                                <Grid item xs={6} className="settingshome__acDetailsCard--formInputContainer">
                                    <TextField
                                        label="First Name"
                                        id="firstName"
                                        value={accountDetails.name || ""}
                                        size="small"
                                        fullWidth
                                        className={`settingshome__acDetailsCard--formControl`}
                                        inputProps={
                                            { readOnly: !toEdit }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6} className="settingshome__acDetailsCard--formInputContainer">
                                    <TextField
                                        label="Last Name"
                                        id="lastName"
                                        value={accountDetails.lastname || ""}
                                        size="small"
                                        fullWidth
                                        className={`settingshome__acDetailsCard--formControl`}
                                        autoComplete='off'
                                        inputProps={
                                            { readOnly: !toEdit }
                                        }
                                    />
                                </Grid>
                            </Grid>
                            <Grid container={true}>
                                <Grid item xs={6} className="settingshome__acDetailsCard--formInputContainer">
                                    <TextField
                                        label="Company"
                                        id="company"
                                        value={accountDetails.company || ""}
                                        size="small"
                                        fullWidth
                                        className={`settingshome__acDetailsCard--formControl`}
                                        inputProps={
                                            { readOnly: !toEdit }
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6} className="settingshome__acDetailsCard--formInputContainer">
                                    <TextField
                                        label="Country"
                                        id="country"
                                        value={accountDetails.country || ""}
                                        size="small"
                                        fullWidth
                                        className={`settingshome__acDetailsCard--formControl`}
                                        inputProps={
                                            { readOnly: !toEdit }
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </form>
                    </div>
                    <div className="settingshome__acDetailsCardBranding">
                        <div className='settingshome__acDetailsCardBranding--row'>
                            <span className="settingshome__acDetailsCardBranding--header">SDK ARWay Branding</span>
                            <Switch
                                enabled={isArwayBrand}
                                onEnableToggle={(e) => setArwayBrand(e.target.checked)}
                                id="toggle-branding"
                                disabled={loading}
                            />
                        </div>
                        {(plan && isArwayBrand !== plan.watermark) && <div className='settingshome__acDetailsCardBranding--btnsRow'>
                            <button className='settingshome__acDetailsCardBranding--btnsRow--save' onClick={() => handleBrandingUpdate()}>Save changes</button>
                            <button className='settingshome__acDetailsCardBranding--btnsRow--cancel' onClick={() => setArwayBrand(prev => !prev)}>Cancel</button>
                        </div>}
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}
