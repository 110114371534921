import React, { useState, useEffect } from 'react';
import { isEmpty, isNull } from "lodash";
import { Box } from '@mui/material';

import DatePicker from '../../../../../_utils/DatePicker';
import { lastTEDays } from '../../../../../_utils/DateUtils';
import RenderLineChart from '../../../../users/analytics/Render/RenderLineChart';
import Loader from '../../../../../common/loader';

export default function MapVisitsCard({ mapsVisitsOverview, handleChange, loading}) {

    const [dateObj, setDateObj] = useState(lastTEDays);
    const [labels, setLabels] = useState(null);
    const [visits, setVisits] = useState(null);

    useEffect(() => {
        if (!isEmpty(mapsVisitsOverview)) {
            setLabels(mapsVisitsOverview.map((visit) => new Date(`${visit.date}`)));
            setVisits(mapsVisitsOverview.map((visit) => visit.visits));
        } else {
            setLabels(null);
            setVisits(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mapsVisitsOverview]);

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col-9">
                        <div className="analyticshome__anCard--contentRow--header">
                            Total Number of Map Visits
                        </div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker cb={(dateObj) => {
                                setDateObj(dateObj);
                                handleChange(dateObj);
                            }} />
                        </Box>
                    </div>
                </div>
                <hr />
                {((isNull(labels) || isNull(visits)) && !loading) ? (
                    <div className='analyticshome__emptyTextCont'>
                        <h5 className="text-center analyticshome__emptyStateText">{" "}No Data available{" "}</h5>
                    </div>
                    ) : loading ? (
                        <div className='analyticshome__emptyTextCont'>
                            <Loader loaderText={"Fetching Data..."} height="120%" width="100%"/>
                        </div>
                    ) : (
                        RenderLineChart(labels, visits, dateObj, "Number of Visits")
                    )}
            </div>
        </div>
    )
}
