import {
	EventDispatcher,
	// Quaternion,
	Raycaster,
	Vector2,
	// Vector3,
} from 'three';

const _pointer = new Vector2();
const _previousPointer = new Vector2();
const _raycaster = new Raycaster();


class DragControls extends EventDispatcher {

	/**
	 * 
	 * @param {*} _meshToDragOn 
	 * @param {*} _camera 
	 * @param {*} _canvas 
	 */
	constructor(_meshToDragOn, _camera, _canvas) {

		super();

		const _domElement = _canvas;
		_domElement.style.touchAction = 'none'; // disable touch scroll

		let _selected = null, _object = null;
		const _intersections = [];

		const scope = this;

		function activate() {
			_domElement.addEventListener('pointermove', onPointerMove);
			_domElement.addEventListener('pointerdown', onPointerDown);
			_domElement.addEventListener('pointerup', onPointerCancel);
			_domElement.addEventListener('pointerleave', onPointerCancel);

		}

		function deactivate() {
			_domElement.removeEventListener('pointermove', onPointerMove);
			_domElement.removeEventListener('pointerdown', onPointerDown);
			_domElement.removeEventListener('pointerup', onPointerCancel);
			_domElement.removeEventListener('pointerleave', onPointerCancel);

			// _domElement.style.cursor = '';

		}

		function dispose() {

			deactivate();

		}

		function getObject() {
			return _object;
		}

		function setObject(object) {
			_object = findGroup(object);
		}

		function getRaycaster() {
			return _raycaster;
		}

		function onPointerMove(event) {

			if (scope.enabled === false) return;
			if (!_selected) return;

			updatePointer(event);

			_intersections.length = 0;

			// Raycast on plane
			_raycaster.setFromCamera(_pointer, _camera);
			_raycaster.intersectObjects([_meshToDragOn], true, _intersections);

			if (_intersections.length > 0) {
				const intersect = _intersections[0];
				if (scope.dragTour) {
					_selected.parent.position.x = intersect.point.x;
					_selected.parent.position.z = intersect.point.z;
				} else {
					_selected.position.copy(intersect.point);
					scope.dispatchEvent( { type: 'drag', object: _selected } );
				}
			}

			scope.dispatchEvent( { type: 'drag', object: _selected } );
			_previousPointer.copy(_pointer);
		}

		function onPointerDown(event) {

			if (scope.enabled === false) return;
			if (!_object) return;

			updatePointer(event);

			_intersections.length = 0;

			_raycaster.setFromCamera(_pointer, _camera);
			_raycaster.intersectObjects([_object], true, _intersections);

			if (_intersections.length > 0) {
				if (scope.transformGroup === true) {
					// just find if the parent of the mesh is group
					_selected = findGroup(_intersections[0].object);
				} else {
					_selected = _intersections[0].object;
				}

				scope.dispatchEvent({ type: 'dragstart', object: _selected });
			}

			_previousPointer.copy(_pointer);
		}

		function onPointerCancel() {

			if (scope.enabled === false) return;

			if (_selected) {
				scope.dispatchEvent({ type: 'dragend', object: _selected });
				_selected = null;
			}

		}

		function updatePointer(event) {
			const rect = _canvas.getBoundingClientRect();

			_pointer.x = (((event.offsetX - rect.left) / rect.width) * 2) - 1;
			_pointer.y = - (((event.offsetY - rect.top) / rect.height) * 2) + 1;
		}

		function findGroup(obj, group = null) {
			if (!obj) return null;
			if (scope.dragTour) return obj;

			if (obj.parent && obj.parent.isGroup) return group = obj.parent;

			return obj;
		}

		activate();

		// API
		this.enabled = true;
		this.transformGroup = false;
		this.dragTour = false;

		this.activate = activate;
		this.dispose = dispose;
		this.getObject = getObject;
		this.getRaycaster = getRaycaster;
		this.setObject = setObject;
	}

}

export { DragControls };