import { Button, Form, Input, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SvgIcn from '../../../assets/svgs/ARWay_Primary_Logo_Brand.svg'
import { changePassResponse, changePassword, deleteChangePassResponse } from "../../../features/auth/authSlice";

// Routes
import {
    LOGIN,
    RESET_SUCCESS
} from '../../_utils/routes';

import {
    Eye,
    EyeOff
} from 'react-feather';

export const ResetPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const changePassRes = useSelector(changePassResponse)
    const [data, setData] = useState({password:null, confirmPass:null})
    const [typing, setTyping] = useState({pass:false, confirmPass:false})
    const [error, setError] = useState({passwordErr:null, confirmPassErr:null})
    const [errType, setErrType] = useState({
        spcharErr:false,
        spcharErrMsg:'Please input at least one special charachter',
        capLetterErr:false,
        capLetterErrMsg:'Please input at least one capital letter',
        numErr:false,
        numErrMsg:'Please input at least one Number',
        eightCharErr:false,
        eightCharErrMsg:'Please input at least 8 characters',
        lowercaseErr:false,
        lowercaseErrMsg:'Please input at least one lowercase letter',
        passMismatch:false,
        passMismatchMsg:'Password Mismatch'
    })

   
    const  spCharCheck = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const capLetterCheck = /^(?=.*[A-Z])/; 
    const numErrCheck = /^(?=.*[0-9])/
    const eightCharErrCheck = /^(?=.{8,}$)/
    const lowercaseErrCheck = /^(?=.*[a-z])/

    useEffect(() => {
        if(changePassRes&& changePassRes.code>299){
            alert(changePassRes?.message)
            // navigate('/forgotpassword')
        }
        if(changePassRes && (!changePassRes.code)){
            // alert(changePassRes?.message)
            navigate(RESET_SUCCESS)
        }

        return () => {
            dispatch(deleteChangePassResponse())
        }

    },[changePassRes, dispatch, navigate])

    const handleSubmit = (e) => {
        if(!data.password&&data.confirmPass===null){
            setError({passwordErr:true})
        }
        if(data.password!==data.confirmPass){
            setErrType(prev => ({...prev, passMismatch:true}))
        } else {
            setErrType(prev => ({...prev, passMismatch:false}))
        }
        if(spCharCheck.test(data.password)===false){
            setErrType(prev => ({...prev, spcharErr:true}))
        } else {
            setErrType(prev => ({...prev, spcharErr:false}))
        }
        if((capLetterCheck.test(data.password))===false){
            setErrType(prev => ({...prev, capLetterErr:true}))
        } else {
            setErrType(prev => ({...prev, capLetterErr:false}))
        }
        if(numErrCheck.test(data.password)===false){
            setErrType(prev => ({...prev, numErr:true}))
        } else {
            setErrType(prev => ({...prev, numErr:false}))
        }
        if(eightCharErrCheck.test(data.password)===false){
            setErrType(prev => ({...prev, eightCharErr:true}))
        } else {
            setErrType(prev => ({...prev, eightCharErr:false}))
        }
        if(lowercaseErrCheck.test(data.password)===false){
            setErrType(prev => ({...prev, lowercaseErr:true}))
        } else {
            setErrType(prev => ({...prev, lowercaseErr:false}))
        }
        e.preventDefault()
        // dispatch(changePassword(data.password))
        if(error.passwordErr===false&&errType.passMismatch===false&&errType.lowercaseErr===false&&errType.capLetterErr===false&&errType.eightCharErr===false&&errType.spcharErr===false&&errType.numErr===false){
            if(data.password===data.confirmPass){
                if(errType.eightCharErr===false){
                    dispatch(changePassword(data.password))
                }
            }
        } else {
            console.log(error, errType.passMismatch, errType.lowercaseErr, errType.capLetterErr, errType.eightCharErr,errType.spcharErr,errType.numErr);
        }
    }

    return(
        <Layout className="main">
            <Layout className="form-containers">
            <img className="brand-logo" alt="brand-logo-3" src={SvgIcn} />
                <h5 className="login-header" id="h5-resetPass">Reset Password</h5>
                <h5 className="reset-guide reset-head">Create a new password</h5>
                <p className="reset-guide">Enter your new password here, it must include 1 special character and be between 8-16 characters.</p>

                    <form onSubmit={handleSubmit}>
                    <Layout className="input-label-container">
                        {typing.pass?(<label className="typing-label">Password</label>):null}
                        <Input 
                            className={`text-inputs ${error.passwordErr || errType.passMismatch ? `error` : null } `}
                            placeholder="Password" 
                            type={showPassword ? "text" : "password"}
                            onChange={(e => {
                                setData(prev => ({...prev, password: e.target.value}))
                                setError(prev=>({...prev,passwordErr:false}))
                                // setErrType({spcharErr:null, numErr:null, eightCharErr:null, lowercaseErr:null})
                            })} 
                            onFocus={() => {
                                setTyping({pass:true})
                            }}
                            onBlur={() => {
                                setTyping({pass:false})
                            }}
                            id='input-resetPass'
                        />
                        <span className="showpassCont">
                            {showPassword ? 
                                <EyeOff strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowPassword(false)}/> : 
                                <Eye strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowPassword(true)}/>
                            }
                        </span>
                        <label className="errors" id='error-resetPass'>
                            {error.passwordErr ? 'Please Enter a password ' :
                                errType.passMismatch ? errType.passMismatchMsg :
                                    [errType.numErr ? errType.numErrMsg : [
                                        errType.eightCharErr ? errType.eightCharErrMsg : [
                                            errType.lowercaseErr ? errType.lowercaseErrMsg : [
                                                (errType.spcharErr)?errType.spcharErrMsg: [errType.capLetterErr ? errType.capLetterErrMsg : null
                                                    ]
                                                ]
                                            ]]
                                        ]}
                        </label>
                    </Layout>

                    
                    <Layout className="input-label-container">
                        {typing.confirmPass?(<label className="typing-label">Confirm Password</label>):null}
                        <Input 
                            className={`text-inputs ${error.passwordErr || errType.passMismatch ?  `error` : null } `}
                            placeholder="Confirm password" 
                            type={showConfirmPassword ? "text" : "password"}
                            onChange={(e) => {
                                setData(prev => ({...prev, confirmPass:e.target.value}))
                                setError(prev =>({...prev,confirmPassErr:false}))
                            }} 
                            onFocus={() => {
                                setTyping({confirmPass:true})
                            }}
                            onBlur={() => {
                                setTyping({confirmPass:false})
                            }}
                            id='input-resetConPass'
                        />
                        <span className="showpassCont">
                                {showConfirmPassword ? 
                                    <EyeOff strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowConfirmPassword(false)}/> : 
                                    <Eye strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowConfirmPassword(true)}/>
                                }
                            </span>
                        <label className="errors" id='error-resetPassConfirm'>
                            {error.passwordErr ? 'Please Enter a password ':null}
                        </label>
                    </Layout>


                    <Form.Item>
                        <Button className="signin-button m15" type="primary" htmlType="submit">
                            <span className="signin-button--spanText" id='button-resetSave'> Save Changes </span>
                        </Button>
                    </Form.Item>
                    <Link className="link-to-login atag" to={LOGIN} id='link-resetBackLogin'>Back to Login</Link>
                    </form>
                {/* </Form> */}
            </Layout>
        </Layout>
    )
}