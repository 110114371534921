import * as THREE from "three";
import EditorExperience from "../2DEditorExperience";

import { TransformControls } from "three/examples/jsm/controls/TransformControls";

//Commands
import { SetPosition } from "../../commands/SetPosition";

export default class WorldMain {
    constructor() {
        this.objects = [];

        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;
        this.time = this.editor.time;
        this.renderer = this.editor.renderer;
        this.orbitControls = this.editor.camera.controls;
        this.navigationTracking = false;

        this.setUpControls();
        this.editor.on("objectAdded", this.objectAdded);
        this.editor.on("objectSelected", this.onObjectSelected);
        this.editor.on("objectChanged", this.onObjectChanged);
        this.editor.on("objectRemoved", this.onObjectRemoved);
        this.editor.on("navigationTracking", this.toggleNavigationTracking);
        this.editor.on("transformModeChanged", this.onTransformModeChanged);
    }

    setUpControls = () => {
        // Transform COntrols
        this.box = new THREE.Box3();

        this.selectionBox = new THREE.BoxHelper();
        this.selectionBox.material.depthTest = false;
        this.selectionBox.material.transparent = true;
        this.selectionBox.visible = false;
        this.scene.add(this.selectionBox);

        var objPosOnDown = null;
        var objScaleOnDown = null;

        this.transformControls = new TransformControls(
            this.camera.instance,
            this.canvas
        );
        this.transformControls.addEventListener("change", () => {
            var object = this.transformControls.object;
            this.transformControls.showZ = false;
            if (object !== undefined) {
                this.selectionBox.setFromObject(object);

                if (!this.navigationTracking) {
                    const toEnable =
                        object?.userData?.transformation === "NO_TRANSFORM";
                    this.transformControls.enabled = !toEnable;
                    this.transformControls.showX = !toEnable;
                    this.transformControls.showY = !toEnable;
                }
            }
        });

        this.transformControls.addEventListener("mouseDown", () => {
            var object = this.transformControls.object;
            objPosOnDown = object.position.clone();
            objScaleOnDown = object.scale.clone();
            this.orbitControls.enabled = false;
        });

        this.transformControls.addEventListener("mouseUp", () => {
            var object = this.transformControls.object;

            if (object !== undefined) {
                switch (this.transformControls.getMode()) {
                    case "translate":
                        if (!objPosOnDown.equals(object.position)) {
                            this.editor.onCommand(
                                new SetPosition(
                                    this.editor,
                                    object,
                                    object.position,
                                    objPosOnDown,
                                    true
                                )
                            );
                        }
                        break;

                    case "scale":
                        if (!objScaleOnDown.equals(object.scale)) {
                            console.log("SCALED");
                            // this.editor.onCommand(new SetScale(this.editor, object, object.scale, objScaleOnDown, delayAutosave));
                        }
                        break;

                    default:
                        break;
                }
            }
            this.orbitControls.enabled = true;
        });

        this.scene.add(this.transformControls);

        this.raycaster = new THREE.Raycaster();
        var mouse = new THREE.Vector2();
        var onDownPos = new THREE.Vector2();
        var onUpPos = new THREE.Vector2();
        // var onDblClickPos = new THREE.Vector2();

        const getIntersects = (point, objects) => {
            mouse.set(point.x * 2 - 1, -(point.y * 2) + 1);
            this.raycaster.setFromCamera(mouse, this.camera.instance);
            return this.raycaster.intersectObjects(objects);
        };

        const getMousePos = (dom, x, y) => {
            var rect = dom.getBoundingClientRect();
            return [(x - rect.left) / rect.width, (y - rect.top) / rect.height];
        };

        const onMouseDown = (e) => {
            var array = getMousePos(this.canvas, e.clientX, e.clientY);
            onDownPos.fromArray(array);

            document.addEventListener("mouseup", onMouseUp, false);
        };

        const onMouseUp = (e) => {
            var array = getMousePos(this.canvas, e.clientX, e.clientY);
            onUpPos.fromArray(array);

            handleClick();

            document.removeEventListener("mouseup", onMouseUp, false);
        };

        const onTouchStart = (e) => {
            var touch = e.changedTouches[0];

            var array = getMousePos(this.canvas, touch.clientX, touch.clientY);
            onDownPos.fromArray(array);

            document.addEventListener("touchend", onTouchEnd, false);
        };

        const onTouchEnd = (e) => {
            var touch = e.changedTouches[0];

            var array = getMousePos(this.canvas, touch.clientX, touch.clientY);
            onUpPos.fromArray(array);

            handleClick();

            document.removeEventListener("touchend", onTouchEnd, false);
        };

        const handleClick = () => {
            if (
                !this.navigationTracking &&
                onDownPos.distanceTo(onUpPos) === 0
            ) {
                var intersects = getIntersects(onUpPos, this.objects);
                if (intersects.length > 0) {
                    var isSelected = false;
                    intersects.forEach((intersect) => {
                        var object = intersect.object;
                        if (isSelected) return;
                        if (this.isNonInteractiveObject(object.uuid)) {
                            this.editor.deselect();
                        } else if (object && object.visible) {
                            if (
                                object.parent instanceof THREE.Group &&
                                "interativeChildren" in
                                    object.parent.userData &&
                                !this.isSkipObject(object)
                            ) {
                                this.editor.select(object);
                                isSelected = true;
                            } else if (
                                object.parent === this.editor.scene &&
                                !this.isSkipObject(object)
                            ) {
                                this.editor.select(object);
                                isSelected = true;
                            } else {
                                let parent = object.parent;
                                let bDone = true;
                                while (bDone && parent !== this.editor.scene) {
                                    if (!parent) {
                                        bDone = false;
                                    } else if (
                                        parent.parent === this.editor.scene
                                    ) {
                                        bDone = false;
                                    } else if (
                                        parent.parent instanceof THREE.Group &&
                                        "interativeChildren" in
                                            parent.parent.userData
                                    ) {
                                        bDone = false;
                                    } else {
                                        parent = parent.parent;
                                    }
                                }
                                if (!this.isSkipObject(parent)) {
                                    this.editor.select(object);
                                    isSelected = true;
                                } else this.editor.deselect();
                            }
                        }
                    });
                } else {
                    this.editor.deselect();
                }
            }
        };

        this.canvas.addEventListener("mousedown", onMouseDown, false);
        this.canvas.addEventListener("touchstart", onTouchStart, false);
    };

    isNonInteractiveObject = (uuid) => {
        const index = this.editor.nonInteractiveObjects.indexOf(uuid);
        return index !== -1;
    };

    isSkipObject = (object) => {
        let bFlag = false;
        if (
            object.userData &&
            "skipScene" in object.userData &&
            object.userData.skipScene === true
        ) {
            bFlag = true;
        }
        return bFlag;
    };

    onClearAndReset = () => {
        this.objects = [];
        this.navigationTracking = false;
    };

    objectAdded = (object) => {
        object.userData.skipScene === undefined &&
            object.traverse((child) => {
                child.userData.skipScene === undefined &&
                    this.objects.push(child);
            });
    };

    onObjectSelected = (object) => {
        this.selectionBox.visible = false;
        this.transformControls.detach();

        if (
            object !== null &&
            object !== this.scene &&
            object !== this.camera.instance &&
            !this.navigationTracking
        ) {
            this.box.setFromObject(object);
            if (this.box.isEmpty() === false) {
                this.selectionBox.setFromObject(object);
                this.selectionBox.visible = true;
            }
            this.transformControls.attach(object);
            this.transformControls.position.set(0, 0, 0);
        }
    };

    onTransformModeChanged = (transformMode) => {
        if (transformMode === null) return;

        this.transformControls.setMode(transformMode);
    };

    toggleNavigationTracking = (bFlag) => {
        this.navigationTracking = bFlag;
        this.transformControls.enabled = !bFlag;
    };

    onObjectChanged = (object) => {
        if (this.editor.selectedObject === object) {
            this.selectionBox.setFromObject(object);
        }

        if (object.type.includes("Camera")) {
            object.updateProjectionMatrix();
        }
    };

    onObjectRemoved = (object) => {
        this.orbitControls.enabled = true;
        if (object) {
            if (object === this.transformControls.object) {
                this.transformControls.detach();
            }
            object.traverse((child) => {
                this.objects.splice(this.objects.indexOf(child), 1);
            });
        }
    };
}
