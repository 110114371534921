import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../api";
import { HandleExceptionWithSecuredCatch } from "../CombineCatch";
import { setNewAlert } from "../common/alertSlice";
import { omit } from "lodash";

const createBuilding = createAsyncThunk(
  "importMaps/createBuilding",
  async (params, { dispatch }) => {
    try {
      const response = await Server.post(`/v1/building/`, params);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getVenueDetails = createAsyncThunk(
  "importMaps/getVenueDetails",
  async (mapId, { dispatch }) => {
    try {
      const response = await Server.get(`/v1/building/${mapId}`);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const updateVenueMap = createAsyncThunk(
  "studio/updateVenueMap",
  async ({ mapId, venueDetails, noAlert }, { dispatch }) => {
    let maps = venueDetails.maps.map((m) =>
      omit(m, [
        // "mapName",
        "mapCode",
        "anchorIds",
        "isFloorAligned",
        "createdOn",
        "modifiedOn",
        "thumbnailImage",
        "createdAt",
        "updatedAt",
      ])
    ).map(m => ({ ...m, _id: "0" }));
    // maps = maps.map(m=>{
    //   console.log(!has(m,'mapName'))
    //   if(!has(m,'mapName')){
    //     return {...m,mapName:''}
    //   }
    //   return m
    // })
    venueDetails = omit({ ...venueDetails, maps }, [
      "id",
      "isPublished",
      "createdOn",
      "modifiedOn",
      "connectorGroups",
      "accountId",
      "isActive",
      'location',
      'gpsCoordinates',
      'publishedOn'
    ]);
    try {
      const response = await Server.put(`/v1/building/${mapId}`, venueDetails);
      !noAlert &&
        dispatch(
          setNewAlert({
            msg: response.data.message,
            alertType: "information",
          })
        );
      return response.data;
    } catch (e) {
      if (e.response.status === 422)
        return dispatch(
          setNewAlert({
            msg: "No contents in the map, you cannot publish an empty map!",
            alertType: "danger",
          })
        );
      else return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const ungroupMap = createAsyncThunk(
  "studio/ungroupVenueMap",
  async ({ venueId, mapId, venueName, mapName, }, { dispatch }) => {
    try {
      const response = await Server.delete(`/v1/building/${venueId}/ungroup/${mapId}`)
      if (response.status === 200) {
        dispatch(
          setNewAlert({
            msg: `Map - ${mapName} has been ungrouped from venue ${venueName} Successfully`,
            alertType: "information",
          })
        );
      }
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const deleteVenue = createAsyncThunk(
  "studio/ungroupVenueMap",
  async ({ venueId, venueName }, { dispatch }) => {
    try {
      const response = await Server.delete(`/v1/building/${venueId}`)
      if (response.status === 200) {
        dispatch(
          setNewAlert({
            msg: `Venue ${venueName} has been Deleted Successfully`,
            alertType: "information",
          })
        );
      }
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);


const getConnectorGroups = createAsyncThunk('webstudo/getConnectorGroups', async ({ buildingId }, { dispatch }) => {
  try {
    const response = await Server.get(`v1/building/${buildingId}/connectorGroup`);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
});

const createConnectorGroups = createAsyncThunk('webstudo/createConnectorGroups', async ({ buildingId, connectObj }, { dispatch }) => {
  try {
    const response = await Server.post(`v1/building/${buildingId}/connectorGroup`, connectObj);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
});

const updateConnectorGroups = createAsyncThunk('webstudo/updateConnectorGroups', async ({ buildingId, groupId, connectObj }, { dispatch }) => {
  try {
    const response = await Server.put(`v1/building/${buildingId}/connectorGroup/${groupId}`, connectObj);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
});

const deleteConnectorGroups = createAsyncThunk('webstudo/deleteConnectorGroups', async ({ buildingId, groupId }, { dispatch }) => {
  try {
    const response = await Server.delete(`v1/building/${buildingId}/connectorGroup/${groupId}`);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
});

const publishVenueMap = createAsyncThunk('webstudo/publishVenueMap', async ({ buildingId, noAlert, isPublish }, { dispatch }) => {
  try {
    const response = await Server.put(`v1/building/${buildingId}/publish`, { isPublish });
    !noAlert && dispatch(setNewAlert({ msg: response.data.message, alertType: "information" }));
    return response.data;
  } catch (e) { 
    if (e.response && e.response.status === 422 && e.response.data) {
      return dispatch(
        setNewAlert({
          msg:  e.response.data.message,
          alertType: "information-stay",
          toHold: true,
        })
      );
    } else {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
});

const initialState = {
  log: null,
  isLoading: false,
  buildingMaps: null,
  buildingDetails: null,
  floorplanData: null,
  connectorGroups: null,
  buildingLog: null,
};

const buildingSlice = createSlice({
  name: "building",
  initialState,
  reducers: {
    resetBuilding: () => {
      return { ...initialState };
    },
    resetLog:()=>{
      return { ...initialState,log: null };
    },
    setBuildingMaps: (state, { payload }) => {
      return {
        ...state,
        buildingMaps: payload
      }
    },
    setIsloading: (state) => {
      return { ...state, isLoading: true }
    },
    resetVenueDetailsTab: (state, action) => {
      return {
        ...initialState
      };
    },
  },
  extraReducers: {
    "common/resetState": () => {
      return { ...initialState };
    },
    [createBuilding.fulfilled]: (state, { payload }) => {
      console.log(payload);
      return {
        ...state,
        log: payload.data.message,
        buildingDetails: payload.data,
        isLoading: false,
      };
    },
    [getVenueDetails.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        venueDetails: payload.data,
        log: null,
      };
    },
    [updateVenueMap.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        venueDetails: {...payload.data},
        log: payload.status,
        isLoading: false,
      };
    },
    [ungroupMap.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        log: payload.status,
      };
    },
    [deleteVenue.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        log: payload.status,
      };
    },
    [getConnectorGroups.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        connectorGroups: payload.data,
        buildingLog: null,
      };
    },
    [createConnectorGroups.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        buildingLog: payload.status,
      };
    },
    [deleteConnectorGroups.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        buildingLog: payload.status,
      };
    },
    [updateConnectorGroups.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        buildingLog: payload.status,
      };
    },
    [publishVenueMap.fulfilled]: (state, { payload }) => {
      return { 
        ...state,
        log:payload.status
      };
    },
  },
});

export {
  createBuilding, getVenueDetails,
  updateVenueMap, ungroupMap, deleteVenue,
  getConnectorGroups, createConnectorGroups,
  deleteConnectorGroups, updateConnectorGroups,
  publishVenueMap,
};

export const { resetBuilding, setIsloading, setBuildingMaps, resetVenueDetailsTab, resetLog } = buildingSlice.actions;

export default buildingSlice.reducer;
