import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../api/noAuthServer';
import { HandleExceptionWithSecuredCatch } from '../CombineCatch';
// import { setNewAlert } from "../common/alertSlice";
import { resetState } from '../common/commonSlice';
import { impersonateUser } from '../admin/impersonations/impersonateSlice';;

/* console.log('NODE_ENV:'+process.env.NODE_ENV);
console.log('REACT_APP_NODE_ENV:'+process.env.REACT_APP_NODE_ENV);
console.log('REACT_APP_MAP_APIS_BASEURL:'+ REACT_APP_MAP_APIS_BASEURL); */

export const login = createAsyncThunk('auth/login', async (values, { dispatch, rejectWithValue }) => {
    try {
        localStorage.setItem('user-email', values.email);
        const fetchLogin = await Server.post(`/v1/auth/login`, values)
        
        if(!('requireVerification' in fetchLogin.data)) {
            localStorage.setItem('token', fetchLogin.data.tokens.access.token);
            localStorage.setItem('tokenExp', fetchLogin.data.tokens.access.expires);
            localStorage.setItem('refToken', fetchLogin.data.tokens.refresh.token);
            localStorage.setItem('refTokenExp', fetchLogin.data.tokens.refresh.expires);
            localStorage.setItem('activeSession', true);
            
            return fetchLogin.data;
        } else {
            return fetchLogin.data;
        }

    } catch (error) {
        return error.response.data;
    }
})

export const register = createAsyncThunk('auth/register', async (values) => {
    try {
        const registerUser = await Server.post(`/v1/auth/register`, values)
        localStorage.setItem('register-email',values.email)
        return registerUser.data
        
    } catch (error) {
        return error.response.data;
    }
})

export const resetPassword = createAsyncThunk('auth/resetpassword', async (email) => {
    try {
        const resetUserPass = await Server.post(`/v1/auth/forgot-password`, email)
        localStorage.setItem('forget-pass-email', resetUserPass.data.data)
        return resetUserPass.data;
    } catch (error) {
        return error.response.data;
    }
})

export const validateOtp = createAsyncThunk('auth/validateOtp', async (otp) => {
    try {
        const email = localStorage.getItem('forget-pass-email')
        localStorage.setItem('otp-token',otp)
        const validate = await Server.post(`/v1/auth/validate-otp?token=${otp}`, {email:email})
        return validate.data;
    } catch (error) {
        return error.response.data;
    }
})

export const changePassword = createAsyncThunk('auth/changepassword', async (value) => {
    try {
        const otpToken = localStorage.getItem('otp-token')
        const email = localStorage.getItem('forget-pass-email')
        const password = value;
        const changingPass = await Server.post(`/v1/auth/reset-password?token=${otpToken}`,{
            email:email,
            password:password
        })
        return changingPass.data;
    } catch (error) {
        return error.response.data;
    }
})

export const resendVerificationMail = createAsyncThunk('auth/resendVerificationEmail', async() => {
    try {
        const email = localStorage.getItem('register-email')
        /* const resendMail = */ await Server.post(`/v1/auth/resend-VerificationEmail`,{
            email:email
        })
        localStorage.setItem('emailVerificationSent', true)

    } catch (error) {
        // console.log(error);
    }
})

export const refreshTokens = createAsyncThunk('auth/refreshTokens', async (values, {dispatch}) => {
    try {
        const response = await Server.post(`/v1/auth/refresh-tokens`, values)
        localStorage.setItem('token', response.data.access.token);
        localStorage.setItem('refToken', response.data.refresh.token);
        localStorage.setItem('refTokenExp', response.data.refresh.expires);
        return response.data
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
})

export const logout = createAsyncThunk('auth/logout', async (_, {dispatch}) => {
    localStorage.clear();
    dispatch(resetState());
    return {}
})

export const logoutImpersonate = createAsyncThunk('auth/logoutImpersonate', async (_, {dispatch}) => {
    sessionStorage.clear();
    dispatch(resetState());
    return {}
})

export const verify2FA = createAsyncThunk('auth/verify2FA', async (otp, {rejectWithValue}) => {
    try {
        const email = localStorage.getItem('user-email')
        const validate = await Server.post(`/v1/auth/validate-login-otp?token=${otp}`, {email:email})
        localStorage.setItem('token', validate.data.tokens.access.token);
        localStorage.setItem('tokenExp', validate.data.tokens.access.expires);
        localStorage.setItem('refToken', validate.data.tokens.refresh.token);
        localStorage.setItem('refTokenExp', validate.data.tokens.refresh.expires);
        localStorage.setItem('activeSession', true);
        return validate.data;
    } catch (error) {
        console.log("ERROR", error.response);
        return rejectWithValue(error.response.data);
    }
})

export const resend2FACode = createAsyncThunk('auth/resend2FACode', async(reqObj) => {
    try {
        const resendMail = await Server.post(`/v1/auth/resend-login-otp`,reqObj)
        return (resendMail.status === 'success');
    } catch (error) {
        return false;
    }
})

const initialState = {
    data: null,
    loggedUserType: null,
    loggedUserName: null,
    loggedUserId: null,
    token: null,
    registerData:null,
    forgotPassData:null,
    validateOtpData:null,
    changePassData:null,
    activeAccount: null,
    is2FaRequired: null,
    verify2FAResponse: null,
    resend2FAResponse: null,
}


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers:{
        updateUsername: (state, action) => {
            return{
                ...state,
                loggedUserName: action.payload.name
            }
        },
        deleteRegisterResponse:(state)=> {
            state.registerData = null
        },
        deleteResetResponse:(state) => {
            state.forgotPassData = null
        },
        deleteOtpResponse:(state) => {
            state.validateOtpData = null
        },
        deleteChangePassResponse:(state) => {
            state.changePassData = null
        },
        delete2faResponse:(state) => {
            state.verify2FAResponse = null
        },
        deleteResend2faResponse: (state) => {
            state.resend2FAResponse = null
        }
    },
    extraReducers: (builder) => {
        builder
        .addCase(login.fulfilled, (state, {payload}) => {
            if(!('requireVerification' in payload)) {
                payload.user && payload.user.accountId && localStorage.setItem('activeAccountId', payload.user.accountId);
                return {
                    ...state,
                    data: payload,
                    loggedUserType: payload.user ? payload.user.role : null,
                    loggedUserName: payload.user ? payload.user.name : null,
                    activeAccount: payload.user ? payload.user.accountId: null,
                    loggedUserId: payload.user ? payload.user.id : null,
                    is2FaRequired: null,
                }
            } else {
                return {
                    ...state,
                    is2FaRequired: payload.requireVerification
                }
            }
        })
        .addCase(register.fulfilled, (state, {payload}) => {
            return {
                ...state,
                registerData: payload
            }
        })
        .addCase(resetPassword.fulfilled, (state, {payload}) => {
            return {
                ...state,
                forgotPassData: payload
            }
        })
        .addCase(validateOtp.fulfilled, (state, {payload}) => {
            return {
                ...state,
                validateOtpData: payload
            }
        })
        .addCase(changePassword.fulfilled, (state, {payload}) => {
            return {
                ...state,
                changePassData: payload
            }
        })
        .addCase(refreshTokens.fulfilled, (state, {payload}) => {
            let newData = {...state.data}
            newData = {
                ...newData,
                tokens: {...payload}
            }
            return{
                ...state,
                data: {...newData}
            }
        })
        .addCase(login.rejected, (state, {payload}) => {
            return {
                ...state,
                data: payload
            }
        })
        .addCase(resetState, (state, action) => {
            return{...initialState}
        })
        .addCase(impersonateUser.fulfilled, (state, {payload}) => {
            // payload.user && payload.user.accountId && sessionStorage.setItem('activeAccountId', payload.user.accountId);
            let newData = {...state.data}
            newData = {
                ...newData,
                user: {...payload.user}
            }
            return({
                ...state,
                data: {...newData},
                loggedUserType: payload.user ? payload.user.role : null,
                loggedUserName: payload.user ? payload.user.name : null,
                activeAccount: payload.user ? payload.user.accountId: null,
                loggedUserId: payload.user ? payload.user.id : null,
            })
        })
        .addCase(verify2FA.fulfilled, (state, {action, payload}) => {
            payload.user && payload.user.accountId && localStorage.setItem('activeAccountId', payload.user.accountId);
            return {
                ...state,
                data: payload,
                verify2FAResponse: payload.user,
                loggedUserType: payload.user ? payload.user.role : null,
                loggedUserName: payload.user ? payload.user.name : null,
                activeAccount: payload.user ? payload.user.accountId: null,
                loggedUserId: payload.user ? payload.user.id : null,
                is2FaRequired: null,
                resend2FAResponse: null,
            }
        })
        .addCase(verify2FA.rejected, (state, {payload}) => {
            console.log("REJECTED", payload);
            return {
                ...state,
                verify2FAResponse: payload
            }
        })
        .addCase(resend2FACode.fulfilled, (state, {action, payload}) => {
            return {
                ...state,
                resend2FAResponse: payload
            }
        })
    },
})

export const loginData = (state) => state?.auth?.data;
export const registerResponse = (state) => state?.auth?.registerData;
export const forgetPassResponse = (state) => state?.auth?.forgotPassData;
export const validateOtpResponse = (state) => state?.auth?.validateOtpData;
export const changePassResponse = (state) => state?.auth?.changePassData;
export const is2FaRequired = (state) => state?.auth?.is2FaRequired;
export const verify2FAResponse = (state) => state?.auth?.verify2FAResponse;
export const resend2FAResponse = (state) => state?.auth?.resend2FAResponse;
export const { 
    deleteRegisterResponse, 
    deleteResetResponse, 
    deleteOtpResponse, 
    deleteChangePassResponse, 
    updateUsername, 
    delete2faResponse,
    deleteResend2faResponse
} = authSlice.actions;

export default authSlice.reducer;