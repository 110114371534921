import * as THREE from "three";
import EditorExperience from "../../2DEditorExperience";

/* LINE UTILS */
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";

class PathEdge {
    constructor(lineProps) {
        this.editor = new EditorExperience();

        this.vertices = lineProps.vertices;
        this.pathColor = lineProps.pathColor;
        this.pathStroke = lineProps.pathStroke;

        this.scalarOffsets = {
            1: 1,
            2: 1.5,
            4: 2,
            8: 2.5,
        };

        // const width = 10 + (10 + this.pathStroke * this.editor.floorData.SCALE_SIZE);
        const width =
            this.editor.floorData.MIN_SIZE *
            0.005 *
            this.scalarOffsets[this.pathStroke];

        this.mesh = new THREE.Group();
        this.mesh.name = `${this.name}`;
        this.material = new LineMaterial({
            vertexColors: true,
            dashed: false,
            depthWrite: false,
            depthTest: false,
            worldUnits: true,
            linewidth: width,
        });
        this.mesh.userData["skipScene"] = true;

        this.editor.time.on("tick", this.updateLineRes);

        this.setupLineGeometry();
    }

    generateAttributeBuffers = () => {
        let positions = [];
        let colors = [];

        var color = new THREE.Color(this.pathColor);

        for (var i = 0, index = 0; i < this.vertices.length; i++, index += 3) {
            positions[index] = this.vertices[i].x;
            positions[index + 1] = this.vertices[i].y;
            positions[index + 2] = this.vertices[i].z;

            colors[index] = color.r;
            colors[index + 1] = color.g;
            colors[index + 2] = color.b;
        }

        return {
            positions,
            colors,
        };
    };

    setupLineGeometry = () => {
        this.geometry = new THREE.BufferGeometry();

        const { colors, positions } = this.generateAttributeBuffers();

        this.geometry = new LineGeometry();
        this.geometry.setPositions(positions);
        this.geometry.setColors(colors);

        this.line = new Line2(this.geometry, this.material);
        this.line.computeLineDistances();
        this.updateLineRes();
        this.line.renderOrder = 1000;
        this.line.userData["skipScene"] = true;
        this.mesh.add(this.line);

        this.editor.nonInteractiveObjects.push(this.mesh, this.line);
    };

    updateLineRes = () => {
        this.material.resolution.set(
            this.editor.sizes.width,
            this.editor.sizes.height
        );
    };

    updateLineStroke = (newStroke) => {
        this.pathStroke = newStroke;
        let width =
            this.editor.floorData.MIN_SIZE *
            0.005 *
            this.scalarOffsets[this.pathStroke];
        this.material.linewidth = width;
        this.material.needsUpdate = true;
    };

    updateGeometryVertices = (updatedVertices, color) => {
        color && (this.pathColor = color);
        this.vertices = updatedVertices;
        this.mesh.remove(this.line);
        this.setupLineGeometry();
    };
}

export { PathEdge };
