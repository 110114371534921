import EventEmitter from "../../../utils/EventEmitter";
import { 
    UIPanel, 
    UIImage, 
    UITooltip 
} from "../../../ui/ui.lib";
import { 
    checkOnboardingFlag, 
    getTypeFromName 
} from "../../threeUtils/Connectors/ConnectorUtils";
import { isEmpty } from 'lodash';

class Menu2DConnectorPin extends EventEmitter {
    constructor(editor, category) {
        super();

        this.editor = editor;
        this.dom = null;
        this.category = category;
        this.name = category.name;
        this.type = getTypeFromName(category.name);
        this.icon = category.image;

        this.isActive = false;
        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('navigationTracking', this.onNavigationTracking);
        this.editor.resources.on('ready', this.onReady);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('ws2DMenuContainer');
        this.uiCont.setId('wsMenuText');
        
        this.uiText = new UITooltip(this.name);
        this.uiImg = new UIImage(this.icon, '28px', '29px', this.name);

        this.uiCont.addChild(this.uiImg);
        this.uiCont.addChild(this.uiText);

        this.uiCont.onClick(() => {
            this.setActivePin()
        })

        this.dom = this.uiCont.dom;
    }

    setActivePin = () => {
        this.makeActive();
        if(this.type === 'elevator') {
            !this.editor.continueConnectorTracking && checkOnboardingFlag('elevatorOnboarding') && this.editor.onToggleInfoModals("ElevatorConnector", true);
        } else if(this.type === 'escalator') {
            if(!this.editor.continueConnectorTracking) {
                checkOnboardingFlag('escalatorOnboarding') 
                    ? this.editor.onToggleInfoModals("EscalatorConnector", true) 
                    : this.editor.onToggleInfoModals("EscalatorDirection", true);
            }
        }
        this.editor.conPinHelper.setUpControls(this.type, this.category);
    }

    onReady = () => {
        if(this.editor.continueConnectorTracking && !isEmpty(this.editor.preloadConnector) && this.editor.preloadConnector['connectorPin'] === this.type) {
            this.setActivePin();
        }
    }

    makeActive = () => {
        if(!this.isActive) {
            this.isActive = true;
            this.uiCont.addClass('ws2DMenuContainer--active');        
        }
        this.editor.trigger('toggleMenuActive', ['Pins', this.name]);
    }

    onToggleMenuActive = (type, name) => {
        if(name !== this.name) {
            this.isActive = false;
            this.uiCont.removeClass('ws2DMenuContainer--active');
        }
    }

    onNavigationTracking = (bFlag) => {
        if(bFlag) {
            this.uiCont.addClass('ws2DMenuContainer--disable'); 
        } else {
            this.uiCont.removeClass('ws2DMenuContainer--disable');
        }
    }
}

export { Menu2DConnectorPin };