import EventEmitter from "../../utils/EventEmitter";
import { UIPanel, UINumber, UIRow, UIText, UISeparator } from "../ui.lib";

import * as THREE from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";

import { SetPosition } from "../../commands/SetPosition";
import { SetRotation } from "../../commands/SetRotation";
import { SetScale } from "../../commands/SetScale";

class FloatingMenuTransform extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;

        this.initUI();

        this.editor.on("nodeSelected", this.onNodeSelected);
        this.editor.on("objectSelected", this.onObjectSelected);
        this.editor.on("objectChanged", this.onObjectSelected);
        this.editor.on(
            "transformModeChanged",
            this.onTransformationModeChanged
        );
    }

    initUI = () => {
        this.uiContainer = new UIPanel();
        this.uiContainer.addClass("wsTransformToolContainer");

        this.uiHeader = new UIText(undefined, "Position", true).addClass(
            "wsTransformToolHeaderText"
        );
        this.uiHeader.setDisplay("none");

        this.uiContainer.addChild(this.uiHeader);

        //position
        this.uiPositionCont = new UIPanel();
        this.uiPositionCont.addClass("wsPositionContainer");

        const uiColXZ = new UIPanel();
        uiColXZ.addClass("wsPositionContainer--uiCol");

        uiColXZ.addChild(
            new UIText(undefined, "Position", true).addClass(
                "wsTransformToolHeaderText"
            )
        );

        const uiRowXZ = new UIRow().setStyle("padding", "0px");

        this.objectPosX = new UINumber()
            .setPrecision(2)
            .setStyle("width", "50%")
            .setId("transX")
            .setHelper("X")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        this.objectPosZ = new UINumber()
            .setPrecision(2)
            .setStyle("width", "50%")
            .setId("transZ")
            .setHelper("Z")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        uiRowXZ.addChild(this.objectPosX, this.objectPosZ);
        uiColXZ.addChild(uiRowXZ);
        this.uiPositionCont.addChild(uiColXZ);

        const uiSeparator = new UISeparator("wsPositionContainer--uiSep");
        this.uiPositionCont.addChild(uiSeparator);

        const uiColY = new UIPanel();
        uiColY.addClass("wsPositionContainer--uiCol");

        uiColY.addChild(
            new UIText(undefined, "Elevate", true).addClass(
                "wsTransformToolHeaderText"
            )
        );

        const uiRowY = new UIRow().setStyle("padding", "0px");

        this.objectPosY = new UINumber()
            .setPrecision(2)
            .setStyle("width", "98%")
            .setId("transY")
            .setHelper("Y")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        uiRowY.addChild(this.objectPosY);
        uiColY.addChild(uiRowY);
        this.uiPositionCont.addChild(uiColY);

        this.uiRotationRow = new UIRow().setStyle("padding", "0px");
        this.objectRotX = new UINumber()
            .setStep(10)
            .setNudge(0.1)
            .setUnit("°")
            .setRange(-360, 360)
            .setStyle("width", "33.3%")
            .setHelper("X")
            .setId("rotX")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        this.objectRotY = new UINumber()
            .setStep(10)
            .setNudge(0.1)
            .setUnit("°")
            .setRange(-360, 360)
            .setStyle("width", "33.3%")
            .setHelper("Y")
            .setId("rotY")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        this.objectRotZ = new UINumber()
            .setStep(10)
            .setNudge(0.1)
            .setUnit("°")
            .setRange(-360, 360)
            .setStyle("width", "33.3%")
            .setHelper("Z")
            .setId("rotZ")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        this.uiRotationRow.addChild(
            this.objectRotX,
            this.objectRotY,
            this.objectRotZ
        );
        this.uiRotationRow.setDisplay("none");

        //Scale
        this.uiScaleRow = new UIRow().setStyle("padding", "0px");
        this.objectScaleX = new UINumber()
            .setPrecision(2)
            .setStyle("width", "33.3%")
            .setHelper("X")
            .setId("scaleX")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        this.objectScaleY = new UINumber()
            .setPrecision(2)
            .setStyle("width", "33.3%")
            .setHelper("Y")
            .setId("scaleY")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        this.objectScaleZ = new UINumber()
            .setPrecision(2)
            .setStyle("width", "33.3%")
            .setHelper("Z")
            .setId("scaleZ")
            .onChange(this.update)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            });

        this.uiScaleRow.addChild(
            this.objectScaleX,
            this.objectScaleY,
            this.objectScaleZ
        );
        this.uiScaleRow.setDisplay("none");

        this.uiContainer.addChild(this.uiPositionCont);
        this.uiContainer.addChild(this.uiRotationRow);
        this.uiContainer.addChild(this.uiScaleRow);

        this.dom = this.uiContainer.dom;
    };

    update = () => {
        var object = this.editor.selectedObject;

        if (object !== null) {
            //Position
            var newPos = new THREE.Vector3(
                this.objectPosX.getValue(),
                this.objectPosY.getValue(),
                this.objectPosZ.getValue()
            );
            const delayAutosave = !("metaFloorplan" in object.userData);
            if (object.position.distanceTo(newPos) >= 0.01) {
                this.editor.onCommand(
                    new SetPosition(
                        this.editor,
                        object,
                        newPos,
                        object.position.clone(),
                        delayAutosave
                    )
                );
            }

            var newRot = new THREE.Euler(
                this.objectRotX.getValue() * THREE.MathUtils.DEG2RAD,
                this.objectRotY.getValue() * THREE.MathUtils.DEG2RAD,
                this.objectRotZ.getValue() * THREE.MathUtils.DEG2RAD
            );
            var vecOldRot = new THREE.Vector3(1, 1, 1).setFromEuler(
                object.rotation
            );
            var vecNewRot = new THREE.Vector3(1, 1, 1).setFromEuler(newRot);
            if (vecOldRot.distanceTo(vecNewRot) >= 0.01) {
                this.editor.onCommand(
                    new SetRotation(
                        this.editor,
                        object,
                        newRot,
                        object.rotation.clone(),
                        delayAutosave
                    )
                );
            }

            var newScale = new THREE.Vector3(
                this.objectScaleX.getValue(),
                this.objectScaleY.getValue(),
                this.objectScaleZ.getValue()
            );
            if (object.scale.distanceTo(newScale) >= 0.01) {
                this.editor.onCommand(
                    new SetScale(
                        this.editor,
                        object,
                        newScale,
                        object.scale.clone(),
                        delayAutosave
                    )
                );
            }
        }
    };

    onTransformationModeChanged = (mode, innerText) => {
        if (mode !== "edit") {            
            this.uiContainer.setDisplay("");
            if (innerText === "Position") {
                this.uiHeader.setDisplay("none");
            } else {
                this.uiHeader.setTextContent(innerText);
                this.uiHeader.setDisplay("");
            }
            this.uiPositionCont.setDisplay(mode === "translate" ? "" : "none");
            this.uiRotationRow.setDisplay(mode === "rotate" ? "" : "none");
            this.uiScaleRow.setDisplay(mode === "scale" ? "" : "none");
        } else {
            this.uiContainer.setDisplay("none");
        }
    };

    onNodeSelected = (node) => {
        if (!node) return;
        this.uiContainer.setDisplay("none");
    }

    onObjectSelected = (object) => {
        if (object) {
            let preventTransformations =
                object instanceof Line2 ||
                object?.userData?.transformation === "NO_TRANSFORM";

            let preventRotateTransform = preventTransformations;
            if ("rotateOn" in object.userData) {
                preventRotateTransform = true;
            }

            this.uiContainer.setDisplay("");

            this.objectPosX
                .setValue(object.position.x)
                .setReadOnly(preventTransformations);
            this.objectPosY
                .setValue(object.position.y)
                .setReadOnly(preventTransformations);
            this.objectPosZ
                .setValue(object.position.z)
                .setReadOnly(preventTransformations);

            this.objectRotX
                .setValue(object.rotation.x * THREE.MathUtils.RAD2DEG)
                .setReadOnly(preventRotateTransform);
            this.objectRotY
                .setValue(object.rotation.y * THREE.MathUtils.RAD2DEG)
                .setReadOnly(preventTransformations);
            this.objectRotZ
                .setValue(object.rotation.z * THREE.MathUtils.RAD2DEG)
                .setReadOnly(preventRotateTransform);

            this.objectScaleX
                .setValue(object.scale.x)
                .setReadOnly(preventTransformations);
            this.objectScaleY
                .setValue(object.scale.y)
                .setReadOnly(preventTransformations);
            this.objectScaleZ
                .setValue(object.scale.z)
                .setReadOnly(preventTransformations);
        }
    };
}

export { FloatingMenuTransform };
