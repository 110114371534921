import React from "react";
import clsx from "clsx";
// import { Link as RouterLink } from 'react-router-dom';
import PropTypes from "prop-types";
import { AppBar, Box, Toolbar } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Logo from "../logo";

const useStyles = makeStyles(() => ({
  root: {
    borderBottom: "1px solid #69728F",
  },
  toolbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  avatar: {
    width: 30,
    height: 30,
  },
  avatarText: {
    fontWeight: "bolder",
  },
  toolbarSubHeader: {
    fontWeight: 300,
    fontSize: "16px",
    marginRight: "20px",
  },
  toolbarHeader: {
    fontWeight: 500,
    fontSize: "16px",
  },
}));

const TopBar = ({ className, subHeader, header, ...rest }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Box>
          {/* <RouterLink to="/#"> */}
          <Logo style={{ width: "36px", height: "36px" }} />
          {/* </RouterLink> */}
        </Box>
        <Box>
          {/* <span className={classes.toolbarSubHeader}>{subHeader}</span> */}
          <span className={classes.toolbarHeader}>{header}</span>
        </Box>
        <Box
          sx={{
            width: "192px",
            visibility: "hidden",
          }}
        ></Box>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
