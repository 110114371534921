// import * as THREE from 'three';
import EventEmitter from "../../../utils/EventEmitter";
import { UIPanel, UIImage, UITooltip } from "../../../ui/ui.lib";

//Icon
import NavIcon from '../../../../../assets/svgs/scenes/search_icon.svg';

class Menu2DListConnector extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;
        this.name = 'Connector Pins';
        this.isActive = false;
        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('navigationTracking', this.onNavigationTracking);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('ws2DMenuContainer');
        this.uiCont.setId('wsMenuLocationPin');
        
        this.uiText = new UITooltip("Search Connector Pins");
        this.uiImg = new UIImage(NavIcon, '28px', '28px', "test");

        this.uiCont.addChild(this.uiImg);
        this.uiCont.addChild(this.uiText);

        this.uiCont.onClick(() => {
            this.makeActive();
        })

        this.dom = this.uiCont.dom;
    }

    makeActive = () => {
        if(!this.isActive) {
            this.isActive = true;
            this.uiCont.addClass('ws2DMenuContainer--active');        
        }
        this.editor.trigger('toggleMenuActive', [this.name]);
        this.editor.trigger('2DSidebarSceneGraphChanged', [this.name]);
    }

    onToggleMenuActive = (name) => {
        if(name !== this.name) {
            this.isActive = false;
            this.uiCont.removeClass('ws2DMenuContainer--active');
        }
    }

    onNavigationTracking = (bFlag) => {
        if(bFlag) {
            this.uiCont.addClass('ws2DMenuContainer--disable'); 
        } else {
            this.uiCont.removeClass('ws2DMenuContainer--disable');
        }
    }
}

export { Menu2DListConnector };