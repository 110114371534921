import * as THREE from 'three';

import EditorExperience from '../../EditorExperience';


/* LINE UTILS */
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import { fromPosObjectToVec3Pos, fromVec3PosToPosObject } from '../TransformConversions';
// import { MeshLine, MeshLineMaterial } from 'three.meshline';

export default class NavLine {

    constructor(lineProps, mode) {
        this.lineProps = lineProps;

        this.editor = new EditorExperience();
        this.navMode = mode;

        this.name = lineProps.name;
        this.description = lineProps.description;
        this.id = lineProps.id;
        this.vertices = lineProps.vertices;
        this.position = new THREE.Vector3();

        //Pins Setup
        this.startVertex = this.vertices[0];
        this.endVertex = this.vertices[this.vertices.length - 1];
        
        this.visible =  this.vertices[0].visible;
        this.pathColor =  this.vertices[0].pathColor;

        this.mesh = {};
        this.start = {};
        this.end = {};
        
        this.navModeStrings = ['waypointAndDestination', 'destination', 'waypoints'];

        this.editor.time.on('tick', this.updateLineRes);
        this.editor.on('objectChanged', this.onObjectChanged);
        this.editor.on('objectAdded', this.onObjectAdded);

        /* this.editor.on('objectSelected', this.hideAnnotation);
        this.editor.on('objectRemoved', this.onObjectRemoved);
        this.editor.on('hotspotHovered', this.showAnnotation); */

        // this.initDescHtml();
        this.setupLineGeometry();

    }

    initDescHtml = () => {

        this.annotation = document.createElement('div');
        this.annotation.classList.add('annotation');

        this.pHead = document.createElement('p');
        this.pHead.classList.add('annotation--header')
        this.pHead.textContent = this.name;
        this.annotation.appendChild(this.pHead);

        this.pDes = document.createElement('p');
        this.pDes.classList.add('annotation--text')
        this.pDes.textContent = this.description;
        this.annotation.appendChild(this.pDes);

        this.annotation.style.visibility = 'hidden';
        document.body.appendChild(this.annotation);
    }

    setupLineGeometry = () => {

        this.geometry = new THREE.BufferGeometry();

        let positions = [];
        let colors = [];
        let lineWidth = [];

        var color = new THREE.Color(this.pathColor)

        for(var i = 0, index = 0; i < this.vertices.length; i++, index += 3) {
            positions[ index ] = this.vertices[i].pointArray[0];
            positions[ index + 1 ] = this.vertices[i].pointArray[1];
            positions[ index + 2 ] = this.vertices[i].pointArray[2];

            colors[ index ] = color.r;
            colors[ index + 1 ] = color.g;
            colors[ index + 2 ] = color.b;
            
            (i <this.vertices.length - 1) && (lineWidth[i] = 10);

        }

        this.geometry = new LineGeometry();
        this.geometry.setPositions(positions);
        this.geometry.setColors(colors);
        this.geometry.setAttribute('linewidth', new THREE.InstancedBufferAttribute(new Float32Array(lineWidth), 1))

        this.material = new LineMaterial({
            vertexColors: true,
            dashed: false,
            alphaToCoverage: true,
        })

        this.material.onBeforeCompile = shader => {
            shader.vertexShader = `${shader.vertexShader}`.replace(`uniform float linewidth;`, `attribute float linewidth;`);
        }

        this.mesh = new THREE.Group();
        this.line = new Line2(this.geometry, this.material);
        this.line.computeLineDistances();
        this.updateLineRes();
        this.mesh.add(this.line);
        this.mesh.name = `${this.name}`;
        this.mesh.userData['id'] = this.id;
        this.mesh.userData['description'] = this.description;
        this.mesh.userData['type'] = "Guided Tour";
        this.mesh.userData['skipChild'] = true;
        this.mesh.userData['visible'] = this.visible;
        this.mesh.userData['pathColor'] = this.pathColor;
        this.mesh.userData['transformation'] = "TRANSLATION";
        this.mesh.userData['transform'] = "ONLY_TRANSLATE";
        this.mesh.visible = this.visible;
        this.navMode === 0 && (this.mesh.userData['navigationMode'] = 'waypointAndDestination');
        this.navMode === 2 && (this.mesh.userData['navigationMode'] = 'waypoints');

        const boxGrp = new THREE.Box3().setFromObject(this.mesh.clone());
        this.origin = boxGrp.getCenter(this.mesh.position.clone())
        this.origin.y = boxGrp.min.y + 0.09;
        this.newOrigin = this.origin.clone();

        // TO ALIGN POSITION OF LINE WITH IT'S CENTER
        var center = new THREE.Vector3();
        this.line.geometry.computeBoundingBox();
        this.line.geometry.boundingBox.getCenter(center);
        this.line.geometry.center();
        this.line.position.copy(center);

        this.position = this.mesh.position.clone();

        const s = 0.4;
        // Add Pins
        this.start = this.editor.navPinStart.clone();
        this.start.rotateY(THREE.MathUtils.degToRad(180))
        this.start.position.copy(this.startVertex.position);
        this.start.scale.set(s, s, s);
        this.start.name = `${this.id}__startPin`;
        this.start.userData['skipScene'] = true;
        this.mesh.add(this.start);

        this.end = this.editor.navPinEnd.clone();
        this.end.rotateY(THREE.MathUtils.degToRad(180))
        this.end.position.copy(this.endVertex.position);
        this.end.scale.set(s, s, s);
        this.end.name = `${this.id}__endPin`;
        this.end.userData['skipScene'] = true;
        this.mesh.add(this.end);

        this.editor.nonInteractiveObjects.push(this.start.uuid, this.end.uuid);
    }

    onObjectChanged = (object, delayAutoSave, b, isView = false) => {        
        if(this.mesh === object) {

            if(!this.position.equals(object.position)) {
                const boxGrp = new THREE.Box3().setFromObject( object.clone());
                this.newOrigin = boxGrp.getCenter(object.position.clone())
                this.newOrigin.y = boxGrp.min.y + 0.09;
                this.mesh.userData['newOrigin'] = this.newOrigin;
            }

            this.name = object.name;
            this.position = object.position.clone();
            this.description = object.userData.description;
            if(this.pathColor !== object.userData.pathColor) {
                this.pathColor = object.userData.pathColor;
                this.updatePathColor();
            }
            this.updateJSONObject(object, delayAutoSave, isView);
            // this.updateHTML();
        }
    }

    onObjectAdded = (object) => {
        if(this.mesh === object) {
            this.position = object.position.clone();
        }
    }

    updateJSONObject = (object, delayAutoSave, isView) => {
        const jsonIndex = this.editor.getIndex('jsonWayProps', object.userData.id);
        let changeObj = {...this.editor.jsonWayProps[jsonIndex]}
        changeObj.name = object.name;
        let wayArray = [...changeObj.navObj]
        let wayObj = {...wayArray[0]};
        wayObj.name = object.name;
        wayObj.description = object.userData.description;

        wayArray[0] = {...wayObj};

        //check for transformation change!!
        if(!this.origin.equals(this.newOrigin)) {
            for(var i = 0; i < wayArray.length; i++) {
                let pointObj = {...wayArray[i]}
                const localOrigin = this.origin.clone();
                const prevPos = fromPosObjectToVec3Pos(pointObj.position);
                const ptDist = prevPos.distanceTo(localOrigin);
                let newPos = new THREE.Vector3();
                const dirVec = new THREE.Vector3();
                dirVec.subVectors(prevPos, localOrigin).normalize();
                newPos.addVectors(this.newOrigin.clone(), dirVec.multiplyScalar(ptDist))
                pointObj.position = fromVec3PosToPosObject(newPos);

                /* const newS = new THREE.Mesh(new THREE.SphereGeometry(0.05, 32, 32), new THREE.MeshBasicMaterial({color: `#${this.colorsArray[Math.floor(Math.random() * this.colorsArray.length)]}`}))
                newS.position.copy(newPos)
                this.editor.scene.add(newS) */
                wayArray[i] = {...pointObj};
            }
            this.origin.copy(this.newOrigin)
        }

        changeObj = {
            ...changeObj,
            navObj: wayArray
        }
        this.editor.jsonWayProps[jsonIndex] = changeObj;
        !isView && this.editor.toAutoSave && this.editor.trigger('autoSaveSceneGraphState');
    }

    updatePathColor = () => {
        let colors = [];
        var color = new THREE.Color(this.pathColor);
        for(var i = 0, index = 0; i < this.vertices.length; i++, index += 3) {
            colors[ index ] = color.r;
            colors[ index + 1 ] = color.g;
            colors[ index + 2 ] = color.b;
        }
        this.line.geometry.setColors(colors);
        this.line.material.needsUpdate = true;
    }

    updateLineRes = () => {
        this.material.resolution.set( this.editor.sizes.width, this.editor.sizes.height );
    }

    updateHTML = () => {
        this.pHead.textContent = this.name;
        this.pDes.textContent = this.description;
    }

    hideAnnotation = (object) => {
        if(this.mesh === object) {
            this.annotation.style.visibility = 'hidden';
        }
    }

    showAnnotation = (object) => {
        /* if(this.end === object && this.editor.selectedObject !== this.mesh) {
            this.annotation.style.visibility = 'visible';
        } */  
        if(object && object.parent && object.parent !== this.editor.scene) {
            let parent = object.parent;
            let bDone = true;
            while(bDone && parent !== this.editor.scene) {
                if(parent.parent === this.editor.scene) {
                    bDone = false;
                } else {
                    parent = parent.parent;
                }
            }
            if(this.end === parent && this.editor.selectedObject !== parent) {
                this.annotation.style.visibility = 'visible';
            } 
        } else {
            this.annotation.style.visibility = 'hidden';
        }
    }

    onObjectRemoved = (object) => {
        if(this.mesh === object) {
            this.editor.removeObject(this.start);
            this.editor.removeObject(this.end);
        }
    }
}