import * as THREE from 'three';

export default class HeatClusterList {
    constructor() {
        this.instance = []
    }

    insertCluster = (type, elements) => {
        var obj = {
            type: type,
            positions: [...elements],
            elements: []
        }

        if(type.includes('cluster')) elements.forEach(ele => obj.elements.push(new THREE.Vector3().fromArray(ele)));
        else obj.elements.push(new THREE.Vector3().fromArray(elements));

        this.instance.push({...obj});
    }
}