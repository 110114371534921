import React from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { XCircle } from "react-feather";
import Warning from "./Warning";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "80% !important",
  height: "80%",
  transform: "translate(-50%, -50%)",
  width: "68%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none !important",
  outline: "none !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center !important",
  borderRadius: "16px",
};

const DomainModal = ({ openModal, onCloseModal, modalData }) => {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 600,
        }}
      >
        <form onSubmit={() => {}} noValidate>
          <Fade in={openModal}>
          <Box sx={{ ...style, height: "450px", width: "50%" }}>
              <XCircle
                color="#353E5A"
                strokeWidth="1.5"
                className="mapdetails__mapModalCloseIcon"
                onClick={() => {
                  onCloseModal();
                }}
              />
              <Box sx={{ width: "100%", paddingTop:'40px'}}>
                {
                    <Warning
                      closeModal={() => {
                        onCloseModal();
                      }}
                      modalData={modalData}
                    />
                }
              </Box>
            </Box>
          </Fade>
        </form>
      </Modal>
    </div>
  );
};

export default DomainModal;
