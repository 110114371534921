import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEqual } from "lodash";

import { Box, MenuItem, Select } from "@mui/material";
import { ChevronDown } from "react-feather";

import DatePicker from "../../../../../_utils/DatePicker";
import { lastTEDays } from "../../../../../_utils/DateUtils";

import {
  getHeatmap,
  resetHeatmapData,
} from "../../../../../../features/user/analytics/analyticsSlice";
import {
  getHeatmapDraftMapData,
  resetStudioStore,
} from "../../../../../../features/webstudio/webStudioSlice";
import { setNewAlert } from "../../../../../../features/common/alertSlice";

import EditorExperience from "../../../../../webstudio/analyticseditor/MinEditorExperience";

const HeatmapCard = (props) => {
  const dispatch = useDispatch();

  const selectedMap = useSelector((state) => state.analytics.selectedMap);
  const heatInfo = useSelector((state) => state.analytics.heatmap);
  const mapInfo = useSelector((state) => state.webstudio.mapDetailsPublished);

  const [mapDetails, setMapDetails] = useState(null);
  const [heatData, setHeatData] = useState(null);
  const [dateObj, setDateObj] = useState(lastTEDays);
  const [heatType, setHeatType] = useState("average");
  const [fetching, setFetching] = useState(false);

  const miniEditor = useRef(null);

  const heatTypeOptns = [
    {
      value: "average",
      label: "Average Time Spent",
    },
    {
      value: "start",
      label: "Start and End Locations",
    },
  ];

  useEffect(() => {
    initiateEditor();
    fetchMapMetadata();
    window.addEventListener("beforeunload", cleanup);

    return () => {
      cleanup();
      window.removeEventListener("beforeunload", cleanup);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (mapInfo && !isEqual(mapInfo, mapDetails)) {
      miniEditor.current &&
        miniEditor.current.initResourceLoading(mapInfo, mapInfo.mapdata, {
          loadingComplete: onEditorLoadingComplete,
        });
      setMapDetails(mapInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInfo]);

  useEffect(() => {
    if (heatInfo && !isEqual(heatInfo, heatData)) {
      miniEditor.current &&
        miniEditor.current.InitHeatmapLoading(heatInfo, heatType);
      setHeatData(heatInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [heatInfo]);

  useEffect(() => {
    fetchHeatMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateObj, heatType]);

  const cleanup = () => {
    miniEditor.current.clearAndReset();
    dispatch(resetStudioStore());
    dispatch(resetHeatmapData());
    delete miniEditor.current;
    miniEditor.current = null;
  };

  const initiateEditor = () => {
    miniEditor.current = new EditorExperience(
      true,
      mapDetails,
      "ARway-Map",
      document.getElementById("webgl-mini-canvas"),
      {
        loadingComplete: onEditorLoadingComplete,
        generateAlert: generateAlert,
      }
    );
  };

  const fetchMapMetadata = () => {
    setFetching(true);
    dispatch(getHeatmapDraftMapData(selectedMap.mapId));
  };

  const fetchHeatMetadata = () => {
    dispatch(
      getHeatmap({ ...dateObj, mapId: selectedMap.mapId, type: heatType })
    );
  };

  const onEditorLoadingComplete = () => {
    setFetching(false);
    fetchHeatMetadata();
  };

  const generateAlert = (data) => dispatch(setNewAlert({ ...data }));

  const handleHeatTypeChange = (e) => {
    setHeatType(e.target.value);
  };

  return (
    <div className="analytics">
      <div className="analyticshome__anCard" style={{ marginBottom: "0px" }}>
        <div className="row analyticshome__anCard--contentRow">
          <div className="col-3">
            <div className="analyticshome__anCard--contentRow--header">
              Heatmap
            </div>
          </div>
          <div className="col" style={{ paddingRight: "2.5rem" }}>
            <div className="row m-0 box">
              {<div className="col-3"></div>}
              <Box
                component="span"
                className="col-4 analyticshome__anCard--filterBox"
              >
                {/* <span style={{ color: "#858993" }}>Region:</span> */}
                <Select
                  style={{ fontFamily: "poppins", color: "#2A4DC2" }}
                  value={heatType}
                  onChange={handleHeatTypeChange}
                  variant="standard"
                  fullWidth
                  disableUnderline
                  IconComponent={ChevronDown}
                >
                  {heatTypeOptns.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      style={{ fontFamily: "poppins", color: "#2A4DC2" }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              {<div className="col-1"></div>}
              <Box
                component="span"
                className="col-4 analyticshome__anCard--filterBox"
              >
                <span style={{ color: "#858993" }}>Date:</span>
                <DatePicker
                  cb={(obj) => {
                    setDateObj(obj);
                  }}
                />
              </Box>
            </div>
          </div>
        </div>
        <hr />
        <div className="analyticshome__studioContent">
          <div
            className="analyticshome__loaderContainer"
            style={fetching ? { display: "flex" } : { display: "none" }}
          >
            <div className="analyticshome__loaderIconsContainer">
              <span className="analyticshome__loaderLogo"></span>
              <span className="analyticshome__loaderCircle"></span>
            </div>
            <span className="analyticshome__loaderText">Opening Map . . .</span>
          </div>
          <div
            className="analyticshome__canvasContainer"
            style={fetching ? { display: "none" } : { display: "flex" }}
          >
            <canvas id="webgl-mini-canvas"></canvas>
          </div>
        </div>
        <div className="analyticshome__hInfoCont">
          {heatType === "average" ? (
            <div className="analyticshome__havgCont">
              <span className="analyticshome__havgCont--text">
                Least Traffic
              </span>
              <div className="analyticshome__havgCont--gradDiv"></div>
              <span className="analyticshome__havgCont--text">
                Most Traffic
              </span>
            </div>
          ) : (
            <div className="analyticshome__havgCont">
              <div className="analyticshome__havgCont--hRow">
                <div className="analyticshome__havgCont--hBlue"></div>
                <span className="analyticshome__havgCont--text2">
                  App Opens
                </span>
              </div>
              <div className="analyticshome__havgCont--hRow">
                <div className="analyticshome__havgCont--hRed"></div>
                <span className="analyticshome__havgCont--text2">
                  App Exits
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeatmapCard;
