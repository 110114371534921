const getNavlist = (selectedMap) =>
  [
    {
      id: 0,
      title: `Overview - ${selectedMap?.mapName}`,
      tabLabel: "Overview",
    },
    selectedMap?.mapId === "allMaps"
      ? {
          id: 1,
          title: "Top Visited Maps",
          tabLabel: "Top Visited Maps",
        }
      : {
          id: 1,
          title: `Location - ${selectedMap?.mapName}`,
          tabLabel: "Location",
        },
    // selectedMap === 'All Maps' ? {
    //     id: 2,
    //     title:  `Visitor Behavior - ${selectedMap === 'All Maps' ? 'All Maps' : selectedMap}`,
    //     tabLabel: 'Visitor Behavior',
    // }:{},
    // {
    //     id: 3,
    //     title: `Demographics - ${selectedMap === 'All Maps' ? 'All Maps' : selectedMap}`,
    //     tabLabel: 'Demographics',
    // },
    // {
    //     id: 4,
    //     title: `Content Interactions - ${selectedMap === 'All Maps' ? 'All Maps' : selectedMap}`,
    //     tabLabel: 'Content Interactions',
    // },
  ].filter((v) => Object.keys(v).length);

export default getNavlist;
