import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions!
import {
    getAccountOverview,
    getAccountRegionSummary,
    getAccessPointsSummary,
    getMapVisitsOverview,
    getTopMapVisits,
    getVisitorBehavior,
} from '../../../../../features/admin/analytics/adminAnalyticsSlice';

// default dateObj!
import { lastTEDays } from '../../../../_utils/DateUtils';
import OverviewCard from './components/OverviewCard';
import RegionCard from './components/RegionCard';
import AccessPointsCard from './components/AccessPointsCard';
import MapVisitsCard from './components/MapVisitsCard';
import TopMapVisitsCard from './components/TopMapVisitsCard';
import VisitorBehaviorCard from './components/VisitorBehaviorCard';

export default function OverviewAll({visitorBehavior}) {
    const dispatch = useDispatch();

    const [ loadingStates, setLoadingStates ] = useState ({
        lOverview: true,
        lVisitor: true,
        lRegion: true,
        lAccess: true,
        lMapOverview: true,
        lMapVisits: true,
    })

    useEffect(() => {
        // dispatch all actions!
        !visitorBehavior && dispatch(getAccountOverview(lastTEDays));
        visitorBehavior && dispatch(getVisitorBehavior(lastTEDays));
        dispatch(getAccountRegionSummary(lastTEDays));
        dispatch(getAccessPointsSummary(lastTEDays));
        dispatch(getMapVisitsOverview(lastTEDays));
        dispatch(getTopMapVisits({...lastTEDays, pageNumber: 1, pageSize: 10}));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overview = useSelector(state => state.adminanalytics.accountOverview);
    const regionSummary = useSelector(state => state.adminanalytics.regionSummary);
    const accessPointsSummary = useSelector(state => state.adminanalytics.accessPointsSummary);
    const mapsVisitsOverview = useSelector(state => state.adminanalytics.mapsVisitsOverview);
    const topMapVisits = useSelector(state => state.adminanalytics.topMapVisits);
    const totalResults = useSelector(state => state.adminanalytics.totalResults);
    const accountVisitorBehavior = useSelector(state => state.adminanalytics.accountVisitorBehavior);

    useEffect(() => {
        if(topMapVisits && loadingStates.lMapVisits) {
            setLoadingStates( state => ({...state, lMapVisits: false}))
        }
    }, 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [topMapVisits, totalResults]);

    useEffect(() => {
        if(overview && loadingStates.lOverview) {
            setLoadingStates( state => ({...state, lOverview: false}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ overview]);

    useEffect(() => {
        if(accountVisitorBehavior && loadingStates.lVisitor) {
            setLoadingStates( state => ({...state, lVisitor: false}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ accountVisitorBehavior]);

    useEffect(() => {
        if(regionSummary && loadingStates.lRegion) {
            setLoadingStates( state => ({...state, lRegion: false}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ regionSummary]);

    useEffect(() => {
        if(accessPointsSummary && loadingStates.lAccess) {
            setLoadingStates( state => ({...state, lAccess: false}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ accessPointsSummary]);

    useEffect(() => {
        if(mapsVisitsOverview && loadingStates.lMapOverview) {
            setLoadingStates( state => ({...state, lMapOverview: false}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ mapsVisitsOverview]);


    return (
        <div>
            {!visitorBehavior ? 
                <OverviewCard 
                    overview={overview}
                    loading={loadingStates.lOverview}
                    handleChange={(dateObj) => {
                        setLoadingStates( state => ({...state, lOverview: true}))
                        dispatch(getAccountOverview(dateObj))}
                    }
                /> 
            :
                <VisitorBehaviorCard 
                    accountVisitorBehavior={accountVisitorBehavior}
                    loading={loadingStates.lVisitor}
                    handleChange={(dateObj) => {
                        setLoadingStates( state => ({...state, lVisitor: true}))
                        dispatch(getVisitorBehavior(dateObj))}
                    }
                /> 
            }
            <RegionCard
                regionSummary={regionSummary}
                loading={loadingStates.lRegion}
                handleChange={(dateObj) => {
                    setLoadingStates( state => ({...state, lRegion: true}))
                    dispatch(getAccountRegionSummary(dateObj))}
                }
            />
            <AccessPointsCard 
                accessPointsSummary={accessPointsSummary}
                loading={loadingStates.lAccess}
                handleChange={(dateObj) => {
                    setLoadingStates( state => ({...state, lAccess: true}))
                    dispatch(getAccessPointsSummary(dateObj))}
                }
            /> 
            <MapVisitsCard 
                mapsVisitsOverview={mapsVisitsOverview}
                loading={loadingStates.lMapOverview}
                handleChange={(dateObj) => {
                    setLoadingStates( state => ({...state, lMapOverview: true}))
                    dispatch(getMapVisitsOverview(dateObj))}
                }
            />
            <TopMapVisitsCard 
                topMapVisits={topMapVisits}
                loading={loadingStates.lMapVisits}
                handleChange={(data) => {
                    setLoadingStates( state => ({...state, lMapVisits: true}))
                    dispatch(getTopMapVisits(data))}
                }
                totalResults={totalResults}
            />
        </div>
    )
}
