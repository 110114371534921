import * as THREE from 'three';
import {
    isObject, has,
    omit, cloneDeep,
    isEqual
} from 'lodash';
import EditorExperience from '../../2DEditorExperience';
import Server from '../../../../../api';

import {
    fromPos2dObjectToVec3Pos, 
    fromPosObjectToVec3Pos, 
    fromVec3PosToPosObject,
    getTransformed2DPos,
    getTransformed2DPosV1,
    getTransformed3DPos,
    getTransformed3DPosV1,
} from '../../../threeUtils/TransformConversions';

import { 
    getAmenityTypeFromId, 
    getPinColorIndex, 
    getPinSpriteTexture, 
    getTexNameFromId,
} from './PinUtils';
import { dispose } from '../../../utils/Dispose.Utils';


const nullPos2dObj = {
    "posX": 0,
    "posY": 0
}

export default class LocationPin {
    constructor(pinProps) {
        this.pinProps = pinProps;

        this.editor = new EditorExperience();
        this.pinType = pinProps.pinType.toLowerCase(); // this is a temp fix!
        this.toUpload = 0;
        this.imageCount = 0;
        this.imgObj = null
        this.advancedImageReset= []

        this.name = pinProps.name || "";
        this.description = pinProps.description || "";
        this.id = pinProps.id;

        this.amenityType = this.pinType === 'amenity' && (pinProps.amenityPinTypes.length || pinProps.amenityType) ? pinProps.amenityType || pinProps.amenityPinTypes[0] : null;
        this.amenityType = isObject(this.amenityType) ? this.amenityType.id : this.amenityType;
        this.texType = this.pinType === 'amenity' ? getTexNameFromId(this.amenityType) : 'location';
        this.amenityChildren = null;

        this.scaleXZ = this.texType === 'location' ? this.editor.locScales.regScaleXZ: this.editor.locScales.amScaleXZ;
        this.scaleY = this.editor.locScales.scaleY;

        // this.scaleXZ += (this.scaleXZ * this.editor.floorData.SCALE_SIZE);
        // this.scaleY += (this.scaleY * this.editor.floorData.SCALE_SIZE);

        this.scaleXZ /= (this.scaleXZ / (this.editor.floorData.MIN_SIZE * (this.texType === 'location' ? .025 : .023)));
        this.scaleY /= (this.scaleY / (this.editor.floorData.MIN_SIZE * .03));

        if (this.amenityType) {
            const aGroup = getAmenityTypeFromId(this.editor.amenityTypes, this.amenityType);
            if (aGroup && has(aGroup, "children")) {
                this.amenityChildren = aGroup;
            }
        }

        // props
        this.images = pinProps.images || [];
        this.pinColor = pinProps.pinColor || '#36CCE7';
        this.pinNavStyle = pinProps.navigationStyle || 'default';
        this.pinCategories = pinProps.categories || [];
        this.visible = pinProps.visible || true;
        this.featuredPin = pinProps.isFeaturedPin || false;

        // advanced
        this.logo = pinProps.advanced?.logo || null;
        this.file = pinProps.advanced?.file || null;
        this.mobile = pinProps.advanced?.mobile || '';
        this.email = pinProps.advanced?.email || '';
        this.note = pinProps.advanced?.note || '';
        this.websiteLabel = pinProps.advanced?.websiteLabel || '';
        this.websiteLink = pinProps.advanced?.websiteLink || '';
        this.email = pinProps.advanced?.email || '';

        this.position = pinProps.position2d && !isEqual(pinProps.position2d, nullPos2dObj) 
                ? fromPos2dObjectToVec3Pos(pinProps.position2d) 
                : this.get2dPosFrom3dPos(fromPosObjectToVec3Pos(pinProps.position));

        this.position3d = fromPosObjectToVec3Pos(pinProps.position);

        this.mesh = {};
        this.bufferedChanges = {};

        pinProps = omit(pinProps, ['wsType']);

        this.editor.jsonLocationPins.push(pinProps);
        // this.editor.zoomObjects.push(this);

        this.editor.on('objectChanged', this.onObjectChanged);
        this.editor.on('meta2dObjectChanged', this.onMetaObjectChanged);
        this.editor.on('SaveMetaObject', this.onSaveMetaObject);
        this.editor.on('objectRemoved', this.onObjectRemoved);
        this.editor.on('objectAdded', this.onObjectAdded);
        this.editor.on('updateObject3DPositionsWithNewFloorMatrix', this.onUpdateObject3DPositions);

        this.setUpPin();
    }


    setUpPin = () => {
        const spriteTex = new THREE.TextureLoader().load(getPinSpriteTexture(this.texType, getPinColorIndex(this.pinColor)));
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        this.spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            side: THREE.DoubleSide,
            transparent: true,
            depthTest: false,
            depthWrite: false,
            // sizeAttenuation: true,
        });

        this.mesh = new THREE.Sprite(this.spriteMaterial);
        this.mesh.position.copy(this.position);
        this.mesh.scale.set(this.scaleXZ, this.scaleY, this.scaleXZ);
        this.mesh.rotation.y = THREE.MathUtils.degToRad(180);
        this.mesh.visible = this.visible;

        // adjust pos
        this.mesh.position.y += this.mesh.scale.y * 0.4;
        this.position.copy(this.mesh.position);

        this.mesh.name = this.name;
        this.mesh.userData['type'] = 'Location Pin';
        this.mesh.userData['id'] = this.id;
        this.mesh.userData['description'] = this.description;
        this.mesh.userData['pinColor'] = this.pinColor;
        this.mesh.userData['navigationStyle'] = this.pinNavStyle;
        this.mesh.userData['pinType'] = this.pinType;
        this.mesh.userData['visible'] = this.visible;
        this.mesh.userData['viewType'] = this.pinType; 

        if (this.pinType === 'regular') {
            // only regular pin has categories and advanced settings
            this.mesh.userData['featuredPin'] = this.featuredPin;
            this.mesh.userData['pinCategories'] = this.pinCategories;
            this.mesh.userData['logo'] = this.logo;
            this.mesh.userData['file'] = this.file;
            this.mesh.userData['images'] = this.images
            this.mesh.userData['mobile'] = this.mobile;
            this.mesh.userData['note'] = this.note;
            this.mesh.userData['email'] = this.email;
            this.mesh.userData['websiteLabel'] = this.websiteLabel;
            this.mesh.userData['websiteLink'] = this.websiteLink;
        } else {
            // features for amenity!
            if (this.amenityChildren) {
                this.mesh.userData['amenityChildren'] = this.amenityChildren;
            }
            this.mesh.userData['amenityType'] = this.amenityType;
        }

    }

    onMetaObjectChanged = (object, property, value, discardAll = false, uploadCnt = 0, imgObj = null) => {
        if (object === this.mesh) {
            this.imgObj = imgObj
            if (imgObj !== null) {
                this.toUpload -= this.imageCount
                this.imageCount = uploadCnt
            }

            'pinColor' === property && this.updatePinSkin(value);

            this.bufferedChanges[property] = value;
            this.toUpload += uploadCnt;
            if((property === 'logo' || property === 'file') && value === null){
                this.advancedImageReset.push({
                    type: property,
                    value
                })
            }
        } else if (discardAll) {
            'pinColor' in this.bufferedChanges && this.updatePinSkin(this.pinColor);
            this.bufferedChanges = {};
            this.toUpload = 0;
        }
    }

    prepareObjForAPI = (reqObj) => {

        reqObj.amenityPinTypes = reqObj.amenityPinTypes.length ? reqObj.amenityPinTypes.map( type => {
            if(typeof type === 'object') return type.id;
            else return type
        }) : [];

        reqObj.categories = reqObj.categories?.length ? reqObj.categories.map( type => {
            if(typeof type === 'object') return type.id;
            else return type
        }) : [];

        if(this.pinType !== 'amenity') {    
            let advanced = { ...reqObj.advanced };
            advanced.logo = typeof advanced.logo === 'object' ? advanced.logo?.link : advanced.logo; 
            advanced.file = typeof advanced.file === 'object' ? advanced.file?.link : advanced.file;
            reqObj.advanced = { ...reqObj.advanced, ...advanced };
        }

        reqObj.images = reqObj.images?.map(img => ({ url: img.link?img.link:img.url, order: img.order }))

        return {...reqObj};
    }

    onSaveMetaObject = async () => {
        if (Object.keys(this.bufferedChanges).length > 0) {

            // trigger loader 
            this.editor.trigger('mount2DLoader', [true]);
            this.editor.trigger('toggle2DLoaderProgress', ['', 'Updating Pin...']);

            const idx = this.editor.getIndex('jsonLocationPins', this.id);
            let reqObj = { ...this.editor.jsonLocationPins[idx] }
            reqObj = omit(reqObj, ['type','amenityType']);
            reqObj = this.prepareObjForAPI(reqObj);

            const proceedSave = () => {
                this.editor.jsonLocationPins[idx] = reqObj;
                // console.log(reqObj);
                this.editor.onPinAPICalls(reqObj, 'UPDATE', true);
            }

            if (has(this.bufferedChanges, 'name')) {
                reqObj.name = this.bufferedChanges.name;
                this.name = this.bufferedChanges.name;
                this.mesh.name = this.bufferedChanges.name;
            }
            if (has(this.bufferedChanges, 'description')) {
                reqObj.description = this.bufferedChanges.description;
                this.description = this.bufferedChanges.description;
                this.mesh.userData.description = this.bufferedChanges.description;
            }
            if (has(this.bufferedChanges, 'navigationStyle')) {
                reqObj.navigationStyle = this.bufferedChanges.navigationStyle;
                this.pinNavStyle = this.bufferedChanges.navigationStyle;
                this.mesh.userData.navigationStyle = this.bufferedChanges.navigationStyle;
            }
            if (has(this.bufferedChanges, 'pinCategories')) {
                reqObj.categories = this.bufferedChanges.pinCategories
                this.categories = this.bufferedChanges.pinCategories
                this.mesh.userData.pinCategories = this.bufferedChanges.pinCategories
            }
            if (has(this.bufferedChanges, 'featuredPin')) {
                reqObj.isFeaturedPin = this.bufferedChanges.featuredPin;
                this.featuredPin = this.bufferedChanges.featuredPin;
                this.mesh.userData.featuredPin = this.bufferedChanges.featuredPin;
            }
            if (has(this.bufferedChanges, 'pinColor')) {
                reqObj.pinColor = this.bufferedChanges.pinColor;
                this.pinColor = this.bufferedChanges.pinColor;
                this.mesh.userData.pinColor = this.bufferedChanges.pinColor;
            }

             //advanced
            let advanced = { ...reqObj.advanced };

            if (has(this.bufferedChanges, 'email')) {
                advanced.email = this.bufferedChanges.email;
                this.email = this.bufferedChanges.email;
                this.mesh.userData.email = this.bufferedChanges.email;
            }
            if (has(this.bufferedChanges, 'note')) {
                advanced.note = this.bufferedChanges.note;
                this.note = this.bufferedChanges.note;
                this.mesh.userData.note = this.bufferedChanges.note;
            }
            if (has(this.bufferedChanges, 'mobile')) {
                advanced.mobile = this.bufferedChanges.mobile;
                this.mobile = this.bufferedChanges.mobile;
                this.mesh.userData.mobile = this.bufferedChanges.mobile;
            }
            if (has(this.bufferedChanges, 'websiteLabel')) {
                advanced.websiteLabel = this.bufferedChanges.websiteLabel;
                this.websiteLabel = this.bufferedChanges.websiteLabel;
                this.mesh.userData.websiteLabel = this.bufferedChanges.websiteLabel;
            }
            if (has(this.bufferedChanges, 'websiteLink')) {
                advanced.websiteLink = this.bufferedChanges.websiteLink;
                this.websiteLink = this.bufferedChanges.websiteLink;
                this.mesh.userData.websiteLink = this.bufferedChanges.websiteLink;
            }
            // advance bools
            if (has(this.bufferedChanges, 'applyMobileToAll')) {
                advanced.applyMobileToAll = this.bufferedChanges.applyMobileToAll;
                this.mesh.userData.applyMobileToAll = this.bufferedChanges.applyMobileToAll;
            }
            if (has(this.bufferedChanges, 'applyNoteToAll')) {
                advanced.applyNoteToAll = this.bufferedChanges.applyNoteToAll;
                this.mesh.userData.applyNoteToAll = this.bufferedChanges.applyNoteToAll;
            }
            if (has(this.bufferedChanges, 'applyWebsiteToAll')) {
                advanced.applyWebsiteToAll = this.bufferedChanges.applyWebsiteToAll;
                this.mesh.userData.applyWebsiteToAll = this.bufferedChanges.applyWebsiteToAll;
            }

            if (has(this.bufferedChanges, 'amenityType')) {
                reqObj.amenityPinTypes = [this.bufferedChanges.amenityType];
                this.amenityType = this.bufferedChanges.amenityType;
                this.mesh.userData.amenityType = this.bufferedChanges.amenityType;
            }

            if (has(this.bufferedChanges, 'pinCategories')) {
                reqObj.categories = this.bufferedChanges.pinCategories
                this.categories = this.bufferedChanges.pinCategories
                this.mesh.userData.pinCategories = this.bufferedChanges.pinCategories
            }

            if(this.imgObj !==null){
                if (this.imgObj.some(imgObj => imgObj.action === 'delete') || this.imgObj.some(imgObj => imgObj.action === 'reorder')) {
                    // console.log('First', reqObj.images)
                    this.imgObj.forEach(imgObj => {
                        // delete
                        if (imgObj.action === 'delete') {
                            reqObj.images = reqObj.images.map(img => ({ url: img.link?img.link:img.url, order: img.order }))
                            .filter(img => img.order !== parseInt(imgObj.selected))
                            .map((img, i) => ({ ...img, order: i + 1 }))
                            this.images = this.images.filter(img => img.order !== parseInt(imgObj.selected)).map((img, i) => ({ ...img, order: i + 1 }))
                            this.mesh.userData.images = this.mesh.userData.images
                            .filter(img => img.order !== parseInt(imgObj.selected)).map((img, i) => ({ ...img, order: i + 1 }))
                        }
                        //reorder
                        if (imgObj.action === 'reorder') {
                            const reorder = (orgImgs) =>{
                                let tempImgs = cloneDeep(orgImgs)
                                let reorderItem = tempImgs.find(img=>img.order === parseInt(imgObj.selected))
                                let indexOfReorder = tempImgs.indexOf(reorderItem)
                                let temp = [reorderItem,...[...tempImgs.slice(0,indexOfReorder)],...[...tempImgs.slice(indexOfReorder+1,tempImgs.length)]]
                                return temp.map((img, i) => ({ ...img, order: i + 1 }))
                            }
                            reqObj.images = reorder(reqObj.images).map(img => ({ url: img.link?img.link:img.url, order: img.order }))
                            this.images = reorder(this.images)
                            this.mesh.userData.images = reorder(this.mesh.userData.images)
                        }
                        // console.log('after changes', reqObj.images)
                    })
                }
            }

            if(this.advancedImageReset.length>0){
                this.advancedImageReset.forEach(async e=>{
                    await Server.post(`/v1/asset/delete`, {
                        assetUrl: advanced[e.type],
                    });
                    advanced[e.type] = e.value;
                    this[e.type] =  e.value;
                    this.mesh.userData[e.type] = e.value;
                })
                this.advancedImageReset=[]
            }

            // do the action one after another
            // check for uploads
            if (this.toUpload > 0) {
                let uploads = [];
                if (has(this.bufferedChanges, 'logo')) {
                    if(this.bufferedChanges.logo){
                        uploads.push({
                            type: 'logo',
                            file: this.bufferedChanges.logo
                        })
                    }
                }

                if (has(this.bufferedChanges, 'file')) {
                    if(this.bufferedChanges.file){
                        uploads.push({
                            type: 'file',
                            file: this.bufferedChanges.file
                        })
                    }
                }

                if (has(this.bufferedChanges, 'images')) {
                    this.bufferedChanges.images.forEach(img => {
                        uploads.push({
                            type: 'images',
                            file: img
                        })
                    })
                }

                for (var i = 0; i < uploads.length; i++) {
                    const { file, type } = uploads[i];

                    let reqParams = new FormData();
                    reqParams.append('file', file);
                    reqParams.append("contentType", "maps");
                    reqParams.append("mapId", this.editor.editor3d.mapId);

                    const response = await Server.post('/v1/asset/upload', reqParams, { headers: {"Content-Type": "multipart/form-data"} })
                    if (response.status === 200) {
                        const link = response.data.data.file;
                        if (type === 'logo' || type === 'file') {
                            advanced[type] = link;
                            this[type] = link;
                            this.mesh.userData[type] = link;
                        } else {
                            // implementimages
                            let replaceImage = reqObj.images.find(img => img.order === file.order)
                            let isRepeatedObj = reqObj.images.indexOf(replaceImage)

                            //replace
                            if (isRepeatedObj > -1) {
                                let temp = cloneDeep(reqObj.images)
                                temp.splice(isRepeatedObj, 1, { ...replaceImage, link })
                                reqObj.images = temp.map(img => ({ url: img.link?img.link:img.url, order: img.order }))
                                temp = cloneDeep(this.images)
                                temp.splice(isRepeatedObj, 1, { ...this.images.find(img => img.order === file.order), link })
                                this.images = temp
                                temp = cloneDeep(this.mesh.userData.images)
                                temp.splice(isRepeatedObj, 1, { ...this.mesh.userData.images.find(img => img.order === file.order), link, name: file.name })
                                this.mesh.userData.images = temp
                            }
                            else {
                                reqObj.images = reqObj.images.length>0 ? [...reqObj.images[0].hasOwnProperty('link') 
                                ? reqObj.images.map(img => ({ url: img.link, order: img.order })):reqObj.images, { url: link, order: file.order }]:[...reqObj.images, { url: link, order: file.order }]
                                this.images = [...this.images, { url: link, order: file.order }]
                                this.mesh.userData.images = [ ...this.mesh.userData.images, { url: link, name: file.name, order: file.order }]
                            }
                        }
                    }
                }

                reqObj.advanced = { ...reqObj.advanced, ...advanced };
                this.imageCount = 0
                proceedSave();
            } else {
                reqObj.advanced = { ...reqObj.advanced, ...advanced };
                proceedSave();
            }

        }
        this.imgObj = null
    }

    onObjectChanged = (object) => {
        if (this.mesh === object) {
            if(this.position.distanceTo(object.position) > 0) {
                const pos = object.position.clone();
                pos.y -= object.scale.y * 0.4;
                this.position.copy(pos);
                this.updateObject(true);
            } else if(this.visible !== object.userData.visible) {
                this.visible = object.userData.visible;
                this.updateObject();
            }
        }
    }

    updatePinSkin = (color) => {
        const spriteTex = new THREE.TextureLoader().load(getPinSpriteTexture(this.texType, getPinColorIndex(color)));
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        this.spriteMaterial.map = spriteTex;
        this.spriteMaterial.needsUpdate = true;
    }

    updateObject = (flag = false) => {
        const idx = this.editor.getIndex('jsonLocationPins', this.id);
        let reqObj = { ...this.editor.jsonLocationPins[idx] }
        reqObj = omit(reqObj, ['type','amenityType']);
        reqObj = this.prepareObjForAPI(reqObj);

        // change pos & color here
        // reqObj.pinColor = this.pinColor;
        if(flag) {
            reqObj.position2d = { posX: this.position.x, posY: Math.abs(this.position.y) };
            reqObj.position = this.getTransformedPos({ posX: this.position.x, posY: Math.abs(this.position.y) });
        }
        reqObj.visible = this.visible;

        this.editor.jsonLocationPins[idx] = reqObj;
        this.editor.onPinAPICalls(reqObj, 'UPDATE');
    }

    onUpdateObject3DPositions = () => {
        // this.updateObject()
        const idx = this.editor.getIndex('jsonLocationPins', this.id);
        if(idx === -1) return;
        let reqObj = { ...this.editor.jsonLocationPins[idx] }
        reqObj = omit(reqObj, ['type','amenityType']);
        reqObj = this.prepareObjForAPI(reqObj);
        let changeObj = {
            id: reqObj.id
        }

        const pos = this.position.clone();
        pos.y -= this.mesh.scale.y * 0.4;

        if(this.editor.manualAligned === '3d_change'){
            reqObj.position2d = changeObj['position2d'] = { posX: pos.x, posY: Math.abs(pos.y) };
            reqObj.position = changeObj['position'] = this.getTransformedPos({ posX: pos.x, posY: Math.abs(pos.y) });

            // this.position3d.copy(fromPosObjectToVec3Pos(reqObj.position))
        } else if(this.editor.manualAligned === '2d_change') {
            const pos2d = this.get2dPosFrom3dPos(this.position3d.clone());
            reqObj.position2d = changeObj['position2d'] = { posX: pos2d.x, posY: Math.abs(pos2d.y) };
            reqObj.position = changeObj['position'] = fromVec3PosToPosObject(this.position3d);

            this.mesh.position.copy(pos2d);
            this.mesh.position.y += this.mesh.scale.y * 0.4;
            this.position.copy(this.mesh.position);
        }

        // console.log(changeObj);
        this.editor.jsonLocationPins[idx] = reqObj;
        this.editor.pinReadjustObj.push(changeObj);
        this.editor.trigger('requestPinPathReadjustment');
    }

    getTransformedPos = (pos) => {
        const { worldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6 
            ? getTransformed3DPos(worldMatrix, imgWidth, imgHeight, pos, true)
            : getTransformed3DPosV1(worldMatrix, imgWidth, imgHeight, pos, true);
    }

    get2dPosFrom3dPos = (pos3d) => {
        const { invWorldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6 
            ? getTransformed2DPos(invWorldMatrix, imgWidth, imgHeight, pos3d)
            : getTransformed2DPosV1(invWorldMatrix, imgWidth, imgHeight, pos3d);
    }

    onObjectRemoved = (object, delayAutosave, isSwitch) => {
        if (!isSwitch && object === this.mesh) {
            const idx = this.editor.getIndex('jsonLocationPins', this.id);
            const pin = this.editor.jsonLocationPins.splice(idx, 1);
            pin && this.editor.onPinAPICalls(pin[0]?.id, 'DELETE');
        } else if(isSwitch && object === this.mesh) {
            this.onCallDestructor(object);
        }
    }

    onObjectAdded = (object, fromHistory) => {
        if(fromHistory && object === this.mesh) {
            this.recreatePin();
        }
    }

    recreatePin = () => {
        let reqObj = { ...this.pinProps }
        reqObj = omit(reqObj, ['type','amenityType', "id"]);
        reqObj = this.prepareObjForAPI(reqObj);

        Server.post(`/v1/map/locationpin/${this.editor.editor3d.mapId}`, reqObj)
        .then((res) => {
            if(res.status === 200) {
                const data = res.data.data;
                console.log("pin recreated")
                this.id = data.id;
                this.pinProps = data;
                this.editor.jsonLocationPins.push(data);
            } else {
                console.log("pin recreation failed")
            }
            
        }).catch(e => console.log(e.response));
    }

    onCallDestructor = (object) => {
        if(object === this.mesh) {
            this.editor.stop('objectChanged', this.onObjectChanged);
            this.editor.stop('meta2dObjectChanged', this.onMetaObjectChanged);
            this.editor.stop('SaveMetaObject', this.onSaveMetaObject);
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            this.editor.stop('updateObject3DPositionsWithNewFloorMatrix', this.onUpdateObject3DPositions);
            dispose(this.mesh);
        }
    }

}