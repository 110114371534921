import EditorExperience from "../../2DEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";
import { UIButton, UIImage, UIPanel, UIRow, UISeparator } from "../../../ui/ui.lib";

//icon
import HelpIcon from '../../../../../assets/icons/sidebar/help.svg';

// menu
import { MenuView } from './2DMenu.View';
import { Menu2DHistory } from './2DMenu.History';
import { Menu2DPaths } from './2DMenu.Paths';
import { Menu2DPins } from "./2DMenu.Pins";
import { Menu2DHelper } from "./2DMenu.Helper";
import { Menu2DUILoader } from "./2DMenu.Loader";
import { Menu2DPens } from "./2DMenu.PenTools";
import { Menu2DFloorList } from './2DMenu.FloorList';
import { Menu2DConnectors } from './2DMenu.Connectors';
import { Menu2DPathStroke } from "./2DMenu.PathStroke";

// import { MenuRandomPins } from "./2DMenu.RandomPins";


class Menu2D extends EventEmitter {

    constructor(props) {
        super(props)
        this.editor = new EditorExperience();
        this.parentEle = props.dom;

        // set amenity
        this.editor.amenityTypes = props.amenityTypes;
        this.editor.isFloorMap = props.isFloorMap;

        this.menuOpen = true;

        this.editor.on('refreshUI', this.onRefreshUI);
        this.editor.on('navigationTracking', this.handleNavigationTracking);
        this.editor.on('objectSelected', this.onObjectSelected);

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel();
        this.uiContainer.setClass('wsMenu2DContainer')

        const uiCont = new UIRow();

        this.uiSepOne = new UISeparator();
        this.uiSepOne.setDisplay('none');

        this.menuView = new MenuView(this.editor);
        this.menuHistory = new Menu2DHistory(this.editor);
        this.menuPath = new Menu2DPaths(this.editor);
        this.menuPens = new Menu2DPens(this.editor);
        this.menuPins = new Menu2DPins(this.editor);
        this.menuHelper = new Menu2DHelper(this.editor);
        this.menuUiLoader = new Menu2DUILoader(this.editor);
        this.menuFloorList = new Menu2DFloorList(this.editor);
        this.menuConnectors = new Menu2DConnectors(this.editor);
        this.menuPathStroke = new Menu2DPathStroke(this.editor);

        // this.menuRandomPins = new MenuRandomPins(this.editor);

        uiCont.dom.appendChild(this.menuView.dom);
        uiCont.addChild(new UISeparator());
        uiCont.dom.appendChild(this.menuHistory.dom);
        uiCont.dom.appendChild(this.menuPath.dom);
        uiCont.dom.appendChild(this.menuPens.dom);
        uiCont.addChild(new UISeparator());
        uiCont.dom.appendChild(this.menuPathStroke.dom);
        uiCont.addChild(this.uiSepOne);
        uiCont.dom.appendChild(this.menuPins.dom);
        uiCont.dom.appendChild(this.menuHelper.dom);
        uiCont.dom.appendChild(this.menuConnectors.dom);

        uiCont.addChild(this.menuUiLoader.dom);

        this.uiContainer.addChild(uiCont);

        const uiBtnsCont = new UIRow();

        // help icon for connectors
        this.uiHelpBtn =  new UIImage(HelpIcon, '28px', '28px', 'Help').onClick(()=> {
            if(this.editor.menuConnectorsMode === 'CONNECTORS') {
                this.editor.onToggleInfoModals("ConnectorOnboarding", true);
            } else {
                this.editor.onToggleInfoModals('ToOnboarding', true, null);
            }
        });
        this.uiHelpBtn.addClass('tapbarHelpBtn');

        //button
        this.uiBtn =  new UIButton('Save & Exit', 'tapbarBtn', true).setId('wsSaveButtonButton').onClick(()=> {
            if(this.editor.pathController.startNavigationTrack || this.editor.locPinHelper.startNavigationTrack) return;
            
            this.editor.trigger('saveAndExit', [ true ]);
        })

        uiBtnsCont.addChild(this.uiHelpBtn);
        uiBtnsCont.addChild(this.uiBtn);

        this.uiContainer.addChild(uiBtnsCont);
        this.uiContainer.addChild(this.menuFloorList.dom);
        // this.uiContainer.addChild(this.menuRandomPins.dom);

        this.parentEle.append(this.uiContainer.dom);
    }

    onRefreshUI = () => {
    
    }

    onObjectSelected = (object) => {
        if(object && 'nodeMesh' in object.userData) {
            this.uiSepOne.setDisplay('');
        } else {
            this.uiSepOne.setDisplay('none');
        }
    }

    handleNavigationTracking = (flag) => {
        if(flag) {
            this.uiBtn.addClass('tapbarBtn--saveDisabled');
        } else {
            this.uiBtn.removeClass('tapbarBtn--saveDisabled');
            this.editor.trigger('toggleMenuActive', [null])
        }
    } 
}

export { Menu2D };