import { ReactComponent as PrintIcon } from "../../../../../assets/svgs/dash-landing/stepper/print.svg";
import { ReactComponent as MapIcon } from "../../../../../assets/svgs/dash-landing/stepper/map.svg";
import { ReactComponent as ActivateIcon } from "../../../../../assets/svgs/dash-landing/stepper/activate.svg";

import { ReactComponent as VisualIcon1 } from "../../../../../assets/svgs/qrhelper/QRHelper_6.svg";
import MultiAccessIcon  from "../../../../../assets/svgs/qrhelper/QRHelper_7.svg";


import { useState } from 'react';
import { ChevronUp, ChevronDown, ArrowRight } from "react-feather";

const Activation = ({onQRHelper}) => {
  const [ showVisual, toggleShowVisual ] = useState(true);

  return (
    <div className="stepper" style={{ textAlign: "center" }}>
      <div className="stepper__Header">
        How To Add Content And Additional QR Codes
      </div>
      <div>
        <label
          className="content-type-select mt-4"
          style={{ lineHeight: "normal" }}
        >
          Ensure you have the ARway app installed. You will need the app to
          finish the map setup.
        </label>
      </div>
      <div className="stepper__Card">
        <div className="mt-4">
          <PrintIcon />
          <div className="stepper__Header">Print and paste</div>
          <div>
            <label
              className="content-type-select mt-2"
              style={{ lineHeight: "normal" }}
            >
              Print out and post your QR code in the center of your map
            </label>
          </div>
          <div className="stepper__CardLink" onClick={() => onQRHelper(true)}>Display & Sizing Recommendations <ArrowRight size={18} /></div>
        </div>
        <div className="stepper__visualCard">
          <div className="stepper__visualCard--header">
            <h6>Visual Requirements</h6>
            {showVisual 
              ? <ChevronUp className="stepper__visualCard--chevron" size={22} onClick={() => toggleShowVisual(false)}/>
              : <ChevronDown className="stepper__visualCard--chevron" size={22} onClick={() => toggleShowVisual(true)}/>
            }
          </div>
          {showVisual && 
            <div>
              <div>
                <VisualIcon1 className="stepper__visualCard--icon"/>
                <ul className="stepper__visualCard--infoUl">
                  <li className="stepper__visualCard--infoLi">Ensure you have the measurement of the QR code before you anchor. You will need to input the dimensions.</li>
                  <li className="stepper__visualCard--infoLi">Display the QR code in a location where it cannot be moved. eg. affixed sign, wall, digital kiosk </li>
                  <li className="stepper__visualCard--infoLi">Avoid areas with poor lighting. </li>
                  <li className="stepper__visualCard--infoLi">Ensure QR codes are positioned upright or flat on the floor. <strong>Do not position the qr codes on slanted surfaces.</strong> </li>
                  <li className="stepper__visualCard--infoLi"><strong>Do not shift or move the QR code once anchored.</strong> Doing this will shift all AR content within your map. Use the edit tool to reposition and rescan. </li>
                </ul>
              </div>
              <br/>
              <div>
                <div className="stepper__visualCard--header">
                  <h6>Multiple access points</h6>
                </div>
                  <img src={MultiAccessIcon} className="stepper__visualCard--icon2" alt="multi-access-icon"/>
                  <div className="stepper__visualCard--textSpan">QR Access Points are unique codes for scanning to preview and edit your map, or for visitors to enter the map or correct drift. <strong>Strengthen the experience by adding multiple access points for additional entry options.</strong></div>
              </div>
            </div>

          }
        </div>
        <div className="mt-4">
          <MapIcon />
          <div className="stepper__Header">Open ARway app</div>
          <div>
            <label
              className="content-type-select mt-2"
              style={{ lineHeight: "normal" }}
            >
              Launch the ARway app and open this map in “My Maps”
            </label>
          </div>
        </div>
        <div className="mt-4">
          <ActivateIcon />
          <div className="stepper__Header">Activate map</div>
          <div>
            <label
              className="content-type-select mt-2"
              style={{ lineHeight: "normal" }}
            >
              Tap the activate button & follow the instructions in the app
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Activation;
