import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../../api';
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";

const getUserDetails = createAsyncThunk('settings/getUserDetails', async (userId, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/user/${userId}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const getTeamMembers = createAsyncThunk('settings/getTeamMembers', async (params, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/getusers`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const addNewTeamMember = createAsyncThunk('settings/addNewTeamMember', async (reqObj, { dispatch }) => {
    try {
        const response = await Server.post(`/v1/account/adduser`, reqObj);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const updateMemberRole = createAsyncThunk('settings/updateMemberRole', async (reqObj, { dispatch }) => {
    try {
        const response = await Server.post(`/v1/account/adduser`, reqObj);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const removeTeamMember = createAsyncThunk('settings/removeTeamMember', async (reqObj, { dispatch }) => {
    try {
        const response = await Server.post('/v1/account/deleteuser', reqObj);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const getUserPlanDetails = createAsyncThunk('settings/getUserPlanDetails', async (_, { dispatch }) => {
    try {
        const response = await Server.get("/v1/account/plandetails");
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const updateAccount = createAsyncThunk('settings/updateAccount', async (reqObj, { dispatch }) => {
    try {
        const response = await Server.put(`/v1/account`, reqObj);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const deleteAccount = createAsyncThunk('settings/deleteAccount', async (userId, { dispatch }) => {
    try {
        const response = await Server.delete(`/v1/user/${userId}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const initialState = {
    userDetails: null,
    planDetails: null,
    settingsLog: null,
    deleteLog: null,
    teamMembers: null,
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
    },
    extraReducers: {
        'common/resetState': () => {
            return { ...initialState }
        },
        [getUserDetails.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                userDetails: payload.data,
                settingsLog: null
            })
        },
        [getTeamMembers.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                teamMembers: payload.data,
                settingsLog: null
            })
        },
        [updateMemberRole.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                settingsLog: payload.status
            })
        },
        [addNewTeamMember.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                settingsLog: payload.status
            })
        },
        [removeTeamMember.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                settingsLog: payload.status
            })
        },
        [getUserPlanDetails.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                planDetails: payload.data,
                settingsLog: null
            })
        },
        [updateAccount.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                settingsLog: payload.status
            })
        },
        [deleteAccount.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                deleteLog: payload.status
            })
        },
    }
});

export const planDetails = (state) => state?.settings?.planDetails;

export {
    getUserDetails,
    getTeamMembers,
    addNewTeamMember,
    updateMemberRole,
    getUserPlanDetails,
    removeTeamMember,
    updateAccount,
    deleteAccount,
};

export default settingsSlice.reducer;