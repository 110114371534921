import React from "react";
import './style.css';

const Wrong = () => {
  return (
    <div className="failed-box-container">
      <div className="failed-box max-30">
        <h1 className="failed-header">Oops! Something went wrong!</h1>
        <p className="failed-body">
          The link you followed doesn't exist. Please resend the email and try
          again
        </p>
        <div className="failed-footer">
          <a
            href="https://maps-studio.nextechar.com/auth/login"
            className="footer-button"
            rel="noreferrer noopener"
          >
            Back to ARWay Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default Wrong;
