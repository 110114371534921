import * as THREE from 'three';

//Comp
import EditorExperience from '../2DEditorExperience';
import { OrbitControls } from '../../utils/OrbitControls';

export default class Camera {

    constructor() {

        this.experience = new EditorExperience();
        this.sizes = this.experience.sizes;
        this.canvas = this.experience.canvas;
        this.scene = this.experience.scene;

        this.initInstance();
        this.initOrbitControls();
    }

    initInstance = () => {
        this.instance = new THREE.PerspectiveCamera(
            60,
            (this.sizes.width / this.sizes.height),
            0.1,
            20000
        );
        this.instance.position.set(0, 0, 0);
        this.instance.name = "ARway-2DEditor-Camera"
        this.instance.lookAt(this.scene.position);
        this.scene.add(this.instance);
    }

    initOrbitControls = () => {
        this.controls = new OrbitControls(this.instance, this.canvas);
        this.controls.enableDamping = true;
        this.controls.enableRotate = false;
        this.controls.zoomSpeed = 1;
        this.controls.panSpeed = 1;
        this.controls.dampingFactor = 0.1;
        this.controls.maxDistance = 20000;
        this.controls.minDistance = 0.5;

        this.controls.mouseButtons = {
            LEFT: THREE.MOUSE.PAN,
            MIDDLE: THREE.MOUSE.DOLLY,
            RIGHT: THREE.MOUSE.ROTATE,
        };
    }

    resize = () => {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }

    update = () => {
        this.controls.update();
    }
}