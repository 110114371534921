import React from "react";
import { useSelector } from "react-redux";
import { Grid } from "@mui/material";

import TopFiveVisited from "./AllMaps/TopFiveVisited";
import VisitorBehavior from "./AllMaps/VisitiorBehavior";
import TotalMapVisitsOvertime from "./AllMaps/TotalMapVisitsOvertime";
import MapActivation from "./AllMaps/MapActivation";
import VisitiorBehaviorByMap from "./IndividualMaps/VisitiorBehaviorByMap";
import TotalMapVisitsOvertimeByMap from "./IndividualMaps/TotalMapVisitsOvertimeByMap";
import AccessPointsWithMostActivation from "./AllMaps/AccessPointsWithMostActivation";
import MapActivationByMap from "./IndividualMaps/MapActivationByMap";

const Overview = (props) => {
  const selectedMap = useSelector((state) => state.analytics.selectedMap);
  const topFiveVisited = useSelector((state) => state.analytics.topFiveVisited);
  const totalMapVisitsOvertime = useSelector(
    (state) => state.analytics.totalMapVisitsOvertime
  );

  return (
    <div>
      {selectedMap?.mapId === "allMaps" ? (
        <div>
          <VisitorBehavior />
          <Grid container={true} spacing={4}>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              {/* <MapVisitsByTimeOfDay/> */}
            </Grid>
          </Grid>
          <TopFiveVisited
            topFiveVisited={topFiveVisited}
            setTab={props.setTab}
          />
          <TotalMapVisitsOvertime
            totalMapVisitsOvertime={totalMapVisitsOvertime}
          />
          <MapActivation />
          <AccessPointsWithMostActivation />
        </div>
      ) : (
        <div>
          <VisitiorBehaviorByMap />
          <TotalMapVisitsOvertimeByMap />
          <MapActivationByMap />
        </div>
      )}
    </div>
  );
};

export default Overview;
