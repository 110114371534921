import React from 'react';
import AccountOverviewCard from './components/IndividualMaps/AccountOverviewCard';
import AccountTopFive from './components/IndividualMaps/AccountTopFiveCard';
import AccountAccessPointsCard from './components/IndividualMaps/AccountAccessPointsCard';
import AccountMapVisitsCard from './components/IndividualMaps/AccountMapVisitsCard';

export default function OverviewAccount() {

    return (
        <div>
            <AccountOverviewCard />
            <AccountTopFive />
            <AccountMapVisitsCard />
            <AccountAccessPointsCard />
        </div>
    )
}
