import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { Search, X } from "react-feather";
import { debounce } from "lodash";
import Dropdown from "../../../../webstudio/studiocommon/ImportMaps/VenuesDetails/Dropdown";
import LocationPinTable from "./LocationPinTable";
import {
  getLocationPins,
  searchLocationpins,
} from "../../../../../features/webstudio/locationSlice";
import { clearSearchedLocationPins } from "../../../../../features/webstudio/locationSlice";

function Locationpin({ state }) {
  const { mapDetails } = state;
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [onFocusMapname, setOnFocusMapname] = useState(false);
  const dispatch = useDispatch();
  const [level, setLevel] = useState(`0-${mapDetails?.maps[0]?.identifier}`);
  const [mapId, setMapId] = useState(mapDetails?.maps[0]?.mapId);
  let locationPins = useSelector(
    (state) => state.locationSlice?.locationPins?.locationPins
  );
  let searchedLocationPins = useSelector(
    (state) => state.locationSlice?.searchedLocationPins?.locationPins
  );

  useEffect(() => {
    if (
      (locationPins?.length === 0 || searchedLocationPins?.length === 0) &&
      loading
    ) {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationPins, searchedLocationPins]);

  useEffect(() => {
    let mapId = mapDetails?.maps.find(
      (m) =>
        m.mapId ===
        mapDetails?.maps.find((m, i) => `${i}-${m.identifier}` === level)[
          "mapId"
        ]
    )["mapId"];
    dispatch(getLocationPins(mapId));
    setMapId(mapId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  useEffect(() => {
    if (searchText.length < 2 && searchText.length !== 0) {
      setOnFocusMapname(true);
    }
    if (searchText.trim().length >= 2) {
      searchLocationPins(1);
      setOnFocusMapname(false);
    } else if (searchText.length <= 0) {
      setOnFocusMapname(false);
      handleClearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  var crossClass = "searchbar__closeIcon--hide";
  if (searchText.length > 0) {
    crossClass = "";
  }

  const handleSearchChange = (e) => {
    if (e.target.value.includes(" ") && e.target.value.length === 1) {
      e.target.value = e.target.value.replace(/\s/g, "");
    }
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    searchLocationPins(1);
    clearSearchedLocationPins();
  };

  const searchLocationPins = (pageNumber) => {
    setLoading(pageNumber === 1 ? true : false);
    dispatch(
      debounce(
        searchLocationpins({
          mapId,
          searchText,
          pageNumber,
          mapsType: "map",
        })
      ),
      250
    );
  };

  return (
    <div>
      <Box className="studiohome__mainRow">
        <div className="d-flex justify-content-between">
          <div className="searchbar" style={{ width: "30%" }}>
            <span className="searchbar__searchbarContainer">
              <Search
                size={20}
                color="#353E5A"
                className="searchbar__searchIcon"
              />
              <input
                type="text"
                autoComplete="off"
                placeholder="Search with Location name"
                value={searchText}
                onChange={handleSearchChange}
                id="input-mapsSearch"
                onKeyPress={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              />
              <X
                size={20}
                color="#353E5A"
                onClick={handleClearSearch}
                id="icon-clearSearch"
                className={`searchbar__closeIcon ${crossClass}`}
              />
            </span>
          </div>

          <h5 className="card-title mapdetails__title">
            <Dropdown
              list={mapDetails?.maps.map((m, i) => {
                return {
                  value: `${i}-${m.identifier}`,
                  label: `${m.identifier} - ${m.mapName}`,
                };
              })}
              setvalue={(val) => {
                setLevel(val);
              }}
            />
          </h5>
        </div>
        {searchText.length < 2 && onFocusMapname ? (
          <span className="p-1" style={{ color: "red" }}>
            Minimum two character required!
          </span>
        ) : null}
        <LocationPinTable loading={loading} searchText={searchText} />
      </Box>
    </div>
  );
}

export default Locationpin;
