import React, { useState } from 'react';
import AccountSelection from './AccountSelection';

import { 
    Box, Container,
    Tabs, Tab
} from '@mui/material';
import OverviewHome from './overview';
import VisitorBehaviorHone from './visitorbehavior';

import { navList } from './navlist';

export default function AdminAnalyticsHome() {

    const [ activeKey, setActiveKey] = useState(0);

    const a11yProps = (index) => {
        return {
            id: `admin-analytics-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    const renderContentTab = () => {
        switch(activeKey) {

            case 0:
                return (
                    <OverviewHome />
                )
            
            case 1:
                return (
                    <VisitorBehaviorHone />
                )
            
            default:
                return  null;
        }
    }

    return (
        <div >
            <Container maxWidth={false} >
                <div className="pageWrapper adanhome__padded">
                    <h4>Analytics</h4>
                </div>
                <AccountSelection />
                <div className="adanhome__padded">
                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={activeKey}
                            onChange={(e, value) =>  setActiveKey(value)}
                            aria-label="basic tabs example"
                        >
                            {navList.map((m) => (
                            <Tab
                                key={m.id}
                                label={m.tabLabel}
                                {...a11yProps(m.id)}
                                className={`studiohome__shTabHeader ${activeKey === m.id ? "studiohome__shTabHeader--active" : "" }`}
                            />
                            ))}
                        </Tabs>
                    </Box>
                    <Box className="analyticshome__mainRow">
                        {renderContentTab()}
                    </Box>
                </Box>
                </div>
            </Container>
        </div>
    )
}