import { ReactComponent as UploadBlankIcon } from "../../../../../assets/svgs/UploadBlankIcon.svg";
import React from "react";
import { validateMIME } from "../../../../common/utils";
import ARWayImage from "../../../../common/CO/ARWayImage";
import { isObject } from "lodash"

const  Thumbnail = React.memo(({ thumbnail, setThumbnail }) => {
  return (
    <div className="venue-content-modal" style={{ width: "35%" }}>
      <label className="content-type-select mt-4">Venue Thumbnail</label>
      {!isObject(thumbnail) && thumbnail? (
        <ARWayImage
          src={
            thumbnail
          }
          className={`card-img-top mapdetails__venuethumbnail`}
          alt="Map Thumbail image"
          style={{
            borderRadius: "8px",
            objectFit:'contain',
          }}
        />
      ) : (
        <div
          className="venue-thumbnail"
          style={{
            backgroundImage: `url(${
              thumbnail ? URL.createObjectURL(thumbnail) : ""
            })`,
          }}
        >
          {!thumbnail && <UploadBlankIcon />}
        </div>
      )}

      <label className="thumbnail-select-btn" htmlFor="content-thumbnail">
        {thumbnail ? "Change a different file" : "Select thumbnail (Optional)"}
      </label>
      <input
        id="content-thumbnail"
        type="file"
        accept="image/*"
        hidden
        onChange={(e) => {
          let acceptedFile = e.target.files[0];
          validateMIME(acceptedFile, "images", (flag) => {
            if (flag) {
              setThumbnail(acceptedFile);
            } else {
              setThumbnail(false);
            }
          });
        }}
      />
    </div>
  );
})

export default Thumbnail;
