import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
// import * as THREE from 'three';

import { UIButton, UIPanel, UIText, UILibraryCardModel } from "../ui.lib";

class SidebarContentModel extends EventEmitter {
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.dom = null;

        this.contentList = [];
        this.pageNumber = 1;

        this.contentState = "";
        this.contentType = "models";

        this.initUI();

        this.editor.on("toggleMenuActive", this.onToggleMenuActive);
        this.editor.on("fetchLibraryContentsResponse", this.updateData);
    }

    initUI = () => {
        // create invisible Input
        const form = document.createElement("form");
        form.style.display = "none";
        document.body.appendChild(form);

        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.id = "ModelUploader";
        fileInput.accept = ".glb";
        fileInput.multiple = false;

        fileInput.addEventListener("change", () => {
            this.editor.loader.initObjectLoading(
                [...fileInput.files],
                "models",
                "new"
            );
            form.reset();
        });

        form.appendChild(fileInput);

        this.uiMyContent = new UIPanel();

        this.uiUploadBtn = new UIButton("Upload").onClick(() => {
            if (this.editor.storageExceeds()) {
                this.editor.callbacks.generateAlert({
                    msg: "plan storage exceeded, please upgrade your storage to continue!",
                    alertType: "danger",
                });
            } else {
                fileInput.click();
            }
        });

        this.uiMyContent.addChild(this.uiUploadBtn);

        this.dom = this.uiMyContent.dom;
    };

    fetchData = (pageNumber) => {
        this.editor.trigger("fetchLibraryContentsRequest", [
            pageNumber,
            this.contentType,
        ]);
    };

    updateData = (data, contentType) => {
        // console.log(data);
        if (contentType === this.contentType) {
            if (this.pageNumber === 1 && data.length === 0) {
                this.contentState = "NO DATA";
            } else if (data.length === 0) {
                this.contentState = "LAST PAGE";
            } else {
                this.contentList.push(...data);
                this.contentList = [
                    ...new Map(
                        this.contentList.map((item) => [item["id"], item])
                    ).values(),
                ];
                this.pageNumber++;

                if (data.length < 10) {
                    this.contentState = "LAST PAGE";
                }
            }
            this.updateContentLibraryUI();
        } else this.emptyContent();
    };

    emptyContent = () => {
        var child = this.uiMyContent.dom.lastChild;
        while (child) {
            if (child === this.uiUploadBtn.dom) break;
            this.uiMyContent.dom.removeChild(child);
            child = this.uiMyContent.dom.lastChild;
        }
    };

    updateContentLibraryUI = () => {
        if (this.contentState === "NO DATA") {
            this.emptyContent();
            const uiNoDataCard = document.createElement("div");
            uiNoDataCard.className = "ws_uiNoDataCard";

            // header
            const uiHeader = document.createElement("div");
            uiHeader.classList.add("ws_uiNDHeader");
            uiHeader.textContent = "No Uploads Yet";

            const uiSubText = document.createElement("div");
            uiSubText.classList.add("ws_uiNDSubText");
            uiSubText.textContent =
                "Your recently uploaded 3D models will appear here.";

            uiNoDataCard.appendChild(uiHeader);
            uiNoDataCard.appendChild(uiSubText);

            this.uiMyContent.dom.appendChild(uiNoDataCard);
        } else {
            const uiCardsRow = new UIPanel();
            uiCardsRow.addClass("ws_uiCardsRowFloat");
            this.emptyContent();
            for (const model of this.contentList) {
                const uiImageCont = new UILibraryCardModel(
                    model,
                    model.thumbnail,
                    model.fileName,
                    this.onLibrayImageSelect
                );
                uiCardsRow.addChild(uiImageCont);
            }

            if (this.contentState !== "LAST PAGE") {
                const uiloadMoreBtn = new UIButton("Load More")
                    .onClick(() => {
                        this.fetchData(this.pageNumber);
                    })
                    .addClass("wsLoadMoreBtn");
                uiCardsRow.addChild(uiloadMoreBtn);
            }

            this.uiMyContent.addChild(
                new UIText(undefined, "Recently Uploaded")
            );
            this.uiMyContent.addChild(uiCardsRow);
        }
    };

    onLibrayImageSelect = (selectedContent) => {
        this.editor.loader.initObjectLoading(selectedContent);
    };

    refreshUI = () => {
        this.contentList = [];
        this.pageNumber = 1;
        this.fetchData(1);
        // check if user can upload
        this.updateBtnClasses();
    };

    updateBtnClasses = () => {
        if (this.editor.storageExceeds()) {
            this.uiUploadBtn.addClass("ws_uiButton--grayedButton");
        } else {
            this.uiUploadBtn.removeClass("ws_uiButton--grayedButton");
        }
    };

    onToggleMenuActive = () => {};

    unload = () => {
        this.contentList = [];
        this.contentState = "";
        this.pageNumber = 1;
        this.emptyContent();
    };
}

export { SidebarContentModel };
