import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Backdrop, Box,
    Modal, Fade,
    Tabs, Tab,
    Button
} from '@mui/material';

import {
    XCircle
} from 'react-feather';
import DropZoneTab from './DropZoneTab';
import FloorTab from './FloorTab';

import { getSampleFloors } from '../../../features/common/commonSlice';
import { setNewAlert } from '../../../features/common/alertSlice';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    minHeight: 400,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

const SampleFloorplans = ({ openModal, onCloseModal, onSuccess, modalData, defaultTab = 0}) => {

    const dispatch = useDispatch();

    const [ activeKey, setActiveKey ] = useState(defaultTab);
    const [ floorList, setFloorList ] = useState([]);
    const [ selectedFloor, setSelectedFloor ] = useState(null);

    const sampleFloors = useSelector(state => state.common.sampleFloors);

    useEffect(() => {
        if(openModal && modalData) {
            dispatch(getSampleFloors()); // floorplan API
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ openModal, modalData ]);

    useEffect(() => {
        if(sampleFloors && sampleFloors.length) {
            setFloorList(sampleFloors)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ sampleFloors ]);

    const handleClose = (e, reason) => {
        if(reason && reason === 'backdropClick') return;
        onCloseModal();
        setActiveKey(defaultTab);
        setSelectedFloor(null)
    }

    const handleChange = (e, key) => {
        setActiveKey(key);
    }

    const handleDropSelect = (file) => {
        setSelectedFloor({ type: 'file', data: file })
    }

    const handleFloorSelect = (url) => {
        setSelectedFloor({ type: 'url', data: url })
    }

    const handleUpload = () => {
        if(selectedFloor) {
            onSuccess({...selectedFloor});
            handleClose();
        } else {
            dispatch(setNewAlert({msg: 'Please select/upload a floor plan!', alertType: 'information'}))
        }
    }

    const a11yProps = (index) => {
        return {
            id: `mymaps-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={handleClose} id="button-modalClose"/>
                        <div className='sfModal'>
                            <div className='sfModal--sfCard'>
                                <h4 className='sfModal--sfCard--title'>Upload File</h4>
                                <div className='sfModal--mainRow'>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: '10px'}}>
                                        <Tabs
                                            value={activeKey}
                                            onChange={handleChange}
                                            aria-label="Connector Tabs"
                                        >
                                            <Tab
                                                label="Upload New File"
                                                {...a11yProps(0)}
                                                disableRipple
                                                className={`studiohome__shTabHeader ${
                                                    activeKey === 0 ? "studiohome__shTabHeader--active" : ""
                                                }`}
                                            />
                                            <Tab
                                                label="Sample Floor Plan"
                                                {...a11yProps(1)}
                                                disableRipple
                                                className={`studiohome__shTabHeader ${
                                                    activeKey === 1 ? "studiohome__shTabHeader--active" : ""
                                                }`}
                                            />
                                        </Tabs>
                                    </Box>
                                    <Box>
                                        {activeKey === 0 && <DropZoneTab onChange={handleDropSelect}/>}
                                        {activeKey === 1 && <FloorTab floors={floorList} onChange={handleFloorSelect}/>}
                                    </Box>
                                    <div className="publishModalBtnsDiv publishModalBtnsDiv--floatRight">
                                        <Button variant='outlined' color='primary' onClick={handleClose}>Cancel</Button>
                                        <Button variant='contained' color='primaryColors' className='mapdetails__mapModalBtnText' onClick={handleUpload}>Upload</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}

export default SampleFloorplans;