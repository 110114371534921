import React from 'react';
import GenericSwitch from '../../../../../common/toggleSwitch/GenericSwitch';


const MapAreaUnit = ({areaSQFt, setAreaSQFt}) => {

    return (
        <div className='mapAreaUnitCont'>
            <div className='mapAreaUnitDiv'>
                <div className='mapAreaUnitDiv__header'>Map Area Unit: </div>
                <div className='mapAreaUnitDiv__toggleCont'>
                    <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Mtr</div>
                    <GenericSwitch
                        enabled={areaSQFt}
                        onEnableToggle={(e) => setAreaSQFt(e.target.checked)}
                    />
                    <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Ft</div>
                </div>
            </div>
        </div>
    )
}


export { MapAreaUnit };