import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MoreVertical } from "react-feather";
import React, { useState, useEffect, useRef } from "react";
import editIcon from "../../../../assets/svgs/studio/locationPin/edit.svg";
import deleteIcon from "../../../../assets/svgs/studio/locationPin/delete.svg";

const SubCategoryItem = ({
  modalData,
  id,
  subcat,
  category,
  isComponentVisible1,
  handleEdit,
  handleDelete,
  register,
  errors,
  getValues
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id: subcat.id,
      data: { parent: category, children: subcat },
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [hover, setHover] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const editRef = useRef(null);

  useEffect(()=>{
    if(showDropdown){
      editRef.current.scrollIntoView({ behavior: "smooth",block: 'end',
      inline: 'nearest' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropdown]);

  useEffect(()=>{
    if(modalData.isEdit && modalData.data.id === subcat.id){
      setIsEdit(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="d-flex">
        <div className="d-flex ms-3">
          <div
            className={
              category.children.length === 0 || id === category.children.length
                ? isComponentVisible1
                  ? "v-line-default v-line"
                  : "v-line-default"
                : "v-line-default v-line"
            }
          ></div>
          <hr className="line"></hr>
        </div>
        {isEdit ? (
          <div style={{ width: "91%" }}>
            <div
              className={`border add-sub-category ${
                errors[`subcat${subcat.id}`]?.message ? "border-danger" : "border-dark"
              }`}
            >
              <input
                autoFocus={true}
                type="text"
                id="category"
                className="cat-input"
                style={{paddingLeft:'2.4rem'}}
                defaultValue={subcat.name}
                {...register(`subcat${subcat.id}`, {required:'SubCategory is required', maxLength:{value:100, message: 'Maximum allowed characters is 100'}})}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                    handleEdit({
                      category,
                      subcat: { ...subcat, name: getValues(`subcat${subcat.id}`) },
                    });
                    setIsEdit(false);
                  }
                }}
              />
            </div>
            <span className="span-error">{errors[`subcat${subcat.id}`]?.message}</span>
          </div>
        ) : (
          <div
            className="border border-dark add-sub-category child-text"
            onMouseEnter={(e) => {
              setHover(true);
            }}
            onMouseLeave={(e) => {
              setHover(false);
              setShowDropdown(false);
            }}
          >
              <div style={{ marginLeft: hover ? "-1rem" : "" }}>
                <MoreVertical
                  style={{
                    float: "left",
                    display: hover ? "block" : "none",
                    marginLeft: "-17px",
                  }}
                />
                <MoreVertical
                  style={{
                    float: "left",
                    display: hover ? "block" : "none",
                    marginLeft: "-17px",
                  }}
                />
              </div>
                  <div className="d-flex justify-content-between">
              <div className="subcat-Text">{subcat.name}</div>
              <div>
                <MoreVertical
                  style={{ float: "right", display: hover ? "block" : "none" }}
                  onClick={() => {
                    setShowDropdown(true);
                  }}
                />
              </div>
            </div>
            <div
              className={`cat-modal-dropdown dropdown-menu`}
              style={{ display: showDropdown ? "block" : "none" }}
            >
              <div
                className="dropdown-item child-text"
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
                onClick={() => {
                  setIsEdit(true);
                }}
              >
                <img
                  src={editIcon}
                  style={{ marginRight: "1rem", marginLeft: "-1rem" }}
                  alt="editIcon"
                ></img>
                Rename
              </div>
              <div
                className="dropdown-item child-text"
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
                onClick={() => {
                  handleDelete({ category, subcat });
                }}
              >
                <img
                  src={deleteIcon}
                  style={{ marginRight: "1rem", marginLeft: "-1rem" }}
                  alt="deleteIcon"
                ></img>
                Delete
              </div>
              <div ref={editRef}/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SubCategoryItem;
