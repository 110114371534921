import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../../api";
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";

const getSDKAnalytics = createAsyncThunk(
  "adminsdkanalytics/getSDKAnalytics",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
    };
    try {
      const response = await Server.get("/v1/sdk/analytics", {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getSDKUsers = createAsyncThunk('adminanalytics/getUsers', async ( { pageNumber, pageSize }, { dispatch}) => {
  try{
      const response = await Server.get(`/v1/account/sdkaccounts?limit=${pageSize}&page=${pageNumber}`);
      return response.data;
  } catch(e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
  }
});

const getSDKAccountAnalytics = createAsyncThunk(
  "adminsdkanalytics/getSDKAccountAnalytics",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      accountid: reqObj.accID,
    };
    try {
      const response = await Server.get(`/v1/sdk/account/analytics/`, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const searchUsers = createAsyncThunk(
  "adminsdkanalytics/searchUsers",
  async (searchString, { dispatch }) => {
    try {
      const response = await Server.get(
        `/v1/account/sdkaccounts?limit=10&page=1&q=${encodeURIComponent(searchString)}`
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const initialState = {
  sdkUsers: [],
  searchedSDKUsers: [],
  sdkAnalytics: null,
  userSDKAnalytics: null,
  sdkanalyticsLog: null,
};

const adminSDKAnalyticsSlice = createSlice({
  name: "adminsdkanalytics",
  initialState,
  reducers: {
    clearSearchedUsers: (state, action) => {
      return { ...state, searchedSDKUsers: [], userSDKAnalytics: null };
    },
    clearLog: (state, action) => {
      return { ...state, sdkanalyticsLog: null };
    },
  },
  extraReducers: {
    "common/resetState": () => {
      return { ...initialState };
    },
    [getSDKUsers.fulfilled]: (state, {payload}) => {
      return({
          ...state,
          sdkUsers: payload.data,
          totalPages: payload.totalPages,
          searchedSDKUsers: [],
          sdkanalyticsLog: null
      })
  },
    [getSDKAnalytics.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        sdkAnalytics: payload.data,
        sdkanalyticsLog: null,
      };
    },
    [getSDKAccountAnalytics.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        userSDKAnalytics: payload.data,
        sdkanalyticsLog: null,
      };
    },
    [searchUsers.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        searchedSDKUsers: payload.data,
        sdkanalyticsLog: null,
      };
    },
  },
});

export { getSDKAccountAnalytics, getSDKAnalytics, searchUsers,getSDKUsers };

export const { clearSearchedUsers, clearLog } = adminSDKAnalyticsSlice.actions;

export default adminSDKAnalyticsSlice.reducer;
