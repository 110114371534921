import React from 'react';
import { isEmpty, isNull } from "lodash";
import { Box } from "@mui/material";
import DatePicker from "../../../../../_utils/DatePicker";
import { useState, useEffect } from "react";
import RenderBarChart from '../../../../users/analytics/Render/RenderBarChart';
import Loader from '../../../../../common/loader';

const AccessPointsCard = ({ accessPointsSummary, handleChange, loading }) => {

    const [labels, setLabels] = useState(null);
    const [count, setCounts] = useState(null);

    const mapActObj = [
        { key: null, label: "" },
        { key: null, label: "" },
        { key: "qr", label: "QR Code" },
        { key: "iap", label: "IAP" },
        { key: null, label: "" },
        { key: null, label: "" },
    ];

    useEffect(() => {
        if (!isEmpty(accessPointsSummary)) {
            setLabels(mapActObj.map((obj) => obj["label"]));
            setCounts(
                mapActObj.map((obj) => {
                    if (obj["key"] === null) {
                        return 0;
                    } else {
                        return accessPointsSummary[obj["key"]];
                    }
                })
            );
        } else {
            setLabels(null);
            setCounts(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessPointsSummary]);


    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col-9">
                        <div className="analyticshome__anCard--contentRow--header">
                            Access Points
                        </div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker cb={(dateObj) => handleChange(dateObj)} />
                        </Box>
                    </div>
                </div>
                <hr />
                {((isNull(labels) || isNull(count)) && !loading) ? (
                    <div className='analyticshome__emptyTextCont'>
                        <h5 className="text-center analyticshome__emptyStateText">{" "}No Data available{" "}</h5>
                    </div>
                ) : loading ? (
                    <div className='analyticshome__emptyTextCont'>
                        <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                    </div>
                ) : (
                    RenderBarChart(labels, count, {}, "Number of times Scanned")
                )}
            </div>
        </div>
    );
};

export default AccessPointsCard;

