import React from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { ReactComponent as ModalCloseBtnIcon } from "../../../../assets/svgs/ModalCloseBtnIcon.svg";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "#ffffff",
  boxShadow:
    "0px 2px 16px rgba(40, 41, 61, 0.12), -2px 20px 32px rgba(96, 97, 112, 0.24)",
  p: 4,
  borderRadius: "16px",
};
const DeleteContentModal = ({
  type,
  item,
  open,
  handleClose,
  handleDeleteClick,
}) => {
  return (
    <Modal
      aria-labelledby="arway-upload-content-modal"
      aria-describedby="arway-upload-content-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style} className="upload-content-modal">
          <button className="upload-modal-close-btn" onClick={handleClose}>
            <ModalCloseBtnIcon />
          </button>
          <h1>Delete Content</h1>
          <p className="delete-modal-content">
            Delete <span>“{item?.fileName}”</span> from your content library?
            This will be removed from all maps containing this{" "}
            {type?.substr(0, type?.length - 1)}.
          </p>
          <p className="delete-modal-content">This cannot be undone.</p>
          <div className="delete-content-btns">
            <button onClick={handleClose} id="button-cancelItem">Cancel</button>
            <button className="delete" onClick={handleDeleteClick} id="button-deleteItem">
              Delete
            </button>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeleteContentModal;
