import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Tabs, Tab } from "@mui/material";
import { isEmpty } from "lodash";
import { ChevronLeft } from "react-feather";
import getNavlist from "./NavTab";

import Overview from "./Overview";
import MapSelection from "./MapSelection";
import TopVisitedMaps from "./TopVisitedMaps";
import VisitorBehaviour from "./VisitorBehaviour";

import { selectMap } from "../../../../features/user/analytics/analyticsSlice";

const Analytics = (props) => {
  const dispatch = useDispatch();

  const analytics = useSelector((state) => state.analytics);
  const selectedMap = useSelector((state) => state.analytics.selectedMap);
  const mapsList = useSelector((state) => state.analytics.mapsList);

  const [activeKey, setActiveKey] = useState(0);
  const [navList, setNavList] = useState(getNavlist(selectedMap));

  useEffect(() => {
    if (!isEmpty(mapsList)) {
      setNavList(
        getNavlist(mapsList.find((m) => m.mapId === selectedMap.mapId))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectMap) {
      setNavList(
        getNavlist(mapsList.find((m) => m.mapId === selectedMap.mapId))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectMap]);

  const changeActiveKey = (e, key) => {
    setActiveKey(key);
  };

  const a11yProps = (index) => {
    return {
      id: `analytics-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const renderContentPane = () => {
    switch (activeKey) {
      case 0:
        return <Overview setTab={(actKey) => setActiveKey(actKey)} />;
      case 1:
        return <TopVisitedMaps analytics={analytics} />;
      case 2:
        return <VisitorBehaviour analytics={analytics} />;
      default:
        return null;
    }
  };

  return (
    <div className="analyticshome">
      <Box padding="40px 60px">
        <div className="analyticshome__headerRow">
          <span className="analyticshome__headerText">Analytics</span>
        </div>
      </Box>
      <MapSelection
        setMap={(value) => dispatch(selectMap(value))}
        setActiveTab={() => setActiveKey(0)}
      />
      <Box padding="40px 60px">
        <div className="analyticshome__contentRow">
          {selectedMap.mapId !== "allMaps" ? (
            <span
              style={{ color: "#353E5A", cursor: "pointer" }}
              onClick={() => {
                dispatch(
                  selectMap({
                    mapId: "allMaps",
                    mapName: "All Maps",
                  })
                );
              }}
            >
              <ChevronLeft /> Back to All Maps
            </span>
          ) : null}
          <span className="analyticshome__contentRow--header analyticshome__emptyStateText">
            {navList.find((nav) => nav.id === activeKey).title}
          </span>
        </div>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeKey}
              onChange={changeActiveKey}
              aria-label="basic tabs example"
            >
              {navList.map((m) => (
                <Tab
                  key={m.id}
                  label={m.tabLabel}
                  {...a11yProps(m.id)}
                  className={`studiohome__shTabHeader ${
                    activeKey === m.id ? "studiohome__shTabHeader--active" : ""
                  }`}
                />
              ))}
            </Tabs>
          </Box>
          <Box className="analyticshome__mainRow">{renderContentPane()}</Box>
        </Box>
      </Box>
    </div>
  );
};

export default Analytics;
