import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { Input, Layout, } from 'antd';
//Action 
import { accessWithPrivateKey, resetPrivateAccessState } from '../../../../../features/auth/accessSlice';
import { setNewAlert } from '../../../../../features/common/alertSlice';
//Icons
import { Eye, EyeOff } from 'react-feather';

const SecretKeyCard = () => {
    const dispatch = useDispatch();

    const accessAuthrized = useSelector(state => state.access.accessAuthrized);

    const [privateKey, setPrivateKey] = useState(false);
    const [errors, setErrors] = useState({ privateKey: false });
    const [showKey, setShowKey] = useState(true);

    useEffect(() => {
        if (accessAuthrized === 'success') {
            dispatch(resetPrivateAccessState());
            dispatch(setNewAlert({ msg: "Your plan is updated Successfully!", alertType: "success" }));
            setPrivateKey('');
            setErrors({ privateKey: false });
            setShowKey(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessAuthrized])

    const handleSecretKey = (e) => {
        e.preventDefault();
        var reqObj = {
            code: privateKey,
            startdate: new Date().toLocaleDateString('sv').replaceAll('-', '')
        }
        dispatch(accessWithPrivateKey(reqObj));
    }

    var toSubmit = privateKey.length > 0;

    return (
        <Box className='accesshome__accessCard accesshome__accessCard--cardDash'>
            <h5 className='accesshome__accessCardHeader'>Already have a code?</h5>
            <div className='accesshome__acFeatureText2 accesshome__acFeatureText2--alignStart'>Enter your private code here.</div>
            <form onSubmit={(e) => e.preventDefault()} style={{ width: '100%' }}>
                <Box className='accesshome__uiFormControlRow'>
                    <Layout className="input-label-container-user" style={{ margin: '0px' }}>
                        <Input
                            type={showKey ? "text" : "password"}
                            id='privateKey'
                            value={privateKey}
                            className={`text-inputs ${errors.privateKey ? `error` : null}`}
                            placeholder="Enter Access Code"
                            onChange={e => setPrivateKey(e.target.value)}
                            autoComplete="off"
                            
                        />
                        <span className="showpassCont">
                            {showKey ?
                                <EyeOff strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowKey(false)} /> :
                                <Eye strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowKey(true)} />
                            }
                        </span>
                    </Layout>
                </Box>
                <button
                    className={toSubmit ? 'accesshome__accessCardBtn' : 'accesshome__accessCardBtn accesshome__accessCardBtn--disabled'}
                    type='submit' disabled={!toSubmit}
                    onClick={handleSecretKey}
                >Submit Code
                </button>
            </form>
        </Box>
    );
}

export default SecretKeyCard;
