import RenderLineChart from "../../Render/RenderLineChart";
import { Box } from "@mui/material";
import DatePicker from "../../../../../_utils/DatePicker";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getTotalMapVisitsOvertime } from "../../../../../../features/user/analytics/analyticsSlice";
import { lastTEDays } from "../../../../../_utils/DateUtils";
import { isEmpty, isNull } from "lodash";

const TotalMapVisitsOvertime = ({ totalMapVisitsOvertime }) => {

  const dispatch = useDispatch();
  const [dateObj, setDateObj] = useState(lastTEDays);
  const [labels, setLabels] = useState(null);
  const [visits, setVisits] = useState(null);

  useEffect(() => {
    if (!isEmpty(totalMapVisitsOvertime)) {
      setLabels(
        totalMapVisitsOvertime.map((visit) => new Date(`${visit.date}`))
      );
      setVisits(totalMapVisitsOvertime.map((visit) => visit.visits));
    } else {
      setLabels(null);
      setVisits(null);
    }
  }, [totalMapVisitsOvertime]);

  useEffect(() => {
    dispatch(getTotalMapVisitsOvertime(dateObj));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col-9">
            <div className="analyticshome__anCard--contentRow--header">
              Total Map Visits Over Time
            </div>
          </div>
          <div className="col-3" style={{ paddingRight: "2.5rem" }}>
            <Box component="span" className="analyticshome__anCard--filterBox">
              <span style={{ color: "#858993" }}>Date:</span>
              <DatePicker
                cb={(dateObj) => {
                  setDateObj(dateObj);
                  dispatch(getTotalMapVisitsOvertime(dateObj));
                }}
              />
            </Box>
          </div>
        </div>
        <hr />
        {isNull(labels) || isNull(visits) ? (
          <h5 className="text-center analyticshome__emptyStateText">
            {" "}
            No data available{" "}
          </h5>
        ) : (
          RenderLineChart(labels, visits, dateObj, "Number of Visits")
        )}
      </div>
    </div>
  );
};

export default TotalMapVisitsOvertime;
