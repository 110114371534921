import * as THREE from "three";
import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";

import { fromPosObjectToVec3Pos } from "../TransformConversions";

// UI
import { UIPanel, UIImage, UIText, UIRow } from "../../ui/ui.lib";

import expandIcon from "../../../../assets/svgs/scenes/expand.svg";

export default class FloorplanPeg extends EventEmitter {
  constructor(pegData) {
    super();
    this.editor = new EditorExperience();
    this.pegData = pegData;
    this.mesh = null;

    this.id = pegData.id;
    this.order = pegData.order;
    this.name = `Peg ${Number(this.order) + 1}`;

    this.thumbnail = "pegThumbnail" in pegData ? pegData.pegThumbnail : null;

    this.position = pegData.position
      ? fromPosObjectToVec3Pos(pegData.position)
      : new THREE.Vector3(0, 0, 0);

    this.uiCont = null;

    this.editor.on("objectSelected", this.onObjectSlected);
    this.editor.on("clearAndReset", this.onClearAndReset);

    this.setupSprite();
    this.initHTML();
  }

  initHTML = () => {
    //Init Img UI
    this.uiCont = new UIPanel();
    this.uiCont.addClass("wsIapContainer");

    const uiIapRow = new UIRow().addClass("wsIapContainer--iapHeaderRow");
    this.uiHeader = new UIText("80%", this.name).addClass(
      "wsIapContainer--iapHeaderText"
    );
    uiIapRow.addChild(this.uiHeader);
    uiIapRow.addChild(
      new UIImage(expandIcon, "25px", "25px", "expand").onClick(() =>
        this.editor.onToggleIAPModal(true, {
          name: this.name,
          description: this.description,
          thumbnail: this.thumbnail,
        })
      )
    );
    this.uiCont.addChild(uiIapRow);

    this.uiThumbnail = new UIImage(this.thumbnail, "100%", "130px", undefined, true).setStyle(
      "objectFit",
      "contain"
    );
    this.uiCont.addChild(this.uiThumbnail);

    this.uiCont.setDisplay("none");
    document.body.appendChild(this.uiCont.dom);
  };

  setupSprite = () => {
    const pegModel = this.editor.floorplanPeg.clone();
    pegModel.name = this.name;

    this.mesh = new THREE.Group();
    this.mesh.add(pegModel);
    this.mesh.name = this.name;
    this.mesh.userData["type"] = "floorplanPeg";
    this.mesh.userData["id"] = this.id;
    this.mesh.userData["transformation"] = "NO_TRANSFORM";
    this.mesh.userData["visible"] = true;
    this.mesh.userData["skipChild"] = "floorplanPeg"; // only to hide child hirarchy in scene content if any
    this.mesh.position.copy(this.position);

    // console.log("Peg", this.order, this.position);
  };

  onObjectSlected = (object) => {
    if (this.mesh === object) {
      this.uiCont.setDisplay("flex");
    } else {
      this.uiCont.setDisplay("none");
    }
  };

  onClearAndReset = (object) => {
    if (this.uiCont) {
      document.body.removeChild(this.uiCont.dom);
      this.uiCont.dom.remove();
    }
  };
}
