import React from "react";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
  TableSortLabel,
} from "@mui/material";
import { HmsToSeconds, getLDate } from "../../../../_utils/DateUtils";
import { isNumber } from "lodash"

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const renderTableData = (row) => {
  return Object.keys(row)
  // .filter(k=> k!=='growth')
  .map((k) => {
    let cellValue = row[k];
    if (k === "growth" && isNumber(row[k]) ) {
      cellValue = cellValue + "%";
      if (row[k] > 0) {
        cellValue = "+" + cellValue;
      }
    }
    return (
      <TableCell
        style={{
          fontFamily: "poppins",
          color: `${
            k === "growth"
              ? row[k] > 0
                ? "#008043"
                : row[k] < 0
                ? "#BD0101"
                : ""
              : ""
          }`,
        }}
        key={k}
      >
        {cellValue}
      </TableCell>
    );
  });
};

function descendingComparator(a, b, orderBy) {
  if (orderBy === "avgVisitTime" || orderBy === "totalVisitTime") {
    if (HmsToSeconds(a[orderBy]) < HmsToSeconds(b[orderBy])) {
      return -1;
    }
    if (HmsToSeconds(a[orderBy]) > HmsToSeconds(b[orderBy])) {
      return 1;
    }
  }
  if (orderBy === "publishedOn") {
    if (getLDate(a[orderBy]) < getLDate(b[orderBy])) {
      return -1;
    }
    if (getLDate(a[orderBy]) > getLDate(b[orderBy])) {
      return 1;
    }
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const sortedRowInfo = (rowArr, comparator) => {
  const stabilizeRowArr = rowArr.map((el, index) => [el, index]);
  stabilizeRowArr.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizeRowArr.map((el) => el[0]);
};

const RenderTable = ({ thheaderData, rowsData }) => {
  const [orderDirection, setOrderDirection] = useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = useState(thheaderData[0]);

  const handleRequestSort = (e, property) => {
    const isAsending = valueToOrderBy === property && orderDirection === "asc";
    setValueToOrderBy(property);
    setOrderDirection(isAsending ? "desc" : "asc");
  };

  const createSortHandler = (property) => (e) => {
    handleRequestSort(e, property);
  };

  return (
    <Table stickyHeader style={{ padding: "0px 32px 0px 32px" }}>
      <TableHead>
        <TableRow>
          {thheaderData.map((thObj) => (
            <TableCell key={thObj.key} className="fw600">
              <TableSortLabel
                active={valueToOrderBy === thObj.key}
                direction={
                  valueToOrderBy === thObj.key ? orderDirection : "asc"
                }
                onClick={createSortHandler(thObj.key)}
              >
                {thObj.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {sortedRowInfo(
          rowsData,
          getComparator(orderDirection, valueToOrderBy)
        ).map((rd, index) => (
          <StyledTableRow hover key={index}>
            {renderTableData(rd)}
          </StyledTableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RenderTable;
