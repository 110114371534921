import { Command } from './Command';

function SetPosition( editor, object, newPosition, optOldPosition, delayAutoSave) {
    Command.call(this, editor);

    this.type = 'SetPosition';
    this.name = 'Set Position';
    this.updatable = true;
    this.delayAutoSave = delayAutoSave;

    this.object = object;

    if(object !== undefined && newPosition !== undefined) {
        this.oldPosition = object.position.clone();
        this.newPosition = newPosition.clone();
        this.name = object.name;
    }

    if(optOldPosition !== undefined) {
        this.oldPosition = optOldPosition;
    }
}

SetPosition.prototype = {
    execute: function() {
        this.object.position.copy(this.newPosition);
        this.object.updateMatrixWorld();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave, this.name])
        this.editor.trigger('sceneAreaChanged');
    },

    undo: function() {
        this.object.position.copy(this.oldPosition);
        this.object.updateMatrixWorld();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave, this.name])
    },

    update: function(command) {
        this.newPosition.copy(command.newPosition);
    },

    toJSON: function() {
        var output = Command.prototype.toJSON.call(this);

        output.objectUuid = this.object.uuid;
        output.oldPosition = this.oldPosition.toArray();
        output.newPosition = this.newPosition.toArray();

        return output;
    }
}

export { SetPosition };