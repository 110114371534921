import * as THREE from 'three';
import { has } from 'lodash';
import Server from '../../../../api';
import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
import { getPinsTexture } from '../../utils/CustomSvgStrings';
import { DragControls } from '../../utils/DragControls';
import GuidedTourLine from './GuidedTour.line';
import {
    fromPosObjectToVec3Pos,
    fromQuaternionToQuaternionObject,
    fromVec3PosToPosObject,
    fromVec3ScaleToScaleObject,
    getRandomIdCode
} from '../TransformConversions';

class GuidedTour extends EventEmitter {
    /**
     * 
     * @param {required* string} tourType - "existing" || "new"
     * @param {optional} tourData;
     * 
     */
    constructor(
        tourType,
        tourData,
        manageNavigationTracking,
        operationsAfterTourEnd,
        checkNodeSelectionPriority,
        exitNewTourCreationFlow = null,
        getActiveTour
    ) {
        super();

        this.manageNavigationTracking = manageNavigationTracking;
        this.operationsAfterTourEnd = operationsAfterTourEnd;
        this.checkNodeSelectionPriority = checkNodeSelectionPriority;
        this.exitNewTourCreationFlow = exitNewTourCreationFlow;
        this.getActiveTour = getActiveTour;

        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;

        // Three.js Elements
        this.raycaster = new THREE.Raycaster();

        // Local Variables
        this.tourData = tourData;
        this.tourId = tourData.id;
        this.tourType = tourType;   // ["existing", "new"]
        this.raycastObjects = [];
        this.nodes = [];
        this.activeHoveredNode = null;
        this.draggableNode = null;
        this.selectedNode = null;
        this.activeNode = -1;
        this.panState = false;
        this.mouseInCanvas = false;
        this.addNewNode = false;
        this.removeNode = false;

        // svgs
        this.tourSize = 1;
        this.referenceSpotSvg = null;
        this.startPinSvg = null;
        this.startSpotSvg = null;
        this.placedSpotSvg = null;
        this.nextSpotSvg = null;
        this.endPinSvg = null;
        this.endSpotSvg = null;
        this.hoverSpotSvg = null;

        // default colors
        this.defaultGrayColor = "#656565";
        this.pathColor = "#36CCE7";

        this.bufferedChanges = {};
        this.toUpload = 0;
        this.imageCount = 0;
        this.advancedImageReset = []

        this.file = tourData.file ? tourData.file : null;
        this.image = tourData?.images?.length ? tourData.images[0] : null;
        this.wheelchairAccessible = tourData.wheelchairAccessible || false;
        this.pathNavStyle = tourData.navigationStyle || "JumboChevrons";
        this.isCreatedFromApp = tourData.isCreatedFromApp || false;
        this.waypoints = tourData?.waypoints || [];

        // pins, spots, groups, and meterials
        this.helperNodesMaterial = null;
        this.placedNodeMaterial = null;
        this.hoveredNodeMaterial = null;
        this.startPinMesh = null;
        this.startSpotMesh = null;
        this.pathMesh = null;
        this.referencePlane = null;
        this.referenceLine = null;
        this.referenceSpot = null;
        this.startPinGroup = new THREE.Group();
        this.endPinGroup = new THREE.Group();

        this.guidedTourLine = new GuidedTourLine(this.addLine, tourData.pathColor || this.pathColor);
        this.dragControls = null;

        // Create a group and push the nodes as child 
        // to the group to add meshes to scene
        this.tourGroup = this.cloneTourGroup();
        this.editor.addObjects(this.tourGroup);

        if (tourType === "new") {
            this.initPins();
        }

        if (tourType === "existing") {
            this.pathColor = tourData.pathColor;

            this.initPins();
        }

        this.editor.on("nodeElevated", this.onNodeElevation);
        this.editor.on("nodeOperationsSelected", this.onNodeOperationsSelected);
        this.editor.on("transformModeChanged", this.onTransfromModeChanged);

        //Properties Toggle Signals
        this.editor.on("MetaObjectChanged", this.onMetaObjectChanged);
        this.editor.on("SaveMetaObject", this.onSaveMetaObject);

        this.editor.resources.on("ready", this.computeTourSize);
    }

    /**
     * 
     * Configure Start and End pin meshes:
            1. applying svgs on two plains, where top plain is 
            billboard (Sprite and Sprite Material), and bottom is location dot
     * 
     */
    initPins = async () => {
        if (this.tourType === "new") {
            // Load all required svgs
            this.computeTourSize();
            await this.loadSvgsForNewTour();

            // After Loading the pins then initiate Drawing
            this.initNewTourDrawing();
        }

        if (this.tourType === "existing") {
            await this.loadSvgsForExistingTour(this.pathColor);
            this.initExistingTourDrawing();
        }
    }

    computeTourSize = () => {
        this.scene.children.forEach((child) => {
            if (child.userData && child.userData.navHelper && child.userData.navHelper === "floorplan") {
                const size = new THREE.Vector3();
                const box = new THREE.Box3();
                box.setFromObject(child);
                box.getSize(size);

                const minSize = Math.min(size.x, size.z);

                this.tourSize = Math.round(minSize * 0.025);

                if (this.tourSize === 0) {
                    this.tourSize = 1;
                }

                // Resize existing tours nodes
                if (this.tourType === "existing") {
                    if (this.tourSize <= 1) {
                        if (this.startPinMesh) this.startPinMesh.position.y = (this.meshHeightToCenter(this.startPinMesh) * this.tourSize) + 0.1;
                        if (this.endPinMesh) this.endPinMesh.position.y = (this.meshHeightToCenter(this.endPinMesh) * this.tourSize) + 0.1;
                    } else {
                        if (this.startPinMesh) this.startPinMesh.position.y += (this.meshHeightToCenter(this.startPinMesh) + (0.3 * this.tourSize));
                        if (this.endPinMesh) this.endPinMesh.position.y += (this.meshHeightToCenter(this.endPinMesh) + (0.2 * this.tourSize));
                    }

                    this.nodes.forEach((node) => {
                        if (node.isGroup) {
                            node.children.forEach((child) => {
                                if (child.isSprite) {
                                    child.scale.setScalar(0.7 * this.tourSize);
                                } else {
                                    child.scale.setScalar(0.31 * this.tourSize);
                                }
                            })
                        } else if (node.isMesh) {
                            // const defaultMeshScale = node.scale.x;
                            node.scale.setScalar(0.31 * this.tourSize);
                        }
                    })
                }
            }
        });

        if (this.isCreatedFromApp) {
            this.configureReferencePlane();
        }
    }

    onTransfromModeChanged = (param) => {
        switch (param) {
            case "translate":
                if (this.addNewNode) this.scrapNewNodeAddProcess();
                if (this.removeNode) this.removeNode = false;
                break;

            case "edit":
                if (this.addNewNode) this.scrapNewNodeAddProcess();
                if (this.removeNode) this.removeNode = false;
                break;

            default:
                break;
        }
    }

    onNodeOperationsSelected = (nodeOperationType) => {
        switch (nodeOperationType) {
            case "addNode":
                if (this.removeNode) this.removeNode = false;
                this.emptySelectedNode();

                if (this.getActiveTour(this.tourId)) {
                    this.addNewNode = true;
                    this.newNodeMesh = this.getHelperNode();
                    this.newNodeMesh.visible = false;
                    this.tourGroup.add(this.newNodeMesh);
                    this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);

                    // Raycast only on the path
                    this.tourGroup.children.forEach((child) => {
                        if (child.userData.isPathGeometry) {
                            this.raycastObjects = [child];
                        }
                    });
                }
                break;

            case "removeNode":
                if (this.addNewNode) this.scrapNewNodeAddProcess();
                this.emptySelectedNode();

                if (this.getActiveTour(this.tourId)) {
                    this.removeNode = true;
                }
                break;

            default:
                if (this.addNewNode) this.scrapNewNodeAddProcess();
                if (this.removeNode) this.removeNode = false;
                break;
        }
    }

    onMetaObjectChanged = (object, property, value, discardAll = false, uploadCnt = 0) => {
        if (object === this.tourGroup) {
            'pathColor' === property && this.refreshTour(value);

            this.bufferedChanges[property] = value;
            this.toUpload += uploadCnt;
            if ((property === 'image' || property === 'file') && value === null) {
                this.advancedImageReset.push({
                    type: property,
                    value
                })
            }
        } else if (discardAll) {
            'pathColor' in this.bufferedChanges && this.refreshTour();
            this.bufferedChanges = {};
            this.toUpload = 0;
        }
    };

    onSaveMetaObject = async () => {
        if (Object.keys(this.bufferedChanges).length > 0) {

            const proceedSave = () => {
                this.editor.toAutoSave && this.editor.trigger('autoSaveSceneGraphState');
                this.editor.deselect();
            }

            if (has(this.bufferedChanges, "name")) {
                this.tourGroup.name = this.bufferedChanges.name;
            }

            if (has(this.bufferedChanges, "description")) {
                this.tourGroup.userData.description = this.bufferedChanges.description;
            }

            if (has(this.bufferedChanges, 'navigationStyle')) {
                this.pathNavStyle = this.bufferedChanges.navigationStyle;
                this.tourGroup.userData.navigationStyle = this.bufferedChanges.navigationStyle;
            }

            if (has(this.bufferedChanges, 'pathColor')) {
                this.pathColor = this.bufferedChanges.pathColor;
                this.tourGroup.userData.pathColor = this.bufferedChanges.pathColor;
            }

            if (has(this.bufferedChanges, 'wheelchairAccessible')) {
                this.wheelchairAccessible = this.bufferedChanges.wheelchairAccessible;
                this.tourGroup.userData.wheelchairAccessible = this.bufferedChanges.wheelchairAccessible;
            }

            if (this.advancedImageReset.length > 0) {
                const scope = this;
                this.advancedImageReset.forEach(e => {
                    const assetUrl = scope[e.type]?.link;
                    if (assetUrl) {
                        this[e.type] = e.value;
                        this.tourGroup.userData[e.type] = e.value;
                        Server.post(`/v1/asset/delete`, { assetUrl });
                    }
                })
                this.advancedImageReset = [];
            }

            // do the action one after another
            // check for uploads
            if (this.toUpload > 0) {
                let uploads = [];
                if (has(this.bufferedChanges, 'file')) {
                    if (this.bufferedChanges.file) {
                        uploads.push({
                            type: 'file',
                            file: this.bufferedChanges.file
                        })
                    }
                }

                if (has(this.bufferedChanges, 'image')) {
                    uploads.push({
                        type: 'image',
                        file: this.bufferedChanges.image
                    })
                }

                for (var i = 0; i < uploads.length; i++) {
                    const { file, type } = uploads[i];

                    let reqParams = new FormData();
                    reqParams.append('file', file);
                    reqParams.append("contentType", "maps");
                    reqParams.append("mapId", this.editor.mapId);

                    const response = await Server.post('/v1/asset/upload', reqParams, { headers: { "Content-Type": "multipart/form-data" } })
                    if (response.status === 200) {
                        const link = response.data.data.file;
                        this[type] = {
                            name: file.name,
                            link
                        }
                        this.tourGroup.userData[type] = {
                            name: file.name,
                            link
                        };
                    }
                }
                proceedSave();
            } else {
                proceedSave();
            }

        }
        this.imgObj = null
    }

    refreshTour = async (color = this.pathColor) => {

        await this.loadSvgsForExistingTour(color);
        this.guidedTourLine.refreshLineMesh(color);
        this.refreshNodes(color);
    }

    refreshNodes = (color) => {
        this.nodes.forEach((node, index) => {
            if (index === 0) {
                const startPinMaterial = new THREE.SpriteMaterial({
                    map: this.startPinSvg,
                });

                this.startSpotMaterial = [
                    new THREE.MeshBasicMaterial({ color: color }),
                    new THREE.MeshBasicMaterial({ map: this.startSpotSvg }),
                    new THREE.MeshBasicMaterial({ map: this.startSpotSvg }),
                ];

                node.children.forEach((b) => {
                    if (b.isSprite) {
                        b.material = startPinMaterial;
                    } else if (b.isMesh) {
                        b.material = this.startSpotMaterial;
                    }
                });
            } else if (index === this.nodes.length - 1) {

                const endPinMaterial = new THREE.SpriteMaterial({
                    map: this.endPinSvg,
                });
                this.endSpotMaterial = [
                    new THREE.MeshBasicMaterial({ color: color }),
                    new THREE.MeshBasicMaterial({ map: this.endSpotSvg }),
                    new THREE.MeshBasicMaterial({ map: this.endSpotSvg }),
                ];

                node.children.forEach((b) => {
                    if (b.isSprite) {
                        b.material = endPinMaterial
                    } else if (b.isMesh) {
                        b.material = this.endSpotMaterial;
                    }
                });
            } else {
                node.material = this.placedNodeMaterial;
            }
        })
    }


    scrapNewNodeAddProcess = () => {
        if (this.addNewNode) this.addNewNode = false;

        if (this.newNodeMesh) {
            this.raycastObjects = this.nodes;
            this.tourGroup.remove(this.newNodeMesh);
            this.newNodeMesh = null;
        }
    }

    configureStartPin = () => {
        // Start Pin
        const startPinMaterial = new THREE.SpriteMaterial({
            map: this.startPinSvg,
        });
        this.startPinMesh = new THREE.Sprite(startPinMaterial);
        this.startPinMesh.scale.setScalar(0.7 * this.tourSize);
        this.startPinMesh.position.y += (this.meshHeightToCenter(this.startPinMesh) + (0.2 * this.tourSize));
        this.startPinMesh.userData['index'] = 0;
        this.startPinGroup.add(this.startPinMesh);

        // Start Spot
        const cylinderGeometry = new THREE.CylinderGeometry(0.5, 0.5, 0.75);
        this.startSpotMaterial = [
            new THREE.MeshBasicMaterial({ color: this.pathColor }),
            new THREE.MeshBasicMaterial({ map: this.tourType === "existing" ? this.placedSpotSvg : this.startSpotSvg, }),
            new THREE.MeshBasicMaterial({ map: this.tourType === "existing" ? this.placedSpotSvg : this.startSpotSvg, }),
        ];
        this.startSpotMesh = new THREE.Mesh(cylinderGeometry, this.startSpotMaterial);
        this.startSpotMesh.position.y = 0.01;
        this.startSpotMesh.scale.setScalar(0.31 * this.tourSize);

        // Set Group on the floor/plan
        this.startPinGroup.position.y = -1.0;
        this.startSpotMesh.userData['index'] = 0;
        this.startPinGroup.add(this.startSpotMesh);

        // Add this pin to nodes array
        this.addNode(this.startPinGroup);
    }

    initMaterialsForCreateOrEditTour = (color = this.pathColor) => {
        // init placed node material
        this.placedNodeMaterial = [
            new THREE.MeshBasicMaterial({ color: color }),
            new THREE.MeshBasicMaterial({ map: this.placedSpotSvg }),
            new THREE.MeshBasicMaterial({ map: this.placedSpotSvg }),
        ];

        // init helper nodes material
        this.helperNodesMaterial = [
            new THREE.MeshBasicMaterial({ color: this.tourType === "new" ? color : "#000000" }),
            new THREE.MeshBasicMaterial({ map: this.nextSpotSvg }),
            new THREE.MeshBasicMaterial({ map: this.nextSpotSvg }),
        ];

        this.hoveredNodeMaterial = [
            new THREE.MeshBasicMaterial({ color: color }),
            new THREE.MeshBasicMaterial({ map: this.hoverSpotSvg }),
            new THREE.MeshBasicMaterial({ map: this.hoverSpotSvg }),
        ];
    }

    configureEndPin = () => {
        // End Pin
        const endPinMaterial = new THREE.SpriteMaterial({
            map: this.endPinSvg,
        });
        this.endPinMesh = new THREE.Sprite(endPinMaterial);
        this.endPinMesh.scale.setScalar(0.7 * this.tourSize);
        this.endPinMesh.position.y += (this.meshHeightToCenter(this.endPinMesh) + (0.2 * this.tourSize));
        this.endPinGroup.add(this.endPinMesh);

        // End Spot
        this.endSpotMaterial = [
            new THREE.MeshBasicMaterial({ color: this.pathColor }),
            new THREE.MeshBasicMaterial({ map: this.endSpotSvg }),
            new THREE.MeshBasicMaterial({ map: this.endSpotSvg }),
        ];

        const cylinderGeometry = new THREE.CylinderGeometry(0.5, 0.5, 0.75);
        this.endSpotMesh = new THREE.Mesh(cylinderGeometry, this.endSpotMaterial);
        this.endSpotMesh.name = "endPinSpot";
        this.endSpotMesh.position.y = 0.01;
        this.endSpotMesh.scale.setScalar(0.31 * this.tourSize);
        this.endPinGroup.add(this.endSpotMesh)
    }

    /**
     * @returns:- A geometry i.e node that appers between start and end nodes
     */
    getHelperNode = () => {
        // Reusing the geometry
        const spot = this.startSpotMesh.clone();
        spot.material = this.helperNodesMaterial;
        spot.scale.setScalar(0.31 * this.tourSize);

        return spot;
    }

    getPlacedNode = () => {
        const spot = this.startSpotMesh.clone();
        spot.material = this.placedNodeMaterial;
        spot.scale.setScalar(0.31 * this.tourSize);

        return spot;
    }

    meshHeightToCenter = (mesh) => {
        const boundingBox = new THREE.Box3().setFromObject(mesh);
        const meshHeight = boundingBox.max.y - boundingBox.min.y;
        const translationY = meshHeight / 2;

        return translationY;
    }

    loadSvgsForNewTour = async () => {
        // Load svgs as texture
        this.startPinSvg = await getPinsTexture("startPinSvg", this.defaultGrayColor, 512);
        this.startSpotSvg = await getPinsTexture("startSpotSvg", "", 512);
        this.placedSpotSvg = await getPinsTexture("placedSpotSvg", this.pathColor, 512);
        this.nextSpotSvg = await getPinsTexture("nextSpotSvg", this.pathColor, 512);
        this.endPinSvg = await getPinsTexture("endPinSvg", "", 512);
        this.endSpotSvg = await getPinsTexture("endSpotSvg", this.pathColor, 512);

        // Load Start pin
        this.configureStartPin();
        this.initMaterialsForCreateOrEditTour();
    }

    loadSvgsForExistingTour = async (color) => {
        this.referenceSpotSvg = await getPinsTexture("startSpotSvg", "", 512);
        this.startPinSvg = await getPinsTexture("startPinSvg", color, 512);
        this.placedSpotSvg = await getPinsTexture("placedSpotSvg", color, 512);
        this.nextSpotSvg = await getPinsTexture("selectedSpotSvg", color, 512);
        this.endPinSvg = await getPinsTexture("endPinSvg", "", 512);
        this.endSpotSvg = await getPinsTexture("endSpotSvg", color, 512);
        this.hoverSpotSvg = await getPinsTexture("hoverSpotSvg", color, 512);

        // Lodad Start Pin
        this.initMaterialsForCreateOrEditTour(color);
    }

    initExistingTourDrawing = async () => {
        this.handleNavigationTrackingTrigger(false);

        // Drag Feature
        this.configureDragFeature();

        // Create Tour meshes and Draw Points
        this.tourData.nodes.forEach((node, index) => {
            const pos = node.position;;
            let posVec3;
            if ('x' in pos) {
                posVec3 = new THREE.Vector3(pos.x, pos.y, pos.z);
            } else {
                posVec3 = fromPosObjectToVec3Pos(pos);
            }

            if (index === 0) {
                this.configureStartPin();
                this.startPinGroup.position.copy(posVec3);
                this.startPinGroup.userData['index'] = 0;
                this.startPinGroup.children.forEach((child) => {
                    child.userData['index'] = 0;
                })
                this.tourGroup.add(this.startPinGroup);
            } else if (index === (this.tourData.nodes.length - 1)) {
                this.configureEndPin();
                this.endPinGroup.position.copy(posVec3);
                this.endPinGroup.userData['index'] = index;
                this.endPinGroup.children.forEach((child) => {
                    child.userData['index'] = index;
                })
                this.addNode(this.endPinGroup);
                this.tourGroup.add(this.endPinGroup);
            } else {
                const node = this.getPlacedNode();
                node.position.copy(posVec3);
                node.userData['index'] = index;
                this.addNode(node);
                this.tourGroup.add(node);
            }
        });

        // Draw Lines using the points
        for (let i = 0; i < this.nodes.length; i++) {
            const points = [this.nodes[i].position.clone()];
            this.guidedTourLine.addPoints(points);
        }

        // Assign nodes to raycast object for raycasting
        this.raycastObjects = this.nodes;

        if (this.raycastObjects.length) {
            this.startEventListenerForNewOrEditPath();
        }
    }

    configureDragFeature = () => {
        if (this.isCreatedFromApp) {
            if (!this.referencePlane) this.configureReferencePlane();
            this.appTourDragFeature();
            return;
        }

        // Find a plane to drag nodes on it
        let planeToDragOn = null;
        this.scene.children.forEach((child) => {
            if (child.userData && child.userData.navHelper && child.userData.navHelper === "floorplan") {
                planeToDragOn = child;
            }
        });

        // Make a instance on drag control to drag the node if plane exist
        if (planeToDragOn) {
            this.dragControls = new DragControls(planeToDragOn, this.camera.instance, this.canvas);
            this.hanldeDragEvent();
        }
    }

    configureReferencePlane = async () => {
        this.referenceSpotSvg = await getPinsTexture("startSpotSvg", "", 512);

        this.scene.children.forEach((child) => {
            if (child.userData && child.userData.navHelper && child.userData.navHelper === "floorplan") {

                const linePoints = [new THREE.Vector3(0, -50, 0), new THREE.Vector3(0, 50, 0)];
                const lineGeo = new THREE.BufferGeometry().setFromPoints(linePoints);
                const lineMat = new THREE.LineBasicMaterial({
                    color: "red",
                });
                this.referenceLine = new THREE.Line(lineGeo, lineMat);
                this.referenceLine.visible = false;
                this.referenceLine.userData['skipScene'] = true;
                this.referenceLine.userData['skipChild'] = true;
                this.referenceLine.userData['TourHelper'] = true;

                const rPlaneGeo = new THREE.PlaneGeometry(0.5, 0.5);
                const rPlaneMat = new THREE.MeshBasicMaterial({
                    map: this.referenceSpotSvg,
                    transparent: true,
                    side: THREE.DoubleSide,
                });
                this.referenceSpot = new THREE.Mesh(rPlaneGeo, rPlaneMat);
                this.referenceSpot.visible = false;
                this.referenceSpot.position.y = child.position.y;
                this.referenceSpot.quaternion.copy(child.quaternion);

                this.referenceSpot.userData['skipScene'] = true;
                this.referenceSpot.userData['skipChild'] = true;
                this.referenceSpot.userData['TourHelper'] = true;

                const planeGeo = new THREE.PlaneGeometry(500, 500);
                const planeMaterial = new THREE.MeshBasicMaterial({
                    color: 'red',
                    transparent: true,
                    opacity: 0,
                    side: THREE.DoubleSide,
                    depthTest: false,
                    depthWrite: false
                });
                this.referencePlane = new THREE.Mesh(planeGeo, planeMaterial);
                this.referencePlane.userData['skipScene'] = true;
                this.referencePlane.userData['skipChild'] = true;
                this.referencePlane.userData['TourHelper'] = true;


                this.referencePlane.position.copy(child.position);
                this.referencePlane.quaternion.copy(child.quaternion);

                this.referencePlane.visible = true;
                this.scene.add(this.referencePlane, this.referenceLine, this.referenceSpot);
                this.editor.nonInteractiveObjects.push(this.referencePlane, this.referenceLine, this.referenceSpot);
            }
        });


    }

    appTourDragFeature = () => {
        if (!this.selectedNode || !this.referencePlane) return;

        if (this.selectedNode.userData.index === 0) {
            this.referencePlane.position.y = this.nodes[0].position.y;
        } else if (this.selectedNode.userData.index === this.nodes.length - 1) {
            this.referencePlane.position.y = this.nodes[this.nodes.length - 1].position.y;
        } else {
            this.referencePlane.position.y = this.selectedNode.position.y;
        }

        if (this.referenceLine) {
            this.referenceLine.position.x = this.selectedNode.position.x;
            this.referenceLine.position.z = this.selectedNode.position.z;
        }

        if (this.referenceSpot) {
            this.referenceSpot.position.x = this.selectedNode.position.x;
            this.referenceSpot.position.z = this.selectedNode.position.z;
        }

        if (this.referencePlane) {
            this.dragControls = null;
            this.removeDragEvent();

            this.dragControls = new DragControls(this.referencePlane, this.camera.instance, this.canvas);
            this.hanldeDragEvent();

        }
    }


    initNewTourDrawing = () => {
        /*
            Raycast just the floor, search "userData.navHelper === 'floorplan'; " 
            in scene.children.
        */
        this.scene.children.forEach((child) => {
            if (child.userData && child.userData.navHelper && child.userData.navHelper === "floorplan") {
                this.raycastObjects.push(child);
            }
        });

        if (this.raycastObjects.length > 0) {
            this.handleNavigationTrackingTrigger(true);
            this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);
            this.editor.trigger("orbitInteractionModeChanged", ["navTracking"]);
            this.startEventListenerForNewOrEditPath();
        } else {
            this.editor.callbacks.generateAlert({
                msg: "Add floorplan in map to create a guided tour.",
                alertType: "information"
            });
        }
    }

    /**
     * 
     * @param {*} e :- Mouse Down event.
     * @returns if the click is on the floor plane -> returns True otherwise False
     */
    withinBounds = (e) => {
        let viewportBound = new THREE.Vector2();
        let raycasterBound = new THREE.Raycaster();
        const rect = this.canvas.getBoundingClientRect();
        viewportBound.x = (((e.offsetX - rect.left) / rect.width) * 2) - 1;
        viewportBound.y = - (((e.offsetY - rect.top) / rect.height) * 2) + 1;

        raycasterBound.setFromCamera(viewportBound, this.camera.instance);
        const intersects = raycasterBound.intersectObjects(this.raycastObjects, true);

        return intersects.length > 0;
    }

    getReadyToPlaceAnotherNode = async () => {

        // If the node is start node change the pin and spot color
        if (this.activeNode === 0) {
            // Change svg color
            this.startPinSvg = await getPinsTexture("startPinSvg", this.pathColor, 512);
            this.startPinMesh.material = new THREE.SpriteMaterial({
                map: this.startPinSvg,
            });
            this.startSpotMaterial = [
                new THREE.MeshBasicMaterial({ color: this.pathColor }),
                new THREE.MeshBasicMaterial({ map: this.placedSpotSvg }),
                new THREE.MeshBasicMaterial({ map: this.placedSpotSvg }),
            ];
            this.startSpotMesh.material = this.startSpotMaterial;

            this.startPinGroup.userData['index'] = this.activeNode;

            // Change cursor
            document.body.classList.add('wsNew_cursor');

            // Configure end pin
            this.configureEndPin();
        }

        // Create new node
        const node = this.getHelperNode();
        node.position.copy(this.nodes[this.activeNode].position);


        // assign placed spot svg
        if (this.nodes[this.activeNode].isMesh) {
            this.nodes[this.activeNode].material = this.placedNodeMaterial;

            if (this.nodes.length > 1) {
                this.editor.trigger('newTourOperationStatusChange', ["InProgress"]);
            }
        }

        // Make line
        if (this.nodes.length === 1) {

            let points = [this.nodes[this.activeNode].position.clone(), node.position.clone()];

            // if the node length is just 1 and users
            // pans the view just send the second node position
            // instead of sending array of two like above.
            if (this.panState) {
                points = [node.position.clone()];
            }

            this.guidedTourLine.addPoints(points);
        } else {
            this.guidedTourLine.updatePoint(this.nodes[this.activeNode].position.clone());
            this.guidedTourLine.addPoints([node.position.clone()]);
        }

        // add new node to scene
        this.addNode(node);
        node.userData['index'] = this.activeNode;
        this.tourGroup.add(node);
    }

    addNode = (node) => {
        this.nodes.push(node);
        this.activeNode = this.nodes.length - 1;
    }

    popNode = (reduceActiveNode = false) => {
        if (reduceActiveNode) {
            this.activeNode -= 1;
        }

        return this.nodes.pop();
    }

    addLine = (line) => {
        this.tourGroup.add(line);
        this.pathMesh = line;
        this.editor.trigger("refreshPathsToTrack");
    }

    endTourHover = () => {

    }

    endTour = () => {
        // End pin can only be placed after start pin + one node is placed
        if (this.activeNode > 1) {

            this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);
            this.editor.trigger("orbitInteractionModeChanged", ["select"]);
            // Call only when helper node is active, just remove the 
            // helper node and make last secound node as last node
            this.guidedTourLine.endTour();

            const currentActiveNode = this.popNode();
            this.tourGroup.remove(currentActiveNode);

            const endNode = this.popNode(true);
            const endIndex = endNode.userData['index']

            const endNodePosition = endNode.position.clone();
            this.tourGroup.remove(endNode);

            this.endPinGroup.position.copy(endNodePosition);
            this.endPinGroup.userData['index'] = endIndex;
            this.endPinGroup.children.forEach((child) => child.userData['index'] = endIndex);
            this.addNode(this.endPinGroup);
            this.tourGroup.add(this.endPinGroup);

            document.body.classList.remove('wsNew_cursor');

            this.operationsAfterTourEnd();
            this.prepareNewTourToEdit();
            this.triggerEditFlowOnEndTour();

            // After operation on nodes then save the tour.
            this.handleNavigationTrackingTrigger(false);

            !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);
        }
    }

    endTourOnRightClick = () => {

        // End pin can only be placed after start pin is placed
        if (this.activeNode > 0) {

            this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);
            this.editor.trigger("orbitInteractionModeChanged", ["select"]);

            // Update the current active node position
            this.guidedTourLine.updatePoint(this.nodes[this.activeNode].position.clone());

            // Pop the node to remove from scene and 
            // replace it with endPinGroup
            const endNode = this.popNode();
            const endNodePosition = endNode.position.clone();
            const endIndex = endNode.userData['index'];
            this.tourGroup.remove(endNode);

            // copy the popped nodes position to endPinGroup position
            this.endPinGroup.position.copy(endNodePosition);
            this.endPinGroup.userData['index'] = endIndex;
            this.endPinGroup.children.forEach((child) => child.userData['index'] = endIndex);
            this.addNode(this.endPinGroup);
            this.tourGroup.add(this.endPinGroup);

            // Go back to the original Cursor.
            document.body.classList.remove('wsNew_cursor');

            // Make new tour 
            this.operationsAfterTourEnd();
            this.prepareNewTourToEdit();
            this.triggerEditFlowOnEndTour();

            // Stop the Navigation Tracing and Event Listeners
            this.handleNavigationTrackingTrigger(false);

            !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);
        }

    }

    triggerEditFlowOnEndTour = () => {
        this.editor.selectedObject = this.tourGroup;

        this.editor.trigger("objectSelectedFromGuidedTour", [this.editor.selectedObject]);
        this.editor.trigger("sidebarSceneGraphChanged", [
            this.editor.selectedObject,
        ]);
        this.editor.trigger("editSidebarObjectContents", [
            this.editor.selectedObject,
        ]);
        this.editor.trigger("transformModeChanged", ["edit", ""]);
    }

    refreshPath = () => {
        const oldPath = this.pathMesh;
        this.tourGroup.remove(oldPath);
        this.pathMesh = null;

        this.guidedTourLine.clearAllPoints();

        // Draw Lines using the points
        for (let i = 0; i < this.nodes.length; i++) {
            const points = [this.nodes[i].position.clone()];
            this.guidedTourLine.addPoints(points);
        }
    }

    prepareNewTourToEdit = async () => {
        // initiate new tour that can be edited
        this.endEventListenerForNewOrEditPath();
        this.tourType = "existing";

        // Assign nodes to raycast object for raycasting
        this.raycastObjects = this.nodes;

        if (this.raycastObjects.length) {
            // Drag Feature
            await this.loadSvgsForExistingTour(this.pathColor);
            this.configureDragFeature();
            this.startEventListenerForNewOrEditPath();
        }

        this.refreshPath();
    }

    pauseTouring = () => {
        // stop navigation tracking
        this.handleNavigationTrackingTrigger(false);

        // stop event listener for mouse down and mouse move
        this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
        this.canvas.removeEventListener('mousedown', this.onMouseDown, false);

        // remove the hanging node 
        if (this.activeNode === (this.nodes.length - 1)) {
            const node = this.popNode();
            this.tourGroup.remove(node);
            this.guidedTourLine.removeLastPoint();
        }

        // remove the mouse cursor
        document.body.classList.remove('wsNew_cursor');
    }

    resumeTouring = async () => {
        // start navigation tracking
        this.handleNavigationTrackingTrigger(false);

        // Start event listener for mouse down and move
        this.canvas.addEventListener('mousemove', this.onMouseMove, false);
        this.canvas.addEventListener('mousedown', this.onMouseDown, false);

        // Add the hanging node
        if (this.activeNode === this.nodes.length) {
            this.activeNode -= 1;

            if (this.activeNode < 0) {
                this.addNode(this.startPinGroup);
                this.tourGroup.add(this.startPinGroup);
            } else await this.getReadyToPlaceAnotherNode();
        }

        // Add the mouse cursor
        this.editor.trigger("orbitInteractionModeChanged", ["navTracking"]);
        document.body.classList.add('wsNew_cursor');
    }

    handlePanningState = async (state) => {
        if (state && !this.panState) {
            this.pauseTouring();
            this.editor.trigger("orbitInteractionModeChanged", ["navPaning"]);
            document.body.classList.add("wsGrab_cursor");
            this.panState = true;
        }

        if (!state && this.panState) {
            await this.resumeTouring();
            document.body.classList.remove("wsGrab_cursor");
            this.editor.trigger("orbitInteractionModeChanged", ["navTracking"]);
            this.panState = false;
        }
    }

    handleEditEscState = () => {
        this.emptySelectedNode();
    }

    cloneTourGroup = () => {
        let group = new THREE.Object3D();


        if (this.tourType === "existing") {
            group.name = this.tourData.name;
            group.userData['id'] = this.tourData.id;
            group.userData['type'] = 'wayPointGroup';
            group.userData['navigationMode'] = 'waypoints';
            group.userData['udScale'] = 'waypoints';
            group.userData['udRotate'] = 'waypoints';
            group.userData['visible'] = this.tourData.visible;
            group.userData['transformation'] = "NO_TRANSFORM";
            group.userData['skipScene'] = true;
            group.userData['skipChild'] = true;
            group.userData['pathName'] = this.tourData.pathName;
            group.userData['description'] = this.tourData.description;
            group.userData['navigationStyle'] = this.pathNavStyle;
            group.userData['pathColor'] = this.pathColor;
            group.userData['MetaObject'] = true;
            group.userData["qrAnchor"] = true;
            group.userData['file'] = this.file;
            group.userData['image'] = this.image;
            group.userData['wheelchairAccessible'] = this.wheelchairAccessible;
            group.userData['isCreatedFromApp'] = this.isCreatedFromApp;
        } else {
            const pathName = `pathName__${getRandomIdCode()}`;

            group.name = `tour_${getRandomIdCode()}`
            group.userData['id'] = this.tourId;
            group.userData['type'] = 'wayPointGroup';
            group.userData['navigationMode'] = 'waypoints';
            group.userData['udScale'] = 'waypoints';
            group.userData['udRotate'] = 'waypoints';
            group.userData['visible'] = true;
            group.userData['transformation'] = "NO_TRANSFORM";
            group.userData['skipScene'] = true;
            group.userData['skipChild'] = true;
            group.userData['pathName'] = pathName;
            group.userData['description'] = "Sample Description";
            group.userData['navigationStyle'] = this.pathNavStyle;
            group.userData['pathColor'] = this.pathColor;
            group.userData['MetaObject'] = true;
            group.userData["qrAnchor"] = true;
            group.userData['file'] = this.file;
            group.userData['image'] = this.image;
            group.userData['wheelchairAccessible'] = this.wheelchairAccessible;
            group.userData['isCreatedFromApp'] = this.isCreatedFromApp;
        }

        return group;
    }

    handleMouseMoveForNewTour = (intersect) => {
        // Show start pin only when mouse enters canvas
        if (!this.mouseInCanvas) {
            this.tourGroup.add(this.startPinGroup);
            this.mouseInCanvas = true;
        }

        this.nodes[this.activeNode].position.copy(intersect.point);

        if (this.nodes.length > 1) {
            this.guidedTourLine.updatePoint(intersect.point);
        }
    }

    handleMouseMoveForAddNewNode = (intersect) => {

        if (this.newNodeMesh && !this.newNodeMesh.visible) this.newNodeMesh.visible = true;
        this.newNodeMesh.position.copy(intersect.point);
    }

    handleMouseMoveForExistingTour = (intersect) => {
        const object = intersect.object;

        // If hovered on pin the spot should be shown hovered
        if (object.isSprite) {
            // Start and end pin consists of group so even if the group is hovered the spot mesh should
            // display changes to do that we are parsing the group
            object.parent.children.length && object.parent.children.forEach((child) => {
                if (child.isMesh) {

                    // If the node is already hovered upon and/or selected hover operation 
                    // again should not be performed on it
                    if (this.activeHoveredNode && this.activeHoveredNode.uuid === child.uuid) return;
                    if (this.selectedNode && this.selectedNode.uuid === child.uuid) return;

                    // If the hovered node is not selected then change the material 
                    // to hovered material
                    child.material = this.hoveredNodeMaterial;
                    this.activeHoveredNode = child;
                }
            })
        } else if (object.isMesh) {
            // If the node is already hovered upon and/or selected hover operation 
            // again should not be performed on it
            if (this.activeHoveredNode && this.activeHoveredNode.uuid === object.uuid) return;
            if (this.selectedNode && this.selectedNode.uuid === object.uuid) return;

            object.material = this.hoveredNodeMaterial;
            this.activeHoveredNode = object;
        }
    }

    handleAddNewNodeToTour = (e) => {
        // Just to check user clicked some where else to deselect the node
        let viewportDown = new THREE.Vector2();
        const rect = this.canvas.getBoundingClientRect();
        viewportDown.x = (((e.offsetX - rect.left) / rect.width) * 2) - 1;
        viewportDown.y = - (((e.offsetY - rect.top) / rect.height) * 2) + 1;

        this.raycaster.setFromCamera(viewportDown, this.camera.instance);
        const intersects = this.raycaster.intersectObjects(this.raycastObjects, true);


        if (intersects.length > 0) {
            const intersect = intersects[0];
            const currentPoint = intersect.point;

            for (let i = 0; i < this.nodes.length - 1; i++) {
                const point1 = this.nodes[i].position;
                const point2 = this.nodes[i + 1].position;
                const doesLies = this.pointBetweenPoints(currentPoint, point1, point2);

                if (doesLies) {
                    this.nodes.splice(i + 1, 0, this.newNodeMesh);
                    this.guidedTourLine.addPointAtIndex(currentPoint.clone(), i + 1);

                    this.newNodeMesh = null;
                    this.addNewNode = false;
                    this.recalculateNodesIndexes();
                    this.raycastObjects = this.nodes;

                    this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);
                    !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);

                    document.body.classList.remove('wsAdd_cursor');
                    this.handleNodeSelectionState(e);
                    break;
                }
            }
        } else {
            if (this.addNewNode) {
                this.scrapNewNodeAddProcess();
            }
        }
    }

    pointBetweenPoints = (newPoint, point1, point2) => {
        const epsilon = 0.6; // A small value to handle floating point precision issues

        // Calculate the vectors between the points
        const vector1 = point1.clone().sub(newPoint.clone()).normalize();
        const vector2 = point2.clone().sub(newPoint.clone()).normalize();

        const dotProduct = vector1.dot(vector2);
        if (dotProduct > 0) {
            return false; // Angle is obtuse, point is not between point1 and point2
        }

        // if the dot product is less than or equal to 0 and the length of the 
        // cross product is less than epsilon, the given point lies on the line segment
        const scalarValue = Math.abs(vector1.clone().cross(vector2).length());

        if (scalarValue < epsilon) {
            return this.isBetween(newPoint.clone(), point1.clone(), point2.clone());
        } else {
            return false;
        }
    }

    isBetween = (newPoint, point1, point2) => {
        // Calculate vectors
        let vectorA = new THREE.Vector3().subVectors(newPoint, point1);
        let vectorB = new THREE.Vector3().subVectors(point2, point1);

        // Calculate dot product
        let dotProduct = vectorA.dot(vectorB);

        // Check if the dot product is positive and less than the squared length of vectorB
        return dotProduct > 0 && dotProduct < vectorB.lengthSq();
    }

    recalculateNodesIndexes = () => {
        for (let i = 0; i < this.nodes.length; i++) {
            if (this.nodes[i].isGroup) {
                this.nodes[i].children.forEach((child) => child.userData['index'] = i);
            }
            this.nodes[i].userData['index'] = i;
        }

        this.raycastObjects = this.nodes;
    }

    handleNodeSelectionState = (e) => {
        // Just to check user clicked some where else to deselect the node
        let viewportDown = new THREE.Vector2();
        const rect = this.canvas.getBoundingClientRect();
        viewportDown.x = (((e.offsetX - rect.left) / rect.width) * 2) - 1;
        viewportDown.y = - (((e.offsetY - rect.top) / rect.height) * 2) + 1;

        this.raycaster.setFromCamera(viewportDown, this.camera.instance);
        const intersects = this.raycaster.intersectObjects(this.raycastObjects, true);


        if (intersects.length > 0) {
            const intersect = intersects[0];
            const object = intersect.object;
            let mesh = null;

            if (object.isSprite) {
                object.parent.children.forEach((child) => {
                    if (child.isMesh) {
                        mesh = child;
                    }
                });
            } else if (object.isMesh) {
                mesh = object;
            }

            // is the node is already selected and user again click on it the the selected node 
            // should not be set again because it is already active.
            if (mesh && this.selectedNode && this.selectedNode.uuid === mesh.uuid) {
                if (this.editor.selectedObject) this.editor.deselect();
                if (!this.removeNode) this.editor.trigger('nodeSelected', [this.isCreatedFromApp ? this.selectedNode : null]);
                return;
            }

            if (mesh && this.tourId === this.checkNodeSelectionPriority()) {
                // if user have already selected a node and clicked on another node
                // the first node selected state should be transfered to currently selected node
                this.emptySelectedNode();
                this.selectedNode = mesh;
                this.selectedNode.material = this.helperNodesMaterial;
                if (this.editor.selectedObject) this.editor.deselect();
                if (!this.removeNode) this.editor.trigger('nodeSelected', [this.isCreatedFromApp ? mesh : null]);


                this.handleNavigationTrackingTrigger(true);

                if (!this.dragControls) {
                    this.configureDragFeature();
                }

                if (!this.removeNode && this.dragControls) {
                    this.dragControls.activate();
                    this.dragControls.transformGroup = true;
                    this.dragControls.setObject(object);
                }
            }
        } else {
            // if user clicks somewhere else other than nodes present in the tour
            // the selected node will be deselected.
            this.emptySelectedNode();
            if (this.removeNode) this.removeNode = false;
            this.editor.trigger("clickedInVoidWhileOperationOnNode");
        }
    }

    // This routine is used multiple times in the code so it is made reusable
    emptySelectedNode = () => {
        if (this.selectedNode) {
            if (this.selectedNode.name === "endPinSpot") {
                this.selectedNode.material = this.endSpotMaterial;
            } else {
                this.selectedNode.material = this.placedNodeMaterial;
            }

            this.selectedNode = null;
            this.editor.trigger("nodeDeSelected");

            this.manageNavigationTracking();
            if (this.dragControls) this.dragControls.dispose();

            if (this.isCreatedFromApp && this.dragControls) {
                this.dragControls = null;
            }
        }
    }

    calculateNodes = () => {
        const nodeObjs = []
        this.nodes.forEach((node, index) => {
            const obj = {
                id: node.uuid,
                order: index,
                position: fromVec3PosToPosObject(node.position.clone()),
            };

            if (index <= 0) {
                obj['adjacentNodes'] = [null, null];
            } else {
                obj['adjacentNodes'] = [nodeObjs[index - 1].id, null];
                nodeObjs[index - 1].adjacentNodes[1] = obj.id;
            }

            nodeObjs.push(obj);
        });

        return nodeObjs;
    }

    calculateWaypoints = () => {
        let waypoints = [];
        const { userData, name } = this.tourGroup;
        const length = this.nodes.length;

        for (let i = 0; i < length - 1; i++) {
            const ptStart = this.nodes[i];
            const ptEnd = this.nodes[i + 1];

            waypoints = [
                ...waypoints,
                ...this.calculateDistance(
                    length,
                    i,
                    ptStart,
                    ptEnd,
                    userData.pathName,
                    name,   // Guided tour name
                    userData.description,   // Description
                    this.pathColor
                ),
            ];
        }

        return waypoints;
    }

    //For Waypoint distancing
    calculateDistance = (
        arrLength,
        index,
        ptStart,
        ptEnd,
        pathName,
        name,
        description,
        pathColor
    ) => {
        const vec1 = ptStart.position.clone();
        const vec2 = ptEnd.position.clone();
        let randId = getRandomIdCode();
        let lineDistance = vec1.distanceTo(vec2);
        let points = [];

        const ptRot = fromQuaternionToQuaternionObject(ptStart.rotation.clone());
        const ptSca = fromVec3ScaleToScaleObject(ptStart.scale.clone());

        if (index === 0) {
            points.push({
                id: `wayPointStart_${randId}`,
                name: name,
                description: description,
                pathColor,
                pathName,
                visible: this.tourGroup.userData.visible,
                navigationStyle: this.tourGroup.userData.navigationStyle,
                note: "",
                images: this.image ? [this.image] : [],
                file: this.file,
                wheelchairAccessible: this.wheelchairAccessible,
                position: fromVec3PosToPosObject(ptStart.position.clone()),
                rotation: ptRot,
                scale: ptSca,
            });
        } else {
            points.push({
                id: `wayPoint_${randId}`,
                position: fromVec3PosToPosObject(ptStart.position.clone()),
                rotation: ptRot,
                scale: ptSca,
            });
        }

        while (lineDistance.toFixed(2) > 1.3) {
            let subV = new THREE.Vector3();
            const randId = getRandomIdCode();
            let vecPrev = fromPosObjectToVec3Pos(points[points.length - 1].position);
            subV.subVectors(vec2, vecPrev).setLength(1).add(vecPrev);

            points.push({
                id: `wayPoint_${randId}`,
                position: fromVec3PosToPosObject(subV.clone()),
                rotation: ptRot,
                scale: ptSca,
            });

            lineDistance = subV.distanceTo(vec2);
        }
        randId = getRandomIdCode();
        arrLength - 2 === index &&
            points.push({
                id: `wayPointEnd_${randId}`,
                position: fromVec3PosToPosObject(vec2.clone()),
                scale: fromVec3ScaleToScaleObject(ptEnd.scale.clone()),
                rotation: fromQuaternionToQuaternionObject(ptEnd.rotation.clone()),
                name: name,
                description: description,
                pathColor,
                pathName,
                visible: this.tourGroup.userData.visible,
                navigationStyle: this.tourGroup.userData.navigationStyle,
                note: "",
                images: this.image ? [this.image] : [],
                file: this.file,
                wheelchairAccessible: this.wheelchairAccessible,
            });

        return [...points];
    };

    toJSON = () => {
        if (this.nodes && this.nodes.length === 0) return;

        return {
            nodes: this.calculateNodes(),
            waypoints: this.calculateWaypoints(),
            isCreatedFromApp: this.isCreatedFromApp,
        }
    }

    hanldeDragEvent = () => {
        if (this.dragControls) {
            this.dragControls.addEventListener('drag', this.onNodeDrag);
            this.dragControls.addEventListener('dragstart', this.onDragStart);
            this.dragControls.addEventListener('dragend', this.onDragEnd);
        }
    }

    removeDragEvent = () => {
        if (this.dragControls) {
            this.dragControls.removeEventListener('drag', this.onNodeDrag);
            this.dragControls.removeEventListener('dragstart', this.onDragStart);
            this.dragControls.removeEventListener('dragend', this.onDragEnd);
        }
    }

    startEventListenerForNewOrEditPath = () => {
        if (this.tourType === "new") {
            this.canvas.addEventListener('mousemove', this.onMouseMove, false);
            this.canvas.addEventListener('mousedown', this.onMouseDown, false);
            this.canvas.addEventListener('mouseup', this.onMouseUp, false);
            document.addEventListener('keydown', this.onKeyDown, false);
            document.addEventListener('keyup', this.onKeyUp, false);
        }

        else if (this.tourType === "existing") {
            this.canvas.addEventListener('mousemove', this.onMouseMove, false);
            this.canvas.addEventListener('mousedown', this.onMouseDown, false);
            this.canvas.addEventListener('mouseup', this.onMouseUp, false);
            document.addEventListener('keydown', this.onKeyDown, false);
        }
    }

    endEventListenerForNewOrEditPath = () => {

        if (this.tourType === "new") {
            this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
            this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
            this.canvas.removeEventListener('mouseup', this.onMouseUp, false);
            document.removeEventListener('keydown', this.onKeyDown, false);
            document.removeEventListener('keyup', this.onKeyUp, false);
        }

        else if (this.tourType === "existing") {
            this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
            this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
            this.canvas.removeEventListener('mouseup', this.onMouseUp, false);
            document.removeEventListener('keydown', this.onKeyDown, false);
        }

    }

    onNodeElevation = () => {
        if (this.selectedNode) {
            let index = null;
            const pos = new THREE.Vector3();

            if (this.selectedNode.userData.index === 0){
                index = 0;
                pos.copy(this.selectedNode.parent.position);
            } else if (this.selectedNode.userData.index === (this.nodes.length - 1)) {
                index = this.selectedNode.parent.userData.index;
                pos.copy(this.selectedNode.parent.position);
            } else {
                index = this.selectedNode.userData.index;
                pos.copy(this.selectedNode.position);
            }

            if (index !== null) {
                this.guidedTourLine.updateEditPoint(index, pos);
            }
        }
    }

    onNodeDrag = (e) => {
        if (e && e.object) {
            const index = e.object.userData['index'];
            const pos = new THREE.Vector3();

            pos.copy(e.object.position);
            this.guidedTourLine.updateEditPoint(index, pos);

            if (this.referenceLine) {
                this.referenceLine.position.x = e.object.position.x;
                this.referenceLine.position.z = e.object.position.z;
            }

            if (this.referenceSpot) {
                this.referenceSpot.position.x = e.object.position.x;
                this.referenceSpot.position.z = e.object.position.z;
            }
        }
    }

    onDragStart = (e) => {
        this.editor.camera.controls.enabled = false;
        document.body.style.cursor = "pointer";

        if (this.isCreatedFromApp) {
            if (this.referenceLine) this.referenceLine.visible = true;
            if (this.referenceSpot) {
                this.referenceSpot.visible = true;
                this.referenceSpot.material.depthTest = false;
                this.referenceSpot.material.needsUpdate = true;
            }
        }
    }

    onDragEnd = (e) => {
        this.editor.camera.controls.enabled = true;
        this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);
        !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);
        document.body.style.cursor = "default";

        if (this.isCreatedFromApp) {
            if (this.referenceLine) this.referenceLine.visible = false;
            if (this.referenceSpot) {
                this.referenceSpot.visible = false;
                this.referenceSpot.material.depthTest = true;
                this.referenceSpot.material.needsUpdate = true;
            }
        }
    }

    onMouseMove = (e) => {

        let viewportDown = new THREE.Vector2();
        const rect = this.canvas.getBoundingClientRect();
        viewportDown.x = (((e.offsetX - rect.left) / rect.width) * 2) - 1;
        viewportDown.y = - (((e.offsetY - rect.top) / rect.height) * 2) + 1;

        this.raycaster.setFromCamera(viewportDown, this.camera.instance);
        const intersects = this.raycaster.intersectObjects(this.raycastObjects, true);


        if (intersects.length > 0) {
            const intersect = intersects[0];

            if (this.tourType === "new") {
                this.handleMouseMoveForNewTour(intersect);
            } else if (this.tourType === "existing") {

                if (this.addNewNode) {
                    this.handleMouseMoveForAddNewNode(intersect);
                } else {
                    // When child is hovered 
                    this.handleNavigationTrackingTrigger(true);

                    this.handleMouseMoveForExistingTour(intersect);
                }
            }
        } else {
            if (this.activeHoveredNode) {

                if (this.selectedNode && this.selectedNode.uuid === this.activeHoveredNode.uuid) {
                    this.activeHoveredNode = null;
                    return;
                };

                if (this.activeHoveredNode.name === "endPinSpot") {
                    this.activeHoveredNode.material = this.endSpotMaterial;
                } else {
                    this.activeHoveredNode.material = this.placedNodeMaterial;
                }

                this.handleNavigationTrackingTrigger(false);

                this.activeHoveredNode = null;
            }

            if (this.addNewNode && this.newNodeMesh) {
                this.newNodeMesh.visible = false;
            }
        }

    }

    onMouseDown = (e) => {
        // Different operations are performed when user is creaing the tour
        // or editing the existing tour so it is handled based on the tourType.


        if (this.tourType === "new") {
            // Check click is on the floor plan, if not return
            if (!this.withinBounds(e)) return;
            switch (e.which) {
                case 1:
                    this.getReadyToPlaceAnotherNode();
                    break;

                case 3:
                    this.endTourOnRightClick();
                    break;

                default:
                    break;
            }
        } else if (this.tourType === "existing") {
            switch (e.which) {
                case 1:
                    if (this.addNewNode) {
                        this.handleAddNewNodeToTour(e);
                    } else {
                        this.handleNodeSelectionState(e);
                    }
                    break;

                default:
                    break;
            }
        }
    }

    onMouseUp = (e) => {

        if (this.removeNode && this.selectedNode) {

            this.removeNodeOperation();
        }
    }

    removeNodeOperation = () => {
        if (this.nodes.length > 2) {
            this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);
            const index = this.selectedNode.userData['index'];


            if (index === 0) {
                const nodeAfterStartNode = this.nodes[1];
                this.nodes[0].position.copy(nodeAfterStartNode.position);
                this.nodes.splice(1, 1);
                this.tourGroup.remove(nodeAfterStartNode);
            } else if (index === (this.nodes.length - 1)) {
                const nodeBeforeEndNode = this.nodes[index - 1];
                this.nodes[index].position.copy(nodeBeforeEndNode.position);
                this.nodes.splice(index - 1, 1);
                this.tourGroup.remove(nodeBeforeEndNode);
            } else {
                this.nodes.splice(index, 1);
                this.tourGroup.remove(this.selectedNode);
            }

            this.guidedTourLine.removeNodeAtIndex(index);

            this.selectedNode = null;
            this.removeNode = false;

            this.recalculateNodesIndexes();

            !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);

        } else if (this.nodes.length === 2) {
            this.removeNode = false;
        }
    }

    onKeyDown = (e) => {
        if (this.tourType === "new") {

            switch (e.code) {
                case "Space":
                    this.handlePanningState(true);
                    break;

                case "Escape":
                    if (this.nodes.length > 2) {
                        this.endTour();
                    } else {
                        this.nodes = [];
                        this.raycastObjects = [];
                        this.editor.removeObject(this.tourGroup);

                        if (this.exitNewTourCreationFlow) {
                            this.exitNewTourCreationFlow();
                        }

                        document.body.classList.remove('wsNew_cursor');
                        this.endEventListenerForNewOrEditPath();

                        this.editor.trigger("orbitInteractionModeChanged", ["select"]);

                    }
                    break;

                default:
                    break;
            }
        }

        else if (this.tourType === "existing") {
            switch (e.code) {
                case "Escape":
                    this.handleEditEscState();
                    break;

                case "Delete":
                    if (this.selectedNode) {
                        this.removeNodeOperation();
                    }
                    break;

                case "Backspace":
                    if (this.selectedNode) {
                        const platform = window.navigator.userAgentData.platform.toLowerCase();

                        if (platform.includes("mac")) {
                            this.removeNodeOperation();
                        }
                    }
                    break;

                default:
                    break;
            }
        }
    }

    onKeyUp = (e) => {
        switch (e.code) {
            case "Space":
                this.handlePanningState(false);
                break;

            default:
                break;
        }
    }

    handleNavigationTrackingTrigger = (state) => {
        this.editor.trigger('navigationTracking', [state]);
    }
}

export default GuidedTour;