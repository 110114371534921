import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
// import * as THREE from 'three';

import { 
    UICol,
    UIPanel,
    UIText,
    UIObjectInfoCard,
    UIImage
} from "../ui.lib";

import {
    getObjectByUserDataProperty
} from '../../threeUtils/TransformConversions'

import { SetVisible } from '../../commands/SetVisible';

import alertIcon from '../../../../assets/svgs/scenes/alert.svg';

class SidebarContentQRCodes extends EventEmitter{
    
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.dom = null;

        this.initUI();

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);

    }

    initUI = () => {

        this.uiMyContent = new UIPanel();

        this.qrText = new UIText(undefined, 'Placed QR Codes');

        this.uiMyContent.addChild(this.qrText);

        this.dom = this.uiMyContent;
    }

    emptyContent = () => {
        var child = this.uiMyContent.dom.lastChild; 
        while (child) {
            if(child === this.qrText.dom) break;
            this.uiMyContent.dom.removeChild(child);
            child = this.uiMyContent.dom.lastChild;
        }
    }

    updateUiData = () => {

        if(this.editor.jsonQRAnchors.length === 0) {
            this.emptyContent();
            const uiNoDataCard = new UIPanel();
            uiNoDataCard.addClass('ws_uiNoDataCard');
            const uiSubText = document.createElement('div')
            uiSubText.classList.add('ws_uiNDSubText');
            uiSubText.textContent = 'You haven’t added a QR Code yet.';
            uiNoDataCard.dom.appendChild(uiSubText);
            this.uiMyContent.addChild(uiNoDataCard);
        } else {
            const uiCardsRow = new UIPanel();
            uiCardsRow.addClass('ws_uiCardsRowFloat');
            this.emptyContent();
            this.editor.jsonQRAnchors.forEach((qrObj, index) =>{
                if(qrObj.isQrCode) {
                    const uiQRCont = new UIObjectInfoCard(this.editor, qrObj, getObjectByUserDataProperty(this.editor.scene, 'id', qrObj.id), this.onQRSelect, "QR", index);
                    uiCardsRow.addChild(uiQRCont)
                }
            });
            this.uiMyContent.addChild(uiCardsRow);

            const uifinishRoute = new UICol().addClass('ws_uiReadInfoCard');
            const uiIcon = new UIImage(alertIcon, '32px', '32px').addClass('ws_uiReadInfoCard--icon');
            const uiTextMsg = new UIText(undefined, 'Note: Open the ARway App to edit or delete QR Codes.').addClass('ws_uiReadInfoCard--textSpan');
            uifinishRoute.addChild(uiIcon);
            uifinishRoute.addChild(uiTextMsg);
            this.uiMyContent.addChild(uifinishRoute);
        }
    }

    onQRSelect = (content, eventType) => {
        const object = getObjectByUserDataProperty(this.editor.scene, 'id', content.id);
        if(object) {
            if(eventType === 2) {
                this.editor.onCommand(new SetVisible(this.editor, object, !object.visible, false, false))
            } else {
                this.editor.select(object);
            }
        }
    }

    refreshUI = () => {
        this.updateUiData()
    }

    onToggleMenuActive = () => {

    }

    unload = () => {
        this.emptyContent();
    }
}

export { SidebarContentQRCodes };