import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";

import {
    UIButton,
    UIPanel,
    UICol,
    UIInputTextArea,
    UIInputText,
    UIRow,
    UIText,
} from "../ui.lib";

class SidebarContentHotspot extends EventEmitter {
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.strings = this.editor.strings;
        this.scene = this.editor.scene;
        this.dom = null;

        this.hotspotDescription = "";
        this.hotspotTitle = "";
        this.hotspotHLabel = "";
        this.hotspotHLink = "";

        this.initUI();

        this.editor.on("toggleMenuActive", this.onToggleMenuActive);
    }

    initUI = () => {
        this.uiMyContent = new UIPanel().addClass("wsUIPanelContainer");

        // Title
        this.uiMyContent.addChild(new UIText(undefined, "Hotspot Content"));
        var objectTitleRow = new UICol().addClass("ws_uiTextContentCol");
        this.objectTitleInput = new UIInputText(
            "hotspotContent",
            "100%",
            undefined,
            20,
            "Add Name",
            "forText"
        ).onChange((e) => {
            var value = e.target.value;
            this.hotspotTitle = value;
            this.uiTitle.setTextContent(`${this.hotspotTitle.length}/20`);
        });
        const objectTitleHeaderRow = new UIRow().addClass(
            "ws_uiTextContentRow"
        );
        objectTitleHeaderRow.addChild(new UIText(undefined, "Title"));
        this.uiTitle = new UIText(
            undefined,
            `${this.hotspotTitle.length}/20`
        ).setStyle("marginRight", "unset");
        objectTitleHeaderRow.addChild(this.uiTitle);
        objectTitleRow.addChild(objectTitleHeaderRow);
        objectTitleRow.addChild(this.objectTitleInput);
        this.uiMyContent.addChild(objectTitleRow);

        // Description
        var objectDescRow = new UICol().addClass("ws_uiTextContentCol");
        this.objectDescInput = new UIInputTextArea(
            "hotspotDescription",
            "100%",
            undefined,
            100,
            "Add Description",
            "4",
            "forText"
        ).onChange((e) => {
            var value = e.target.value;
            this.hotspotDescription = value;
            this.uiDescription.setTextContent(
                `${this.hotspotDescription.length}/100`
            );
        });
        const objectHeaderRow = new UIRow().addClass("ws_uiTextContentRow");
        objectHeaderRow.addChild(new UIText(undefined, "Description"));
        this.uiDescription = new UIText(
            undefined,
            `${this.hotspotDescription.length}/100`
        ).setStyle("marginRight", "unset");
        objectHeaderRow.addChild(this.uiDescription);
        objectDescRow.addChild(objectHeaderRow);
        objectDescRow.addChild(this.objectDescInput);
        this.uiMyContent.addChild(objectDescRow);

        //Hyperlink Content
        this.uiMyContent.addChild(new UIText(undefined, "Hyperlink"));
        var objectHyperLabelRow = new UICol().addClass("ws_uiTextContentCol");
        this.objectHyperLabelInput = new UIInputText(
            "hotspotLinkLabel",
            "100%",
            undefined,
            30,
            "Add Label",
            "forText"
        ).onChange((e) => {
            var value = e.target.value;
            this.hotspotHLabel = value;
            this.uiLabel.setTextContent(`${this.hotspotHLabel.length}/30`);
        });
        const objectHLabelHeaderRow = new UIRow().addClass(
            "ws_uiTextContentRow"
        );
        objectHLabelHeaderRow.addChild(new UIText(undefined, "Label"));
        this.uiLabel = new UIText(
            undefined,
            `${this.hotspotHLabel.length}/30`
        ).setStyle("marginRight", "unset");
        objectHLabelHeaderRow.addChild(this.uiLabel);
        objectHyperLabelRow.addChild(objectHLabelHeaderRow);
        objectHyperLabelRow.addChild(this.objectHyperLabelInput);
        this.uiMyContent.addChild(objectHyperLabelRow);

        // Description
        var objectHyperlinkRow = new UICol().addClass("ws_uiTextContentCol");
        this.objectHyperlinkInput = new UIInputTextArea(
            "hotspotLinkDescription",
            "100%",
            undefined,
            10000,
            "Add Link",
            "4",
            "forText"
        ).onChange((e) => {
            var value = e.target.value;
            this.hotspotHLink = value;
        });
        const objectHLinkHeaderRow = new UIRow().addClass(
            "ws_uiTextContentRow"
        );
        objectHLinkHeaderRow.addChild(new UIText(undefined, "Link"));
        objectHyperlinkRow.addChild(objectHLinkHeaderRow);
        objectHyperlinkRow.addChild(this.objectHyperlinkInput);
        this.uiMyContent.addChild(objectHyperlinkRow);

        this.uiAddTxtBtn = new UIButton("Save & Add in map").onClick(() => {
            if (this.hotspotTitle.length <= 0) {
                this.editor.callbacks.generateAlert({
                    msg: "Please add hotspot title!",
                    alertType: "information",
                });
            } else if (this.hotspotDescription.length <= 0) {
                this.editor.callbacks.generateAlert({
                    msg: "Please add hotspot description!",
                    alertType: "information",
                });
            } else if (
                this.hotspotHLink.length &&
                !this.isURL(this.hotspotHLink)
            ) {
                this.editor.callbacks.generateAlert({
                    msg: "Please add a valid link!",
                    alertType: "information",
                });
            } else {
                let hotspotData = {
                    name: this.hotspotTitle,
                    description: this.hotspotDescription,
                };
                if (this.hotspotHLabel.length) {
                    hotspotData["hyperlinkName"] = this.hotspotHLabel;
                }
                if (this.hotspotHLink.length && this.isURL(this.hotspotHLink)) {
                    hotspotData["hyperlink"] = this.hotspotHLink;
                }
                this.editor.loader.initObjectLoading(
                    { ...hotspotData, contentType: "hotspots" },
                    null,
                    "preload"
                );
            }
        });

        this.uiMyContent.addChild(this.uiAddTxtBtn);

        this.dom = this.uiMyContent;
    };

    isURL(url) {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
                "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
                "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
                "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
                "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
                "(\\#[-a-z\\d_]*)?$",
            "i"
        ); // fragment locator
        return !!pattern.test(url);
    }

    refreshUI = () => {
        if (this.uiMyContent) {

            let canAnchor = false;
            this.scene.children.forEach((child) => {
                if(child.userData && child.userData.navHelper 
                    && child.userData.navHelper === 'floorplan' && child.userData?.alignmentStatus === true) {
                        canAnchor = true;
                }
            });
            this.uiAddTxtBtn.setTextContent(canAnchor ? "Save & Anchor on map" : "Save & Add in map");

            this.hotspotDescription = "";
            this.objectDescInput.setValue(this.hotspotDescription);
            this.uiDescription.setTextContent(
                `${this.hotspotDescription.length}/100`
            );
            this.hotspotTitle = "";
            this.objectTitleInput.setValue(this.hotspotTitle);
            this.uiTitle.setTextContent(`${this.hotspotTitle.length}/20`);
            this.hotspotHLabel = "";
            this.objectHyperLabelInput.setValue(this.hotspotHLabel);
            this.uiLabel.setTextContent(`${this.hotspotHLabel.length}/30`);
            this.hotspotHLink = "";
            this.objectHyperlinkInput.setValue(this.hotspotHLink);
        }
    };

    onToggleMenuActive = () => {};

    unload = () => {
        //TODO
    };
}

export { SidebarContentHotspot };
