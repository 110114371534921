import React, { forwardRef } from 'react';

const ARWayImage = forwardRef(({ src, draggable = true, alt, style, ...otherProps}, ref) => {
    const uId = Math.floor(Math.random() * 90000) + 10000;
    return (
        <img crossOrigin='anonymous' 
            src={src} draggable = {draggable}
            {...otherProps}
            alt ={alt || `ARWAY_IMAGE_${uId}`}
            id={uId} 
            ref={ref}
            style={{zIndex:1,...style}}
        />
    )
});

export default ARWayImage;