import * as THREE from "three";
import EditorExperience from "../EditorExperience";

export default class Renderer {
    constructor() {
        this.experience = new EditorExperience();
        this.sizes = this.experience.sizes;
        this.canvas = this.experience.canvas;
        this.scene = this.experience.scene;
        this.camera = this.experience.camera;

        this.raycaster = new THREE.Raycaster();

        this.initInstance();
    }

    initInstance = () => {
        const scope = this;
        this.instance = new THREE.WebGLRenderer({
            antialias: this.sizes.pixelRatio > 1 ? false : true,
            canvas: this.canvas,
            powerPreference: "high-performance",
        });

        this.instance.physicallyCorrectLights = true;
        this.instance.outputColorSpace = THREE.SRGBColorSpace;
        this.instance.setClearColor("#AAAAAA");
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);
        // this.instance.domElement.addEventListener('mousemove', this.onMouseMove, false);

        this.instance.getContext().canvas.addEventListener(
            "webglcontextlost",
            (e) => {
                e.preventDefault();
                console.log("CONTEXT LOST");
                scope.experience?.time.cancelAnimationFrame();
                window.location.reload();
            },
            false
        );
    };

    onMouseMove = (e) => {
        const mouse = { x: 0, y: 0 };
        mouse.x = (e.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(e.clientY / window.innerHeight) * 2 + 1;
        //Hover
        this.raycaster.setFromCamera(mouse, this.camera.instance);
        const intersects = this.raycaster.intersectObjects(
            this.experience.raycasterObjects,
            true
        );
        if (intersects.length > 0) {
            const object = intersects[0].object;
            this.experience.trigger("hotspotHovered", [object]);
        } else {
            this.experience.trigger("hotspotHovered");
        }
    };

    resize = () => {
        this.instance.setSize(this.sizes.width, this.sizes.height);
        this.instance.setPixelRatio(this.sizes.pixelRatio);
    };

    update = () => {
        this.instance.render(this.scene, this.camera.instance);
    };
}
