import RenderTable from "./RenderTable";
import { isEmpty, isNull, isUndefined } from "lodash";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import RenderPagination from "./RenderPagination";
import usePagination from "./usePagination";
import { Box } from "@mui/material";
import DatePicker from "../../../../_utils/DatePicker";
import { lastTEDays } from "../../../../_utils/DateUtils";

const RenderTableCard = ({
  title,
  name,
  tableHeaders,
  isDateFieldRequired = false,
  isPaginationRequired = false,
  actioncb,
}) => {
  const tableData = useSelector((state) => {
    return state.analytics[`${name}`];
  });

  const limit = 10;
  const [page, setPage] = useState(1);
  const count = Math.ceil(tableData.totalResults / limit);
  const _DATA = usePagination(
    tableData.data ? tableData.data : [],
    limit
  );

  const [dateObj, setDateObj] = useState(lastTEDays);

  useEffect(() => {
    let args = { ...dateObj, page, limit };
    actioncb(args);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // const handleRegionChange = (e) => {
  //   setPage(e.target.value);
  // };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col-9">
            <div className="analyticshome__anCard--contentRow--header">
              {title}
            </div>
          </div>
          {isDateFieldRequired ? (
            <div className="col-3" style={{ paddingRight: "2.5rem" }}>
              <Box
                component="span"
                className="analyticshome__anCard--filterBox"
              >
                <span style={{ color: "#858993" }}>Date:</span>
                <DatePicker
                  cb={(obj) => {
                    setDateObj(obj);
                    let args = { ...obj, page, limit };
                    actioncb(args);
                  }}
                />
              </Box>
            </div>
          ) : null}
        </div>
        <hr />
        {isEmpty(tableData.data) || isNull(tableData.data) || isUndefined(tableData.data) ? (
          <h5 className="text-center analyticshome__emptyStateText ">
            {" "}
            No Data available{" "}
          </h5>
        ) : (
          <RenderTable thheaderData={tableHeaders} rowsData={tableData.data} />
        )}
        <div className="row analyticshome__anCard--contentRow--footer">
          <div className="col">
            {!isEmpty(tableData.data) && isPaginationRequired ? (
              <RenderPagination
                count={count}
                page={page}
                limit={limit}
                handleChange={handleChange}
                cb={(page) => {
                  setPage(page);
                  _DATA.jump(page);
                }}
                totalResults={tableData.totalResults}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderTableCard;
