import * as THREE from 'three';
import { fromPosObjToPosArray } from '../../threeUtils/TransformConversions';
import EditorExperience from '../MinEditorExperience';
import HeatClusterList from './HeatMap/HeatClusterList';
import HeatMap from './HeatMap/HeatMap';

import sdbscan from 'sdbscan';
import { isEqual, uniqWith } from 'lodash';

const TYPES = {
    average: 1,
    start: 2, 
    end: 3
}

export default class WorldMain {

    constructor() {

        this.editor = new EditorExperience();
        this.scene = this.editor.scene;

        this.averageHeatDistribution = [];
        this.startHeatDistribution = [];
        this.endHeatDistribution = [];

        this.heatDistribution = [];

        this.prevHeatMaps = [];

        this.initHelpers();
        this.initSimulatedAreaLight();
        this.editor.on('adjustGridPosition', this.gridPositioning);

    }

    initHelpers = () => {
        this.gridHelper = new THREE.GridHelper(500, 500, "#555555", "#bfbfbf");
        this.gridHelper.position.set(0, -0.1, 0);
        this.scene.add(this.gridHelper);
    }

    initSimulatedAreaLight = () => {
        this.rayLight1 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight1.position.set(0, 100, 0);
        this.scene.add(this.rayLight1);

        this.rayLight2 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight2.position.set(0, -100, 0);
        this.scene.add(this.rayLight2);

        this.rayLight3 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight3.position.set(100, 0, 0);
        this.scene.add(this.rayLight3);

        this.rayLight4 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight4.position.set(-100, 0, 0);
        this.scene.add(this.rayLight4);

        this.rayLight5 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight5.position.set(0, 0, 100);
        this.scene.add(this.rayLight5);

        this.rayLight6 = new THREE.DirectionalLight('#ffffff', 2.5);
        this.rayLight6.position.set(0, 0, -100);
        this.scene.add(this.rayLight6);

    }

    gridPositioning = (newVec) => {
        newVec && (this.gridHelper.position.y = newVec.y);
    }

    clearPrevHeatMaps = () => {
        while(this.prevHeatMaps.length) {
            const name = this.prevHeatMaps.pop();
            const obj = this.editor.scene.getObjectByName(name);
            const node = document.getElementById(`start_${name}`);
            node && document.body.removeChild(node);
            this.editor.scene.remove(obj);
        }

        this.editor.raycasterObjects = [];
        this.editor.heatMaps = [];
    }

    initHeatmapCalculations = () => {
        this.heatDistribution = [];

        this.clearPrevHeatMaps();
        
        if(this.editor.heatMapType === 'average') {
            const totalPositions = this.editor.heatMapData.positions.length;
            if(totalPositions === 0) {
                this.editor.callbacks.generateAlert({msg: "No traffic data available in selected date range", alertType: 'information'})
                return;
            };
            
            let groupList = new HeatClusterList();

            // Now Flat it!
            let flatArray = this.editor.heatMapData.positions.map((ele) => fromPosObjToPosArray(ele))
            flatArray = uniqWith(flatArray, isEqual)
            var clusterNoiseList = sdbscan(flatArray, 0.8, 2);
            if(clusterNoiseList.clusters.length > 0) {
                clusterNoiseList.clusters.forEach(cluster => groupList.insertCluster(`cluster${cluster.id}`, cluster.data));
            }

            if(clusterNoiseList.noise.length > 0) {
                clusterNoiseList.noise.forEach((n, i) => groupList.insertCluster(`noise${i}`, n));
            }
            //Append Centroid
            this.appendCentroids(groupList.instance);
            this.generateHeatDistributions(groupList.instance, TYPES['average']);

            // console.log("GROUPS avg", groupList.instance, this.heatDistribution);

            const heatMesh = new HeatMap(this.heatDistribution, 'average');
            this.prevHeatMaps.push(heatMesh.mesh.name);
            this.editor.scene.add(heatMesh.mesh);
        } 
        else if(this.editor.heatMapType === 'start') {
            // START AND END
            const startTotal = this.editor.heatMapData.startPositions.length;
            const endTotal = this.editor.heatMapData.endPositions.length;

            if(startTotal === 0 || endTotal === 0) {
                this.editor.callbacks.generateAlert({msg: "No traffic data available in selected date range", alertType: 'information'})
                return;
            };

            let groupListStart = new HeatClusterList(this.editor.heatGroupPivots);
            
            // Now Flat Start!
            let flatStartArray = this.editor.heatMapData.startPositions.map((ele) => fromPosObjToPosArray(ele))
            flatStartArray = uniqWith(flatStartArray, isEqual);
            var startClusterNoiseList = sdbscan(flatStartArray, 0.8, 2);

            if(startClusterNoiseList.clusters.length > 0) {
                startClusterNoiseList.clusters.forEach(cluster => groupListStart.insertCluster(`cluster${cluster.id}`, cluster.data));
            }
            if(startClusterNoiseList.noise.length > 0) {
                startClusterNoiseList.noise.forEach((n, i) => groupListStart.insertCluster(`noise${i}`, n));
            }

            this.appendCentroids(groupListStart.instance);
            this.generateHeatDistributions(groupListStart.instance, TYPES['start']);

            let groupListEnd = new HeatClusterList(this.editor.heatGroupPivots);

            // Now Flat End!
            let flatEndArray = this.editor.heatMapData.endPositions.map((ele) => fromPosObjToPosArray(ele))
            flatEndArray = uniqWith(flatEndArray, isEqual);
            var endClusterNoiseList = sdbscan(flatEndArray, 0.8, 2);

            if(endClusterNoiseList.clusters.length > 0) {
                endClusterNoiseList.clusters.forEach(cluster => groupListEnd.insertCluster(`cluster${cluster.id}`, cluster.data));
            }
            if(endClusterNoiseList.noise.length > 0) {
                endClusterNoiseList.noise.forEach((n, i) => groupListEnd.insertCluster(`noise${i}`, n));
            }
            this.appendCentroids(groupListEnd.instance);
            this.generateHeatDistributions(groupListEnd.instance, TYPES['end']);

            // console.log("GROUPS start/end", this.heatDistribution);

            const heatMesh = new HeatMap(this.heatDistribution, 'end', {start: startTotal, end: endTotal});
            this.editor.raycasterObjects.push(heatMesh.mesh)
            this.editor.heatMaps.push(heatMesh);
            this.prevHeatMaps.push(heatMesh.mesh.name);
            this.editor.scene.add(heatMesh.mesh);
        }
    }

    appendCentroids = (groupList) => {
        groupList.forEach(group => {
            if(group.type.includes('cluster')) {
                group.elements.unshift(this.computeCentriod(group.elements));
            }
        });
    }

    generateHeatDistributions = (groupList, type) => {
        
        groupList.forEach((group) => {
            const pushGroupsDistribution = (array) => {
                let centroid = array[0];
                let maxDist = this.getMaxWeight(array);
                for(var i = 1; i < array.length; i++) {
                    const element = array[i];
                    const dist = centroid.distanceTo(element);
                    const mappedDist = THREE.MathUtils.mapLinear(dist, 0, maxDist, 0, 1);
                    const mappedType = type === 1 ? THREE.MathUtils.mapLinear(dist, 0, maxDist, 10, 100) : 0;
                    this.heatDistribution.push({
                        position: element.toArray(), dist, 
                        weight: 1 - (!isNaN(mappedDist) ? mappedDist : 0), type, 
                        colorVal: (!isNaN(mappedType) ? Math.floor(mappedType) : 100)
                    })
                }
            }

            if(group.type.includes('cluster')) {
                pushGroupsDistribution(group.elements);
            } else {
                this.heatDistribution.push({
                    position: group.elements[0].toArray(), dist: 0, 
                    weight: 1, type, 
                    colorVal: 100
                })
            }
        })
    }

    getMaxWeight = (array) => {
        const centroid = array[0];
        let max = 0;
        for(var i = 1; i < array.length; i++){
            var dist = centroid.distanceTo(array[i]);
            if(dist > max) max = dist;
        }
        return max;
    }

    computeCentriod = (distribution) => {
        let sumX = 0, sumY = 0, sumZ = 0;
        const len = distribution.length;
        distribution.forEach(ele => {
            sumX += ele.x;
            sumY += ele.y;
            sumZ += ele.z;
        });
        return new THREE.Vector3(sumX/len, sumY/len, sumZ/len);
    }

}