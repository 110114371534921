import * as React from 'react';
import {
    Backdrop, Box, 
    Modal, Fade, 
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

//Images
import LPImgOne from '../../../assets/svgs/scenes/lp_1.png';
import LPImgTwo from '../../../assets/svgs/scenes/lp_2.png';
import IAPImgOne from '../../../assets/svgs/scenes/iap_1.png';
import IAPImgTwo from '../../../assets/svgs/scenes/iap_2.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

const modalData = {
    location: {
        header: 'Location Pins',
        info: 'Add location pins to your map so customers can locate key destinations right from the directory.',
        imgStudio: LPImgOne,
        imgApp: LPImgTwo
    },
    imageAccess: {
        header: 'Default Image Access Points',
        info: 'Default image access points are recommended for locations with one entrance. This will be the first access point messaged to visitors to access the map.',
        imgStudio: IAPImgOne,
        imgApp: IAPImgTwo
    }
}

export default function ContentInfoModal({openModal, onCloseModal, type}) {

    const data = modalData[(type && !(type instanceof Object)) ? type : 'location'];

    const classImg = type === 'imageAccess' ? 'tgModal__containerLocation--img' : '';

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal} id="btn_closeInfo"/>
                        <div className='tgModal__header'>{data.header}</div>
                        <div className="mapdetails__mapModalText">{data.info}</div>
                        <div className='tgModal__containerLocation'>
                            <img src={data.imgStudio} alt={"lpImage-1"} draggable={false} className={classImg}/>
                            <img src={data.imgApp} alt={"lpImage-2"} draggable={false} className={classImg}/>
                        </div>
                        <button className='tgModal__button' onClick={onCloseModal} id="btn_successInfo">Got it!</button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
