import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { useSelector, useDispatch } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import StepperMapDetails from "./StepperMapDetails/StepperMapDetails";
import DownloadQR from "./DownloadQR";
import Activation from "./Activation";
import {
  isMapDetailsExist,
} from "../../../../../features/user/studio/studioSlice";
import{
  resetMapDetailsTab
} from '../../../../../features/common/commonSlice'
import QRHelperModal from "../common/QRHelperModal";

const steps = ["Map Details", "Download & Print QR Code", "Activation"];

export default function CreateMapStepper({
  closeModalAfterSuccess,
  setStepHeader,
}) {
  const dispatch = useDispatch();
  // current mapper details
  const [activeStep, setActiveStep] = useState(0);
  const [nextDisabled, setNextDisabled] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isModified, setIsModified] = useState(false);
  const [create, setCreate] = useState(false);
  const [update, setUpdate] = useState(false);
  const isMapDetailsExists = useSelector(isMapDetailsExist);
  const [openHelper, setOpenHelper] = useState(false);
  const [download, setDownload] = useState(false);


  useEffect(() => {
    if (activeStep === 2) {
      dispatch(resetMapDetailsTab());
      setStepHeader("Next steps: Map Activation");
      setOpenHelper(true);
      setDownload(true)
    }
    else setStepHeader("Map Setup");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  const handleNext = () => {
    if (activeStep === 0 && !isMapDetailsExists) {
      setCreate(true);
    } else if (isModified) {
      setUpdate(true);
    } else if (activeStep === steps.length - 1) closeModalAfterSuccess();
    else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepperContent = (index) => {
    switch (index) {
      case 0:
        return (
          <StepperMapDetails
            setNext={(value) => {
              setNextDisabled(value);
            }}
            setUpload={(val) => setIsUploading(val)}
            setActiveStep={() =>
              setActiveStep((prevActiveStep) => prevActiveStep + 1)
            }
            createData={{ create, setCreate }}
            updateObj={{ update, setUpdate }}
            setIsModifiedObj={{ isModified, setIsModified }}
          />
        );
      case 1:
        return <DownloadQR setNext={(val) => setNextDisabled(val)} />;
      case 2:
        return <Activation onQRHelper={setOpenHelper} />;
      default:
        return null
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={activeStep}>
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {
        <React.Fragment>
          <Typography  component={'span'} sx={{ mt: 2, mb: 1 }}>
            {getStepperContent(activeStep)}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              pt: 4,
              justifyContent: "center",
            }}
          >
            {activeStep === 0 ? (
              ""
            ) : (
              <button
                id="button-back"
                className="button-next"
                disabled={activeStep === 0}
                onClick={handleBack}
                style={{marginRight: '12px'}}
              >
                Back
              </button>
            )}
            <button
              id="button-next"
              onClick={handleNext}
              className={`button-next ${nextDisabled || isUploading ? "button-next--disabled" : ""}`}
              disabled={nextDisabled || isUploading}
            >
              {activeStep === steps.length - 1
                ? "Done"
                : (isUploading && isModified)
                ? "Updating"
                : isUploading
                ? "Creating..."
                : isModified
                ? "Update"
                : "Next"}
            </button>
          </Box>
        </React.Fragment>
      }
      <QRHelperModal 
        openModal={openHelper}
        onCloseModal={() => {
          setOpenHelper(false);
          setDownload(false);
        }}
        isDownload={download}
      />
    </Box>
  );
}
