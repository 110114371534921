import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";

//Icon
import CloseIcon from "../../../../assets/icons/sidebar/menu_header.svg";
import ARIcon from "../../../../assets/svgs/scenes/ar_scene.svg";

import { UIPanel, UIImage, UIRow, UITextHeader, UISeparator } from "../ui.lib";

// components
import { SceneSidebarScene } from "./SceneSidebar.Scene";
import { SceneSidebarMaps } from "./SceneSidebar.Maps";

class SceneSidebar extends EventEmitter {
  constructor(props) {
    super(props);
    this.editor = new EditorExperience();
    this.strings = this.editor.strings;
    this.parentEle = props.dom;
    this.parentEle.style.height = "auto";

    this.toggleSceneGraph = false;

    this.dom = null;

    this.initUI();

    this.editor.on("mountObjectLoader", this.onMountObjectLoader);
    this.editor.on("toggleMenuActive", this.onToggleMenuActive);
    // this.editor.on('objectSelected', this.onObjectSelected);
  }

  initUI = () => {
    this.uiCont = new UIPanel();
    this.uiCont
      .addClass("wsSceneSidebarContainer")
      .setId("btn_wsSceneContainer");

    this.uiMinLogoCont = new UIPanel().addClass("wsSceneLgoCont");
    this.uiMinLogoCont.addChild(
      new UIImage(
        ARIcon,
        "34px",
        "34px",
        this.strings.getKey("bottombar/arscene")
      )
    );
    this.uiMinLogoCont.onClick((e) => {
      e.stopPropagation();
      this.toggleSceneGraphView();
    });

    // entire side panel
    this.uiSidePanel = new UIPanel().addClass("wsSceneSidePanel");

    const uiHeaderRow = new UIRow().addClass("wsSceneHeaderRow");
    uiHeaderRow.addChild(
      new UITextHeader("90%", "Scene Content").addClass("wsSceneHeader")
    );
    uiHeaderRow.addChild(
      new UIImage(CloseIcon, "40px", "40px", "close")
        .setStyle("transform", "rotate(180deg)")
        .onClick((e) => {
          e.stopPropagation();
          this.toggleSceneGraphView(false);
        })
    );

    this.uiSidePanel.addChild(uiHeaderRow);
    this.uiSidePanel.addChild(new UISeparator("wsSceneSeparator"));

    // create UI instances
    this.sidebarScene = new SceneSidebarScene(this.editor);
    this.sidebarMaps = new SceneSidebarMaps(this.editor);

    const uiContentPanel = new UIPanel();
    uiContentPanel.addClass("wsSceneSidebarContentsPanel");

    uiContentPanel.addChild(this.sidebarMaps.dom);
    uiContentPanel.addChild(new UISeparator("wsSceneSeparator"));
    uiContentPanel.addChild(this.sidebarScene.dom);

    this.uiSidePanel.addChild(uiContentPanel);

    this.uiSidePanel.setDisplay("none");

    this.uiCont.addChild(this.uiMinLogoCont);
    this.uiCont.addChild(this.uiSidePanel);

    this.parentEle.append(this.uiCont.dom);
  };

  checkParent(parent, child) {
    let node = child.parentNode;
    // keep iterating unless null
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  handleClick = (e) => {
    if (this.editor.processingImportMaps) {
      return;
    }
    !this.checkParent(this.uiCont.dom, e.target) &&
      this.toggleSceneGraphView(false);
  };

  toggleSceneGraphView = (bFlag = undefined) => {
    this.toggleSceneGraph =
      bFlag !== undefined ? bFlag : !this.toggleSceneGraph;
    if (this.toggleSceneGraph) {
      this.parentEle.style.height = "100%";
      this.uiMinLogoCont.setDisplay("none");
      this.uiSidePanel.setDisplay("block");
      this.editor.deselect();
      this.editor.trigger("toggleSceneGraphView", [this.toggleSceneGraph]);
      // window.addEventListener('click', this.handleClick, true);
      this.editor.trigger("sidebarUpdateClassList");
    } else {
      this.parentEle.style.height = "auto"
      this.uiMinLogoCont.setDisplay("flex");
      this.uiSidePanel.setDisplay("none");
      this.editor.trigger("toggleSceneGraphView", [this.toggleSceneGraph]);
      // window.removeEventListener('click', this.handleClick, true);
      this.editor.trigger("sidebarUpdateClassList", [true]);
    }
  };

  onObjectSelected = (object) =>
    object !== null && this.toggleSceneGraphView(false);

  onToggleMenuActive = (name) => {
    // if(name) { this.toggleSceneGraphView(false) }
  };

  onMountObjectLoader = (bFlag) => {
    if (bFlag) {
      this.uiCont.addClass("wsBottomBarContainer--noEvnts");
    } else {
      this.uiCont.removeClass("wsBottomBarContainer--noEvnts");
    }
  };
}

export { SceneSidebar };
