import { Bar } from "react-chartjs-2";
// import externalTooltipHandler from "./customTooltip";
// import { test,customArbitraryLine,arbitraryLine } from "./customArbirtraryLine";

const graphData = (labels, data, labelDesc) => {
  return {
    labels,
    datasets: [
      {
        label: labelDesc,
        data: data,
        backgroundColor: [
          "#3e95cd",
          "#3e95cd",
          "#163499",
          "#446AEB",
          "#359DFF",
          "#c45850",
          "#c45850",
        ],
        borderWidth: 1,
      },
    ],
  };
};

const options = (data) => {
  let yMax = Math.max(...data);
  let yGraphMax = yMax > 100 ? 100 : 10
  return {
    plugins: {
      legend: {
        onClick: null,
        labels: {
          usePointStyle: true,
          boxWidth: 12,
        },
        position: "bottom",
      },
      // tooltip: {
      //   enabled: false,
      //   position: "nearest",
      //   external: (context) => externalTooltipHandler(context, "Bar"),
      // },
      arbitraryLine: {
        arbitraryLineColor: "blue",
        xPosition: 3,
      },
      corsair: {
        dash: [10, 10],
        color: 'blue',
        width: 3
      }
    },
    scales: {
      yAxis: {
        min: 0,
        max: Math.ceil((yMax + yMax * 0.5) / yGraphMax) * yGraphMax,
      },
    },
  };
};

const RenderBarChart = (labels, data, dateObj, labelDesc) => {
  return (
    <div style={{ padding: "0px 32px 0px 32px" }}>
      <Bar
        data={graphData(labels, data, labelDesc)}
        options={options(data)}
        // plugins={[arbitraryLine]}
        // plugins ={[test]}
      />
    </div>
  );
};

export default RenderBarChart;
