import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../../api';
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";

const getAppList = createAsyncThunk('developer/getAppList', async (reqObj,{ dispatch }) => {
    try{
        const response = await Server.get('v1/apps');
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const createApp = createAsyncThunk('developer/createApp', async (reqObj, { dispatch }) => {
    try{
        const response = await Server.post('/v1/apps/', reqObj);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const deleteApp = createAsyncThunk('developer/deleteApp', async (appId, { dispatch }) => {
    try{
        const response = await Server.delete(`/v1/apps/${appId}`);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const updateApp = createAsyncThunk('developer/updateApp', async (reqObj, { dispatch }) => {
    try{
        const response = await Server.put('/v1/apps/', reqObj);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});

const updateAppStatus = createAsyncThunk('developer/updateAppStatus', async (reqObj, { dispatch }) => {
    try{
        const response = await Server.put('/v1/apps/updatestatus', reqObj);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e))
    }
});


const initialState = {
    appsList:[],
    devLog: null,
    deleteLog: null,
    updateLog: null,
};

const developerSlice = createSlice({
    name: 'developer',
    initialState,
    reducers: {},
    extraReducers: {
        'common/resetState': () => {
            return{...initialState}
        },
        [getAppList.fulfilled]: (state, {payload}) => {
            return({
                ...state,
                appsList: payload.data,
                devLog: null,
                deleteLog: null,
                updateLog: null
            })
        },
        [createApp.fulfilled]: (state, { payload }) => {
            return({
                ...state,
                devLog: payload.status
            })
        },
        [updateApp.fulfilled]: (state, { payload }) => {
            return({
                ...state,
                devLog: payload.status
            })
        },
        [updateAppStatus.fulfilled]: (state, { payload }) => {
            return({
                ...state,
                updateLog: payload.status
            })
        },
        [deleteApp.fulfilled]: (state, { payload }) => {
            return({
                ...state,
                deleteLog: payload.status
            })
        },
    }
});

export {
    getAppList,
    createApp,
    updateApp,
    deleteApp,
    updateAppStatus
};

export default developerSlice.reducer;