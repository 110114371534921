import React from "react";
import MapLayersTable from "./MapLayersTable";

function MapLayers({setMapsLayers, maps}) {
  return (
    <div>
      <label className="content-type-select mt-4">
        Rename and reorder your imported maps
      </label>
      <p className="para pt-4">
        Modify the order in which the maps appear in the directory. Rename the
        maps to let visitors know which level or zone they are on, Eg Ground
        Floor. Adjust the naming type for special floors like “Parking.” The
        identifier helps visitors identify the floor level or zone they are in.
      </p>
      <MapLayersTable setMapsLayers={setMapsLayers} maps={maps}/>
    </div>
  );
}

export default MapLayers;
