import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
import * as THREE from "three";

// COMMANDS
import { SetPosition } from "../../commands/SetPosition";
import { SetRotation } from "../../commands/SetRotation";

import {
  UINumber,
  UIPanel,
  UIRow,
  UITextHeader,
  UIText,
  UICol,
  UIButton,
} from "../ui.lib";

class SidebarObjectAccesss extends EventEmitter {
  constructor(props) {
    super(props);

    this.editor = new EditorExperience();
    this.strings = this.editor.strings;

    this.dom = null;
    this.objProperties = {};
    this.Object = {};

    this.prevChanges = 0;

    this.initUI();

    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on("toggleAdjustAccess", this.onToggleAdjustObject);
    this.editor.on("refreshSidebarObjects", this.onRefreshSidebarObjects);
    this.editor.on("objectChanged", this.onObjectChanged);
    this.editor.on("toggleMenuActive", this.onToggleMenuActive);
  }

  initUI = () => {
    this.uiContainer = new UIPanel();
    this.uiContainer.setDisplay("none");

    this.uiContainer.addChild(
      new UITextHeader("100%", "Adjust Access Point").addClass("wsBolderHeader")
    );

    //Transformations
    this.objectTransformCol = new UICol();
    this.objectTransformCol.addChild(new UITextHeader("20%", "Transformation"));

    //Position
    var objectPositionRow = new UIRow();
    this.objectPosX = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setId("transX")
      .setHelper("X")
      .onChange(this.update);
    this.objectPosY = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setId("transY")
      .setHelper("Y")
      .onChange(this.update);
    this.objectPosZ = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setId("transZ")
      .setHelper("Z")
      .onChange(this.update);
    objectPositionRow.addChild(
      new UIText("20%", this.strings.getKey("sidebar/object/position"))
    );
    objectPositionRow.addChild(
      this.objectPosX,
      this.objectPosY,
      this.objectPosZ
    );
    this.objectTransformCol.addChild(objectPositionRow);

    //Rotation
    var objectRotationRow = new UIRow();
    this.objectRotX = new UINumber()
      .setStep(10)
      .setNudge(0.1)
      .setUnit("°")
      .setRange(-360, 360)
      .setStyle("width", "25%")
      .setHelper("X")
      .setId("rotX")
      .onChange(this.update);
    this.objectRotY = new UINumber()
      .setStep(10)
      .setNudge(0.1)
      .setUnit("°")
      .setRange(-360, 360)
      .setStyle("width", "25%")
      .setHelper("Y")
      .setId("rotY")
      .onChange(this.update);
    this.objectRotZ = new UINumber()
      .setStep(10)
      .setNudge(0.1)
      .setUnit("°")
      .setRange(-360, 360)
      .setStyle("width", "25%")
      .setHelper("Z")
      .setId("rotZ")
      .onChange(this.update);
    objectRotationRow.addChild(
      new UIText("20%", this.strings.getKey("sidebar/object/rotation"))
    );
    objectRotationRow.addChild(
      this.objectRotX,
      this.objectRotY,
      this.objectRotZ
    );
    this.objectTransformCol.addChild(objectRotationRow);

    this.uiContainer.addChild(this.objectTransformCol);

    const uiBtns = new UIPanel().addClass("ws_uBtnsCont-bottom");

    //Save btn
    var objectSaveQRCol = new UICol();
    const uiSaveBtn = new UIButton("Save", "ws_uiOverlayBtn", false)
      .setId("ws_uiButtonSaveFloorplan")
      .onClick(() => {
        this.editor.trigger("toggleAdjustAccess", [
          false,
          this.editor.selectedObject,
        ]);
        this.editor.trigger("saveAdjustAccessChanges", [
          this.editor.selectedObject,
        ]);
      });
    objectSaveQRCol.addChild(uiSaveBtn);
    uiBtns.addChild(objectSaveQRCol);

    //cancel btn
    var objectCancelQRCol = new UICol();
    const uiCancelBtn = new UIButton(
      "Cancel",
      "ws_uiOverlayOutlineBtn",
      false
    ).onClick(() => {
      const currChanges = this.editor.history.undos.length - this.prevChanges;
      currChanges > 0 && this.editor.history.undoAllCmds(currChanges);
      this.editor.trigger("toggleAdjustAccess", [
        false,
        this.editor.selectedObject,
      ]);
    });
    objectCancelQRCol.addChild(uiCancelBtn);
    uiBtns.addChild(objectCancelQRCol);

    this.uiContainer.addChild(uiBtns);

    this.dom = this.uiContainer.dom;
  };

  update = () => {
    var object = this.editor.selectedObject;

    if (object !== null) {
      var newPos = new THREE.Vector3(
        this.objectPosX.getValue(),
        this.objectPosY.getValue(),
        this.objectPosZ.getValue()
      );
      const delayAutosave = !("metaFloorplan" in object.userData);

      if (object.position.distanceTo(newPos) >= 0.01) {
        this.editor.onCommand(
          new SetPosition(
            this.editor,
            object,
            newPos,
            object.position.clone(),
            delayAutosave
          )
        );
      }

      var newRot = new THREE.Euler(
        this.objectRotX.getValue() * THREE.MathUtils.DEG2RAD,
        this.objectRotY.getValue() * THREE.MathUtils.DEG2RAD,
        this.objectRotZ.getValue() * THREE.MathUtils.DEG2RAD, "YXZ"
      );
      var vecOldRot = new THREE.Vector3(1, 1, 1).setFromEuler(object.rotation);
      var vecNewRot = new THREE.Vector3(1, 1, 1).setFromEuler(newRot);
      if (vecOldRot.distanceTo(vecNewRot) >= 0.01) {
        this.editor.onCommand(
          new SetRotation(
            this.editor,
            object,
            newRot,
            object.rotation.clone(),
            delayAutosave
          )
        );
      }
    }
  };

  updateUIData = (object) => {
    if (!object) return;

    this.objectPosX.setValue(object.position.x);
    this.objectPosY.setValue(object.position.y);
    this.objectPosZ.setValue(object.position.z);

    this.objectRotX.setValue(object.rotation.x * THREE.MathUtils.RAD2DEG);
    this.objectRotY.setValue(object.rotation.y * THREE.MathUtils.RAD2DEG);
    this.objectRotZ.setValue(object.rotation.z * THREE.MathUtils.RAD2DEG);
  };

  onObjectSelected = () => {
    this.editor.toAdjustAccess = false;
    this.uiContainer.setDisplay("none");
    // this.editor.trigger('toggleAdjustAccess', [ false, this.editor.selectedObject ])
  };

  onToggleAdjustObject = (state, object) => {
    if (state && object !== null) {
      this.editor.toAdjustAccess = state;
      this.uiContainer.setDisplay("block");
      this.updateUIData(object);
      this.prevChanges = this.editor.history.undos.length;
      this.editor.trigger("sidebarUpdateClassList", [true]);
    } else {
      this.editor.toAdjustAccess = false;
      this.uiContainer.setDisplay("none");
    }
  };

  onToggleMenuActive = (name) => {
    if (name !== null) {
      this.uiContainer.setDisplay("none");
    }
  };

  onRefreshSidebarObjects = (object) => {
    // console.log("onRefreshSidebarObjects", object);
  };

  onObjectChanged = (object) => {
    if (object !== this.editor.selectedObject) return;
    this.updateUIData(object);
  };
}

export { SidebarObjectAccesss };
