import * as THREE from "three";
import EventEmitter from "../../../utils/EventEmitter";
import EditorExperice from "../../2DEditorExperience";
import { PathNode } from "./PathNode";
import { PathEdge } from "./PathEdge";
import {
    fromPosObjectToVec3Pos,
    fromVec3PosToPosObject,
    getTransformed3DPos,
    getTransformed3DPosV1,
} from "../../../threeUtils/TransformConversions";

class PathGraph extends EventEmitter {
    constructor(pathData) {
        super();

        this.editor = new EditorExperice();

        this.id = pathData.id;
        this.colors = {
            walkable: "#FFDC26",
            allAccess: "#2A4DC2",
        };

        this.drawingStateColor = {
            walkable: "#FFF3B3",
            allAccess: "#96A9E8",
        };

        this.currentType = pathData.pathType || "walkable";
        this.pathColor = this.colors[this.currentType];
        this.visible = pathData.visible || true;

        this.pathStroke = pathData.pathStroke || 4;

        this.nodes = new Map();
        this.nodePath = pathData.nodePath || [];
        this.removedNodes = [];
        this.baseNode = null;

        this.mesh = new THREE.Group();
        this.mesh.userData["interativeChildren"] = true; //! working on a bug of node move!
        this.mesh.userData["nodeGraph"] = true;
        this.mesh.userData["viewType"] = this.currentType;
        this.mesh.visible = this.visible;
        this.mesh.userData["visible"] = this.visible;

        pathData?.nodes && this.setNodes(pathData.nodes);

        // this.editor.on('objectRemoved', this.onObjectRemoved);
        this.editor.on("editor2DNodePosUpdated", this.onEditor2DNodePosUpdated);
        this.editor.on("updatePathStroke", this.onUpdatePathStroke);
    }

    setType = (type) => {
        this.currentType = type;
        this.pathColor = this.colors[this.currentType];
    };

    // add node into graph
    addNode = (id, data) => {
        if (this.nodes.has(id)) {
            return this.nodes.get(id);
        }

        const node = new PathNode({ ...data, pathStroke: this.pathStroke });
        if (
            this.baseNode === null &&
            Array.from(this.nodes.values()).length === 0
        ) {
            this.baseNode = node.id;
        }
        this.nodes.set(id, node);
        // in 3d too
        this.mesh.add(node.nodeMesh);
        return node;
    };

    updateNode = (id, data) => {
        if (!this.nodes.has(id)) {
            return;
        }

        const node = this.getNode(id);
        node.updateNode(data);
    };

    removeNode(id, keepTrack = false) {
        const currentNode = this.nodes.get(id);

        if (currentNode) {
            Array.from(this.nodes.values()).forEach((node) =>
                node.removeAdjacent(currentNode)
            );
        }

        keepTrack && this.removedNodes.push(this.nodes.get(id));

        return this.nodes.delete(id);
    }

    getNode = (id) => {
        if (this.nodes.has(id)) {
            return this.nodes.get(id);
        }
        return null;
    };

    addEdge(srcId, destId) {
        const srcNode = this.getNode(srcId);
        const destNode = this.getNode(destId);

        if (srcNode && destNode) {
            srcNode.addAdjacent(destNode);
            destNode.addAdjacent(srcNode);

            return [srcNode, destNode];
        }
        return [];
    }

    removeEdge(srcId, destId) {
        const srcNode = this.nodes.get(srcId);
        const destNode = this.nodes.get(destId);

        if (srcNode && destNode) {
            srcNode.removeAdjacent(destNode);
            destNode.removeAdjacent(srcNode);
        }

        return [srcNode, destNode];
    }

    areAdjacent(srcId, destId) {
        const srcNode = this.nodes.get(srcId);
        const destNode = this.nodes.get(destId);

        if (srcNode && destNode) {
            return srcNode.isAdjacent(destNode);
        }

        return false;
    }

    // 3D functions
    setNodes(nodes) {
        // set nodes first
        nodes.forEach((node, index) => {
            this.addNode(node.id, node);
        });
        // add edges frm backend / auto
        nodes.forEach((node, idx) => {
            if ("adjacentNodes" in node && node.adjacentNodes.length) {
                node.adjacentNodes.forEach((aNode) =>
                    this.addEdge(node.id, aNode)
                );
            } else {
                if (idx > 0) {
                    this.addEdge(node.id, nodes[idx - 1].id);
                }
            }
        });
        // setEdges and draw them!
        this.setEdges();
    }

    /* setEdge = (srcId, destId) => {
        this.addEdge(srcId, destId)
    } */

    setEdges = () => {
        Array.from(this.nodes.values()).forEach((node) => {
            node.adjacents.forEach((adNode) => {
                if (
                    !node.edgesDrawn.has(adNode.id) &&
                    !adNode.edgesDrawn.has(node.id)
                ) {
                    let edge = new PathEdge({
                        vertices: [
                            node.position2d.clone(),
                            adNode.position2d.clone(),
                        ],
                        pathColor: this.pathColor,
                        pathStroke: this.pathStroke,
                    });

                    node.edgesDrawn.set(adNode.id, edge);
                    adNode.edgesDrawn.set(node.id, edge);

                    this.mesh.add(edge.mesh);
                }
            });
        });
    };

    setEdge = (nodeId, adNodeId, edgeState) => {
        const node = this.nodes.get(nodeId);
        const adNode = this.nodes.get(adNodeId);

        if (
            !node.edgesDrawn.has(adNode.id) &&
            !adNode.edgesDrawn.has(node.id)
        ) {
            let edge = new PathEdge({
                vertices: [node.position2d.clone(), adNode.position2d.clone()],
                pathColor: this.pathColor,
                pathStroke: this.pathStroke,
            });

            node.edgesDrawn.set(adNode.id, edge);
            adNode.edgesDrawn.set(node.id, edge);

            this.mesh.add(edge.mesh);
        } else if (
            node.edgesDrawn.has(adNode.id) &&
            adNode.edgesDrawn.has(node.id)
        ) {
            const edge = node.edgesDrawn.get(adNode.id);

            let color = this.colors[this.currentType];
            const { isStraightLine, newVec } = this.getCoplanerAngle(
                node.position2d,
                adNode.position2d
            );
            if (edgeState && edgeState !== "DRAWN" && !isStraightLine) {
                color = this.drawingStateColor[this.currentType];
            }

            if (newVec) {
                adNode.updateNode({
                    position2d: {
                        posX: newVec.x,
                        posY: Math.abs(newVec.y),
                    },
                    position: this.getTransformedPos({
                        posX: newVec.x,
                        posY: Math.abs(newVec.y),
                    }),
                }, true);
            }

            edge.updateGeometryVertices(
                [node.position2d.clone(), adNode.position2d.clone()],
                color
            );
        }
    };

    getCoplanerAngle = (p, n) => {
        const rightAngles = [0, 90, 180];
        const c = p.clone();
        c.y += -2;

        let cp = new THREE.Vector3().subVectors(p, c);
        let cn = new THREE.Vector3().subVectors(n, p);

        var crossProduct = new THREE.Vector3().crossVectors(cp, cn);
        var dotProduct = cp.dot(cn);
        var angleRad = Math.atan2(crossProduct.length(), dotProduct);
        var orientation = crossProduct.dot(p);
        var signedAngleRad = orientation >= 0 ? angleRad : -angleRad;
        var signedAngleDeg = THREE.MathUtils.radToDeg(signedAngleRad);

        let canOffsetToRightAngle = false;
        let subAngle = null;
        rightAngles.forEach((angle) => {
            if (
                !canOffsetToRightAngle &&
                Math.abs(angle - Math.abs(signedAngleDeg)) <= 4
            ) {
                canOffsetToRightAngle = true;
                subAngle = angle;
            }
        });

        const dirClone = p.clone();

        if (canOffsetToRightAngle) {
            const vecLength = p.distanceTo(n);

            if (subAngle === 90) {
                if (signedAngleDeg > 0) {
                    dirClone.x =
                        p.x - vecLength * Math.cos(THREE.MathUtils.degToRad(0));
                    dirClone.y =
                        p.y - vecLength * Math.sin(THREE.MathUtils.degToRad(0));
                } else {
                    dirClone.x =
                        p.x + vecLength * Math.cos(THREE.MathUtils.degToRad(0));
                    dirClone.y =
                        p.y + vecLength * Math.sin(THREE.MathUtils.degToRad(0));
                }
            } else {
                dirClone.x =
                    p.x +
                    vecLength *
                        Math.cos(
                            THREE.MathUtils.degToRad(subAngle === 0 ? 90 : 270)
                        );
                dirClone.y =
                    p.y +
                    vecLength *
                        Math.sin(
                            THREE.MathUtils.degToRad(subAngle === 0 ? 90 : 270)
                        );
            }

        }

        if (canOffsetToRightAngle) {
            return { isStraightLine: canOffsetToRightAngle, newVec: dirClone };
        } else {
            return {
                isStraightLine:
                    rightAngles.indexOf(Math.abs(signedAngleDeg)) !== -1,
                newVec: null,
            };
        }
    };

    getTransformedPos = (pos) => {
        const { worldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6
            ? getTransformed3DPos(worldMatrix, imgWidth, imgHeight, pos, true)
            : getTransformed3DPosV1(
                  worldMatrix,
                  imgWidth,
                  imgHeight,
                  pos,
                  true
              );
    };

    calculateDistance = (ptStart, ptEnd) => {
        const vec1 = ptStart.position.clone();
        const vec2 = ptEnd.position.clone();
        let lineDistance = vec1.distanceTo(vec2);
        let points = [];

        points.push({
            position: fromVec3PosToPosObject(ptStart.position.clone()),
        });

        while (lineDistance.toFixed(2) > 1.3) {
            let subV = new THREE.Vector3();
            let vecPrev = fromPosObjectToVec3Pos(
                points[points.length - 1].position
            );
            subV.subVectors(vec2, vecPrev).setLength(1).add(vecPrev);

            points.push({ position: fromVec3PosToPosObject(subV.clone()) });
            lineDistance = subV.distanceTo(vec2);
        }
        // lineDistance.toFixed(2) < 1.00 && points.push({ position: fromVec3PosToPosObject(vec2.clone())});

        return [...points];
    };

    // helper functions!
    computeNodePath = () => {
        let pathNodes = [];

        // if all nodes are deleted then just update empty node path!
        if (!Array.from(this.nodes.values()).length) {
            this.nodePath = 0;
            return;
        }
        // BFS to traverse!
        let toVisitQueue = [Array.from(this.nodes.values())[0].id];
        let visited = {};
        visited[Array.from(this.nodes.values())[0].id] = true;

        while (toVisitQueue.length) {
            const startPt = this.nodes.get(toVisitQueue.shift());
            const adjacentes = startPt.getAdjacents();
            adjacentes.forEach((adNode) => {
                if (!visited[adNode.id]) {
                    visited[adNode.id] = true;
                    // compute node paths
                    pathNodes.push(...this.calculateDistance(startPt, adNode));
                    // add to visit Next for adjacents
                    toVisitQueue.push(adNode.id);
                }
            });
        }
        this.nodePath = [...pathNodes];
    };

    computeUpdatedNodePositions = (flag = true) => {
        Array.from(this.nodes.values()).forEach((node) =>
            flag ? node.computeTransformed3D() : node.computeTransformed2D()
        );
    };

    updateNodeStates = () => {
        const nodes = Array.from(this.nodes.values());
        nodes.forEach((node) => node.setNodeState("existing"));
    };

    isStrandedNode = (nodeId) => {
        let toVisitQueue = [nodeId];
        let visited = {};
        let canVisitBase = false;

        while (toVisitQueue.length) {
            const node = this.nodes.get(toVisitQueue.shift());
            const adjacentes = node.getAdjacents();

            if (canVisitBase) break;

            // eslint-disable-next-line
            adjacentes.forEach((adNode) => {
                if (canVisitBase) return;
                if (!visited[adNode.id]) {
                    visited[adNode.id] = true;

                    if (adNode.id === this.baseNode) {
                        canVisitBase = true;
                    }

                    toVisitQueue.push(adNode.id);
                }
            });
        }

        return canVisitBase;
    };

    removeActiveNodeEdge = (id) => {
        const node = this.nodes.get(id);
        if (node) {
            const adNodes = node.getAdjacents();
            adNodes.forEach((aNode) => {
                const edge = node.edgesDrawn.get(aNode.id);
                // remove edge 3D
                this.mesh.remove(edge.mesh);
                this.removeEdge(node.id, aNode.id);
            });
            this.mesh.remove(node.nodeMesh);
            this.removeNode(node.id);
        }
    };

    removeNewNodesAndEdges = () => {
        const nodes = Array.from(this.nodes.values());
        nodes.forEach((node) => {
            if (node.nodeState === "add") {
                const adNodes = node.getAdjacents();
                adNodes.forEach((aNode) => {
                    const edge = node.edgesDrawn.get(aNode.id);
                    // remove edge 3D
                    this.mesh.remove(edge.mesh);
                    this.removeEdge(node.id, aNode.id);
                });
                this.mesh.remove(node.nodeMesh);
                this.removeNode(node.id);
            }
        });
    };

    removeNodeandEdges = (id) => {
        const node = this.nodes.get(id);

        if (node) {
            const adNodes = node.getAdjacents();

            adNodes.forEach((aNode) => {
                const edge = node.edgesDrawn.get(aNode.id);
                // remove edge 3D
                this.mesh.remove(edge.mesh);

                this.removeEdge(node.id, aNode.id);

                aNode.setNodeState("updated");

                if (!this.isStrandedNode(aNode.id)) {
                    this.removeAllAdjacentNodes(aNode.id);
                }

                // console.log(aNode, this.isStrandedNode(aNode.id));
            });

            this.mesh.remove(node.nodeMesh);
            this.removeNode(node.id, true);
        }

        if (Array.from(this.nodes.keys()).length === 0) {
            // removed all nodes
            this.editor.onPathAPICalls(this.id, "DELETE");
        }
    };

    removeAllAdjacentNodes = (id) => {
        let toVisitQueue = [id];
        let visited = {};

        while (toVisitQueue.length) {
            const node = this.nodes.get(toVisitQueue.shift());
            const adjacentes = node.getAdjacents();

            adjacentes.forEach((adNode) => {
                if (!visited[adNode.id]) {
                    visited[adNode.id] = true;

                    const edge = node.edgesDrawn.get(adNode.id);
                    this.mesh.remove(edge.mesh);
                    this.removeEdge(node.id, adNode.id);
                    adNode.setNodeState("updated");

                    toVisitQueue.push(adNode.id);
                }
            });
            this.mesh.remove(node.nodeMesh);
            this.removeNode(node.id, true);
        }
    };

    toJSON = () => {
        this.computeNodePath();
        return {
            id: this.id,
            pathStroke: this.pathStroke,
            pathType: this.currentType,
            nodes: Array.from(this.nodes.values()).map((node) => {
                return {
                    id: node.id,
                    position2d: {
                        posX: node.position2d.x,
                        posY: Math.abs(node.position2d.y),
                    },
                    position: fromVec3PosToPosObject(node.position),
                    adjacentNodes: Array.from(node.adjacents.values()).map(
                        (ad) => ad.id
                    ),
                };
            }),
            nodePath: this.nodePath,
        };
    };

    onEditor2DNodePosUpdated = (nodeId) => {
        if (this.nodes.get(nodeId)) {
            this.computeNodePath();
            this.editor.trigger("editor2DGraphUpdated", [this.id]);
        }
    };

    onUpdatePathStroke = (object, newStroke) => {
        if (
            object &&
            "nodeMesh" in object.userData &&
            this.nodes.get(object.userData.id)
        ) {
            this.pathStroke = newStroke;
            Array.from(this.nodes.values()).forEach((node) =>
                node.updatePathStroke(newStroke)
            );
            this.editor.trigger("editor2DGraphUpdated", [this.id]);
        }
    };

    onObjectRemoved = (object, d, isSwitch) => {
        if (!isSwitch && object === this.mesh) {
            this.editor.onPathAPICalls(this.id, "DELETE");
        }
    };
}

export { PathGraph };
