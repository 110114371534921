import CategoriesHome from "./CategoriesHome";
import React, { useState, useEffect, useRef } from "react";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import { setNewAlert } from "../../../../features/common/alertSlice";
import {
  getCategories,
  updateCategoryBulk,
} from "../../../../features/webstudio/editor2dSlice";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
// import { DevTool } from "@hookform/devtools";
import { XCircle } from "react-feather";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  maxHeight: "80% !important",
  height: "80%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none !important",
  outline: "none !important",
  display: "flex",
  flexDirection: "column",
  alignItems: "center !important",
  borderRadius: "16px",
};

const CategoryModal = ({ openModal, onCloseModal, modalData }) => {
  const dispatch = useDispatch();
  const childRef = useRef();
  const form = useForm({    shouldUnregister: true});
  const { register, /* control, */ handleSubmit, formState, getValues, resetField, unregister } = form;
  const { errors } = formState;

  const [save, setSave] = useState(false);

  useEffect(() => {
    if (save) {
      dispatch(
        updateCategoryBulk({
          mapId: modalData.mapId,
          data: finalCategories,
        })
      ).then(() => {
        unregister()
        // console.log("Form submited", getValues());
        onCloseModal();
        dispatch(
          setNewAlert({
            msg: "Details updated",
            alertType: "information",
          })
        );
        setSave(false)
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [save]);

  const onSubmit = (data) => {
    // console.log("Form submited", data);
    console.log("HEREE", data.newCategory, errors);
    if(data.newCategory!==""){
      childRef.current.addCategory();
    }
    childRef.current.addEditedCategories()
    childRef.current.addSubCategory()
    childRef.current.disableSubInput()
    setSave(true);
  };

  useEffect(() => {
    if (modalData) dispatch(getCategories(modalData?.mapId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData ? modalData.showCategoriesModal : null]);

  const [finalCategories, setFinalCategories] = useState([]);

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 600,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <Fade in={openModal}>
            <Box sx={style}>
              <XCircle
                color="#353E5A"
                strokeWidth="1.5"
                className="mapdetails__mapModalCloseIcon"
                onClick={onCloseModal}
              />
              <CategoriesHome
                modalData={modalData}
                setFinalCategories={setFinalCategories}
                register={register}
                errors={errors}
                getValues={getValues}
                resetField={resetField}
                ref={childRef}
                unregister={unregister}
              />
              <div className="row justify-content-between" style={{marginTop: '4.5rem'}}>
                <button
                  className="col-sm cancel-btn cat-btn"
                  onClick={onCloseModal}
                >
                  Cancel
                </button>
                <button
                  className="col-sm apply-btn cat-btn"
                  style={{ marginLeft: "40px" }}
                  type="submit"
                  onClick={() => {
                    // console.log(childRef);
                    // prevFinalCategories.current=finalCategories
                  }}
                >
                  Apply!
                </button>
              </div>
              {/* <DevTool control={control} /> */}
            </Box>
          </Fade>
        </form>
      </Modal>
    </div>
  );
};

export default CategoryModal;
