import React, { useEffect } from "react";
import Dropdown from "./Dropdown";

function NamingConvention({setNamingConvention, namingConvention=null}) {
  const Nlist = [
    { value: "levels", label: "Levels" },
    { value: "floors", label: "Floors" },
    { value: "zones", label: "Zones" },
    { value: "areas", label: "Areas" },
    { value: "sections", label: "Sections" },
    { value: "parking", label: "Parking" },
  ];
  useEffect(()=>{
    setNamingConvention(namingConvention?namingConvention:Nlist[0].value)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  return (
    <div>
      <label className="content-type-select mt-4">Naming Convention</label>
      <p className="para pt-4">
        For a multi-floor venue like a mall select “Levels” or “Floors.” For a
        multi-area venue like a convention center select “Zone”, “Area” or
        “Section”
      </p>
      <Dropdown list={Nlist} defaultVal={namingConvention?namingConvention:Nlist[0].value} setvalue={setNamingConvention}/>
    </div>
  );
}

export default NamingConvention;
