//icons

import AllAccessIcon from '../../../assets/svgs/scenes/menu/allaccess_path.svg';
import WalkableOnlyIcon from '../../../assets/svgs/scenes/menu/walkable_path.svg';
import LocationPinIcon from '../../../assets/svgs/scenes/menu/navigation.svg';
import QRCodeIcon from '../../../assets/svgs/scenes/menu/qrcode.svg';
import MapMarkerIcon from '../../../assets/svgs/scenes/menu/mapmarker.svg';
import TourIcon from '../../../assets/svgs/scenes/menu/path.svg';
import FloorIcon from '../../../assets/svgs/scenes/menu/floorplan.svg';
import IAPIcon from '../../../assets/svgs/scenes/menu/imageaccess.svg';
import ConnectorIcon from '../../../assets/svgs/scenes/menu/connector_pin.svg';

function Strings() {

    var values = {
        'sidebar/object/title': 'Properties',
        'sidebar/object/type': 'Type',
        'sidebar/object/uuid': 'UUID',
        'sidebar/object/name': 'Name',
        'sidebar/object/desc': 'Description',
        'sidebar/object/hName': 'Label',
        'sidebar/object/hLink': 'Hyperlink',
        'sidebar/object/position': 'Position',
        'sidebar/object/rotation': 'Rotation',
        'sidebar/object/scale': 'Scale',
        'sidebar/object/fov': 'FOV',
        'sidebar/object/left': 'Left',
        'sidebar/object/right': 'Right',
        'sidebar/object/top': 'Top',
        'sidebar/object/bottom': 'Bottom',
        'sidebar/object/near': 'Near',
        'sidebar/object/far': 'Far',
        'sidebar/object/intensity': 'Intensity',
        'sidebar/object/color': 'Color',
        'sidebar/object/distance': 'Distance',
        'sidebar/object/angle': 'Angle',
        'sidebar/object/penumbra': 'Penumbra',
        'sidebar/object/decay': 'Decay',
        'sidebar/object/shadow': 'Shadow',
        'sidebar/object/cast': 'Cast',
        'sidebar/object/receive': 'Receive',
        'sidebar/object/visible': 'Visible',
        'sidebar/object/audio': 'Audio',
        'sidebar/object/video': 'Video',
        'sidebar/object/showAsIcon': 'Display',
        'sidebar/object/autoplay': 'Autoplay',
        'menubar/menu': 'Menu',
        'menubar/text': 'Text',
        'menubar/image': 'Images',
        'menubar/audio': 'Audio',
        'menubar/model': '3D Models',
        'menubar/hotspot': 'Hotspot',
        'menubar/floorplan': 'Floor Plan',
        'menubar/waypoint': 'Location Pins',
        'menubar/connector': 'Map Connectors',
        'menubar/navigation': 'Guided Tours',
        'menubar/autosave': 'Autosave',
        'menubar/color': 'Text Color',
        'menubar/video': 'Videos',
        'menubar/qrcode': 'QR Codes',
        'menubar/imgaccess': 'Image Access',
        'menubar/mapmarker': 'Map Markers',
        'menubar/autosave/progress': "Saving in progress...",
        'bottombar/arscene': 'Scene Contents',
        'bottombar/view': 'View',
    }

    return {
        getKey: function(key) {
            return values[key] || "N/A";
        }
    };
}

function ViewOptions () {

    var options = {
        '0' : {
            id: 0,
            name: 'Walk-able Only Paths',
            state: true,
            type: 'walkable',
            enable: false,
            icon: WalkableOnlyIcon,
        },
        '1' : {
            id: 1,
            name: 'All Access Paths',
            state: true,
            type: 'allAccess',
            enable: false,
            icon: AllAccessIcon,
        },
        '2' : {
            id: 2,
            name: 'Connector Pins',
            state: true,
            type: 'Connector Pin',
            enable: false,
            icon: ConnectorIcon,
        },
        '3' : {
            id:3,
            name: 'Location Pins',
            state: true,
            type: 'Location Pin',
            enable: false,
            icon: LocationPinIcon,
        },
        '4' : {
            id: 4,
            name: 'Floor Plan',
            state: true,
            type: 'floorplan',
            enable: false,
            icon: FloorIcon,
        },
        '5' : {
            id: 5,
            name: 'QR Codes',
            state: true,
            type: 'qrAnchor',
            enable: false,
            icon: QRCodeIcon,
        },
        '6' : {
            id: 6,
            name: 'Map Markers',
            state: true,
            type: 'mapMarker',
            enable: false,
            icon: MapMarkerIcon,
        },
        '7' : {
            id: 7,
            name: 'Floor Plan Anchors',
            state: true,
            type: 'floorplanPeg',
            enable: false,
            icon: LocationPinIcon
        },
        '8' : {
            id: 8,
            name: 'Image Access Points',
            state: true,
            type: 'imageAccessPoint',
            enable: false,
            icon: IAPIcon,
        },
        '9' : {
            id: 9,
            name: 'Guided Tours',
            state: true,
            type: ['Guided Tour', 'wayPointGroup'],
            enable: false,
            icon: TourIcon,
        },     
    }

    var breakIndices = [ 2, 4, 7 ]

    return {
        toArray: function() {
            return Object.values(options);
        },

        setState: function(id) {
            if(Object.keys(options).length >= id) {
                var lState = options[id].state;
                options[id].state = !lState;
                return 0;
            } else return -1;
        },

        setEnable: function(id) {
            if(Object.keys(options).length >= id) {
                var lEnable = options[id].enable;
                options[id].enable = !lEnable;
                return 0;
            } else return -1;
        },

        getOption: function(id) {
            return options[id] || null;
        },

        getTypes: function() {
            return Object.values(options).map(opt => opt.type).flat();
        },

        getBreakIndices: function() {
            return breakIndices;
        },

        resetAll: function() {
            for(var opt in options) {
                options[opt].enable = false;
                options[opt].state = true;
            }
        },
    }
}

function SceneTypes () {
    var types = {
        'floorplan': 'Floor Plans',
        'floorplanPeg': 'Floor Plan Pegs',
        'Location Pin': 'Location Pins',
        'Connector Pin': 'Connector Pins',
        'Guided Tour': 'Guided Tours',
        'wayPointGroup': 'Guided Tours',
        'walkable': 'Walkable Paths',
        'allAccess': 'All Access Paths',
        'audio': 'Audios',
        'hotspot': 'Hotspots',
        'image': 'Images',
        'imageAccessPoint': 'Access Points',
        'mapMarker': 'Mapmarkers',
        'model': '3D Models',
        'qrAnchor': 'Access Points',
        'text': 'Texts',
        'video': 'Videos',
    }

    var subTypes = {
        'imageAccessPoint': 'Image Access Points',
        'qrAnchor': 'QR Codes',
    }

    var forSubTypes = [ 'accesspoints' ]

    return {
        getType: function (key) {
            return types[key] || '3D Objects';
        },

        getSubType: function (key) {
            return subTypes[key] || '3D Objects';
        },

        isSubType: function(key) {
            return (forSubTypes.indexOf(key.replace(/\s/g,'').toLowerCase()) > -1);
        }
    }
}

export { Strings, ViewOptions, SceneTypes };