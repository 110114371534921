import React, {
  useEffect,
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import useComponentVisible from "./useComponentVisible";
import CategoriesData from "./CategoriesData";
import { closestCenter, DndContext } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { isNull, isEqual, has, uniqueId, cloneDeep, isEmpty } from "lodash";
import addPNG from "../../../../assets/pngs/studio/locationPin/add.png";
import { useSelector } from "react-redux";
import { MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import DeleteCategoryModal from "./DeleteCategoryModal";
import { X } from "react-feather";

const CategoriesHome = (
  {
    modalData,
    setFinalCategories,
    register,
    errors,
    getValues,
    resetField,
    unregister
  },
  ref
) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      delay: 300,
    },
  });
  const sensors = useSensors(mouseSensor);

  const categories = useSelector((state) => state.editor2D.categories);
  const [categoriesReorder, setCategoriesReorder] = useState(categories);
  // console.log(categoriesReorder);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCat, setDeleteCat] = useState({});

  const { /* visRef, */ isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const inputScrollRef = useRef(null);
  // const inputRef = useRef(null)

  const childRef = useRef();

  useEffect(() => {
    if (modalData.data === null && modalData.isAdd) {
      setIsComponentVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isComponentVisible) {
      inputScrollRef.current.scrollIntoView({
        behavior: "smooth", block: 'end',
        inline: 'nearest'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible]);

  useEffect(() => {
    setFinalCategories(categoriesReorder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesReorder]);

  const creteCategory = (name, parentId = null, type = null) => {
    // console.log(categoriesReorder, categories);
    const newCategory = {
      name,
      order: isNull(parentId)
        ? isEqual(categories, categoriesReorder)
          ? categories.length + 1
          : categoriesReorder.length + 1
        : isEqual(categories, categoriesReorder)
          ? categories.find((cat) => cat.id === parentId).children === undefined
            ? 1
            : categories.find((cat) => cat.id === parentId).children.length + 1
          : categoriesReorder.find((cat) => cat.id === parentId).children ===
            undefined
            ? 1
            : categoriesReorder.find((cat) => cat.id === parentId).children.length +
            1,
      id: parseInt(uniqueId()),
      ...(isEqual("subcat", type) ? {} : { children: [] }),
    };
    // console.log(newCategory);
    return newCategory;
  };

  useImperativeHandle(ref, () => {
    return {
      addCategory: () => {
        if (getValues("newCategory") !== undefined) {
          setCategoriesReorder([
            ...(isEqual(categories, categoriesReorder)
              ? categories
              : categoriesReorder),
            creteCategory(getValues("newCategory")),
          ]);
          unregister("newCategory");
          setIsComponentVisible(false);
        }
      },
      addEditedCategories: () => {
        // console.log(getValues())
        categoriesReorder.forEach((cat, i) => {
          if (getValues(`category${cat.id}`) !== undefined) {
            let data = { category: { id: cat.id, name: getValues(`category${cat.id}`) } }
            handleEdit(data, true)
          }
        })
      },
      addSubCategory: () => {
        const updatedCategories = cloneDeep([
          ...(isEqual(categories, categoriesReorder)
            ? categories
            : categoriesReorder),
        ]);
        categoriesReorder.forEach((cat, i) => {
          if (getValues(`newSubCategory${cat.id}`) !== undefined) {
            if (!has(updatedCategories[i], "children")) {
              updatedCategories[i]["children"] = [];
            }
            updatedCategories[i].children.push(
              creteCategory(
                getValues(`newSubCategory${cat.id}`),
                cat.id,
                "subcat"
              )
            );
            childRef.current.disableInput();
            unregister(`newSubCategory${cat.id}`);
          }
        });
        setCategoriesReorder(updatedCategories);
      },
      disableSubInput: () => {
        childRef.current.disableInput();
      }
    };
  });

  const handleAddCategory = () => {
    if (getValues("newCategory") !== undefined && !isEmpty(getValues("newCategory"))) {
      setCategoriesReorder([
        ...(isEqual(categories, categoriesReorder)
          ? categories
          : categoriesReorder),
        creteCategory(getValues("newCategory")),
      ]);
      unregister("newCategory");
      setIsComponentVisible(false);
    }
  };

  const handleAddSubcategory = (subCatName, parentId) => {
    const updatedCategories = cloneDeep([
      ...(isEqual(categories, categoriesReorder)
        ? categories
        : categoriesReorder),
    ]);
    if (!has(updatedCategories[selectedCategory], "children")) {
      updatedCategories[selectedCategory]["children"] = [];
    }
    updatedCategories[selectedCategory].children.push(
      creteCategory(subCatName, parentId, "subcat")
    );
    setCategoriesReorder(updatedCategories);
    unregister(`newSubCategory${parentId}`);
  };

  const handleKeyDown = (type, data) => {
    if (type === "subcat") handleAddSubcategory(data.name, data.parentId);
    else handleAddCategory();
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (!(active && over)) {
      return;
    }
    if (active.id !== over.id) {
      const updatedCategories = cloneDeep([
        ...(isEqual(categories, categoriesReorder) ||
          isEmpty(categoriesReorder)
          ? categories
          : categoriesReorder),
      ]);

      // check is over is parent
      let overIndex = updatedCategories.findIndex((cat) => cat.id === over.id);
      let activeParentIndex = updatedCategories.findIndex((cat) => {
        if (has(cat, "children")) {
          return cat.children.some((subcat) => subcat.id === active.id);
        }
        return -1;
      });
      // check is active is chid and over is parent
      if (overIndex > -1 && activeParentIndex > -1) {
        // swap sub to main
        // get selected child's parent
        let selectedCat = updatedCategories[activeParentIndex];
        let updatedSubCategories = selectedCat.children;
        let index = updatedSubCategories.findIndex(
          (subcat) => subcat.id === active.id
        );
        if (index > -1) {
          updatedSubCategories.splice(index, 1);
        }
        updatedCategories[activeParentIndex].children = updatedSubCategories;
        updatedCategories.splice(
          overIndex,
          0,
          creteCategory(active.data.current.children.name)
        );
        setCategoriesReorder([...updatedCategories]);
      }
      //active and over both parent
      else if (overIndex > -1 && activeParentIndex === -1) {
        const updatedCategories = cloneDeep([
          ...(isEqual(categories, categoriesReorder) ||
            isEmpty(categoriesReorder)
            ? categories
            : categoriesReorder),
        ]);

        const activeIndex = updatedCategories.findIndex(
          (cat) => cat.id === active.id
        );

        const overIndex = updatedCategories.findIndex(
          (cat) => cat.id === over.id
        );

        // console.log(activeIndex, overIndex);
        let updatedCategory = arrayMove(
          updatedCategories,
          activeIndex,
          overIndex
        );
        setCategoriesReorder([...updatedCategory]);
      } else {
        const cateIndex = updatedCategories.findIndex(
          (cat) => active.data.current.parent.id === cat.id
        );
        let selectedSubCat =
          isEqual(categories, categoriesReorder) ||
            isEmpty(categoriesReorder)
            ? categories[cateIndex].children
            : categoriesReorder[cateIndex].children;
        const activeIndex = selectedSubCat.indexOf(
          selectedSubCat[
          selectedSubCat.findIndex((subcat) => subcat.id === active.id)
          ]
        );
        const overIndex = selectedSubCat.indexOf(
          selectedSubCat[
          selectedSubCat.findIndex((subcat) => subcat.id === over.id)
          ]
        );
        let updatedSubCategories = arrayMove(
          selectedSubCat,
          activeIndex,
          overIndex
        );
        updatedCategories[cateIndex].children = updatedSubCategories;
        setCategoriesReorder([...updatedCategories]);
      }
    }
  };

  const handleDelete = (data, isParent = false) => {
    // console.log(data);
    setShowDeleteModal(true);
    setDeleteCat({
      isCategory: isParent,
      categoryName: isParent ? data.category.name : data.subcat.name,
      data: data,
    });
    // console.log(deleteCat);
  };

  const delteCategoty = () => {
    const updatedCategories = cloneDeep([
      ...(isEqual(categories, categoriesReorder) ||
        isEmpty(categoriesReorder)
        ? categories
        : categoriesReorder),
    ]);

    if (deleteCat.isCategory) {
      let deleteCatIndex = updatedCategories.findIndex(
        (cat) => cat.id === deleteCat.data.category.id
      );
      if (deleteCatIndex > -1) {
        updatedCategories.splice(deleteCatIndex, 1);
      }
      setCategoriesReorder([...updatedCategories]);
    } else {
      let selectedCategoryIndex = updatedCategories.findIndex(
        (cat) => deleteCat.data.category.id === cat.id
      );

      let selectedSubCat = cloneDeep([
        ...(isEqual(categories, categoriesReorder) ||
          isEmpty(categoriesReorder)
          ? categories[selectedCategoryIndex].children
          : categoriesReorder[selectedCategoryIndex].children),
      ]);

      const index = selectedSubCat.indexOf(
        selectedSubCat[
        selectedSubCat.findIndex(
          (subcat) => subcat.id === deleteCat.data.subcat.id
        )
        ]
      );
      if (index > -1) {
        selectedSubCat.splice(index, 1);
      }
      updatedCategories[selectedCategoryIndex].children = selectedSubCat;
      // console.log(updatedCategories);
      setCategoriesReorder([...updatedCategories]);
    }
    setShowDeleteModal(false);
  };

  const handleEdit = (data, isParent = false) => {
    // console.log(data);
    const updatedCategories = cloneDeep([
      ...(isEqual(categories, categoriesReorder) ||
        isEmpty(categoriesReorder)
        ? categories
        : categoriesReorder),
    ]);

    if (isParent) {
      // console.log(updatedCategories, data);
      let renameCatIndex = updatedCategories.findIndex(
        (cat) => cat.id === data.category.id
      );
      if (renameCatIndex > -1) {
        updatedCategories[renameCatIndex] = {
          ...updatedCategories[renameCatIndex],
          name: data.category.name,
        };
      }
      setCategoriesReorder([...updatedCategories]);
    } else {
      let selectedCategoryIndex = updatedCategories.findIndex(
        (cat) => data.category.id === cat.id
      );

      let selectedSubCat = cloneDeep([
        ...(isEqual(categories, categoriesReorder) ||
          isEmpty(categoriesReorder)
          ? categories[selectedCategoryIndex].children
          : categoriesReorder[selectedCategoryIndex].children),
      ]);

      const index = selectedSubCat.indexOf(
        selectedSubCat[
        selectedSubCat.findIndex((subcat) => subcat.id === data.subcat.id)
        ]
      );
      selectedSubCat[index] = data.subcat;
      updatedCategories[selectedCategoryIndex].children = selectedSubCat;
      // console.log(updatedCategories);
      setCategoriesReorder([...updatedCategories]);
    }
  };

  return (
    <>
      <DndContext
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        sensors={sensors}
      >
        <div className="categories">
          <div className="cat-header">
            {modalData?.isEdit ? (
              <>
                <h2>Edit Categories</h2>
                <p className="cat-text">
                  Edit a category by clicking on the category text. Click and
                  drag the left side of each category to reorganize them.
                </p>
              </>
            ) : (
              <>
                <h2>Add Categories</h2>
                <p className="cat-text">
                  Add a new category by entering a category name. Click and drag
                  the left side of each category to reorder them. Categories and
                  subcategories will appear in the visitor directory in the app.
                </p>
              </>
            )}
          </div>
          <div className="d-flex justify-content-between">
            <div className="p-2">
              <h5>
                Category
                <img
                  alt='addIcon'
                  src={addPNG}
                  onClick={() => {
                    setIsComponentVisible(true);
                  }}
                  style={{ paddingLeft: "10px", cursor: "pointer" }}
                />
              </h5>
            </div>
            {/* <div className="ml-auto p-2">Sort by</div> */}
          </div>
          <div className="cat-scroll">
            <div className="current-categories">
              {isEqual(categories, categoriesReorder)
                ? categories.map((category, index) => (
                  <CategoriesData
                    modalData={modalData}
                    key={index}
                    categories={categories}
                    category={category}
                    index={index}
                    setSelectedCategory={setSelectedCategory}
                    handleKeyDown={handleKeyDown}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    register={register}
                    unregister={unregister}
                    errors={errors}
                    getValues={getValues}
                    resetField={resetField}
                    setIsComponentVisible={setIsComponentVisible}
                    ref={childRef}
                  />
                ))
                : categoriesReorder.map((category, index) => (
                  <CategoriesData
                    modalData={modalData}
                    key={index}
                    categories={categories}
                    category={category}
                    index={index}
                    setSelectedCategory={setSelectedCategory}
                    handleKeyDown={handleKeyDown}
                    handleDelete={handleDelete}
                    handleEdit={handleEdit}
                    register={register}
                    unregister={unregister}
                    errors={errors}
                    getValues={getValues}
                    resetField={resetField}
                    setIsComponentVisible={setIsComponentVisible}
                    ref={childRef}
                  />
                ))}
              <div>
                {isComponentVisible && (
                  <div>
                    <div
                      className={`flex-input border add-parent-category ${errors.newCategory?.message ? "border-danger" : "border-dark"
                        }`}
                    >
                      <input
                        autoFocus={true}
                        type="text"
                        id="category"
                        className="cat-input"
                        style={{ paddingLeft: '3rem' }}
                        {...register(`newCategory`, {
                          required: "Category is required",
                          maxLength: {
                            value: 100,
                            message: "Maximum allowed characters is 100",
                          },
                        })}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                            handleKeyDown("main");
                          }
                        }}
                      />

                      <X className="xIcon" onClick={() => {
                        unregister('newCategory');
                        setIsComponentVisible(false);
                      }} />
                    </div>
                    <span className="span-error">
                      {errors.newCategory?.message}
                    </span>
                    <div ref={inputScrollRef} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </DndContext>
      <DeleteCategoryModal
        openModal={showDeleteModal}
        onCloseModal={() => {
          setShowDeleteModal(false);
        }}
        showClose={true}
        modalData={deleteCat}
        handleDelete={delteCategoty}
      />
    </>
  );
};

export default forwardRef(CategoriesHome);
