import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { stepperMapDetails } from "../../../../../../features/user/studio/studioSlice";
import { isMapDetailsExist } from "../../../../../../features/user/studio/studioSlice";
import blankSVG from "../../../../../../assets/svgs/UploadBlankIcon.svg";
import { validateMIME } from "../../../../../common/utils";
import { setNewAlert } from "../../../../../../features/common/alertSlice";
import SampleFloorplans from "../../../../../common/sampleFloorplans";
import ARWayImage from "../../../../../common/CO/ARWayImage";

const ImageComponent = React.memo(({ setFilecb, isFloorplan }) => {
  const mapData = useSelector(stepperMapDetails);
  const dispatch = useDispatch();
  const isMapDetailsExists = useSelector(isMapDetailsExist);
  const [file, setFile] = useState(null);
  const [showFloorModal, toggleShowFloorModal] = useState(false);
  const [fileURL, setFileURL] = useState(null);
  const fileTypes = {
    images: ".png,.jpg,.jpeg",
  };

  useEffect(() => {
    if (isMapDetailsExists) {
      if (isFloorplan && mapData.floorplan) {
        setFile(mapData.floorplan);
        setFileURL(
          mapData.floorplan.isUrl
            ? mapData.floorplan.image
            : URL.createObjectURL(mapData.floorplan)
        );
      } else if (!isFloorplan && mapData.thumbnail) {
        setFile(mapData.thumbnail);
        setFileURL(URL.createObjectURL(mapData.thumbnail));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapDetailsExists]);

  useEffect(() => {
    if (file === false) {
      dispatch(
        setNewAlert({
          msg: "File/Mime type not supported!",
          alertType: "danger",
        })
      );
      setFile(null);
      setFileURL(null);
    } else {
      setFilecb(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  const handleFileChange = (e, fData) => {
    const acceptedFile = e ? e.target.files[0] : fData;
    if (acceptedFile) {
      validateMIME(acceptedFile, "images", (flag) => {
        if (flag) {
          setFile(acceptedFile);
          setFileURL(URL.createObjectURL(acceptedFile));
        } else {
          setFile(false);
          setFileURL(null);
        }
      });
    }
  };

  const onSampleFloorSuccess = (object) => {
    if (object) {
      if (object.type === "file") handleFileChange(null, object.data);
      else if (object.type === "url") {
        setFileURL(object.data.image);
        setFile({ ...object.data, isUrl: true });
      }
    }
  };

  let bgImge = isFloorplan && mapData ? mapData?.floorplan : mapData?.thumbnail;
  if (bgImge) {
    if (bgImge.isUrl) bgImge = bgImge.image;
    else bgImge = URL.createObjectURL(bgImge);
  }

  return (
    <div className="stepper__ImageUpload">
      <div className="content-thumbnail mt-4">
        <ARWayImage
          className="content-thumbnail-img"
          src={fileURL ? fileURL : bgImge ? bgImge : blankSVG }
          style={{
            height: !(file || bgImge) ? "25%" : "100%", 
            width: !(file || bgImge) ? "" : "100%"
          }}
        />
      </div>
      {
        <div className="create-imgInput">
          <input
            type="file"
            accept={fileTypes["images"]}
            onChange={(e) => handleFileChange(e)}
            id={`create-image-input-${isFloorplan ? "floorplan" : "thumbnail"}`}
          />
          <label
            className="create-imgInput__labelCont"
            htmlFor={`create-image-input-${
              isFloorplan ? "floorplan" : "thumbnail"
            }`}
          >
            <div className="content-dnd-msg">
              {file && (
                <div
                  className="filename"
                  style={{ wordBreak: "break-word" }}
                  id={`div-${isFloorplan ? "floorplan" : "thumbnail"}FileName`}
                >
                  <p>{file.name}</p>
                </div>
              )}
              <div className="d-flex justify-content-between">
                <span
                  className="create-imgInput__chooseText"
                  id={`p-upload${isFloorplan ? "Floorplan" : "Thumbnail"}${
                    file ? "Different" : ""
                  }`}
                >
                  {file
                    ? isFloorplan
                      ? "Choose different floor plan"
                      : "Choose different thumbnail"
                    : isFloorplan
                    ? "Upload Floor plan"
                    : "Select Thumbnail"}
                </span>
                <a
                  className="create-imgInput__chooseText"
                  href="https://www.arway.ai/_files/ugd/ee6c8e_b89be40b180e4b578297ea30cbee82d4.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {isFloorplan ? "Specs" : ""}
                </a>
              </div>
            </div>
          </label>
        </div>
      }
      {isFloorplan && (
        <div className="create-imgInput__sampleTextCont">
          <span className="create-imgInput__sampleTextCont--span">
            Don't have a floor plan yet? Choose one of our sample floor plans to
            practice creating AR experiences.
          </span>
          <div
            className="create-imgInput__sampleTextCont--selectBtn"
            onClick={() => toggleShowFloorModal(true)}
          >
            Select a Sample Floor Plan
          </div>
        </div>
      )}
      <SampleFloorplans
        openModal={showFloorModal}
        onSuccess={onSampleFloorSuccess}
        onCloseModal={() => toggleShowFloorModal(false)}
        modalData={mapData}
        defaultTab={1}
      />
    </div>
  );
});

export default ImageComponent;
