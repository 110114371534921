import * as THREE from "three";
import { isEmpty } from "lodash";
import EventEmitter from "../utils/EventEmitter";
import WorldMain from "./miniComponents/World.Main";
import Camera from "./miniUtils/Camera";
import Renderer from "./miniUtils/Renderer";
import Resizer from "./miniUtils/Resizer";
import Time from "./miniUtils/Time";
import ObjectsLoader from "./miniUtils/ObjectsLoader";
import { Strings } from "../utils/Strings";
import { InfoPopup } from "./miniUI/uiPopup";
import { detectBrowser } from "../../_utils/detectBrowser";

// singleton object
let instance = null;

export default class EditorExperience extends EventEmitter {
    constructor(isNew, mapDetails, sceneName, canvas, callbacks) {
        super();
        if (instance && !isNew) return instance;

        instance = this;

        this.mapData = mapDetails;

        this.floorVector = new THREE.Vector3(0, -0.5, 0);
        this.canvas = canvas;
        this.callbacks = callbacks;

        this.mapPinObject = {};
        this.anchorPinObject = {};
        this.navPinStart = {};
        this.navPinEnd = {};
        this.qrPin = {};
        this.qrMainPin = {};
        this.markerPin = {};
        this.imgAccessPin = {};
        this.imgAccessMainPin = {};
        this.floorplanPeg = {};

        this.sizes = new Resizer();
        this.time = new Time();

        this.scene = new THREE.Scene();
        this.scene.name = sceneName;
        this.camera = new Camera();
        this.renderer = new Renderer();
        this.resources = new ObjectsLoader();
        this.world = new WorldMain();
        this.strings = new Strings();

        this.popUI = new InfoPopup(this);
        this.canvas.parentElement.appendChild(this.popUI.dom.dom);
        this.supportedBrowsers = ["firefox", "chrome", "edge"];

        this.raycasterObjects = [];
        this.heatMaps = [];

        this.markers = [];
        this.toComputeHeat = false;

        this.sizes.on("resize", this.resize);
        this.time.on("tick", this.update);
        this.resources.on("ready", this.makeHTMLVisible);

        // global instance
        window.miniExperience = this;
    }

    addObjects = (object) => {
        object && this.scene.add(object);
    };

    initResourceLoading = (mapDetails, data, callback) => {
        console.log("REsource", data);
        this.mapData = mapDetails;
        this.callbacks.loadingComplete = callback.loadingComplete;
        const obj = !isEmpty(data)
            ? this.getMapContent({ ...data.mapContent }, mapDetails.metadata)
            : this.getMapContent({}, mapDetails.metadata);
        this.resources.initiateLoading(obj);
    };

    InitHeatmapLoading = (data, type) => {
        this.heatMapData = data;
        this.heatMapType = type;
        this.toComputeHeat = false;
        console.log(this.heatMapData);

        this.initHeatmapCalculations();
    };

    initHeatmapCalculations = () => {
        // console.log("COMPUTE CALLED");
        if (this.toComputeHeat) return;

        this.toComputeHeat = true;
        this.world.initHeatmapCalculations();
    };

    toAdd(object) {
        if (object && object.type.includes("Camera")) return false;
        if (object && object.type.includes("Light")) return false;
        if (object && object.type.includes("GridHelper")) return false;
        if (object && object.type.includes("BoxHelper")) return false;
        return true;
    }

    makeHTMLVisible = () => {
        console.log("READY HEATMAP");
        this.callbacks.loadingComplete();
        const { metadata } = this.mapData;
        // Calculate Pivots
        const sceneGroup = new THREE.Group();
        for (var i = 0; i < this.scene.children.length; i++) {
            const obj = this.scene.children[i];
            if (this.toAdd(obj)) {
                sceneGroup.add(obj.clone());
            }
        }
        const box3 = new THREE.Box3().setFromObject(sceneGroup);
        const center = new THREE.Vector3();
        box3.getCenter(center);

        this.simulationData = { box3, center };

        if (metadata.anchorType && metadata.anchorType === "qrAnchors") {
            this.trigger("adjustGridPosition", [box3.min]);
        }

        !this.toComputeHeat && this.initHeatmapCalculations();

        // Compute Browser!
        const browser = detectBrowser();
        if (this.supportedBrowsers.indexOf(browser) === -1) {
            this.trigger("togglePopup", [true]);
        } else {
            this.trigger("togglePopup", [false]);
        }
    };

    getMapContent = (mapContent, metadata) => {
        if (
            metadata.anchorType &&
            metadata.anchorType === "azure" &&
            metadata.mapVisual &&
            metadata.mapVisual.length > 0
        ) {
            let mapVisual = [
                {
                    id: "mapVisual_001",
                    name: `${metadata.mapName} vis`,
                    mapName: metadata.mapName,
                    anchors: metadata.anchors,
                    link: metadata.mapVisual,
                    position: { posX: 0, posY: 0, posZ: 0 },
                    rotation: { rotX: 0, rotY: 0, rotZ: 0, rotW: 0 },
                    scale: { scaX: 1, scaY: 1, scaZ: 1 },
                },
            ];
            mapContent = { ...mapContent, mapVisual };
        } else if (
            metadata.anchorType &&
            metadata.anchorType === "qrAnchors" &&
            metadata.anchors &&
            metadata.anchors.length > 0
        ) {
            let qrAnchors = [
                {
                    id: "qrAnchors",
                    anchors: metadata.anchors,
                },
            ];
            mapContent = { ...mapContent, qrAnchors };
        }

        if (metadata.floors) {
            // console.log(metadata.floors);
            let floors = [
                {
                    id: "metadata_floorplans",
                    floorplans: metadata.floors,
                },
            ];
            mapContent = { ...mapContent, floors };
        }
        return mapContent;
    };

    resize = () => {
        this.camera.resize();
        this.renderer.resize();
    };

    update = () => {
        this.camera.update();
        this.renderer.update();

        //Update Marker Scale
        this.markers.forEach((marker) => {
            var scaleVector = new THREE.Vector3();
            var scaleFactor = 20;
            var scale =
                scaleVector
                    .subVectors(marker.position, this.camera.instance.position)
                    .length() / scaleFactor;
            scale = scale <= 1 ? 1 : scale;
            marker.scale.set(scale, scale, scale);
        });

        // for heatmaps
        for (const heatMaps of this.heatMaps) {
            const screenPos = heatMaps.mesh.position.clone();
            screenPos.project(this.camera.instance);
            const transX = screenPos.x * this.sizes.width * 0.5;
            const transY = -(screenPos.y * this.sizes.height * 0.5);
            heatMaps.annotation.style.transform = `translate(${transX}px, ${transY}px)`;
        }
    };

    clearAndReset = () => {
        this.trigger("clearAndReset");
        while (this.scene.children.length) {
            this.scene.remove(this.scene.children[0]);
        }
    };
}
