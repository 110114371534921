import { Command } from './Command';

function AddObject( editor, object, delayAutoSave = true, unmountLoader = false, toSelect = true ) {
    Command.call(this, editor);

    this.type = 'AddObject';
    this.name = object ? `Add Object ${object.name}` : "";
    this.updatable = false;
    this.object = object;
    this.delayAutoSave = delayAutoSave;
    this.unmountLoader = unmountLoader;
    this.toSelect = toSelect;
}

AddObject.prototype = {
    execute: function(fromHistory = false) {
        this.editor.addObjects(this.object, this.delayAutoSave, fromHistory);
        this.toSelect && this.editor.select(this.object);
        this.unmountLoader &&  this.editor.trigger('mountObjectLoader', [false]) && this.editor.trigger('toggleLoaderProgress', ['none']);
    },

    undo: function() {
        this.editor.deselect();
        this.editor.removeObject(this.object);
    },

    toJSON: function () {
        
        var output = Command.prototype.toJSON.call(this);
        output.delayAutosave = this.delayAutosave;
        output.object = this.object.toJSON();

        return output;
    } 
}

export { AddObject };