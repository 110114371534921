import * as THREE from "three";
import EventEmitter from "./EventEmitter";

export default class Time extends EventEmitter {
    constructor() {
        super();
        this.start = Date.now();
        this.current = this.start;
        this.clock = new THREE.Clock();
        this.intervalDelta = 0;
        this.interval = 1 / 30; // for 30 fps

        this.elapsed = 0;
        this.delta = 16;

        this.frameId = window.requestAnimationFrame(() => {
            this.tick();
        });
    }

    tick = () => {
        this.frameId = window.requestAnimationFrame(this.tick);
        this.intervalDelta += this.clock.getDelta();

        if (this.intervalDelta > this.interval) {
            const currentTime = Date.now();
            this.delta = currentTime - this.current;
            this.current = currentTime;
            this.elapsed = this.current - this.start;

            this.intervalDelta = this.intervalDelta % this.interval;

            this.trigger("tick");
        }
    };

    cancelAnimationFrame = () => {
        window.cancelAnimationFrame(this.frameId);
    };

}
