import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';

import cogratsIcon from "../../../../assets/pngs/studio/importMaps/congrats.png";
import { resetMapDetailsTab } from "../../../../features/common/commonSlice";

function Congratulations() {
  const dispatch = useDispatch();

  const [ building, setBuilding ] = useState(null)
  const buildingData = useSelector(state => state.building.buildingDetails);

  useEffect(() => {
    if(buildingData) {
      dispatch(resetMapDetailsTab());
      setBuilding(buildingData)
    }
  }, [buildingData])

  const handleReloadVenue = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if(building) {
      localStorage.setItem('venueCreatedHandleBack', true);
      localStorage.setItem('venueCreated', true);
      window.location.replace(`${window.location.origin}${window.location.pathname}-${building.id}`);
    }
    
  }
  return (
    <div className="d-flex align-items-center flex-column">
      <h2>Congratulations on creating your venue map!</h2>
      <div className="d-flex align-items-stretch venueCont">
        <img src={cogratsIcon} alt="cogratsIcon"></img>
        <div className="ms-5 text-left">
        <p className="import-maps-subtext pt-4 ">
          Enable multi-floor navigation by linking imported floor maps together
          by adding connector pins (elevator, stairs, escalator etc.)
        </p>
        <p className="import-maps-subtext pt-4">
          In the Location Directory Editor, simply place connector pins along
          walkable or all access paths on your floor maps and establish
          connections between the pins. This will ensure seamless navigation
          across your venue, allowing visitors to move effortlessly between
          floor maps.
        </p>
        <div className="venuereload">
          <span className="">Reload to reflect recent venue changes.</span>
          <button onClick={handleReloadVenue}>Reload Venue</button>
        </div>
        </div>
      </div>
      {/* <h5 className="pt-5">Next: Add connector pins to link maps together</h5> */}
    </div>
  );
}

export default Congratulations;
