import React from "react";
import { Box } from "@mui/material";

const UsageGraph = ({ unit, unitString, limit, usage, type = "FLOAT" }) => {
  const percent =
    type === "INT" ? (usage / limit) * 100 : (usage / limit).toFixed(2) * 100;

  const hsl_col_perc = (percent, start, end) => {
    var a = percent / 100,
      b = (end - start) * a,
      c = b + start;
    // Return a CSS HSL string
    return "hsl(" + c + ", 100%, 50%)";
  };
  return (
    <Box className="settingshome__barGraphCont">
      <div className="settingshome__barGraphCont--contentRow">
        <span className="settingshome__barGraphCont--unitSpan">
          {unit ? unit : null}
        </span>
        <span className="settingshome__barGraphCont--unitSpan">
          {percent.toFixed(2)}% used
        </span>
      </div>
      <div className="settingshome__barGraphCont--barDivCont">
        <div
          className="settingshome__barGraphCont--barDiv"
          style={{
            backgroundColor: hsl_col_perc(percent, 60, 24),
            width: `${percent}%`,
          }}
        ></div>
      </div>
      <div className="settingshome__barGraphCont--contentRow">
        <div className="settingshome__barGraphCont--usageLimitSpan">
          {`${type === "INT" ? parseInt(usage) : usage.toFixed(2)}/${limit}  `}{" "}
          {unitString}
        </div>
        {/* <span className="settingshome__barGraphCont--greenAnchor">Need more?</span> */}
      </div>
    </Box>
  );
};

export default UsageGraph;
