import EventEmitter from "../../utils/EventEmitter";
import PointerIcon from "../../../../assets/svgs/scenes/menu/pointer_cursor.svg";
import PanIcon from "../../../../assets/svgs/scenes/menu/pan_cursor.svg";
import RotateIcon from "../../../../assets/svgs/scenes/menu/rotate_cursor.svg";
import FitScreenIcon from "../../../../assets/svgs/scenes/menu/fit_screen.svg";
import ZoomSelectionIcon from "../../../../assets/svgs/scenes/menu/zoom_selection.svg";
import NavigatorIcon from "../../../../assets/svgs/scenes/menu/navigator.svg";

// import UI Components
import { UIPanel, UISeparator, UITooltip } from "../ui.lib";

import * as THREE from "three";

class MenubarInteractions extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;

        this.zoomLevel = 100;

        this.initUI();

        this.editor.on("orbitInteractionModeChanged", this.onOrbitModeChanged);
        this.editor.on(
            "cameraInteractionModeChanged",
            this.onCameraModeChanged
        );
        this.editor.on("orbitControlsChanged", this.onOrbitControlsChanged);
        this.editor.resources.on("ready", this.onCheckNavigator);
        this.editor.on("floorDataReady", this.onCheckNavigator);
    }

    initUI = () => {
        var uiContainer = new UIPanel();
        uiContainer.addClass("wsToolsContainer");

        this.uiSelectContainer = document.createElement("div");
        this.uiSelectContainer.className = "wsToolbarButtons wsToolbarSelected";

        var selectCursorIcon = document.createElement("img");
        selectCursorIcon.classList.add("wsToolbarIcon");
        selectCursorIcon.title = "Select";
        selectCursorIcon.id = "img_SelectCursor";
        selectCursorIcon.src = PointerIcon;
        this.uiSelectContainer.appendChild(selectCursorIcon);
        this.uiSelectContainer.addEventListener("click", () => {
            this.editor.trigger("orbitInteractionModeChanged", ["select"]);
        });

        this.uiSelectContainer.appendChild(
            new UITooltip("Select", "ws_tooltipInteractions").dom
        );

        this.uiPanContainer = document.createElement("div");
        this.uiPanContainer.classList.add("wsToolbarButtons");

        var panCursorIcon = document.createElement("img");
        panCursorIcon.classList.add("wsToolbarIcon");
        panCursorIcon.title = "Pan";
        panCursorIcon.id = "img_PanCursor";
        panCursorIcon.src = PanIcon;
        this.uiPanContainer.appendChild(panCursorIcon);
        this.uiPanContainer.addEventListener("click", () => {
            this.editor.trigger("orbitInteractionModeChanged", ["pan"]);
        });
        this.uiPanContainer.appendChild(
            new UITooltip("Pan", "ws_tooltipInteractions").dom
        );

        this.uiRotateContainer = document.createElement("div");
        this.uiRotateContainer.classList.add("wsToolbarButtons");

        var rotateCursorIcon = document.createElement("img");
        rotateCursorIcon.classList.add("wsToolbarIcon");
        rotateCursorIcon.title = "Rotate";
        rotateCursorIcon.id = "img_RotateCursor";
        rotateCursorIcon.src = RotateIcon;
        this.uiRotateContainer.appendChild(rotateCursorIcon);
        this.uiRotateContainer.addEventListener("click", () => {
            this.editor.trigger("orbitInteractionModeChanged", ["rotate"]);
        });
        this.uiRotateContainer.appendChild(
            new UITooltip("Rotate", "ws_tooltipInteractions").dom
        );

        this.uiNavigatorContainer = document.createElement("div");
        this.uiNavigatorContainer.classList.add("wsToolbarButtons");

        var navigatorIcon = document.createElement("img");
        navigatorIcon.classList.add("wsToolbarIcon");
        navigatorIcon.title = "Navigator";
        navigatorIcon.id = "img_Navigator";
        navigatorIcon.src = NavigatorIcon;
        this.uiNavigatorContainer.appendChild(navigatorIcon);
        this.uiNavigatorContainer.addEventListener("click", () => {
            this.editor.trigger("cameraInteractionModeChanged", ["navigator"]);
        });
        this.uiNavigatorContainer.appendChild(
            new UITooltip("Navigator", "ws_tooltipInteractions").dom
        );

        this.uiZoomSelectionContainer = document.createElement("div");
        this.uiZoomSelectionContainer.classList.add("wsToolbarButtons");

        var zoomSelectIcon = document.createElement("img");
        zoomSelectIcon.classList.add("wsToolbarIcon");
        zoomSelectIcon.title = "Zoom Selection";
        zoomSelectIcon.id = "img_ZoomSelection";
        zoomSelectIcon.src = ZoomSelectionIcon;
        this.uiZoomSelectionContainer.appendChild(zoomSelectIcon);
        this.uiZoomSelectionContainer.addEventListener("click", () => {
            this.editor.trigger("cameraInteractionModeChanged", ["zoomSelect"]);
        });
        this.uiZoomSelectionContainer.appendChild(
            new UITooltip("Zoom", "ws_tooltipInteractions").dom
        );

        this.uiFitScreenContainer = document.createElement("div");
        this.uiFitScreenContainer.classList.add("wsToolbarButtons");

        var fitScreenIcon = document.createElement("img");
        fitScreenIcon.classList.add("wsToolbarIcon");
        fitScreenIcon.title = "Fit To Screen";
        fitScreenIcon.id = "img_FitToScreen";
        fitScreenIcon.src = FitScreenIcon;
        this.uiFitScreenContainer.appendChild(fitScreenIcon);
        this.uiFitScreenContainer.addEventListener("click", () => {
            this.editor.setIsometricCamera(1);
            this.editor.trigger("cameraInteractionModeChanged", [null]);
        });
        this.uiFitScreenContainer.appendChild(
            new UITooltip("Fit To Screen", "ws_tooltipInteractions").dom
        );

        // zoom options
        this.uiZoomOptionsContainer = document.createElement("div");
        this.uiZoomOptionsContainer.classList.add("wsToolbarZoomOption");

        this.uiZoomValueText = document.createElement("div");
        this.uiZoomValueText.classList.add("wsToolbarZoomOption--text");
        this.uiZoomValueText.textContent = `${this.zoomLevel}%`;

        const uiZoonOutIcon = document.createElement("div");
        uiZoonOutIcon.classList.add("wsToolbarZoomOption--actionBtn");
        uiZoonOutIcon.textContent = "-";
        uiZoonOutIcon.addEventListener("click", () => {
            if (this.zoomLevel < 200) {
                this.editor.setIsometricCamera(
                    (parseInt(this.zoomLevel / 10, 10) * 10 + 10) / 100
                );
            }
        });

        const uiZoonInIcon = document.createElement("div");
        uiZoonInIcon.classList.add("wsToolbarZoomOption--actionBtn");
        uiZoonInIcon.textContent = "+";
        uiZoonInIcon.addEventListener("click", () => {
            if (this.zoomLevel > 10) {
                const zoomTo =
                    (parseInt(this.zoomLevel / 10, 10) * 10 - 10) / 100;
                this.editor.setIsometricCamera(zoomTo < 0.1 ? 0.1 : zoomTo);
            }
        });

        this.uiZoomOptionsContainer.appendChild(uiZoonOutIcon);
        this.uiZoomOptionsContainer.appendChild(this.uiZoomValueText);
        this.uiZoomOptionsContainer.appendChild(uiZoonInIcon);

        // add to container
        uiContainer.dom.appendChild(this.uiSelectContainer);
        uiContainer.dom.appendChild(this.uiPanContainer);
        uiContainer.dom.appendChild(this.uiRotateContainer);
        //separator
        uiContainer.dom.appendChild(
            new UISeparator("ws_uiSeparatorCenter", "ws_tooltipInteractions")
                .dom
        );

        uiContainer.dom.appendChild(this.uiNavigatorContainer);
        uiContainer.dom.appendChild(this.uiZoomSelectionContainer);
        uiContainer.dom.appendChild(this.uiFitScreenContainer);
        uiContainer.dom.appendChild(this.uiZoomOptionsContainer);

        this.dom = uiContainer.dom;
    };

    onOrbitControlsChanged = () => {
        const { camera, controlBounds } = this.editor;
        const currentLevel =
            camera.controls.getDistance() / controlBounds.maxDistance;
        const percent = THREE.MathUtils.mapLinear(currentLevel, 0, 1, 0, 200);
        this.zoomLevel = parseInt(percent.toFixed(0));
        this.uiZoomValueText.textContent = `${200 - this.zoomLevel}%`;
    };

    onOrbitModeChanged = (transformMode) => {
        this.uiSelectContainer.classList.remove("wsToolbarSelected");
        this.uiPanContainer.classList.remove("wsToolbarSelected");
        this.uiRotateContainer.classList.remove("wsToolbarSelected");

        document.body.classList.remove("wsGrab_cursor");

        switch (transformMode) {
            case "select":
                this.uiSelectContainer.classList.add("wsToolbarSelected");
                break;
            case "pan":
                document.body.classList.add("wsGrab_cursor");
                this.uiPanContainer.classList.add("wsToolbarSelected");
                break;
            case "rotate":
                this.uiRotateContainer.classList.add("wsToolbarSelected");
                break;
            default:
                break;
        }
    };

    onCameraModeChanged = (cameraMode) => {
        cameraMode &&
            this.editor.trigger("orbitInteractionModeChanged", ["select"]);

        this.uiNavigatorContainer.classList.remove("wsToolbarSelected");
        this.uiZoomSelectionContainer.classList.remove("wsToolbarSelected");
        this.uiFitScreenContainer.classList.remove("wsToolbarSelected");

        switch (cameraMode) {
            case "navigator":
                this.uiNavigatorContainer.classList.add("wsToolbarSelected");
                break;
            case "zoomSelect":
                this.uiZoomSelectionContainer.classList.add(
                    "wsToolbarSelected"
                );
                break;
            case "fitScreen":
                this.uiFitScreenContainer.classList.add("wsToolbarSelected");
                break;
            default:
                break;
        }
    };

    onCheckNavigator = () => {
        if ("hasFloor" in this.editor.floorData) {
            this.uiNavigatorContainer.classList.remove("wsToolbarDisabled");
        } else {
            this.uiNavigatorContainer.classList.add("wsToolbarDisabled");
        }
    };
}

export { MenubarInteractions };
