import * as THREE from 'three';

//Comp
import EditorExperience from '../MinEditorExperience';
import { OrbitControls } from '../../utils/OrbitControls';

export default class Camera {

    constructor() {

        this.experience = new EditorExperience();
        this.sizes = this.experience.sizes;
        this.canvas = this.experience.canvas;
        this.scene = this.experience.scene;

        this.initInstance();
        this.initOrbitControls();
    }

    initInstance = () => {
        this.instance = new THREE.PerspectiveCamera(
            35,
            (this.sizes.width / this.sizes.height),
            0.1,
            2000
        );
        this.instance.position.set(-8, 4, -12);
        this.instance.name = "ARway-Camera"
        this.instance.lookAt(this.scene.position);
        this.scene.add(this.instance);
    }

    initOrbitControls = () => {
        this.controls = new OrbitControls(this.instance, this.canvas);
        this.controls.enableDamping = true;
        this.controls.rotateSpeed = 0.4;
        this.controls.zoomSpeed = 0.4;
        this.controls.panSpeed = 0.4;
        this.controls.dampingFactor = 0.1;
        this.controls.maxDistance = 1000;
    }

    resize = () => {
        this.instance.aspect = this.sizes.width / this.sizes.height;
        this.instance.updateProjectionMatrix();
    }

    update = () => {
        this.controls.update();
    }
}