const pins = {
  elevator: [
    "/static/sprites/elevator_connector/elevator_connector_0.png",
    "/static/sprites/elevator_connector/elevator_connector_1.png",
    "/static/sprites/elevator_connector/elevator_connector_2.png",
    "/static/sprites/elevator_connector/elevator_connector_3.png",
    "/static/sprites/elevator_connector/elevator_connector_4.png",
    "/static/sprites/elevator_connector/elevator_connector_5.png",
    "/static/sprites/elevator_connector/elevator_connector_6.png",
    "/static/sprites/elevator_connector/elevator_connector_7.png",
    "/static/sprites/elevator_connector/elevator_connector_8.png",
    "/static/sprites/elevator_connector/elevator_connector_9.png",
    "/static/sprites/elevator_connector/elevator_connector_10.png",
    "/static/sprites/elevator_connector/elevator_connector_11.png",
    "/static/sprites/elevator_connector/elevator_connector_12.png",
    "/static/sprites/elevator_connector/elevator_connector_13.png",
    "/static/sprites/elevator_connector/elevator_connector_14.png",
  ],
  escalator: [
    "/static/sprites/escalator_connector/escalator_connector_0.png",
    "/static/sprites/escalator_connector/escalator_connector_1.png",
    "/static/sprites/escalator_connector/escalator_connector_2.png",
    "/static/sprites/escalator_connector/escalator_connector_3.png",
    "/static/sprites/escalator_connector/escalator_connector_4.png",
    "/static/sprites/escalator_connector/escalator_connector_5.png",
    "/static/sprites/escalator_connector/escalator_connector_6.png",
    "/static/sprites/escalator_connector/escalator_connector_7.png",
    "/static/sprites/escalator_connector/escalator_connector_8.png",
    "/static/sprites/escalator_connector/escalator_connector_9.png",
    "/static/sprites/escalator_connector/escalator_connector_10.png",
    "/static/sprites/escalator_connector/escalator_connector_11.png",
    "/static/sprites/escalator_connector/escalator_connector_12.png",
    "/static/sprites/escalator_connector/escalator_connector_13.png",
    "/static/sprites/escalator_connector/escalator_connector_14.png",
  ],
  stairs: [
    "/static/sprites/stairs_connector/stairs_connector_0.png",
    "/static/sprites/stairs_connector/stairs_connector_1.png",
    "/static/sprites/stairs_connector/stairs_connector_2.png",
    "/static/sprites/stairs_connector/stairs_connector_3.png",
    "/static/sprites/stairs_connector/stairs_connector_4.png",
    "/static/sprites/stairs_connector/stairs_connector_5.png",
    "/static/sprites/stairs_connector/stairs_connector_6.png",
    "/static/sprites/stairs_connector/stairs_connector_7.png",
    "/static/sprites/stairs_connector/stairs_connector_8.png",
    "/static/sprites/stairs_connector/stairs_connector_9.png",
    "/static/sprites/stairs_connector/stairs_connector_10.png",
    "/static/sprites/stairs_connector/stairs_connector_11.png",
    "/static/sprites/stairs_connector/stairs_connector_12.png",
    "/static/sprites/stairs_connector/stairs_connector_13.png",
    "/static/sprites/stairs_connector/stairs_connector_14.png",
  ],
  bridge: [
    "/static/sprites/birdge_connector/bridge_connector_0.png",
    "/static/sprites/birdge_connector/bridge_connector_1.png",
    "/static/sprites/birdge_connector/bridge_connector_2.png",
    "/static/sprites/birdge_connector/bridge_connector_3.png",
    "/static/sprites/birdge_connector/bridge_connector_4.png",
    "/static/sprites/birdge_connector/bridge_connector_5.png",
    "/static/sprites/birdge_connector/bridge_connector_6.png",
    "/static/sprites/birdge_connector/bridge_connector_7.png",
    "/static/sprites/birdge_connector/bridge_connector_8.png",
    "/static/sprites/birdge_connector/bridge_connector_9.png",
    "/static/sprites/birdge_connector/bridge_connector_10.png",
    "/static/sprites/birdge_connector/bridge_connector_11.png",
    "/static/sprites/birdge_connector/bridge_connector_12.png",
    "/static/sprites/birdge_connector/bridge_connector_13.png",
    "/static/sprites/birdge_connector/bridge_connector_14.png",
  ],
};

export const pinColors = [
  "#36CCE7",
  "#FFE768",
  "#4A4A4A",
  "#6689FF",
  "#BC67FF",
  "#FF6CA2",
  "#F166FF",
  "#CFFF69",
  "#6BFF7B",
  "#FF8165",
  "#FFB16A",
  "#FF68D5",
  "#803D00",
  "#17181C",
  "#D9D9D9",
];

export const getConnectorColorIndex = (color) => {
  let pinIdx = pinColors.indexOf(color);
  return pinIdx >= 0 ? pinIdx : 0;
};

export const getTypeFromName = (name) => {
  let bFlag = false;
  let type = "elevator";
  if (!name) return type;

  let tName = name
    .replace(/[^a-zA-Z0-9 ]/g, "")
    .toLowerCase()
    .replace(/\s/g, "");
  for (const t in pins) {
    if (t.match(tName) && !bFlag) {
      bFlag = true;
      type = t;
    }
  }
  return type;
};

export const getConnectorTypeFromId = (aCategories, id, needsParent = true) => {
  let obj = null;

  // console.log(aCategories, id);

  aCategories?.forEach((cat) => {
    if (cat.id === id) {
      obj = cat;
    } else if (cat.children) {
      cat.children.forEach((child) => {
        if (child.id === id) {
          needsParent ? (obj = cat) : (obj = child);
        }
      });
    }
  });

  return obj;
};

export const getConnectorIdFromName = (aCategories, conType) => {
  let connectorCat = aCategories.filter((aT) => aT?.type === "connector");
  let type = null;
  let bFlag = false;
  if (!connectorCat.length) return type;

  for (const t of connectorCat) {
    let tName = t.name
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .toLowerCase()
      .replace(/\s/g, "");
    if (conType === tName && !bFlag) {
      bFlag = true;
      type = t;
    }
  }
  return type?.id;
};

export const getConnectorSpriteTexture = (type = "elevator", index = 0) => {
  // console.log(type, index);
  return type in pins ? pins[type][index] : pins.elevator[index];
};

export const getNameType = (key = "floors") => {
  const names = {
    floors: "Floor",
    levels: "Level",
    zones: "Zone",
    areas: "Area",
    sections: "Section",
    parking: "Parking",
  };

  let match = names[key];
  for (const name in names) {
    if (name.includes(key) || key === name) {
      match = names[name];
    }
  }

  return match;
};

export const getConnectorPrename = (floor, type) => {
  let prename = `${getNameType(floor?.mapNamingType)[0]}${
    floor.identifier || 1
  } - ${type}`;

  return prename;
};

export const generateFloorListWithName = (list) => {
  return list.map((li) => {
    // console.log(`${getNameType(li?.mapNamingType)[0]}${li.identifier || 1}`);
    return {
      ...li,
      identifierName: `${getNameType(li?.mapNamingType)[0]}${li.identifier} - ${
        li.mapName
      }`,
      typeName: `${getNameType(li?.mapNamingType)} - ${li.mapName}`,
      identifierShort: `${getNameType(li?.mapNamingType)[0]}${
        li.identifier || 1
      }`,
    };
  });
};

export const sortConnectorGroupDropDownOptions = (
  groups,
  type,
  ignorePairing = false
) => {
  let sorted = [];
  if (type === "elevator" || type === "bridge") {
    sorted = groups.filter((grp) => grp.connectorType === type);
  } else if (type === "escalator" || type === "stairs") {
    sorted = groups?.filter((grp) => {
      if (ignorePairing) return grp.connectorType === type;
      else return grp.connectorType === type && grp.pins?.length < 2;
    });
  }
  // console.log(sorted);
  return sorted;
};

export const setOnboardingFlag = (key, value) => {
  // store localstorage key
  let storageOnboardingFlags = localStorage.getItem("storageOnboardingFlags");
  if (!storageOnboardingFlags) {
    localStorage.setItem(
      "storageOnboardingFlags",
      JSON.stringify({ [key]: true })
    );
  } else {
    storageOnboardingFlags = JSON.parse(storageOnboardingFlags);
    storageOnboardingFlags[key] = true;
    localStorage.setItem(
      "storageOnboardingFlags",
      JSON.stringify(storageOnboardingFlags)
    );
  }
};

export const checkOnboardingFlag = (key) => {
  // store localstorage key
  let storageOnboardingFlags = localStorage.getItem("storageOnboardingFlags");
  if (!storageOnboardingFlags) {
    return true;
  } else {
    storageOnboardingFlags = JSON.parse(storageOnboardingFlags);
    let bFlag = storageOnboardingFlags[key];
    // console.log(bFlag, storageOnboardingFlags);
    return bFlag === undefined ? true : !bFlag;
  }
};
