import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
//MUI COmponents
import { Box } from "@mui/material";
import Loader from "../../../../common/loader";
//Actions
import {
  resetMapDetails,
  clearSearchedUserMaps,
  resetMapsState,
  getDefaultMapsDetails,
} from "../../../../../features/user/studio/studioSlice";
//Icons
import NoDataIllus from "../../../../../assets/svgs/nodata.svg";
import { Search, X } from "react-feather";
import MapCard from "../../../../dashboard/users/studio/MapCard";
import SelectedMapDetails from "./SelectedMapDetails";
import { Grid } from "@mui/material";
import { ChevronLeft } from "react-feather";

const ConnectedMaps = ({ state, handletoggleModal, handleReplaceFP }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [onFocusMapname, setOnFocusMapname] = useState(false);
  const maps = state.mapDetails?.maps;
  const [selectedMap, setSelectedMap] = useState(null);
  const [connectedMaps, setConnectedMaps] = useState([]);

  const dMaps = useSelector(state => state.studio.defaultMaps);

  let isPublished = useRef(undefined);

  useEffect(() => {
    // eslint-disable-next-line
    isPublished.current = state.isPublished;
    dispatch(resetMapDetails());
    setLoading(true);
    setConnectedMaps([]);
    dispatch(getDefaultMapsDetails(maps.map((m) => m.mapId)));
    return () => {
      dispatch(resetMapsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPublished.current !== state.isPublished) {
      isPublished.current = state.isPublished;
      setLoading(true);
      setConnectedMaps([]);
      dispatch(getDefaultMapsDetails(maps.map((m) => m.mapId)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  useEffect(() => {
    if (dMaps.length && connectedMaps.length !== dMaps.length) {
      const soretedMaps = dMaps?.map((m, i) => ({
        ...m,
        mapName: maps.find((mp) => mp.mapId === m.mapId)?.mapName,
        order: maps.find((mp) => mp.mapId === m.mapId)?.order || i + 1,
        isPublished: m?.isPublished !== isPublished.current ? isPublished.current : m.isPublished,
      })).sort((a, b) => a.order - b.order);
      setConnectedMaps(soretedMaps);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dMaps]);

  const [searchMaps, setSearchMaps] = useState(dMaps);

  useEffect(() => {
    if (searchText.length < 3 && searchText.length !== 0) {
      setOnFocusMapname(true);
    }
    if (searchText.trim().length >= 3) {
      setSearchMaps(
        dMaps?.filter((m) => m.mapName.toLowerCase().includes(searchText))
      );
      setOnFocusMapname(false);
    } else if (searchText.length === 0) {
      handleClearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleSearchChange = (e) => {
    if (e.target.value.includes(" ") && e.target.value.length === 1) {
      e.target.value = e.target.value.replace(/\s/g, "");
    }
    setSearchText(e.target.value);
  };

  const handleClearSearch = () => {
    setSearchText("");
    clearSearchedUserMaps();
  };

  const renderUserMaps = () => {
    if (loading) {
      return <Loader loaderText="Loading Maps..." height="100%" width="100%" />;
    } else if (searchText.length >= 3 && searchMaps && searchMaps.length > 0) {
      return searchMaps?.map((map, index) => (
        <div key={index} className="studiohome__mapCardContainer">
          <MapCard
            map={map}
            isConnected={true}
            onClickAction={() => {
              setSelectedMap(map);
            }}
          />
        </div>
      ));
    } else if (
      searchText.length >= 3 &&
      (!searchMaps || searchMaps.length <= 0)
    ) {
      return (
        <div className="illustration-container">
          <img src={NoDataIllus} alt="no maps!" draggable={false} />
          <br />
          <h5 id="h5-noMatch">No matches found.</h5>
        </div>
      );
    } else {
      return connectedMaps?.map((map, index) => (
        <div key={index} className="studiohome__mapCardContainer">
          <MapCard
            map={map}
            isConnected={true}
            onClickAction={() => {
              setSelectedMap(map);
            }}
          />
        </div>
      ));
    }
  };

  var crossClass = "searchbar__closeIcon--hide";
  if (searchText.length > 0) {
    crossClass = "";
  }

  const handleGoBack = () => {
    setConnectedMaps([]);
    dispatch(getDefaultMapsDetails(maps.map((m) => m.mapId)));
    setSelectedMap(null);
  };

  const handleUngroup = () => {
    setLoading(true);
    setConnectedMaps([]);
    dispatch(getDefaultMapsDetails(maps.map((m) => m.mapId)));
    setSelectedMap(null);
  };

  return (
    <div className="studiohome" style={{ height: "67vh" }}>
      {selectedMap ? (
        <Grid item xs={12} className="pt-4">
          <div
            className="mapinfo__backBtn"
            onClick={handleGoBack}
            id="button-mapBack"
          >
            <ChevronLeft
              size={24}
              color="#353E5A"
              className="mapinfo__mapIcon"
            />
            Back
          </div>
        </Grid>
      ) : null}

      <Box sx={{ width: "100%" }}>
        {selectedMap ? (
          <SelectedMapDetails
            state={state}
            handletoggleModal={handletoggleModal}
            selectedMap={selectedMap}
            venueId={state.mapData}
            venueName={state.name}
            setSelectedMap={() => handleUngroup()}
            handleReplaceFP={() => handleReplaceFP()}
          />
        ) : (
          <Box className="studiohome__mainRow">
            <div className="searchbar" style={{ width: "30%" }}>
              <span className="searchbar__searchbarContainer">
                <Search
                  size={20}
                  color="#353E5A"
                  className="searchbar__searchIcon"
                />
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search with Map Name"
                  value={searchText}
                  onChange={handleSearchChange}
                  id="input-mapsSearch"
                  onKeyPress={(e) => {
                    e.key === "Enter" && e.preventDefault();
                  }}
                />
                <X
                  size={20}
                  color="#353E5A"
                  onClick={handleClearSearch}
                  id="icon-clearSearch"
                  className={`searchbar__closeIcon ${crossClass}`}
                />
              </span>
            </div>
            {searchText.length < 3 && onFocusMapname ? (
              <span className="p-1" style={{ color: "red" }}>
                Minimum three character required!
              </span>
            ) : null}
            <div style={{ height: "60vh", overflow: "auto" }}>
              {renderUserMaps()}
            </div>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default ConnectedMaps;
