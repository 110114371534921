import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container } from "@mui/material";
import { debounce, isEqual } from "lodash";
//Icons
import { ChevronLeft, ChevronRight, Search, X } from "react-feather";
import UserTable from "./UserTable";
import {
  getUsers,
  searchUsers,
  clearSearchedUsers,
} from "../../../../features/admin/impersonations/impersonateSlice";
import Loader from "../../../common/loader";

const UserReportsHome = () => {
  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchedUsers, setSearchedUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);

  const userList = useSelector((state) => state.impersonate.users);
  const searchedUserList = useSelector(
    (state) => state.impersonate.searchedUsers
  );
  const totalPages = useSelector((state) => state.impersonate.totalPages) || 1;

  const pageSize = 9;

  useEffect(() => {
    setFetching(true);
    fetchUsers(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userList && !isEqual(userList, users)) {
      setFetching(false);
      setLoading(false);
      setUsers(userList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  useEffect(() => {
    if (
      searchedUserList &&
      (!isEqual(searchedUserList, searchedUsers) ||
        searchedUserList.length === 0)
    ) {
      setFetching(false);
      setLoading(false);
      setSearchedUsers(searchedUserList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedUserList]);

  useEffect(() => {
    fetchUsers(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (searchText.length >= 3) {
      searchUsersNow();
    } else if (searchText.length <= 0) {
      handleClearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleSearch = () => {
    dispatch(searchUsers(searchText));
  };

  const getSearchedUsers = debounce(handleSearch, 250);

  const fetchUsers = (pageNumber) => {
    var reqObj = {
      pageNumber,
      pageSize,
    };
    dispatch(getUsers(reqObj));
  };

  const handlePageChange = (pageType) => {
    if (pageType === 1) {
      if (pageNumber < totalPages) {
        setPageNumber(pageNumber + 1);
        setLoading(true);
      }
    } else {
      if (pageNumber > 1) {
        setPageNumber(pageNumber - 1);
        setLoading(true);
      }
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.value.includes(" ") && e.target.value.length === 1) {
      e.target.value = e.target.value.replace(/\s/g, "");
    }
    const sText = e.target.value;
    var filter = /^([\w,:\s@.\-_]*)$/;
    let filteredSearchText = [...sText]
      .map((c) => (filter.test(c) ? c : ""))
      .join("");
    setSearchText(filteredSearchText);
  };

  const searchUsersNow = () => {
    if (searchText.length > 0) {
      setLoading(true);
      setSearchedUsers([]);
      getSearchedUsers();
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
    dispatch(clearSearchedUsers());
  };

  const renderUsers = () => {
    if ((fetching && users.length === 0) || loading) {
      return (
        <Loader
          loaderText={loading ? "Loading More Users..." : "Fetching Users..."}
          height="500px"
          width="100%"
        />
      );
    }
    if (users.length === 0) {
      return (
        <div className="illustration-container" style={{ height: "500px" }}>
          <h4>No Users Available!</h4>
        </div>
      );
    } else if (searchedUsers && searchedUsers.length > 0) {
      return <UserTable users={searchedUsers} />;
    } else if (
      searchText.length >= 3 &&
      (!searchedUsers || searchedUsers.length <= 0)
    ) {
      return (
        <div className="illustration-container" style={{ height: "500px" }}>
          <h4>No matches found.</h4>
        </div>
      );
    } else {
      return <UserTable users={users} />;
    }
  };

  var crossClass = "searchbar__closeIcon--hide";
  if (searchText.length > 0) {
    crossClass = "";
  }

  return (
    <div style={{ padding: "40px" }}>
      <Container maxWidth={false}>
        <div className="pageWrapper">
          <h4>Impersonate Users</h4>
        </div>
        <div className="serachContRow">
          <div className="searchbar searchbar--padded" style={{ width: "50%" }}>
            <span className="searchbar__searchbarContainer">
              <Search
                size={20}
                color="#353E5A"
                className="searchbar__searchIcon"
              />
              <input
                type="text"
                autoComplete="off"
                placeholder="Search with User Email"
                value={searchText}
                onChange={handleSearchChange}
                id="input-impersonateSearch"
              />
              <X
                size={20}
                color="#353E5A"
                onClick={handleClearSearch}
                className={`searchbar__closeIcon ${crossClass}`}
              />
            </span>
          </div>
          {searchText.length === 0 ? (
            <div>
              {pageNumber > 1 && (
                <ChevronLeft
                  className="pageBar"
                  onClick={() => handlePageChange(2)}
                />
              )}
              <span className="pageCount">
                {pageNumber} / {totalPages}
              </span>
              <ChevronRight
                className="pageBar"
                style={{
                  visibility: totalPages !== pageNumber ? "visible" : "hidden",
                }}
                onClick={() => handlePageChange(1)}
              />
            </div>
          ) : null}
        </div>
        <Box className="boxWrapper">{renderUsers()}</Box>
      </Container>
    </div>
  );
};

export default UserReportsHome;
