export const GET_MAP_LIST = 'v2/map/'

export const GET_MAPS_OVERVIEW = 'v1/analytics/mapsoverview/'

export const GET_TOTAL_MAPVISITS_TO_DATE = 'v1/analytics/totalMapVisitsToDate/'

export const GET_MAP_VISITS = 'v1/analytics/mapvisits/'

export const GET_MAP_VISITS_OVERTIME = 'v1/analytics/mapVisitsOverTime/'

export const GET_REGIONS = 'v1/analytics/regions'

export const GET_LOCATION_SUMMARY = 'v1/analytics/maplocation-summary/'

export const GET_MOST_NAVIGATED_LOCATIONS_BY_MAP = 'v1/analytics/mostNavigatedLocationsByMap/'

export const GET_LOCATION_POPULAR_SEARCHES = 'v1/analytics/location/search/topkeywords/'

export const GET_HEATMAP = 'v1/analytics/heatmap/'

export const GET_ACCESS_POINT_ACTIVATIONS_SUMMARY = 'v1/analytics/accessPointsActivationsSummary'

export const GET_ACCESS_POINT_ACTIVATIONS = 'v1/analytics/accessPointsActivations'
