import React, { useEffect, useState } from 'react';
import DatePicker from '../../../../../_utils/DatePicker';
import Loader from '../../../../../common/loader';

import { Box } from '@mui/material';
import RenderDoughnutChart from '../../../../users/analytics/Render/RenderDoughnutChart';

export default function RegionCard({ regionSummary, handleChange, loading }) {

    const [colors, setColors] = useState([]);

    const interpolateColor = (color1, color2, factor = 0.5) => {
        var result = color1.slice();
        for (var i = 0; i < 3; i++) {
            result[i] = Math.round(result[i] + factor * (color2[i] - color1[i]));
        }

        return `rgb(${result.join(',')})`;
    }

    const getInterpolatedColors = (color1, color2, steps) => {
        var stepFactor = 1 / (steps - 1), interpolatedColorArray = [];

        color1 = color1.match(/\d+/g).map(Number);
        color2 = color2.match(/\d+/g).map(Number);

        for (var i = 0; i < steps; i++) {
            interpolatedColorArray.push(interpolateColor(color1, color2, stepFactor * i));
        }

        return interpolatedColorArray;
    }

    useEffect(() => {
        if (regionSummary && regionSummary.length) {
            setColors(getInterpolatedColors('rgb(22, 52, 153)', 'rgb(53, 157, 255)', regionSummary.length + 1))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [regionSummary])

    //getInterpolatedColors('rgb(22, 52, 153)', 'rgb(53, 157, 255)', data.length + 1) rgb(235, 245, 255)

    const renderLengends = () => {
        return regionSummary.map((reg, index) => (
            <div key={(index)} className='analyticshome__doughnutLegend'>
                <div className='analyticshome__doughnutLegend--bullet' style={{ backgroundColor: colors[index] }}></div>
                <span className='analyticshome__doughnutLegend--label'>{reg._id}:</span>
                <span className='analyticshome__doughnutLegend--value'> {reg.count}</span>
            </div>
        ))
    }

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col">
                        <div className="analyticshome__anCard--contentRow--header">Real-time User Count</div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker cb={(dateObj) => handleChange(dateObj)} />
                        </Box>
                    </div>
                </div>
                <hr />
                {(regionSummary && regionSummary.length && !loading) ? (
                    <div className='analyticshome__doughnutchartCont'>
                        <h5>Active Users</h5>
                        <br />
                        {RenderDoughnutChart(regionSummary?.map(d => d._id), regionSummary.map(d => d.count), 'Real-time User Count', colors)}
                        <div className='analyticshome__doughnutchartLegendCont'>
                            {renderLengends()}
                        </div>
                    </div>
                ) : loading ? (
                    <div className='analyticshome__emptyTextCont'>
                        <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                    </div>
                ) : (
                    <div className='analyticshome__emptyTextCont'>
                        <h5 className="text-center analyticshome__emptyStateText">{" "}No Data available{" "}</h5>
                    </div>
                )
                }
            </div>
        </div>
    )
}
