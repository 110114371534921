import { Grid, Box } from "@mui/material";
const commonStyles = {
  bgcolor: "background.paper",
  m: 1,
  border: 1,
  width: "100%",
  height: "5rem",
};

const RenderTile = ({ tileSize,color, miniCardHeader, miniCardDesc,svg}) => {
  return (
    <Grid item md={6} xl={tileSize}>
      <Box sx={{ ...commonStyles, borderRadius: "6px", borderColor:color }}>
        <Box sx={{ padding: "15px 0px 15px 24px" }}>
          <Grid container={true}>
            <Grid item xs={2}>
            <img className="" alt="uniq" src={svg} />
            </Grid>
            <Grid item xs={10} pl={'5px'}>
            <div className="analyticshome__miniCard--header" style={{color}}>{miniCardHeader}</div>
            <div className="analyticshome__miniCard--desc"><abbr title={miniCardDesc}>{miniCardDesc}</abbr></div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default RenderTile;
