import React from "react";
import { Outlet, Navigate, /* useNavigate */ } from "react-router-dom";
import { makeStyles } from '@mui/styles';
import { useSelector, /* useDispatch */ } from 'react-redux';
//COmponents
import Sidebar from "../../common/sidebar/SideBar";
import TopBar from "../../common/topbar/TopBar";

/* import items from '../../common/sidebar/menu';
import { retainNavState } from '../../../features/common/commonSlice'; */

//Routes
import { HOME } from '../../_utils/routes'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    backgroundColor: '#fbfbfb'
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  }
}));

const Dashboard = () => {
  const classes = useStyles();

  const loggedUserData = useSelector((state)=> state.auth.data);
  
  return (
    <React.Fragment>
      {loggedUserData ? 
        <div className={classes.root}>
          <TopBar />
          <Sidebar />
          <div className={classes.wrapper}>
            <div className={classes.contentContainer}>
              <div className={classes.content}>
                <Outlet />
              </div>
            </div>
          </div>
        </div> :
        <Navigate replace to={HOME} />}
    </React.Fragment>
  );
};

export default Dashboard;