import EventEmitter from "../../utils/EventEmitter";
import { 
    UIPanel, 
    UIText, 
    UIImage, 
    UIViewOptionRow, 
    UISeparator 
} from "../ui.lib";

//Icon
import Chevron from '../../../../assets/svgs/scenes/chevron.svg';
//Views
import { ViewOptions } from "../../utils/Strings";
//Commands
import { SetVisible } from "../../commands/SetVisible";

class TopbarView extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.strings = this.editor.strings;
        this.domBtn = null;
        this.dom = null;

        this.toggleView = false;

        this.viewOptions = new ViewOptions();

        this.editor.resources.on('ready', this.onReady);
        this.editor.on('objectAdded', this.onReady);
        this.editor.on('objectRemoved', this.onReady);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('wsViewContainer');
        
        this.uiText = new UIText(null, this.strings.getKey('bottombar/view'));
        this.uiText.addClass('wsViewOptnText');
        this.uiImg = new UIImage(Chevron, '24px', '24px', this.strings.getKey('bottombar/view'));

        this.uiCont.addChild(this.uiText);
        this.uiCont.addChild(this.uiImg);

        //Now Init View Dom
        this.initViewUI();

        this.uiContView = new UIPanel();
        this.uiContView.addClass('ws3dViewPanelContainer');

        this.uiContView.onClick((e) => e.stopPropagation());
        this.uiContView.dom.appendChild(this.domBtn);

        this.uiContView.setDisplay('none');

        this.uiCont.onClick((e) => {
            this.toggleSceneViewOptions();
        });

        this.uiCont.dom.appendChild(this.uiContView.dom);

        this.dom = this.uiCont;
    }

    initViewUI = () => {
        const uiPanel = new UIPanel().addClass('ws_uiViewCont');

        const bIndices = this.viewOptions.getBreakIndices();

        this.viewOptions.toArray().map((option, i) => {
            const uiViewRow = new UIViewOptionRow(this.editor, option, this.handleStateChanges);
            uiPanel.addChild(uiViewRow);
            bIndices.indexOf(i) !== -1 && uiPanel.addChild(new UISeparator('ws3dViewPanelContainer--verSeprator'))

            return uiPanel;
        });

        this.domBtn = uiPanel.dom;
    }

    checkParent(parent, child) {
        let node = child.parentNode;
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    handleClick = (e) => { !this.checkParent(this.uiCont.dom, e.target) && this.toggleSceneViewOptions() }

    toggleSceneViewOptions = () => {
        this.toggleView = !this.toggleView;
        if(this.toggleView) {
            this.uiCont.addClass("wsViewContainer--active");
            this.uiContView.setDisplay('block');
            window.addEventListener('click', this.handleClick, true);
        } else {
            this.uiCont.removeClass("wsViewContainer--active");
            this.uiContView.setDisplay('none');
            window.removeEventListener('click', this.handleClick, true);
        }
    }

    handleStateChanges = (id) => {
        // console.log("HANDLE CHANGES CB", id);
        this.viewOptions.setState(id);
        this.initSetViewCommand(id);
    }

    initSetViewCommand = (id) => {
        const option = this.viewOptions.getOption(id);
        this.editor.scene.children.forEach(object => {
            if(option.type instanceof Array && object.userData && object.userData.type && option.type.indexOf(object.userData.type) !== -1){
                this.editor.onCommand(new SetVisible(this.editor, object, option.state, true, true, true), 'VIEW_OPTION');
            } else if(object.userData && object.userData.type === option.type) {
                this.editor.onCommand(new SetVisible(this.editor, object, option.state, true, true, true), 'VIEW_OPTION');
            }
        });
    }

    onReady = () => {
        if(!this.editor.isReady) return;
        const types = this.viewOptions.getTypes();
        this.viewOptions.resetAll();
        this.editor.trigger('disableViewGraph');
        this.editor.scene.children.forEach(object => {
            if(object?.userData?.type !== undefined) {
                let idx = types.indexOf(object?.userData?.type);
                if(idx !== -1) {
                    const optn = this.viewOptions.getOption(idx > 9 ? --idx : idx)
                    if(optn.enable === false) {
                        this.viewOptions.setEnable(idx);
                        this.editor.trigger('viewGraphChanged', [idx]);
                    }
                }
            }
        });
    }
}

export { TopbarView };