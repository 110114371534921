import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from "react";
// import "./Categories.css";
import useComponentVisible from "./useComponentVisible";
import { CSS } from "@dnd-kit/utilities";

import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import SubCategoryItem from "./SubCategoryItem";
import addPNG from "../../../..//assets/pngs/studio/locationPin/add.png";
import { useSortable } from "@dnd-kit/sortable";
import CategoryItem from "./CategoryItem";
import { X } from "react-feather";


const CategoriesData = ({
  modalData,
  categories,
  category,
  index,
  setSelectedCategory,
  handleKeyDown,
  handleDelete,
  handleEdit,
  register,
  unregister,
  errors,
  getValues,
  resetField,
  setIsComponentVisible
}, ref) => {
  const {
    // visRef: ref1,
    isComponentVisible: isComponentVisible1,
    setIsComponentVisible: setIsComponentVisible1,
  } = useComponentVisible(false);

  const [categoryExpand, setCategoryExpand] = useState(false);
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: category.id });
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const inputRef = useRef(null);

  useEffect(() => {
    if (modalData.data === category.id && modalData.isAddSub) {
      setIsComponentVisible1(true);
      setCategoryExpand(true)
      setSelectedCategory(index);
    }
    if (modalData.isEdit && modalData.data.categoryId === category.id) {
      setCategoryExpand(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isComponentVisible1) {
      inputRef.current.scrollIntoView({
        behavior: "smooth", block: 'end',
        inline: 'nearest'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isComponentVisible1]);

  useImperativeHandle(ref, () => {
    return {
      disableInput: () => {
        setIsComponentVisible1(false);
      },
    }
  })

  return (
    <div>
      <SortableContext
        items={categories}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
          <CategoryItem
            modalData={modalData}
            category={category}
            categories={categories}
            index={index}
            handleEdit={handleEdit}
            handleDelete={handleDelete}
            categoryExpanObj={{ categoryExpand, setCategoryExpand }}
            register={register}
            errors={errors}
            getValues={getValues}
            setIsComponentVisible={setIsComponentVisible}
          />
        </div>
      </SortableContext>
      <div
        className="subcat"
        style={{ display: categoryExpand ? "block" : "none" }}
      >
        {category?.children?.length > 0 ? (
          <SortableContext
            items={category.children}
            strategy={verticalListSortingStrategy}
          >
            {category.children.map((subcat, i) => (
              <SubCategoryItem
                modalData={modalData}
                key={i}
                id={i + 1}
                subcat={subcat}
                isComponentVisible1={isComponentVisible1}
                category={category}
                handleEdit={handleEdit}
                handleDelete={handleDelete}
                register={register}
                errors={errors}
                getValues={getValues}
              />
            ))}
          </SortableContext>
        ) : null}
        {isComponentVisible1 && (
          <div className="d-flex">
            <div className="d-flex ms-3"></div>
            <div className="v-line-default"></div>
            <hr className="line"></hr>
            <div style={{ width: "91%" }}>
              <div
                className={`flex-input border add-sub-category ${errors[`newSubCategory${category.id}`]?.message ? "border-danger" : "border-dark"
                  }`}
              >
                <input
                  autoFocus={true}
                  type="text"
                  id="category"
                  className="cat-input"
                  style={{ paddingLeft: "2.4rem" }}
                  {...register(`newSubCategory${category.id}`, {
                    required: "SubCategory is required",
                    maxLength: { value: 100, message: 'Maximum allowed characters is 100' },
                  })}
                  onClick={() => {
                    setSelectedCategory(index);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      if (e.target.value !== "") {
                        handleKeyDown("subcat", {
                          name: e.target.value,
                          parentId: category.id,
                        });
                        // debugger
                        resetField(`newSubCategory${category.id}`);
                        setIsComponentVisible1(false);
                        // setCategoryExpand(false)
                      }
                    }
                  }}
                />
                <X className="xIcon" onClick={() => {
                  unregister(`newSubCategory${category.id}`);
                  setIsComponentVisible1(false);
                }} />
              </div>
              <span className="span-error">
                {errors[`newSubCategory${category.id}`]?.message}
              </span>
              <div ref={inputRef} />
            </div>
          </div>
        )}
        <div className="d-flex justify-content-end mt-3 mb-3">
          <div style={{ color: "#2A4DC2" }}>
            Add a Subcategory
            <img
              alt="addIcon"
              src={addPNG}
              onClick={() => {
                setIsComponentVisible1(true);
                setIsComponentVisible(false)
                setSelectedCategory(index)
              }}
              style={{ paddingLeft: "10px", cursor: "pointer" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(CategoriesData);
