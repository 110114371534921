import React, { useState } from 'react';
import Carousel from 'react-material-ui-carousel'
import {
    Backdrop, Box,
    Modal, Fade,
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

//Images
import Slide1 from '../../../assets/pngs/studio/guidedTour/slide1.png';
import Slide2 from '../../../assets/pngs/studio/guidedTour/slide2.png';
import Slide3 from '../../../assets/pngs/studio/guidedTour/slide3.png';
import Slide4 from '../../../assets/pngs/studio/guidedTour/slide4.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

export default function TourModal({ openModal, onCloseModal }) {

    const [index, setIndex] = useState(0);
    const [btnTxt, setBtnTxt] = useState("Next");

    const handleNextSlide = () => {
        const ind = index + 1;

        if (ind > 3) {
            handleClose();
            return;
        } else if (ind > 2){
            setBtnTxt("Done")
        }

        setIndex(ind);
    }

    const handleClose = () => {
        onCloseModal();
        setIndex(0);
        setBtnTxt("Next");
    }

    const handleOnChange = (index) => {
        if (index === 3) {
            setIndex(3);
            setBtnTxt("Done")
        } else {
            setIndex(index);
            setBtnTxt("Next");
        }
    }

    const renderTGCard = (title, imgSrc, header, subText, width = "100%", height = "100%") => {
        return (
            <div className='obModal--obCard'>
                <h4 className='obModal--obCard--title'>{title}</h4>
                <span className='obModal--obCard--header'>{header}</span>
                <div style={{width: width, height: height, display: "flex", justifyContent: "center", alignItems: "center", margin: "10px 0px"}}>
                    <img className='obModal--obCard--tourImg' src={imgSrc} alt={imgSrc} draggable="false" />
                </div>
                {subText && <span className='obModal--obCard--subText'>{subText}</span>}
            </div>
        )
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={handleClose} />
                        <div className='obModal'>
                            <Carousel autoPlay={false} height={560} navButtonsAlwaysInvisible={true} swipe={false} index={index} onChange={handleOnChange}>
                                {renderTGCard(
                                    'What is a Guided Tour?',
                                    Slide1,
                                    `The guided tour feature allows you to create immersive experiences for your visitors by guiding them through predefined paths in your space.`,
                                    `Whether you're showcasing a museum exhibit, guiding customers through a store, or highlighting points of interest in a venue, the guided tour feature helps you create engaging experiences.`,
                                    "720px",
                                    "350px",
                                )}

                                {renderTGCard(
                                    'How to Create a Guided Tour',
                                    Slide2,
                                    `Step 1: Place a starting point and create your route`,
                                    `Begin by clicking on the floor plan to place your tour start point. Click to anchor nodes and build your tour route.`,
                                    "580px"
                                )}

                                {renderTGCard(
                                    'How to Create a Guided Tour',
                                    Slide3,
                                    `Step 2. Finalize the tour route and edit your path`,
                                    `Anchor the end point by clicking 'End Tour Route' once your route is complete. Note: It’s best to edit node positions, adjust elevation, add or delete nodes, and modify start and end points after you end your tour route.`,
                                    "720px"
                                )}

                                {renderTGCard(
                                    'How to Create a Guided Tour',
                                    Slide4,
                                    `Step 3. Add tour details and save!`,
                                    `Complete your tour by adding engaging details such as a thumbnail, tour name, and description. Once satisfied with your tour and edits, hit 'Save & Complete' to finalize and save your tour.`,
                                    "560px"
                                )}
                            </Carousel>
                        </div>
                        <button className='obModal--btn' onClick={handleNextSlide}>{btnTxt}</button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
