import EventEmitter from "../../../utils/EventEmitter";
import { UIPanel, UIButton } from "../../../ui/ui.lib";

class Menu2DHelper extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;
        this.menuOpen = false;

        this.activePathType = 'new';

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('wsMenu2DContainer');
        
        this.helpBtn = new UIButton('Finish Path', 'tapbarBtn tapbarBtn--path', true).onClick(() => {
            this.editor.pathController.endNavigationTracking(this.activePathType);
        });

        this.uiCont.addChild(this.helpBtn);

        this.uiCont.setDisplay('none');
        this.dom = this.uiCont.dom;
    }

    onToggleMenuActive = (name, pathType) => {
        if((name === 'Paths' || name === 'Pens') && pathType) {
            this.menuOpen = true;
            this.activePathType = pathType;
            this.uiCont.setDisplay('');
            if(pathType === 'edit') {
                this.helpBtn.setTextContent('Finish Editing');
            } else this.helpBtn.setTextContent('Finish Path');
        } else if(!pathType && this.menuOpen) {
            this.menuOpen = false;
            this.uiCont.setDisplay('none');
        }
    }
}

export { Menu2DHelper };