import React from 'react';
import { Box, Grid } from '@mui/material';

import DatePicker from '../../../../../_utils/DatePicker';
import { secondsToHms } from '../../../../../_utils/DateUtils';
import RenderTile from '../../../../users/analytics/Render/RenderTile';
import Loader from '../../../../../common/loader';

// incons
import EyeSvg from '../../../../../../assets/svgs/analytics/a_eye.svg';
import MapsSvg from '../../../../../../assets/svgs/analytics/a_uniq_green.svg';
import AccountsSvg from '../../../../../../assets/svgs/analytics/a_sdk_cyan.svg';
import TimeSvg from '../../../../../../assets/svgs/analytics/a_acc_time.svg';
import DurationSvg from '../../../../../../assets/svgs/analytics/a_duration.svg';
import SDkAppsSvg from '../../../../../../assets/svgs/analytics/a_sdkapp_red.svg';

export default function VisitorBehaviorCard({ accountVisitorBehavior, handleChange, loading}) {

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col">
                        <div className="analyticshome__anCard--contentRow--header">Visitor Behavior</div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker
                                cb={(dateObj) => {
                                    handleChange(dateObj)
                                }}
                            />
                        </Box>
                    </div>
                </div>
                <hr />
                {(!accountVisitorBehavior && !loading)  ? (
                    <div className='analyticshome__emptyTextCont'>
                        <h5 className="text-center analyticshome__emptyStateText">{" "}No Data available{" "}</h5>
                    </div>
                ) : loading ? (
                    <div className='analyticshome__emptyTextCont'>
                        <Loader loaderText={"Fetching Data..."} height="120%" width="100%"/>
                    </div>
                ) : (
                    <div style={{ padding: "0px 32px 0px 32px" }}>
                        <Grid container={true} spacing={2}>
                            <RenderTile
                                tileSize={6}
                                color="#008043"
                                miniCardHeader={accountVisitorBehavior?.topMap ? accountVisitorBehavior?.topMap : "--"}
                                miniCardDesc="Top visited map to date"
                                svg={MapsSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#2A4DC2  "
                                miniCardHeader={accountVisitorBehavior?.totalDuration ? secondsToHms(accountVisitorBehavior?.totalDuration)  : "--"}
                                miniCardDesc="Total accumulated visit time"
                                svg={TimeSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#017EB4"
                                miniCardHeader={accountVisitorBehavior?.topApp ? accountVisitorBehavior?.topApp : "--"}
                                miniCardDesc="Top visited SDK app"
                                svg={AccountsSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#C10353"
                                miniCardHeader={accountVisitorBehavior?.totalSdkApps ? accountVisitorBehavior?.totalSdkApps + "  SDK Apps" : "--"}
                                miniCardDesc="Total number of SDK Apps created"
                                svg={SDkAppsSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#5600AC"
                                miniCardHeader={accountVisitorBehavior?.average ? secondsToHms(accountVisitorBehavior?.average) : "--"}
                                miniCardDesc="Average map visit duration"
                                svg={DurationSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#B80290"
                                miniCardHeader={accountVisitorBehavior?.totalVisits ? accountVisitorBehavior?.totalVisits : "--"}
                                miniCardDesc="Total map visits to date"
                                svg={EyeSvg}
                            />
                        </Grid>
                        <hr />
                    </div>
                )
                }
            </div>
        </div>
    )
}
