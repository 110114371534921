import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../../api";
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";
import { secondsToHms } from "../../../components/_utils/DateUtils";
import * as APIconstants from "../../APIconstants";
import { isEmpty, uniqWith, isEqual } from "lodash";

const getMapsList = createAsyncThunk(
  "analytics/getMapsList",
  async ({ pageNumber, isLoadMore, pageSize }, { dispatch }) => {
    let params = {
      page: pageNumber,
      limit: pageSize,
      mapsFilter: "Published",
    };
    try {
      const response = await Server.get(APIconstants.GET_MAP_LIST, { params });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getTopVisitedMapToDate = createAsyncThunk(
  "analytics/getTopVisitedMapToDate",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
    };
    try {
      const response = await Server.get(APIconstants.GET_MAPS_OVERVIEW, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getTotalMapVisitsToDate = createAsyncThunk(
  "analytics/getTotalMapVisitsToDate",
  async (reqObj, { dispatch }) => {
    try {
      const response = await Server.get(
        APIconstants.GET_TOTAL_MAPVISITS_TO_DATE
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getTopFiveVisited = createAsyncThunk(
  "analytics/getTopFiveVisited",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      limit: reqObj.limit,
    };
    try {
      const response = await Server.get(APIconstants.GET_MAP_VISITS, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getTotalMapVisitsOvertime = createAsyncThunk(
  "analytics/totalMapVisitsOvertime",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
    };
    try {
      const response = await Server.get(APIconstants.GET_MAP_VISITS_OVERTIME, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getTopVisitedMapsCard = createAsyncThunk(
  "analytics/getTopVisitedMapsCard",
  async (reqObj, { dispatch }) => {
    let params = {
      page: reqObj.page,
      limit: reqObj.limit,
    };
    try {
      const response = await Server.get(APIconstants.GET_MAP_VISITS, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getTotalMapVisitsOverTimeByLocation = createAsyncThunk(
  "analytics/getTotalMapVisitsOverTimeByLocation",
  async (reqObj, { dispatch }) => {
    let params = reqObj
      ? {
          fromDate: reqObj.fromDate,
          toDate: reqObj.toDate,
          ...(reqObj.country === "all" ? {} : { country: reqObj.country }),
          ...(reqObj.reg === "all" ? {} : { region: reqObj.reg }),
        }
      : {
          fromDate: "2022-06-01T00:00:00.0Z",
          toDate: "2022-07-20T00:00:00.0Z",
        };
    try {
      const response = await Server.get(APIconstants.GET_MAP_VISITS_OVERTIME, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getHighestPerformingMapsbyLocation = createAsyncThunk(
  "analytics/getHighestPerformingMapsbyLocation",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      ...(reqObj.country === "all" ? {} : { country: reqObj.country }),
      ...(reqObj.reg === "all" ? {} : { region: reqObj.reg }),
      page: reqObj.page,
      limit: reqObj.limit,
    };
    try {
      const response = await Server.get(APIconstants.GET_MAP_VISITS, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getTotalVisitorByMap = createAsyncThunk(
  "analytics/getTotalVisitorByMap",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      mapId: reqObj.mapId,
    };
    try {
      const response = await Server.get(APIconstants.GET_MAPS_OVERVIEW, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getTotalMapVisitsOvertimeByMap = createAsyncThunk(
  "analytics/getTotalMapVisitsOvertimeByMap",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      mapId: reqObj.mapId,
    };
    try {
      const response = await Server.get(APIconstants.GET_MAP_VISITS_OVERTIME, {
        params,
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getMapActivation = createAsyncThunk(
  "analytics/getMapActivation",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      mapId: reqObj.mapId,
    };
    try {
      let response = await Server.get(
        APIconstants.GET_ACCESS_POINT_ACTIVATIONS_SUMMARY,
        {
          params,
        }
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getAPWithMostActivation = createAsyncThunk(
  "analytics/getAPWithMostActivation",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
      limit: reqObj.limit,
      page: reqObj.page,
      mapId: reqObj.mapId,
    };
    try {
      let response = await Server.get(
        APIconstants.GET_ACCESS_POINT_ACTIVATIONS,
        {
          params,
        }
      );
      return response.data.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getRegions = createAsyncThunk(
  "analytics/getRegions",
  async (reqObj, { dispatch }) => {
    try {
      const response = await Server.get(APIconstants.GET_REGIONS);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getVisitorBehaviourCard = createAsyncThunk(
  "analytics/getVisitorBehaviourCard",
  async (reqObj, { dispatch }) => {
    /* let params = {
      page: reqObj.page,
      limit: reqObj.limit,
    }; */
    try {
      // const response = await Server.get("v1/analytics/topVisitedMapToDate", {
      //   params,
      // });
      // return response.data;
      return {
        status: "success",
        message: "Data fetched successfully",
        data: [
          {
            _id: "62b466a72e9ec730d0569d9a",
            // visits: 2,
            totalDuration: 0,
            average: null,
            // mapName: "fullhouse",
            // publishedOn: null,
          },
          {
            _id: "628f8511c12663328e37f4e4",
            // visits: 2,
            totalDuration: 0,
            average: null,
            // mapName: "fullhouse",
            // publishedOn: null,
          },
          {
            _id: "62628b0ea6d36463e5725a44",
            // visits: 2,
            totalDuration: 0,
            average: null,
            // mapName: "fullhouse",
            // publishedOn: null,
          },
        ],
        page: 1,
        limit: 10,
        totalPages: 1,
        totalResults: 10,
      };
      // eslint-disable-next-line
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getLocationSummary = createAsyncThunk(
  "analytics/getLocationSummary",
  async (reqObj, { dispatch }) => {
    let params = {
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
    };
    try {
      const response = await Server.get(
        `${APIconstants.GET_LOCATION_SUMMARY}${reqObj.mapId}`,
        {
          params,
        }
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getLocationList = createAsyncThunk(
  "analytics/getLocationList",
  async (reqObj, { dispatch }) => {
    let params = {
      page: reqObj.page,
      limit: reqObj.limit,
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
    };
    try {
      const response = await Server.get(
        `${APIconstants.GET_MOST_NAVIGATED_LOCATIONS_BY_MAP}${reqObj.mapId}`,
        {
          params,
        }
      );
      return response.data;
      // eslint-disable-next-line
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getLocationPopularSearches = createAsyncThunk(
  "analytics/getLocationPopularSearches",
  async (reqObj, { dispatch }) => {
    let params = {
      page: reqObj.page,
      limit: reqObj.limit,
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
    };
    try {
      const response = await Server.get(
        `${APIconstants.GET_LOCATION_POPULAR_SEARCHES}${reqObj.mapId}`,
        {
          params,
        }
      );
      return response.data;
      // eslint-disable-next-line
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getHeatmap = createAsyncThunk(
  "analytics/getHeatmap",
  async (reqObj, { dispatch }) => {
    let params = {
      type: reqObj.type,
      fromDate: reqObj.fromDate,
      toDate: reqObj.toDate,
    };
    try {
      const response = await Server.get(
        `${APIconstants.GET_HEATMAP}${reqObj.mapId}`,
        {
          params,
        }
      );
      return response.data;
      // eslint-disable-next-line
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const initialState = {
  selectedMap: { mapId: "allMaps", mapName: "All Maps" },
  mapsList: [],
  totalResults: null,
  topVisitedMapToDate: {},
  totalMapVisitsToDate: {},
  topFiveVisited: [],
  totalMapVisitsOvertime: [],
  topVisitedMapsCard: {},
  topVisitedMapsCSV: {},
  topVisitedMapsMetaCard: {},
  totalMapVisitsOverTimeByLocation: [],
  mapActivation: {},
  apWithMostActivation: {},
  highestPerformingMapsbyLocation: {},
  highestPerformingMapsbyLocationCSV: {},
  totalVisitorsIndividual: 0,
  visitorBehaviourCard: [],
  visitorBehaviourMetaCard: {},
  regions: [],
  topFiveLocationList: {},
  locationSummary: {},
  locationPopularSearches: {},
  locationList: {},
  heatmap: null,
};

const analyticsSlice = createSlice({
  name: "analytics",
  initialState,
  reducers: {
    selectMap: (state, action) => {
      return {
        ...state,
        selectedMap: action.payload,
      };
    },
    resetHeatmapData: (state, action) => {
      return {
        ...state,
        heatmap: null,
      };
    },
  },
  extraReducers: {
    "common/resetState": () => {
      return { ...initialState };
    },
    [getMapsList.fulfilled]: (state, { payload, meta }) => {
      return {
        ...state,
        mapsList: [
          !meta.arg.isLoadMore && initialState.selectedMap,
          ...payload?.data?.map((m) => ({
            ...m,
            mapName: m.mapName.charAt(0).toUpperCase() + m.mapName?.slice(1),
          })),
        ],
        totalResults: payload.totalResults,
      };
    },
    [getTopVisitedMapToDate.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        topVisitedMapToDate: payload.data,
      };
    },
    [getTotalMapVisitsToDate.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        totalMapVisitsToDate: payload.data,
      };
    },
    [getTopFiveVisited.fulfilled]: (state, { payload }) => {
      let topFiveVisited = payload?.data?.map((m, index) => ({
        rank: index + 1,
        mapName: m.mapName.charAt(0).toUpperCase() + m.mapName?.slice(1),
        mapVisits: m.visits,
        mapArea: m.mapArea > 0 ? Math.round(m.mapArea * 10.764) : "--",
        growth: m.growth === "-" ? "--" : m.growth,
        country: m.country
          ? m.country.split(" ").length > 1
            ? m.country
                .split(" ")
                .reduce((a, c) => {
                  return a + c.charAt(0).toUpperCase() + c?.slice(1) + " ";
                }, "")
                ?.slice(0, -1)
            : m.country.charAt(0).toUpperCase() + m.country?.slice(1)
          : "--",
      }));
      return {
        ...state,
        topFiveVisited,
      };
    },
    [getTotalMapVisitsOvertime.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        totalMapVisitsOvertime: payload.data,
      };
    },
    [getMapActivation.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapActivation: payload.data,
      };
    },
    [getAPWithMostActivation.fulfilled]: (state, { payload }) => {
      let data = payload.data.map((obj, i) => ({
        rank: i + 1,
        name: isEmpty(obj.name) ? `${obj.mapName} Primary` : obj.name,
        type: obj.type,
        growth: obj.growth === "-" ? "--" : obj.growth,
        activations: obj.activations,
      }));
      return {
        ...state,
        apWithMostActivation: { ...payload, data },
      };
    },
    [getTopVisitedMapsCard.fulfilled]: (state, { payload, meta }) => {
      let data = payload?.data?.map((m) => ({
        mapName: m.mapName.charAt(0).toUpperCase() + m.mapName?.slice(1),
        publishedOn: m.publishedOn
          ? `${new Date(m.publishedOn).toLocaleDateString()} ${new Date(
              m.publishedOn
            ).toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: true,
            })}`
          : "--",
        mapArea: m.mapArea > 0 ? Math.round(m.mapArea * 10.764) : "--",
        mapVisits: m.visits,
        avgVisitTime: m.average ? secondsToHms(m.average) : "--",
        totalVisitTime: m.totalDuration ? secondsToHms(m.totalDuration) : "--",
      }));
      if (meta.arg.isCSV) {
        let topVisitedMapsCSV = { ...payload, data };
        return { ...state, topVisitedMapsCSV };
      }
      if (payload.page === 1 && payload.limit === 1) {
        let topVisitedMapsMetaCard = {};
        topVisitedMapsMetaCard = payload.data[0];
        return { ...state, topVisitedMapsMetaCard };
      } else {
        let topVisitedMapsCard = { ...payload, data };
        return {
          ...state,
          topVisitedMapsCard,
        };
      }
    },
    [getTotalMapVisitsOverTimeByLocation.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        totalMapVisitsOverTimeByLocation: payload.data,
      };
    },
    [getHighestPerformingMapsbyLocation.fulfilled]: (
      state,
      { payload, meta }
    ) => {
      let data = payload?.data?.map((m, index) => ({
        rank: index + 1,
        mapTitle: m.mapName,
        mapArea: m.mapArea > 0 ? Math.round(m.mapArea * 10.764) : "--",
        mapvisits: m.visits,
        region: m.region
          ? (m.region.split(" ").length > 1
              ? m.region
                  .split(" ")
                  .reduce((a, c) => {
                    return a + c.charAt(0).toUpperCase() + c?.slice(1) + " ";
                  }, "")
                  ?.slice(0, -1)
              : m.region.charAt(0).toUpperCase() + m.region?.slice(1)) +
            ", " +
            (m.country.split(" ").length > 1
              ? m.country
                  .split(" ")
                  .reduce((a, c) => {
                    return a + c.charAt(0).toUpperCase() + c?.slice(1) + " ";
                  }, "")
                  ?.slice(0, -1)
              : m.country.charAt(0).toUpperCase() + m.country?.slice(1))
          : null,
      }));
      let highestPerformingMapsbyLocation = { ...payload, data };
      if (meta.arg.isCSV) {
        return {
          ...state,
          highestPerformingMapsbyLocationCSV: highestPerformingMapsbyLocation,
        };
      }
      return {
        ...state,
        highestPerformingMapsbyLocation,
      };
    },
    [getTotalVisitorByMap.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        totalVisitorByMap: payload.data,
      };
    },
    [getTotalMapVisitsOvertimeByMap.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        totalMapVisitsOvertime: payload.data,
      };
    },
    [getRegions.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        regions: [
          { value: "all,all", label: "--" },
          ...uniqWith(
            payload?.map((m) => {
              let value = m.region + "," + m.country;
              let label =
                (m?.region?.split(" ").length > 1
                  ? m.region
                      .split(" ")
                      .reduce((a, c) => {
                        return (
                          a + c.charAt(0).toUpperCase() + c?.slice(1) + " "
                        );
                      }, "")
                      ?.slice(0, -1)
                  : m?.region?.charAt(0)?.toUpperCase() + m?.region?.slice(1)) +
                ", " +
                (m?.country.split(" ").length > 1
                  ? m.country
                      .split(" ")
                      .reduce((a, c) => {
                        return (
                          a + c.charAt(0).toUpperCase() + c?.slice(1) + " "
                        );
                      }, "")
                      ?.slice(0, -1)
                  : m.country.charAt(0).toUpperCase() + m.country?.slice(1));
              return { value, label };
            }),
            isEqual
          ),
        ],
      };
    },
    [getVisitorBehaviourCard.fulfilled]: (state, { payload }) => {
      let data = payload?.data?.map((m) => ({
        avgVisitTime: m.average ? m.average : 0.0,
        totalVisitTime: m.totalDuration ? m.totalDuration : 0.0,
      }));
      if (payload.page === 1 && payload.limit === 1) {
        let visitorBehaviourMetaCard = {};
        visitorBehaviourMetaCard = payload.data[0];
        return { ...state, visitorBehaviourMetaCard };
      } else {
        let visitorBehaviourCard = { ...payload, data };
        return {
          ...state,
          visitorBehaviourCard,
        };
      }
    },
    [getLocationSummary.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        locationSummary: payload.data,
      };
    },
    [getLocationList.fulfilled]: (state, { payload }) => {
      let data = payload?.data?.map((m, i) => ({
        rank: i + 1,
        location: m.name,
        timespent: m.duration !== 0 ? secondsToHms(m.duration) : "--",
        growth: m.growth === "-" ? "--" : m.growth,
        noOfNavigation: m.count,
      }));
      let locationListData = { ...payload, data };
      return payload.limit === 5
        ? {
            ...state,
            topFiveLocationList: locationListData,
          }
        : {
            ...state,
            locationList: locationListData,
          };
    },
    [getLocationPopularSearches.fulfilled]: (state, { payload }) => {
      let data = payload?.data?.map((m, i) => ({
        rank: i + 1,
        searchQuery: m._id,
        growth: m.growth === "-" ? "--" : m.growth,
        noOfSearches: m.count,
      }));
      let locationPopularSearches = { ...payload, data };
      return {
        ...state,
        locationPopularSearches,
      };
    },
    [getHeatmap.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        heatmap: payload.data,
      };
    },
  },
});

export const { selectMap, resetHeatmapData } = analyticsSlice.actions;

export const topVisitedMapsCSV = (state) => state?.analytics?.topVisitedMapsCSV;

export const highestPerformingMapsbyLocationCSV = (state) =>
  state?.analytics?.highestPerformingMapsbyLocationCSV;

export {
  getMapsList,
  getTopVisitedMapToDate,
  getTotalMapVisitsToDate,
  getTopFiveVisited,
  getTotalMapVisitsOvertime,
  getMapActivation,
  getAPWithMostActivation,
  getTopVisitedMapsCard,
  getHighestPerformingMapsbyLocation,
  getTotalMapVisitsOverTimeByLocation,
  getTotalVisitorByMap,
  getTotalMapVisitsOvertimeByMap,
  getRegions,
  getVisitorBehaviourCard,
  getLocationSummary,
  getLocationList,
  getLocationPopularSearches,
  getHeatmap,
};

export default analyticsSlice.reducer;
