import * as THREE from 'three';
import EditorExperience from '../EditorExperience';
import EventEmitter from '../utils/EventEmitter';

import { 
    fromPosObjectToVec3Pos, 
    // fromScaleObjectToVec3Scale 
} from './TransformConversions';
import { dispose } from '../utils/Dispose.Utils';

export default class Audio extends EventEmitter {
    constructor(audioData) {
        super();
        this.editor = new EditorExperience();
        this.audioData = audioData;
        this.audioLink = audioData.link;
        this.audioSprite = null;

        this.id = audioData.id;
        this.name = audioData.name.substring(0, 20);
        
        this.visible = 'visible' in audioData ? audioData.visible : true;
        this.autoplay = 'autoplay' in audioData ? audioData.autoplay : true;

        this.position = audioData.position ? fromPosObjectToVec3Pos(audioData.position) : new THREE.Vector3(0, 0, 0);        
        // this.scale = audioData.scale ? fromScaleObjectToVec3Scale(audioData.scale) : new THREE.Vector3(1, 1, 1);        
        this.setupSprite();
        // this.iniializeAudio();

        this.editor.on('objectRemoved', this.onObjectRemoved);

    }  


    setupSprite = () => {
        const spriteTex = new THREE.TextureLoader().load('/static/textures/mic-audio.png');
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        const spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            transparent: true,
            depthTest: false,
            depthWrite: false
        });
        
        const audio = new THREE.Sprite(spriteMaterial);
        audio.scale.set(0.25, 0.25, 0.25);
        audio.name = this.audioData.name;

        this.audioSprite = new THREE.Group();
        this.audioSprite.add(audio)
        this.audioSprite.name = this.name;
        this.audioSprite.userData['type'] = "audio";
        this.audioSprite.userData['skipChild'] = "audio";
        this.audioSprite.userData['id'] =  this.id;
        this.audioSprite.userData['audioLink'] = this.audioLink;
        this.audioSprite.userData['autoplay'] = this.autoplay;
        this.audioSprite.userData['visible'] = this.visible;
        this.audioSprite.userData['transform'] = "ONLY_TRANSLATE";
        this.audioSprite.userData['udScale'] = 'audio';
        this.audioSprite.userData['udRotate'] = 'audio';
        this.audioSprite.position.copy(this.position);
        // this.audioSprite.scale.copy(this.scale);
        this.audioSprite.visible = this.visible;
    }

    iniializeAudio = () => {

    }

    onObjectRemoved = (object) => {
        if(object === this.audioSprite) {
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            dispose(this.audioSprite);
        }
    }
}