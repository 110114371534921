import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, Box } from "@mui/material";

import RenderTile from '../../../../../users/analytics/Render/RenderTile';

import { lastTEDays } from "../../../../../../_utils/DateUtils";
import { secondsToHms } from "../../../../../../_utils/DateUtils";
import DatePicker from '../../../../../../_utils/DatePicker';
import Loader from '../../../../../../common/loader';

import UniqSvg from "../../../../../../../assets/svgs/analytics/a_uniq.svg";
import EyeSvg from "../../../../../../../assets/svgs/analytics/a_eye.svg";
import AvgDuration from "../../../../../../../assets/svgs/analytics/a_duration.svg";
import AccTime from "../../../../../../../assets/svgs/analytics/a_acc_time.svg";

import { getUserAccountOverview } from "../../../../../../../features/admin/analytics/adminAnalyticsSlice";

const AccountOverviewCard = () => {
    const dispatch = useDispatch();

    const selectedAccount = useSelector((state) => state.adminanalytics.activeAccount);

    const [dateObj, setDateObj] = useState(lastTEDays);
    const [loading, setLoading] = useState(true);

    const overview = useSelector((state) => state.adminanalytics.userAccountOverview);

    useEffect(() => {
        dispatch(getUserAccountOverview({ ...dateObj, accountId: selectedAccount?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(selectedAccount && selectedAccount.id !== 'allAccounts') {
            setLoading(true);
            setDateObj(lastTEDays)
            dispatch(getUserAccountOverview({ ...lastTEDays, accountId: selectedAccount?.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);

    useEffect(() => {
        if (overview && loading) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overview]);

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col">
                        <div className="analyticshome__anCard--contentRow--header">
                            Overview
                        </div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker
                                cb={(dateObj) => {
                                    setDateObj(dateObj);
                                    setLoading(true);
                                    dispatch(getUserAccountOverview({ ...dateObj, accountId: selectedAccount?.id }));
                                }}
                            />
                        </Box>
                    </div>
                </div>
                <hr />
                {!overview && !loading ? (
                    <h5 className="text-center analyticshome__emptyStateText">
                        {" "}
                        No Data available{" "}
                    </h5>
                ) : loading ? (
                    <div className='analyticshome__emptyTextCont'>
                        <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                    </div>
                ) : (<div style={{ padding: "0px 32px 0px 32px" }}>
                    <Grid container={true} spacing={4}>
                        <RenderTile
                            tileSize={6}
                            color="#008080"
                            miniCardHeader={
                                overview?.topVisitedMap
                                    ? overview.topVisitedMap.charAt(0).toUpperCase() +
                                    overview.topVisitedMap?.slice(1)
                                    : "--"
                            }
                            miniCardDesc="Top visited map to date"
                            svg={UniqSvg}
                        />
                        <RenderTile
                            tileSize={6}
                            color="#2A4DC2"
                            miniCardHeader={
                                overview?.totalDuration
                                    ? secondsToHms(overview.totalDuration)
                                    : "--"
                            }
                            miniCardDesc="Total accumulated visit time"
                            svg={AccTime}
                        />
                        <RenderTile
                            tileSize={6}
                            color="#5600AC"
                            miniCardHeader={
                                overview?.average ? secondsToHms(overview.average) : "--"
                            }
                            miniCardDesc="Average map visit duration"
                            svg={AvgDuration}
                        />
                        <RenderTile
                            tileSize={6}
                            color="#B80290"
                            miniCardHeader={
                                overview?.totalVisits ? overview.totalVisits : "--"
                            }
                            miniCardDesc="Total map visits to date"
                            svg={EyeSvg}
                        />
                    </Grid>
                    <hr />
                </div>)}
            </div>
        </div>
    );
};

export default AccountOverviewCard;
