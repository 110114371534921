import * as THREE from 'three';
import EditorExperience from "../../EditorExperience";

/* LINE UTILS */
import { PathTubeGeometry } from '../../utils/pathLib/PathTubeGeometry';
import { PathPointList } from '../../utils/pathLib/PathPointList';

class GuidedTourLine {
    constructor(addLine, color) {
        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;
        this.addLine = addLine;

        this.pointList = new PathPointList();
        this.points = [];
        this.activePoint = 0;
        this.radius = 0;

        // Geometries, Meshes and Materials
        this.lineGeometry = null;
        this.interactionLineGeometry = null;
        this.line = null;
        this.interactionLine = null;
        this.color = color;
        this.material = new THREE.MeshBasicMaterial({
            color: this.color,
            side: THREE.DoubleSide,
        });

        this.editor.resources.on("ready", this.calculateRadius);
    }

    calculateRadius = () => {
        this.scene.children.forEach((child) => {
            if (child.userData && child.userData.navHelper && child.userData.navHelper === "floorplan") {
                const size = new THREE.Vector3();
                const box = new THREE.Box3();
                box.setFromObject(child);
                box.getSize(size);

                const minSize = Math.min(size.x, size.z);
                const width = minSize * 0.0022;
                this.radius = Number(width.toFixed(2)) * 0.8;
                this.radius = this.radius < 0.1 ? 0.1 : this.radius;
            }
        });

        this.updateLineGeometry();
    }

    addPoints = (points) => {
        // this.points.push(...points);
        if (this.radius === 0) {
            this.calculateRadius();
        }

        if (points && points.length) {
            points.forEach((point) => this.points.push(point));
        }

        if (this.points.length === 2) {
            this.pointList.set(this.points, .15, 10, undefined, false);

            this.createLineGeometry();
            this.createLineMesh();
        } else if (this.points.length > 2) {
            this.pointList.set(this.points, .15, 10, undefined, false);
            this.updateLineGeometry();
        }
    }

    removeLastPoint = () => {
        this.points.pop();

        this.pointList.set(this.points, .15, 10, undefined, false);
        this.updateLineGeometry();
    }

    clearAllPoints = () => {
        this.points = [];
        this.pointList.set(this.points, .15, 10, undefined, false);
        this.updateLineGeometry();
    }

    /**
     * 
     * @param {*} point :- replace this passed point with active point
     */
    updatePoint = (point) => {
        this.points[this.points.length - 1] = point;

        this.pointList.set(this.points, .15, 10, undefined, false);
        this.updateLineGeometry();
    }

    updateEditPoint = (index, point) => {
        this.points[index] = point;
        this.pointList.set(this.points, .15, 10, undefined, false);
        this.updateLineGeometry();
    }

    addPointAtIndex = (point, index) => {
        this.points.splice(index, 0, point);
        this.pointList.set(this.points, .15, 10, undefined, false);
        this.updateLineGeometry();
    }

    removeNodeAtIndex = (index) => {
        this.points.splice(index, 1);
        this.pointList.set(this.points, .15, 10, undefined, false);
        this.updateLineGeometry();
    }

    createLineGeometry = () => {
        this.lineGeometry = new PathTubeGeometry({
            pathPointList: this.pointList,
            options: { radius: this.radius },
            usage: THREE.DynamicDrawUsage
        });
    }

    updateLineGeometry = () => {
        // Update geometry when pathPointList changed
        if (this.lineGeometry) {
            this.lineGeometry.update(this.pointList, {
                radius: this.radius,
                usage: THREE.DynamicDrawUsage,
            });
        }
    }

    createLineMesh = () => {
        this.line = new THREE.Mesh(this.lineGeometry, this.material);
        this.line.frustumCulled = false;
        this.line.position.y += 0.01;
        this.line.renderOrder = 1002;
        this.line.userData['isPathGeometry'] = true;
        this.addLine(this.line);
    }

    refreshLineMesh = (color) => {
        this.color = color;
        this.material = new THREE.MeshBasicMaterial({
            color: this.color,
            side: THREE.DoubleSide,
        });

        this.line.material = this.material;
    }

    endTour = () => {
        this.points.pop();
        this.pointList.set(this.points, .15, 10, undefined, false);
        this.updateLineGeometry();
    }
}

export default GuidedTourLine;