import React from 'react';
import { Doughnut } from "react-chartjs-2";

const graphData = (labels, data, labelDesc, colors) => {
    return {
        maintainAspectRatio: true,
        responsive: true,
        labels,
        datasets: [{
            label: labelDesc,
            data: data,
            backgroundColor: colors,
            borderWidth: 1,
        }]
    }
}

const pieOptions = {
    cutout: '70%',
    plugins: {
        legend: {
            display: false,
        },
        elements: {
            arc: {
                borderWidth: 0
            }
        }
    }
};

const RenderDoughnutChart = (labels, data, labelDesc, colors) => {

    return (
    <div style={{ padding: "0px 32px 0px 32px" }}>
        <Doughnut
            data={graphData(labels, data, labelDesc, colors)}
            options={pieOptions}
        />
    </div>
    );
};

export default RenderDoughnutChart;