import * as THREE from 'three';
import { AddObject } from '../commands/AddObject';

import EditorExperience from '../EditorExperience';
import EventEmitter from '../utils/EventEmitter';
import Navigation from './Navigation/Navigation';
import { 
    fromQuaternionToQuaternionObject, 
    fromVec3PosToPosObject, 
    fromVec3ScaleToScaleObject, 
    getRandomIdCode 
} from './TransformConversions';

class LocationPinHelper extends EventEmitter {
    constructor() {
        super();
        
        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;
        this.time = this.editor.time;
        this.renderer = this.editor.renderer;
        this.orbitControls = this.editor.camera.controls;

        this.startNavigationTrack = false;

        this.intersectionPoint = new THREE.Vector3();
        this.raycaster = new THREE.Raycaster();

        this.raycastObjects = [];

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
    }
    

    setUpControls = () => {

        this.scene.children.forEach((child) => {
            if(child.userData && child.userData.navHelper &&  child.userData.navHelper === 'floorplan') {
                this.raycastObjects.push(child)
            }
        })

        if(this.raycastObjects.length > 0) {
            this.startNavigationTrack = true;
            this.editor.trigger('navigationTracking', [true]);
            this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);

            this.layoutNavPaths(0, new THREE.Vector3());
        } else {
            this.editor.callbacks.generateAlert({
                msg: "Add floor plan in map to create navigation.",
                alertType: "information"
            })
        }
    }

    layoutNavPaths = (index = -1, vec3Pos) => {

        this.currentHeader = this.cloneLocationHeader(index);
        this.currentHeader.position.copy(vec3Pos)
        this.editor.addObjects(this.currentHeader);

        this.canvas.addEventListener('mousemove', this.onMouseMove, false);
        this.canvas.addEventListener('mousedown', this.onMouseDown, false);
        document.addEventListener('keydown', this.onKeyDownExit, false);
    }

    onMouseMove = (e) => {
        if(this.startNavigationTrack) {
            let viewportDown = new THREE.Vector2();
            const rect = this.canvas.getBoundingClientRect();
            viewportDown.x =   ((( e.offsetX - rect.left) / rect.width ) * 2 ) - 1;
            viewportDown.y = - ((( e.offsetY - rect.top) / rect.height ) * 2 ) + 1;

            this.raycaster.setFromCamera(viewportDown, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(this.raycastObjects, true);

            if(intersects.length > 0) {
                const intersect = intersects[0];
                this.intersectionPoint.copy(intersect.point);
                this.currentHeader.position.copy(this.intersectionPoint)
            }
        }
    }

    onMouseDown = (e) => {
        switch(e.which) {

            case 2:
                this.exitNavigationTracking();
            break;

            case 3:
                this.currentHeader.position.copy(this.intersectionPoint);
                let wayObj = {
                    name: this.currentHeader.name,
                    position: fromVec3PosToPosObject(this.intersectionPoint.clone()),
                    scale: fromVec3ScaleToScaleObject(new THREE.Vector3(1,1,1)),
                    rotation: fromQuaternionToQuaternionObject(new THREE.Quaternion()),
                    id: this.currentHeader.userData['id'],
                    description: "sample description",
                    pathColor: '#FD7A02',
                    visible: true
                };
                this.startNavigationTrack = false;
                this.editor.trigger('navigationTracking', [false]);
                this.editor.removeObject(this.currentHeader, true);
                const locationPin = new Navigation(wayObj, undefined, true, undefined);
                this.editor.onCommand(new AddObject(this.editor, locationPin.destination.mesh));
                this.currentHeader = null; 
                !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);

                this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
                this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
                document.removeEventListener('keydown', this.onKeyDownExit, false);
            break;

            default:
                break;
        }
    }


    onKeyDownExit = (e) => {
        if(e.keyCode === 27) {
            this.exitNavigationTracking();
        }
    }

    exitNavigationTracking = () => {
        this.editor.removeObject(this.currentHeader);
        this.startNavigationTrack = false;
        this.editor.trigger('navigationTracking', [false]);
        this.canvas.removeEventListener('mousemove', this.onMouseMove, false);  
        this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
        this.canvas.removeEventListener('dblclick', this.onDoubleClick, false);
        document.removeEventListener('keydown', this.onKeyDownExit, false);
    }

    cloneLocationHeader = () => {
        let header = this.editor.mapPinObject.clone();
        const newColorMat = new THREE.MeshStandardMaterial().copy(header.material);
        newColorMat.color.setHex(16611842, 'srgb-linear');
        header.material = newColorMat;
        header.material.needsUpdate = true;
        header.children[0].material = newColorMat;
        header.children[0].material.needsUpdate = true;
        const randID = getRandomIdCode();
        header.name = `locationPin_${randID}`;
        header.userData['id'] = `locationPin_${randID}`;
        header.scale.set(0.007, 0.007, 0.007);

        return header;
    }

    onToggleMenuActive = () => {
        if(this.startNavigationTrack) {
            this.exitNavigationTracking();
        }
    }
}

export default LocationPinHelper;