import * as THREE from 'three';

import EditorExperience from '../../MinEditorExperience';


/* LINE UTILS */
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';

export default class NavLine {

    constructor(lineProps, mode) {
        this.lineProps = lineProps;

        this.editor = new EditorExperience();
        this.navMode = mode;

        this.name = lineProps.name;
        this.description = lineProps.description;
        this.id = lineProps.id;
        this.vertices = lineProps.vertices;

        //Pins Setup
        this.startVertex = this.vertices[0];
        this.endVertex = this.vertices[this.vertices.length - 1];

        this.pathColor =  this.vertices[0].pathColor;
        
        this.mesh = {};
        this.start = {};
        this.end = {};
        
        this.navModeStrings = ['waypointAndDestination', 'destination', 'waypoints'];

        this.editor.time.on('tick', this.updateLineRes);

        this.setupLineGeometry();
    }

    setupLineGeometry = () => {

        this.geometry = new THREE.BufferGeometry();

        let positions = [];
        let colors = [];
        let lineWidth = [];

        var color = new THREE.Color(this.pathColor)

        for(var i = 0, index = 0; i < this.vertices.length; i++, index += 3) {
            positions[ index ] = this.vertices[i].pointArray[0];
            positions[ index + 1 ] = this.vertices[i].pointArray[1];
            positions[ index + 2 ] = this.vertices[i].pointArray[2];

            colors[ index ] = color.r;
            colors[ index + 1 ] = color.g;
            colors[ index + 2 ] = color.b;
            
            (i <this.vertices.length - 1) && (lineWidth[i] = 10);
        }

        this.geometry = new LineGeometry();
        this.geometry.setPositions(positions);
        this.geometry.setColors(colors);
        this.geometry.setAttribute('linewidth', new THREE.InstancedBufferAttribute(new Float32Array(lineWidth), 1))

        this.material = new LineMaterial({
            vertexColors: true,
            dashed: false,
            alphaToCoverage: true,

        })

        this.material.onBeforeCompile = shader => {
            shader.vertexShader = `${shader.vertexShader}`.replace(`uniform float linewidth;`, `attribute float linewidth;`);
        }

        this.mesh = new THREE.Group();
        this.line = new Line2(this.geometry, this.material);
        this.line.computeLineDistances();
        this.mesh.add(this.line);
        this.mesh.scale.set( 1, 1, 1 );
        this.mesh.name = `${this.name}`

        // Add Pins
        this.start = this.editor.navPinStart.clone();
        this.start.rotateY(THREE.MathUtils.degToRad(180));
        this.start.position.copy(this.startVertex.position);
        this.start.scale.set(0.45, 0.45, 0.45);
        this.mesh.add(this.start);

        this.end = this.editor.navPinEnd.clone();
        this.end.rotateY(THREE.MathUtils.degToRad(180));
        this.end.position.copy(this.endVertex.position);
        this.end.scale.set(0.45, 0.45, 0.45);
        this.mesh.add(this.end);
    }

    updateLineRes = () => {
        this.material.resolution.set( this.editor.sizes.width, this.editor.sizes.height );
    }
}