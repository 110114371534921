import React, { useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UploadIcon } from "../../../../assets/svgs/uploadIcon.svg";
import AllContent from "./AllContent";
import AudioContent from "./AudioContent";
import ContentTabs from "./ContentTabs";
//import GifContent from "./GifContent";
//import HologramContent from "./HologramsContent";
import ImageContent from "./ImageContent";
import VideosContent from "./VideosContent";
import FloorplansContent from "./FloorplansContent";
import ModelContent from "./ModelContent";
import ModelPreviewModal from "./ModelPreviewModal";
import UploadContentModal from "./UploadContentModal";
import { Alert, Snackbar } from "@mui/material";
import ContentLoaderModel from "./ContentLoaderModel";
import { setNewAlert } from "../../../../features/common/alertSlice";

const ContentHome = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [openThumbnailModal, setOpenThumbnailModal] = useState(false);
  const [openModelPreviewModal, setOpenModelPreviewModal] = useState(false);
  const [item, setItem] = useState(null);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [refetchContents, setRefetchContents] = useState(false);

  const planDetails = useSelector(state => state.settings.planDetails);

  const handleTabChange = (e, value) => {
    setActiveTab(value);
  }

  const handleItemClick = async (item) => {
    setOpenModelPreviewModal(true);

    axios
      .get(`${item.path}`)
      .then((res) => {
        setItem(item);
        setOpenModelPreviewModal(false);
        setOpenThumbnailModal(true);
      })
      .catch((err) => {
        setOpenModelPreviewModal(false);
        setErrorSnackbarOpen(true);
      });
  };

  const storageExceeds = () => {
    let bFlag = false;
    if (planDetails?.planStorageLeft === false) bFlag = true;
    return bFlag;
  }

  const tabItems = {
    All: (
      <AllContent
        handleItemClick={handleItemClick}
        refetchContents={refetchContents}
        stopRefetch={() => setRefetchContents(false)}
      />
    ),
    "3D Models": (
      <ModelContent
        handleItemClick={handleItemClick}
        refetchContents={refetchContents}
        stopRefetch={() => setRefetchContents(false)}
      />
    ),
    Images: (
      <ImageContent
        handleItemClick={handleItemClick}
        refetchContents={refetchContents}
        stopRefetch={() => setRefetchContents(false)}
      />
    ),
    Videos: (
      <VideosContent
        handleItemClick={handleItemClick}
        refetchContents={refetchContents}
        stopRefetch={() => setRefetchContents(false)}
      />
    ),
    "Audio Files": (
      <AudioContent
        handleItemClick={handleItemClick}
        refetchContents={refetchContents}
        stopRefetch={() => setRefetchContents(false)}
      />
    ),
    "Floor plans": (
      <FloorplansContent
        handleItemClick={handleItemClick}
        refetchContents={refetchContents}
        stopRefetch={() => setRefetchContents(false)}
      />
    ),
    // Holograms: <HologramContent handleItemClick={handleItemClick} refetchContents={refetchContents} stopRefetch={() => setRefetchContents(false)} />,
    // GIFs: <GifContent handleItemClick={handleItemClick} refetchContents={refetchContents} stopRefetch={() => setRefetchContents(false)} />,
  };

  return (
    <div className="content-home-container">
      <div className="content-home-header">
        <h1>Content Library</h1>
        <button onClick={() => {
          if (storageExceeds()) {
            dispatch(setNewAlert({
              msg: 'plan storage exceeded, please upgrade your storage to continue!',
              alertType: 'danger'
            }))
          } else {
            setOpenUploadModal(true);
          }
        }} id="button-uploadContent" className={`contentUploadButton ${storageExceeds() && "contentUploadButton__disabled"}`}>
          <UploadIcon /> Upload Content
        </button>
      </div>
      <div className="content-category-tabs">
        <ContentTabs
          activeTab={activeTab}
          onTabChange={handleTabChange}
          tabItems={tabItems}
        />
      </div>
      <UploadContentModal
        open={openUploadModal}
        handleClose={() => setOpenUploadModal((prevState) => !prevState)}
        onSuccess={() => setRefetchContents(true)}
      />
      <ModelPreviewModal
        open={openThumbnailModal}
        onClose={() => setOpenThumbnailModal((prevState) => !prevState)}
        item={item}
      />
      <ContentLoaderModel
        open={openModelPreviewModal}
        onClose={() => setOpenModelPreviewModal((prevState) => !prevState)}
      />
      <Snackbar
        open={errorSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setErrorSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setErrorSnackbarOpen(false)}
          severity="error"
          sx={{ width: "100%" }}
          style={{ backgroundColor: "rgb(211, 47, 47)", color: "#fff" }}
          className="content-snackbar"
        >
          Item not found
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContentHome;
