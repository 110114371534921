import RenderTable from "../../Render/RenderTable";
import { isEmpty, isNull, isUndefined } from "lodash";
import { CSVLink } from "react-csv";
import { Download, ChevronDown } from "react-feather";
import {
  getHighestPerformingMapsbyLocation,
  highestPerformingMapsbyLocationCSV,
} from "../../../../../../features/user/analytics/analyticsSlice";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import RenderPagination from "../../Render/RenderPagination";
import usePagination from "../../Render/usePagination";
import { Box, MenuItem, Select } from "@mui/material";
import DatePicker from "../../../../../_utils/DatePicker";
import { lastTEDays, getFileNameWithTS } from "../../../../../_utils/DateUtils";

const highestPerformingMapsbyLocationHeaders = [
  { key: "rank", label: "Rank" },
  { key: "mapTitle", label: "Map Name" },
  { key: "mapArea", label: "Map Area sq. ft"},
  { key: "mapvisits", label: "Visit Count" },
  { key: "region", label: "Region" },
];

const HighestPerformingMapsbyLocation = ({
  highestPerformingMapsbyLocation,
}) => {
  const regions = useSelector((state) => state.analytics.regions);

  const dispatch = useDispatch();
  const [totalResults, setTotalResults] = useState(0);
  const limit = 10;
  const [page, setPage] = useState(1);
  const count = Math.ceil(highestPerformingMapsbyLocation.totalResults / limit);
  const _DATA = usePagination(
    highestPerformingMapsbyLocation.data
      ? highestPerformingMapsbyLocation.data
      : [],
    limit
  );

  const [region, setRegion] = useState("all,all");
  const [dateObj, setDateObj] = useState(lastTEDays);
  const [args, setArgs] = useState();

  const previousRegion = useRef();

  const highestPerformingMapsbyLocationCSVData = useSelector(
    highestPerformingMapsbyLocationCSV
  );

  useEffect(() => {
    let [reg, country] = region.split(",");
    let args = { ...dateObj, country, reg };
    setArgs(args);
    dispatch(getHighestPerformingMapsbyLocation(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let [reg, country] = region.split(",");
    let args = { ...dateObj, country, reg, page, limit };
    setArgs(args);
    dispatch(getHighestPerformingMapsbyLocation(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    let [reg, country] = region.split(",");
    let args = {};
    if (previousRegion.current !== region) {
      setPage(1);
      args = { ...dateObj, country, reg };
    } else {
      args = { ...dateObj, country, reg, page, limit };
    }
    previousRegion.current = region;
    setArgs(args);
    dispatch(getHighestPerformingMapsbyLocation(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);

  useEffect(() => {
    if (totalResults !== highestPerformingMapsbyLocation?.totalResults) {
      setTotalResults(highestPerformingMapsbyLocation?.totalResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highestPerformingMapsbyLocation]);

  useEffect(() => {
    if (totalResults > 0)
      dispatch(
        getHighestPerformingMapsbyLocation({
          ...args,
          page: 1,
          limit: totalResults,
          isCSV: true,
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalResults]);

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col-4">
            <div className="analyticshome__anCard--contentRow--header">
              Highest Performing Maps by Location
            </div>
          </div>
          <div className="col" style={{ paddingRight: "2.5rem" }}>
            <div className="row m-0 box">
              <div className="col-4"></div>
              <Box
                component="span"
                className="col-3 analyticshome__anCard--filterBox"
              >
                <span style={{ color: "#858993" }}>Region:</span>
                <Select
                  style={{
                    fontFamily: "poppins",
                    color: "#2A4DC2",
                    width: "70%",
                  }}
                  value={region}
                  onChange={handleRegionChange}
                  variant="standard"
                  fullWidth
                  disableUnderline
                  IconComponent={ChevronDown}
                >
                  {regions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      style={{ fontFamily: "poppins", color: "#2A4DC2" }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <div className="col-1"></div>
              <Box
                component="span"
                className="col-4 analyticshome__anCard--filterBox"
              >
                <span style={{ color: "#858993" }}>Date:</span>
                <DatePicker
                  cb={(obj) => {
                    setDateObj(obj);
                    let [reg, country] = region.split(",");
                    let args = { ...obj, country, reg, page, limit };
                    dispatch(getHighestPerformingMapsbyLocation(args));
                  }}
                />
              </Box>
              {/* } */}
            </div>
            <div
              className="analyticshome__floatright"
              style={{ paddingRight: "35px" }}
            ></div>
          </div>
        </div>
        <div className="row pt-3 analyticshome__anCard--contentRow">
          <div className="col">
            <div
              className="analyticshome__floatright"
              style={{ paddingRight: "35px" }}
            >
              {isEmpty(highestPerformingMapsbyLocationCSVData.data) ? null : (
                <CSVLink
                  headers={highestPerformingMapsbyLocationHeaders.map(
                    (headerObj, index) => ({
                      label: headerObj.label,
                      key: Object.keys(
                        highestPerformingMapsbyLocationCSVData.data[0]
                      )[index],
                    })
                  )}
                  data={highestPerformingMapsbyLocationCSVData.data}
                  filename={getFileNameWithTS(
                    "HighestPerformingMapsbyLocation"
                  )}
                  style={{ color: "#2A4DC2" }}
                >
                  <Download /> Download CSV
                </CSVLink>
              )}
            </div>
          </div>
        </div>
        <hr />
        {isEmpty(highestPerformingMapsbyLocation.data) ||
        isNull(_DATA.currentData()) ||
        isUndefined(_DATA.currentData()) ? (
          <h5 className="text-center analyticshome__emptyStateText ">
            {" "}
            No Data available{" "}
          </h5>
        ) : (
          <RenderTable
            thheaderData={highestPerformingMapsbyLocationHeaders}
            rowsData={_DATA.currentData()}
          />
        )}
        <div className="row analyticshome__anCard--contentRow--footer">
          <div className="col">
            {isEmpty(highestPerformingMapsbyLocation.data) ? null : (
              <RenderPagination
                count={count}
                page={page}
                limit={limit}
                handleChange={handleChange}
                cb={(page) => {
                  setPage(page);
                  _DATA.jump(page);
                }}
                totalResults={highestPerformingMapsbyLocation.totalResults}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HighestPerformingMapsbyLocation;
