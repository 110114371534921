import React, { useEffect, useState } from "react";
import { ReactComponent as AndroidIcon } from "../../../../assets/svgs/Android.svg";
import IosIcon from "../../../../assets/svgs/Apple.png";
import Server from "../../../../api/noAuthServer";

const Downloads = () => {
  const [appData, setAppData] = useState("");
  const getAppDetails = async () => {
    try {
      const res = await Server.get("/v1/app/version");
      setAppData(res?.data?.data);
    } catch (error) {
      setAppData("");
    }
  };
  useEffect(() => {
    getAppDetails();
  }, []);
  return (
    <div className="ar-download">
      <h1 className="ar-downlaod-heading">Downloads</h1>
      <h1 className="ar-downlaod-sub-heading">Apps</h1>
      <div className="download-container">
        <div className="download-item">
          <div className="item-details">
            <h1>ARway App {appData?.androidVersion}</h1>
            <p>
              <span>Android</span>
              {/* last updated */}
            </p>
            <a
              href="https://play.google.com/store/apps/details?id=com.nextechar.armaps"
              target={"_blank"}
              rel="noopener noreferrer"
              id="link-arwayPlayStore"
            >
              Download
            </a>
          </div>
          <div className="item-icon">
            <AndroidIcon />
          </div>
        </div>
        <div className="download-item">
          <div className="item-details">
            <h1>ARway App {appData?.iosVersion}</h1>
            <p>
              <span>iOS</span>
              {/* last updated */}
            </p>
            <a
              href="https://apps.apple.com/us/app/aritize-maps/id1599443392"
              target={"_blank"}
              rel="noopener noreferrer"
              id="link-arwayAppStore"
            >
              Download
            </a>
          </div>
          <div className="item-icon">
            <img src={IosIcon} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Downloads;
