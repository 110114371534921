import React, { useEffect, useState } from "react";
import Thumbnail from "./Thumbnail";
import TextField from "./TextField";
import useTextField from "./useTextField";
import NamingConvention from "./NamingConvention";
import MapLayers from "./MapLayers";

function EnterVenueDetails({ setBuilding, venueData }) {
  const [thumbnail, setThumbnail] = useState(venueData?.thumbnail?venueData?.thumbnail:"");
  const [venueName, bindVenueName] = useTextField(venueData?.name);
  const [venueDescription, bindVenueDescription] = useTextField(venueData?.description);
  const [namingConvention, setNamingConvention] = useState(venueData?.namingConvention);
  const [mapsLayers, setMapsLayers] = useState(venueData?.maps);

  useEffect(() => {
    setBuilding({
      thumbnail,
      name: venueName,
      description: venueDescription,
      namingConvention,
      maps: mapsLayers?.map((m,i)=>({...m,order:i+1})),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnail, venueName, venueDescription, namingConvention, mapsLayers]);

  return (
    <div className="" style={{}}>
      <div className="d-flex align-items-center flex-column">
        <h2>Enter Venue Map Details</h2>
        <p className="import-maps-subtext pt-4">
          Your import was successful! Enter the details for your newly created
          venue map.
        </p>
      </div>
      {/* <hr/> */}
      <div
        className="d-flex flex-column ps-5 ms-4 me-4"
        style={{ height: "49vh", overflow: "auto" }}
      >
        <Thumbnail thumbnail={thumbnail} setThumbnail={setThumbnail} />
        <hr />
        <TextField
          name={"Venue Name"}
          type={"input"}
          config={{ data: venueName, bindData: bindVenueName }}
        />
        <TextField
          name={"Venue Description"}
          type={"textarea"}
          config={{ data: venueDescription, bindData: bindVenueDescription }}
        />
        <hr />
        <NamingConvention setNamingConvention={setNamingConvention} namingConvention={namingConvention}/>
        <hr />
        <MapLayers
          maps={venueData?.maps}
          setMapsLayers={(data) => {
            setMapsLayers(
              data.map((m) => ({
                mapId: m.mapId,
                order: m.order,
                mapName: m.name,
                mapNamingType: m.type,
                identifier: m.identifier,
              }))
            );
          }}
        />
      </div>
    </div>
  );
}

export default EnterVenueDetails;
