import * as THREE from 'three';
import EditorExperience from '../../EditorExperience';
import EventEmitter from '../../utils/EventEmitter';

import { UIImage, UIPanel } from '../../ui/ui.lib';


export default class AnchorNode extends EventEmitter {
    constructor(anchorData, index, nodeState) {
        super();
        this.editor = new EditorExperience();
        this.anchorData = anchorData;
        this.index = index;
        this.nodeState = nodeState;
        this.anchorSprite = null;
        
        // this.editor.on('interactionWithNonInteractiveObject', this.updateUI);

        this.setupSprite();
        this.initHTML();
    }


    // create and return a canvas texture
    createCanvasTexture = (text) => {
        var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');
        canvas.width = 128;
        canvas.height = 128;

        //Draw outer circle
        ctx.beginPath();
        ctx.arc(64, 64, 24, 0, 2* Math.PI);
        ctx.fillStyle = '#04D9FF';
        ctx.fill();

        ctx.beginPath();
        ctx.font="24px Poppins";
        ctx.fillStyle = "#000000";
        ctx.textAlign = "center";
        ctx.fillText(text, 64, 73);

        var texture = new THREE.CanvasTexture(canvas);
        return texture;
    };

    setupSprite = () => {

        if(this.nodeState === 'NODE') {
            const spriteMaterial = new THREE.SpriteMaterial({
                map: this.createCanvasTexture(this.index+1),
                alphaTest: 0.5,
                transparent: true,
                depthTest: false,
                depthWrite: false
            });
            
            this.anchorSprite = new THREE.Sprite(spriteMaterial);
            this.anchorSprite.name = "Anchor";
            this.anchorSprite.position.copy(this.anchorData.position);
            this.anchorSprite.scale.set(0.45, 0.45, 0.45);

        } else {
            this.anchorSprite = this.editor.anchorPinObject.clone();
            this.anchorSprite.name = this.nodeState;
            this.anchorSprite.position.copy(this.anchorData.position);
            this.anchorSprite.position.y += 0.15;
            this.anchorSprite.scale.set(0.0035, 0.0035, 0.0035);

        }

        this.uuid = this.anchorSprite.uuid;
        this.editor.nonInteractiveObjects.push(this.uuid);

        // this.editor.addObjects(this.anchorSprite)
    }

    initHTML = () => {
        this.uiCont = new UIPanel();
        this.uiCont.addClass('wsMapVisualContainer__mapPointContainer');
        const uiImage = new UIImage(this.anchorData.thumbnailURL, '100%', '160px').addClass('wsMapVisualContainer__mapPointImage');
        this.uiCont.addChild(uiImage);
        this.uiCont.setDisplay('none');

    }

    /* updateUI = (object) => {
        if(object && object.uuid === this.uuid) {
            this.uiCont.setDisplay('');
        } else {
        this.uiCont.setDisplay('none');
        }
    } */
}