import axios from "axios";
import { REACT_APP_MAP_APIS_BASEURL } from "../values/constants";

// ROUTS & Constants
import {
    LOGIN,
    REFRESH_URL,
} from '../components/_utils/routes';
import differenceInDays from "date-fns/differenceInDays";

const axiosInstance = axios.create({
    baseURL: REACT_APP_MAP_APIS_BASEURL,
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosInstance.interceptors.request.use((config) => {
    const authToken = localStorage.getItem('token');
    // const authAccountId = localStorage.getItem('activeAccountId');
    config.headers.Authorization = authToken ? `Bearer ${authToken}` : '';
    // config.headers.accountId = authAccountId ? authAccountId : '';
    if(sessionStorage.getItem('impersonateUser') === 'true') {
        const impersonateId = sessionStorage.getItem('impersonateUserId');
        config.headers['X-User-Impersonate'] = impersonateId ? impersonateId : '';
    }
    
    return config;
});

// Refresh Things!
let axiosIsRefreshing = false;
let axiosFailedQueue = [];

const axiosProcessQueue = (error, token = null) => {
    axiosFailedQueue.forEach(promise => {
        if(error) {
            promise.reject(error);
        } else {
            promise.resolve(token);
        }
    });

    axiosFailedQueue = [];
}


axiosInstance.interceptors.response.use(response => response, (error) => {
    const originalRequest = error.config;

    // console.log(error);

    if (error.response && error.response.status === 401 && !originalRequest._toRetry) {
        const token = localStorage.getItem('refToken');
        const tokenExp = localStorage.getItem('refTokenExp');
        
        if(token && tokenExp) {
            // Push into failed queue!
            if(axiosIsRefreshing) {
                return new Promise((resolve, reject) => {
                    axiosFailedQueue.push({resolve, reject});
                }).then(token => {
                    originalRequest.headers['Authorization'] = `Bearer ${token}`;
                    return axiosInstance(originalRequest);
                }).catch(error => {
                    return Promise.reject(error);
                })
            }

            originalRequest._toRetry = true;
            axiosIsRefreshing = true;
            
            if(differenceInDays(new Date(tokenExp), new Date()) > 0) {
                return new Promise((resolve, reject) => {
                    axiosInstance
                    .post(`/${REFRESH_URL}`, {refreshToken: token})
                    .then((response) => {
                        localStorage.setItem('token', response.data.access.token);
                        localStorage.setItem('tokenExp', response.data.access.expires);
                        localStorage.setItem('refToken', response.data.refresh.token);
                        localStorage.setItem('refTokenExp', response.data.refresh.expires);

                        axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.access.token}`; 
                        originalRequest.headers['Authorization'] = `Bearer ${response.data.access.token}`;
                        //resolve failed queue!
                        axiosProcessQueue(null, response.data.access.token);
                        resolve(axiosInstance(originalRequest));
                    })
                    .catch(err => {
                        //Refresh Error [ logout ]
                        axiosProcessQueue(err, null);
                        window.location.href = LOGIN;
                        localStorage.clear();
                        reject(err);
                    })
                    .finally(() => { axiosIsRefreshing = false });
                })
            } else {
                console.log("Refresh token is expired");
                localStorage.clear();
                window.location.href = LOGIN;
            }
        } else {
            console.log("Refresh token not available.")
            localStorage.clear();
            window.location.href = LOGIN;
        }
    }
    return Promise.reject(error);
})

export default axiosInstance;