import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getRandomIdCode } from '../../../../webstudio/threeUtils/TransformConversions';

//components
import { Container, Box } from '@mui/material';
import UserInfoTab from './infotabs/UserInfoTab';
import PlanInfoTab from './infotabs/plan/PlanInfoTab';
import ImpUserTab from './infotabs/ImpUserTab';
import ContentTabs from '../../../users/content-library/ContentTabs';

//Actions
import { getAccountUserInfo } from '../../../../../features/admin/manageaccounts/manageAccountsSlice';
import { ChevronLeft } from 'react-feather';

export default function AccountDetailsHome() {
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ activeTab, setActiveTab ] = useState(0);

    const accountDetails = useSelector(state => state.manageaccounts.accountDetails);

    const account = location.state;
    const updatedAccount = {
        ...account,
        id: account.userId,
        accoundId: account.id
    }

    useEffect(() => {
        dispatch(getAccountUserInfo(account.userId));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleImpersonation = () => {
        // console.log("IMPERSONATE", updatedAccount);
        const userKey = getRandomIdCode();
        let impState = localStorage.getItem("impersonateState");
        if(impState === null) {
            //EMPTY
            impState = {}
            impState[userKey] = updatedAccount;
            localStorage.setItem('impersonateState', JSON.stringify(impState));
        } else {
            impState = JSON.parse(impState);
            impState[userKey] = updatedAccount;
            localStorage.setItem('impersonateState', JSON.stringify(impState));
        }
        // Now open
        window.open(`/impersonate/${userKey}`, '_blank');
    }

    const tabItems = {
        'Account Profile': (
            <UserInfoTab
                accountDetails={accountDetails}
                accountId={updatedAccount.accoundId}
            />
        ),
        'Maps': (
            <ImpUserTab
                accountDetails={updatedAccount}
                label="Visit User Maps"
                path='/dashboard/studio'
            />
        ),
        'Plan': (
            <PlanInfoTab 
                accountDetails={accountDetails}
                accountData={account}
            />
        ),
        'Analytics': (
            <ImpUserTab
                accountDetails={updatedAccount}
                label="Visit User Analytics"
                path='/dashboard/analytics'
            />
        ),
    }

    return (
        <div style={{padding:'40px'}}>
            <Container maxWidth={false} >
                <div  className='maccounts__headerCont'>
                    <div className='maccounts__backBtn' onClick={() => navigate(-1)}><ChevronLeft /> Back </div>
                </div>
                <div className="pageWrapper">
                    <span className='maccounts__headerRow'>
                        <h4>Account <span>{account.name || '- -'}</span></h4>
                    </span>
                    <button className='maccounts__reportButton' onClick={handleImpersonation}>Impersonate</button>
                </div>
            </Container>
            <Box className="boxWrapper">
                <ContentTabs 
                    activeTab={activeTab}
                    onTabChange={(e, value) => {
                        setActiveTab(value)
                    }}
                    tabItems={tabItems}
                />
            </Box>
        </div>
    )
}
