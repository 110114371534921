import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { Box, Grid } from "@mui/material";

import {
  NavLink as Link,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";

//Icons
import StudioIcon from "../../../../../assets/icons/sidebar/studioIcon.svg";
import { ReactComponent as PublishIcon } from "../../../../../assets/icons/sidebar/publish_icon.svg";
import VenueImg from "../../../../../assets/pngs/studio/importMaps/building.png";
import { ChevronLeft, Edit2, Share2, Copy, Save } from "react-feather";
import OccImgOn from "../../../../../assets/pngs/occ_on.png";
import OccImgOff from "../../../../../assets/pngs/occ_off.png";

import { convertToNamedDate } from "../../../../_utils/dateFormatter";

import ContentTabs from "../../content-library/ContentTabs";
import MessageModal from "../../../../common/modal/Modal";
import CustomModal from "../../../../common/modal";
import Loader from "../../../../common/loader";
import ConnectedMaps from "./ConnectedMaps";
import Locationpin from "./LocationPin";
import VenueDetailsTab from "./VenueDetailsTab";
import QRCodesTab from "../QRCodeTab/QRCodesTab";
import IAPTab from "../IAPTab/IAPTab";

import {
  getVenueDetails,
  setBuildingMaps,
  updateVenueMap,
  deleteVenue,
  resetVenueDetailsTab,
  publishVenueMap,
  resetLog,
  getConnectorGroups
} from "../../../../../features/webstudio/buildingSlice";

import { getAmenityPinCategories } from "../../../../../features/webstudio/editor2dSlice";
import {
  resetCommonLog,
  resetMapDetailsTab,
} from "../../../../../features/common/commonSlice";
import {
  setNewAlert,
  clearAlerts,
} from "../../../../../features/common/alertSlice";
import { getQRURL } from "../../../../_utils/QRURLUtils";

const Building = (props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  const venueInfo = useSelector((state) => state.building.venueDetails);
  const defaultMapData = useSelector((state) => state.studio.mapDetails);
  const studioLog = useSelector((state) => state.studio.studioLog);
  const deleteLog = useSelector((state) => state.studio.deleteLog);
  const replaceLog = useSelector((state) => state.studio.replaceLog);
  const anchorLog = useSelector((state) => state.anchors.anchorLog);
  const updateLog = useSelector((state) => state.building.log);

  const mapData = params.venueMapId;

  const [venueDetails, setVenueDetails] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [fetching, setFetching] = useState(false);

  const [venueState, setVenueState] = useState({
    name: "",
    description: "",
    mapStatus: "public",
    isPublished: false,
    mapOcclusion: false,
    dialIndicator: false,
    hasAnchors: false,
    isAssociated: false,
    isVenue: location.state?.isVenue,
    defaultMap: null,
    defaultMapData: null,
  });
  // helper bools
  const [helperBools, setHelperBools] = useState({
    isTitleEdit: true,
    isEdit: true,
    copied: false,
    qrGenerated: false,
    isBackClicked: false,
    externalIAPClick: false,
    isReplaceFloorplan: false,
  });
  // modal bools
  const [modalBools, setModalBools] = useState({
    deleteModal: false,
    shareModal: false,
    IAPModal: false,
    occlusionModal: false,
    replaceFloorplanModal: false,
  });

  useEffect(() => {
    setFetching(true);
    dispatch(resetCommonLog());
    fetchVenueDetails();
    dispatch(getAmenityPinCategories());
    dispatch(getConnectorGroups({buildingId:mapData}))

    return () => {
      dispatch(resetVenueDetailsTab());
      dispatch(clearAlerts());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isEqual(defaultMapData, venueState.defaultMapData)) {
      setVenueState((venue) => ({
        ...venue,
        defaultMapData,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultMapData]);

  useEffect(() => {
    if (venueInfo && !isEqual(venueInfo, venueDetails)) {
      setFetching(false);
      setVenueDetails(venueInfo);
      addBuildingMaps(venueInfo);
      setVenueState((state) => ({
        ...state,
        name: venueInfo.name,
        description: venueInfo.description ? venueInfo.description.trim() : "",
        isPublished: venueInfo.isPublished,
        defaultMap: venueInfo?.maps.length ? venueInfo?.maps[0] : null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueInfo]);

  // useEffect for logs!!
  useEffect(() => {
    if (updateLog === "success") {
      fetchVenueDetails();
      dispatch(resetLog());
    }

    if (anchorLog === "success") {
      fetchVenueDetails();
    }

    if (studioLog === "success") {
      fetchVenueDetails();
      !helperBools.isEdit &&
        setHelperBools((helpers) => ({ ...helpers, isEdit: !helpers.isEdit }));
      dispatch(resetCommonLog());
    }

    if (deleteLog === "success") {
      setModalBools((modal) => ({ ...modal, deleteModal: false }));
      navigate(-1);
    }

    if (replaceLog === "success") {
      setModalBools((modal) => ({ ...modal, replaceFloorplanModal: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateLog, anchorLog, studioLog, deleteLog, replaceLog]);

  const fetchVenueDetails = () => {
    dispatch(getVenueDetails(mapData));
  };

  const addBuildingMaps = (buildingDetails) => {
    if (buildingDetails) {
      dispatch(setBuildingMaps(buildingDetails.maps));
    }
  };

  const handleGoBack = () => {
    setHelperBools((helpers) => ({
      ...helpers,
      isBackClicked: !helperBools.isBackClicked,
    }));
    if (activeTab === 0) {
      dispatch(resetMapDetailsTab());
      dispatch(resetVenueDetailsTab());
    }
    navigate(-1);
  };

  const toggleModal = (varId) => {
    setModalBools((modals) => ({
      ...modals,
      [varId]: !modals[varId],
    }));
  };

  const handleDelete = () => {
    dispatch(
      deleteVenue({
        venueId: mapData,
        venueName: venueState.name,
      })
    );
    toggleModal("deleteModal");
    navigate(-1);
  };

  const handleReplaceFloorplan = () => {
    setHelperBools((helpers) => ({
      ...helpers,
      isReplaceFloorplan: !helpers.isReplaceFloorplan,
    }));
  };

  const handleSaveChanges = (e) => {
    const { name } = venueState;
    if (name.length <= 0) {
      dispatch(
        setNewAlert({
          msg: "Venue Name cannot be empty!",
          alertType: "danger",
        })
      );
    } else if (name.length < 3) {
      dispatch(
        setNewAlert({
          msg: "Minimum three character required!",
          alertType: "danger",
        })
      );
    }
  };

  if (fetching && !venueDetails) {
    return (
      <Loader
        loaderText="Fetching Venue Details..."
        height="100%"
        width="100%"
      />
    );
  }

  if (!venueDetails) {
    return <h2>Venue Details Not Available!</h2>;
  }

  const tabItems = {
    Overview: (
      <VenueDetailsTab
        state={{
          ...venueState,
          ...helperBools,
          mapDetails: venueDetails,
          mapData, 
        }}
        handleBack={(val) =>
          setHelperBools((helpers) => ({ ...helpers, isBackClicked: val }))
        }
        handletoggleModal={toggleModal}
        replaceLog={replaceLog}
        handleReplaceFP={() =>
          setHelperBools((helpers) => ({
            ...helpers,
            isReplaceFloorplan: !helpers.isReplaceFloorplan,
          }))
        }
      />
    ),
    "QR Codes": (
      <QRCodesTab
        data={{
          isVenue: true,
          mapData: venueState.defaultMap,
          maps: venueDetails?.maps,
        }}
      />
    ),
    "Image Access Points": (
      <IAPTab
        data={{
          isVenue: true,
          mapData: venueState.defaultMap,
          maps: venueDetails.maps,
        }}
      />
    ),
    "Location Pins": <Locationpin state={{ mapDetails: venueDetails }} />,
    "Connected Maps": (
      <ConnectedMaps
        handletoggleModal={toggleModal}
        state={{
          ...venueState,
          ...helperBools,
          mapDetails: venueDetails,
          mapData,
        }}
        handleReplaceFP={() =>
          setHelperBools((helpers) => ({
            ...helpers,
            isReplaceFloorplan: !helpers.isReplaceFloorplan,
          }))
        }
      />
    ),
  };

  return (
    <Box className="mapinfo">
      <Grid container={true}>
        <Grid
          item
          xs={12}
          className={`mapinfo__separator mapinfo__mapTopRow ${
            !helperBools.isEdit ? "mapinfo__separatorPadded" : ""
          }`}
        >
          <div
            className="mapinfo__backBtn"
            onClick={handleGoBack}
            id="button-mapBack"
          >
            <ChevronLeft
              size={24}
              color="#353E5A"
              className="mapinfo__mapIcon"
            />
            Back
          </div>
        </Grid>
        <form onSubmit={(e) => e.preventDefault()} style={{ width: "100%" }}>
          <Grid container={true}>
            <Grid item xs={4} className="mapinfo__mapTopRow">
              <div className="d-flex bd-highlight">
                <div className="flex-fill bd-highlight">
                  <img
                    src={VenueImg}
                    style={{
                      zIndex: 100,
                      position: "relative",
                      top: "22px",
                    }}
                    alt="venue-map"
                  ></img>
                </div>
                <div className="p-2 flex-fill bd-highlight">
                  {helperBools.isTitleEdit ? (
                    <label
                      className={`mapinfo__mapFormInputMapname`}
                      id="input-mapName"
                    >
                      {venueState.name}
                    </label>
                  ) : (
                    <input
                      type="text"
                      readOnly={helperBools.isTitleEdit}
                      value={venueState.name}
                      onChange={(e) =>
                        setVenueState((state) => ({
                          ...state,
                          name: e.target.value,
                        }))
                      }
                      onBlur={(e) => {
                        setVenueState((state) => ({
                          ...state,
                          name: e.target.value.trim(),
                        }));
                      }}
                      className={`mapinfo__mapFormInput ${
                        !helperBools.isTitleEdit
                          ? "mapinfo__mapFormInput--active"
                          : ""
                      }`}
                      autoComplete="off"
                      maxLength={30}
                      minLength={3}
                      id="name"
                    />
                  )}
                </div>
                <div className="p-2 flex-fill bd-highlight">
                  {helperBools.isTitleEdit ? (
                    <Edit2
                      style={{ cursor: "pointer", marginTop: "1.6rem" }}
                      onClick={() =>
                        setHelperBools((helper) => ({
                          ...helper,
                          isTitleEdit: !helper.isTitleEdit,
                        }))
                      }
                      id="button-editMapName"
                    />
                  ) : (
                    <Save
                      style={{ cursor: "pointer", marginTop: "1.6rem" }}
                      onClick={() => {
                        if (
                          venueState.name !== venueInfo.name &&
                          venueState.name.length >= 3
                        ) {
                          dispatch(
                            updateVenueMap({
                              mapId: mapData,
                              venueDetails: {
                                ...venueDetails,
                                name: venueState.name,
                              },
                            })
                          );
                          setHelperBools((helper) => ({
                            ...helper,
                            isTitleEdit: !helper.isTitleEdit,
                          }));
                        } else {
                          handleSaveChanges();
                        }
                      }}
                      id="button-saveMapName"
                    />
                  )}
                </div>
              </div>
            </Grid>
          </Grid>
          <Grid container={true}>
            <Grid item xs={6} className="mapinfo__separator mapinfo__mapTopRow">
              <div className="mapinfo__mapStatusRow">
                <div
                  style={{ height: "100%" }}
                  className={`mapinfo__mapStatusSpan ${
                    venueDetails.isPublished
                      ? "mapinfo__mapStatusSpan--published"
                      : "mapinfo__mapStatusSpan--draft"
                  }`}
                  id="span-mapStatus"
                >
                  {venueDetails.isPublished ? "Published" : "Draft"}
                </div>
                <div>
                  <span className="mapinfo__mapStatusSpan2">
                    {venueDetails?.isPublicMap === true ? "Public" : "Private"}
                  </span>
                  <span className="mapinfo__mapStatusSpan2">&bull;</span>
                  <span className="mapinfo__mapStatusSpan2">
                    Modified on {convertToNamedDate(venueDetails.modifiedOn)}
                  </span>
                  <span className="mapinfo__mapStatusSpan2">&bull;</span>
                  <span className="mapinfo__mapStatusSpan2">
                    Created on {convertToNamedDate(venueDetails.createdOn)}
                  </span>
                </div>
              </div>
            </Grid>
            <Grid
              container
              justifyContent="flex-end"
              item
              xs={6}
              className="mapinfo__separator"
            >
              <div className="">
                <button
                  className="mapinfo__mactionBtn"
                  style={{ background: "none", height: "44px" }}
                  onClick={() => toggleModal("shareModal")}
                  id="button-shareMap"
                >
                  {" "}
                  <Share2
                    size={16}
                    color="#353E5A"
                    className="mapinfo__mapIcon"
                  />{" "}
                  Share Venue Map
                </button>
              </div>
              {
                <div className="">
                  <button
                    className="mapinfo__mactionBtn"
                    style={{
                      background: "none",
                      height: "44px",
                    }}
                    onClick={() => {
                      dispatch(
                        publishVenueMap({
                          buildingId: mapData,
                          isPublish: !venueState.isPublished,
                        })
                      );
                    }}
                    id="button-mapState"
                  >
                    {" "}
                    <PublishIcon
                      size={16}
                      color="#353E5A"
                      className="mapinfo__mapIcon"
                    />{" "}
                    {venueState?.isPublished ? `Unpublish` : "Publish"}
                  </button>
                </div>
              }
              <Link
                to={`/editor/${venueState.defaultMap.mapCode}-${venueState.defaultMap.mapId}-${mapData}`}
                /* target="_blank"
                  rel="noopener noreferrer" */
                className={`linkTagMap`}
                style={{ paddingTop: "10px" }}
                id="link-openInStudio"
              >
                <span className={`mapinfo__studioIconBtn`}>
                  <img src={StudioIcon} alt="toStudioBtn" />
                  Open in Studio
                </span>
              </Link>
            </Grid>
          </Grid>
          <ContentTabs
            activeTab={activeTab}
            onTabChange={(e, value) => setActiveTab(value)}
            tabItems={tabItems}
          />
        </form>
      </Grid>
      <MessageModal
        openModal={modalBools.deleteModal}
        onCloseModal={() => toggleModal("deleteModal")}
        onSuccessModal={handleDelete}
        modalHeader={`Delete Venue: ${venueState.name}`}
        modalText="Are you sure you want to delete your venue?"
        modalSubText="This cannot be undone!"
        textSuccess="Delete"
        textCancel="Cancel"
      />
      <MessageModal
        openModal={modalBools.replaceFloorplanModal}
        showLoading={true}
        onCloseModal={() => toggleModal("replaceFloorplanModal")}
        onSuccessModal={handleReplaceFloorplan}
        modalHeader={`Replace Floor plan?`}
        modalText="Do you want to replace this floor plan? Any floor plans that have been aligned will have to be re-aligned once replaced."
        modalSubText="This cannot be undone!"
        textSuccess="Replace"
        textCancel="Cancel"
      />
      <CustomModal
        openModal={modalBools.shareModal}
        onCloseModal={() => toggleModal("shareModal")}
      >
        <Box className="mapinfo__miModalBody">
          <h4 className="mapinfo__miModalHeader">Share Your Map</h4>
          <div className="mapinfo__miModalSubCont">
            <span className="mapinfo__miModalSubHeader">
              {getQRURL(mapData)}
            </span>
            <button
              className={`mapinfo__miModalBtn ${
                helperBools.copied ? "mapinfo__miModalBtn--copied" : ""
              }`}
              onClick={() => {
                navigator.clipboard.writeText(getQRURL(mapData));
                setHelperBools((helpers) => ({ ...helpers, copied: true }));
              }}
              id="button-copyMap"
              disabled={helperBools.copied}
            >
              <Copy
                size={18}
                color="#ffffff"
                className="mapinfo__miModalBtn--idIcon"
              />
              {helperBools.copied ? "Link Copied" : "Copy Link"}
            </button>
          </div>
        </Box>
      </CustomModal>

      <CustomModal
        openModal={modalBools.occlusionModal}
        onCloseModal={() => toggleModal("occlusionModal")}
      >
        <Box className="mapinfo__miModalBody">
          <span className="mapinfo__miModalSubHeader mapinfo__miModalSubHeader--alignCenter">
            Enabling occlusion will show content behind real world objects
            without breaking immersion. Disabling occlusion will show content in
            front of real world objects at all times.
          </span>
          <div className="mapinfo__miModalInfoRow">
            <div className="mapinfo__miModalInfoCol">
              <img src={OccImgOn} alt="occ_img_on" draggable="false" />
              <span className="mapinfo__miModalInfoCol--text">
                Occlusion On
              </span>
            </div>
            <div className="mapinfo__miModalInfoCol">
              <img src={OccImgOff} alt="occ_img_off" draggable="false" />
              <span className="mapinfo__miModalInfoCol--text">
                Occlusion Off
              </span>
            </div>
          </div>
        </Box>
      </CustomModal>
      <CustomModal
        openModal={modalBools.IAPModal}
        onCloseModal={() => toggleModal("IAPModal")}
      >
        <Box className="mapinfo__miModalBody">
          <span className="mapinfo__miModalSubHeader mapinfo__miModalSubHeader--alignCenter">
            Default image access points are recommended for locations with one
            entrance. Turning this on will direct visitors to a specific access
            point for venue entry.
          </span>
        </Box>
      </CustomModal>
    </Box>
  );
};

export default Building;
