import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
import * as THREE from "three";
import { isObject } from "lodash";
//Icon
import HelpIcon from "../../../../assets/icons/sidebar/help.svg";
// utils
import {
  UIPanel,
  UIRow,
  UICol,
  UIText,
  UITextHeader,
  UIInputText,
  UINumber,
  UIColor,
  UICheckbox,
  UIInputTextArea,
  UIAudio,
  UIRadioButton,
  UIColorPalette,
  UIAnimations,
  UIVideo,
  UIButton,
  UIQRCode,
  UIImageInput,
  UISeparator,
  UISwitch,
  UIImage,
  UITooltip,
  UIUserWarningCard,
  UIFloorplanImgInput,
  UIPinNavSelector,
  UIFileInput,
} from "../ui.lib";

// COMMANDS
import { SetPosition } from "../../commands/SetPosition";
import { SetRotation } from "../../commands/SetRotation";
import { SetScale } from "../../commands/SetScale";
import { SetValue } from "../../commands/SetValue";
import { SetUserDataValue } from "../../commands/SetUserDataValue";
import { generateTextMesh } from "../../threeUtils/Text";
import { RemoveObject } from "../../commands/RemoveObject";
import { SetMaterialColor } from "../../commands/SetMaterialColor";
// import { SetVisible } from "../../commands/SetVisible";

import { Line2 } from "three/examples/jsm/lines/Line2";

class SidebarObject extends EventEmitter {
  constructor(props) {
    super(props);

    this.editor = new EditorExperience();
    this.strings = this.editor.strings;

    this.dom = null;
    this.objProperties = {};
    this.Object = {};

    this.userDataProperties = [
      "description",
      "hyperlinkName",
      "hyperlink",
      "audioLink",
      "showAsIcon",
      "autoplay",
      "pathColor",
      "animations",
      "videoLink",
      "qrAnchor",
      "qrcodeview",
      "imageAccess",
      "texture",
      "primaryAccess",
      "autoRotate",
      "canDownload",
      "floorWarning",
      "assetLink",
      "floorAdjust",
      "accessPointDimDir",
      "accessAdjust",
      "navigationStyle",
      "file",
      "image",
      "wheelchairAccessible"
    ];

    this.initUI();

    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on(
      "editSidebarObjectContents",
      this.onEditSidebarObjectContents
    );
    this.editor.on("toggleAdjustObject", this.onToggleAdjustObject);
    this.editor.on("toggleAdjustAccess", this.onToggleAdjustObject);
    this.editor.on("refreshSidebarObjects", this.onRefreshSidebarObjects);
    this.editor.on("objectChanged", this.onObjectChanged);
    this.editor.on("toggleMenuActive", this.onToggleMenuActive);
  }

  initUI = () => {
    const scope = this;

    this.uiContainer = new UIPanel();
    this.uiContainer.setDisplay("none");

    this.uiContainer.addChild(
      new UITextHeader(
        "100%",
        this.strings?.getKey("sidebar/object/title") || "Properties"
      )
        .addClass("wsBolderHeader")
        .setId("ws_titleProperties")
    );

    // Type
    var objectTypeRow = new UIRow();
    this.objectType = new UIText("80%")
      .setDisabled(true)
      .setStyle("textTransform", "capitalize");
    objectTypeRow.addChild(
      new UIText("20%", this.strings.getKey("sidebar/object/type"))
    );
    objectTypeRow.addChild(this.objectType);
    this.uiContainer.addChild(objectTypeRow);

    // ImageCard
    const uiImageCol = new UICol()
    uiImageCol.addChild(new UIText('100%', 'Image (Optional)'))
    this.objectTourImageInput = new UIFileInput('image', 'images', 'ws_uiFileInputCont--imgCont80h').onChange(() => {
      const file = this.objectTourImageInput.getFile();
      if(file){
        this.editor.trigger('MetaObjectChanged', [this.editor.selectedObject, 'image', file, false, 1]);
      } else{
        this.editor.trigger('MetaObjectChanged', [this.editor.selectedObject, 'image', file, false, 0]);
        if(this.objectTourImageInput.getError()) {
          this.objectTourImageInput.resetError();
          this.editor.editor3d.callbacks.generateAlert({ msg: 'Selected Invalid File Type!', alertType: 'information' });
          this.objectTourImageInput.setSrc(null);
        }
      }
    });
    uiImageCol.addChild(this.objectTourImageInput)
    this.uiContainer.addChild(uiImageCol);
    this.objProperties['image'] = uiImageCol;

    //wheelchairAccessible
    const uiWheelChairAccRow = new UIRow().addClass('wsFlexRowCenter');
    const uiPanelWheelAccess = new UIRow();
    uiPanelWheelAccess.addChild(new UITextHeader(undefined, 'Wheelchair Accessible'));
    uiWheelChairAccRow.addChild(uiPanelWheelAccess);
    this.objectWheelchairAccessState = new UIText('10%', 'OFF');
    this.objectWheelchairAccessSwitch = new UISwitch(false, true, 'ws_uiSliderFeaturedPin').onChange((e) => {
      const value = e.target.checked;
      this.objectWheelchairAccessState.setTextContent(value ? 'ON' : 'OFF');
      this.editor.trigger('MetaObjectChanged', [this.editor.selectedObject, 'wheelchairAccessible', value]);
    })
    uiWheelChairAccRow.addChild(this.objectWheelchairAccessState);
    uiWheelChairAccRow.addChild(this.objectWheelchairAccessSwitch);
    this.uiContainer.addChild(uiWheelChairAccRow);
    this.objProperties['wheelchairAccessible'] = uiWheelChairAccRow;

    // Name
    var objectNameRow = new UICol();
    this.objectNameInput = new UIInputText(
      "objectTitle",
      "100%",
      undefined,
      20,
      "Name"
    ).onChange((e) => {
      var value = e.target.value;
      if (value.length <= 0) {
        this.editor.callbacks.generateAlert({
          msg: `Object name shouldn't be empty!`,
          alertType: "information",
        });
      } else {
        if (this.editor.selectedObject.userData["type"] === "text") {
          const object = this.editor.selectedObject;
          let posVec = object.position.clone();
          let scaleVec = object.scale.clone();
          let RotEuler = object.rotation.clone();
          let autoRotate = object.userData.autoRotate || false;
          this.editor.onCommand(new RemoveObject(this.editor, object));
          generateTextMesh(
            0.5,
            value,
            object.children[0].material.color.getHex(),
            posVec,
            scaleVec,
            RotEuler,
            autoRotate
          );
        } else if (
          "MetaObject" in this.editor.selectedObject.userData &&
          this.editor.selectedObject.userData["MetaObject"] === true
        ) {
          this.editor.trigger("MetaObjectChanged", [
            this.editor.selectedObject,
            "name",
            value,
          ]);
        } else {
          const delayAutoSave = !(
            (this.editor.selectedObject &&
              "navigationMode" in this.editor.selectedObject.userData) ||
            "metaFloorplan" in this.editor.selectedObject.userData
          );
          this.editor.onCommand(
            new SetValue(
              this.editor,
              this.editor.selectedObject,
              "name",
              value,
              delayAutoSave
            )
          );
        }
      }
    });
    objectNameRow.addChild(
      new UITextHeader("20%", this.strings.getKey("sidebar/object/name"))
    );
    objectNameRow.addChild(this.objectNameInput);
    this.uiContainer.addChild(objectNameRow);

    //Transformations
    this.objectTransformCol = new UICol();
    this.objectTransformCol.addChild(new UITextHeader("20%", "Transformation"));

    //Position
    var objectPositionRow = new UIRow();
    this.objectPosX = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setId("transX")
      .setHelper("X")
      .onChange(this.update);
    this.objectPosY = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setId("transY")
      .setHelper("Y")
      .onChange(this.update);
    this.objectPosZ = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setId("transZ")
      .setHelper("Z")
      .onChange(this.update);
    objectPositionRow.addChild(
      new UIText("20%", this.strings.getKey("sidebar/object/position"))
    );
    objectPositionRow.addChild(
      this.objectPosX,
      this.objectPosY,
      this.objectPosZ
    );

    this.objectTransformCol.addChild(objectPositionRow);

    //Rotation
    var objectRotationRow = new UIRow();
    this.objectRotX = new UINumber()
      .setStep(10)
      .setNudge(0.1)
      .setUnit("°")
      .setRange(-360, 360)
      .setStyle("width", "25%")
      .setHelper("X")
      .setId("rotX")
      .onChange(this.update);
    this.objectRotY = new UINumber()
      .setStep(10)
      .setNudge(0.1)
      .setUnit("°")
      .setRange(-360, 360)
      .setStyle("width", "25%")
      .setHelper("Y")
      .setId("rotY")
      .onChange(this.update);
    this.objectRotZ = new UINumber()
      .setStep(10)
      .setNudge(0.1)
      .setUnit("°")
      .setRange(-360, 360)
      .setStyle("width", "25%")
      .setHelper("Z")
      .setId("rotZ")
      .onChange(this.update);
    objectRotationRow.addChild(
      new UIText("20%", this.strings.getKey("sidebar/object/rotation"))
    );
    objectRotationRow.addChild(
      this.objectRotX,
      this.objectRotY,
      this.objectRotZ
    );
    this.objectTransformCol.addChild(objectRotationRow);

    //Scale
    var objectScaleRow = new UIRow();
    this.objectScaleX = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setHelper("X")
      .setId("scaleX")
      .onChange(this.update);
    this.objectScaleY = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setHelper("Y")
      .setId("scaleY")
      .onChange(this.update);
    this.objectScaleZ = new UINumber()
      .setPrecision(2)
      .setStyle("width", "25%")
      .setHelper("Z")
      .setId("scaleZ")
      .onChange(this.update);
    objectScaleRow.addChild(
      new UIText("20%", this.strings.getKey("sidebar/object/scale"))
    );
    objectScaleRow.addChild(
      this.objectScaleX,
      this.objectScaleY,
      this.objectScaleZ
    );
    this.objectTransformCol.addChild(objectScaleRow);
    this.objProperties["transformation"] = this.objectTransformCol;
    this.objProperties["udTranslate"] = objectPositionRow; //ud - userData [ use only for hide specific things ]
    this.objProperties["udScale"] = objectScaleRow;
    this.objProperties["udRotate"] = objectRotationRow;

    this.uiContainer.addChild(this.objectTransformCol);

    // Color
    var objectColorRow = new UIRow();
    this.objectColor = new UIColor().onChange(this.update);
    objectColorRow.addChild(
      new UIText("20%", this.strings.getKey("sidebar/object/color"))
    );
    objectColorRow.addChild(this.objectColor);
    this.uiContainer.addChild(objectColorRow);
    this.objProperties["color"] = objectColorRow;

    /* // Visible
        var objectVisibleRow = new UIRow();
        var uiCheckCont = new UIPanel();
        uiCheckCont.addClass('wsCheckCont');
        this.objectVisible = new UICheckbox(true).onChange(this.update);
        objectVisibleRow.addChild(new UIText('20%', this.strings.getKey('sidebar/object/visible')));
        uiCheckCont.addChild(this.objectVisible)
        objectVisibleRow.addChild(uiCheckCont);
        this.uiContainer.addChild(objectVisibleRow);
        this.objProperties['visible'] = objectVisibleRow;
        */

    // Show As Icon
    var objectShowIconCol = new UICol();
    objectShowIconCol.addChild(
      new UITextHeader("100%", this.strings.getKey("sidebar/object/showAsIcon"))
    );

    var uiImgContRow = new UIRow();
    var uiCheckAsImage = new UIPanel();
    uiCheckAsImage.addClass("wsCheckCont");
    this.objectAsImage = new UIRadioButton(true, "showAsIcon")
      .setId("radio_showDirect")
      .onChange((e) => {
        const val = e.target.checked;
        this.objectAsImage.setValue(val);
        this.update(e);
      });
    uiCheckAsImage.addChild(this.objectAsImage);
    uiImgContRow.addChild(uiCheckAsImage);
    uiImgContRow.addChild(new UIText("80%", "Display content directly on map"));
    objectShowIconCol.addChild(uiImgContRow);

    var uiIconContRow = new UIRow();
    var uiCheckAsIcon = new UIPanel();
    uiCheckAsIcon.addClass("wsCheckCont");
    this.objectAsIcon = new UIRadioButton(false, "showAsIcon")
      .setId("radio_showIcon")
      .onChange((e) => {
        const val = e.target.checked;
        this.objectAsIcon.setValue(val);
        this.update(e);
      });
    uiCheckAsIcon.addChild(this.objectAsIcon);
    uiIconContRow.addChild(uiCheckAsIcon);
    uiIconContRow.addChild(
      new UIText(
        "80%",
        "Display content as an icon (tap icon to view in the App)"
      )
    );
    objectShowIconCol.addChild(uiIconContRow);

    this.uiContainer.addChild(objectShowIconCol);
    this.objProperties["showAsIcon"] = objectShowIconCol;

    //Dynamic Viewing
    const uiDynamicViewRow = new UIRow().addClass("ws_uiDynamicCont");
    const uiDynamicPanel = new UIPanel().addClass("ws_uiDynamicCont--textCont");
    uiDynamicPanel.addChild(
      new UIText("80%", "Dynamic Viewing").setStyle("marginRight", "0px")
    );
    //ToolTip
    const uiDVHelpCont = new UIPanel()
      .addClass("ws_uiDynamicCont--helpCont")
      .onClick(() => this.editor.onToggleInfoModals("DynamicView", true));
    uiDVHelpCont.addChild(
      new UITooltip(
        "Dynamic viewing enables the direction of the object to continuously aim towards the visitor as they move through the experience.",
        "ws_tooltipInfo ws_tooltipInfoExt"
      )
    );
    uiDVHelpCont.addChild(
      new UIImage(HelpIcon, "25px", "25px").setStyle("marginLeft", "6px")
    );
    uiDynamicPanel.addChild(uiDVHelpCont);

    // uiDynamicPanel.addChild(new UIImage(HelpIcon, '25px', '25px', 'help').setStyle('marginLeft', '6px').onClick(() => this.editor.onToggleInfoModals('DynamicView', true)))
    uiDynamicViewRow.addChild(uiDynamicPanel);
    this.objectDynamicViewState = new UIText("10%", "OFF");
    this.objectDynamicViewSwitch = new UISwitch(
      false,
      true,
      "ws_uiSliderDynamicView"
    ).onChange((e) => {
      const value = e.target.checked;
      this.objectDynamicViewState.setTextContent(value ? "ON" : "OFF");
      //AutoSave Tirgger!
      const delayAutoSave = !(
        this.editor.selectedObject &&
        "navigationMode" in this.editor.selectedObject.userData
      );
      this.editor.onCommand(
        new SetUserDataValue(
          this.editor,
          this.editor.selectedObject,
          "autoRotate",
          value,
          delayAutoSave
        )
      );
    });
    uiDynamicViewRow.addChild(this.objectDynamicViewState);
    uiDynamicViewRow.addChild(this.objectDynamicViewSwitch);
    this.uiContainer.addChild(uiDynamicViewRow);
    this.objProperties["autoRotate"] = uiDynamicViewRow;

    //AutoPlay
    var objectAudioAutoplayRow = new UIRow();
    var uiAutoplayCheckCont = new UIPanel();
    uiAutoplayCheckCont.addClass("wsCheckCont");
    this.objectAudioAutoplay = new UICheckbox(true)
      .setId("checkbox_autoplay")
      .onChange((e) => {
        const val = e.target.checked;
        this.objectAudioAutoplay.setValue(val);
        this.update(e);
      });
    objectAudioAutoplayRow.addChild(
      new UIText("24%", this.strings.getKey("sidebar/object/autoplay"))
    );
    uiAutoplayCheckCont.addChild(this.objectAudioAutoplay);
    objectAudioAutoplayRow.addChild(uiAutoplayCheckCont);
    this.uiContainer.addChild(objectAudioAutoplayRow);
    this.objProperties["autoplay"] = objectAudioAutoplayRow;

    //Download Images!
    const uiDownloadToggleRow = new UIRow().addClass("ws_uiDynamicCont");
    const uiDownloadPanel = new UIPanel().addClass(
      "ws_uiDynamicCont--textCont"
    );
    uiDownloadPanel.addChild(
      new UIText("65%", "Add download button").setStyle("marginRight", "0px")
    );
    //ToolTip
    const uiHelpCont = new UIPanel().addClass("ws_uiDynamicCont--helpCont");
    uiHelpCont.addChild(
      new UITooltip(
        "This will add a download button for viewers to save the image onto their device.",
        "ws_tooltipInfo"
      )
    );
    uiHelpCont.addChild(
      new UIImage(HelpIcon, "25px", "25px").setStyle("marginLeft", "6px")
    );
    uiDownloadPanel.addChild(uiHelpCont);

    uiDownloadToggleRow.addChild(uiDownloadPanel);
    this.objectDownloadState = new UIText("10%", "OFF");
    this.objectDownloadSwitch = new UISwitch(
      false,
      true,
      "ws_uiSliderAutoDownload"
    ).onChange((e) => {
      const value = e.target.checked;
      this.objectDownloadState.setTextContent(value ? "ON" : "OFF");
      //AutoSave Tirgger!
      const delayAutoSave = !(
        this.editor.selectedObject &&
        "navigationMode" in this.editor.selectedObject.userData
      );
      this.editor.onCommand(
        new SetUserDataValue(
          this.editor,
          this.editor.selectedObject,
          "canDownload",
          value,
          delayAutoSave
        )
      );
    });
    uiDownloadToggleRow.addChild(this.objectDownloadState);
    uiDownloadToggleRow.addChild(this.objectDownloadSwitch);
    this.uiContainer.addChild(uiDownloadToggleRow);
    this.objProperties["canDownload"] = uiDownloadToggleRow;

    // Description
    var objectDescRow = new UICol().addClass("ws_uiTextContentCol");
    this.objectDescInput = new UIInputTextArea(
      "objectName",
      "100%",
      undefined,
      100,
      "Description",
      "4",
      "forText"
    ).onChange((e) => {
      var value = e.target.value;
      if (value.length <= 0) {
        this.editor.callbacks.generateAlert({
          msg: `Object description shouldn't be empty!`,
          alertType: "information",
        });
      } else if (
        "MetaObject" in this.editor.selectedObject.userData &&
        this.editor.selectedObject.userData["MetaObject"] === true
      ) {
        this.editor.trigger("MetaObjectChanged", [
          this.editor.selectedObject,
          "description",
          value,
        ]);
      } else {
        const delayAutoSave = !(
          this.editor.selectedObject.name.includes("wayPoint") ||
          (this.editor.selectedObject &&
            "navigationMode" in this.editor.selectedObject.userData)
        );
        this.editor.onCommand(
          new SetUserDataValue(
            this.editor,
            this.editor.selectedObject,
            "description",
            value,
            delayAutoSave
          )
        );
      }
    });
    const objectHeaderRow = new UIRow().addClass("ws_uiTextContentRow");
    objectHeaderRow.addChild(
      new UIText(undefined, this.strings.getKey("sidebar/object/desc"))
    );
    objectDescRow.addChild(objectHeaderRow);
    objectDescRow.addChild(this.objectDescInput);
    this.uiContainer.addChild(objectDescRow);
    this.objProperties["description"] = objectDescRow;

    //Adjust Access Btn
    var objectAdjusAccessCol = new UICol();
    const uiAdjustAccessBtn = new UIButton("Adjust Access Point").onClick(
      () => {
        this.editor.trigger("toggleAdjustAccess", [
          true,
          this.editor.selectedObject,
        ]);
      }
    );
    objectAdjusAccessCol.addChild(uiAdjustAccessBtn);
    this.uiContainer.addChild(objectAdjusAccessCol);
    this.objProperties["accessAdjust"] = objectAdjusAccessCol;

    // Audio
    var objectAudioRow = new UICol();
    this.objectAudio = new UIAudio();
    objectAudioRow.addChild(
      new UIText("100%", this.strings.getKey("sidebar/object/audio"))
    );
    objectAudioRow.addChild(this.objectAudio);
    this.uiContainer.addChild(objectAudioRow);
    this.objProperties["audioLink"] = objectAudioRow;

    // Video
    var objectVideoRow = new UICol();
    this.objectVideo = new UIVideo();
    objectVideoRow.addChild(
      new UIText("100%", this.strings.getKey("sidebar/object/video"))
    );
    objectVideoRow.addChild(this.objectVideo);
    this.uiContainer.addChild(objectVideoRow);
    this.objProperties["videoLink"] = objectVideoRow;

    // MaterialColor
    var objectMatColorRow = new UICol();
    this.objectMatColor = new UIColor()
      .addClass("ws_uiColorMod")
      .onChange(this.update);
    objectMatColorRow.addChild(
      new UITextHeader(
        "100%",
        this.strings.getKey("sidebar/object/color")
      ).setStyle("fontWeight", "400 !important")
    );
    var uiMatColorRow = new UIRow().addClass("ws_uiTextColorRow");
    uiMatColorRow.addChild(this.objectMatColor);
    this.matColorValueInput = new UIText(
      "max-content",
      this.objectMatColor.getValue()
    ).addClass("ws_uiTextColorValue");
    uiMatColorRow.addChild(this.matColorValueInput);
    objectMatColorRow.addChild(uiMatColorRow);
    this.uiContainer.addChild(objectMatColorRow);
    this.objProperties["matColor"] = objectMatColorRow;

    // Hyperlink Labek
    var objectHNameRow = new UICol().addClass("ws_uiTextContentCol");
    this.objectHNameInput = new UIInputText(
      "objectHLinkName",
      "100%",
      undefined,
      30,
      "Hyperlink",
      "forText"
    ).onChange((e) => {
      var value = e.target.value;
      const delayAutoSave = !(
        this.editor.selectedObject &&
        "navigationMode" in this.editor.selectedObject.userData
      );
      this.editor.onCommand(
        new SetUserDataValue(
          this.editor,
          this.editor.selectedObject,
          "hyperlinkName",
          value,
          delayAutoSave
        )
      );
    });
    const objectHNameLinkHeaderRow = new UIRow().addClass(
      "ws_uiTextContentRow"
    );
    objectHNameLinkHeaderRow.addChild(
      new UIText(undefined, this.strings.getKey("sidebar/object/hName"))
    );
    objectHNameRow.addChild(objectHNameLinkHeaderRow);
    objectHNameRow.addChild(this.objectHNameInput);
    this.uiContainer.addChild(objectHNameRow);
    this.objProperties["hyperlinkName"] = objectHNameRow;

    // Hyperlink
    var objectHLinkRow = new UICol().addClass("ws_uiTextContentCol");
    this.objectHLinkInput = new UIInputTextArea(
      "objectHLinkName",
      "100%",
      undefined,
      10000,
      "Hyperlink",
      "4",
      "forText"
    ).onChange((e) => {
      var value = e.target.value;
      const delayAutoSave = !(
        this.editor.selectedObject &&
        "navigationMode" in this.editor.selectedObject.userData
      );
      this.editor.onCommand(
        new SetUserDataValue(
          this.editor,
          this.editor.selectedObject,
          "hyperlink",
          value,
          delayAutoSave
        )
      );
    });
    const objectHLinkHeaderRow = new UIRow().addClass("ws_uiTextContentRow");
    objectHLinkHeaderRow.addChild(
      new UIText(undefined, this.strings.getKey("sidebar/object/hLink"))
    );
    objectHLinkRow.addChild(objectHLinkHeaderRow);
    objectHLinkRow.addChild(this.objectHLinkInput);
    this.uiContainer.addChild(objectHLinkRow);
    this.objProperties["hyperlink"] = objectHLinkRow;

    // Material Skin Color
    var objectMatSkinColorRow = new UICol();
    this.objectMatPathColor = new UIColorPalette().onChange(() => {
      if (
        "MetaObject" in this.editor.selectedObject.userData &&
        this.editor.selectedObject.userData["MetaObject"] === true
      ) {
        this.editor.trigger("MetaObjectChanged", [
          this.editor.selectedObject,
          "pathColor",
          this.objectMatPathColor.getValue(),
        ]);
      } else {
        this.update();
      }
    });
    objectMatSkinColorRow.addChild(
      new UITextHeader(
        "100%",
        this.strings.getKey("sidebar/object/color")
      ).setStyle("fontWeight", "400 !important")
    );
    objectMatSkinColorRow.addChild(this.objectMatPathColor);
    this.uiContainer.addChild(objectMatSkinColorRow);
    this.objProperties["pathColor"] = objectMatSkinColorRow;

    // Nav Style
    const uiHeaderRowNav = new UICol();
    const uiPanelNav = new UIRow();
    uiPanelNav.addChild(new UITextHeader(undefined, 'Navigation Style'));
    uiHeaderRowNav.addChild(uiPanelNav);
    this.objectNavStyleSelector = new UIPinNavSelector('tour').onChange((e) => {
      const value = this.objectNavStyleSelector.getValue();
      this.editor.trigger('MetaObjectChanged', [this.editor.selectedObject, 'navigationStyle', value]);
    });
    uiHeaderRowNav.addChild(this.objectNavStyleSelector);
    this.uiContainer.addChild(uiHeaderRowNav);
    this.objProperties['navigationStyle'] = uiHeaderRowNav;

    //Object Animations
    var objectAnimationsCol = new UICol();
    objectAnimationsCol.addChild(
      new UIRow().addChild(new UITextHeader("100%", "Animations"))
    );
    this.objectAnimations = new UIAnimations(this.editor);
    objectAnimationsCol.addChild(this.objectAnimations);
    this.uiContainer.addChild(objectAnimationsCol);
    this.objProperties["animations"] = objectAnimationsCol;

    //Object QR Code
    var objectQRCodeCol = new UICol();
    objectQRCodeCol.addChild(
      new UIRow().addChild(new UITextHeader("100%", "QR Code"))
    );
    this.objectQRCode = new UIQRCode(this.editor.mapCode, this.editor.mapData);
    objectQRCodeCol.addChild(this.objectQRCode);
    this.uiContainer.addChild(objectQRCodeCol);
    this.objProperties["qrcodeview"] = objectQRCodeCol;

    //thumbnail
    var objectThumbnailCol = new UICol();
    this.objectThumbnailHeader = new UITextHeader("100%", "Thumbnail");
    objectThumbnailCol.addChild(
      new UIRow().addChild(this.objectThumbnailHeader)
    );
    // create UIImageInput
    this.objectImageInput = new UIImageInput("Thumbnail").onChange(() => {
      scope.editor.trigger("MetaObjectChanged", [
        this.editor.selectedObject,
        "texture",
        this.objectImageInput.getFile(),
      ]);
    });
    objectThumbnailCol.addChild(this.objectImageInput);
    this.uiContainer.addChild(objectThumbnailCol);
    this.objProperties["texture"] = objectThumbnailCol;

    //floorplan Image
    var objectFloorplanAsset = new UIPanel();
    this.objectFPAssetInput = new UIFloorplanImgInput("Floorplan").onChange(
      () => {
        if (this.objectFPAssetInput.getError() === null) {
          this.editor.trigger("ReplaceFloorplan", [
            this.editor.selectedObject,
            "assetLink",
            this.objectFPAssetInput.getFile(),
          ]);
        } else {
          this.editor.callbacks.generateAlert({
            msg: "File Size limit exceeded! [Upload Limit : 20MB]",
            alertType: "information",
          });
          this.objectFPAssetInput.resetError();
        }
      }
    );
    objectFloorplanAsset.addChild(this.objectFPAssetInput);
    this.uiContainer.addChild(objectFloorplanAsset);
    this.objProperties["assetLink"] = objectFloorplanAsset;

    // Img Switches
    var objectIAPSwitchCol = new UICol().addClass("ws_uiCol--mBottom");
    objectIAPSwitchCol.addChild(new UISeparator("ws_uiSeparatorHor"));
    const uiDefRow = new UIRow();
    uiDefRow.addChild(new UITextHeader("90%", "Default Image Access Point"));
    uiDefRow.addChild(
      new UIImage(HelpIcon, "25px", "25px", "help")
        .setStyle("marginLeft", "10px")
        .onClick(() =>
          this.editor.onToggleInfoModals("Location", true, "imageAccess")
        )
    );
    objectIAPSwitchCol.addChild(uiDefRow);
    const uiIAPToggleRow = new UIRow();
    uiIAPToggleRow.addChild(new UIText("70%", "Default Image Access Point"));
    this.objectIAPDefaultState = new UIText("10%", "OFF");
    this.objectIAPDefaultSwitch = new UISwitch(
      false,
      true,
      "ws_uiSliderIAP"
    ).onChange((e) => {
      const value = e.target.checked;
      const object = this.editor.selectedObject;
      this.objectIAPDefaultSwitch.setValue(value);
      this.objectIAPDefaultState.setTextContent(value ? "ON" : "OFF");
      this.objectIAPDefaultRadio.setValue(value);
      //dispatch update signal! [ ON/OFF signal resp with object! ]
      this.editor.trigger("MetaObjectChanged", [
        object,
        "defaultIAPState",
        value,
      ]);
    });
    uiIAPToggleRow.addChild(this.objectIAPDefaultState);
    uiIAPToggleRow.addChild(this.objectIAPDefaultSwitch);
    objectIAPSwitchCol.addChild(uiIAPToggleRow);

    const uiIAPRadioRow = new UIRow();
    var uiCheckIsPrimary = new UIPanel();
    uiCheckIsPrimary.addClass("wsCheckCont");
    this.objectIAPDefaultRadio = new UICheckbox(false)
      .setId("checkbox-setDefault")
      .onChange((e) => {
        const object = this.editor.selectedObject;
        const val = e.target.checked;
        this.objectIAPDefaultRadio.setValue(val);
        if (
          object.userData.primaryAccess !== undefined &&
          object.userData.primaryAccess !==
            this.objectIAPDefaultRadio.getValue()
        ) {
          this.editor.trigger("MetaObjectChanged", [
            object,
            "isPrimaryAccess",
            this.objectIAPDefaultRadio.getValue(),
          ]);
        }
      });
    uiCheckIsPrimary.addChild(this.objectIAPDefaultRadio);
    uiIAPRadioRow.addChild(uiCheckIsPrimary);
    uiIAPRadioRow.addChild(
      new UIText("80%", "Select as the default image access point")
    );
    objectIAPSwitchCol.addChild(uiIAPRadioRow);

    this.uiContainer.addChild(objectIAPSwitchCol);
    this.objProperties["primaryAccess"] = objectIAPSwitchCol;

    // IAP & QR dirrection & dimension
    var objectAccessDimDirCol = new UICol().addClass("ws_uiCol--mBottom");
    objectAccessDimDirCol.addChild(new UISeparator("ws_uiSeparatorHor"));
    const uiDirRow = new UIRow();
    uiDirRow.addChild(new UIText("60%", "Surface Direction: "));
    this.objectAccessDirection = new UITextHeader("40%", "").setStyle(
      "textTransform",
      "capitalize"
    );
    uiDirRow.addChild(this.objectAccessDirection);
    objectAccessDimDirCol.addChild(uiDirRow);
    const uiDimRow = new UIRow();
    uiDimRow.addChild(new UIText("40%", "Dimension: "));
    this.objectAccessDimension = new UITextHeader("60%", "");
    uiDimRow.addChild(this.objectAccessDimension);
    objectAccessDimDirCol.addChild(uiDimRow);
    var objectAccessWarningCol = new UIUserWarningCard(
      "Note: Edit surface direction and display in ARway App."
    );
    objectAccessDimDirCol.addChild(objectAccessWarningCol);
    this.uiContainer.addChild(objectAccessDimDirCol);
    this.objProperties["accessPointDimDir"] = objectAccessDimDirCol;


    //Attach File
    const uiFileCol = new UICol()
    uiFileCol.addChild(new UIText('100%', 'Attach file (Optional)'))
    uiFileCol.addChild(new UIText('100%', 'Max: 5MB'))
    uiFileCol.addChild(new UIText('100%', 'File types: PNG or JPG'))
    this.objectFileInput = new UIFileInput('image', 'images').onChange(() => {
      const file = this.objectFileInput.getFile();
      if(file){
        this.editor.trigger('MetaObjectChanged', [this.editor.selectedObject, 'file', file, false, 1]);
      } else{
        this.editor.trigger('MetaObjectChanged', [this.editor.selectedObject, 'file', file, false, 0]);
        if(this.objectFileInput.getError()) {
          this.objectFileInput.resetError();
          this.editor.editor3d.callbacks.generateAlert({ msg: 'Selected Invalid File Type!', alertType: 'information' });
          this.objectFileInput.setSrc(null);
        }
      }
    });
    uiFileCol.addChild(this.objectFileInput)
    this.uiContainer.addChild(uiFileCol);
    this.objProperties['file'] = uiFileCol;

    //Save Btn
    var objectSaveQRCol = new UICol();
    const uiSaveBtn = new UIButton("Save")
      .setId("ws_uiButtonSaveAccessPoint")
      .onClick(() => {
        this.editor.trigger("SaveMetaObject");
      });
    objectSaveQRCol.addChild(uiSaveBtn);
    this.uiContainer.addChild(objectSaveQRCol);
    this.objProperties["qrAnchor"] = objectSaveQRCol;

    //Adjust Floor Btn
    var objectAdjusFloorCol = new UICol();
    this.uiAdjustBtn = new UIButton("Adjust Floor plan").onClick(() => {
      this.editor.trigger("toggleAdjustObject", [
        true,
        this.editor.selectedObject,
      ]);
    });
    objectAdjusFloorCol.addChild(this.uiAdjustBtn);
    this.uiContainer.addChild(objectAdjusFloorCol);
    this.objProperties["floorAdjust"] = objectAdjusFloorCol;

    // user Warining FLoorplan
    var objectFloorWarningCol = new UIUserWarningCard(
      "Note: Open the ARway App to align the floor plan or reposition pegs"
    );
    this.uiContainer.addChild(objectFloorWarningCol);
    this.objProperties["floorWarning"] = objectFloorWarningCol;

    this.dom = this.uiContainer.dom;
  };

  updateUIRows = (object) => {
    for (var property in this.objProperties) {
      var uiEl = this.objProperties[property];
      if (this.userDataProperties.indexOf(property) !== -1) {
        uiEl.setDisplay(object.userData[property] !== undefined ? "" : "none");
      } else if (property === "transformation") {
        uiEl.setDisplay(object.userData[property] !== undefined ? "none" : "");
      } else if (property === "matColor") {
        uiEl.setDisplay(object.userData.type === "text" ? "" : "none");
      } else if (
        property === "udTranslate" ||
        property === "udScale" ||
        property === "udRotate"
      ) {
        // to hide specific rows
        uiEl.setDisplay(object.userData[property] !== undefined ? "none" : "");
      } else {
        uiEl.setDisplay(object[property] !== undefined ? "" : "none");
      }
    }
  };

  updateUIData = (object) => {
    const preventTransformations = object instanceof Line2;
    let preventRotateTransform = preventTransformations;
    if ("rotateOn" in object.userData) {
      preventRotateTransform = true;
    }

    const preventEvents =
      object.userData.type === "floorplanPeg" ||
      "interactive2D" in object.userData;

    const type = object.userData.type === "wayPointGroup" ? "Guided Tour" : object.userData.type

    this.objectType.setTextContent(type);
    this.objectNameInput.setValue(object.name).setReadOnly(preventEvents);

    this.objectPosX
      .setValue(object.position.x)
      .setReadOnly(preventTransformations);
    this.objectPosY
      .setValue(object.position.y)
      .setReadOnly(preventTransformations);
    this.objectPosZ
      .setValue(object.position.z)
      .setReadOnly(preventTransformations);

    this.objectRotX
      .setValue(object.rotation.x * THREE.MathUtils.RAD2DEG)
      .setReadOnly(preventRotateTransform);
    this.objectRotY
      .setValue(object.rotation.y * THREE.MathUtils.RAD2DEG)
      .setReadOnly(preventTransformations);
    this.objectRotZ
      .setValue(object.rotation.z * THREE.MathUtils.RAD2DEG)
      .setReadOnly(preventRotateTransform);

    this.objectScaleX
      .setValue(object.scale.x)
      .setReadOnly(preventTransformations);
    this.objectScaleY
      .setValue(object.scale.y)
      .setReadOnly(preventTransformations);
    this.objectScaleZ
      .setValue(object.scale.z)
      .setReadOnly(preventTransformations);

    if (object.color !== undefined) {
      this.objectColor.setHexValue(object.color.getHexString());
    }

    /* if(object.visible !== undefined) {
        this.objectVisible.setValue(object.visible);
    } */

    if (object.userData.description !== undefined) {
      this.objectDescInput
        .setValue(object.userData.description)
        .setReadOnly(preventEvents);
    }

    if (object.userData.audioLink !== undefined) {
      this.objectAudio.setSrcAttribute(object.userData.audioLink);
    }

    if (object.userData.videoLink !== undefined) {
      this.objectVideo.setSrcAttribute(object.userData.videoLink);
    }

    if (object.userData.animations !== undefined) {
      this.objectAnimations.setAnimations(object, object.userData.animations);
    }

    if (
      object.userData.qrcodeview !== undefined &&
      !this.objectQRCode.isRendered
    ) {
      this.objectQRCode.setValue(
        object.userData.id,
        this.editor.mapData.metadata
      );
    }

    if (object.userData.texture !== undefined) {
      this.objectImageInput.setSrc(object.userData.texture);
    }

    if (object.userData.assetLink !== undefined) {
      this.objectFPAssetInput.setSrc(object.userData.assetLink);
      this.objectFPAssetInput.setAligned(object.userData.alignmentStatus);
    }

    if (object.userData.primaryAccess !== undefined) {
      this.objectIAPDefaultRadio.setValue(object.userData.primaryAccess);
      const defIAP = this.editor.checkDefaultIAP();
      this.objectIAPDefaultSwitch.setValue(defIAP);
      this.objectIAPDefaultState.setTextContent(defIAP ? "ON" : "OFF");
    }

    if (object.userData.autoRotate !== undefined) {
      this.objectDynamicViewSwitch.setValue(object.userData.autoRotate);
      this.objectDynamicViewState.setTextContent(
        object.userData.autoRotate ? "ON" : "OFF"
      );
    }

    if (object.userData.canDownload !== undefined) {
      this.objectDownloadSwitch.setValue(object.userData.canDownload);
      this.objectDownloadState.setTextContent(
        object.userData.canDownload ? "ON" : "OFF"
      );
    }

    if (object.userData.autoplay !== undefined) {
      this.objectAudioAutoplay.setValue(object.userData.autoplay);
    }

    if (object.userData.type === "text") {
      this.objectMatColor.setHexValue(
        object.children[0].material.color.getHexString()
      );
      this.matColorValueInput.setTextContent(this.objectMatColor.getValue());
    }

    if (object.userData.hyperlinkName !== undefined) {
      this.objectHNameInput.setValue(object.userData.hyperlinkName);
    }

    if (object.userData.hyperlink !== undefined) {
      this.objectHLinkInput.setValue(object.userData.hyperlink);
    }

    if (object.userData.accessPointDimDir !== undefined) {
      this.objectAccessDirection.setTextContent(object.userData.direction);
      this.objectAccessDimension.setTextContent(
        `${
          isObject(object.userData.dimension)
            ? `(${Math.round(
                object.userData.dimension.sizeX * 100
              )} X ${Math.round(object.userData.dimension.sizeY * 100)} Cm)`
            : `${object.userData.dimension}`
        }`
      );
    }

    if (object.userData.showAsIcon !== undefined) {
      if (object.userData.showAsIcon === false) {
        this.objectAsImage.setValue(true);
        this.objectAsIcon.setValue(false);
      } else {
        this.objectAsImage.setValue(false);
        this.objectAsIcon.setValue(true);
      }
    }

    if (object.userData.type === "floorplan") {
      if (!object.userData.alignmentStatus) {
        this.uiAdjustBtn.setDisabled(true);
        this.uiAdjustBtn.addClass("ws_uiButton--grayedDisabled");
      } else {
        this.uiAdjustBtn.setDisabled(false);
        this.uiAdjustBtn.removeClass("ws_uiButton--grayedDisabled");
      }
    }

    if (object.userData.pathColor !== undefined) {
      this.objectMatPathColor.updatePaletteToNoWhite(
        object.userData?.type === "mapMarker"
      );
      this.objectMatPathColor.setValue(object.userData.pathColor);
    }

    if (object.userData.navigationStyle !== undefined) {
      this.objectNavStyleSelector.setValue(object.userData.navigationStyle);
    }

    if (object.userData.file !== undefined) {
      this.objectFileInput.setContent(object.userData.file);
    }

    if (object.userData.image !== undefined) {
      this.objectTourImageInput.setContent(object.userData.image)
    }

    if(object.userData.wheelchairAccessible !== undefined) {
      this.objectWheelchairAccessSwitch.setValue(object.userData.wheelchairAccessible)
      this.objectWheelchairAccessState.setTextContent(object.userData.wheelchairAccessible ? 'ON' : 'OFF');
    }

  };

  update = () => {
    var object = this.editor.selectedObject;

    if (object !== null) {
      //Position
      var newPos = new THREE.Vector3(
        this.objectPosX.getValue(),
        this.objectPosY.getValue(),
        this.objectPosZ.getValue()
      );
      const delayAutosave = !("metaFloorplan" in object.userData);
      if (object.position.distanceTo(newPos) >= 0.01) {
        this.editor.onCommand(
          new SetPosition(
            this.editor,
            object,
            newPos,
            object.position.clone(),
            delayAutosave
          )
        );
      }

      var newRot = new THREE.Euler(
        this.objectRotX.getValue() * THREE.MathUtils.DEG2RAD,
        this.objectRotY.getValue() * THREE.MathUtils.DEG2RAD,
        this.objectRotZ.getValue() * THREE.MathUtils.DEG2RAD
      );
      var vecOldRot = new THREE.Vector3(1, 1, 1).setFromEuler(object.rotation);
      var vecNewRot = new THREE.Vector3(1, 1, 1).setFromEuler(newRot);
      if (vecOldRot.distanceTo(vecNewRot) >= 0.01) {
        this.editor.onCommand(
          new SetRotation(
            this.editor,
            object,
            newRot,
            object.rotation.clone(),
            delayAutosave
          )
        );
      }

      var newScale = new THREE.Vector3(
        this.objectScaleX.getValue(),
        this.objectScaleY.getValue(),
        this.objectScaleZ.getValue()
      );
      if (object.scale.distanceTo(newScale) >= 0.01) {
        this.editor.onCommand(
          new SetScale(
            this.editor,
            object,
            newScale,
            object.scale.clone(),
            delayAutosave
          )
        );
      }

      if (
        object.userData &&
        "showAsIcon" in object.userData &&
        this.objectAsImage.getValue() &&
        object.userData.showAsIcon !== false
      ) {
        this.editor.onCommand(
          new SetUserDataValue(this.editor, object, "showAsIcon", false)
        );
      }

      if (
        object.userData &&
        "showAsIcon" in object.userData &&
        this.objectAsIcon.getValue() &&
        object.userData.showAsIcon !== true
      ) {
        this.editor.onCommand(
          new SetUserDataValue(this.editor, object, "showAsIcon", true)
        );
      }

      if (
        object.userData.autoplay !== undefined &&
        object.userData.autoplay !== this.objectAudioAutoplay.getValue()
      ) {
        this.editor.onCommand(
          new SetUserDataValue(
            this.editor,
            object,
            "autoplay",
            this.objectAudioAutoplay.getValue()
          )
        );
      }

      /* if(object.visible !== this.objectVisible.getValue()) {
                this.editor.onCommand(new SetVisible(this.editor, object, this.objectVisible.getValue()));
            } */

      if (
        object.color !== undefined &&
        object.color.getHex() !== this.objectColor.getHexValue()
      ) {
        this.editor.onCommand(
          new SetValue(
            this.editor,
            object,
            "color",
            this.objectColor.getHexValue()
          )
        );
      }

      if (
        object.userData.type === "text" &&
        object.children[0].material.color !== undefined &&
        object.children[0].material.color.getHex() !==
          this.objectMatColor.getHexValue()
      ) {
        this.editor.onCommand(
          new SetMaterialColor(
            this.editor,
            object.children[0],
            this.objectMatColor.getHexValue()
          )
        );
      }

      if (
        object.userData.pathColor !== undefined &&
        object.userData.pathColor !== this.objectMatPathColor.getValue()
      ) {
        const delayAutoSave = !(object && "navigationMode" in object.userData);
        this.editor.onCommand(
          new SetUserDataValue(
            this.editor,
            object,
            "pathColor",
            this.objectMatPathColor.getValue(),
            delayAutoSave
          )
        );
      }
    }
  };

  onEditSidebarObjectContents = (object) => {
    this.editor.trigger("MetaObjectChanged", [
      undefined,
      undefined,
      undefined,
      true,
    ]);
    if (
      this.editor.previousSelected &&
      this.editor.previousSelected.userData.audioLink
    )
      this.pauseAudio();
    if (
      this.editor.previousSelected &&
      this.editor.previousSelected.userData.videoLink
    )
      this.pauseVideo();
    if (
      this.editor.previousSelected &&
      this.editor.previousSelected.userData.animations
    )
      this.objectAnimations.triggerStop();
    if (
      this.editor.previousSelected &&
      this.editor.previousSelected.userData.qrcodeview
    )
      this.objectQRCode.triggerClear();
    if (object !== null) {
      this.uiContainer.setDisplay("block");
      this.updateUIRows(object);
      this.updateUIData(object);
      this.editor.trigger("sidebarUpdateClassList", [true]);
    } else {
      this.uiContainer.setDisplay("none");
    }
  };

  onObjectSelected = () => {
    this.onEditSidebarObjectContents(null);
    this.editor.trigger("sidebarSceneGraphChanged", [null]);
  };

  onToggleMenuActive = (name) => {
    if (name !== null) {
      this.uiContainer.setDisplay("none");
    }
  };

  onToggleAdjustObject = (state, object) => {
    if (state) {
      this.onEditSidebarObjectContents(null);
    } else {
      this.onEditSidebarObjectContents(object);
    }
  };

  pauseAudio = () => {
    const audioPlayer = document.getElementById("ws_Audio");
    audioPlayer.src = "";
  };

  pauseVideo = () => {
    const videoPlayer = document.getElementById("ws_Video");
    videoPlayer.src = "";
  };

  onRefreshSidebarObjects = (object) => {
    // console.log("onRefreshSidebarObjects", object);
  };

  onObjectChanged = (object) => {
    if (object !== this.editor.selectedObject) return;
    this.updateUIData(object);
  };
}

export { SidebarObject };
