import { uploadAsset, deleteAsset } from "../../../../../features/common/commonSlice";

export const createAsset = (
  dispatch,
  file,
  contentType,
  assertType,
  mapId,
  cb
) => {
  let reqObj = new FormData();
  reqObj.append("file", file);
  reqObj.append("contentType", contentType);
  reqObj.append("mapId", mapId);
  contentType === "floorplans" && reqObj.append("compressFile", true);
  let data = {
    reqObj,
    isThumbnailUploading: true,
    assertType,
  };
  dispatch(uploadAsset(data)).then((resp) => {
    if(contentType === "floorplans") {
      cb(resp?.payload?.data?.compressedFile || resp?.payload?.data?.file);
    } else {
      cb(resp?.payload?.data?.file);
    }
  });
};

export const deleteAssetURL = (dispatch, fileURL, cb = null) => {
  dispatch(deleteAsset({ assetUrl: fileURL })).then((resp) => {
    if (cb) {
      cb()
    }
    console.log(resp)
  })
}