import * as THREE from 'three';
import EditorExperience from '../EditorExperience';
import EventEmitter from '../utils/EventEmitter';

// Random ID Gen
import { 
    getRandomIdCode,
    fromPosObjectToVec3Pos,
    // fromScaleObjectToVec3Scale,
} from './TransformConversions';
import { dispose } from '../utils/Dispose.Utils';

export default class Hotspot extends EventEmitter {
    constructor(hotspotData) {
        super();
        this.editor = new EditorExperience();
        this.hotspotData = hotspotData || {};
        this.sprite = null;
        this.annotation = null;
        this.position = hotspotData !== null && hotspotData.position ? fromPosObjectToVec3Pos(hotspotData.position) : new THREE.Vector3(0, 0, 0);
        // this.scale = hotspotData !== null && hotspotData.scale ? fromScaleObjectToVec3Scale(hotspotData.scale) : new THREE.Vector3(1, 1, 1);
        this.name = this.hotspotData.name ? this.hotspotData.name.substring(0, 20) : "Name";
        this.id = this.hotspotData.id || `hotspot_${getRandomIdCode()}`;
        this.description = this.hotspotData.description || "Description";
        this.hyperlinkName = hotspotData.hyperlinkName || '';
        this.hyperlink = hotspotData.hyperlink || ''
        this.visible = hotspotData.visible || true;

        this.setupSprite();

        this.editor.on('objectChanged', this.hotspotChanged);
        this.editor.on('objectRemoved', this.onObjectRemoved);
    }

    setupSprite = () => {

        const texture = new THREE.TextureLoader().load('/static/textures/hotspot.png');
        texture.colorSpace = THREE.SRGBColorSpace;
        const spriteMaterial = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
            depthTest: false,
            depthWrite: false,
        });
        const hotspotSprite = new THREE.Sprite(spriteMaterial);
        hotspotSprite.scale.set(0.25, 0.25, 0.25);
        hotspotSprite.name = this.name;

        this.sprite = new THREE.Group();
        this.sprite.add(hotspotSprite)

        this.sprite.name = this.name;
        this.sprite.userData['description'] = this.description;
        this.sprite.userData['hyperlinkName'] = this.hyperlinkName;
        this.sprite.userData['hyperlink'] = this.hyperlink;
        this.sprite.userData['type'] = "hotspot";
        this.sprite.userData['skipChild'] = "hotspot";
        this.sprite.userData['id'] = this.id;
        this.sprite.userData['transform'] = "ONLY_TRANSLATE";
        this.sprite.userData['udScale'] = 'hotspot';
        this.sprite.userData['udRotate'] = 'hotspot';
        this.sprite.userData['visible'] = this.visible;
        this.sprite.visible = this.visible;
        this.sprite.position.copy(this.position);
        // this.sprite.scale.copy(this.scale);
    }

    hotspotChanged = (object) => {
        if(this.sprite === object) {
            // this.sprite.copy(object);
            this.name = object.name;
            this.description = object.userData.description;
            this.hyperlinkName = object.userData.hyperlinkName;
            this.hyperlink = object.userData.hyperlink;
            this.position.copy(object.position);
        }
    }

    onObjectRemoved = (object) => {
        if(object === this.sprite) {
            this.editor.stop('objectChanged', this.hotspotChanged);
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            dispose(this.sprite);
        }
    }
}