import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { isEmpty, isNull } from "lodash";
import { Box } from "@mui/material";

import { lastTEDays } from "../../../../../../_utils/DateUtils";
import DatePicker from "../../../../../../_utils/DatePicker";
import RenderLineChart from "../../../../../users/analytics/Render/RenderLineChart";
import Loader from '../../../../../../common/loader';

import { getUserAccountMapVisitsOvertime } from "../../../../../../../features/admin/analytics/adminAnalyticsSlice";

const AccountMapVisitsCard = () => {

    const dispatch = useDispatch();
    const [dateObj, setDateObj] = useState(lastTEDays);
    const [labels, setLabels] = useState(null);
    const [visits, setVisits] = useState(null);
    const [loading, setLoading] = useState(true);

    const selectedAccount = useSelector((state) => state.adminanalytics.activeAccount);
    const totalMapVisitsOvertime = useSelector((state) => state.adminanalytics.totalMapVisitsOvertime);

    useEffect(() => {
        if (!isEmpty(totalMapVisitsOvertime)) {
            setLabels(
                totalMapVisitsOvertime.map((visit) => new Date(`${visit.date}`))
            );
            setVisits(totalMapVisitsOvertime.map((visit) => visit.visits));
        } else {
            setLabels(null);
            setVisits(null);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalMapVisitsOvertime]);

    useEffect(() => {
        dispatch(getUserAccountMapVisitsOvertime({ ...dateObj, accountId: selectedAccount?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(selectedAccount && selectedAccount.id !== 'allAccounts') {
            setLoading(true);
            setDateObj(lastTEDays)
            dispatch(getUserAccountMapVisitsOvertime({ ...lastTEDays, accountId: selectedAccount?.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col-9">
                        <div className="analyticshome__anCard--contentRow--header">
                            Total Map Visits Over Time
                        </div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker
                                cb={(dateObj) => {
                                    setDateObj(dateObj);
                                    setLoading(true);
                                    dispatch(getUserAccountMapVisitsOvertime({ ...dateObj, accountId: selectedAccount?.id }));
                                }}
                            />
                        </Box>
                    </div>
                </div>
                <hr />
                {isNull(labels) || isNull(visits) ? (
                    <h5 className="text-center analyticshome__emptyStateText">
                        {" "}
                        No data available{" "}
                    </h5>
                ) : loading ? (
                    <div className='analyticshome__emptyTextCont'>
                        <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                    </div>
                ) : (
                    RenderLineChart(labels, visits, dateObj, "Number of Visits")
                )}
            </div>
        </div>
    );
};

export default AccountMapVisitsCard;
