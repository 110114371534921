import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { v4 } from  'uuid';
const initialState = [];

export const setNewAlert = createAsyncThunk('alerts/setNewAlert', async (data, { dispatch }) => {
    const id = v4();
    if(!data.toHold) setTimeout(() => dispatch(removeAlert(id)), 4000);
    return({id, ...data});
})

const commonSlice = createSlice({
    name: 'alerts',
    initialState,
    reducers: {
        removeAlert: (state, action) => {
            state.forEach(altr => {
                if(altr.id === action.payload && altr.toClose){
                    window.close();
                }
            });
            return(state.filter(alt => alt.id !== action.payload));
        },
        clearAlerts: (state, action) => {
            return([]);
        }
    },
    extraReducers: {
        [setNewAlert.fulfilled]: (state, action) => {
            return([...state, action.payload]);
        },
        'common/resetState': () => {
            return([...initialState]);
        },
    }
});

export const { removeAlert, clearAlerts } = commonSlice.actions;

export default commonSlice.reducer;