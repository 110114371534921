import EventEmitter from "../../utils/EventEmitter";
import { cloneDeep } from "lodash";
import importIcon from "../../../../assets/svgs/scenes/import_map.svg";

import { UIPanel, UIText, UIRow, UIImage, UIMapInfoCard } from "../ui.lib";

class SceneSidebarMaps extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;

        this.dom = null;

        this.editor.on("refreshBuildingMapsList", this.refreshUI);
        this.editor.resources.on("ready", this.onReady);

        this.initUI();
    }

    initUI = () => {
        this.uiCont = new UIPanel().addClass("wsSceneMapsContainer");

        this.uiHeaderRow = new UIRow().addClass("wsSceneMapHeaderRow");

        this.uiHeaderRow.addChild(
            new UIText("100%", "Floor Plan Maps").addClass("wsSceneTitle")
        );
        const uiImportImg = new UIImage(
            importIcon,
            "30px",
            "30px",
            "Import Maps"
        );
        uiImportImg.onClick((e) => {
            e.stopPropagation();
            this.editor.trigger("initImportMapsModal", [
                true,
                {
                    mapDetails: this.editor.mapData.metadata,
                    venueDetails: this.editor.venueDetails,
                },
            ]);
        });
        this.uiHeaderRow.addChild(uiImportImg);
        this.uiCont.addChild(this.uiHeaderRow);

        // init ui for maps
        if (this.editor.buildingData) {
            this.updateUIData();
        }

        this.dom = this.uiCont;
    };

    emptyContent = () => {
        var child = this.uiCont.dom.lastChild;
        while (child) {
            if (child === this.uiHeaderRow.dom) break;
            this.uiCont.dom.removeChild(child);
            child = this.uiCont.dom.lastChild;
        }
    };

    updateUIData = () => {
        if (this.editor?.buildingData?.length) {
            const uiCardsRow = new UIPanel();
            uiCardsRow.addClass("ws_uiCardsRowFloatMaps");
            this.emptyContent();
            this.editor.buildingData.forEach((bData, index) => {
                const position =
                    index === 0
                        ? "first"
                        : index === this.editor.buildingData.length - 1
                        ? "last"
                        : "middle";
                const uiMapCard = new UIMapInfoCard(
                    this.editor,
                    bData,
                    this.onMapSelect,
                    index,
                    this.editor.isFloorMap,
                    position
                );
                uiCardsRow.addChild(uiMapCard);
            });
            this.uiCont.addChild(uiCardsRow);
        }
    };

    onMapSelect = (content, eventType, action) => {
        // console.log(content, eventType, action);
        if (eventType === 3) {
            // switch to that map!
            if (this.editor.activeMap !== content.mapId) {
                this.editor.trigger("switchBetweenMaps", [content]);
            }
        } else if (eventType === 4) {
            let order = content.order;
            if (action === "moveUp") {
                if (content.order === 1) return;
                this.moveOrders(content, --order);
            } else if (action === "moveDown") {
                if (content.order === this.editor.buildingData.length) return;
                this.moveOrders(content, ++order);
            } else if (action === "edit") {
                this.editor.onToggleRenameVenueMapModal(true, content);
            }
        }
    };

    moveOrders = (toMove, toMoveOrder) => {
        const venueMaps = cloneDeep(this.editor.buildingData);
        const idxPrev = venueMaps.findIndex((d) => d.order === toMoveOrder);
        const idxChange = venueMaps.findIndex((d) => d.order === toMove.order);

        if (idxPrev !== -1 && idxChange !== -1) {
            let changeObj = cloneDeep(venueMaps[idxChange]);
            let prevObj = cloneDeep(venueMaps[idxPrev]);

            prevObj.order = changeObj.order;
            changeObj.order = toMoveOrder;

            venueMaps[idxChange] = changeObj;
            venueMaps[idxPrev] = prevObj;

            this.editor.buildingData = cloneDeep(venueMaps);

            let venueDetails = cloneDeep(this.editor.venueDetails);
            venueDetails.maps = this.editor.buildingData;

            this.editor.onUpdateVenueDetails(venueDetails);
        } else return;
    };

    refreshUI = () => {
        this.updateUIData();
    };

    onReady = () => {
        if (this.editor?.buildingData?.length) {
            const activeMap = this.editor?.buildingData?.find(
                (f) => f.mapId === this.editor.mapId
            );
            activeMap &&
                this.editor.trigger("setTopbarMapName", [activeMap.mapName]);
        }
    };
}

export { SceneSidebarMaps };
