import * as React from 'react';
import {
    Backdrop, Box, 
    Modal, Fade, 
    Button
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

const helperObjs = {
    update: {
        modalHeader: "Replace Floor Plan",
        modalText: "If the current floor plan is replaced, floor plan alignment progress will be lost. Use the ARway App to align the replacement floor plan.",
        modalSubText: "Do you want to replace the floor plan anyway?",
        successText: "Yes, replace"
    },
    delete: {
        modalHeader: "Delete Floor Plan",
        modalText: "Floor plan alignment progress will be lost if this floor plan is deleted. Floor plan alignment will need to be redone in the ARway App if another floor plan is uploaded.",
        modalSubText: "Do you want to delete the floor plan anyway?",
        successText: "Yes, delete"
    }
}

export default function FloorplanModal({openModal, onCloseModal, modalData, onSuccessModal}) {
    const {modalHeader, modalSubText, modalText, successText} = helperObjs[modalData?.type||'update'];
    
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal} id="button-messageClose"/>
                        <div className="mapdetails__mapModalHeader">{modalHeader}</div>
                        <div className="mapdetails__mapModalText">{modalText} </div>
                        {modalSubText && <div  className="mapdetails__mapModalSubText">{modalSubText}</div>}
                        <div className="mapdetails__mapModalBtnsDiv">
                            <Button variant='outlined' color='primary' onClick={onCloseModal} id="button-calcel">Cancel</Button>
                            <Button variant='contained' color='errorRed' onClick={() => onSuccessModal(modalData)} className='mapdetails__mapModalBtnText' id="button-success">{successText}</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
