import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
// import * as THREE from 'three';

import { UIButton, UIPanel, UILibraryCardAudio, UIText } from "../ui.lib";

class SidebarContentAudio extends EventEmitter {
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.dom = null;

        this.contentList = [];
        this.pageNumber = 1;

        this.contentState = "";
        this.contentType = "audios";

        this.initUI();

        this.editor.on("toggleMenuActive", this.onToggleMenuActive);
        this.editor.on("fetchLibraryContentsResponse", this.updateData);
    }

    initUI = () => {
        // create invisible Input
        const form = document.createElement("form");
        form.style.display = "none";
        document.body.appendChild(form);

        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.id = "AudioUploader";
        fileInput.accept = "audio/ogg, audio/wav, audio/mp3 ";
        fileInput.multiple = false;

        fileInput.addEventListener("change", () => {
            this.editor.loader.initObjectLoading(
                [...fileInput.files],
                "audios",
                "new"
            );
            form.reset();
        });

        form.appendChild(fileInput);

        this.uiMyContent = new UIPanel();

        this.uiUploadBtn = new UIButton("Upload Audio File").onClick(() => {
            if (this.editor.storageExceeds()) {
                this.editor.callbacks.generateAlert({
                    msg: "plan storage exceeded, please upgrade your storage to continue!",
                    alertType: "danger",
                });
            } else {
                fileInput.click();
            }
        });
        this.uiUploadBtn.addClass("ws_uiButton--paddedTop");

        this.uiMyContent.addChild(this.uiUploadBtn);

        this.dom = this.uiMyContent;
    };

    fetchData = (pageNumber) => {
        this.editor.trigger("fetchLibraryContentsRequest", [
            pageNumber,
            this.contentType,
        ]);
    };

    updateData = (data, contentType) => {
        if (this.contentType === contentType) {
            if (this.pageNumber === 1 && data.length === 0) {
                this.contentState = "NO DATA";
            } else if (data.length === 0) {
                this.contentState = "LAST PAGE";
            } else {
                this.contentList.push(...data);
                this.contentList = [
                    ...new Map(
                        this.contentList.map((item) => [item["id"], item])
                    ).values(),
                ];
                this.pageNumber++;

                if (data.length < 10) {
                    this.contentState = "LAST PAGE";
                }
            }
            this.updateContentLibraryUI();
        } else this.emptyContent();
    };

    emptyContent = () => {
        var child = this.uiMyContent.dom.lastChild;
        while (child) {
            if (child === this.uiUploadBtn.dom) break;
            this.uiMyContent.dom.removeChild(child);
            child = this.uiMyContent.dom.lastChild;
        }
    };

    updateContentLibraryUI = () => {
        if (this.contentState === "NO DATA") {
            this.emptyContent();
            const uiNoDataCard = document.createElement("div");
            uiNoDataCard.className = "ws_uiNoDataCard";

            // header
            const uiHeader = document.createElement("div");
            uiHeader.classList.add("ws_uiNDHeader");
            uiHeader.textContent = "No Uploads Yet";

            const uiSubText = document.createElement("div");
            uiSubText.classList.add("ws_uiNDSubText");
            uiSubText.textContent =
                "Your recently uploaded images will appear here.";

            uiNoDataCard.appendChild(uiHeader);
            uiNoDataCard.appendChild(uiSubText);

            this.uiMyContent.dom.appendChild(uiNoDataCard);
        } else {
            const uiCardsCol = new UIPanel();
            uiCardsCol.addClass("ws_uiCardsColFloat");
            this.emptyContent();
            for (const image of this.contentList) {
                const uiImageCont = new UILibraryCardAudio(
                    this.editor,
                    image,
                    image.path,
                    image.fileName,
                    this.onLibrayImageSelect
                );
                uiCardsCol.addChild(uiImageCont);
            }

            if (this.contentState !== "LAST PAGE") {
                const uiloadMoreBtn = new UIButton("Load More")
                    .onClick(() => {
                        this.fetchData(this.pageNumber);
                    })
                    .addClass("wsLoadMoreBtn");
                uiCardsCol.addChild(uiloadMoreBtn);
            }
            this.uiMyContent.addChild(
                new UIText(undefined, "Recently Uploaded")
            );
            this.uiMyContent.addChild(uiCardsCol);
        }
    };

    onLibrayImageSelect = (selectedContent) => {
        this.editor.loader.initObjectLoading(selectedContent);
    };

    refreshUI = () => {
        this.contentList = [];
        this.pageNumber = 1;
        this.fetchData(1);
        // check if user can upload
        this.updateBtnClasses();
    };

    updateBtnClasses = () => {
        if (this.editor.storageExceeds()) {
            this.uiUploadBtn.addClass("ws_uiButton--grayedButton");
        } else {
            this.uiUploadBtn.removeClass("ws_uiButton--grayedButton");
        }
    };

    onToggleMenuActive = () => {};

    unload = () => {
        const audioElArray = document.getElementsByClassName("ws_uiAudio");
        for (var i = 0; i < audioElArray.length; i++) {
            !audioElArray[i].paused && audioElArray[i].pause();
        }

        this.contentList = [];
        this.contentState = "";
        this.pageNumber = 1;
        this.emptyContent();
    };
}

export { SidebarContentAudio };
