import * as THREE from "three";
// loader
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";

import EventEmitter from "./EventEmitter";
import Image from "../threeUtils/Image";
import Audio from "../threeUtils/Audio";
import Model from "../threeUtils/Model";
import Video from "../threeUtils/Video";
import { encodeVideo } from "./FFMPEGLoader";
//Command
import { AddObject } from "../commands/AddObject";
import { RemoveObject } from "../commands/RemoveObject";

// Random ID Gen
import {
    fromVec3PosToPosObject,
    getRandomIdCode,
} from "../threeUtils/TransformConversions";
import {
    validateMIME,
    validateExt,
    validateSize,
    fileLimits,
} from "../../common/utils";
import Floorplan from "../threeUtils/Floorplan/Floorplan";
import Path from "../threeUtils/PinAndPath/PathGraph";
import Pin from "../threeUtils/PinAndPath/Pin";
import Connector from "../threeUtils/PinAndPath/Connector";
import Hotspot from "../threeUtils/Hotspot";
import { generateTextMesh } from "../threeUtils/Text";

class Loader extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;

        this.loaders = {};
        this.asyncObject = false;
        this.loadManager = new THREE.LoadingManager();

        this.intersectionPoint = new THREE.Vector3();
        this.raycaster = new THREE.Raycaster();

        this.startNavigationTrack = false;
        this.raycastObjects = [];
        this.anchorMesh = null;
        this.currentHeader = null;
        this.currentArgs = {};

        this.editor.on("asyncObjectLoaded", this.onAsyncObjectLoaded);

        this.initLoaders();
        this.setupAnchorGeometry();
    }

    initLoaders = () => {
        var dracoLoader = new DRACOLoader(this.loadManager);
        dracoLoader.setDecoderPath("/static/draco/");
        this.loaders.gltfLoader = new GLTFLoader(this.loadManager);
        this.loaders.gltfLoader.setDRACOLoader(dracoLoader);

        this.loaders.texLoader = new THREE.TextureLoader(this.loadManager);
        this.loaders.texLoader.setCrossOrigin("anonymous");
    };

    setupAnchorGeometry = () => {
        const size = 0.4;
        const anchorGeo = new THREE.PlaneGeometry(size, size, 32, 32);
        const tex = new THREE.TextureLoader().load(
            "/static/textures/anchor_point.png"
        );
        tex.colorSpace = THREE.SRGBColorSpace;
        const anchorMat = new THREE.MeshBasicMaterial({
            map: tex,
            side: THREE.DoubleSide,
            depthTest: false,
            depthWrite: false,
            transparent: true,
        });
        this.anchorMesh = new THREE.Mesh(anchorGeo, anchorMat);
        this.anchorMesh.renderOrder = 10000;
        this.anchorMesh.rotateX(THREE.MathUtils.degToRad(90));
    };

    setName = (fName, name, prevName) => {
        if (fName && fName.length > 0) {
            return fName.split(".")[0];
        } else if (name && name.length > 0) {
            return name;
        } else {
            return prevName;
        }
    };

    checkSize = (file, contentType) => {
        if (validateSize(file, contentType)) {
            return true;
        } else {
            this.editor.callbacks.generateAlert({
                msg: `File Size limit exceeded! [Upload Limit : ${parseInt(
                    fileLimits[contentType] / 1048576
                )}MB]`,
                alertType: "information",
            });
            return false;
        }
    };

    // assetTye can be preload or new
    initObjectLoading = (files, objectType = null, assetType = "preload") => {
        this.scene.children.forEach((child) => {
            if (
                child.userData &&
                child.userData.navHelper &&
                child.userData.navHelper === "floorplan" &&
                child.userData?.alignmentStatus === true
            ) {
                this.raycastObjects.push(child);
            }
        });

        if (this.raycastObjects.length > 0) {
            this.startNavigationTrack = true;
            this.editor.trigger("navigationTracking", [true]);
            this.editor.toAutoSave &&
                this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
            this.currentArgs = { files, objectType, assetType };

            this.layoutAnchor(0, new THREE.Vector3());
        } else {
            this.navigateObjectLoading(files, objectType, assetType);
        }
    };

    layoutAnchor = (index = -1, vec3Pos) => {
        this.currentHeader = this.cloneAnchorHeader(index);
        this.currentHeader.position.copy(vec3Pos);
        this.editor.addObjects(this.currentHeader);

        this.canvas.addEventListener("mousemove", this.onMouseMove, false);
        this.canvas.addEventListener("mousedown", this.onMouseDown, false);
        document.addEventListener("keydown", this.onKeyDownExit, false);
    };

    onMouseMove = (e) => {
        if (this.startNavigationTrack) {
            let viewportDown = new THREE.Vector2();
            const rect = this.canvas.getBoundingClientRect();
            viewportDown.x = ((e.offsetX - rect.left) / rect.width) * 2 - 1;
            viewportDown.y = -(((e.offsetY - rect.top) / rect.height) * 2) + 1;

            this.raycaster.setFromCamera(viewportDown, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(
                this.raycastObjects,
                true
            );

            if (intersects.length > 0) {
                const intersect = intersects[0];
                this.intersectionPoint.copy(intersect.point);
                this.currentHeader.position.copy(this.intersectionPoint);
            }
        }
    };

    withinBounds = (e) => {
        let viewportBound = new THREE.Vector2();
        let raycasterBound = new THREE.Raycaster();
        const rect = this.canvas.getBoundingClientRect();
        viewportBound.x = ((e.offsetX - rect.left) / rect.width) * 2 - 1;
        viewportBound.y = -(((e.offsetY - rect.top) / rect.height) * 2) + 1;

        raycasterBound.setFromCamera(viewportBound, this.camera.instance);
        const intersects = raycasterBound.intersectObjects(
            this.raycastObjects,
            true
        );

        return intersects.length > 0;
    };

    onMouseDown = (e) => {
        if (!this.withinBounds(e)) return;

        switch (e.which) {
            case 2:
                this.exitNavigationTracking();
                break;

            case 1:
                this.currentHeader.position.set(this.intersectionPoint);
                const tIntersectPt = this.intersectionPoint.clone();

                this.startNavigationTrack = false;
                this.editor.trigger("navigationTracking", [false]);
                !this.editor.toAutoSave &&
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                this.editor.removeObject(this.currentHeader, true);

                const { files, objectType, assetType } = this.currentArgs;
                this.navigateObjectLoading(
                    files,
                    objectType,
                    assetType,
                    tIntersectPt.add(new THREE.Vector3(0, .5, 0))
                );

                // common steps!
                this.currentHeader = null;
                this.canvas.removeEventListener(
                    "mousedown",
                    this.onMouseDown,
                    false
                );
                this.canvas.removeEventListener(
                    "mousemove",
                    this.onMouseMove,
                    false
                );
                document.removeEventListener(
                    "keydown",
                    this.onKeyDownExit,
                    false
                );

                break;

            default:
                break;
        }
    };

    onKeyDownExit = (e) => {
        if (e.keyCode === 27) {
            this.exitNavigationTracking();
        }
    };

    exitNavigationTracking = () => {
        this.editor.removeObject(this.currentHeader);
        this.startNavigationTrack = false;
        this.editor.trigger("navigationTracking", [false]);
        !this.editor.toAutoSave &&
            this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
        this.editor.deselect();
        this.canvas.removeEventListener("mousemove", this.onMouseMove, false);
        this.canvas.removeEventListener("mousedown", this.onMouseDown, false);
        document.removeEventListener("keydown", this.onKeyDownExit, false);
        this.currentArgs = null;
        this.currentHeader = null;
    };

    cloneAnchorHeader = () => {
        let header = this.anchorMesh.clone();
        const randID = getRandomIdCode();
        header.name = `node_${randID}`;
        header.userData["id"] = `node_${randID}`;
        return header;
    };

    navigateObjectLoading = (
        files,
        objectType,
        assetType,
        initialPosition = new THREE.Vector3()
    ) => {
        if (assetType === "new") {
            this.loadFiles(files, objectType, initialPosition);
        } else {
            this.loadLibraryFile(files, initialPosition);
        }
    };

    loadFiles = (files, objectType = null, initialPosition) => {
        for (let i = 0; i < files.length; i++) {
            if (objectType === "images" || objectType === "floorplans") {
                validateMIME(files[i], objectType, (flag) => {
                    let checkSizeBool = this.checkSize(files[i], objectType);
                    if (flag && checkSizeBool) {
                        objectType === "floorplans"
                            ? this.loadFloorplans(files[i], objectType)
                            : this.loadFile(
                                  files[i],
                                  objectType,
                                  initialPosition
                              );
                    } else {
                        checkSizeBool &&
                            this.editor.callbacks.generateAlert({
                                msg: `File/Mime type not supported`,
                                alertType: "information",
                            });
                        return;
                    }
                });
            } else {
                if (validateExt(files[i], objectType)) {
                    if (this.checkSize(files[i], objectType)) {
                        this.loadFile(files[i], objectType, initialPosition);
                    }
                } else {
                    this.editor.callbacks.generateAlert({
                        msg: `File/Mime type not supported`,
                        alertType: "information",
                    });
                    return;
                }
            }
        }
    };

    setProgress = (e) => {
        this.editor.trigger("updateProgressContent", [
            `${Math.round(100 * e.ratio)}%`,
        ]);
    };

    updateProgress = (data) => {
        this.editor.trigger("updateProgressContent", [
            `${Math.round((100 * data.loaded) / data.total)}%`,
        ]);
    };

    loadFile = async (file, objectType, initialPosition) => {
        let scope = this;
        let fileName = file.name;
        let fExt = fileName.split(".").pop().toLowerCase();
        let fName = fileName.split(".")[0];
        let reader = new FileReader();

        //Trigger the loading UI!
        this.editor.trigger("mountObjectLoader", [true]);

        switch (fExt) {
            case "png":
            case "jpeg":
            case "jpg":
                reader.addEventListener(
                    "load",
                    (e) => {
                        let content = e.target.result;
                        const randomObjId = getRandomIdCode();
                        let imgMesh = new Image(
                            {
                                name: this.setName(
                                    fName,
                                    null,
                                    `image_${randomObjId}`
                                ),
                                id: `image_${randomObjId}`,
                                link: content,
                                position:
                                    fromVec3PosToPosObject(initialPosition),
                            },
                            "image"
                        );

                        let reqObj = new FormData();
                        reqObj.append("file", file);
                        reqObj.append("contentType", "images");
                        reqObj.append("mapId", scope.editor.mapId);
                        this.editor.trigger("toggleLoaderProgress", [
                            "",
                            "Uploading Image",
                        ]);

                        this.editor.networkService.createAsset(
                            reqObj,
                            this.updateProgress,
                            (response) => {
                                if (response.status === 200) {
                                    imgMesh.setLink(response.data.data.file);
                                    this.editor.toAutoSave &&
                                        this.editor.trigger(
                                            "autoSaveSceneGraphState"
                                        );
                                } else {
                                    this.handleException(
                                        "Error while saving image!",
                                        "information"
                                    );
                                }
                            },
                            (e) => this.editor.callbacks.handleExceptionCatch(e)
                        );

                        scope.editor.onCommand(
                            new AddObject(
                                scope.editor,
                                imgMesh.imageMesh,
                                false,
                                true
                            )
                        );
                    },
                    false
                );
                reader.readAsDataURL(file);
                break;

            case "glb":
                reader.addEventListener(
                    "load",
                    (e) => {
                        let content = e.target.result;

                        this.loaders.gltfLoader.parse(
                            content,
                            "",
                            (result) => {
                                const randomObjId = getRandomIdCode();
                                const modelData = {
                                    name: this.setName(
                                        fName,
                                        null,
                                        `model_${randomObjId}`
                                    ),
                                    id: `model_${randomObjId}`,
                                    model: result,
                                    link: "",
                                    position:
                                        fromVec3PosToPosObject(initialPosition),
                                };
                                const model = new Model(modelData);
                                scope.editor.onCommand(
                                    new AddObject(
                                        scope.editor,
                                        model.gltfModel,
                                        false,
                                        true
                                    )
                                );

                                let reqObj = new FormData();
                                reqObj.append("file", file);
                                reqObj.append("contentType", "models");
                                reqObj.append("mapId", scope.editor.mapId);
                                this.editor.trigger("toggleLoaderProgress", [
                                    "",
                                    "Uploading 3D Model",
                                ]);

                                this.editor.networkService.createAsset(
                                    reqObj,
                                    this.updateProgress,
                                    (response) => {
                                        if (response.status === 200) {
                                            model.setLinkAsync(
                                                response.data.data.file
                                            );
                                            this.editor.toAutoSave &&
                                                this.editor.trigger(
                                                    "autoSaveSceneGraphState"
                                                );
                                        } else {
                                            this.handleException(
                                                "Error while saving model!",
                                                "information"
                                            );
                                        }
                                    },
                                    (e) =>
                                        this.editor.callbacks.handleExceptionCatch(
                                            e
                                        )
                                );
                            },
                            (e) => {
                                console.log("ERROR", e);
                                this.handleException(
                                    "Error while parsing, Upload different 3DModel!",
                                    "danger"
                                );
                            }
                        );
                    },
                    false
                );
                reader.readAsArrayBuffer(file);
                break;

            case "mp3":
            case "wav":
            case "ogg":
                let reqObj = new FormData();
                reqObj.append("file", file);
                reqObj.append("contentType", "audios");
                reqObj.append("mapId", scope.editor.mapId);
                this.editor.trigger("toggleLoaderProgress", [
                    "",
                    "Uploading Audio",
                ]);

                this.editor.networkService.createAsset(
                    reqObj,
                    this.updateProgress,
                    (response) => {
                        if (response.status === 200) {
                            const randomObjId = getRandomIdCode();
                            let audioBlob = {
                                link: response.data.data.file,
                                name: this.setName(
                                    fName,
                                    null,
                                    `audio_${randomObjId}`
                                ),
                                id: `audio_${randomObjId}`,
                                position:
                                    fromVec3PosToPosObject(initialPosition),
                            };
                            let audio = new Audio(audioBlob);
                            scope.editor.onCommand(
                                new AddObject(
                                    scope.editor,
                                    audio.audioSprite,
                                    true,
                                    true
                                )
                            );
                        } else {
                            this.handleException(
                                "Error while saving Audio!",
                                "information"
                            );
                        }
                    },
                    (e) => {
                        this.editor.callbacks.handleExceptionCatch(e);
                        this.handleException(
                            "Error while saving Audio!",
                            "information"
                        );
                    }
                );
                break;

            case "mp4":
                let reqObjmp4Vid = new FormData();
                this.editor.trigger("toggleLoaderProgress", [
                    "",
                    "Encoding Video",
                ]);
                let video = await encodeVideo(file, this.setProgress);
                reqObjmp4Vid.append("file", video);
                reqObjmp4Vid.append("contentType", "videos");
                reqObjmp4Vid.append("mapId", scope.editor.mapId);
                this.editor.trigger("toggleLoaderProgress", [
                    "",
                    "Uploading Video",
                ]);

                this.editor.networkService.createAsset(
                    reqObjmp4Vid,
                    this.updateProgress,
                    (response) => {
                        if (response.status === 200) {
                            const randomObjId = getRandomIdCode();
                            let videoData = {
                                link: response.data.data.file,
                                name: this.setName(
                                    fName,
                                    null,
                                    `video_${randomObjId}`
                                ),
                                id: `video_${randomObjId}`,
                                position:
                                    fromVec3PosToPosObject(initialPosition),
                            };
                            let video = new Video(videoData);
                            scope.editor.onCommand(
                                new AddObject(
                                    scope.editor,
                                    video.videoSprite,
                                    true,
                                    true
                                )
                            );
                            this.setProgress({ ratio: 0 });
                        } else {
                            this.handleException(
                                "Error while saving Video!",
                                "information"
                            );
                        }
                    },
                    (e) => {
                        this.editor.callbacks.handleExceptionCatch(e);
                        console.log(e);
                        this.handleException(
                            "Error while saving Video!",
                            "information"
                        );
                    }
                );
                break;

            default:
                this.editor.trigger("mountObjectLoader", [false]);
                break;
        }
    };

    loadLibraryFile = (file, initialPosition) => {
        //Trigger the loading UI!
        this.editor.trigger("mountObjectLoader", [true]);

        switch (file.contentType) {
            case "images":
                let contentImg = file.path;
                const randomImgObjId = getRandomIdCode();
                const imgMesh = new Image(
                    {
                        name: this.setName(file.fileName, file.name, undefined),
                        id: `image_${randomImgObjId}`,
                        link: contentImg,
                        position: fromVec3PosToPosObject(initialPosition),
                    },
                    "image"
                );
                this.editor.onCommand(
                    new AddObject(this.editor, imgMesh.imageMesh, true, true)
                );
                break;

            case "audios":
                const randomObjIdAudio = getRandomIdCode();
                let audioBlob = {
                    link: file.path,
                    name: this.setName(
                        file.fileName,
                        file.name,
                        `audio_${randomObjIdAudio}`
                    ),
                    id: `audio_${randomObjIdAudio}`,
                    position: fromVec3PosToPosObject(initialPosition),
                };
                let audio = new Audio(audioBlob);
                this.editor.onCommand(
                    new AddObject(this.editor, audio.audioSprite, true, true)
                );
                break;

            case "videos":
                const randomObjIdVideo = getRandomIdCode();
                let videoBlob = {
                    link: file.path,
                    name: this.setName(
                        file.fileName,
                        file.name,
                        `video_${randomObjIdVideo}`
                    ),
                    id: `video_${randomObjIdVideo}`,
                    position: fromVec3PosToPosObject(initialPosition),
                };
                let video = new Video(videoBlob);
                this.editor.onCommand(
                    new AddObject(this.editor, video.videoSprite, true, true)
                );
                break;

            case "models":
                let contentModel = file.path;
                this.editor.trigger("toggleLoaderProgress", [
                    "",
                    "Uploading 3D Model",
                ]);
                this.loaders.gltfLoader.load(
                    contentModel,
                    (result) => {
                        const randomObjId = getRandomIdCode();
                        const modelData = {
                            name: this.setName(
                                file.fileName,
                                file.name,
                                `model_${randomObjId}`
                            ),
                            id: `model_${randomObjId}`,
                            model: result,
                            link: contentModel,
                            position: fromVec3PosToPosObject(initialPosition),
                        };
                        const model = new Model(modelData);
                        this.editor.onCommand(
                            new AddObject(
                                this.editor,
                                model.gltfModel,
                                true,
                                true
                            )
                        );
                    },
                    this.updateProgress,
                    () => {
                        this.handleException(
                            "Error while parsing the 3D-Model!",
                            "information"
                        );
                    }
                );
                break;

            case "3DyModels":
                let content3DyModel = file.models.glb_uncompressed.url;
                this.loaders.gltfLoader.load(
                    content3DyModel,
                    (result) => {
                        const randomObjId = getRandomIdCode();
                        const modelData = {
                            name: file.title,
                            id: `model_${randomObjId}`,
                            model: result,
                            link: content3DyModel,
                            position: fromVec3PosToPosObject(initialPosition),
                        };
                        const model = new Model(modelData);
                        this.editor.onCommand(
                            new AddObject(
                                this.editor,
                                model.gltfModel,
                                true,
                                true
                            )
                        );
                    },
                    undefined,
                    () => {
                        this.handleException(
                            "Error while parsing the 3D-Model!",
                            "information"
                        );
                    }
                );
                break;

            case "texts":
                generateTextMesh(
                    0.5,
                    file.value,
                    file.color,
                    initialPosition,
                    new THREE.Vector3(1, 1, 1),
                    new THREE.Euler(0, 0, 0),
                    false
                );
                break;

            case "hotspots":
                const newHS = new Hotspot({
                    ...file,
                    position: fromVec3PosToPosObject(initialPosition),
                });
                this.editor.onCommand(
                    new AddObject(this.editor, newHS.sprite, true, true)
                );
                break;

            default:
                this.editor.trigger("mountObjectLoader", [false]);
                break;
        }
    };

    loadFloorplans = (file, objectType) => {
        let scope = this;
        let fileName = file.name;
        let fName = fileName.split(".")[0];
        //Trigger the loading UI!
        this.editor.trigger("mountObjectLoader", [true]);

        let uploadObj = new FormData();
        uploadObj.append("file", file);
        uploadObj.append("contentType", "floorplans");
        uploadObj.append("mapId", scope.editor.mapId);
        uploadObj.append("compressFile", true);

        this.editor.trigger("toggleLoaderProgress", [
            "",
            "Uploading Floor plan",
        ]);

        this.editor.networkService.createAsset(
            uploadObj,
            this.updateProgress,
            (response) => {
                if (response.status === 200) {
                    this.createFloorplan(
                        response.data.data?.compressedFile ||
                            response.data.data.file,
                        fName
                    );
                } else {
                    this.handleException(
                        "Error while saving image!",
                        "information"
                    );
                }
            },
            (e) => this.editor.callbacks.handleExceptionCatch(e)
        );
    };

    createFloorplan = (url, fName) => {
        let scope = this;
        const randomObjId = getRandomIdCode();
        let reqObj = {
            id: `floorplan_${randomObjId}`,
            name: fName,
            link: url,
            floorplanVersion: 2.7,
        };
        this.editor.trigger("toggleLoaderProgress", [
            "",
            "Creating Floor plan",
        ]);

        this.editor.networkService.createFloorplan(
            scope.editor.mapId,
            reqObj,
            this.updateProgress,
            (res) => {
                if (res.status === 200) {
                    const floorData = res.data.data;
                    new Floorplan(floorData);
                    this.asyncObject = true;
                    // scope.editor.onCommand(new AddObject(scope.editor, floorPlan.imageMesh, true, true), 'RESTRICT_UNDO');
                } else {
                    this.handleException(
                        "Error while creating floor plan!",
                        "information"
                    );
                }
            },
            (e) => this.editor.callbacks.handleExceptionCatch(e)
        );
    };

    onAsyncObjectLoaded = (floorPlan, type) => {
        if (type === "floorplan" && this.asyncObject) {
            this.asyncObject = false;
            this.editor.onCommand(
                new AddObject(this.editor, floorPlan.imageMesh, true, true),
                "RESTRICT_UNDO"
            );
        }
    };

    addFloorplan = (instance, object, newFloorData) => {
        //remove prev object
        const idx = this.editor.getIndex("jsonFloors", this.id);
        this.editor.jsonFloors.splice(idx, 1);
        this.editor.onCommand(
            new RemoveObject(this.editor, instance.pegMesh, true, true),
            "RESTRICT_UNDO"
        );
        this.editor.onCommand(
            new RemoveObject(this.editor, object, true, true),
            "RESTRICT_UNDO"
        );
        //Add new One!
        new Floorplan(newFloorData);
        this.asyncObject = true;
    };

    loadNavPaths = (path) => {
        const navPath = new Path(path);
        this.editor.onCommand(
            new AddObject(this.editor, navPath.mesh, false, true, false),
            "RESTRICT_UNDO"
        );
    };

    loadNavPins = (pin) => {
        let navPin;
        if (pin.pinType === "connector") {
            navPin = new Connector(pin);
        } else {
            navPin = new Pin(pin);
        }
        this.editor.onCommand(
            new AddObject(this.editor, navPin.mesh, false, true, false),
            "RESTRICT_UNDO"
        );
    };

    handleException = (msg, alertType) => {
        this.editor.callbacks.generateAlert({ msg, alertType });
        this.editor.trigger("mountObjectLoader", [false]);
        this.editor.trigger("toggleLoaderProgress", ["none"]);
    };
}

export { Loader };
