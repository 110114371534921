import React, { useState } from "react";
import {
  Popover,
  IconButton,
  TextField,
  InputAdornment,
  Button,
} from "@mui/material";
import { ChevronDown } from "react-feather";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { DateRangePicker, createStaticRanges } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

const useStyles = makeStyles((thete) => ({
  calendarWrapper: {
    padding: "16px",
  },
  multilineColor: {
    color: "#2A4DC2",
  },
}));

const staticRanges = createStaticRanges([
  {
    label: "Last 28 Days",
    range: () => ({
      startDate: moment().subtract(27, "days").startOf("day").toDate(),
      endDate: moment().subtract(0, "days").endOf("day").toDate(),
    }),
  },
  {
    label: "This Year",
    range: () => ({
      startDate: moment().subtract(0, "years").startOf("year").toDate(),
      endDate: moment().subtract(0, "years").endOf("year").toDate(),
    }),
  },
  {
    label: "Last Year",
    range: () => ({
      startDate: moment().subtract(1, "years").startOf("year").toDate(),
      endDate: moment().subtract(1, "years").endOf("year").toDate(),
    }),
  },
]);

const DatePicker = (props) => {
  const dateFormat = "DD MMM YYYY";
  const classes = useStyles();
  const maxDate = new Date();

  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [inputValue, setInputValue] = useState("Last 28 Days");
  const [anchorEl, setAnchorEl] = useState(null);
  const [fromDate, setFromDate] = useState(
    moment().subtract(27, "days").startOf("day").toDate()
  );
  const [toDate, setToDate] = useState(
    moment().subtract(0, "days").endOf("day").toDate()
  );

  const onAdornmentClick = (e) => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const onInputChange = (e) => {
    const inpVal = e.target.value;
    const { fDate, tDate } = processInputValue(inpVal);
    setInputValue(inpVal);
    setFromDate(fDate);
    setToDate(tDate);
  };

  const onPopoverClose = () => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const onClickDatePicker = (e, reason) => {
    setDisplayCalendar(false);
    setAnchorEl(null);

    props.cb({
      fromDate: `${fromDate.getFullYear()}${
        fromDate.getMonth() + 1 < 10
          ? "0" + (fromDate.getMonth() + 1)
          : fromDate.getMonth() + 1
      }${
        fromDate.getDate() < 10 ? "0" + fromDate.getDate() : fromDate.getDate()
      }`,
      toDate: `${toDate.getFullYear()}${
        toDate.getMonth() + 1 < 10
          ? "0" + (toDate.getMonth() + 1)
          : toDate.getMonth() + 1
      }${toDate.getDate() < 10 ? "0" + toDate.getDate() : toDate.getDate()}`,
    });
  };

  const onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;
    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inpValue = "";
    if (startDate) inpValue += moment(startDate).format(dateFormat);
    if (endDate) inpValue += " - " + moment(endDate).format(dateFormat);
    if (
      moment(startDate).format(dateFormat) ===
      moment(endDate).format(dateFormat)
    ) {
      inpValue = moment(startDate).format(dateFormat);
    }
    if (moment(endDate).diff(moment(startDate), "days") === 27) {
      inpValue = "Last 28 Days";
    }
    setFromDate(new Date(moment(startDate)));
    setToDate(new Date(moment(endDate)));
    setInputValue(inpValue);
  };

  const processInputValue = (value) => {
    let [fromDate, toDate] = value.split("-").map((elm) => elm.trim());

    fromDate = moment(fromDate, dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate, toDate };
  };

  return (
    <div style={{ width: "100%" }}>
      <TextField
        variant="standard"
        fullWidth={true}
        value={inputValue}
        InputProps={{
          className: `${classes.multilineColor} analyticshome__input`,
          disableUnderline: true,
          readOnly: true,
          style: { fontFamily: "poppins", color: "#2A4DC2" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton className="nopadding" onClick={onAdornmentClick}>
                <ChevronDown />
              </IconButton>
            </InputAdornment>
          ),
        }}
        onChange={onInputChange}
      />
      <Popover
        open={displayCalendar}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={onPopoverClose}
      >
        <div className={classes.calendarWrapper}>
          <DateRangePicker
            ranges={[
              {
                startDate: fromDate,
                endDate: toDate,
                key: "selection",
                color: "#2A4DC2",
              },
            ]}
            onChange={onSelectDateRanges}
            staticRanges={staticRanges}
            inputRanges={[]}
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            RangeDatePicker
            maxDate={maxDate}
          />
          <div>
            <Button
              style={{
                borderRadius: 35,
                backgroundColor: "#2A4DC2",
                float: "right",
                marginBottom: "1rem",
              }}
              variant="contained"
              onClick={onClickDatePicker}
            >
              Go
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default DatePicker;
