import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../../api";
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";
import { getUserMapDetails } from "../studio/studioSlice";

const updateAnchor = createAsyncThunk(
  "anchor/updateAnchor",
  async ({ mapId, modifiedAnchor }, { dispatch }) => {
    try {
      const response = await Server.put(
        `/v1/map/anchor/${mapId}`,
        modifiedAnchor
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const deleteAnchor = createAsyncThunk(
  "anchor/deleteAnchor",
  async ({ mapId, anchorId }, { dispatch }) => {
    try {
      const response = await Server.delete(`/v1/map/anchor/${mapId}`, {
        data: { id: anchorId },
      });
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const initialState = {
  anchorDetails: null,
  anchorlog: null,
};

const anchorSlice = createSlice({
  name: "anchor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAnchor.fulfilled, (state, { payload }) => {
        return {
          ...state,
          anchorDetails: payload.data,
          anchorLog: "success",
        };
      })
      .addCase(deleteAnchor.fulfilled, (state, { payload }) => {
        return {
          ...state,
          anchorLog: payload.status,
        };
      })
      .addCase(getUserMapDetails.fulfilled, (state, { payload }) => {
        return {
          ...state,
          anchorLog: null,
        };
      });
  },
});
export { updateAnchor, deleteAnchor };
export default anchorSlice.reducer;
