
// create Command prototype
function Command(editor) {
    this.id = -1;
    this.updatable = false;
    this.type = '';
    this.name = '';
    this.editor = editor;
}

Command.prototype.toJSON = function () {
    
    let output = {};
    output.type = this.type;
    output.id = this.id;
    output.name = this.name;

    return output;
}

Command.prototype.fromJSON = function(json) {

    this.type = json.stype;
    this.id = json.id;
    this.name = json.name;
}

export {
    Command
}