import * as React from "react";
import { Backdrop, Box, Modal, Fade, Button } from "@mui/material";

import { XCircle } from "react-feather";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: "max-content",
  maxHeight: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  border: "none !important",
  outline: "none !important",
  borderRadius: "16px",
  overflowY: 'auto',
  overflowX: 'hidden'
};

const alignStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center !important",
  alignItems: "center !important",
}

export default function CustomModal({
  openModal,
  onCloseModal,
  modalHeader,
  textSuccess,
  textCancel,
  successDisabled,
  onSuccessModal,
  children,
  modalStyle,
  excludeAlignStyle,
}) {
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openModal}
        onClose={onCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <Box sx={{ ...style, ...(modalStyle ? modalStyle : {}), ...(excludeAlignStyle === undefined && alignStyle) }}>
            <XCircle
              color="#353E5A"
              strokeWidth="1.5"
              className="mapdetails__mapModalCloseIcon"
              onClick={onCloseModal}
            />
            <div className="mapdetails__mapModalHeader">{modalHeader}</div>
            {children}
            <div className="mapdetails__mapModalBtnsDiv">
              <Button variant="outlined" color="primary" onClick={onCloseModal}>
                {textCancel}
              </Button>
              <Button
                disabled={successDisabled}
                variant="contained"
                onClick={onSuccessModal}
                className="mapdetails__mapModalBtnText"
                color={textSuccess === 'Delete' ? 'errorRed' : 'primary'}
              >
                {textSuccess}
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
