import { Command } from './Command';

function RemoveObject( editor, object, delayAutosave = false, isVersionChange = false ) {
    Command.call(this, editor);

    this.type = 'RemoveObject';
    this.name = "Remove Object";
    this.updatable = false;
    this.delayAutosave = delayAutosave;
    this.isVersionChange = isVersionChange;
    this.object = object;
}

RemoveObject.prototype = {
    execute: function() {
        this.editor.deselect();
        this.editor.removeObject(this.object, this.delayAutosave, this.isVersionChange);
    },

    undo: function () {
        this.editor.addObjects(this.object, false);
        this.editor.trigger('objectChanged', [this.object, false, this.name])
        this.editor.select(this.object)
    },

    toJSON: function() {
        var output = Command.prototype.toJSON.call(this);
        output.object = this.object.toJSON();
        output.delayAutosave = this.delayAutosave;

        return output;
    }
}

export { RemoveObject };