import { useEffect, forwardRef, useImperativeHandle } from "react";
import { BrowserQRCodeSvgWriter}from "@zxing/browser";
import EncodeHintType from "@zxing/library/esm/core/EncodeHintType";
import ErrorCorrectionLevel from "@zxing/library/esm/core/qrcode/decoder/ErrorCorrectionLevel";
import * as htmlToImage from "html-to-image";
import { useDispatch } from "react-redux";
import { setNewAlert } from "../../../../../features/common/alertSlice";
import { v4 } from "uuid";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { PDFDownloader } from "./PDFDownloader";
import ARWayQRIconBG from "../../../../../assets/pngs/ARWayQR_BG.png";
import ARWayQRIconFG from "../../../../../assets/pngs/ARWayQR_FG.png";
import { getQRURL, shouldAddQRMargins } from "../../../../_utils/QRURLUtils";

const GenerateQR = forwardRef((props, ref) => {
  let codeWriter = new BrowserQRCodeSvgWriter();
  const [width, height] = [256, 256];
  const [downloadQRWidth, downloadQRHeight] = [1024, 1024];
  const dispatch = useDispatch();
  const { QRId, mapName } = props.mapData;
  const { isChecked } = props;
  const { isAnchorQR } = props;
  const { isExtId } = props;

  useEffect(() => {
    document.getElementById(`stepper_qrcode_${QRId?.slice(-6)}`).innerHTML = "";
    const hints = new Map();
    hints.set(EncodeHintType.ERROR_CORRECTION, ErrorCorrectionLevel.M);
    hints.set(EncodeHintType.MARGIN, 2);
    const url = getQRURL(isExtId ? QRId.slice(0, -2) : QRId);
    if(shouldAddQRMargins()) {
      const ele = document.getElementsByClassName('stepper__qrparent--qrlogo');
      for(let i = 0; i < ele.length; i++) {
        const uele = ele.item(i);
        uele.classList.add('stepper__qrparent--qrlogo_s240');
      }
      const ldigEle = document.getElementsByClassName('stepper__qrparent--lastdigit');
      for(let i = 0; i < ldigEle.length; i++) {
        const dele = ldigEle.item(i);
        dele.classList.add('stepper__qrparent--lastdigit_b2per');
      }
    } else {
      const ele = document.getElementsByClassName('stepper__qrparent--qrlogo');
      for(let i = 0; i < ele.length; i++) {
        const uele = ele.item(i);
        uele.classList.remove('stepper__qrparent--qrlogo_s240');
      }
      const ldigEle = document.getElementsByClassName('stepper__qrparent--lastdigit');
      for(let i = 0; i < ldigEle.length; i++) {
        const dele = ldigEle.item(i);
        dele.classList.remove('stepper__qrparent--lastdigit_b2per');
      }
    }

    codeWriter.writeToDom(
      `#stepper_qrcode_${QRId?.slice(-6)}`,
      url,
      width,
      height,
      hints
    );

    return(()=>{
      // eslint-disable-next-line
      codeWriter = null
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QRId]);

  const generateAdditionalQR = (newQRID) => {
    const hints = new Map();
    hints.set(EncodeHintType.ERROR_CORRECTION, ErrorCorrectionLevel.M);
    hints.set(EncodeHintType.MARGIN, 2);
    const url = getQRURL(newQRID);
    if(shouldAddQRMargins()) {
      const ele = document.getElementsByClassName('stepper__qrparent--qrlogo');
      for(let i = 0; i < ele.length; i++) {
        const uele = ele.item(i);
        uele.classList.add('stepper__qrparent--qrlogo_s240');
      }
      const ldigEle = document.getElementsByClassName('stepper__qrparent--lastdigit');
      // console.log(ldigEle);
      for(let i = 0; i < ldigEle.length; i++) {
        const dele = ldigEle.item(i);
        dele.classList.add('stepper__qrparent--lastdigit_b2per');
      }
    } else {
      const ele = document.getElementsByClassName('stepper__qrparent--qrlogo');
      for(let i = 0; i < ele.length; i++) {
        const uele = ele.item(i);
        uele.classList.remove('stepper__qrparent--qrlogo_s240');
      }
      const ldigEle = document.getElementsByClassName('stepper__qrparent--lastdigit');
      // console.log(ldigEle);
      for(let i = 0; i < ldigEle.length; i++) {
        const dele = ldigEle.item(i);
        dele.classList.remove('stepper__qrparent--lastdigit_b2per');
      }
    }
    codeWriter.writeToDom(
      `#stepper_qrcode_${QRId?.slice(-6)}`,
      url,
      width,
      height,
      hints
    );
  };

  useImperativeHandle(ref, () => ({
    async handleDownloadQR(type = "png", setLodingModal) {
      setLodingModal && setLodingModal(true);
      let node = document.getElementById(`stepper_qrparent_${QRId?.slice(-6)}`);
      let qrnode = document.getElementById(`stepper_qrcode_${QRId?.slice(-3)}`);
      if (isAnchorQR) {
        node.classList.remove("mapdetails__anchorcardDefaultQRBG");
        node.classList.remove("mapdetails__anchorcardQRBG");
        qrnode.classList.remove("stepper__qrparent--qrlogobgunset");
        qrnode.classList.add("stepper__qrparent--qrlogobgwhite");
      }
      await htmlToImage
        .toBlob(node, {
          canvasHeight: downloadQRHeight,
          canvasWidth: downloadQRWidth,
          cacheBust: true,
        })
        .then((blob) => {
          var link = document.createElement("a");
          let filename = `${mapName + ".png"}`;
          link.download = filename;
          let blobURL = window.URL.createObjectURL(blob);
          link.href = blobURL;
          if (type === "png") {
            link.click();
          } else {
            let filename = `${mapName}.pdf`;
            PDFDownloader(blobURL, filename, downloadQRWidth, downloadQRHeight);
          }
        });
      if (isAnchorQR) {
        node.classList.add("mapdetails__anchorcardQRBG");
        node.classList.add("mapdetails__anchorcardDefaultQRBG");
        qrnode.classList.remove("stepper__qrparent--qrlogobgwhite");
        qrnode.classList.add("stepper__qrparent--qrlogobgunset");
      }
      setLodingModal && setLodingModal(false);
      dispatch(
        setNewAlert({ msg: "QR Code downloaded", alertType: "success" })
      );
    },
    async handleDownloadZipQR() {
      let node = document.getElementById(`stepper_qrparent_${QRId?.slice(-6)}`);
      let qrnode = document.getElementById(`stepper_qrcode_${QRId?.slice(-3)}`);
      if (isAnchorQR) {
        node.classList.remove("mapdetails__anchorcardDefaultQRBG");
        node.classList.remove("mapdetails__anchorcardQRBG");
        qrnode.classList.remove("stepper__qrparent--qrlogobgunset");
        qrnode.classList.add("stepper__qrparent--qrlogobgwhite");
      }
      const zip = new JSZip();
      await htmlToImage
        .toBlob(node, {
          canvasHeight: downloadQRHeight,
          canvasWidth: downloadQRWidth,
          cacheBust: true,
        })
        .then((blob) => {
            zip.file( `${mapName + ".png"}`, blob, {type: "image/png" });
            let blobURL = window.URL.createObjectURL(blob);
            const pdfBlob = PDFDownloader(blobURL, `${mapName}.pdf`, downloadQRWidth, downloadQRHeight, false);
            zip.file( `${mapName}.pdf`, pdfBlob, {binary: true});
        });
      if (isAnchorQR) {
        node.classList.add("mapdetails__anchorcardQRBG");
        node.classList.add("mapdetails__anchorcardDefaultQRBG");
        qrnode.classList.remove("stepper__qrparent--qrlogobgwhite");
        qrnode.classList.add("stepper__qrparent--qrlogobgunset");
      }
      await zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${mapName}_QR.zip`);
      });
      dispatch(
        setNewAlert({ msg: "QR Code downloaded", alertType: "success" })
      );
    },
    async handleDownloadMultipleQR(
      noOfDownloads,
      setLodingModal,
      setProgress,
      setIsDownloadingAdditional,
      type = "png"
    ) {
      setLodingModal(true);
      let node = document.getElementById(`stepper_qrparent_${QRId?.slice(-6)}`);
      const zip = new JSZip();
      let QRCodeElement = document.getElementById(
        `stepper_qrcode_${QRId?.slice(-6)}`
      );
      let lastdigitElement = document.getElementById(
        `stepper_lastdigit_${QRId?.slice(-6)}`
      );
      for (let i = 1; i <= noOfDownloads; i++) {
        QRCodeElement.innerHTML = "";
        lastdigitElement.innerHTML = "";
        let newQRID = v4();
        lastdigitElement.innerHTML = newQRID.slice(-4).toUpperCase();
        generateAdditionalQR(newQRID);
        await htmlToImage
          .toBlob(node, {
            canvasHeight: downloadQRHeight,
            canvasWidth: downloadQRWidth,
          })
          .then((blob) => {
            // zip.file(`${mapName}_${newQRID?.slice(-4)}.png`, dataUrl, {
            //   type: "image/png",
            // });
            if (type === "pdf") {
              blob = PDFDownloader(
                window.URL.createObjectURL(blob),
                `${mapName}_${newQRID?.slice(-4)}.pdf`,
                downloadQRWidth,
                downloadQRHeight,
                false
              );
            }
            zip.file(
              `${
                type === "pdf"
                  ? `${mapName}_${newQRID?.slice(-4)}.pdf`
                  : `${mapName}_${newQRID?.slice(-4)}.png`
              }`,
              blob,
              { ...(type === "pdf" ? { binary: true } : { type: "image/png" }) }
            );
          });
        setProgress(i / noOfDownloads);
      }
      await zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${mapName}_${type}.zip`);
      });
      dispatch(
        setNewAlert({
          msg: "Addtional QR Codes downloaded",
          alertType: "success",
        })
      );
      QRCodeElement.innerHTML = "";
      lastdigitElement.innerHTML = `${QRId?.slice(-4).toUpperCase()}`;
      generateAdditionalQR(QRId);
      setIsDownloadingAdditional(false);
      setProgress(0);
      setLodingModal(false);
    },
    async handleDownloadMultipleQRMultipleType(
      noOfDownloads,
      setLodingModal,
      setProgress,
      setIsDownloadingAdditional,
      types
    ) {
      setLodingModal(true);
      let node = document.getElementById(`stepper_qrparent_${QRId?.slice(-6)}`);
      const zip = new JSZip();
      let QRCodeElement = document.getElementById(
        `stepper_qrcode_${QRId?.slice(-6)}`
      );
      let lastdigitElement = document.getElementById(
        `stepper_lastdigit_${QRId?.slice(-6)}`
      );
      for (let i = 1; i <= noOfDownloads; i++) {
        QRCodeElement.innerHTML = "";
        lastdigitElement.innerHTML = "";
        let newQRID = v4();
        lastdigitElement.innerHTML = newQRID.slice(-4).toUpperCase();
        generateAdditionalQR(newQRID);
        await htmlToImage
          .toBlob(node, {
            canvasHeight: downloadQRHeight,
            canvasWidth: downloadQRWidth,
          })
          .then((blob) => {
            types.forEach(type => {
              let fileName = '';
              let zipOptions = {}
              if (type === "pdf") {
                fileName = `${mapName}_${newQRID?.slice(-4)}.pdf`;
                zipOptions = { binary: true };
                blob = PDFDownloader(
                  window.URL.createObjectURL(blob),
                  fileName,
                  downloadQRWidth,
                  downloadQRHeight,
                  false
                );
              } else if(type === "png") {
                fileName = `${mapName}_${newQRID?.slice(-4)}.png`;
                zipOptions = { type: "image/png" };
              }
              zip.file(fileName, blob, zipOptions);
            })
          });
        setProgress(i / noOfDownloads);
      }
      await zip.generateAsync({ type: "blob" }).then(function (content) {
        saveAs(content, `${mapName}_QRS.zip`);
      });
      dispatch(
        setNewAlert({
          msg: "Addtional QR Codes downloaded",
          alertType: "success",
        })
      );
      QRCodeElement.innerHTML = "";
      lastdigitElement.innerHTML = `${QRId?.slice(-4).toUpperCase()}`;
      generateAdditionalQR(QRId);
      setIsDownloadingAdditional(false);
      setProgress(0);
      setLodingModal(false);
    }
  }));

  return (
    <div className="stepper__qr">
      {
        <div
          className={`stepper__qrparent ${
            isChecked === undefined
              ? ""
              : isChecked
              ? "mapdetails__anchorcardQRBG"
              : "mapdetails__anchorcardDefaultQRBG"
          }`}
          id={`stepper_qrparent_${QRId?.slice(-6)}`}
        >
          <div
            className="stepper__qrparent--qrcode"
            id={`stepper_qrcode_${QRId?.slice(-6)}`}
          ></div>
          <div className="stepper__qrparent--qrlogocont mt-10">
            <img
              id={`stepper_qrcode_${QRId?.slice(-3)}`}
              className={`stepper__qrparent--qrlogo ${shouldAddQRMargins() && 'stepper__qrparent--qrlogo_s240'}   ${
                isChecked && isAnchorQR
                  ? "stepper__qrparent--qrlogobgunset"
                  : "stepper__qrparent--qrlogobgwhite"
              }`}
              src={ARWayQRIconBG}
              alt="QR_IMG"
            />
            <img
              className="stepper__qrparent--qrlogo stepper__qrparent--qrlogo--foreground"
              src={ARWayQRIconFG}
              alt="QR_IMG"
            />
          </div>
          <h4
            className="stepper__qrparent--lastdigit"
            id={`stepper_lastdigit_${QRId?.slice(-6)}`}
          >
            {isExtId
              ? QRId?.slice(-6, -2).toUpperCase()
              : QRId?.slice(-4).toUpperCase()}
          </h4>
        </div>
      }
    </div>
  );
});

export default GenerateQR;
