import * as THREE from 'three';
import EditorExperience from '../../EditorExperience';
import EventEmitter from '../../utils/EventEmitter';
import { AnchorPoint } from './AnchorPoint';

/* LINE UTILS */
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { LineMaterial } from 'three/examples/jsm/lines/LineMaterial';
import { LineGeometry } from 'three/examples/jsm/lines/LineGeometry';
import AnchorNode from './AnchorNode';

import { 
    UIPanel, 
    UIRow, 
    UISwitch, 
    UIText,
    UITextButton
} from '../../ui/ui.lib';


export default class MapVisualHelper extends EventEmitter {
    constructor(name, mapBounds, anchors) {
        super();
        this.editor = new EditorExperience();
        this.sphere = mapBounds.sphere;
        this.box3 = mapBounds.box3;
        this.mapName = name;
        this.anchors = anchors;
        this.vertices = [];
        this.anchorSprites = [];
        this.visible = true;
        this.contentVisisble = false;
        this.uiCont = null;

        this.editor.on('objectRemoved', this.onObjectRemoved);
        this.editor.on('clearAndReset', this.onClearAndReset);
        
        //Meshes
        this.mappingPathGroup = new THREE.Group();
        this.mappingPathGroup.name = `${this.mapName} mapping path`;
        this.mappingPathGroup.visible = this.visible;
        this.editor.nonInteractiveObjects.push(this.mappingPathGroup.uuid);

        this.floorMesh = null;
        this.mapPathMesh = null;

        this.editor.time.on('tick', this.updateLineRes);
        this.editor.on('interactionWithNonInteractiveObject', this.updateUI);
        this.editor.resources.on('ready', () => this.uiCont.setDisplay(''));

        this.setupDefaultFloorPlan();
        if(this.anchors.length) {
            this.initHTML();
            this.setupAnchorHelpers();
            this.editor.addObjects(this.mappingPathGroup);
        }
    }  


    setupDefaultFloorPlan = () => {

        const defGeoW = Math.abs(this.box3.max.x - this.box3.min.x);
        const defGeoH = Math.abs(this.box3.max.z - this.box3.min.z);
        const planeG = new THREE.PlaneGeometry(defGeoW, defGeoH);
        const planeM = new THREE.MeshBasicMaterial({
            side: THREE.DoubleSide, 
            transparent: true, 
            map: new THREE.TextureLoader().load('/static/textures/defFloorTex.png')
        });

        this.floorMesh = new THREE.Mesh(planeG, planeM);
        this.floorMesh.rotation.x = THREE.MathUtils.degToRad(-90);
        this.floorMesh.name = `${this.mapName} floorplan`;
        this.floorMesh.userData['navHelper'] = 'floorplan';
        this.floorMesh.position.set(this.sphere.center.x, this.box3.min.y, this.sphere.center.z);

        this.editor.addObjects(this.floorMesh);
        this.editor.trigger('adjustGridPosition', [this.box3.min]);
        
    }

    setupAnchorHelpers = () => {
        //TO DO!
        for(const anchor of this.anchors) {
            this.vertices.push(new AnchorPoint(anchor));
        }
        // draw line!
        this.anchors.length >= 2 && this.setupAnchorLineGeometry();
        
        // work with anchors
        const size = this.vertices.length;
        this.vertices.forEach((vertex, i) => {
            var nodeState = size === 1 ? 'NODE' : i === 0 ? 'START' : i === size-1 ? 'END' : 'NODE';
            this.anchorSprites.push(new AnchorNode(vertex, i, nodeState));
        });

        this.anchorSprites.forEach((obj) => {
            this.mappingPathGroup.add(obj.anchorSprite);
            this.uiThumbnailCont.addChild(obj.uiCont);
        })

    }

    setupAnchorLineGeometry = () => {
        this.geometry = new THREE.BufferGeometry();

        let positions = [];
        let colors = [];
        let lineWidth = [];

            // color: '##04D9FF',
        var color = new THREE.Color(0x04D9FF);

        for(var i = 0, index = 0; i < this.vertices.length; i++, index += 3) {
            positions[ index ] = this.vertices[i].pointArray[0];
            positions[ index + 1 ] = this.vertices[i].pointArray[1];
            positions[ index + 2 ] = this.vertices[i].pointArray[2];

            colors[ index ] = color.r;
            colors[ index + 1 ] = color.g;
            colors[ index + 2 ] = color.b;
            
            (i <this.vertices.length - 1) && (lineWidth[i] = 7);

        }
        // console.log("Pos", positions, "Col", colors, "Dis", lineWidth);

        this.geometry = new LineGeometry();
        this.geometry.setPositions(positions);
        this.geometry.setColors(colors);
        this.geometry.setAttribute('linewidth', new THREE.InstancedBufferAttribute(new Float32Array(lineWidth), 1))

        this.mapPathMaterial = new LineMaterial({
            color: '#04D9FF',
            vertexColors: true,
            dashed: false,
            // alphaToCoverage: true,

        })

        this.mapPathMaterial.onBeforeCompile = shader => {
            shader.vertexShader = `${shader.vertexShader}`.replace(`uniform float linewidth;`, `attribute float linewidth;`);
        }

        this.mapPathMesh = new Line2(this.geometry, this.mapPathMaterial);
        this.mapPathMesh.computeLineDistances();
        this.mapPathMesh.scale.set( 1, 1, 1 );
        this.mapPathMesh.name = `${this.mapName} mapping path`;
        this.mappingPathGroup.add(this.mapPathMesh);

        this.editor.nonInteractiveObjects.push(this.mapPathMesh.uuid);
    }

    initHTML = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('wsMapVisualContainer');
        // this.uiCont.setDisplay('none');

        // Header Row
        const uiHeaderRow = new UIRow().addClass('wsMapVisualContainer__mvcHeaderRow');
        const uiHeadCont = new UIPanel().addClass('wsMapVisualContainer__mvcHeaderSubRow');
        const uiHighLighter = new UIPanel().addClass('wsMapVisualContainer__mvcHighBullet');
        this.uiHeaderText = new UIText(undefined, 'Mapping Path', true).addClass('wsMapVisualContainer__mvcHeader');
        this.uiHeaderBtn = new UITextButton(this.contentVisisble ? 'Hide': 'Show').onClick(() => {
            if(this.contentVisisble) {
                this.editor.trigger('interactionWithNonInteractiveObject', [null]);
            } else {
                this.editor.trigger('interactionWithNonInteractiveObject', [this.anchorSprites[0].anchorSprite]);
            }
        });
        this.uiHeaderBtn.addClass('wsMapVisualContainer__mvcVisualBtn');
        uiHeadCont.addChild(uiHighLighter);
        uiHeadCont.addChild(this.uiHeaderText);
        uiHeaderRow.addChild(uiHeadCont);
        uiHeaderRow.addChild(this.uiHeaderBtn);
        this.uiCont.addChild(uiHeaderRow);

        const uiToggleRow = new UIRow().addClass('wsMapVisualContainer__mvcHeaderRow');
        const uiSubText = new UIText(undefined, "Show mapping path", true).addClass('wsMapVisualContainer__mvcSubHeaderText');
        const uiToggleCont = new UIPanel().addClass('wsMapVisualContainer__mvcHeaderSubRow');
        this.uiStatusText = new UIText(undefined, this.visible ? 'ON' : 'OFF', true).addClass('wsMapVisualContainer__mvcSubHeaderSwitch');
        this.uiSwitchVisible = new UISwitch(this.visible).onChange((e) => {
            this.visible = e.target.checked;
            this.mappingPathGroup.visible = this.visible;
            this.uiSwitchVisible.setValue(this.visible);
            this.uiStatusText.setTextContent(this.visible ? 'ON' : 'OFF');
            !this.visible && this.editor.trigger('interactionWithNonInteractiveObject', [null]);
        });

        uiToggleRow.addChild(uiSubText);
        uiToggleCont.addChild(this.uiStatusText);
        uiToggleCont.addChild(this.uiSwitchVisible);
        uiToggleRow.addChild(uiToggleCont);
        this.uiCont.addChild(uiToggleRow);

        // Thumbnail Panel!
        this.uiThumbnailCont = new UIPanel().addClass('wsMapVisualContainer__mvcThumbnailCont');
        this.uiCont.addChild(this.uiThumbnailCont);
        this.uiCont.setDisplay('none');
        document.body.appendChild(this.uiCont.dom);
    }

    updateUI = (object) => {
        if(object) {
            const uuid = object.uuid;
            this.anchorSprites.forEach((anchor) => {
                if(anchor.anchorSprite.uuid === uuid) {
                    anchor.uiCont.setDisplay('');
                    this.contentVisisble = true;
                    this.uiHeaderBtn.setTextContent(this.contentVisisble ? 'Hide': 'Show');
                } else {
                    anchor.uiCont.setDisplay('none');
                }
            })
        } else {
            this.anchorSprites.forEach((anchor) => {
                anchor.uiCont.setDisplay('none');
            })
            this.contentVisisble = false;
            this.uiHeaderBtn.setTextContent(this.contentVisisble ? 'Hide': 'Show');
        }
    }

    updateLineRes = () => {
        this.mapPathMaterial && this.mapPathMaterial.resolution.set( this.editor.sizes.width, this.editor.sizes.height );
    }

    onObjectRemoved = (object) => {
        if(object === this.mappingPathGroup) {
            this.uiCont.dom.remove();
        }
    }

    onClearAndReset = (object) => {
        if(this.uiCont) {
            document.body.removeChild(this.uiCont.dom);
            this.uiCont.dom.remove();
        }
    }

}