import * as THREE from 'three';

import { v4 } from "uuid";
import GuidedTour from "./GuidedTour";
import EditorExperience from "../../EditorExperience";
import { DragControls } from '../../utils/DragControls';

class GuidedTourController {
    constructor() {
        this.raycaster = new THREE.Raycaster();
        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;

        // stores all the tour instances
        this.guidedTourInstances = new Map();
        this.selectedObject = null;
        this.difference = null;
        this.activeTour = null;
        this.dragControls = null;
        this.newTourInProcess = false;
        this.pathsToRaycast = [];
        this.tourSizes = null;

        // Three elements
        // this.box = new THREE.Box3();
        this.boxHelper = new THREE.BoxHelper()
        this.boxHelper.visible = false;
        this.editor.scene.add(this.boxHelper);

        // Reference Plane
        this.referencePlane = null;
        this.isSelectedTourCreatedFromApp = false;

        this.enableEventListeners();
        this.editor.on("objectSelected", this.onObjectSelected);
        this.editor.on("refreshPathsToTrack", this.refreshPathsToTrack);
        this.editor.on("transformModeChanged", this.onTransformModeChanged);
        this.editor.on("deleteSelectedAsset", this.onDeleteSelectedAsset);
        this.editor.on("nodeSelected", this.onNodeSelected);
        this.editor.on("nodeOperationsSelected", this.onNodeOperationsSelected);
    }

    initGuidedTours = (tour) => {
        // iterate over tours array, and create new instance of guided
        // tour pass the tour data and tour type to the constructor and save
        // the instance to guidedTourInstances Maps ex:- this.guidedTourInstances.set("tour.id", instance);

        if (tour && tour.nodes && tour.nodes.length && tour.waypoints && tour.waypoints.length) {
            const wpZero = { ...tour.waypoints[0] }

            const tourData = {
                id: wpZero.id,
                name: wpZero.name,
                pathName: wpZero.pathName,
                description: wpZero.description,
                pathColor: wpZero.pathColor,
                visible: wpZero.visible,
                navigationStyle: wpZero.navigationStyle,
                nodes: tour.nodes,
                waypoints: tour.waypoints,
                images: wpZero.images,
                file: wpZero.file,
                wheelchairAccessible: wpZero.wheelchairAccessible,
                isCreatedFromApp: tour?.isCreatedFromApp,
            }

            const newTour = new GuidedTour(
                "existing",
                tourData,
                this.manageNavigationTracking,
                this.operationsAfterTourEnd,
                this.checkNodeSelectionPriority,
                null,
                this.getActiveTour,
            )

            this.guidedTourInstances.set(wpZero.id, newTour);

            return newTour;
        }

        return null;
    }

    initNewTour = () => {
        // create new instance of the tour and set type as "new", no tourData is needed
        // and save the instance to this.guidedTourInstances
        this.editor.trigger('newTourOperationStatusChange', ["Started"]);

        if (!this.newTourInProcess) {
            this.activeTour = v4();
            this.newTourInProcess = true;
            this.boxHelper.visible = false;

            this.guidedTourInstances.set(
                this.activeTour,
                new GuidedTour(
                    "new",
                    { id: this.activeTour },
                    this.manageNavigationTracking,
                    this.operationsAfterTourEnd,
                    this.checkNodeSelectionPriority,
                    this.exitNewTourCreationFlow,
                    this.getActiveTour,
                )
            );
        }

    }

    configureReferencePlane = (point) => {
        this.scene.children.forEach((child) => {
            if (child.userData && child.userData.navHelper && child.userData.navHelper === "floorplan") {
                const planeGeo = new THREE.PlaneGeometry(500, 500);
                const planeMaterial = new THREE.MeshBasicMaterial({
                    color: 'red',
                    transparent: true,
                    opacity: 0,
                    side: THREE.DoubleSide,
                });
                this.referencePlane = new THREE.Mesh(planeGeo, planeMaterial);
                this.referencePlane.userData['skipScene'] = true;
                this.referencePlane.userData['skipChild'] = true;
                this.referencePlane.userData['TourHelper'] = true;


                this.referencePlane.position.copy(child.position);
                this.referencePlane.position.y = point.y;
                this.referencePlane.quaternion.copy(child.quaternion);

                this.referencePlane.visible = true;
                this.scene.add(this.referencePlane);
                this.editor.nonInteractiveObjects.push(this.referencePlane);
            }
        });
    }

    endTour = () => {
        const tour = this.guidedTourInstances.get(this.activeTour);

        // tour cannot be ended until start point + one extra node is placed
        if (tour && tour.tourType === "new" && (tour.activeNode > 1)) {
            tour.endTour();
        }
    }

    getActiveTour = (tourId) => {
        return tourId === this.activeTour;
    }

    operationsAfterTourEnd = () => {
        this.newTourInProcess = false;
        this.editor.trigger('newTourOperationStatusChange', ["Ended"]);
        this.updateBoxHelper();
    }

    configureDragFeature = () => {
        // Find a plane to drag nodes on it
        let planeToDragOn = null;

        if (this.isSelectedTourCreatedFromApp && this.referencePlane) planeToDragOn = this.referencePlane;
        else {
            this.scene.children.forEach((child) => {
                if (child.userData && child.userData.navHelper && child.userData.navHelper === "floorplan") {
                    planeToDragOn = child;
                }
            });
        }
    
        // Make a instance on drag control to drag the node if plane exist
        if (planeToDragOn) {
            this.dragControls = new DragControls(planeToDragOn, this.camera.instance, this.canvas);
            this.dragControls.dragTour = true;
            this.hanldeDragEvent();
        }
    }

    /**
     * This method helps to maintain navigation tracking status based on 
     * all instances selectedNode 
     */
    manageNavigationTracking = () => {
        let activeNode = false
        this.guidedTourInstances.forEach((val, key) => {
            if (val.selectedNode) {
                activeNode = true;
            }
        });

        if (!activeNode) {
            this.editor.trigger('navigationTracking', [false]);
        }
    }

    checkNodeSelectionPriority = () => {
        let tourId = null;
        this.guidedTourInstances.forEach((val, key) => {
            if (val.activeHoveredNode) {
                tourId = val.tourId;
            }
        });

        return tourId;
    }

    refreshPathsToTrack = () => {
        const paths = [];
        this.guidedTourInstances.forEach((val) => {
            paths.push(val.pathMesh);
        });

        this.pathsToRaycast = paths;
    }

    onTransformModeChanged = (transformMode) => {
        switch (transformMode) {
            case "translate":
                if (!this.dragControls && this.selectedObject) {
                    this.configureDragFeature();
                }

                if (this.dragControls) {
                    this.dragControls.transformGroup = true;
                    this.dragControls.setObject(this.selectedObject);
                    this.dragControls.activate();
                }
                this.updateBoxHelper();
                break;

            default:
                if (this.dragControls) {
                    this.dragControls.dispose();
                    this.dragControls = null;
                };
                break;
        }
    }

    onNodeOperationsSelected = () => {
        if (this.dragControls) {
            this.dragControls.dispose();
            this.dragControls = null;
        }
    }

    hanldeDragEvent = () => {
        if (this.dragControls) {
            this.dragControls.addEventListener('drag', this.onNodeDrag);
            this.dragControls.addEventListener('dragstart', this.onDragStart);
            this.dragControls.addEventListener('dragend', this.onDragEnd);
        }
    }

    enableEventListeners = () => {
        this.canvas.addEventListener('mousedown', this.onMouseDown, false);
    }

    onNodeDrag = (e) => {
        this.editor.camera.controls.enabled = false;
        if (this.selectedObject) {
            const finalPos = this.selectedObject.position.clone().add(this.difference);
            this.selectedObject.position.x = finalPos.x;
            this.selectedObject.position.z = finalPos.z;

            this.boxHelper.object.children.forEach((child, index) => {
                const veco = new THREE.Vector3();
                this.selectedObject.children[index].getWorldPosition(veco);
                child.position.copy(veco);
            });
            this.boxHelper.update();
        }
    }

    onDragStart = (e) => {
        this.editor.camera.controls.enabled = false;
    }

    onDragEnd = (e) => {
        this.editor.camera.controls.enabled = true;

        if (this.selectedObject) {
            this.selectedObject.children.forEach((child) => {
                if (!child.userData.isPathGeometry) {
                    const veco = new THREE.Vector3();
                    child.getWorldPosition(veco);
                    child.position.copy(veco);
                }
            })
            this.selectedObject.position.set(0, 0, 0);
        }

        if (this.activeTour) {
            const object = this.guidedTourInstances.get(this.activeTour);
            object.refreshPath();
        }

        this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);
        !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);
    }

    onMouseDown = (e) => {
        let viewportDown = new THREE.Vector2();
        const rect = this.canvas.getBoundingClientRect();
        viewportDown.x = (((e.offsetX - rect.left) / rect.width) * 2) - 1;
        viewportDown.y = - (((e.offsetY - rect.top) / rect.height) * 2) + 1;

        this.raycaster.setFromCamera(viewportDown, this.camera.instance);
        const intersects = this.raycaster.intersectObjects(this.pathsToRaycast, false);

        if (intersects.length > 0) {
            let intersect = []

            if (intersects.length > 1) {
                intersect = intersects[intersects.length - 1];
            } else {
                intersect = intersects[0];
            }

            const parent = intersect.object.parent;

            this.isSelectedTourCreatedFromApp = parent.userData.isCreatedFromApp;

            if (this.isSelectedTourCreatedFromApp && !this.referencePlane) this.configureReferencePlane(intersect.point);
            else if (this.isSelectedTourCreatedFromApp && this.referencePlane) {
                this.referencePlane.position.y = intersect.point.y;
            }

            if (parent && parent.userData["type"] === "wayPointGroup") {
                if (this.activeTour === parent.userData["id"]) {
                    this.updateBoxHelper(intersect.point);
                    return;
                }

                this.activeTour = parent.userData["id"];
                this.editor.deselect();
                this.updateBoxHelper(intersect.point);
            }
        }
    }

    selectGuidedTour = (id) => {
        if (this.guidedTourInstances.has(id)) {
            this.activeTour = id;
            this.editor.deselect();
            this.updateBoxHelper();
        } else {
            this.updateBoxHelper();
        }
    }

    onObjectSelected = (object) => {
        if (object && object !== this.selectedObject) {
            this.activeTour = null;
            this.selectedObject = null;
            this.difference = null;
            this.updateBoxHelper();
        }
    }

    onNodeSelected = () => {
        this.activeTour = null;
        this.selectedObject = null;
        this.difference = null;
        this.updateBoxHelper();
    }

    updateBoxHelper = (point = null) => {
        if (this.activeTour) {
            if (this.selectedObject && this.activeTour !== this.selectedObject?.userData?.id) {
                if (this.dragControls) {
                    this.dragControls.dispose();
                    this.dragControls = null;
                };
            }

            this.selectedObject = this.findActiveTourGroup(this.activeTour);

            if (!this.selectedObject) {
                this.boxHelper.visible = false;
                return;
            }

            const group = new THREE.Group();
            if (point) {
                this.difference = this.selectedObject.position.clone().sub(point);
            }
            this.boxHelper.setFromObject(group);

            this.selectedObject.children.forEach((child) => {
                if (!child.userData.isPathGeometry) group.attach(child.clone());
                this.boxHelper.update();
            })
            this.boxHelper.visible = true;
            this.editor.select(this.selectedObject);

        } else {
            this.boxHelper.visible = false;
            if (this.editor.selectedObject && this.editor.selectedObject.userData.type === 'wayPointGroup') this.editor.deselect();
            if (this.dragControls) {
                this.dragControls.setObject(null);
                this.dragControls.dispose();
                this.dragControls = null;
            }
        }
    }

    findActiveTourGroup = (activeTour) => {
        if (activeTour) {
            const object = this.guidedTourInstances.get(activeTour);

            if (object.activeHoveredNode) return null;
            return object.tourGroup;
        }

        return null;
    }

    toJSON = (id) => {
        if (id && this.guidedTourInstances.get(id)) {
            return this.guidedTourInstances.get(id).toJSON();
        }
    }

    exitNewTourCreationFlow = () => {

        if (this.newTourInProcess && this.activeTour) {
            this.guidedTourInstances.delete(this.activeTour);

            this.selectedObject = null;
            this.difference = null;
            this.activeTour = null;
            this.dragControls = null;
            this.newTourInProcess = false;
            this.boxHelper.visible = false;

            this.refreshPathsToTrack();
            this.editor.trigger('newTourOperationStatusChange', ["Ended"]);
        }
    }

    onDeleteSelectedAsset = () => {
        if (this.activeTour && this.selectedObject) {
            this.guidedTourInstances.delete(this.activeTour);

            this.selectedObject = null;
            this.difference = null;
            this.activeTour = null;
            this.dragControls = null;
            this.newTourInProcess = false;
            this.boxHelper.visible = false;

            this.refreshPathsToTrack();
        }
    }

    clearTours = () => {
        // stores all the tour instances
        this.guidedTourInstances = new Map();
        this.activeTour = null;
    };
}

export default GuidedTourController;