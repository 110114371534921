import EditorExperience from "../../2DEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";


// import UI Components
import { ToolbarTransforms } from './2DToolbar.Transform';

import {
    UIPanel,
} from '../../../ui/ui.lib';

class Toolbar2D extends EventEmitter {

    constructor(props) {
        super(props)
        this.editor = new EditorExperience();
        this.parentEle = props.dom;

        this.editor.on('mount2DLoader', this.onMountObjectLoader);

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel();
        this.uiContainer.addClass('ws2dBottomBarContainer');

        this.transformTools = new ToolbarTransforms(this.editor);

        this.uiContainer.dom.appendChild(this.transformTools.dom);

        this.parentEle.append(this.uiContainer.dom);
    }

    onMountObjectLoader = (bFlag) => {
        if(bFlag) {
            this.uiContainer.addClass('ws2dBottomBarContainer--noEvnts');
        } else {
            this.uiContainer.removeClass('ws2dBottomBarContainer--noEvnts');
        }
    }
}

export { Toolbar2D }