import * as THREE from 'three';
import EditorExperience from '../../EditorExperience';
import EventEmitter from '../../utils/EventEmitter';
import { 
    fromPos2dObjectToVec3Pos,
    fromPosObjectToVec3Pos,
    getTransformed3DPos,
    getTransformed3DPosV1,
} from '../TransformConversions';

class PathNode extends EventEmitter{

    constructor(nodeData, pathColor, pathStroke) {
        super();
        this.editor = new EditorExperience();

        this.id = nodeData.id;        
        this.position2d = fromPos2dObjectToVec3Pos(nodeData.position2d);
        this.position = fromPosObjectToVec3Pos(nodeData.position);

        this.adjacents = new Set();
        this.edgesDrawn = new Map();

        this.pathColor = pathColor;
        this.pathStroke = pathStroke;

        this.nodeMesh = null;

        this.createNodeMesh();

    }

    addAdjacent(node) {
        this.adjacents.add(node);
    }

    removeAdjacent(node) {
        this.edgesDrawn.delete(node.id);
        return this.adjacents.delete(node);
    }

    isAdjacent(node) {
        return this.adjacents.has(node);
    }

    getAdjacents() {
        return Array.from(this.adjacents);
    }

    setNodeState = state => this.nodeState = state;

    //3js functions
    createNodeMesh = () => {
        const cylinGeo = new THREE.CircleGeometry((.1 * this.pathStroke), 64);
        const cylinMat = new THREE.MeshBasicMaterial({
            color: this.pathColor,
            side: THREE.DoubleSide,
        });
        this.nodeMesh = new THREE.Mesh(cylinGeo, cylinMat);
        this.nodeMesh.rotateX(THREE.MathUtils.degToRad(-90));
        this.nodeMesh.position.copy(this.position);
        this.nodeMesh.position.add(new THREE.Vector3(0, 0.1, 0));

        this.nodeMesh.renderOrder = 1000;

        this.nodeMesh.userData['transformation'] = "NO_TRANSFORM"; //only to hide transformation controls in edit mode
        this.nodeMesh.userData['skipChild'] = true;
        this.nodeMesh.userData['visible'] = this.visible;
        this.nodeMesh.userData['interactive2D'] = true;
    }

    computeTransformed3D = () => {
        const { imgWidth, imgHeight } = this.editor.floorData;
        const pos = {posX: this.position2d.x, posY:  Math.abs(this.position2d.y)};
        const pos2d = this.editor.floorplanVersion !== 2.6 
            ? getTransformed3DPos(this.editor.floorWorldMatrix, imgWidth, imgHeight, pos)
            : getTransformed3DPosV1(this.editor.floorWorldMatrix, imgWidth, imgHeight, pos);

        this.position = pos2d.clone();
        this.nodeMesh.position.copy(this.position);
        this.nodeMesh.position.add(new THREE.Vector3(0, 0.1, 0));

        this.moveEdgesBetnAdjacents();
    }

    moveEdgesBetnAdjacents = () => {
        this.adjacents.forEach(adNode => {
            if(this.edgesDrawn.has(adNode.id)) {
                const edge = this.edgesDrawn.get(adNode.id);
                edge.updateGeometryVertices([this.position.clone(), adNode.position.clone()]);
            }
        })
    }

}

export { PathNode };