import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
import { UIPanel, UISeparator } from "../ui.lib";

import { MenubarText } from "./Menubar.Text";
import { MenubarHotspot } from "./Menubar.Hotspot";
import { MenubarImage } from "./Menubar.Image";
import { MenubarModel } from "./Menubar.Model";
import { MenubarAudio } from "./Menubar.Audio";
import { MenubarFloorplan } from "./Menubar.Floorplan";
import { MenubarLocationPin } from "./Menubar.LocationPin";
import { MenubarGuidedTour } from "./Menubar.GuidedTour";
import { MenubarVideo } from "./Menubar.Video";
import { MenubarQRCode } from "./Menubar.QRCode";
import { MenubarMapMarker } from "./Menubar.MapMarker";
import { MenubarHistory } from "./Menubar.History";
import { MenubarImageAccess } from "./Menubar.ImageAccess";
// import { MenubarJSONDownloader } from "./Menubar.JSONDownload";
import { MenubarConnectorPin } from "./Menubar.ConnectorPin";
import { MenubarInteractions } from "./Menubar.Interactions";

import { InfoPopup } from "./Menubar.UIPopUp";
import { MenubarNavigator } from "./Menubar.Navigator";

class Menubar extends EventEmitter {
    constructor(props) {
        super(props);
        this.editor = new EditorExperience();
        this.strings = this.editor.strings;
        this.parentEle = props.dom;

        this.menuOpen = true;

        this.editor.on("refreshUI", this.onRefreshUI);
        this.editor.on("mountObjectLoader", this.onMountObjectLoader);

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel();
        this.uiContainer.setClass("wsMenubarContainer");

        const uiSepOne = new UISeparator();
        const uiSepTwo = new UISeparator();
        const uiSepThree = new UISeparator();
        uiSepThree.setStyle("marginRight", "10px");

        this.menuText = new MenubarText(this.editor);
        this.menuImage = new MenubarImage(this.editor);
        this.menubarAudio = new MenubarAudio(this.editor);
        this.menuModel = new MenubarModel(this.editor);
        this.menuHotspot = new MenubarHotspot(this.editor);
        this.menuFloorplan = new MenubarFloorplan(this.editor);
        this.menuLocationPins = new MenubarLocationPin(this.editor);
        this.menuConnectorPins = new MenubarConnectorPin(this.editor);
        this.menuGuidedTours = new MenubarGuidedTour(this.editor);
        this.menuVideo = new MenubarVideo(this.editor);
        this.menuMapMarker = new MenubarMapMarker(this.editor);
        this.menuQRCode = new MenubarQRCode(this.editor);
        this.menuHistory = new MenubarHistory(this.editor);
        this.menuImgAccess = new MenubarImageAccess(this.editor);
        // this.menuJsonDownload = new MenubarJSONDownloader(this.editor);
        this.menuInteractions = new MenubarInteractions(this.editor);
        this.menuPopup = new InfoPopup(this.editor);
        this.menuNavigator = new MenubarNavigator(this.editor);

        const uiOptnsRow = new UIPanel();
        uiOptnsRow.addClass("wsMenubarContainer--optionsRow");

        uiOptnsRow.dom.appendChild(this.menuHistory.dom);
        uiOptnsRow.addChild(uiSepOne);
        uiOptnsRow.dom.appendChild(this.menuQRCode.dom);
        uiOptnsRow.dom.appendChild(this.menuImgAccess.dom);
        uiOptnsRow.dom.appendChild(this.menuMapMarker.dom);
        uiOptnsRow.dom.appendChild(this.menuFloorplan.dom);
        uiOptnsRow.addChild(uiSepTwo);
        uiOptnsRow.dom.appendChild(this.menuLocationPins.dom);
        uiOptnsRow.dom.appendChild(this.menuConnectorPins.dom);
        uiOptnsRow.dom.appendChild(this.menuGuidedTours.dom);
        uiOptnsRow.dom.appendChild(this.menuImage.dom);
        uiOptnsRow.dom.appendChild(this.menuText.dom);
        uiOptnsRow.dom.appendChild(this.menuVideo.dom);
        uiOptnsRow.dom.appendChild(this.menubarAudio.dom);
        uiOptnsRow.dom.appendChild(this.menuHotspot.dom);
        uiOptnsRow.dom.appendChild(this.menuModel.dom);

        this.uiContainer.addChild(uiOptnsRow);

        const uiTransformRow = new UIPanel();
        uiTransformRow.addClass("wsMenubarContainer--optionsRow");

        uiTransformRow.addChild(uiSepThree);
        uiTransformRow.dom.appendChild(this.menuInteractions.dom);

        this.uiContainer.addChild(uiTransformRow);

        // this.uiContainer.addChild(this.menuJsonDownload.dom);
        this.uiContainer.addChild(this.menuPopup.dom);
        this.uiContainer.addChild(this.menuNavigator.dom);

        this.parentEle.append(this.uiContainer.dom);
    };

    onRefreshUI = () => {
        if (
            "anchorType" in this.editor.mapData?.metadata &&
            this.editor.mapData?.metadata?.anchorType !== "qrAnchors"
        ) {
            this.uiContainer.dom.removeChild(this.menuQRCode.dom);
        }
    };

    onMountObjectLoader = (bFlag) => {
        if (bFlag) {
            this.uiContainer.addClass("wsMenubarContainer--noEvnts");
        } else {
            this.uiContainer.removeClass("wsMenubarContainer--noEvnts");
        }
    };
}

export { Menubar };
