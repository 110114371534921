import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { 
    Backdrop, Box, 
    Modal, Fade,
    TextField, Button
} from "@mui/material";

import { XCircle } from "react-feather";

import {
    updateConnectorGroups
} from '../../../../../features/webstudio/buildingSlice';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80% !important",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    borderRadius: "16px",
    overflowY: 'auto',
};

const EditGroupModal = ({ openModal, onCloseModal, modalData }) => {

    const dispatch = useDispatch();

    const [ groupName, setGroupName ] = useState('');
    const [ errName, setErrName ] = useState(null);
    const [ save, setSave ] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(groupName && groupName.trim().length) {
            setSave(true);
        } else {
            setErrName('Please enter group name!')
        }
    }

    const handleClose = (e, reason) => {
        if(reason && reason === 'backdropClick') return;
        setGroupName('');
        setErrName(null);
        onCloseModal()
    }

    const handleChange = (e) => {
        setGroupName(e.target.value);
        e.target.value.length ? setErrName(null) : setErrName('Please enter group name!');
    }

    useEffect(() => {
        if(save) {
            if(groupName === modalData.group.name) {
                handleClose();
            } else {
                dispatch(
                    updateConnectorGroups({
                        connectObj: {name: groupName}, 
                        buildingId: modalData.buildingId, 
                        groupId: modalData.group.id
                    })
                ).then(() => {
                    handleClose();
                    setSave(false)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ save ])

    useEffect(() => {
        if(openModal && modalData) {
            setGroupName(modalData.group.name)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ modalData ])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={handleClose}
                        />
                        <div className='cgModal'>
                            <div className='cgModal--cgCard'>
                                <form onSubmit={handleSubmit}>
                                    <h4 className='cgModal--cgCard--title'>Edit "{modalData?.group?.name}" Group</h4>
                                    <div className='cgModal--cgCard--header'>Edit connector group details or select individual pins to adjust their status, details or group location. </div>
                                    <div className='cgModal--cgCard--renameCont'>
                                        <div className="cgModal--cgCard--subHeader">Edit Group Name</div>
                                        <TextField 
                                            label="Group Name" 
                                            id="groupName"
                                            value={groupName}
                                            onChange={handleChange}
                                            size="small"
                                            fullWidth
                                            className={`settingshome__acDetailsCard--formControl settingshome__acDetailsCard--formControl--active`}
                                            autoComplete="off"
                                            inputProps={
                                                { maxLength: 50 }
                                            }
                                        />
                                        {errName ? <span className='settingshome__acDetailsCard--errorMsg'>{errName}</span> : null}
                                    </div>
                                    <div className="publishModalBtnsDiv publishModalBtnsDiv--floatRight">
                                        <Button variant='outlined' color='primary' onClick={handleClose}>Cancel</Button>
                                        <Button variant='contained' color='primaryColors' className='mapdetails__mapModalBtnText' type='submit'>Save Changes</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default EditGroupModal;
