import React from 'react';
import { useSelector } from 'react-redux';
import OverviewAll from './OverviewAll';
import OverviewAccount from './OverviewAccount';
import Loader from '../../../../common/loader';

export default function OverviewHome() {

    const selectedAccount = useSelector((state) => state.adminanalytics.activeAccount);

    if(selectedAccount && selectedAccount?.id === 'allAccounts') {
        return <OverviewAll visitorBehavior={false}/>
    } else if( selectedAccount && selectedAccount?.id !== 'allAccounts') {
        return <OverviewAccount />
    } else {
        return <Loader loaderText={"Loading..."} height="500px" width="100%"/>
    }
}