import EventEmitter from "../../../utils/EventEmitter";

import TranslateIcon from '../../../../../assets/svgs/scenes/translate.svg';
import ScaleIcon from '../../../../../assets/svgs/scenes/scale.svg';
import DeleteIcon from '../../../../../assets/svgs/scenes/delete.svg';

//Command
import { RemoveObject } from '../../../commands/RemoveObject';

// import UI Components
import {
    UIPanel
} from '../../../ui/ui.lib';

class ToolbarTransforms extends EventEmitter {

    constructor(editor) {
        super();

        this.editor = editor;
        this.inSavingState = false;

        this.initUI();

        this.editor.on('transformModeChanged', this.onTransformModeChanged);
        this.editor.on('objectSelected', this.onObjectSelected);
        this.editor.on('deleteSelectedAsset', this.onDeleteSelectedAsset);
    }

    initUI = () => {
        var uiContainer = new UIPanel();
        uiContainer.addClass('wsToolsContainer')

        //Delete
        this.uiDeleteContainer = document.createElement('div');
        this.uiDeleteContainer.classList.add('wsToolbarButtons2d');

        var deleteIcon = document.createElement('img');
        deleteIcon.classList.add('wsToolbarIcon');
        deleteIcon.title = "Delete";
        deleteIcon.id = 'img_Delete';
        deleteIcon.src = DeleteIcon;
        deleteIcon.addEventListener('click',  () => {
            let object = this.editor.selectedObject;
            if(object !== null && object.parent !== null && object.userData) {
                const type = object.userData.type;
                this.editor.editor3d.onToggleDeleteAssetModal(true, {type, name: object.name, showSubText: true}, true);
            }
            // this.onDeleteSelectedAsset();
        });
        this.uiDeleteContainer.appendChild(deleteIcon);

        this.uiTranslateContainer = document.createElement('div');
        this.uiTranslateContainer.className = 'wsToolbarButtons2d wsToolbarSelected2d';

        var translateIcon = document.createElement('img');
        translateIcon.classList.add('wsToolbarIcon');
        translateIcon.title = "Translate";
        translateIcon.id = 'img_Translate';
        translateIcon.src = TranslateIcon;
        translateIcon.addEventListener('click',  () => {
            // Translate Clicked
            this.editor.trigger('transformModeChanged', ['translate'])
        });
        this.uiTranslateContainer.appendChild(translateIcon);

        this.uiScaleContainer = document.createElement('div');
        this.uiScaleContainer.classList.add('wsToolbarButtons2d');

        var scaleIcon = document.createElement('img');
        scaleIcon.classList.add('wsToolbarIcon');
        scaleIcon.title = "Scale";
        scaleIcon.id = 'img_Scale';
        scaleIcon.src = ScaleIcon;
        scaleIcon.addEventListener('click',  () => {
            // Scale Clicked
            this.editor.trigger('transformModeChanged', ['scale'])
        });
        this.uiScaleContainer.appendChild(scaleIcon);

        uiContainer.dom.appendChild(this.uiTranslateContainer);
        // uiContainer.dom.appendChild(this.uiScaleContainer);
        uiContainer.dom.appendChild(this.uiDeleteContainer);

        this.dom = uiContainer.dom;
    }

    onObjectSelected = (object) => {
        //For delete!
        if(object && object.userData && ( object.userData.type === 'floorplan' || 'nodeGraph' in object.userData )) {
            this.uiDeleteContainer.classList.add('wsToolbarDisabled');
        } else {
            this.uiDeleteContainer.classList.remove('wsToolbarDisabled');
        }
    }

    onTransformModeChanged = (transformMode) => {
        this.uiTranslateContainer.classList.remove('wsToolbarSelected2d');
        this.uiScaleContainer.classList.remove('wsToolbarSelected2d');

        switch(transformMode) {
            case 'translate': this.uiTranslateContainer.classList.add('wsToolbarSelected2d'); break;
            case 'scale': this.uiScaleContainer.classList.add('wsToolbarSelected2d'); break;
            default: break;
        }
    }

    onDeleteSelectedAsset = () => {
        let object = this.editor.selectedObject;
        if(object !== null && object.parent !== null) {
            this.editor.onCommand(new RemoveObject(this.editor, object, false), 'RESTRICT_UNDO');
        }
    }

}

export { ToolbarTransforms }