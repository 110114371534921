import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
import { UIPanel } from "../ui.lib";
import { FloatingMenuTransform } from "./FloatingMenu.Transformations";
import { FloatingMenuActions } from "./FloatingMenubar.ActionButtons";
import { FloatingMenuElevation } from "./FloatingMenubar.Elevation";

class FloatingMenubar extends EventEmitter {
  constructor(props) {
    super(props);
    this.editor = new EditorExperience();
    this.strings = this.editor.strings;
    this.parentEle = props.dom;

    // this.editor.on("refreshUI", this.onRefreshUI);
    this.editor.on("nodeSelected", this.onNodeSelected);
    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on("objectSelectedFromGuidedTour", this.onObjectSelected);

    this.initUI();
  }

  initUI = () => {
    this.uiContainer = new UIPanel();
    this.uiContainer.addClass("wsFloatingMenuContainer")

    this.floatingTransforms = new FloatingMenuTransform(this.editor);
    this.floatingActions = new FloatingMenuActions(this.editor);
    this.floatingElevation = new FloatingMenuElevation(this.editor);

    this.uiContainer.dom.appendChild(this.floatingTransforms.dom);
    this.uiContainer.dom.appendChild(this.floatingActions.dom);
    this.uiContainer.dom.appendChild(this.floatingElevation.dom);

    this.uiContainer.setDisplay('none');
    this.parentEle.append(this.uiContainer.dom);
  };

  onNodeSelected = (node) => {
    if(node) {
      this.uiContainer.setDisplay("");
    } else {
      this.uiContainer.setDisplay("none");
    }
  }

  onObjectSelected = (object) => {
    if(object) {
      this.uiContainer.setDisplay('');
    } else {
      this.uiContainer.setDisplay('none');
    }
  }
}

export { FloatingMenubar };
