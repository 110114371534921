import { colors, createTheme } from '@mui/material';
import shadows from './shadows';
import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: '#030E31'
    },
    secondary: {
      main: colors.blueGrey[600]
    },
    primaryRed: {
      main: '#e50027'
    },
    errorRed: {
      main: '#BD0101'
    },
    successGreen: {
      main: '#08974B'
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    },
    /* NEW COLORS */
    primaryColors: {
      main: '#353E5A',
    },
    darker: {
      main: '#030E31',
      darkerBlue01: '#212738',
      darkerBlue02: '#4E5D8A',
      darkerBlue03: '#212739',
      darkerBlue04: '#5D657B',
    },
    neutralColors: {
      lighter: '#DDDEE0',
      white: '#FFFFFF'
    },
    accentBlue: {
      main: '#2A4DC2'
    },
    textColors: {
      primary: '#353E5A',
      bodycolor: '#353E5A',
      headline2: '#717171',
      textLink: '#2A4DC2',
    }
  },
  shadows,
  typography
});

export default theme;


    /* darkerBlue01:{
      main:  '#212738'
    },
    darkerBlue2: {
      main: '#4E5D8A'
    }, 
    lighter: {
      main: '#DDDEE0'
    }, */
