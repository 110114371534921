import { Command } from './Command';

function SetVisible( editor, object, newValue, delayAutoSave, triggerDeselect = true, sessionVisibility = false) {
    Command.call(this, editor);

    this.type = 'SetVisible';
    this.name = `Set Visible`;
    this.updatable = true;

    this.object = object;
    this.oldValue = object !== undefined ? object['visible'] : undefined;
    this.newValue = newValue;
    this.triggerDeselect = triggerDeselect;
    this.delayAutoSave = delayAutoSave;
    this.sessionVisibility = sessionVisibility;

}

SetVisible.prototype = {
    execute: function() {
        var scope = this;
        this.object['visible'] = this.newValue;
        !this.sessionVisibility && (this.object.userData['visible' ] = this.newValue);

        (function toggleVisibility(objects, val){
            for(var i = 0 ; i < objects.length; i++) {
                let obj = objects[i];

                obj['visible'] = val;

                if(obj.children.length > 0) {
                    toggleVisibility(obj.children, scope.newValue);
                }
            }
        })(this.object.children, this.newValue);

        let parent = this.object.parent;
        while(parent !== this.editor.scene) {
            parent['visible'] = this.newValue;
            parent = parent.parent;
        }

        this.newValue === false && this.triggerDeselect && this.editor.deselect();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave, null, this.sessionVisibility]); //3rd param for old value on other commands
        !this.triggerDeselect && this.editor.trigger('sidebarVisibleChanged', [this.object]);
    },

    undo: function() {
        var scope = this;
        this.object['visible'] = this.oldValue;
        (function toggleVisibility(objects, val){
            for(var i = 0 ; i < objects.length; i++) {
                let obj = objects[i];

                obj['visible'] = val;

                if(obj.children.length > 0) {
                    toggleVisibility(obj.children, scope.oldValue);
                }
            }
        })(this.object.children, this.oldValue);

        let parent = this.object.parent;
        while(parent !== this.editor.scene) {
            parent['visible'] = this.oldValue;
            parent = parent.parent;
        }

        this.oldValue === false && this.triggerDeselect && this.editor.deselect();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave]);
        !this.triggerDeselect && this.editor.trigger('sidebarVisibleChanged', [this.object]);
    },

    update: function(command) {
        this.newValue = command.newValue;
    },

    toJSON: function() {
        var output = Command.prototype.toJSON.call(this);

        output.objectUuid = this.object.uuid;
        output.oldValue = this.oldValue;
        output.newValue = this.newValue;
        output.delayAutosave = this.delayAutosave;
        output.triggerDeselect = this.triggerDeselect;

        return output;
    }
}

export { SetVisible };