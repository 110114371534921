// Creates waypoinbt destination and a line to draw between desired points!
import EditorExperience from '../../MinEditorExperience';
import EventEmitter from '../../../utils/EventEmitter';

import NavLine from './NavLine';
import { NavPoint } from './NavPoint';
import LocationPin from './LocationPin';


class Navigation extends EventEmitter {
    constructor(wayProps, withWaypointsAndDest, withDestination, withWaypoints) {
        super();
        this.editor = new EditorExperience();
        this.wayProps = wayProps;
        this.name = wayProps instanceof Array ? "" : wayProps.name ? wayProps.name.substring(0, 20) : "Sample Waypoint";
        this.description = wayProps instanceof Array ? "" : wayProps.description || "Sample Description";
        this.id = wayProps instanceof Array ? "" : wayProps.id

        this.wayPoints = [];
        this.destination = null;

        this.withWaypointsAndDest = withWaypointsAndDest;
        this.withDestination = withDestination;
        this.withWaypoints = withWaypoints;

        if(withWaypointsAndDest) {
            this.createWayPoints(0, this.wayProps.waypoints);
            this.createDestination(0);
            this.createLineGeometry(0);

        } else if(withDestination) {
            this.createDestination(1);
            
        } else if(withWaypoints) {
            this.name = wayProps[0].name;
            this.description = wayProps[0].description || "";
            this.id = wayProps[0].id;

            this.createWayPoints(2, this.wayProps);
            this.createLineGeometry(2);
        }
    }

    createWayPoints = (mode, argWaypoints) => {
        if(argWaypoints) {
            for(const waypoint of argWaypoints) {
                this.wayPoints.push(new NavPoint(waypoint));
            }
        }
        if(mode === 1) { // Both
            this.wayPoints.push(new NavPoint(this.wayProps));
        }
    }

    createDestination = (mode) => {
        this.destination = new LocationPin(this.wayProps, mode);
    }
    
    createLineGeometry = (mode) => {
        this.wayPoints.length > 0 && (this.line = new NavLine({vertices: this.wayPoints, name: this.name, description: this.description, id: this.id}, mode));
    }
}

export default Navigation;