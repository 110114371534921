import * as THREE from "three";
import EditorExperience from "../../EditorExperience";
import { isObject, omit, cloneDeep, isEmpty, has } from "lodash";
import {
    fromPos2dObjectToVec3Pos,
    fromPosObjectToVec3Pos,
    getTransformed2DPos,
    getTransformed2DPosV1,
    getTransformed3DPos,
    getTransformed3DPosV1,
} from "../TransformConversions";
import {
    getPinSpriteTexture,
    getPinColorIndex,
    getTexNameFromId,
} from "../../2d-editor/threeUtils/Pins/PinUtils";
import { dispose } from "../../utils/Dispose.Utils";

export default class LocationPin {
    constructor(pinProps) {
        this.pinProps = pinProps;

        this.editor = new EditorExperience();
        this.pinType = pinProps.pinType;

        this.name = pinProps.name || "";
        this.description = pinProps.description || "Sample Description";
        this.id = pinProps.id;
        this.visible = pinProps.visible || true;

        this.amenityType =
            this.pinType === "amenity" && pinProps.amenityPinTypes.length
                ? pinProps.amenityPinTypes[0]
                : null;
        this.amenityType = isObject(this.amenityType)
            ? this.amenityType.id
            : this.amenityType;
        this.texType =
            this.pinType === "amenity"
                ? getTexNameFromId(this.amenityType)
                : "location";

        this.scaleY = 0.9;
        this.scaleXZ = this.texType === "location" ? 0.8 : 0.7;

        // props
        this.images = pinProps.images || [];
        this.pinColor = pinProps.pinColor || "#36CCE7";
        this.pinNavStyle = pinProps.navigationStyle || "default";
        this.pinCategories = pinProps.categories || [];

        // advanced
        this.logo = pinProps.advanced?.logo || "";
        this.file = pinProps.advanced?.file || "";
        this.mobile = pinProps.advanced?.mobile || "";
        this.email = pinProps.advanced?.email || "";
        this.note = pinProps.advanced?.note || "Sample Note";
        this.websiteLabel = pinProps.advanced?.label || "";
        this.websiteLabel = pinProps.advanced?.link || "";
        this.email = pinProps.advanced?.email || "";

        this.position = pinProps.position
            ? fromPosObjectToVec3Pos(pinProps.position)
            : new THREE.Vector3(0, 0, 0);
        this.position2d = pinProps.position2d
            ? fromPos2dObjectToVec3Pos(pinProps.position2d)
            : new THREE.Vector3(0, 0, 0);
        this.isMoved = pinProps.isMoved || false;

        if (this.isMoved) {
            ++this.editor.movedPinsCount;
        }

        this.mesh = {};

        this.editor.jsonNavPins.push(pinProps);

        this.editor.on("repositionYOfObjects", this.onRepositionYOfObjects);
        this.editor.on(
            "updateObject3DPositionsWithNewFloorMatrix",
            this.onUpdateObject3DPositions
        );
        this.editor.on("callDestructor", this.onCallDestructor);
        this.editor.on("objectRemoved", this.onCallDestructor);
        this.editor.resources.on("ready", this.onReadyToUpdate2dPosition);
        this.editor.on("floorDataReady", this.onReadyToUpdate2dPosition);

        this.setUpPin();
    }

    setUpPin = () => {
        const spriteTex = new THREE.TextureLoader().load(
            getPinSpriteTexture(this.texType, getPinColorIndex(this.pinColor))
        );
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        const spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            side: THREE.DoubleSide,
            transparent: true,
        });

        this.mesh = new THREE.Sprite(spriteMaterial);
        this.mesh.position.copy(this.position);
        this.mesh.scale.set(this.scaleXZ, this.scaleY, this.scaleXZ);
        this.mesh.rotation.y = THREE.MathUtils.degToRad(180);

        this.mesh.position.y =
            (this.editor.floorY ? this.editor.floorY : this.mesh.position.y) *
            (this.pinType === "regular"
                ? this.scaleY / 1.38
                : this.scaleY / 1.48);

        this.mesh.name = this.name;
        this.mesh.userData["type"] = "Location Pin";
        this.mesh.userData["id"] = this.id;
        this.mesh.userData["description"] = this.description;
        this.mesh.userData["visible"] = this.visible;
        this.mesh.userData["transformation"] = "NO_TRANSFORM"; //only to hide transformation controls in edit mode
        this.mesh.userData["skipChild"] = true;
        this.mesh.userData["interactive2D"] = true;

        this.mesh.userData["navStyle"] = this.pinNavStyle;
        this.mesh.userData["pinType"] = this.pinType;
    };

    onRepositionYOfObjects = (yValue) => {
        this.mesh.position.y =
            yValue *
            (this.pinType === "regular"
                ? this.scaleY / 1.38
                : this.scaleY / 1.48);
    };

    prepareObjForAPI = (reqObj) => {
        reqObj.amenityPinTypes = reqObj.amenityPinTypes.length
            ? reqObj.amenityPinTypes.map((type) => {
                  if (typeof type === "object") return type.id;
                  else return type;
              })
            : [];

        reqObj.categories = reqObj.categories?.length
            ? reqObj.categories.map((type) => {
                  if (typeof type === "object") return type.id;
                  else return type;
              })
            : [];

        if (this.pinType !== "amenity") {
            let advanced = { ...reqObj.advanced };
            advanced.logo =
                typeof advanced.logo === "object"
                    ? advanced.logo?.link
                    : advanced.logo;
            advanced.file =
                typeof advanced.file === "object"
                    ? advanced.file?.link
                    : advanced.file;
            reqObj.advanced = { ...reqObj.advanced, ...advanced };
        }

        reqObj.images = reqObj.images?.map((img) => ({
            url: img.link ? img.link : img.url,
            order: img.order,
        }));

        return { ...reqObj };
    };

    onUpdateObject3DPositions = () => {
        const idx = this.editor.getIndex("jsonNavPins", this.id);
        if (idx === -1) return;
        let reqObj = { ...this.editor.jsonNavPins[idx] };
        let changeObj = {
            id: reqObj.id,
        };

        reqObj.position2d = changeObj["position2d"] = {
            posX: this.position2d.x,
            posY: Math.abs(this.position2d.y),
        };
        const pos2d = this.getTransformedPos({
            posX: this.position2d.x,
            posY: Math.abs(this.position2d.y),
        });
        reqObj.position = changeObj["position"] = pos2d;

        this.mesh.position.copy(fromPosObjectToVec3Pos(pos2d));
        this.mesh.position.y =
            (this.editor.floorY ? this.editor.floorY : this.mesh.position.y) *
            (this.pinType === "regular"
                ? this.scaleY / 1.38
                : this.scaleY / 1.48);
        this.position.copy(this.mesh.position);

        this.editor.jsonNavPins[idx] = reqObj;
        this.editor.pinReadjustObj.push(changeObj);
        this.editor.trigger("requestPinPathReadjustment");
    };

    onReadyToUpdate2dPosition = () => {
        if (this.isMoved && !isEmpty(this.editor.floorData) && has(this.editor.floorData, 'floorWorldMatrix')) {
            // update 2D pos since 3d updated from app
            const idx = this.editor.getIndex("jsonNavPins", this.id);
            if (idx === -1) return;
            let reqObj = { ...this.editor.jsonNavPins[idx] };
            reqObj = omit(reqObj, ["wsType", "type", "amenityType"]);
            reqObj = this.prepareObjForAPI(reqObj);

            let pos2d = cloneDeep(
                this.get2dPosFrom3dPos(this.position.clone())
            );
            this.isMoved = reqObj.isMoved = false;

            reqObj.position2d = pos2d;
            this.position2d = fromPos2dObjectToVec3Pos(cloneDeep(pos2d));

            this.editor.jsonNavPins[idx] = reqObj;
            this.editor.pinMovedObj.push(reqObj);
            this.editor.trigger("requestUpdateMovedPins");
        }
    };

    getTransformedPos = (pos) => {
        const { imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6
            ? getTransformed3DPos(
                  this.editor.floorWorldMatrix,
                  imgWidth,
                  imgHeight,
                  pos,
                  true
              )
            : getTransformed3DPosV1(
                  this.editor.floorWorldMatrix,
                  imgWidth,
                  imgHeight,
                  pos,
                  true
              );
    };

    get2dPosFrom3dPos = (pos3d) => {
        const { imgWidth, imgHeight } = this.editor.floorData;
        let invWorldMatrix = this.editor.floorWorldMatrix?.clone();
        invWorldMatrix = invWorldMatrix.invert();
        return this.editor.floorplanVersion !== 2.6
            ? getTransformed2DPos(
                  invWorldMatrix,
                  imgWidth,
                  imgHeight,
                  pos3d,
                  true
              )
            : getTransformed2DPosV1(
                  invWorldMatrix,
                  imgWidth,
                  imgHeight,
                  pos3d,
                  true
              );
    };

    onCallDestructor = (object) => {
        if (object === this.mesh) {
            this.editor.stop(
                "repositionYOfObjects",
                this.onRepositionYOfObjects
            );
            this.editor.stop(
                "updateObject3DPositionsWithNewFloorMatrix",
                this.onUpdateObject3DPositions
            );
            this.editor.stop("callDestructor", this.onCallDestructor);
            this.editor.stop("objectRemoved", this.onCallDestructor);
            this.editor.resources.stop("ready", this.onReadyToUpdate2dPosition);

            dispose(this.mesh);
        }
    };
}
