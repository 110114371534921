import EditorExperience from "../../2DEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";

import {
  UIFloorDropDownCard,
  UIImage,
  UIPanel,
  UIRow,
  UIText,
} from "../../../ui/ui.lib";
import FloorIcon from "../../../../../assets/svgs/scenes/menu/floorplan.svg";
import ChevronIcon from "../../../../../assets/svgs/scenes/chevron.svg";
import { generateFloorListWithName } from "../../threeUtils/Connectors/ConnectorUtils";

class Menu2DFloorList extends EventEmitter {
  constructor(props) {
    super(props);
    this.editor = new EditorExperience();
    this.dom = null;

    this.editor.venueFloorList = this.venueFloorList = this.editor.editor3d
      .buildingData
      ? generateFloorListWithName(this.editor.editor3d.buildingData)
      : [];
    this.editor.activeFloor = this.activeFloor = this.venueFloorList.length
      ? this.getActiveFloor(this.venueFloorList)
      : null;

    this.dropOpen = false;
    this.toInteract = true;

    this.initUI();

    this.editor.resources.on("ready", this.onReady);
    this.editor.on("toggleMenuActive", this.onToggleMenuActive);
    this.editor.on(
      "2DSidebarSceneGraphChanged",
      this.on2DSidebarSceneGraphChanged
    );
    this.editor.on(
      "continueConnectorPlacement",
      this.onContinueConnectorPlacement
    );
    this.editor.on("navigationTracking", this.handleNavigationTracking);
  }

  initUI = () => {
    this.uiContainer = new UIPanel();
    this.uiContainer.setClass("wsMenu2DFloorListCont");

    const headerRow = new UIRow().addClass("wsMenu2DFloorListCont--headRow");
    this.uiActiveFloorText = new UIText(
      "80%",
      this.activeFloor ? this.activeFloor.typeName : "",
      true
    ).addClass("wsMenu2DFloorListCont--headerText");

    headerRow.addChild(new UIImage(FloorIcon, "26px", "26px"));
    headerRow.addChild(this.uiActiveFloorText);
    this.chevronIcon = new UIImage(ChevronIcon, "26px", "26px");
    headerRow.addChild(this.chevronIcon);

    headerRow.onClick((e) => {
      e.stopPropagation();
      this.onToggleDropOpen();
    });

    this.uiDropContainer = new UIPanel().addClass("wsMenu2DDropContainer");
    this.uiDropContainer.setDisplay("none");

    this.uiContainer.addChild(headerRow);
    this.uiContainer.addChild(this.uiDropContainer);

    this.venueFloorList.length && this.initDropList();

    this.dom = this.uiContainer;
  };

  initDropList = () => {
    const uiCardsRow = new UIPanel();
    uiCardsRow.addClass("wsMenu2DDropContainer--dropPanel");
    this.emptyContent();
    this.venueFloorList.forEach((floor, index) => {
      const uiRowCard = new UIFloorDropDownCard(
        this.editor,
        floor,
        this.onFloorSelect,
        index
      );
      uiCardsRow.addChild(uiRowCard);
    });
    this.uiDropContainer.addChild(uiCardsRow);
  };

  getActiveFloor = (list) => {
    return list.find((l) => l.mapId === this.editor.activeMap);
  };

  emptyContent = () => {
    var child = this.uiDropContainer.dom.lastChild;
    while (child) {
      this.uiDropContainer.dom.removeChild(child);
      child = this.uiDropContainer.dom.lastChild;
    }
  };

  checkParent(parent, child) {
    let node = child.parentNode;
    // keep iterating unless null
    while (node !== null) {
      if (node === parent) {
        return true;
      }
      node = node.parentNode;
    }
    return false;
  }

  handleClick = (e) => {
    !this.checkParent(this.uiContainer.dom, e.target) &&
      this.onToggleDropOpen();
  };

  onToggleDropOpen = () => {
    this.dropOpen = !this.dropOpen;

    if (this.dropOpen) {
      this.dropOpen = true;
      this.uiDropContainer.setDisplay("");
      this.chevronIcon.setStyle("transform", "rotate(180deg)");
      window.addEventListener("click", this.handleClick, true);
    } else {
      this.dropOpen = false;
      this.uiDropContainer.setDisplay("none");
      this.chevronIcon.setStyle("transform", "rotate(0deg)");
      window.removeEventListener("click", this.handleClick, true);
    }
  };

  onReady = () => {
    this.toInteract = !this.editor.isFloorMap;
    if (this.editor.isFloorMap) {
      this.uiContainer.setDisplay("none");
    } else {
      this.uiContainer.setDisplay("");
      this.editor.venueFloorList = this.venueFloorList = this.editor.editor3d
        .buildingData
        ? generateFloorListWithName(this.editor.editor3d.buildingData)
        : [];
      this.editor.activeFloor = this.activeFloor = this.venueFloorList.length
        ? this.getActiveFloor(this.venueFloorList)
        : null;
      this.uiActiveFloorText.setTextContent(
        this.activeFloor ? this.activeFloor.typeName : ""
      );
      this.venueFloorList.length && this.initDropList();
      this.editor.activeFloor && this.editor.trigger("updateConnectorPrename");
    }
  };

  onContinueConnectorPlacement = (content) => {
    if (content && content.floorData) {
      this.onFloorSelect(content.floorData);
    }
  };

  onFloorSelect = (content, key) => {
    if ("isFloorAligned" in content && !content.isFloorAligned) return;
    if (content.mapId !== this.editor.activeMap) {
      // console.log("Selected", content);
      this.editor.onSaveAndExit(true);
      this.editor.editor3d.trigger("switchBetweenMaps", [content, true]);
    }
  };

  onToggleMenuActive = (name) => {
    if (!this.toInteract) return;

    if (
      name === "Connector Pins" ||
      name === "Location Pins" ||
      this.editor.isFloorMap
    ) {
      this.uiContainer.setDisplay("none");
    } else {
      this.uiContainer.setDisplay("");
    }
  };

  on2DSidebarSceneGraphChanged = (object) => {
    if (!this.toInteract) return;

    if (object) {
      this.uiContainer.setDisplay("none");
    } else {
      this.uiContainer.setDisplay("");
    }
  };

  handleNavigationTracking = (flag) => {
    if (flag) {
      this.uiContainer.addClass("wsMenu2DFloorListCont--disabled");
    } else {
      this.uiContainer.removeClass("wsMenu2DFloorListCont--disabled");
    }
  };
}

export { Menu2DFloorList };
