import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../api";

export const getContents = createAsyncThunk("content/all", async (value) => {
  try {
    const url = value.type
      ? `/v1/asset/?limit=12&page=${value.page + 1}&contentType=${value.type}${
          value.sortBy ? `&sort=${value.sortBy}` : ""
        }`
      : `/v1/asset/?limit=12&page=${value.page + 1}&excludeMaps=true${
          value.sortBy ? `&sort=${value.sortBy}` : ""
        }`;
    const contentList = await Server.get(url);
    return contentList.data;
  } catch (error) {
    return [];
  }
});

export const searchContents = createAsyncThunk(
  "content/searchContents",
  async (value) => {
    try {
      const url = value.type
        ? `/v1/asset/?limit=12&page=${value.page + 1}&contentType=${
            value.type
          }${value.query ? `&q=${encodeURIComponent(value.query)}` : ""}${
            value.sortBy ? `&sort=${value.sortBy}` : ""
          }`
        : `/v1/asset/?limit=12&page=${value.page + 1}&excludeMaps=true${
            value.query ? `&q=${encodeURIComponent(value.query)}` : ""
          }${value.sortBy ? `&sort=${value.sortBy}` : ""}`;
      const contentList = await Server.get(url);
      // console.log(contentList);
      return contentList.data;
    } catch (error) {
      return [];
    }
  }
);

export const deleteContents = createAsyncThunk(
  "content/delete",
  async (value) => {
    try {
      const contentList = await Server.post(`/v1/asset/delete`, {
        assetUrl: value.path,
      });
      if (contentList) {
        value.onSuccess();
      } else {
        value.onError();
      }
      return contentList.data.data;
    } catch (error) {
      value.onError();
      return [];
    }
  }
);

export const uploadContents = createAsyncThunk(
  "content/upload",
  async (value) => {
    try {
      const contentList = await Server.post(`/v1/asset/upload`, value.data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (contentList) value.onSuccess();
      return contentList.data.data;
    } catch (error) {
      return [];
    }
  }
);

const initialState = {
  data: null,
  isLoading: false,
  searchResult: null,
  totalPages: 0,
  page: 0,
  isUploading: false,
};

const contentSlice = createSlice({
  name: "content",
  initialState,
  reducers: {
    resetState: () => {
      return { ...initialState };
    },
  },
  extraReducers: {
    [getContents.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [getContents.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        data: payload?.data.filter((item) => item?.contentType !== "maps"),
        totalPages: payload.totalPages,
        page: payload.page,
        searchResult:null
      };
    },
    [deleteContents.fulfilled]: (state, { payload }) => {
      const filteredContent = state?.data?.filter(
        (item) => !(item?.path === payload)
      );
      return {
        ...state,
        isLoading: false,
        data: filteredContent,
      };
    },
    [uploadContents.pending]: (state, { payload }) => {
      return {
        ...state,
        isUploading: true,
      };
    },
    [uploadContents.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        isUploading: false,
      };
    },
    [searchContents.pending]: (state, { payload }) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    [searchContents.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        isLoading: false,
        searchResult: payload.data,
        data:null
      };
    },
  },
});
export const { resetState } = contentSlice.actions;
export const contentData = (state) => state?.content?.data;
export const totalPages = (state) => state?.content?.totalPages;
export const currentPage = (state) => state?.content?.page;
export const contentLoadingData = (state) => state?.content?.isLoading;
export const uploadingData = (state) => state?.content?.isUploading;
export const searchData = (state) => state?.content?.searchResult;
export default contentSlice.reducer;
