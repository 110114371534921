import { Button, Form, Input, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
// Actions
import { deleteResetResponse, resetPassword } from "../../../features/auth/authSlice";
import { forgetPassResponse } from "../../../features/auth/authSlice"
//Icons
import SvgIcn from '../../../assets/svgs/ARWay_Primary_Logo_Brand.svg'
// Routes
import {
    LOGIN,
    VERIFY_OTP
} from '../../_utils/routes';

export const ForgotPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const forgetPassVal = useSelector(forgetPassResponse)
    const [error, setError] = useState({err:false, errMsg:null})
    const [email, setEmail] = useState(null)
    const [typing, setTyping] = useState(false)
    useEffect(() => {
        if(forgetPassVal){
        }
        if(forgetPassVal&& forgetPassVal.code>299){
            // alert(forgetPassVal?.message)
            setError({
                err:true,
                errMsg:forgetPassVal?.message
            })
        }
        if(forgetPassVal && (!forgetPassVal.code)){
            // alert(forgetPassVal?.message)
            navigate(VERIFY_OTP)
        }

        return () => {
            dispatch(deleteResetResponse())
        }
    },[forgetPassVal, dispatch, navigate])

   

    const handleSubmit = (event) => {
        event.preventDefault()
        if(!email || email.trim().length <= 0 ||
            !String(email).toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            setError({
                err:true,
                errMsg: "Please enter a valid email address"
            })
        } else {
            dispatch(resetPassword({email:email}))
        }
    }

    return(
        <Layout className="main">
            <Layout className="form-containers">
                <img className="brand-logo" alt="brand-logo-3" src={SvgIcn} />
                <h5 className="login-header" id="h5-forgotPass">Forgot Password?</h5>
                <p className="reset-guide">Enter the email you signed up with and we will send you the verification code to reset your password.</p>
                {/* <Form
                        name="forgotPassword"
                        initialValues={{ remember: true }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    > */}
                    <form onSubmit={handleSubmit}>
                    {/* <Form.Item
                        name="email"
                        rules={[
                        {
                            type: 'email',
                            message: 'The input is not a valid E-mail!',
                        },
                        {
                            required: true,
                            message: 'Please input your E-mail!',
                        },
                        ]}
                    >
                        <Input type='email' className={error ? `error` : null } placeholder="Email"/>
                    </Form.Item> */}
                    <Layout className="input-label-container">
                        {typing?(<label className="typing-label">Email</label>):null}
                        <Input 
                            type='text' 
                            className={`text-inputs ${error.err ? `error` : null } `}
                            placeholder="Email"
                            onChange={(e) => {
                                setEmail(e.target.value)
                                setError({err:false, errMsg:null})
                            }}
                            onFocus={() => {
                                setTyping(true)
                            }}
                            onBlur={() => {
                                setTyping(false)
                            }}
                            id='input-forgotEmail'
                        />
                        {error.err?(<label className="errors" id='error-forgotEmail'>{error.errMsg}</label>):null}
                    </Layout>
                    

                    <Form.Item>
                        <Button className="signin-button" type="primary" htmlType="submit" id='button-forgotNext'>
                            <span  className="signin-button--spanText"> Next </span>
                        </Button>
                    </Form.Item>
                    <div className="account-exists " id='link-forgotLogin'><Link className="atag" to={LOGIN}> Return to Log in</Link></div>
                    </form>
                {/* </Form> */}
            </Layout>
        </Layout>
    )
}