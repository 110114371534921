import { 
  Grid, FormControl,
  Checkbox, 
  MenuItem, Select, 
  ListItemText
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { mapDetails } from "../../../../../features/user/studio/studioSlice";

import { useState, useEffect, useRef } from "react";
import GenerateQR from "../common/GenerateQR";
import { setNewAlert } from "../../../../../features/common/alertSlice";
import Loader from "../../../../common/loader";
import MessageModal from "../../../../common/modal";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DownloadQR = ({ setNext }) => {
  const mapData = useSelector(mapDetails);
  const [downloadCount, setDownloadCount] = useState(0);
  const [additional, setAdditional] = useState(0);
  const [selectedTypes, setSelectedTypes] = useState(['PNG']);
  const [additionalSelectedTypes, setAdditionalSelectedTypes] = useState(['PNG']);
  const [isDownloadingAdditional, setIsDownloadingAdditional] = useState(false);
  const [loaderModal, setLoaderModal] = useState(false);
  const [progress, setProgress] = useState(0);

  const childRef = useRef(null);
  const prevMapData = useRef();
  const parentDiv = useRef();

  const dispatch = useDispatch();

  const types = [ 'PNG', 'PDF' ];

  let mapCode = mapData?.metadata?.mapCode;
  let QRId = mapData?.metadata?.anchors.find(
    (anc) => anc.anchorIndex === "primary"
  )?.id;

  useEffect(() => {
    parentDiv.current && parentDiv.current.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    prevMapData.current = QRId;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapData]);

  useEffect(() => {
    if (!(downloadCount > 0 && (selectedTypes.length))) {
      setNext(true);
    } else {
      setNext(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTypes, downloadCount]);

  useEffect(() => {
    if (isDownloadingAdditional) {
      if(additionalSelectedTypes.length === 2) {
        childRef.current.handleDownloadMultipleQRMultipleType(
          additional,
          (val) => setLoaderModal(val),
          (val) => setProgress(val),
          (val) => setIsDownloadingAdditional(val),
          additionalSelectedTypes.map(m => m.toLowerCase())
        );
        setAdditional(0);
        setAdditionalSelectedTypes(['PNG']);
      } else if(additionalSelectedTypes.length === 1) {
        childRef.current.handleDownloadMultipleQR(
          additional,
          (val) => setLoaderModal(val),
          (val) => setProgress(val),
          (val) => setIsDownloadingAdditional(val),
          additionalSelectedTypes[0].toLowerCase()
        );
        setAdditional(0);
        setAdditionalSelectedTypes(['PNG']);
      } else {
        dispatch(setNewAlert({ msg: "Please select download type to download QR.", alertType: "information" }));
      }
      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDownloadingAdditional]);

  const handleDownload = () => {
    if(selectedTypes.length === 2) {
      childRef.current.handleDownloadZipQR();
    } else if(selectedTypes.length === 1){
      childRef.current.handleDownloadQR(selectedTypes[0] === 'PNG' ?'png':'pdf');
    } else {
      dispatch(setNewAlert({ msg: "Please select download type to download QR.", alertType: "information" }));
    }
  };

  const handleChange = (event) => {
    const {target: { value }} = event;
    if((typeof value === 'string' && value.length === 0) || value.length === 0) {
      dispatch(setNewAlert({ msg: "Please select download type to download QR.", alertType: "information" }));
    }
    setSelectedTypes(typeof value === 'string' ? value.split(',') : value);
  };

  const handleAdditionalChange = (event) => {
    const {target: { value }} = event;
    if((typeof value === 'string' && value.length === 0) || value.length === 0) {
      dispatch(setNewAlert({ msg: "Please select download type to download QR.", alertType: "information" }));
    }
    setAdditionalSelectedTypes(typeof value === 'string' ? value.split(',') : value);
  };

  const handleDownloadAdditional = () => {
    setIsDownloadingAdditional(true);
  };

  return (
    <div className="stepper" ref={parentDiv}>
      <div className="stepper__Header">
        Download, print, and place the primary QR code close to the center of
        your map environment for activation. You can scan this QR code with the
        ARway app to enter, edit or add content to your map.
      </div>
      <div className="stepper__Card">
        <Grid container={true}>
          <Grid item xs={6} className="mapinfo__separator">
            <div
              className="stepper__SectionHeader"
              style={{ justifyContent: "center" }}
            >
              {`QR Code ${mapData?.metadata?.mapName}`}
            </div>
            <div className="pt-4">
              {mapData ? (
                <GenerateQR
                  mapData={{
                    mapCode,
                    QRId,
                    mapName: mapData?.metadata?.mapName,
                  }}
                  ref={childRef}
                />
              ) : null}
            </div>
          </Grid>
          <Grid item xs={6} className="mapinfo__separator">
            <div className="stepper__SectionHeader">
              QR Code For Map Activation
            </div>
            <label
              className="content-type-select mt-4"
              style={{ lineHeight: "normal" }}
            >
              You must download, print, and activate a primary QR code first to
              anchor your map to the geo location - we recommend adding
              additional QR codes at multiple access points if you are mappng a
              large space.
            </label>
            <div className="content-type-select mt-4 mb-2">
              <h6>Download Type</h6>
              <FormControl>
                <Select
                  labelId="demo-multiple-checkbox-label"
                  id="demo-multiple-checkbox"
                  multiple
                  value={selectedTypes}
                  onChange={handleChange}
                  renderValue={(selected) => selected.join(', ')}
                  MenuProps={MenuProps}
                >
                  {types.map((name) => (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={selectedTypes.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <button
              id="button-download-primary-qr"
              className="button-next"
              onClick={() => {
                setDownloadCount((prevCount) => prevCount + 1);
                handleDownload();
              }}
              variant="contained"
              disabled={selectedTypes.length <= 0}
            >
              Download Primary QR Code
            </button>
          </Grid>
        </Grid>
      </div>
          <Grid item xs={6}>
          <div className="shadow p-3 mb-3 bg-body rounded mapdetails__QRCodeCard">
            <div className="stepper__SectionHeader">Additional QR Codes<span>(Optional)</span></div>
            <label
              className="content-type-select mt-4"
              style={{ lineHeight: "normal", textAlign: "center" }}
            >
              Download, print and post multiple QR codes in different areas of your map location to allow viewers 
              to open the experience from multiple access points.
            </label>
            <div className="stepper__qrCont">
              <div className="container" style={{ marginTop: "1rem" }}>
                <span
                  className="border border-dark mt-4 d-flex justify-content-between"
                  style={{
                    width: "100%",
                    height: "42px",
                    display: "inline-block",
                    borderRadius: '6px',
                  }}
                >
                  <span
                    className="p-2 ps-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      if (additional > 0) {
                        setAdditional(additional - 1);
                      }
                    }}
                    id="span-minusQR"
                  >
                    -
                  </span>
                  <label className="p-2" id="label-totalQRCount">
                    {additional}
                  </label>
                  <span
                    className="p-2 pe-4"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setAdditional(additional + 1);
                    }}
                    id="span-plusQR"
                  >
                    +
                  </span>
                </span>
              </div>
              <div className="content-type-select container" style={{ marginTop: "2rem", marginBottom: "2rem"  }}>
                {/* <div className="content-type-select mt-4 mb-2"> */}
                  <h6>Download Type</h6>
                  <FormControl>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      value={additionalSelectedTypes}
                      onChange={handleAdditionalChange}
                      renderValue={(selected) => selected.join(', ')}
                      MenuProps={MenuProps}
                    >
                      {types.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={additionalSelectedTypes.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                {/* </div> */}
              </div>
            </div>
            <button
              className={`button-next button-download ${additional <= 0 || additionalSelectedTypes.length <= 0 ? "button-next--disabled" : ""}`}
              onClick={handleDownloadAdditional}
              variant="contained"
              disabled={additional <= 0 || additionalSelectedTypes.length <= 0}
              id="button-downloadMultiQR"
            >
              Download Additional
            </button>
          </div>
        </Grid>

        <MessageModal
          openModal={loaderModal}
          onCloseModal={() => {}}
          inlineStyle={{ height: "80vh", width: "90vw" }}
        >
          <Loader
            loaderText={`Download is in progress`}
            height="100%"
            width="500vw"
            value={Math.round(progress * 100)}
          />
        </MessageModal>
    </div>
  );
};

export default DownloadQR;
