import {useState} from 'react'

function useDropDown(intitialValue) {
    const [value, setValue] = useState(intitialValue);
    const bind = {
        value,
        onChange: (e) => {
          setValue(e.target.value);
        },
      };
      return [value, bind]
}

export default useDropDown

