import { dataStore } from '../../features/store';

const getQRURL = ( anchorId ) => {

    const userPlanDetails = dataStore.getState().settings.planDetails;
    const urlDomain = userPlanDetails && userPlanDetails.domain && userPlanDetails.domain.length ? userPlanDetails.domain : 'anchor.arway.ai/map';

    return `https://${urlDomain}/${anchorId}`;
}

const shouldAddQRMargins = () => {
    const userPlanDetails = dataStore.getState().settings.planDetails;
    const urlDomain = userPlanDetails && userPlanDetails.domain && userPlanDetails.domain.length ? userPlanDetails.domain : 'anchor.arway.ai/map';
    const dotsInDomain = (urlDomain.match(/\./g))?.length ?? 0;

    if(dotsInDomain <= 1) {
        if(urlDomain.length >= 17) {
            return false;
        } 
        return true;
    } else {
        return false;
    }
}

export { getQRURL, shouldAddQRMargins };