import { Layout, Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import SvgIcn from '../../../assets/svgs/ARWay_Primary_Logo_Brand.svg'
import SuccessIcn from '../../../assets/svgs/access_check.svg'

// Routes
import {
    LOGIN,
} from '../../_utils/routes';

export const ResetSuccessful = () => {
    return(

        <Layout className="main">
            <Layout className="form-containers">
            <img className="brand-logo" alt="brand-logo-3" src={SvgIcn} />
                <h5 className="login-header" id="h5-resetSuccess">Reset Password</h5>
                <img className="success-icon" alt="success-icon-2" src={SuccessIcn} />
                <h5 className="reset-head">Your password has been updated!</h5>
                <p className="reset-guide">Tap the button below to go back to the login page and start creating AR map experiences.</p>
                
                <Button className="signin-button create-account" type="primary" >
                    <Link className="signin-button--spanText" to={LOGIN} id='link-resetLogin'>Back to Login</Link>
                </Button>
            </Layout>
        </Layout>
    )
}