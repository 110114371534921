import React from "react";
import { Box, Tab, Tabs,/* Typography */} from "@mui/material";

const a11yProps = (index) => {
  return {
    id: `contentlib-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  );
};

const ContentTabs = ({ activeTab, onTabChange, tabItems }) => {
  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={activeTab}
          onChange={onTabChange}
          aria-label="Content tabs"
          className="content-tabs-container"
        >
          {Object.keys(tabItems)?.map((label, idx) => (
            <Tab
              key={`label_${idx}`}
              label={label}
              {...a11yProps(idx)}
              className="content-tab"
              disableRipple
              classes={{ selected: "content-active-tab" }}
            />
          ))}
        </Tabs>
      </Box>
      {Object.keys(tabItems)?.map((item, idx) => (
        <TabPanel
          className="content-tab-panel"
          key={`item${idx}`}
          value={activeTab}
          index={idx}
        >
          {tabItems[item]}
        </TabPanel>
      ))}
    </>
  );
};

export default ContentTabs;
