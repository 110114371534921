import React, { useState, useEffect } from 'react';
import { isEmpty } from "lodash";
import { Box } from '@mui/material';

import { lastTEDays } from '../../../../../_utils/DateUtils';
import DatePicker from '../../../../../_utils/DatePicker';
import RenderTable from '../../../../users/analytics/Render/RenderTable'
import RenderPagination from '../../../../users/analytics/Render/RenderPagination';
import Loader from '../../../../../common/loader';
import { MapAreaUnit } from './MapAreaUnit';


const apWithMostActivationHeaders = [
    { key: "accountName", label: "Account Name" },
    { key: "mapName", label: "Map Name" },
    { key: "visits", label: "Visits" },
    { key: "mapArea", label: "Map Area" },
    { key: "activeDays", label: "Days Active" },
];

export default function TopMapVisitsCard({ topMapVisits, handleChange, totalResults, loading }) {

    const pageSize = 10;
    const [dateObj, setDateObj] = useState(lastTEDays);
    const [pageNumber, setPageNumber] = useState(1);
    const [areaSQFt, setAreaSQFt] = useState(true);


    useEffect(() => {
        handleChange({ ...dateObj, pageNumber, pageSize });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    const getRowData = () => {
        return topMapVisits.map(d => {
            return {
                accountName: d.accountName,
                mapName: d.mapName,
                visits: d.visits,
                mapArea: d.mapArea ? areaSQFt ? (d.mapArea).toFixed(2) : (d.mapArea / 10.764).toFixed(2) : '--',
                activeDays: d.activeDays,
            }
        })
    }

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col-9">
                        <div className="analyticshome__anCard--contentRow--header">
                            Total Number of Map Visits
                        </div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker cb={(dateObj) => {
                                setDateObj(dateObj);
                                handleChange({ ...dateObj, pageNumber, pageSize });
                            }} />
                        </Box>
                    </div>
                </div>
                <hr />
                {(isEmpty(topMapVisits) && !loading) ? (
                    <div className='analyticshome__emptyTextCont'>
                        <h5 className="text-center analyticshome__emptyStateText">{" "}No Data available{" "}</h5>
                    </div>
                ) : loading ? (
                    <div className='analyticshome__emptyTextCont'>
                        <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                    </div>
                ) : (
                    <div>
                        <MapAreaUnit
                            areaSQFt={areaSQFt}
                            setAreaSQFt={setAreaSQFt}
                        />
                        <RenderTable
                            thheaderData={apWithMostActivationHeaders}
                            rowsData={getRowData(topMapVisits)}
                        />
                        <RenderPagination
                            count={Math.ceil(totalResults / pageSize)}
                            page={pageNumber}
                            limit={pageSize}
                            handleChange={(e, page) => setPageNumber(page)}
                            cb={(page) => {
                                setPageNumber(page);
                            }}
                            totalResults={totalResults}
                        />
                    </div>
                )}
            </div>
        </div>
    )
}
