//Icons
import Manage from '../../../../assets/svgs/dash-landing/d_accounts.svg';
import UserAccess from '../../../../assets/svgs/dash-landing/d_content_library.svg';
import Analytics from '../../../../assets/svgs/dash-landing/d_analytics.svg';
import Impersonate from '../../../../assets/svgs/dash-landing/d_impersonate.svg';
import Report from '../../../../assets/svgs/dash-landing/d_report.svg';

//Links
import {
    D_CONTENT_ANALYTICS, 
    D_CONTENT_USERS, D_CONTENT_IMPERSONATE,
    D_CONTENT_MAPS, D_CONTENT_MANAGE_ACCOUNTS
} from '../../../_utils/routes';

export const linksCardData = [
    {
        id: '1',
        title: 'Manage Accounts',
        icon: Manage,
        content: 'View and manage all of the uploaded content',
        link: D_CONTENT_MANAGE_ACCOUNTS,
        linkText: 'Manage Accounts'
    },
    {
        id: '2',
        title: 'Impersonate',
        icon: Impersonate,
        content: 'View and explore into an user account.',
        link: D_CONTENT_IMPERSONATE,
        linkText: 'Impersonate User'
    },
    {
        id: '3',
        title: 'Admin Analytics',
        icon: Analytics,
        content: 'Explore how all maps are being viewed and where viewer interest is at',
        link: D_CONTENT_ANALYTICS,
        linkText: 'View Analytics'
    },
    {
        id: '4',
        title: 'User Access',
        icon: UserAccess,
        content: 'View all users and their plan details.',
        link: D_CONTENT_USERS,
        linkText: 'View User Access'
    },
    {
        id: '5',
        title: 'Reported Contents',
        icon: Report,
        content: 'View all the reported maps & accounts.',
        link: D_CONTENT_MAPS,
        linkText: 'View Reported Contents'
    },
]
