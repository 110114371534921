import React, { useEffect, useState } from "react";
import { Form, Input, Button, Layout, Checkbox } from "antd";
import { Link, useNavigate } from "react-router-dom";
// import "./login.css";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  loginData,
  is2FaRequired,
  logout,
} from "../../../features/auth/authSlice";
import { getUserPlanDetails } from "../../../features/user/settings/settingsSlice";
import SvgIcn from "../../../assets/svgs/ARWay_Primary_Logo_Brand.svg";

import { Eye, EyeOff } from "react-feather";

// Routes
import {
  REGISTER,
  DASHBOARD_HOME,
  ACCOUNT_SETUP,
  PNF,
  FORGOT_PASSWORD,
  VERIFY_2FA,
} from "../../_utils/routes";

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userData = useSelector(loginData);
  const userAccessGranted = useSelector((state) => state.access.accessGranted);
  const is2FARequired = useSelector(is2FaRequired);

  const [rememberMe, setRememberMe] = useState(false);

  const [emailError, setEmailError] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [passErr, setPassErr] = useState();
  const [typing, setTyping] = useState({ email: false, password: false });
  const [loginFormData, setLoginFormData] = useState({
    email: null,
    password: null,
  });
  const [incorrectCreds, setIncorrectCreds] = useState(false);
  const [userUnverified, setUserUnverified] = useState(false);

  const isImpersonateUser = JSON.parse(sessionStorage.getItem("impersonateUser"));

  useEffect(() => {
    if (userData) {
      //1st put switch in if(userdata) cause if userdata is null it will not exicute the switch
      // console.log("LOGIN", userData);
    }
    if (userData && userData.code > 299) {
      // alert(userData?.message)
      // console.log(userData?.message.includes('verified'));
      if (userData?.message?.includes("verified")) {
        setUserUnverified(true);
      }
      setIncorrectCreds(true);
      dispatch(logout());
    }
    if (userData && !userData.code && userAccessGranted !== null) {
      if (userData.user.role) {
        if (userAccessGranted === "EXISTING_USER") {
          dispatch(getUserPlanDetails());
          navigate(DASHBOARD_HOME);
        } else if (userAccessGranted === "NEW_USER") {
          if (userData?.user?.role === "admin" || isImpersonateUser) {
            dispatch(getUserPlanDetails());
            navigate(DASHBOARD_HOME);
          } else {
            navigate(ACCOUNT_SETUP);
          }
        }
      } else {
        navigate(PNF);
      }
    }
  }, [userData, userAccessGranted, navigate, dispatch]);

  useEffect(() => {
    if (is2FARequired === true) {
      navigate(VERIFY_2FA);
    }
  }, [is2FARequired, navigate]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!loginFormData?.email) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
    if (!loginFormData?.password) {
      setPassErr(true);
    } else {
      setPassErr(false);
    }
    if (rememberMe) {
      localStorage.setItem("storeSession", true);
    } else {
      localStorage.removeItem("storeSession");
    }
    if (emailError === false && passErr === false) {
      dispatch(
        login({ email: loginFormData.email, password: loginFormData.password })
      );
    }
  };

  return (
    <Layout className="main">
      <Layout className="form-containers">
        <img className="brand-logo" alt="brand-logo-3" src={SvgIcn} />
        <h5 className="login-header" id="h5-login">
          Log In
        </h5>
        <div className="signupLink">
          <span className="signupLink--spanText">Don't have an account?</span>
          <Link className="atag" to={REGISTER}>
            Register Here.
          </Link>
        </div>
        <form onSubmit={handleSubmit}>
          <Layout className="input-label-container">
            {typing.email ? (
              <label className="typing-label">Email</label>
            ) : null}
            <Input
              type="email"
              className={`text-inputs ${
                emailError || userUnverified || incorrectCreds ? `error` : null
              }`}
              placeholder="Email"
              id="input-loginEmail"
              onChange={(e) => {
                setLoginFormData((prev) => ({
                  ...prev,
                  email: e.target.value,
                }));
                setEmailError(false);
                setIncorrectCreds(false);
                setUserUnverified(false);
              }}
              onFocus={() => {
                setTyping({ email: true });
              }}
              onBlur={() => {
                setTyping({ email: false });
              }}
            />
            {emailError ? (
              <label className="errors" id="error-loginEmail">
                Please enter a valid email address
              </label>
            ) : (
              [
                userUnverified ? (
                  <label className="errors" id="error-loginNotVerify">
                    User not verified.
                  </label>
                ) : (
                  [
                    incorrectCreds ? (
                      <label className="errors" id="error-loginInvalid">
                        Email or password incorrect
                      </label>
                    ) : null,
                  ]
                ),
              ]
            )}
          </Layout>
          <Layout className="input-label-container">
            {typing.password ? (
              <label className="typing-label">Password</label>
            ) : null}
            <Input
              className={`text-inputs ${
                passErr || userUnverified || incorrectCreds ? `error` : null
              }`}
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              id="input-loginPassword"
              onChange={(e) => {
                setLoginFormData((prev) => ({
                  ...prev,
                  password: e.target.value,
                }));
                setPassErr(false);
                setIncorrectCreds(false);
                setUserUnverified(false);
              }}
              onFocus={() => {
                setTyping({ password: true });
              }}
              onBlur={() => {
                setTyping({ password: false });
              }}
            />
            <span className="showpassCont">
              {showPassword ? (
                <EyeOff
                  strokeWidth={2}
                  size={16}
                  color={"#353E5A"}
                  onClick={() => setShowPassword(false)}
                  id="button-eyeOff"
                />
              ) : (
                <Eye
                  strokeWidth={2}
                  size={16}
                  color={"#353E5A"}
                  onClick={() => setShowPassword(true)}
                  id="button-eye"
                />
              )}
            </span>
            {passErr ? (
              <label className="errors" id="error-loginPass">
                Please enter a password
              </label>
            ) : (
              [
                userUnverified ? (
                  <label className="errors" id="error-loginPassNotVerify">
                    User not verified.
                  </label>
                ) : (
                  [
                    incorrectCreds ? (
                      <label className="errors" id="error-loginPassInvalid">
                        Email or password incorrect
                      </label>
                    ) : null,
                  ]
                ),
              ]
            )}
          </Layout>

          <Layout className="remember-forgot">
            <Form.Item valuePropName="checked">
              <Checkbox
                className="checkboxInput"
                onClick={() => {
                  setRememberMe((prev) => !prev);
                }}
                id="checkbox-remember"
                value={rememberMe}
              >
                <span className="checkboxText"> Remember me</span>
              </Checkbox>
            </Form.Item>
            <Link className="forgot-password atag" to={FORGOT_PASSWORD}>
              Forgot Password?
            </Link>
          </Layout>

          <Layout className="bottom-buttons">
            <Form.Item>
              <Button
                className="signin-button"
                type="primary"
                htmlType="submit"
                id="button-login"
              >
                <span className="signin-button--spanText">Log In</span>
              </Button>
            </Form.Item>
          </Layout>
        </form>
        {/* </Form> */}
      </Layout>
    </Layout>
  );
};
