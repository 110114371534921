import EditorExperience from "../../2DEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";

//Icon
import HelpIcon from '../../../../../assets/icons/sidebar/help.svg';

import DropdownIcon from '../../../../../assets/pngs/studio/locationPin/chevron_drop.png';

// utils
import {
  UIPanel, UIRow, UICol, UIText,
  UITextHeader, UIInputText,
  UIInputTextArea, UILocationPinImageContainer,
  UIImage, UISwitch, UIButton, UIMultiselect, 
  UIColorPalette, UIPinNavSelector,
  UIFileInput, UIRadioSelector,
  UINameIdenfierInput, UIDropDown,
  /* UICheckbox, */ UIDropDownMini, UIArrowCheckbox
} from '../../../ui/ui.lib';
import { pinColors } from "../../threeUtils/Pins/PinUtils";
import { 
  sortConnectorGroupDropDownOptions
} from "../../threeUtils/Connectors/ConnectorUtils";

// command
// import { SetUserDataValue } from '../../../commands/SetUserDataValue';

class Sidebar2DObject extends EventEmitter {

  constructor(props) {
    super(props);

    this.editor = new EditorExperience();
    this.dom = null;
    this.objProperties = {};
    this.multiselect = null;

    this.userDataProperties = [
      'description', 'pinColor',
      'navigationStyle', 'pinType',
      'pinCategories', 'logo',
      'file', 'mobile', 'email', 'note',
      'websiteLabel', 'websiteLink',
      'pinCategories', 'amenityChildren',
      'featuredPin','images', 'indexName',
      'connectorGroupId', 'wheelchairAccessible',
      'relatedAccessPointID', 'entryName',
      'direction'
    ]
    this.userDataHeaderProps = [
      'advancedSettings'
    ];

    this.openAdvancedPane = false; 

    this.entryOptions = [ { name: 'Enter', id: 'enter' }, { name: 'Exit', id: 'exit' }, { name: 'Both', id: 'both' } ];
    this.directionOptions = [ { name: 'Single Direction - Up', id: 'up' }, { name: 'Single Direction - Down', id: 'down' }, { name: 'Dual Direction - Both', id: 'both' } ];

    this.customUIConnectorTypes = [ 'escalator', 'stairs' ];

    this.initUI();

    this.editor.on('objectSelected', this.onObjectSelected);
    this.editor.on('objectChanged', this.onObjectChanged);
    this.editor.on('toggleMenuActive', this.onToggleMenuActive);
    this.editor.on('sidebarCategoryGraphChanged', this.onCategoriesChanged);
    this.editor.on('meta2dObjectChanged', this.onBufferedChanges);
    this.editor.on('connectorGroupOptionsChanged', this.onConnectorGroupOptionsChanged);

  }


  initUI = () => {

    this.uiContainer = new UIPanel();
    // this.uiContainer.setDisplay('none');

    // Location
    this.objectTypeHeader = new UITextHeader('100%', "Location").addClass('wsBolderHeader')
    this.uiContainer.addChild(this.objectTypeHeader);

    // ImageCard
    var objectImgPanel = new UICol();
    var objectImageRow = new UIRow();
    objectImageRow.addChild(new UIText('100%', "Images (Optional Max of 6)"));
    objectImgPanel.addChild(objectImageRow);
    this.UIImageCont = new UILocationPinImageContainer()
    this.UIImageCont.onChange(() => {
      const images = this.objectImageContainer.getImageFiles()
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'images', images, false, images.length, this.UIImageCont.imgActions])
    });
    this.objectImageContainer = this.UIImageCont
    objectImgPanel.addChild(this.objectImageContainer);
    this.uiContainer.addChild(objectImgPanel);
    this.objProperties['images'] = objectImgPanel


    // Feature Pin Row
    const uiFeaturedPinRow = new UIRow().addClass('wsFlexRowCenter');
    const uiPanel = new UIRow();
    uiPanel.addChild(new UITextHeader(undefined, 'Featured Pin'));
    uiPanel.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiFeaturedPinRow.addChild(uiPanel);

    this.objectFeaturedPinState = new UIText('10%', 'OFF');
    this.objectFeaturedPin = new UISwitch(false, true, 'ws_uiSliderFeaturedPin').onChange((e) => {
      const value = e.target.checked;
      this.objectFeaturedPinState.setTextContent(value ? 'ON' : 'OFF');
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'featuredPin', value]);
    })
    uiFeaturedPinRow.addChild(this.objectFeaturedPinState);
    uiFeaturedPinRow.addChild(this.objectFeaturedPin);
    this.uiContainer.addChild(uiFeaturedPinRow);
    this.objProperties['featuredPin'] = uiFeaturedPinRow;


    // Location Details
    var objectTypeRow = new UIRow();
    this.objectTypeText = new UIText(undefined, "Location Details");
    objectTypeRow.addChild(this.objectTypeText);
    objectTypeRow.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick((e) => this.handleInfoModal(e, 'onboarding')));

    this.uiContainer.addChild(objectTypeRow);

    // Name
    var objectNameRow = new UICol();
    this.objectNameInput = new UIInputText('objectTitle', '100%', undefined, 100, '',).onChange((e) => {
      var value = e.target.value;
      if (value.length <= 0) {
        this.editor.editor3d.callbacks.generateAlert({ msg: `Object name shouldn't be empty!`, alertType: 'information' });
      } else {
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'name', value])
      }
    });
    var objectNameRown = new UIRow().addClass('ws_uiTextContentRow');
    objectNameRown.addChild(new UIText(undefined, "Name"))
    objectNameRow.addChild(objectNameRown);
    objectNameRow.addChild(this.objectNameInput);
    this.uiContainer.addChild(objectNameRow);
    this.objProperties['name'] = objectNameRow;

    // Indexed Name
    var objectIdxNameRow = new UICol();
    this.objectIdxNameInput = new UINameIdenfierInput('', 2, '', '55px').onChange((e) => {
      var value = this.objectIdxNameInput.getValue();
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'indexName', value])
    });
    this.objectIdxNameInput.addClass('ws_uiNameContColor');
    var objectIdxNameRowLabel = new UIRow().addClass('ws_uiTextContentRow');
    objectIdxNameRowLabel.addChild(new UIText(undefined, "Name"))
    objectIdxNameRow.addChild(objectIdxNameRowLabel);
    objectIdxNameRow.addChild(this.objectIdxNameInput);
    this.uiContainer.addChild(objectIdxNameRow);
    this.objProperties['indexName'] = objectIdxNameRow;

    // ENtry Name
    var objectEntryNameRow = new UICol();
    this.objectEntryNameInput = new UINameIdenfierInput('', 2, '', '35px').onChange((e) => {
      var value = this.objectEntryNameInput.getValue();
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'entryName', value])
    });
    this.objectEntryNameDrop = new UIDropDownMini().onChange((e) => {
        const value = this.objectEntryNameDrop.getActive();
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'entryMode', value.id]);
    });
    this.objectEntryNameInput.addClass('ws_uiNameNoPad');

    var objectEntNameLabel = new UIRow().addClass('ws_uiTextContentRow');
    objectEntNameLabel.addChild(new UIText(undefined, "Name"))
    objectEntryNameRow.addChild(objectEntNameLabel);

    var objectNameInpCont = new UIRow().addClass('ws_uiEntryNameCont');

    objectNameInpCont.addChild(this.objectEntryNameInput);
    objectNameInpCont.addChild(this.objectEntryNameDrop);

    objectEntryNameRow.addChild(objectNameInpCont);
    this.uiContainer.addChild(objectEntryNameRow);
    this.objProperties['entryName'] = objectEntryNameRow;


    // Description
    var objectDescRow = new UICol().addClass('ws_uiTextContentCol');
    this.objectDescInput = new UIInputTextArea('objectName', '100%', undefined, 400, '', '4', 'forText').onChange((e) => {
      var value = e.target.value;
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'description', value]);
    });
    const objectHeaderRow = new UIRow().addClass('ws_uiTextContentRow');
    objectHeaderRow.addChild(new UIText(undefined, "Description"))
    objectDescRow.addChild(objectHeaderRow);
    objectDescRow.addChild(this.objectDescInput);
    this.uiContainer.addChild(objectDescRow);
    this.objProperties['description'] = objectDescRow;

    // amenity Children selection
    const uiHeaderRowAPC = new UICol();
    const uiPanelAPC = new UIRow();
    uiPanelAPC.addChild(new UITextHeader(undefined, 'Type of Amenity Pin'));
    uiPanelAPC.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiHeaderRowAPC.addChild(uiPanelAPC);
    this.objectAmenityTypeSelector = new UIRadioSelector().onChange(() => {
      const value = this.objectAmenityTypeSelector.getValue()
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'amenityType', value]);

    });
    uiHeaderRowAPC.addChild(this.objectAmenityTypeSelector);
    this.uiContainer.addChild(uiHeaderRowAPC);
    this.objProperties['amenityChildren'] = uiHeaderRowAPC;


    //Pin and path color
    const uiHeaderRowPPC = new UICol();
    const uiPanelPPC = new UIRow();
    uiPanelPPC.addChild(new UITextHeader(undefined, 'Pin & Path Colors'));
    uiPanelPPC.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiHeaderRowPPC.addChild(uiPanelPPC);
    this.objectMatSkinColor = new UIColorPalette(null, pinColors).onChange((e) => {
      const value = this.objectMatSkinColor.getValue();
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'pinColor', value]);
      // this.editor.onCommand(new SetUserDataValue(this.editor, this.editor.selectedObject, 'pinColor', value, false));
    });
    uiHeaderRowPPC.addChild(this.objectMatSkinColor);
    this.uiContainer.addChild(uiHeaderRowPPC);
    this.objProperties['pinColor'] = uiHeaderRowPPC;

    // Nav Style
    const uiHeaderRowNav = new UICol();
    const uiPanelNav = new UIRow();
    uiPanelNav.addChild(new UITextHeader(undefined, 'Navigation Style'));
    uiPanelNav.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiHeaderRowNav.addChild(uiPanelNav);
    this.objectNavStyleSelector = new UIPinNavSelector().onChange((e) => {
      const value = this.objectNavStyleSelector.getValue();
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'navigationStyle', value]);
    });
    uiHeaderRowNav.addChild(this.objectNavStyleSelector);
    this.uiContainer.addChild(uiHeaderRowNav);
    this.objProperties['navigationStyle'] = uiHeaderRowNav;

    //Connector Group
    const uiHeaderRowConGrp = new UICol().addClass('ws_uiConGrpPanelCont');
    const uiPanelConGrp = new UIRow();
    uiPanelConGrp.addChild(new UITextHeader(undefined, 'Connector Group'));
    uiPanelConGrp.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiHeaderRowConGrp.addChild(uiPanelConGrp);
    this.objectConnectorGroupDrop = new UIDropDown([], null, { placeholder: 'Select a Connector Group' }).onChange(() => {
      const mode = this.objectConnectorGroupDrop.getMode();
      if(mode === 'select') {
        const value = this.objectConnectorGroupDrop.getActive();
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'connectorGroupId', value.id]);
      } else if(mode === 'create') {
        this.editor.invokeConnectorCreate(this.editor.selectedObject);
      }
    })
    uiHeaderRowConGrp.addChild(this.objectConnectorGroupDrop);
    this.uiContainer.addChild(uiHeaderRowConGrp);
    this.objProperties['connectorGroupId'] = uiHeaderRowConGrp;

    //Connector Group
    const uiHeaderConnectDirection = new UICol();
    const uiPanelConDir = new UIRow();
    uiPanelConDir.addChild(new UITextHeader(undefined, 'Connector Type'));
    uiPanelConDir.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiHeaderConnectDirection.addChild(uiPanelConDir);
    this.objectConnectorDirDrop = new UIDropDown([], null, {toAdd: false, placeholder: 'Select a Direction' }).onChange(() => {
      const mode = this.objectConnectorDirDrop.getMode();
      if(mode === 'select') {
        const value = this.objectConnectorDirDrop.getActive();
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'direction', value.id]);
      }
    })
    uiHeaderConnectDirection.addChild(this.objectConnectorDirDrop);
    this.uiContainer.addChild(uiHeaderConnectDirection);
    this.objProperties['direction'] = uiHeaderConnectDirection;

    const uiWheelChairAccRow = new UIRow().addClass('wsFlexRowCenter').addClass('ws_uiWheelAccessPanelCont');
    const uiPanelWheelAccess = new UIRow();
    uiPanelWheelAccess.addChild(new UITextHeader(undefined, 'Wheelchair Accessible'));
    uiPanelWheelAccess.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiWheelChairAccRow.addChild(uiPanelWheelAccess);
    this.objectWheelchairAccessState = new UIText('10%', 'OFF');
    this.objectWheelchairAccessSwitch = new UISwitch(false, true, 'ws_uiSliderFeaturedPin').onChange((e) => {
      const value = e.target.checked;
      this.objectWheelchairAccessState.setTextContent(value ? 'ON' : 'OFF');
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'wheelchairAccessible', value]);
    })
    uiWheelChairAccRow.addChild(this.objectWheelchairAccessState);
    uiWheelChairAccRow.addChild(this.objectWheelchairAccessSwitch);
    this.uiContainer.addChild(uiWheelChairAccRow);
    this.objProperties['wheelchairAccessible'] = uiWheelChairAccRow;

    // Relative QR
    const uiHeaderRowRelatedQR = new UICol();
    const uiPanelRelatedQR = new UIRow();
    uiPanelRelatedQR.addChild(new UITextHeader(undefined, 'Related Access Point'));
    uiPanelRelatedQR.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiHeaderRowRelatedQR.addChild(uiPanelRelatedQR);
    uiHeaderRowRelatedQR.addChild(new UIRow().addChild(new UIText(undefined, 'Note: Add access points must be placed near connector pins so visitors can access each floor plan map.')))
    this.uiContainer.addChild(uiHeaderRowRelatedQR);
    const uiPanelQRListCont = new UICol().addClass('ws_uiQRDropCont');
    
    const uidropCheckRow = new UIRow().addClass('ws_uiQRDropCont--checkCont');
    uidropCheckRow.addChild(new UIPanel().addChild(new UIArrowCheckbox(false).onChange(() => {
      // console.log("Call the Algo");
    })).addClass('wsArrowCheckCont'));
    uidropCheckRow.addChild(new UIText('90%', 'Select access point near this connector.').addClass('ws_uiQRDropCont--checkText'))
    uiPanelQRListCont.addChild(uidropCheckRow);
    this.objectQRListDrop = new UIDropDown([], null, {placeholder: 'Select a QR Code', toAdd: false, showBorder: true}).onChange(() => {
      const mode = this.objectQRListDrop.getMode();
      if(mode === 'select') {
        const value = this.objectQRListDrop.getActive();
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'relatedAccessPointID', value.id]);
      }
    })
    uiPanelQRListCont.addChild(this.objectQRListDrop);
    uiHeaderRowRelatedQR.addChild(uiPanelQRListCont);
    this.uiContainer.addChild(uiHeaderRowRelatedQR);
    this.objProperties['relatedAccessPointID'] = uiHeaderRowRelatedQR;

    //Categories
    const uiHeaderRowCat = new UICol();
    const uiPanelCat = new UIRow();
    uiPanelCat.addChild(new UITextHeader(undefined, 'Categories'));
    uiPanelCat.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiHeaderRowCat.addChild(uiPanelCat);
    // category Logic
    this.catCol = new UICol()
    this.multiselect = new UIMultiselect(this.editor).onChange(e => {
      const categories = this.multiselect.getCategoryValues()
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'pinCategories', categories])
    })
    this.catCol.addChild(this.multiselect)
    uiHeaderRowCat.addChild(this.catCol)
    this.uiContainer.addChild(uiHeaderRowCat);
    this.objProperties['pinCategories'] = uiHeaderRowCat;
    this.multiselect.loadOptions(this.editor.editor3d.categories)

    // category Logic
    //Advanced setttings
    const uiAdvancecPanel = new UICol().addClass('ws_uiAdvancedPanelCont');
    const uiHeaderRowAdv = new UIRow().addClass('wsFlexRowCenter');
    const uiPanelAdv = new UIRow();
    uiPanelAdv.addChild(new UITextHeader(undefined, 'Advanced Settings'));
    uiPanelAdv.addChild(new UIImage(HelpIcon, '25px', '25px', 'Help').setStyle('marginLeft', '10px').addClass('ws_uiHelpIcon').onClick(this.handleInfoModal));
    uiHeaderRowAdv.addChild(uiPanelAdv);
    this.uiadvancedChevron = new UIImage(DropdownIcon, '30px', '30px', 'Open More').onClick(() => {
      this.openAdvancedPane = !this.openAdvancedPane;
      this.uiadvancedChevron.setStyle('rotate', this.openAdvancedPane ? '180deg' : '0deg')
      this.uiAdvancedDropCont.setDisplay(this.openAdvancedPane ? '' : 'none');
    });
    uiHeaderRowAdv.addChild(this.uiadvancedChevron);
    uiAdvancecPanel.addChild(uiHeaderRowAdv);
    this.objProperties['advancedSettings'] = uiAdvancecPanel;
    this.uiContainer.addChild(uiAdvancecPanel);

    this.uiAdvancedDropCont = new UIPanel();
    this.uiAdvancedDropCont.setDisplay(this.openAdvancedPane ? '' : 'none');

    //Logo
    const uiLogoCol = new UICol()
    uiLogoCol.addChild(new UIText('100%', 'Logo (Optional)'))
    uiLogoCol.addChild(new UIText('100%', 'Max:512x512px, PNG or JPG'))

    this.objectLogoImageInput = new UIFileInput('image', 'images').onChange(() => {
      const file = this.objectLogoImageInput.getFile();
      if(file){
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'logo', file, false, 1]);
      } else {
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'logo', file, false, 0]);
        if(this.objectLogoImageInput.getError()) {
          this.objectLogoImageInput.resetError();
          this.editor.editor3d.callbacks.generateAlert({ msg: 'Invalid File Type Selected!', alertType: 'information' });
          this.objectLogoImageInput.setSrc(null);
        }
      }
    })
    uiLogoCol.addChild(this.objectLogoImageInput);
    this.uiAdvancedDropCont.addChild(uiLogoCol);
    this.objProperties['logo'] = uiLogoCol;


    //Attach File
    const uiFileCol = new UICol()
    uiFileCol.addChild(new UIText('100%', 'Attach file (Optional)'))
    uiFileCol.addChild(new UIText('100%', 'Max: 5MB'))
    uiFileCol.addChild(new UIText('100%', 'File types: PNG or JPG'))
    this.objectFileInput = new UIFileInput('image', 'images').onChange(() => {
      const file = this.objectFileInput.getFile();
      if(file){
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'file', file, false, 1]);
      } else{
        this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'file', file, false, 0]);
        if(this.objectFileInput.getError()) {
          this.objectFileInput.resetError();
          this.editor.editor3d.callbacks.generateAlert({ msg: 'Selected Invalid File Type!', alertType: 'information' });
          this.objectFileInput.setSrc(null);
        }
      }
    });
    uiFileCol.addChild(this.objectFileInput)
    this.uiAdvancedDropCont.addChild(uiFileCol);
    this.objProperties['file'] = uiFileCol;


    // Phone 
    const uiPhoneCol = new UICol()
    uiPhoneCol.addChild(new UIText('100%', 'Phone Number (Optional)').setStyle('marginBottom', '5px'))
    this.objectPhoneInput = new UIInputText('phone-number', '100%', undefined, 20, 'EX: 123-456-7890', 'forText').onChange((e) => {
      var value = e.target.value;
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'mobile', value]);
    })
    uiPhoneCol.addChild(this.objectPhoneInput);
    /* const uiPhoneRow = new UIRow().addClass('wsFlexRowCenter');
    uiPhoneRow.addChild(new UIText('65%', 'Apply phone number to all pins'));

    this.phoneState = new UIText('10%', 'OFF');
    this.phoneSwitch = new UISwitch(false, true, 'ws_uiSliderMobileNumber').onChange((e) => {
      const value = e.target.checked;
      this.phoneState.setTextContent(value ? 'ON' : 'OFF');
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'applyMobileToAll', value]);

    })
    uiPhoneRow.addChild(this.phoneState);
    uiPhoneRow.addChild(this.phoneSwitch);
    uiPhoneCol.addChild(uiPhoneRow) */
    this.uiAdvancedDropCont.addChild(uiPhoneCol);
    this.objProperties['mobile'] = uiPhoneCol;

    // Notes
    const notesCol = new UICol()
    notesCol.addChild(new UIText('100%', 'Notes (Optional)').setStyle('marginBottom', '5px'))
    var objectNotesRow = new UICol().addClass('ws_uiTextContentCol');
    this.objectNotesInput = new UIInputTextArea('notes', '100%', undefined, 100, 'Add Notes', '4', 'forText').onChange((e) => {
      var value = e.target.value;
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'note', value]);
    });
    const objectNotesHeaderRow = new UIRow().addClass('ws_uiTextContentRow');
    objectNotesHeaderRow.addChild(new UIText(undefined, 'Description'))
    objectNotesRow.addChild(objectNotesHeaderRow);
    objectNotesRow.addChild(this.objectNotesInput);
    notesCol.addChild(objectNotesRow);
    /* const uiNotesRow = new UIRow().addClass('wsFlexRowCenter');
    uiNotesRow.addChild(new UIText('65%', 'Apply Notes to all pins'));
    this.notesState = new UIText('10%', 'OFF');
    this.notesSwitch = new UISwitch(false, true, 'ws_uiSliderNotes').onChange((e) => {
      const value = e.target.checked;
      this.notesState.setTextContent(value ? 'ON' : 'OFF');
      // Switches!
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'applyNoteToAll', value]);
    })
    uiNotesRow.addChild(this.notesState);
    uiNotesRow.addChild(this.notesSwitch);
    notesCol.addChild(uiNotesRow); */
    this.uiAdvancedDropCont.addChild(notesCol);
    this.objProperties['note'] = notesCol;

    //website
    const websiteCol = new UICol()
    const uiEmailCol = new UICol()
    uiEmailCol.addChild(new UIText('100%', 'Email').setStyle('marginBottom', '5px'))
    this.objectEmailInput = new UIInputText('email', '100%', undefined, 320, 'abc@armail.com', 'forText').onChange((e) => {
      var value = e.target.value;
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'email', value])
    })
    uiEmailCol.addChild(this.objectEmailInput);
    websiteCol.addChild(uiEmailCol)
    websiteCol.addChild(new UIText('100%', 'Website (Optional)').setStyle('marginTop', '10px'))
    var objectWebsiteRow = new UICol().addClass('ws_uiTextContentCol');
    this.objectWebsiteInput = new UIInputTextArea('webLabel', '100%', undefined, 300, 'View more', '2', 'forText').onChange((e) => {
      var value = e.target.value;
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'websiteLabel', value]);
    });
    const objectWebsiteHeaderRow = new UIRow().addClass('ws_uiTextContentRow');
    objectWebsiteHeaderRow.addChild(new UIText(undefined, 'Label'))
    objectWebsiteRow.addChild(objectWebsiteHeaderRow);
    objectWebsiteRow.addChild(this.objectWebsiteInput);
    websiteCol.addChild(objectWebsiteRow)

    var objectWebsiteLinkRow = new UICol().addClass('ws_uiTextContentCol');
    this.objectWebsiteLinkInput = new UIInputTextArea('webLink', '100%', undefined, 300, 'Website Link', '3', 'forText').onChange((e) => {
      var value = e.target.value;
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'websiteLink', value]);
    });
    const objectWebsiteLinkHeaderRow = new UIRow().addClass('ws_uiTextContentRow');
    objectWebsiteLinkHeaderRow.addChild(new UIText(undefined, 'Link'))
    objectWebsiteLinkRow.addChild(objectWebsiteLinkHeaderRow);
    objectWebsiteLinkRow.addChild(this.objectWebsiteLinkInput);
    websiteCol.addChild(objectWebsiteLinkRow)
    /* const uiWebsiteRowToggle = new UIRow().addClass('wsFlexRowCenter');
    uiWebsiteRowToggle.addChild(new UIText('65%', 'Apply Website to all pins'));
    this.websiteState = new UIText('10%', 'OFF');
    this.websiteSwitch = new UISwitch(false, true, 'ws_uiSliderWebsite').onChange((e) => {
      const value = e.target.checked;
      this.websiteState.setTextContent(value ? 'ON' : 'OFF');
      this.editor.trigger('meta2dObjectChanged', [this.editor.selectedObject, 'applyWebsiteToAll', value]);
    })
    uiWebsiteRowToggle.addChild(this.websiteState);
    uiWebsiteRowToggle.addChild(this.websiteSwitch);
    websiteCol.addChild(uiWebsiteRowToggle) */
    this.uiAdvancedDropCont.addChild(websiteCol);
    uiAdvancecPanel.addChild(this.uiAdvancedDropCont);
    this.uiContainer.addChild(uiAdvancecPanel);
    this.objProperties['websiteLink'] = websiteCol;

    // save and cancel
    const uiSaveCancel = new UICol().addClass('ws_uiSaveCancel');
    this.uiSaveBtn = new UIButton('Save').addClass('ws_uiButton--grayedDisabled').onClick(() => {

      const proceedSave = () => {
        this.editor.trigger('SaveMetaObject');
        this.UIImageCont.imgActions=[]  
      }

      const object = this.editor.selectedObject;
      if(object && object.userData.type === 'Connector Pin') {
        const connectorId = this.objectConnectorGroupDrop.getActive();
        const direction = this.objectConnectorDirDrop.getActive();
        const name = object.userData.connectorType === 'elevator' ? this.objectIdxNameInput.getValue() : this.objectEntryNameInput.getValue();
        if(!connectorId) {
          this.editor.editor3d.callbacks.generateAlert({ msg: 'Select Connector Group for the Pin!', alertType: 'information' });
        } else if(object.userData.direction !== undefined && !direction) {
          this.editor.editor3d.callbacks.generateAlert({ msg: 'Select Connector Direction for the Pin!', alertType: 'information' });
        } else if(name.trim().length !== 2) {
          this.editor.editor3d.callbacks.generateAlert({ msg: `Add two identifiers for connector name!`, alertType: 'information' });
        } else proceedSave();
      } else {
        // check for mandotory felds
        const webLabel = this.objectWebsiteInput.dom.value;
        const webLink = this.objectWebsiteLinkInput.dom.value;

        if((webLabel.length && !webLink.length) || (webLink.length && !webLabel.length) ) {
          this.editor.editor3d.callbacks.generateAlert({ msg: `Add Website ${!webLabel.length ? 'Label' : 'Link'}!`, alertType: 'information' });
        } else if(webLabel.length && webLink.length && !webLink.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g)) {
            this.editor.editor3d.callbacks.generateAlert({ msg: `Please enter valid website URL!`, alertType: 'information' });
        } else proceedSave();
      }
    });
    const uiCancelBtn = new UIButton('Cancel', 'ws_uiButtonCancel').onClick(() => {
      this.editor.trigger('meta2dObjectChanged', [undefined, undefined, undefined, true]);
      this.editor.trigger('isBufferPinDiscarded', [ this.editor.selectedObject ]);
      this.editor.trigger('continueConnectorPlacement', [ null ]);
      this.editor.deselect();
    });
    uiSaveCancel.addChild(this.uiSaveBtn);
    uiSaveCancel.addChild(uiCancelBtn);
    this.uiContainer.addChild(uiSaveCancel);

    this.dom = this.uiContainer.dom;
  }

  handleInfoModal = (e, type = null) => {
    const object = this.editor.selectedObject;
    if(type === 'onboarding' && object && object.userData.type === 'Connector Pin') {
        const type = object.userData.connectorType;
        if( type === 'elevator') {
          this.editor.onToggleInfoModals("ElevatorConnector", true);
        } else if(type === 'escalator' || type === 'stairs') {
          this.editor.onToggleInfoModals("EscalatorConnector", true, {toShowDirection: false, type});
        }
      }
    else {
      this.editor.onToggleInfoModals('ToOnboarding', true, null);
    }
  }

  updateUIRows = (object) => {
    for (var property in this.objProperties) {
      var uiEl = this.objProperties[property];
      if (this.userDataProperties.indexOf(property) !== -1) {
        uiEl.setDisplay(object.userData[property] !== undefined ? '' : 'none');
      } else if (this.userDataHeaderProps.indexOf(property) !== -1) {
        uiEl.setDisplay(object.userData.pinType === 'regular' ? '' : 'none');
      } else if(property === 'name' && (object.userData.indexName !== undefined || object.userData.entryName !== undefined)){
        uiEl.setDisplay(object[property] !== undefined ? 'none' : '');
      } else {
        uiEl.setDisplay(object[property] !== undefined ? '' : 'none');
      }
    }
  }

  updateUIClasses = (object) => {
    if(object) {
      // append extra classes
        if(object.userData.type === 'Connector Pin') {
          const type = object.userData.connectorType;
          if(type === 'stairs' || type === 'bridge') {
            this.objectEntryNameDrop.disableDrop(true);
            this.objectEntryNameInput.setInputWidth('50px');
            this.objectConnectorDirDrop.disableDrop(true);
          }
        }
    } else {
      // remove classes
      this.objectEntryNameDrop.disableDrop(false);
      this.objectEntryNameInput.setInputWidth('35px');
      this.objectConnectorDirDrop.disableDrop(false);
    }
  }

  updateUIData = (object) => {

    // console.log(object.userData);

    if(object.userData.toCreate !== undefined && this.customUIConnectorTypes.indexOf(object.userData.connectorType) !== -1) {
      if(this.editor.conPinHelper.getPairStep() === 1) {
        this.uiSaveBtn.setTextContent('Save First Pin');
      } else {
        this.uiSaveBtn.setTextContent('Save & Pair Pins');
      }
    } else {
      this.uiSaveBtn.setTextContent('Save');
    }

    this.objectTypeHeader.setTextContent(object.userData.type);
    this.objectTypeText.setTextContent(`${object.userData.type} Details`)

    if(object.userData.indexName !== undefined) {
      this.objectIdxNameInput.setPrename(object.userData.preName);
      this.objectIdxNameInput.setValue(object.userData.indexName);
    } else if(object.userData.entryName !== undefined) {
      this.objectEntryNameInput.setPrename(object.userData.preName);
      this.objectEntryNameInput.setValue(object.userData.entryName);
      this.objectEntryNameDrop.setOptions(this.entryOptions);
      this.objectEntryNameDrop.setActive(object.userData.entryMode);
    } else {
      this.objectNameInput.setValue(object.name)
    }

    if (object.userData.description !== undefined) {
      this.objectDescInput.setValue(object.userData.description);
    }

    if (object.userData.amenityChildren !== undefined) {
      this.objectAmenityTypeSelector.setOptions(object.userData.amenityChildren);
      this.objectAmenityTypeSelector.setValue(object.userData.amenityType);
    }

    if(object.userData.featuredPin !== undefined) {
      this.objectFeaturedPin.setValue(object.userData.featuredPin)
      this.objectFeaturedPinState.setTextContent(object.userData.featuredPin ? 'ON' : 'OFF');
    }

    if (object.userData.images !== undefined) {
      this.objectImageContainer.setImages(object.userData.images)
    }

    if (object.userData.pinColor !== undefined) {
      this.objectMatSkinColor.setValue(object.userData.pinColor);
    }

    if (object.userData.navigationStyle !== undefined) {
      this.objectNavStyleSelector.setValue(object.userData.navigationStyle);
    }

    if(object.userData.connectorGroupId !== undefined) {
      this.objectConnectorGroupDrop.setOptions(sortConnectorGroupDropDownOptions(this.editor.connectorGroups, object.userData.connectorType, true));
      object.userData.connectorGroupId?.length && this.objectConnectorGroupDrop.setActive(object.userData.connectorGroupId);
    }

    if(object.userData.direction !== undefined) {
      this.objectConnectorDirDrop.setOptions(this.directionOptions);
      object.userData.direction?.length && this.objectConnectorDirDrop.setActive(object.userData.direction);
    }

    if(object.userData.relatedAccessPointID !== undefined) {
      this.objectQRListDrop.setOptions(this.setAnchorNames(this.editor.jsonQRAnchors));
      object.userData.relatedAccessPointID?.length && this.objectQRListDrop.setActive(object.userData.relatedAccessPointID);
    }

    if(object.userData.wheelchairAccessible !== undefined) {
      this.objectWheelchairAccessSwitch.setValue(object.userData.wheelchairAccessible)
      this.objectWheelchairAccessState.setTextContent(object.userData.wheelchairAccessible ? 'ON' : 'OFF');
    }

    if (object.userData.mobile !== undefined) {
      this.objectPhoneInput.setValue(object.userData.mobile);
    }

    if (object.userData.note !== undefined) {
      this.objectNotesInput.setValue(object.userData.note);
    }

    if (object.userData.logo !== undefined) {
      this.objectLogoImageInput.setContent(object.userData.logo);
    }

    if (object.userData.file !== undefined) {
      this.objectFileInput.setContent(object.userData.file);
    }

    if (object.userData.email !== undefined) {
      this.objectEmailInput.setValue(object.userData.email);
    }

    if(object.userData.websiteLabel !== undefined) {
      this.objectWebsiteInput.setValue(object.userData.websiteLabel);
    }

    if (object.userData.websiteLink !== undefined) {
      this.objectWebsiteLinkInput.setValue(object.userData.websiteLink);
    }

    if (object.userData.pinCategories !== undefined) {
      this.multiselect.setCategoriesValue(object.userData.pinCategories)
    }

  }

  setAnchorNames = (anchors) => {
    return anchors.map(a => ({...a, name: a.name?.length ? a.name : (a.anchorIndex === "primary" ? 'QR_Primary' : 'QR_Secondary')}))
  }

  onObjectSelected = (object) => {
    // discard changes
    this.editor.trigger('meta2dObjectChanged', [undefined, undefined, undefined, true]);
    if (object !== null && (object.userData.type === 'Location Pin' || object.userData.type === 'Connector Pin')) {
      this.uiContainer.setDisplay('block');
      this.openAdvancedPane = false;
      this.uiAdvancedDropCont.setDisplay('none');

      this.updateUIRows(object);
      this.updateUIClasses(object);
      this.updateUIData(object);
    } else {
      this.uiContainer.setDisplay('none');
      this.updateUIClasses(null);
    }
  }

  onBufferedChanges = (a, b, c, d = false) => { 
    if(!d) this.uiSaveBtn.removeClass('ws_uiButton--grayedDisabled')
    else this.uiSaveBtn.addClass('ws_uiButton--grayedDisabled')
  }

  onToggleMenuActive = (name) => {
    if (name !== null) {
      this.uiContainer.setDisplay('none');
    }
  }

  onCategoriesChanged = (categories) => {
    this.multiselect.loadOptions(categories,true)
  }

  onConnectorGroupOptionsChanged = (groups) => {
    if(this.editor.selectedObject) {
      this.objectConnectorGroupDrop.setOptions(sortConnectorGroupDropDownOptions(groups, this.editor.selectedObject.userData.connectorType));
    }
  }

  onObjectChanged = (object) => {
    if (object !== this.editor.selectedObject) return;
    this.updateUIData(object);
  }

}

export { Sidebar2DObject };