import React from 'react';
import { useSelector } from 'react-redux';
import VisitorBehaviorAccount from './VisitorBehaviorAccount';
import OverviewAll from '../overview/OverviewAll';
import Loader from '../../../../common/loader';

export default function VisitorBehaviorHone() {

    const selectedAccount = useSelector((state) => state.adminanalytics.activeAccount);

    if( selectedAccount && selectedAccount?.id === 'allAccounts') {
        return <OverviewAll visitorBehavior={true}/>
    } else if(selectedAccount && selectedAccount?.id !== 'allAccounts') {
        return <VisitorBehaviorAccount />
    } else {
        return <Loader loaderText={"Loading..."} height="500px" width="100%"/>
    }
}
