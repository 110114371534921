import * as THREE from 'three';
import EditorExperience from '../../EditorExperience';
import { 
    fromVec3PosToPosObject,
    fromPosObjectToVec3Pos,
    fromScaleObjectToVec3Scale,
    fromQuaternionObjectToQuaternion
} from '../TransformConversions';

export default  class LocationPin {
    constructor(destProps, mode) {
        this.destProps = destProps;

        this.editor = new EditorExperience();
        this.navMode = mode;

        this.name = destProps.name
        this.description = destProps.description || ""
        this.id = destProps.id

        this.position = destProps.position ? fromPosObjectToVec3Pos(destProps.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = destProps.rotation ? fromQuaternionObjectToQuaternion(destProps.rotation) : new THREE.Quaternion(0, 0, 0, 0);
        this.scale = destProps.scale ? fromScaleObjectToVec3Scale(destProps.scale) : new THREE.Vector3(1, 1, 1);
        
        this.visible = 'visible' in destProps ? destProps.visible : false;
        this.pathColor = 'pathColor' in destProps ? destProps.pathColor : '#FD7A02';

        this.mesh = {};

        this.editor.on('objectChanged', this.destinationChanged);
        this.editor.on('objectRemoved', this.onObjectRemoved);
        /* this.editor.on('objectSelected', this.hideAnnotation);
        this.editor.on('objectRemoved', this.onObjectRemoved);
        this.editor.on('hotspotHovered', this.showAnnotation); */

        // this.initDescHtml();
        this.setUpDestination();
    }

    initDescHtml = () => {

        this.annotation = document.createElement('div');
        this.annotation.classList.add('annotation');

        this.pHead = document.createElement('p');
        this.pHead.classList.add('annotation--header')
        this.pHead.textContent = this.name;
        this.annotation.appendChild(this.pHead);

        this.pDes = document.createElement('p');
        this.pDes.classList.add('annotation--text')
        this.pDes.textContent = this.description;
        this.annotation.appendChild(this.pDes);

        this.annotation.style.visibility = 'hidden';
        document.body.appendChild(this.annotation);
    }

    setUpDestination = () => {

        this.mesh = this.editor.mapPinObject.clone();
        this.mesh.position.copy(this.position);
        // this.mesh.quaternion.copy(this.rotation);
        this.mesh.scale.set(0.007, 0.007, 0.007);
        this.mesh.name = this.name;
        this.mesh.userData['description'] = this.description;
        this.mesh.userData['type'] = 'Location Pin';
        this.mesh.userData['skipChild'] = true;
        this.mesh.userData['pathColor'] = this.pathColor;
        this.mesh.userData['visible'] = this.visible;
        this.mesh.userData['transform'] = "ONLY_TRANSLATE";
        this.mesh.userData['udScale'] = 'LocationPin';
        this.mesh.userData['udRotate'] = 'LocationPin';
        this.mesh.visible = this.visible;
        this.navMode === 0 && (this.mesh.userData['navigationMode'] = 'waypointAndDestination');
        this.navMode === 1 && (this.mesh.userData['navigationMode'] = 'destination');
        this.mesh.userData['id'] = this.id;

        this.updateMaterialColor();
    }

    destinationChanged = (object, a, b, isView = false) => {
        if(this.mesh === object) {
            this.name = object.name;
            this.description = object.userData.description;
            this.position.copy(object.position);
            this.pathColor = object.userData.pathColor
            this.updateJSONObject(object, isView);
            this.updateMaterialColor();
            // this.updateHTML();
        }
    }

    updateJSONObject = (object, isView) => {
        const jsonIndex = this.editor.getIndex('jsonWayProps', object.userData.id);
        let reqObj = {...this.editor.jsonWayProps[jsonIndex]}
        reqObj.name = object.name;
        reqObj.description = object.userData.description;
        let wayObj = {...reqObj.navObj};
        wayObj.name = object.name;
        wayObj.description = object.userData.description;
        wayObj.position = fromVec3PosToPosObject(object.position);
        /* wayObj.scale = fromVec3ScaleToScaleObject(object.scale);
        wayObj.rotation = fromQuaternionToQuaternionObject(object.quaternion); */
        wayObj.pathColor = this.pathColor;

        reqObj = {
            ...reqObj,
            navObj: wayObj
        }
        this.editor.jsonWayProps[jsonIndex] = reqObj;
        !isView && this.editor.toAutoSave && this.editor.trigger('autoSaveSceneGraphState');
    }

    hexToDec = (hex) => parseInt(hex.substring(1), 16)

    updateMaterialColor = () => {
        const newColorMat = new THREE.MeshStandardMaterial().copy(this.mesh.material);
        newColorMat.color.setHex(this.hexToDec(this.pathColor), 'srgb-linear')

        this.mesh.material = newColorMat;
        this.mesh.material.needsUpdate = true;
        this.mesh.children[0].material = newColorMat;
        this.mesh.children[0].material.needsUpdate = true;

    }

    updateHTML = () => {
        this.pHead.textContent = this.name;
        this.pDes.textContent = this.description;
    }

    hideAnnotation = (object) => {
        if(this.mesh === object) {
            this.annotation.style.visibility = 'hidden';
        }
    }

    showAnnotation = (object) => {
        /* if(object && object.parent && object.parent === this.editor.scene) {
            let parent = object.parent;
            let bDone = true;
            while(bDone && parent !== this.editor.scene) {
                if(parent.parent === this.editor.scene) {
                    bDone = false;
                } else {
                    parent = parent.parent;
                }
            }
            if(this.mesh === parent && this.editor.selectedObject !== parent) {
                this.annotation.style.visibility = 'visible';
            } 
        } */ 
        if(this.mesh === object && this.editor.selectedObject !== object) {
            this.annotation.style.visibility = 'visible';
        } else {
            this.annotation.style.visibility = 'hidden';
        }
    }

    onObjectRemoved = (object) => {
        if(this.mesh === object) {
            // this.annotation.remove();
            // stop listeners
            this.editor.stop('objectChanged', this.destinationChanged);
            this.editor.stop('objectRemoved', this.onObjectRemoved);
        }
    }
}
