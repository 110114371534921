import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import Loader from "../../../common/loader";

import { DASHBOARD_HOME, D_LANDING } from "../../../_utils/routes";

import {
  impersonateUser,
  initImpersonation,
} from "../../../../features/admin/impersonations/impersonateSlice";

export default function SetImpersonation() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [navigateKey, setNavigateKey] = useState(D_LANDING);

  const impersonatedUserData = useSelector((state) => state.auth.data);

  // Do all the things
  useEffect(() => {
    let impState = localStorage.getItem("impersonateState");
    if (impState !== null) {
      const userKey = location.pathname.slice(-5);
      impState = JSON.parse(impState);
      let userInfo = impState[userKey];
      delete impState[userKey];
      // console.log(userInfo, impState);
      sessionStorage.setItem("impersonateUserId", userInfo.id);
      sessionStorage.setItem("currentUser", JSON.stringify(userInfo));
      sessionStorage.setItem("impersonateUser", true);
      if ("toNavigate" in userInfo && userInfo.toNavigate !== undefined) {
        setNavigateKey(userInfo.toNavigate);
      }

      localStorage.setItem("impersonateState", JSON.stringify(impState));
      dispatch(initImpersonation());
      dispatch(impersonateUser());
    } else {
      console.log("else");
      navigate(DASHBOARD_HOME, { replace: true });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (impersonatedUserData?.user?.role !== "admin") {
      navigate(navigateKey, { replace: true });
    }
  }, [impersonatedUserData, navigate, navigateKey]);

  return (
    <Loader
      loaderText="Loading User Information..."
      height="100%"
      width="100%"
    />
  );
}
