import React from "react";
import { 
    Backdrop, Box, 
    Modal, Fade,Button
} from "@mui/material";
import { XCircle } from "react-feather";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80% !important",
    transform: "translate(-50%, -50%)",
    width: 650,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    borderRadius: "16px",
    overflowY: 'auto',
};

const DeleteEscalatorPairModal = ({ openModal, onCloseModal, modalData }) => {

    const handleClose = (e, reason) => {
        if(reason && reason === 'backdropClick') return;
        onCloseModal();
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={handleClose}
                        />
                        <div className='cgModal'>
                            <div className='cgModal--cgCard'>
                                    <h4 className='cgModal--cgCard--title'>Escalator Connnector Pin Deleted</h4>
                                    <div className='cgModal--cgCard--headerDel cgModal--cgCard--headerDel--mt10'> You have deleted an Escalator Connector pin from the Connector Pair, Make sure to delete it's connecting Escalator Pin.</div>
                                    <div className="publishModalBtnsDiv publishModalBtnsDiv--mt10">
                                        <Button variant='contained' color='primary' className='mapdetails__mapModalBtnText' onClick={handleClose}>Close</Button>
                                    </div>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default DeleteEscalatorPairModal;
