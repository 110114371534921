// import * as THREE from 'three';
import EventEmitter from "../../utils/EventEmitter";
import { UIPanel, UIImage, UITooltip } from "../ui.lib";

//Icon
import NavIcon from '../../../../assets/svgs/scenes/menu/path.svg';

class MenubarGuidedTour extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.strings = this.editor.strings;
        this.dom = null;
        this.name = 'Guided Tours';
        this.isActive = false;
        this.editor.on('toggleMenuActive', this.onToggleMenuActive);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('wsMenuContainer');
        this.uiCont.setId('wsMenuTour');
        
        this.uiText = new UITooltip(this.strings.getKey('menubar/navigation'));
        this.uiImg = new UIImage(NavIcon, '28px', '28px', this.strings.getKey('menubar/navigation'));

        this.uiCont.addChild(this.uiImg);
        this.uiCont.addChild(this.uiText);

        this.uiCont.onClick(() => {
            this.makeActive();
        })

        this.dom = this.uiCont.dom;
    }

    makeActive = () => {
        if(!this.isActive) {
            this.isActive = true;
            this.uiCont.addClass('wsMenuContainer--active');        
        }
        this.editor.trigger('toggleMenuActive', [this.name]);
        this.editor.trigger('sidebarSceneGraphChanged', [this.name]);
    }

    onToggleMenuActive = (name) => {
        if(name !== this.name) {
            this.isActive = false;
            this.uiCont.removeClass('wsMenuContainer--active');
        }
    }
}

export { MenubarGuidedTour };