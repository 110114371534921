import React, { useState } from "react";

function TestField({  name, setParent, maxLength }) {
  const [data, setData] = useState(name);
  const [showField, setShowField] = useState(false);
  return (
    <div>
      {showField ? (
        <input
          onBlur={() => {
            setShowField(false);
            setParent(data)
          }}
          autoFocus={true}
          type="text"
          style={{ paddingLeft: "1rem" }}
          defaultValue={data}
          maxLength={maxLength}
          onChange={(e) => {
            setData(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              setShowField(false);
              setParent(data)
            }
          }}
        />
      ) : (
        <div
          onClick={() => {
            setShowField(true);
          }}
          id="test"
        >
          {data}
        </div>
      )}
    </div>
  );
}

export default TestField;
