// import * as THREE from 'three';
import EventEmitter from "../../../utils/EventEmitter";
import { UIImage, UIPanel, UIRow } from "../../../ui/ui.lib";

//Icon
import PenNewIcon from '../../../../../assets/svgs/scenes/menu/pen_new.svg';
import PenAddIcon from '../../../../../assets/svgs/scenes/menu/pen_add.svg';
import PenRemoveIcon from '../../../../../assets/svgs/scenes/menu/pen_remove.svg';
import ChevronIcon from '../../../../../assets/svgs/scenes/chevron.svg';

class Menu2DPens extends EventEmitter {
    constructor(editor) {
        super();

        this.types = {
            "new": {
                icon: PenNewIcon,
                title: 'Add New Node',
                type: 'new',
                size: '28px',
                disableOnEmpty: false,
            },
            "add": {
                icon: PenAddIcon,
                title: 'Edit Node',
                type: 'add',
                size: '32px',
                disableOnEmpty: true,
            },
            "remove": {
                icon: PenRemoveIcon,
                title: 'Remove Node',
                type: 'remove',
                size: '32px',
                disableOnEmpty: true,
            },
        }
        this.uiObjs = {};

        this.editor = editor;
        this.dom = null;
        this.name = 'Pens';
        this.isActive = false;
        this.activePenType = "new"

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.resources.on('ready', this.onUpdatePenTools);
        this.editor.on('updatePenToolsUI', this.onUpdatePenTools);


        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.setClass('ws2DMenuContainer ws2DPathNav');
        this.uiCont.setId('ws2DMenuPaths');  

        this.uiImg = new UIImage(this.types[this.activePenType].icon, this.types[this.activePenType].size, this.types[this.activePenType].size, "Pen Tools");
        this.uiCont.addChild(this.uiImg);
        this.uiCont.addChild(new UIImage(ChevronIcon, '18px', '18px').setStyle('padding', '2px'));

        // Paths UI
        this.uiPathsContainer = new UIPanel();
        this.uiPathsContainer.setClass('ws2DPathNav--panelMini');

        for(const pens in this.types) {
            let uiPathCont = new UIRow().setClass('wsPenPanelRow').onClick(() => this.setActivePath(pens));
            uiPathCont.addChild(new UIImage(this.types[pens].icon, this.types[pens].size, this.types[pens].size, this.types[pens].title))
            this.uiPathsContainer.addChild(uiPathCont);
            this.uiObjs[pens] = uiPathCont;
        }
        
        this.uiCont.addChild(this.uiPathsContainer);

        /* this.uiCont.onClick(() => {
            this.setActivePath(this.activePenType);
        }); */

        this.dom = this.uiCont.dom;
    }

    onUpdatePenTools = () => {
        const pathSize = Array.from(this.editor.pathController.sessionPaths.values()).length;
        for(const p in this.uiObjs) {
            if(this.types[p].disableOnEmpty && pathSize === 0) {
                this.uiObjs[p].removeClass('wsPenPanelRow--active');
                this.uiObjs[p].addClass('wsPenPanelRow--disabled');
            } else {
                this.uiObjs[p].removeClass('wsPenPanelRow--active');
                this.uiObjs[p].removeClass('wsPenPanelRow--disabled');
            }
        }
    }

    setActivePath = (path) => {
        if(this.editor.pathController.startNavigationTrack || this.editor.locPinHelper.startNavigationTrack) return;

        this.editor.deselect(null);
        this.activePenType = path;
        this.uiImg.setSrc(this.types[path].icon);
        this.uiImg.setStyle('width', this.types[path].size);
        this.uiImg.setStyle('height', this.types[path].size);

        for(const p in this.uiObjs) p === path ? this.uiObjs[p].addClass('wsPenPanelRow--active') : (this.uiObjs[p].removeClass('wsPenPanelRow--active') && this.uiObjs[p].addClass('wsPenPanelRow--disabled'))
        
        this.isActive = false;
        this.makeActive();

        // start it!
        this.editor.pathController.setupControls(this.activePenType);
    }

    makeActive = () => {
        if(!this.isActive) {
            this.isActive = true;
            this.uiCont.addClass('ws2DMenuContainer--active');        
        }
        const pathType = this.activePenType === 'new' ? 'add' : 'edit'; 
        this.editor.trigger('toggleConnectorMenuMode', ['PATHS']);
        this.editor.trigger('toggleMenuActive', [this.name, pathType]);
    }

    onToggleMenuActive = (name) => {
        if(name !== this.name) {
            this.isActive = false;
            this.activePenType = 'new';
            this.uiImg.setSrc(this.types['new'].icon);
            this.uiImg.setStyle('width', this.types['new'].size);
            this.uiImg.setStyle('height', this.types['new'].size);
            for(const p in this.uiObjs) (this.uiObjs[p].removeClass('wsPenPanelRow--active') && this.uiObjs[p].removeClass('wsPenPanelRow--disabled'))

            this.uiCont.removeClass('ws2DMenuContainer--active');
            if(name === 'Pins' || this.editor.menuConnectorsMode === 'PINS' || this.editor.menuConnectorsMode === 'CONNECTORS') {
                this.uiCont.addClass('ws2DMenuContainer--disable');
                this.uiCont.setDisplay('none');
            } else {
                this.uiCont.removeClass('ws2DMenuContainer--disable');
                this.uiCont.setDisplay('');
            }
            this.onUpdatePenTools();
        }
    }
}

export { Menu2DPens };