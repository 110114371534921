// import * as THREE from 'three';
import EventEmitter from "../../../utils/EventEmitter";
import { UIImage, UIPanel, UIRow, UIText } from "../../../ui/ui.lib";

//Icon
import PathStrokeIcon from '../../../../../assets/svgs/scenes/menu/path_width.svg';
import ChevronIcon from '../../../../../assets/svgs/scenes/chevron.svg';

class Menu2DPathStroke extends EventEmitter {
    constructor(editor) {
        super();

        this.types = [ 1, 2, 4, 8 ];
        this.uiObjs = {};

        this.editor = editor;
        this.dom = null;
        this.isActive = false;
        this.activeStroke = 4;
        this.name = 'pathStroke'

        // this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('objectSelected', this.onObjectSelected);
        this.editor.resources.on('ready', this.onReady);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.setClass('ws2DMenuContainer ws2DPathNav');
        this.uiCont.setId('ws2DMenuPaths');  

        this.uiImg = new UIImage(PathStrokeIcon, '30px', '30px', "Path");
        this.uiCont.addChild(this.uiImg);

        this.uiStrokeText = new UIText('100%', `${this.activeStroke}m`);
        this.uiStrokeText.addClass('ws2DPathNav--strokeText');
        this.uiCont.addChild(this.uiStrokeText);

        this.uiCont.addChild(new UIImage(ChevronIcon, '18px', '18px').setStyle('padding', '2px'));

        // Paths UI
        this.uiPathsContainer = new UIPanel();
        this.uiPathsContainer.setClass('ws2DPathNav--panelMini');

        for(const path of this.types) {
            let uiPathCont = new UIRow().setClass('wsPathStrokeRow').onClick(() => this.setActiveStroke(path));
            uiPathCont.addChild(new UIText('100%', path).addClass('wsPathStrokeRow--text'));
            this.uiPathsContainer.addChild(uiPathCont);
            this.uiObjs[path] = uiPathCont;
        }
        
        this.uiCont.addChild(this.uiPathsContainer);

        this.uiCont.setDisplay('none');

        this.dom = this.uiCont.dom;
    }

    setActiveStroke = (stroke, isInitValue = false) => {
        if(this.activeStroke === stroke && !isInitValue) return;
        if(this.editor.pathController.startNavigationTrack || this.editor.locPinHelper.startNavigationTrack) return;

        this.activeStroke = stroke;

        for(const p in this.uiObjs) parseInt(p) === stroke ? this.uiObjs[p].addClass('wsPathStrokeRow--active')  : this.uiObjs[p].removeClass('wsPathStrokeRow--active');

        this.uiStrokeText.setTextContent(`${stroke}m`);

        !isInitValue && this.editor.trigger('updatePathStroke', [ this.editor.selectedObject, stroke]);

        !this.isActive && this.makeActive(isInitValue);
    }

    makeActive = (isInit) => {
        if(!this.isActive) {
            this.isActive = true;
            this.uiCont.addClass('ws2DMenuContainer--active'); 
        }
        this.editor.trigger('toggleMenuActive', [ this.name, isInit ]);
    }

    onObjectSelected = (object) => {
        if(object && 'nodeMesh' in object.userData) {
            this.setActiveStroke(object.userData.pathStroke, true);
            this.uiCont.setDisplay('');
            this.uiCont.removeClass('ws2DMenuContainer--disable');
        } else {
            this.resetState();
        }
    }

    onReady = () => {
        this.uiCont.addClass('ws2DMenuContainer--disable');
        this.uiCont.removeClass('ws2DMenuContainer--active');
    }

    onToggleMenuActive = (name) => {
        if(name !== this.name) {
            this.resetState();
        }
    }

    resetState = () => {
        this.isActive = false;
        this.activeStroke = 1;
        this.uiCont.setDisplay('none');
        this.uiCont.addClass('ws2DMenuContainer--disable');
        this.uiCont.removeClass('ws2DMenuContainer--active');
    }
}

export { Menu2DPathStroke };