import * as THREE from 'three';
import EditorExperience from '../2DEditorExperience';

import {
    fromPosObjectToVec3Pos,
    fromVec3PosToPosObject,
    getTransformed2DPos,
    getTransformed2DPosV1,
} from '../../threeUtils/TransformConversions';


export default class QRCode {
    constructor(pinProps) {
        this.pinProps = pinProps;

        this.editor = new EditorExperience();

        this.name = pinProps.name || "";
        this.id = pinProps.id;

        this.scaleXZ = this.editor.locScales.amScaleXZ;
        this.scaleY = this.editor.locScales.scaleY;

        this.scaleXZ /= (this.scaleXZ / (this.editor.floorData.MIN_SIZE * .023));
        this.scaleY /= (this.scaleY / (this.editor.floorData.MIN_SIZE * .03));

        this.visible = pinProps.visible || true;

        this.position = this.get2dPosFrom3dPos(fromPosObjectToVec3Pos(pinProps.position));
        this.position3d = fromPosObjectToVec3Pos(pinProps.position);

        this.editor.jsonQRAnchors.push({...pinProps, position2d: fromVec3PosToPosObject(this.position.clone())});

        this.mesh = {};

        this.setUpPin();
    }


    setUpPin = () => {
        const spriteTex = new THREE.TextureLoader().load('/static/sprites/2d_access_points.png');
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        this.spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            transparent: true,
            depthTest: false,
            depthWrite: false,
        });

        this.mesh = new THREE.Sprite(this.spriteMaterial);
        this.mesh.position.copy(this.position);
        this.mesh.scale.set(this.scaleXZ, this.scaleY, this.scaleXZ);
        this.mesh.rotation.y = THREE.MathUtils.degToRad(180);
        this.mesh.visible = this.visible;

        // adjust pos
        this.mesh.position.y += this.mesh.scale.y * 0.4;
        this.position.copy(this.mesh.position);

        this.mesh.name = this.name;
        this.mesh.userData['type'] = 'qrAnchor';
        this.mesh.userData['id'] = this.id;
        this.mesh.userData['visible'] = this.visible;
        this.mesh.userData['viewType'] = 'qrAnchor';
        this.mesh.userData['skipScene'] = true;
    }


    get2dPosFrom3dPos = (pos3d) => {
        const { invWorldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6 
            ? getTransformed2DPos(invWorldMatrix, imgWidth, imgHeight, pos3d)
            : getTransformed2DPosV1(invWorldMatrix, imgWidth, imgHeight, pos3d);
    }

}