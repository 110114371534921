import {
  File,
  Download,
  Code,
  Map,
  Settings as SettingsIcon,
  HelpCircle,
  Flag,
  Home,
  BarChart,
  Users as UserIcon,
  PieChart, DollarSign
} from 'react-feather';

import {
  D_LANDING, D_ANALYTICS,
  D_CONTENT_LIBRARY, D_DOWNLOADS, 
  D_DEVELOPERS, D_SETTINGS, 
  D_STUDIO, D_SUPPORT, 
  D_CONTENT_MAPS, D_CONTENT_USERS,
  D_CONTENT_IMPERSONATE, D_CONTENT_ANALYTICS,
  D_CONTENT_SDK_ANALYTICS, D_CONTENT_LANDING,
  D_CONTENT_PLANS, D_CONTENT_MANAGE_ACCOUNTS
} from '../../_utils/routes'

const menuObj = {
  'owner': [
    {
      link: D_LANDING,
      icon: Home,
      title: 'Dashboard',
      id: 0,
    },
    {
      link: D_STUDIO,
      icon: Map,
      title: 'My Maps',
      id: 1,
    },
    {
      link: D_CONTENT_LIBRARY,
      icon: File,
      title: 'Content Library',
      id: 3,
    },
    {
      link: D_DEVELOPERS,
      icon: Code,
      title: 'For Developers',
      id: 4,
    },
    {
      link: D_DOWNLOADS,
      icon: Download,
      title: 'Downloads',
      id: 5,
    },
    {
      link: D_ANALYTICS,
      icon: BarChart,
      title: 'Analytics',
      id: 6,
    }, 
    {
      link: D_SETTINGS,
      icon: SettingsIcon,
      title: 'My Account',
      id: 7,
      restrictAccess: true,
    },
    {
      link: D_SUPPORT,
      icon: HelpCircle,
      title: 'Help & Support',
      id: 6,
    },
  ],
  'user': [
    {
      link: D_LANDING,
      icon: Home,
      title: 'Dashboard',
      id: 0,
    },
    {
      link: D_STUDIO,
      icon: Map,
      title: 'My Maps',
      id: 1,
    },
    {
      link: D_CONTENT_LIBRARY,
      icon: File,
      title: 'Content Library',
      id: 3,
    },
    {
      link: D_DEVELOPERS,
      icon: Code,
      title: 'For Developers',
      id: 4,
    },
    {
      link: D_DOWNLOADS,
      icon: Download,
      title: 'Downloads',
      id: 5,
    },
    {
      link: D_ANALYTICS,
      icon: BarChart,
      title: 'Analytics',
      id: 6,
    }, 
    {
      link: D_SETTINGS,
      icon: SettingsIcon,
      title: 'My Account',
      id: 7,
      restrictAccess: true,
    },
    {
      link: D_SUPPORT,
      icon: HelpCircle,
      title: 'Help & Support',
      id: 6,
    },
  ],
  'admin': [
    {
      link: D_CONTENT_LANDING,
      icon: Home,
      title: 'Dashboard',
      id: 0,
    },
    {
      link: D_CONTENT_MANAGE_ACCOUNTS,
      icon: SettingsIcon,
      title: 'Manage Accounts',
      id: 1,
    },
    {
      link: D_CONTENT_ANALYTICS,
      icon: BarChart,
      title: 'Admin Analytics',
      id: 2,
    },
    {
      link: D_CONTENT_IMPERSONATE,
      icon: UserIcon,
      title: 'Impersonate',
      id: 3,
    },
    {
      link: D_CONTENT_USERS,
      icon: Map,
      title: 'User Access',
      id: 4,
    },
    {
      link: D_CONTENT_MAPS,
      icon: Flag,
      title: 'Reported Content',
      id: 5,
    },
    {
      link: D_CONTENT_SDK_ANALYTICS,
      icon: PieChart,
      title: 'SDK Analytics',
      id: 5,
    },
    {
      link: D_CONTENT_PLANS,
      icon: DollarSign,
      title: 'Manage Plans',
      id: 6,
    },
  ],
};

export default menuObj;