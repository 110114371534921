import React from 'react';
// import { useDispatch } from 'react-redux';
import { NavLink as Link } from 'react-router-dom';
import {
    Card, Table,
    TableBody, TableCell,
    TableHead, TableRow,
    Button
} from '@mui/material';


const UserTable = ({users}) => {
    const uId = Math.floor(Math.random() * 90000) + 10000;
    
    return (
        <Card>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell className="fw600">User ID</TableCell>
                        <TableCell className="fw600">User Email</TableCell>
                        <TableCell className="fw600">User Name</TableCell>
                        <TableCell className="w60 fw600">Analytics</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow hover key={user.id}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.name}</TableCell>
                            <TableCell className="w80">
                                <Button variant='outlined'><Link to={`/dashboard/sdk-analytics-users/${uId}`} state={user} className="linkTag">View Analytics</Link></Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}

export default UserTable;