import * as React from 'react';
import {
    Backdrop, Box,
    Modal, Fade,
    Button
} from '@mui/material';

import {
    XCircle
} from 'react-feather';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};


export default function ConfirmPublishModal({ openModal, onCloseModal, onSuccessModal, isFloorMap }) {
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal} />
                        {
                            openModal &&
                            <React.Fragment>
                                <div className="mapdetails__mapModalHeader">Publish {isFloorMap ? 'Map' : 'Building'}</div>
                                <div className="mapdetails__mapModalText">Are you sure, you want to publish the {isFloorMap ? 'map' : 'building'}?</div>
                                <div className="mapdetails__mapModalText">Publish your {isFloorMap ? 'map' : 'building'} when you finish editing your map to ensure your viewers are experiencing your most recent updates.</div>
                                <div className="publishModalBtnsDiv">
                                    <Button variant='contained' color='errorRed' onClick={onCloseModal} className='mapdetails__mapModalBtnText'>Cancel</Button>
                                    <Button variant='contained' color='successGreen' onClick={() => onSuccessModal(true)} className='mapdetails__mapModalBtnText'>Publish</Button>
                                </div>
                            </React.Fragment>
                        }
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
