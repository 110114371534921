import { stepperMapDetails } from "../../../../../../features/user/studio/studioSlice";
import { isMapDetailsExist } from "../../../../../../features/user/studio/studioSlice";
import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import ImageComponent from "./ImageComponent";

const UploadComponent = ({ header, subHeader, setFilecb, floorplanNamecb }) => {
  const mapData = useSelector(stepperMapDetails);
  const isMapDetailsExists = useSelector(isMapDetailsExist);
  const [filename, setFilename] = useState("");
  const [isFileSelected, setIsFileSelected] = useState(false);

  useEffect(() => {
    if (isMapDetailsExists) {
      setFilename(mapData.floorplanName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMapDetailsExists]);

  const memoizedCallback = useCallback((file) => {
    setFilecb(file);
    setIsFileSelected(file ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  } , []);

  return (
    <div className="stepper__Card">
      <div className="stepper__SectionHeader">{header}</div>
      <label className="content-type-select mt-4">{subHeader} </label>
      {floorplanNamecb ? (
        <label className="content-type-select mt-4">
          <input
            type="text"
            id="input-floorplan-name"
            readOnly={!(isFileSelected)}
            disabled ={!(isFileSelected)}
            className="form-control"
            placeholder={isFileSelected ? "Floor plan Name*" : "Floor plan Name"}
            onChange={(e) => {
              floorplanNamecb(e.target.value);
              setFilename(e.target.value);
            }}
            value={filename}
            maxLength={30}
          />
        </label>
      ) : null}
      <ImageComponent
        setFilecb= {memoizedCallback}
        isFloorplan={floorplanNamecb?true:false}
      />
    </div>
  );
}
export default UploadComponent;
