import { Command } from './Command';

function SetFloorTransform( editor, object, transformMode, newValue, optOldValue, delayAutoSave) {
    Command.call(this, editor);

    this.type = 'SetFloorTransform';
    this.name = 'Set Floor Transform';
    this.updatable = true;
    this.delayAutoSave = delayAutoSave;

    this.transformMode = transformMode;

    this.object = object;

    if(object !== undefined && newValue !== undefined) {
        this.oldValue = object[transformMode].clone();
        this.newValue = newValue.clone();
        this.name = object.name;
    }

    if(optOldValue !== undefined) {
        this.oldValue = optOldValue;
    }
}

SetFloorTransform.prototype = {
    execute: function() {
        if('metaFloorplan' in this.object.userData) {
            this.object.children[0][this.transformMode].copy(this.newValue);
            this.object.children[0].updateMatrixWorld();
        } else {
            this.object[this.transformMode].copy(this.newValue);
        }

        this.object.updateMatrixWorld();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave, this.name])
        this.editor.trigger('sceneAreaChanged');
    },

    undo: function() {
        if('metaFloorplan' in this.object.userData) {
            this.object.children[0][this.transformMode].copy(this.oldValue);
            this.object.children[0].updateMatrixWorld();
        } else {
            this.object[this.transformMode].copy(this.oldValue);
        }

        this.object.updateMatrixWorld();
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave, this.name])
        this.editor.trigger('sceneAreaChanged');
    },

    update: function(command) {
        this.newPosition.copy(command.newPosition);
    },

    toJSON: function() {
        var output = Command.prototype.toJSON.call(this);

        output.objectUuid = this.object.uuid;
        output.transformMode = this.transformMode;
        output.oldValue = this.oldValue.toArray();
        output.newValue = this.newValue.toArray();
        return output;
    }
}

export { SetFloorTransform };