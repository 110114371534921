import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";

import { 
    UIPanel,
    UIText,
    UIObjectInfoCard,
} from "../ui.lib";

import { SetVisible } from '../../commands/SetVisible';

import {
    getObjectByUserDataProperty
} from '../../threeUtils/TransformConversions';
class SidebarContentMapMarkers extends EventEmitter{
    
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.dom = null;

        this.initUI();

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);

    }

    initUI = () => {
        this.uiMyContent = new UIPanel();
        this.qrText = new UIText(undefined, 'Placed Map Markers');
        this.uiMyContent.addChild(this.qrText);
        this.dom = this.uiMyContent;
    }

    emptyContent = () => {
        var child = this.uiMyContent.dom.lastChild; 
        while (child) {
            if(child === this.qrText.dom) break;
            this.uiMyContent.dom.removeChild(child);
            child = this.uiMyContent.dom.lastChild;
        }
    }


    updateUiData = () => {
        if(this.editor.jsonMapMarkers.length === 0) {
            this.emptyContent();
            const uiNoDataCard = new UIPanel();
            uiNoDataCard.addClass('ws_uiNoDataCard');
            const uiSubText = document.createElement('div')
            uiSubText.classList.add('ws_uiNDSubText');
            uiSubText.textContent = 'You haven’t added any map marker yet.';
            uiNoDataCard.dom.appendChild(uiSubText);
            this.uiMyContent.addChild(uiNoDataCard);
        } else {
            const uiCardsRow = new UIPanel();
            uiCardsRow.addClass('ws_uiCardsRowFloat');
            this.emptyContent();
            this.editor.jsonMapMarkers.forEach((marker, index) => {
                const uiImageCont = new UIObjectInfoCard(this.editor, marker, getObjectByUserDataProperty(this.editor.scene, 'id', marker.id), this.onMarkerSelect, "MARKER", index);
                uiCardsRow.addChild(uiImageCont)
            })
            this.uiMyContent.addChild(uiCardsRow);
        }
    }

    onMarkerSelect = (content, eventType) => {
        const object = getObjectByUserDataProperty(this.editor.scene, 'id', content.id);
        if(object) {
            if(eventType === 2) {
                this.editor.onCommand(new SetVisible(this.editor, object, !object.visible, false, false))
            } else {
                this.editor.select(object);
            }
        }

    }

    refreshUI = () => {
        this.updateUiData()
    }

    onToggleMenuActive = () => {

    }

    unload = () => {
        this.emptyContent();
    }
}

export { SidebarContentMapMarkers };