import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../api";
import { HandleExceptionWithSecuredCatch } from "../CombineCatch";
import { setNewAlert } from "../common/alertSlice";

const getLocationPins = createAsyncThunk(
  "editor2d/getLocationPin",
  async (mapId, { dispatch }) => {
    try {
      const response = await Server.get(`/v1/map/locationpin/${mapId}`);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        return dispatch(
          setNewAlert({
            msg: "error",
            alertType: "information",
          })
        );
      } else {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  }
);

const getCategories = createAsyncThunk(
  "editor2d/getCategories",
  async (mapId, { dispatch }) => {
    try {
      const response = await Server.get(
        `/v1/map/locationpin/category/${mapId}`
      );
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        return dispatch(
          setNewAlert({
            msg: "error",
            alertType: "information",
          })
        );
      } else {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  }
);

const createCategory = createAsyncThunk(
  "editor2d/createCategory",
  async (params, { dispatch }) => {
    try {
      const response = await Server.post(
        `/v1/map/locationpin/category/${params.mapId}`,
        params
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const updateCategory = createAsyncThunk(
  "editor2d/updateCategory",
  async (params, { dispatch }) => {
    try {
      const response = await Server.post(
        `/v1/map/locationpin/category/${params.mapId}`,
        params
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const deleteCategory = createAsyncThunk(
  "editor2d/deleteCategory",
  async (params, { dispatch }) => {
    try {
      const response = await Server.delete(
        `/v1/map/locationpin/category/${params.mapId}/${params.id}`
      );
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        return dispatch(
          setNewAlert({
            msg: "error",
            alertType: "information",
          })
        );
      } else {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  }
);

const updateCategoryBulk = createAsyncThunk(
  "editor2d/updateCategoryBulk",
  async (params, { dispatch }) => {
    try {
      const response = await Server.put(
        `/v1/map/locationpin/category/${params.mapId}/bulkupdate`, [...params.data]
      );
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        return dispatch(
          setNewAlert({
            msg: "error",
            alertType: "information",
          })
        );
      } else {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  }
);

// Paths Actions create, edit, delete
const getPaths = createAsyncThunk('webstudo/getPaths', async (mapId, { dispatch }) => {
  try {
    const response = await Server.get(`/v1/map/locationpin/path/${mapId}`);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const updatePath = createAsyncThunk('webstudo/updatePath', async ({ PathObj, mapId }, { dispatch }) => {
  try {
    const response = await Server.put(`/v1/map/locationpin/path/${mapId}`, PathObj);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const deletePath = createAsyncThunk('webstudo/deletePath', async ({ pathId, mapId }, { dispatch }) => {
  try {
    const response = await Server.delete(`/v1/map/locationpin/path/${mapId}/${pathId}`);
    const urlArr = response.config.url.split('/')
    return {...response.data, id: urlArr[urlArr.length -1]};
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

// floorplan Actons create, edit, delete
const updatePin = createAsyncThunk('webstudo/updatePin', async ({ pinObj, mapId }, { dispatch }) => {
  try {
    const response = await Server.put(`/v1/map/locationpin/${mapId}`, pinObj);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const deletePin = createAsyncThunk('webstudo/deletePin', async ({ pinId, mapId }, { dispatch }) => {
  try {
    const response = await Server.delete(`/v1/map/locationpin/${mapId}/${pinId}`);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const getAmenityPinCategories = createAsyncThunk('webstudo/getAmenityPinCategories', async ( _, { dispatch }) => {
  try {
    const response = await Server.get('/v1/location-pins/amenity-types');
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const bulkUpdatePinsAndPaths = createAsyncThunk('webstudo/bulkUpdatePinsAndPaths', async ({ reqObj, mapId }, { dispatch }) => {
  try {
    const response = await Server.put(`/v1/map/locationpin/${mapId}/bulkupdate`, reqObj);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const bulkDeletePaths = createAsyncThunk('webstudo/bulkDeletePaths', async ({ reqObj, mapId, deleteAll }, { dispatch }) => {
  try {
    const response = await Server.delete(`/v1/map/locationpin/path/${mapId}/bulkdelete/${deleteAll}`, { data: reqObj });
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const initialState = {
  locationPins: [],
  navigationPaths: null,
  pinContent: null,
  updatePinLog: null,
  deletePinLog: null,
  pathContent: null,
  updatePathLog: null,
  deletePathLog: null,
  categories: [],
  amenityCategories: null,
  bulkUpdateLog: null,
  bulkDeleteLog: null,
  log: null,
};

const editor2DSlice = createSlice({
  name: "editor2d",
  initialState,
  reducers: {
    resetEditor2dStore: () => {
      return { ...initialState };
    },
    resetPathStates: (state) => {
      return {
        ...state,
        pathContent: null,
        updatePathLog: null,
        deletePathLog: null
      };
    },
    resetPinStates: (state) => {
      return {
        ...state,
        pinContent: null,
        updatePinLog: null,
        deletePinLog: null
      };
    },
    reset2dStudioStore: (state) => {
      return {
        ...state,
        navigationPaths: null,
        locationPins: [],
        amenityCategories: null,
      };
    },
    resetBulkLog: (state) => {
      return {
        ...state, 
        bulkUpdateLog: null,
        bulkDeleteLog: null,
      }
    },
  },
  extraReducers: {
    "common/resetState": () => {
      return { ...initialState };
    },
    [getLocationPins.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        locationPins: payload.data,
      };
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        categories: payload?.data ? [...payload?.data] : [],
      };
    },
    [createCategory.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        log: payload.data.message,
      };
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        log: payload.data.message,
      };
    },
    [deleteCategory.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        log: payload.data.message,
      };
    },
    [updatePin.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        pinContent: payload.data,
        updatePinLog: payload.status,
        deletePinLog: null,
      };
    },
    [deletePin.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updatePinLog: null,
        deletePinLog: payload.status,
      };
    },
    [updateCategoryBulk.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        log: payload.data.message,
      };
    },
    [getPaths.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        navigationPaths: payload.data,
        log: null,
      };
    },
    [updatePath.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        pathContent: payload.data,
        updatePathLog: payload.status,
        deletePathLog: null,
      };
    },
    [deletePath.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        pathContent: payload?.id,
        updatePathLog: null,
        deletePathLog: payload.status,
      };
    },
    [getAmenityPinCategories.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        amenityCategories: payload.data,
        log: null,
      };
    },
    [bulkUpdatePinsAndPaths.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        bulkUpdateLog: payload.status,
      };
    },
    [bulkDeletePaths.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        bulkDeleteLog: payload.status,
      };
    },
  },
});


export {
  getLocationPins,
  getCategories,
  deleteCategory,
  createCategory,
  updateCategory,
  updateCategoryBulk,
  getPaths,
  updatePath, 
  deletePath,
  updatePin,
  deletePin,
  getAmenityPinCategories,
  bulkUpdatePinsAndPaths,
  bulkDeletePaths,
};

export const { resetEditor2dStore, resetPathStates, resetPinStates, reset2dStudioStore, resetBulkLog } = editor2DSlice.actions;

export default editor2DSlice.reducer;
