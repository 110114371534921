import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Backdrop, Box, Modal, Fade } from "@mui/material";
import deleteIcon from "../../../../assets/svgs/studio/locationPin/delete_2.svg";
import { has, isEmpty, cloneDeep, isEqual } from "lodash";
import { XCircle } from "react-feather";
import { updateCategoryBulk } from "../../../../features/webstudio/editor2dSlice";
import { setNewAlert } from "../../../../features/common/alertSlice";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80% !important",
    height: "320px%",
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    borderRadius: "16px",
};

const DeleteCategoryModal = ({
    openModal,
    onCloseModal,
    modalData,
    handleDelete,
}) => {
    const dispatch = useDispatch();
    const isFromSidebar = !has(modalData, "isCategory");
    const categories = useSelector((state) => state.editor2D.categories);
    const [categoriesReorder, setCategoriesReorder] = useState(categories);
    const [deleteCat, setDeleteCat] = useState({});
    const [save, setSave] = useState(false);

    useEffect(() => {
        if (isFromSidebar) {
            setDeleteCat({
                isCategory: modalData?.data?.isCategory,
                data: modalData?.data,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalData]);

    useEffect(() => {
        if (save) {
            dispatch(
                updateCategoryBulk({
                    mapId: modalData.mapId,
                    data: categoriesReorder,
                })
            ).then(() => {
                onCloseModal();
                dispatch(
                    setNewAlert({
                        msg: "Details updated",
                        alertType: "information",
                    })
                );
                setSave(false);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [save]);

    const delteCategoty = () => {
        // console.log(modalData)
        // console.log(deleteCat)
        const updatedCategories = cloneDeep([
            ...(isEqual(categories, categoriesReorder)
                ? categoriesReorder
                : categories),
        ]);
        if (deleteCat.isCategory) {
            let deleteCatIndex = updatedCategories.findIndex(
                (cat) => cat.id === deleteCat.data.id
            );
            if (deleteCatIndex > -1) {
                updatedCategories.splice(deleteCatIndex, 1);
            }
            setCategoriesReorder([...updatedCategories]);
        } else {
            let selectedCategoryIndex = updatedCategories.findIndex(
                (cat) => deleteCat.data?.categoryId === cat.id
            );
            let selectedSubCat = cloneDeep([
                ...(isEqual(categories, categoriesReorder) ||
                !isEmpty(categoriesReorder)
                    ? categoriesReorder[selectedCategoryIndex].children
                    : categories[selectedCategoryIndex].children),
            ]);

            const index = selectedSubCat.indexOf(
                selectedSubCat[
                    selectedSubCat.findIndex(
                        (subcat) => subcat.id === deleteCat.data.id
                    )
                ]
            );
            if (index > -1) {
                selectedSubCat.splice(index, 1);
            }
            updatedCategories[selectedCategoryIndex].children = selectedSubCat;
            // console.log(updatedCategories);
            setCategoriesReorder([...updatedCategories]);
        }
    };

    if (modalData !== null) {
        // console.log(modalData)
        let { isCategory, categoryName } = modalData;
        if (isFromSidebar) {
            isCategory = modalData?.data?.isCategory;
            categoryName = modalData?.data?.name;
        }

        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={openModal}
                    onClose={onCloseModal}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 600,
                    }}
                >
                    <Fade in={openModal}>
                        <Box sx={style}>
                            <XCircle
                                color="#353E5A"
                                strokeWidth="1.5"
                                className="mapdetails__mapModalCloseIcon"
                                onClick={onCloseModal}
                            />
                            <img
                                alt="delIcon"
                                src={deleteIcon}
                                style={{
                                    marginRight: "1rem",
                                    marginLeft: "-1rem",
                                }}
                            ></img>
                            <h2 className="mt-4 mb-4">{`Delete ${
                                isCategory ? "" : "Sub"
                            } Category`}</h2>
                            <p>
                                Delete the{" "}
                                {isCategory ? "category" : "subcategory"} '
                                <span style={{ fontWeight: "bold" }}>
                                    {categoryName}
                                </span>
                                ' ?
                                {/* No
              location pins are attached to this subcategory. */}
                            </p>
                            <div className="row justify-content-between mt-5">
                                <button
                                    className="col-sm cancel-btn cat-btn"
                                    onClick={onCloseModal}
                                >
                                    No, Cancel
                                </button>
                                <button
                                    className="col-sm del-btn cat-btn"
                                    style={{ marginLeft: "40px" }}
                                    onClick={() => {
                                        if (isFromSidebar) {
                                            delteCategoty();
                                            setSave(true);
                                        } else {
                                            handleDelete();
                                        }
                                    }}
                                >
                                    Yes, Delete!
                                </button>
                            </div>
                        </Box>
                    </Fade>
                </Modal>
            </div>
        );
    }
};

export default DeleteCategoryModal;
