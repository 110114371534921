import { Download } from "react-feather";
import { Grid } from "@mui/material";
import { useState, useEffect } from "react";
import RenderTile from "../../Render/RenderTile";
import Visitorsvg from "../../../../../../assets/svgs/analytics/a_visitor.svg";
import EyeSvg from "../../../../../../assets/svgs/analytics/a_eye_violet.svg";
// import ImageSvg from "../../../../../../assets/svgs/analytics/a_image.svg";
import ClockSvg from "../../../../../../assets/svgs/analytics/a_acc_time.svg";
import { getTotalVisitorByMap } from "../../../../../../features/user/analytics/analyticsSlice";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "../../../../../_utils/DatePicker";
import { Box } from "@mui/material";
import { CSVLink } from "react-csv";
import { lastTEDays, secondsToHms, getFileNameWithTS } from "../../../../../_utils/DateUtils";

const VisitorBehavior = () => {
  const dispatch = useDispatch();

  const [dateObj, setDateObj] = useState(lastTEDays);

  const totalVisitorByMap = useSelector(
    (state) => state.analytics.totalVisitorByMap
  );
  const selectedMap = useSelector((state) => state.analytics.selectedMap);

  useEffect(() => {
    dispatch(getTotalVisitorByMap({ mapId: selectedMap.mapId, ...dateObj }));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(getTotalVisitorByMap({ mapId: selectedMap.mapId, ...dateObj }));
    // eslint-disable-next-line
  }, [selectedMap.mapId, dateObj]);

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col" style={{ paddingRight: "2.5rem" }}>
            <div className="analyticshome__anCard--contentRow--header">
              <div className="row m-0 box">
                <div className="col-2">Overview</div>
                <div className="col-5"></div>

                <div className="col-2" style={{ color: "#2A4DC2" }}>
                  {" "}
                  <CSVLink
                    headers={[
                      { label: "Total Visitors", key: "visits" },
                      { label: "Average Map Visit", key: "avg" },
                      // { label: "Most Viwed Content", key: "mvc" },
                      { label: "Total Acc Visit", key: "tac" },
                    ]}
                    data={[
                      {
                        visits: totalVisitorByMap?.totalVisits
                          ? totalVisitorByMap.totalVisits
                          : "--",
                        avg: totalVisitorByMap?.average
                          ? secondsToHms(totalVisitorByMap.average)
                          : "--",
                        // mvc: totalVisitorByMap?.mvc
                        //   ? totalVisitorByMap.mvc
                        //   : "--",
                        tac: totalVisitorByMap?.totalDuration
                          ? secondsToHms(totalVisitorByMap.totalDuration)
                          : "--",
                      },
                    ]}
                    filename={getFileNameWithTS(`${selectedMap.mapName}-overview`)}
                    style={{ color: "#2A4DC2" }}
                  >
                    <Download /> Download CSV
                  </CSVLink>
                </div>
                <Box
                  component="span"
                  className="col-3 analyticshome__anCard--filterBox"
                >
                  <span style={{ color: "#858993" }}>Date:</span>
                  <DatePicker
                    cb={(obj) => {
                      setDateObj(obj);
                      dispatch(
                        getTotalVisitorByMap({ mapId: selectedMap.mapId, ...obj })
                      );
                    }}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ padding: "0px 32px 0px 32px" }}>
          <Grid container={true} spacing={4}>
            <RenderTile
              tileSize={4}
              color="#B80290"
              miniCardHeader={
                totalVisitorByMap?.totalVisits
                  ? totalVisitorByMap.totalVisits
                  : "--"
              }
              miniCardDesc="Total visitors"
              svg={Visitorsvg}
            />
            <RenderTile
              tileSize={4}
              color="#5600AC"
              miniCardHeader={
                totalVisitorByMap?.average ? secondsToHms(totalVisitorByMap.average) : "--"
              }
              miniCardDesc="Average map visit time"
              svg={EyeSvg}
            />
            {/* <RenderTile
              tileSize={6}
              color="#008069"
              miniCardHeader={
                totalVisitorByMap?.viewed ? totalVisitorByMap.mostviewed : "--"
              }
              miniCardDesc="Most viewed content type"
              svg={ImageSvg}
            /> */}
            <RenderTile
              tileSize={4}
              color="#2A4DC2"
              miniCardHeader={
                totalVisitorByMap?.totalDuration
                  ? secondsToHms(totalVisitorByMap.totalDuration)
                  : "--"
              }
              miniCardDesc="Total accumulated visit time"
              svg={ClockSvg}
            />
          </Grid>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default VisitorBehavior;
