import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {
    Backdrop, Box, 
    Modal, Fade, 
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

//Images
import CIM11Icon from '../../../../assets/pngs/studio/ConnectorPins/CIM_11.png';
import CIM13Icon from '../../../../assets/pngs/studio/ConnectorPins/CIM_12.png';
import CIM21Icon from '../../../../assets/pngs/studio/ConnectorPins/CIM_21.png';
import CIM22Icon from '../../../../assets/pngs/studio/ConnectorPins/CIM_22.png';
import CIM23Icon from '../../../../assets/pngs/studio/ConnectorPins/CIM_23.png';
import CIM31Icon from '../../../../assets/pngs/studio/ConnectorPins/CIM_31.png';
import CIM32Icon from '../../../../assets/pngs/studio/ConnectorPins/CIM_32.png';
import CIM33Icon from '../../../../assets/pngs/studio/ConnectorPins/CIM_33.png';
import { setOnboardingFlag } from '../../2d-editor/threeUtils/Connectors/ConnectorUtils';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

export default function ConnectorOnboardingModal({openModal, onCloseModal}) {

    const renderPaneOne = () => {
        return(
            <div className='obModal--obCard'>
                <h4 className='obModal--obCard--title'>Connector Pins and Linking Maps</h4>
                <span className='obModal--obCard--header'>Connector pins serve as the links between the imported maps. Anchor connector pins at locations where visitors will enter or exit from one map to another. 
                    By strategically positioning connector pins at these entry/exit points, you ensure a seamless transition for visitors as they navigate between different maps within your venue.</span>
                <div className='obModal--obCard--imgCont'>
                    <img src={CIM11Icon} alt={CIM11Icon} draggable="false" />
                    <img src={CIM13Icon} alt={CIM13Icon} draggable="false" />
                </div>
            </div>
        )
    }

    const renderPaneTwo = () => {
        return(
            <div className='obModal--obCard'>
                <h4 className='obModal--obCard--title'>How to link and group your pins</h4>
                <span className='obModal--obCard--header'>To organize the connectors effectively, follow these guidelines:</span>
                <div className='obModal--obCard--infoCont'>
                    <div className='obModal--obCard--infoCard'>
                        <img src={CIM21Icon} alt={CIM21Icon} draggable="false" />
                        <span className='obModal--obCard--infoCard--title'>Pairs</span>
                        <span className='obModal--obCard--infoCard--subTitle'>For connectors with defined start and end points, such as a bridge or an escalator, create a pair to establish the connection between the top and bottom / entry exit.</span>
                    </div>
                    <div className='obModal--obCard--infoCard'>
                        <img src={CIM22Icon} alt={CIM22Icon} draggable="false" />
                        <span className='obModal--obCard--infoCard--title'>Groups</span>
                        <span className='obModal--obCard--infoCard--subTitle'>Group multiple connectors that link together, such as elevators in the same shaft. Grouping these connectors maintains their association and facilitates efficient navigation for visitors.</span>
                    </div>
                    <div className='obModal--obCard--infoCard'>
                        <img src={CIM23Icon} alt={CIM23Icon} draggable="false" />
                        <span className='obModal--obCard--infoCard--title'>Grouping Pairs</span>
                        <span className='obModal--obCard--infoCard--subTitle'>Paired connectors like escalators or stairs that span multiple levels can be grouped. Grouping pairs maintains their association and facilitates efficient navigation for visitors.</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderPaneThree = () => {
        return(
            <div className='obModal--obCard'>
                <h4 className='obModal--obCard--title'>Add access points near connector pins</h4>
                <span className='obModal--obCard--header'>For seamless navigation between floor plans, place access points near connector pins. When visitors scan these access points, they gain entry to the respective floor maps and access level-specific content. 
                    By anchoring access points close to the connector pins, you create a user-friendly experience, allowing visitors to navigate smoothly between different levels in your venue.</span>
                <div className='obModal--obCard--imgCont2'>
                    <img src={CIM31Icon} alt={CIM31Icon} draggable="false" />
                    <img src={CIM32Icon} alt={CIM13Icon} draggable="false" />
                    <img src={CIM33Icon} alt={CIM13Icon} draggable="false" />
                </div>
            </div>
        )
    }

    const handleClose = () => {
        setOnboardingFlag('connectorOnboarding', true);
        onCloseModal();
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={handleClose}/>
                        <div className='obModal'>
                            <Carousel autoPlay={false} height={560}>
                                {renderPaneOne()}
                                {renderPaneTwo()}
                                {renderPaneThree()}
                            </Carousel>
                        </div>
                        <button className='obModal--btn' onClick={handleClose}>Got it!</button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}