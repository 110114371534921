import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";

import {
    UIButton,
    UIPanel,
    UICol,
    UIInputTextArea,
    UITextHeader,
    UIRow,
    UIColor,
    UIText,
} from "../ui.lib";

class SidebarContentText extends EventEmitter {
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.strings = this.editor.strings;
        this.scene = this.editor.scene;

        this.dom = null;

        this.textValue = "";
        this.textColor = "#ffffff";

        this.initUI();

        this.editor.on("toggleMenuActive", this.onToggleMenuActive);
    }

    initUI = () => {
        this.uiMyContent = new UIPanel();

        // Description
        var objectDescRow = new UICol().addClass("ws_uiTextContentCol");
        this.objectDescInput = new UIInputTextArea(
            "objectTextName",
            "100%",
            undefined,
            200,
            "Add Text Here",
            "4",
            "forText"
        ).onChange((e) => {
            var value = e.target.value;
            this.textValue = value;
            this.uiTextLen.setTextContent(`${this.textValue.length}/200`);
        });
        const objectHeaderRow = new UIRow().addClass("ws_uiTextContentRow");
        objectHeaderRow.addChild(
            new UIText(undefined, this.strings.getKey("menubar/text"))
        );
        this.uiTextLen = new UIText(undefined, `${this.textValue.length}/200`);
        objectHeaderRow.addChild(this.uiTextLen);
        objectDescRow.addChild(objectHeaderRow);
        objectDescRow.addChild(this.objectDescInput);
        this.uiMyContent.addChild(objectDescRow);

        // Color
        var objectColorRow = new UICol();
        this.objectColor = new UIColor()
            .addClass("ws_uiColorMod")
            .onChange((e) => {
                this.textColor = this.objectColor.getValue();
                this.colorValueInput.setTextContent(this.textColor);
            });
        objectColorRow.addChild(
            new UITextHeader(
                "100%",
                this.strings.getKey("menubar/color")
            ).setStyle("fontWeight", "400 !important")
        );
        var uiColorRow = new UIRow().addClass("ws_uiTextColorRow");
        uiColorRow.addChild(this.objectColor);
        this.colorValueInput = new UIText(
            "max-content",
            this.textColor
        ).addClass("ws_uiTextColorValue");
        uiColorRow.addChild(this.colorValueInput);
        objectColorRow.addChild(uiColorRow);
        this.uiMyContent.addChild(objectColorRow);

        this.uiAddTxtBtn = new UIButton("Add Text").onClick(() => {
            if (this.textValue.length > 0) {
                this.editor.loader.initObjectLoading(
                    {
                        value: this.textValue,
                        color: this.textColor,
                        contentType: "texts",
                    },
                    null,
                    "preload"
                );
            } else {
                this.editor.callbacks.generateAlert({
                    msg: "Please add the text string!",
                    alertType: "information",
                });
            }
        });

        this.uiMyContent.addChild(this.uiAddTxtBtn);

        this.dom = this.uiMyContent;
    };

    refreshUI = () => {
        if (this.uiMyContent) {
            let canAnchor = false;
            this.scene.children.forEach((child) => {
                if(child.userData && child.userData.navHelper 
                    && child.userData.navHelper === 'floorplan' && child.userData?.alignmentStatus === true) {
                        canAnchor = true;
                }
            });
            this.uiAddTxtBtn.setTextContent(canAnchor ? "Anchor Text" : "Add Text");

            this.textValue = "";
            this.textColor = "#ffffff";
            this.objectColor.setValue(this.textColor);
            this.objectDescInput.setValue(this.textValue);
            this.uiTextLen.setTextContent(`${this.textValue.length}/200`);
            this.colorValueInput.setTextContent(this.textColor);
        }
    };

    onToggleMenuActive = () => {};

    unload = () => {
        //TODO
    };
}

export { SidebarContentText };
