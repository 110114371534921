import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";

// import UI Components
import {
    UIPanel, UIRow, UIText
} from '../ui.lib';
import { TopbarObjectLoaderUI } from "./Topbar.ObjectLoader";
import { TopbarSave } from "./Topbar.Save";
import { TopbarVersionConrtol } from "./Topbar.VersionControl";
import { TopbarView } from "./Topbar.View";

class Topbar extends EventEmitter {

    constructor(props) {
        super(props)
        this.editor = new EditorExperience();
        this.parentEle = props.dom;

        this.editor.on('mountObjectLoader', this.onMountObjectLoader);
        this.editor.on('setTopbarMapName', this.setMapHeader);

        this.initUI();
    }

    initUI = () => {
        this.uiContainer = new UIPanel().addClass('webstudio__wsTopRow');
        const headerCont = new UIRow()

        this.topLogo = new TopbarVersionConrtol(this.editor);
        this.topView = new TopbarView(this.editor);
        this.topText = new UIText(undefined, '', true).addClass('topBarHeader');
        this.topSave = new TopbarSave(this.editor);
        this.topLoader = new TopbarObjectLoaderUI(this.editor);
        
        headerCont.addChild(this.topLogo.dom);
        headerCont.addChild(this.topView.dom);
        this.uiContainer.addChild(headerCont);
        this.uiContainer.addChild(this.topText);
        this.uiContainer.addChild(this.topSave.dom);
        this.uiContainer.addChild(this.topLoader.dom);

        this.parentEle.append(this.uiContainer.dom);
    }

    setMapHeader = (value) => {
        this.topText.setTextContent(value);
    }

    onMountObjectLoader = (bFlag) => {
        if(bFlag) {
            this.uiContainer.addClass('webstudio__wsTopRow--noEvnts');
        } else {
            this.uiContainer.removeClass('webstudio__wsTopRow--noEvnts');
        }
    }
}

export { Topbar }