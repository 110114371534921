import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MapTable from "./MapTable";
import Loader from "../../../common/loader";
//Icons
import { ChevronLeft, ChevronRight } from "react-feather";
//Actions
import {
  getReportedMaps,
  changePageNumber,
} from "../../../../features/admin/mapreports/mapReportSlice";
import { isEqual } from "lodash";

const MapReportsHome = (props) => {
  const dispatch = useDispatch();

  const [reportedMaps, setReportedMaps] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);

  const pageSize = 9;

  const reportedMapList = useSelector((state) => state.mapreports.reportedMaps);
  const totalPages = useSelector((state) => state.mapreports.totalPages) || 1;
  const page = useSelector((state) => state.mapreports.pageNumber);

  useEffect(() => {
    setFetching(true);
    fetchReportedMaps(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportedMapList && !isEqual(reportedMapList, reportedMaps)) {
      setReportedMaps(reportedMapList);
      setFetching(false);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportedMapList]);

  useEffect(() => {
    setPageNumber(page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    fetchReportedMaps(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const fetchReportedMaps = (pageNumber) => {
    dispatch(getReportedMaps({ pageNumber, pageSize }));
  };

  const handlePageChange = (pageType) => {
    if (pageType === 1) {
      if (pageNumber > totalPages) {
        dispatch(changePageNumber(pageNumber + 1));
        setLoading(true);
      }
    } else {
      if (pageNumber > 1) {
        dispatch(changePageNumber(pageNumber - 1));
        setLoading(true);
      }
    }
  };

  const renderMapReports = () => {
    if ((fetching && reportedMaps.length === 0) || loading) {
      return (
        <Loader
          loaderText={
            loading ? "Loading More Users..." : "Fetching Reported Maps..."
          }
          height="500px"
          width="100%"
        />
      );
    }

    if (reportedMaps.length === 0) {
      return <h2>No Reports Available!</h2>;
    }

    return (
      <Box className="boxWrapper">
        <MapTable maps={reportedMaps} />
      </Box>
    );
  };

  return (
    <div style={{ padding: "40px" }}>
      <Container maxWidth={false}>
        <div className="pageWrapper">
          <h4>Reported Maps</h4>
          <div>
            {pageNumber > 1 && (
              <ChevronLeft
                className="pageBar"
                onClick={() => handlePageChange(2)}
              />
            )}
            <span className="pageCount">
              {pageNumber} / {totalPages}
            </span>
            <ChevronRight
              className="pageBar"
              style={{
                visibility: totalPages !== pageNumber ? "visible" : "hidden",
              }}
              onClick={() => handlePageChange(1)}
            />
          </div>
        </div>
        {renderMapReports()}
      </Container>
    </div>
  );
};

export default MapReportsHome;
