import * as React from "react";
import { Backdrop, Box, Modal, Fade, Button, Grid } from "@mui/material";
import { XCircle } from "react-feather";

//assets
import QRHelper1 from '../../../../../assets/svgs/qrhelper/QRHelper_1.svg';
import QRHelper2 from '../../../../../assets/svgs/qrhelper/QRHelper_2.svg';
import QRHelper3 from '../../../../../assets/svgs/qrhelper/QRHelper_3.svg';
import QRHelper4 from '../../../../../assets/svgs/qrhelper/QRHelper_4.svg';
import QRHelper5 from '../../../../../assets/svgs/qrhelper/QRHelper_5.svg';
import CheckSvg from '../../../../../assets/svgs/access_check.svg';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 920,
    height: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    borderRadius: "16px",
    overflowY: "scroll",
};

const infoData = [
    {
        id: 3,
        title: 'A0 Large Access Point',
        description: 'Choose the A0 large size for venues where visitors need to scan access points from 4-6 meters away. Ideal for spacious areas like airports and large event venues, ensuring easy scanning from a distance.',
        icon: QRHelper3
    },
    {
        id: 2,
        title: 'A2 Medium Access Point',
        description: 'The A2 medium size is recommended for venues with moderate distances between access points and visitors. It\'s versatile for locations like department stores and stadiums where visitors can comfortably scan from a 2m or 6ft distance.',
        icon: QRHelper2
    },
    {
        id: 1,
        title: 'A4 Small Access Point',
        description: 'For venues with lower ceilings or narrow hallways, we recommend using the A4 size for your access points. This size is ideal for confined areas like offices or small galleries, where visitors need to be  1m or 3ft away to scan the access point.',
        icon: QRHelper1
    },
    {
        id: 4,
        title: 'Custom Access Point Sizes',
        description: 'Custom sizes allow you to tailor access points to your venue\'s unique needs. Keep in mind, smaller sizes may require visitors to be closer for scanning. You can create sizes between a minimum of 7 x 7in or 18 x 18cm and a maximum of 40 x 40in or 1 x 1m to match your space perfectly.',
        icon: QRHelper4
    },
    {
        id: 5,
        title: 'Printer Settings',
        description: 'To ensure optimal recognition of QR codes, we recommend using standard paper sizes like A4, A2, A0, or US printer paper Letter, Legal, and Tabloid and adjust the printer settings to "Scale to fit.',
        icon: QRHelper5
    }
]

export default function QRHelperModal({
    openModal,
    onCloseModal,
    isDownload,
    modalStyle,
}) {
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={{ ...style, ...(modalStyle ? modalStyle : {}) }}>
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={onCloseModal}
                        />
                        {isDownload && 
                            <div className="mapdetails__mapModalQRPane--downloadCard">
                                <img src={CheckSvg} alt="success_check" />
                                <span>Download Successfully!</span>
                            </div>
                        }
                        <div className="mapdetails__mapModalHeader">Display & Access Point Sizes</div>
                        <div className="mapdetails__mapModalText">Use these recommendations to size your access points, aiming for larger sizes while considering scanning distance.</div>
                        <div className="mapdetails__mapModalQRPane">
                            {infoData.map(info => (
                                <Grid container className="mapdetails__mapModalQRPane--infoCard" key={info.id}>
                                    <Grid item xs={6} >
                                        <img src={info.icon} alt={info.title}/>
                                    </Grid>
                                    <Grid item xs={6} >
                                        <div>
                                            <h4>{info.title}</h4>
                                            <span>{info.description}</span>
                                        </div>
                                    </Grid>
                                </Grid>
                            ))}
                        </div>
                        <div className="mapdetails__mapModalBtnsDiv">
                            <Button variant="outlined" color="primary" onClick={onCloseModal}>Got it!</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
