import React from 'react';
import { useDispatch } from 'react-redux';
import {
    Card, Table,
    TableBody, TableCell,
    TableHead, TableRow,
    NativeSelect, FormControl
} from '@mui/material';
import { changeUserActivityStatus } from '../../../../features/admin/userreports/userReportSlice';


const User = ({users}) => {
    const dispatch = useDispatch();

    const handleStatusChange = (e, user) => {
        const reqObj = {
            userId: `${user._id}`,
            status: !JSON.parse(user.isActive)
        }
        dispatch(changeUserActivityStatus(reqObj))
    }

    return (
        <Card>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell className="fw600">User ID</TableCell>
                        <TableCell className="fw600">User Email</TableCell>
                        <TableCell className="fw600">Times Reported</TableCell>
                        <TableCell className="fw600">Role</TableCell>
                        <TableCell className="w60 fw600">Activity Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow hover key={user._id}>
                            <TableCell>{user._id}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.mapsCount}</TableCell>
                            <TableCell>{user.role}</TableCell>
                            <TableCell className="w60">
                                <FormControl fullWidth>
                                    <NativeSelect
                                        defaultValue={user.isActive}
                                        inputProps={{
                                            name: 'astatus',
                                            id: 'uncontrolled-native',
                                        }}
                                        onChange={(e) => handleStatusChange(e, user)}
                                    >
                                        <option value={"false"}>Blocked</option>
                                        <option value={"true"}>Unblocked</option>
                                    </NativeSelect>
                                </FormControl>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}

export default User;