import { Command } from './Command';

function SetValue( editor, object, attribute, newValue, delayAutoSave) {
    Command.call(this, editor);

    this.type = 'SetValue';
    this.name = `Set ${attribute}`;
    this.updatable = true;

    this.object = object;
    this.attribute = attribute;
    this.oldValue = object !== undefined ? object[attribute] : undefined;
    this.newValue = newValue;
    this.delayAutoSave = delayAutoSave;

}

SetValue.prototype = {
    execute: function() {
        this.object[this.attribute] = this.newValue;
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave, this.oldValue])
    },

    undo: function() {
        this.object[this.attribute] = this.oldValue;
        this.editor.trigger('objectChanged', [this.object, this.delayAutoSave, this.newValue])
    },

    update: function(command) {
        this.newValue = command.newValue;
    },

    toJSON: function() {
        var output = Command.prototype.toJSON.call(this);

        output.objectUuid = this.object.uuid;
        output.oldValue = this.oldValue;
        output.attribute = this.attribute;
        output.newValue = this.newValue;
        output.delayAutoSave = this.delayAutoSave;

        return output;
    }
}

export { SetValue };