import React, { useState } from 'react';
//MUI COmponents
import { Box, Tabs, Tab } from '@mui/material';

//Components!
import AccountHome from './account/AccountHome';
import MyPlanHome from './myplan/MyPlanHome';
import TeamHome from './team/TeamHome';

const StudioHome = () => {

    const [activeKey, setActiveKey] = useState(0);

    const changeActiveKey = (e, activeKey) => {
        setActiveKey(activeKey);
    }

    const a11yProps = (index) => {
        return { id: `settings-tab-${index}`, 'aria-controls': `simple-tabpanel-${index}` };
    }

    const renderContentPane = () => {
        if (activeKey === 0) {
            return (<AccountHome />)
        } else if (activeKey === 1) {
            return (<MyPlanHome />)
        } else if (activeKey === 2) {
            return (<TeamHome />)
        }
    }

    return (
        <div className="studiohome">
            <Box padding='40px 60px'>
                <div className="studiohome__headerRow">
                    <span className="studiohome__headerText">My Account</span>
                </div>
                <Box sx={{ width: '100%' }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={activeKey} onChange={changeActiveKey} aria-label="basic tabs example">
                            <Tab label="Profile Info" {...a11yProps(0)} className={`studiohome__shTabHeader ${activeKey === 0 ? "studiohome__shTabHeader--active" : ""}`} />
                            <Tab label="My Plan" {...a11yProps(1)} className={`studiohome__shTabHeader ${activeKey === 1 ? "studiohome__shTabHeader--active" : ""}`} />
                            <Tab label="Team Members" {...a11yProps(2)} className={`studiohome__shTabHeader ${activeKey === 2 ? "studiohome__shTabHeader--active" : ""}`} />
                        </Tabs>
                    </Box>
                    <Box className="studiohome__mainRow">
                        {renderContentPane()}
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default StudioHome;