import React from "react";
// import { useDispatch } from 'react-redux';

import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
} from "@mui/material";
import { getRandomIdCode } from "../../../webstudio/threeUtils/TransformConversions";

const UserTable = ({ users }) => {
  // const dispatch = useDispatch();

  const handleImpersonation = (e, user) => {
    // console.log("IMPERSONATE", user);
    const userKey = getRandomIdCode();
    let impState = localStorage.getItem("impersonateState");
    if (impState === null) {
      //EMPTY
      impState = {};
      impState[userKey] = user;
      localStorage.setItem("impersonateState", JSON.stringify(impState));
    } else {
      impState = JSON.parse(impState);
      impState[userKey] = user;
      localStorage.setItem("impersonateState", JSON.stringify(impState));
    }
    // Now open
    window.open(`/impersonate/${userKey}`, "_blank");
  };

  return (
    <Card>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className="fw600">User ID</TableCell>
            <TableCell className="fw600">User Email</TableCell>
            <TableCell className="fw600">Name</TableCell>
            <TableCell className="w60 fw600">Activity Status</TableCell>
            <TableCell className="w60 fw600">Impersonate User</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {users.map((user) => (
            <TableRow hover key={user.id}>
              <TableCell>{user.id}</TableCell>
              <TableCell>{user.email}</TableCell>
              <TableCell>{user.name}</TableCell>
              <TableCell>
                {user.active === true ? "Active" : "Blocked"}
              </TableCell>
              <TableCell className="w60">
                <Button
                  variant="outlined"
                  onClick={(e) => handleImpersonation(e, user)}
                >
                  impersonate
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
};

export default UserTable;
