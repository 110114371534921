import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from '@mui/styles';

//SVG
import MenuHeaderIcon from '../../../assets/icons/sidebar/menu_header.svg';
import {
    Users
} from 'react-feather';

import {
    Box,
    Drawer,
    List,
    ListItem,
    Button
} from '@mui/material';

import {
    ACCOUNT_SETUP
} from '../../_utils/routes'

import items from './menu';
import { retainNavState } from '../../../features/common/commonSlice';

const useStyles = makeStyles((theme) => ({
    desktopDrawer: {
        top: '64px !important',
        height: 'calc(100vh - 64px) !important',
        backgroundColor:`${theme.palette.darker.darkerBlue01} !important`,
        position: 'relative !important',
        display: 'flex !important',
        justifyContent: 'space-between !important',
        paddingBottom: '30px'
    },
    icon: {
        marginRight: theme.spacing(1)
    },
    iconPadded: {
        margin: '4px 6px'
    },
    title: {
        marginRight: 'auto',
        fontWeight: '400'
    },
    header: {
        marginRight: 'auto',
        fontSize: '1rem !important',
        fontWeight: '400'
    },
    headerIcon: {
        width: '30px',
        height: '30px',
        transform: 'rotate(180deg) !important',
    },
    headerIconActive: {
        transform: 'rotate(0deg) !important',
    },
    button: {
        display: 'flex',
        flexDirection: 'row',
        color: `${theme.palette.neutralColors.lighter} !important`,
        justifyContent: 'flex-start',
        letterSpacing: 0,
        padding: '8px !important',
        textTransform: 'capitalize !important',
        width: '100%',
        minWidth: 'max-content !important',
        textDecoration: 'none !important',
        '&:hover': {
            backgroundColor:`${theme.palette.darker.darkerBlue04} !important`,
        }
    },
    active: {
        color: `${theme.palette.neutralColors.white} !important`,
        backgroundColor:`${theme.palette.darker.darkerBlue04} !important`
    },
}));



const Sidebar = ({onMobileClose, openMobile}) => {
    const classes = useStyles();
    const location = useLocation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [miniSidebar, setMiniSidebar] = useState(false);

    const loggedUserType = useSelector((state) => state.auth.loggedUserType) || 'user';
    const loggedUserName = useSelector((state) => state.auth.loggedUserName);
    const activeNavIndex = useSelector((state) => state.common.activeNavIndex);
    const userAccessGranted = useSelector((state) => state.access.accessGranted);
    const profileAuthrized = useSelector((state) => state.access.profileAuthrized);

    const isImpersonateUser = JSON.parse(sessionStorage.getItem("impersonateUser"));

    const onButtonClick = (currIndx) => {
        setSelectedIndex(currIndx);
        dispatch(retainNavState(currIndx));
    }

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        if(!loggedUserName && userAccessGranted === 'NEW_USER' && profileAuthrized !== 'success') {
            navigate(ACCOUNT_SETUP);
        }
        if(activeNavIndex !== null) {
            setSelectedIndex(activeNavIndex)
            if(location.pathname !== items[loggedUserType][activeNavIndex]?.link 
                && !location.pathname.includes('reported-maps') 
                && !location.pathname.includes('analytics-users') 
                && !location.pathname.includes('studio')
                && !location.pathname.includes('manage-accounts')
                && !location.pathname.includes('settings')) {
                let activeInx = activeNavIndex;
                const findIndxArr = items[loggedUserType];
                findIndxArr.forEach((ele, index) => {
                    if(location.pathname === ele.link) activeInx = index;
                });
                setSelectedIndex(activeInx);
                navigate(items[loggedUserType][activeInx].link);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname, activeNavIndex, navigate, loggedUserName]);

    useEffect(() => {
        let activeInx = activeNavIndex;
        const findIndxArr = items[loggedUserType];
        findIndxArr.forEach((ele, index) => {
            if(location.pathname === ele.link) activeInx = index;
        });
        setSelectedIndex(activeInx);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, activeNavIndex]);

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box padding='14px'>
                <List>
                    <ListItem key="menu" disableGutters
                        sx={{
                            justifyContent: miniSidebar ? 'flex-start' : 'center',
                            width: miniSidebar ? 'max-content' : '200px'
                        }} >
                        <Button className={classes.button} onClick={() => setMiniSidebar(!miniSidebar)}>
                            {!miniSidebar && <span className={classes.header}>Menu</span>}
                            <img src={MenuHeaderIcon} alt="MenuHeaderIcon" className={`${classes.headerIcon} ${miniSidebar ? classes.headerIconActive : ''}`} />
                        </Button>
                    </ListItem>
                    {items[loggedUserType].map((item, index) => (
                        (!('restrictAccess' in item && isImpersonateUser) && <ListItem
                            key={item.title}
                            disableGutters
                            sx={{
                                justifyContent: miniSidebar ? 'flex-start' : 'center',
                                width: miniSidebar ? 'max-content' : '200px'
                            }}
                            >
                            <Button
                                className={selectedIndex !== index ? classes.button : `${classes.button} ${classes.active}`}
                                component={RouterLink}
                                to={item.link}
                                onClick={() => onButtonClick(index)}
                                id={`link-${item.title.replace(/\s+/g, '').toLowerCase()}`}
                            >
                                {item.icon && <item.icon className={!miniSidebar ? classes.icon : classes.iconPadded} size="20" />}
                                {!miniSidebar && <span className={classes.title}>{item.title}</span>}
                            </Button>
                        </ListItem>)
                    ))}
                </List>
            </Box>
        </Box>
    )
    return (
        <Drawer
            anchor="left"
            classes={{ paper: classes.desktopDrawer }}
            open
            variant="persistent"
            sx={{
                width:'max-content !important'
            }}
        >
            {content}
            <a 
                className='communityButton' 
                target="_blank" rel="noopener noreferrer" 
                href="https://www.arway.ai/creator-portal"
                id='link-community'
            >
                <Users
                    size= {20}
                    color='#ffffff'
                    className="communityButton--idIcon"
                    style={{margin: miniSidebar ? '4px' : '0px',}}
                />
                {miniSidebar ? "" : "Community" }
            </a>
        </Drawer>
    )

}

Sidebar.propTypes = {
    items: PropTypes.array,
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
}

Sidebar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false
};

export default Sidebar;
