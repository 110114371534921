import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
    Container, Grid, Button
} from '@mui/material';
import {
    ChevronLeft
} from 'react-feather';
import {
    convertToShortDate
} from '../../../_utils/dateFormatter';
import { resetReportedMaps } from '../../../../features/admin/mapreports/mapReportSlice';

const MapDetailsPage = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const mapDetails = location.state;
    /* 
    link: "https://d2nayzusap6sa3.cloudfront.net/jobs/4db6cfb0/packaging/mesh.glb"
    link: "https://arwayfe.blob.core.windows.net/arway/61b06374c67ce152fc7cee44%2F61b8685d1926ad596960e810%2Fimages%2Fdf143481-8638-4711-83fb-ba9661527e62-pickedMedia1.jpg"
    */

    useEffect(()=> {
        dispatch(resetReportedMaps());
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [])

    const getContentTypeFromLink = (url) => {
        const fileExt = url.match(/\.([^./?]+)($|\?)/) ? url.match(/\.([^./?]+)($|\?)/)[1] : url;
        if(fileExt === 'glb') return 'object';
        else if(fileExt.match(/(jpg|jpeg|png|gif|svg)$/i)) return 'image';
        else if(fileExt.match(/(mp3|mpeg|wav)$/i)) return 'audio';
        else return 'text';
    }

    const renderContent = (label, data) => (
        <div className="mapdetails__dataRow">
            <span className="mapdetails__dataLabel">{label}</span>
            <span className="mapdetails__dataText">{String(data).length > 0 ? data : "N/A"}</span>
        </div>
    );

    const renderGridItem = (xsData, label, data) => (
        <Grid item xs={xsData} className="mapdetails__separator">
            {renderContent(label, data)}
        </Grid>
    )

    const renderAudioPlayer = (xsData, label, data, cType) => (
        <Grid item xs={xsData} className="mapdetails__separator">
            <div className="mapdetails__dataRow">
                <span className="mapdetails__dataLabel">{label}</span>
                <span className="mapdetails__dataText mapdetails__dataText--center">
                    <audio controls >
                        <source 
                            src={data}
                            type={cType}
                        />
                        Your browser does not support the audio element.
                    </audio>
                </span>
            </div>
        </Grid>
    );

    const renderDownloadObject = (xsData, label, data) => (
        <Grid item xs={xsData} className="mapdetails__separator">
            <div className="mapdetails__dataRow">
                <span className="mapdetails__dataLabel">{label}</span>
                <span className="mapdetails__dataText">
                    <a className="mapdetails__dataTextAchor" href={data} download>Download: {data.substring(data.lastIndexOf('/')+1)}</a>
                </span>
            </div>
        </Grid>
    );

    const renderImagePane = (xsData, label, data) => (
        <Grid item xs={xsData} className="mapdetails__separator">
            <div className="mapdetails__dataRow">
                <span className="mapdetails__dataLabel">{label}</span>
                <span className="mapdetails__dataText mapdetails__dataText--center">
                    <img src={data} alt={"image-"+data} className="mapdetails__contentImage" draggable={false}/>
                </span>
            </div>
        </Grid>
    );

    return(
        <Container className="mapdetails">
            <Grid container={true} >
                <Grid item xs={12} className="mapdetails__separator mapdetails__mapTopRow">
                    <Button variant="outlined" onClick={() => navigate(-1)}>
                        <ChevronLeft size={20} color={'#212738'} className="mapdetails__mapIcon" />
                        Back
                    </Button> 
                </Grid>
                {renderGridItem(12, "Email", mapDetails.email)}
                {/* {renderGridItem(6, "Times Reported", mapDetails.reportsCount)} */}
                {renderGridItem(3, "Map Name", mapDetails.mapName)}
                {renderGridItem(3, "Map Code", mapDetails.mapCode)}
                {renderGridItem(3, "Created On", convertToShortDate(mapDetails.mapCreatedOn))}
                {renderGridItem(3, "Modified On", convertToShortDate(mapDetails.mapModifiedOn))}
                {renderGridItem(12, "Map Description", mapDetails.mapDescription, 60)}
                {renderGridItem(6, "Report Type", mapDetails.reportType)}
                {renderGridItem(6, "Report Description", mapDetails.reportDescription)}
                {mapDetails.reportContentId && renderGridItem(6, "Reported Content Type", mapDetails.reportContentId)}
                {mapDetails.reportContentLink && getContentTypeFromLink(mapDetails.reportContentLink) === 'text' && renderGridItem(6, "Reported Text", mapDetails.reportContentLink)}
                {mapDetails.reportContentLink && getContentTypeFromLink(mapDetails.reportContentLink) === 'object' && renderDownloadObject(6, "Reported 3D Object", mapDetails.reportContentLink )}
                {mapDetails.reportContentLink && getContentTypeFromLink(mapDetails.reportContentLink) === 'audio' && renderAudioPlayer(6, "Reported Audio Clip", mapDetails.reportContentLink, 'audio/mpeg')}
                {mapDetails.reportContentLink && getContentTypeFromLink(mapDetails.reportContentLink) === 'image' && renderImagePane(12, "Reported Image", mapDetails.reportContentLink)}
            </Grid>
        </Container>
    )
};

export default MapDetailsPage;