import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Backdrop, Box, 
    Modal, Fade, 
    Grid, Button
} from '@mui/material';

import {
    XCircle, Check
} from 'react-feather';
import { 
    clearLog, updateUserPlan 
} from '../../../../../../../features/admin/analytics/adminAnalyticsSlice';

let style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px',
    overflow: 'hidden'
};

export default function ManagePlans({
    openModal,
    onCloseModal,
    plansList,
    currentPlan,
    accountData
}) {

    const dispatch = useDispatch();

    const [ selectedPlan, setSelectedPlan ] = useState(currentPlan?.planId);
    const [ priceFilter, setPriceFilter ] = useState('month');
    const [ step, setStep ] = useState(1);

    const changeLog = useSelector(state => state.adminanalytics.analyticsLog);

    const handleClose = () => {
        setStep(1);
        setPriceFilter('month');
        onCloseModal();
    }

    useEffect(() => {
        if(changeLog === 'success') {
            dispatch(clearLog())
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeLog])

    const updatePlan = () => {
        if(selectedPlan !== currentPlan.planId) {
            dispatch(updateUserPlan({
                accountId: accountData.id,
                planId: selectedPlan
            }))
        };
    }

    const renderPlanFeature = (data) => {
        return (
            <div className="mphome__planCard--apFeature">
                <Check color="#2A4DC2" size="16"/>
                <span className="mphome__planCard--apFeature--data">{data}</span>
            </div>
        )
    }

    const renderPlans = () => {
        if(!plansList) return <h4>No Plans Avaiable!</h4>

        return plansList.map(plan => {
            return (
                <div  key={plan.id} className={`mphome__planCard ${selectedPlan === plan.id && 'mphome__planCard--active'}`}>
                    <div className={`mphome__planCard--header ${plan.id === currentPlan.planId && "mphome__planCard--header--current"}`}>
                        <span>{plan.name}</span>
                    </div>
                    <div className='mphome__planCard--body'>
                        <div className='mphome__planCard--priceHeader'>
                            <span className='mphome__planCard--priceHeader--price'>&#36; {priceFilter === 'month' ? plan.price : plan.annualPrice}</span>
                            <span className='mphome__planCard--priceHeader--tag'>/{priceFilter === 'month' ? "month" : "year"}</span>
                        </div>
                    </div>
                    <div className="mphome__planCard--apFeatureCont">
                        {renderPlanFeature(`${plan.storage || 0} MB Storage`)}
                        {renderPlanFeature(`${plan.apiCalls || 0} API Calls`)}
                        {renderPlanFeature(`${plan.mapsLimit || 0} Maps`)}
                        {renderPlanFeature(`${plan.duration || 0} Days`)}
                        {renderPlanFeature(`${plan.mapArea.enable ? plan.mapArea.size + "SqFt Map Area" : "Unlimited Map Area"}`)}
                        {renderPlanFeature(`${plan.activeMapDays.enable ? plan.activeMapDays.count : "Unlimited"} Active Maps Days`)}
                        {renderPlanFeature(`${plan.publishedMap.enable ? plan.publishedMap.count || 0 : "Unlimited"} Published Maps`)}
                    </div>
                    <div className="mphome__planCard--selectBtn">
                        <button className={`mphome__planCard--selectBtn--btn`} onClick={() => setSelectedPlan(plan.id)}>Select Plan</button>
                    </div>
                </div>
            )
        })
    }

    const renderCurrPlanFeature = (label, value) => {
        return (
            <div className="accountplanhome__apFeature">
                <Check color="#2A4DC2" size="18"/>
                <span className="accountplanhome__apFeature--label">{label}</span>
                <span className="accountplanhome__apFeature--data">{value}</span>
            </div>
        )
    }

    const renderCurrentPlanDetails = (selectedPlan) => {
        const cPlan = plansList.find(p => p.id === selectedPlan);

        if(!cPlan) return null;

        return(
            <div className="accountplanhome__apDetailsCard">
                <div className="accountplanhome__apDetailsCard--header">{cPlan.name}</div>
                <div className="accountplanhome__apDetailsCard--pInfoCont">
                    <div className="accountplanhome__apDetailsCard--pHeader">
                        <span className="accountplanhome__apDetailsCard--pHeader--text">Plan Features</span>
                        <span className="accountplanhome__apDetailsCard--pHeader--price">&#36; {cPlan.price}</span>
                    </div>
                    <Grid container={true} className='accountplanhome__apFeatureGrid'>
                        <Grid item xs={6}>{renderCurrPlanFeature("Storage:", `${cPlan.storage || 0} MB`)}</Grid>
                        <Grid item xs={6}>{renderCurrPlanFeature("API Calls:", `${cPlan.apiCalls || 0}`)}</Grid>
                    </Grid>
                    <Grid container={true} className='accountplanhome__apFeatureGrid'>
                        <Grid item xs={6}>{renderCurrPlanFeature("Maps Limit:", `${cPlan.mapsLimit || 0}`)}</Grid>
                        <Grid item xs={6}>{renderCurrPlanFeature("Plan Duration:", `${cPlan.duration || 0} Days`)}</Grid>
                    </Grid>
                    <Grid container={true} className='accountplanhome__apFeatureGrid'>
                        <Grid item xs={6}>{renderCurrPlanFeature("Recurring Plan:", `${cPlan.isRecurring ? "Yes" : "No"}`)}</Grid>
                        <Grid item xs={6}>{renderCurrPlanFeature("Map Area Limit:", `${cPlan.mapArea.enable ? cPlan.mapArea.size + " SqFt" : "No Limits"}`)}</Grid>
                    </Grid>
                    <Grid container={true} className='accountplanhome__apFeatureGrid'>
                        <Grid item xs={6}>{renderCurrPlanFeature("Active Map Days:", `${cPlan.activeMapDays.enable ? cPlan.activeMapDays.count + " Days" : "No Limit"}`)}</Grid>
                        <Grid item xs={6}>{renderCurrPlanFeature("Published Map Limit:", `${cPlan.publishedMap.enable ? cPlan.publishedMap.count + " Maps" : "No Limit"}`)}</Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style={...style, height: step === 1 ? 700 : 500}}>
                    <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={handleClose}/>
                        <React.Fragment>
                            {step === 1 ?
                                <div className='mphome'>
                                    <div className='pmCreateModal__header'>Manage Plan</div>
                                    <div>
                                        <div className='mphome__plansContainer'>
                                            {renderPlans()}
                                        </div>
                                    </div>
                                    <div className="pmCreateModal__btnsDiv">
                                        <Button variant='outlined' color='primary' onClick={handleClose} id="button-calcel">Cancel</Button>
                                        <Button variant='contained' color='accentBlue' disabled={currentPlan.planId === selectedPlan} onClick={() => setStep(2)} className='mapdetails__mapModalBtnText' >Change Plan</Button>
                                    </div>
                                </div>:
                                <div className='mphome'>
                                    <div className='pmCreateModal__header'>Change Plan</div>
                                    <div className='mphome--cplanCont'>
                                        {renderCurrentPlanDetails(selectedPlan)}
                                    </div>
                                    <div className="pmCreateModal__btnsDiv">
                                        <Button variant='outlined' color='primary' onClick={() => setStep(1)} id="button-calcel">Previous</Button>
                                        <Button variant='contained' color='accentBlue' onClick={updatePlan} className='mapdetails__mapModalBtnText' >Update Plan</Button>
                                    </div>
                                </div>
                            }
                        </React.Fragment>
                    </Box>
                </Fade>
            </Modal>
        </div>
    )
}
