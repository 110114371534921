import React from 'react';
import {
    Backdrop, Box, 
    Modal, Fade, 
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

//Images
import CIM11Icon from '../../../../assets/pngs/studio/ConnectorPins/EleOnboarding.png';
import { setOnboardingFlag } from '../../2d-editor/threeUtils/Connectors/ConnectorUtils';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

export default function ElevatorOnboardingModal({openModal, onCloseModal}) {

    const handleClose = (isCancel = false) => {
        setOnboardingFlag('elevatorOnboarding', true);
        onCloseModal(isCancel);
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={() => handleClose(true)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={() => handleClose(true)}/>
                        <div className='obModal'>
                            <div className='obModal--obCard'>
                                <h4 className='obModal--obCard--title'>How to add and group elevator pins</h4>
                                <span className='obModal--obCard--header'>To add elevator connector pins to your floor plan map follow these simple steps:</span>
                                <div className='obModal--obCard--infoCont'>
                                    <img src={CIM11Icon} alt={CIM11Icon} draggable="false" />
                                    <div className='obModal--obCard--infoCont--olDiv'>
                                        <ol>
                                            <li>Position the first elevator pin on a path in front of the elevator shaft. </li>
                                            <li>Fill out the elevator pin details.</li>
                                            <li>Create and name a group for the elevator pins in the same shaft.</li>
                                            <li>Place additional elevator pins on each level connected to the same shaft and add them to the connector group.</li>
                                            <li>Repeat these steps for other elevator shafts.</li>
                                        </ol>
                                        <span className='obModal--obCard--infoCont--infoSpan'>By following these instructions, you can accurately represent the elevators on your floor plan map, allowing users to easily locate and navigate them.</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className='obModal--btn' onClick={() => handleClose(false)}>Place Elevator Pins</button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}