import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid } from "@mui/material";
import { CSVLink } from "react-csv";
import { Download } from "react-feather";
import { isEmpty, isNull, isUndefined } from "lodash";

import { getUserAccountVisitorBehavior, userAccountVisitorBehaviorCSV } from "../../../../../features/admin/analytics/adminAnalyticsSlice"
import RenderTile from "../../../users/analytics/Render/RenderTile";
import RenderTable from "../../../users/analytics/Render/RenderTable";
import RenderPagination from "../../../users/analytics/Render/RenderPagination";
import usePagination from "../../../users/analytics/Render/usePagination";

import { getFileNameWithTS, secondsToHms } from '../../../../_utils/DateUtils';

import UniqSvg from "../../../../../assets/svgs/analytics/a_uniq.svg";
import EyeSvg from "../../../../../assets/svgs/analytics/a_eye_blue.svg";
import AvgDuration from "../../../../../assets/svgs/analytics/a_duration.svg";
import Loader from "../../../../common/loader";
import { MapAreaUnit } from "../overview/components/MapAreaUnit";

const topVisitedMapsHeaders = [
    { key: "mapName", label: "Map Name" },
    { key: "publishedOn", label: "Publish Date" },
    { key: "mapArea", label: "Map Area" },
    { key: "mapVisits", label: "Visit Count" },
    { key: "avgVisitTime", label: "Average Time Visited" },
    { key: "totalVisitTime", label: "Total Time Visited" },
];

const TopVisitedMapsCard = () => {
    const dispatch = useDispatch();

    const selectedAccount = useSelector((state) => state.adminanalytics.activeAccount);
    const topVisitedMapsCard = useSelector(state => state.adminanalytics.userAccountVisitorBehavior);
    const topVisitedMapsMetaCard = useSelector(state => state.adminanalytics.userAccountVisitorBehaviorMeta);

    const limit = 10;
    const [page, setPage] = useState(1);
    const [totalResults, setTotalResults] = useState(0);
    const [loadOverview, setLoadOverview] = useState(true);
    const [loadList, setLoadList] = useState(true);
    const [areaSQFt, setAreaSQFt] = useState(true);

    const count = Math.ceil(topVisitedMapsCard?.totalResults / limit);
    const _DATA = usePagination(
        topVisitedMapsCard?.data ? topVisitedMapsCard.data : [],
        limit
    );
    const topVisitedMapsCSVData = useSelector(userAccountVisitorBehaviorCSV)

    useEffect(() => {
        dispatch(getUserAccountVisitorBehavior({ page, limit, accountId: selectedAccount?.id }));
        dispatch(getUserAccountVisitorBehavior({ page: 1, limit: 1, accountId: selectedAccount?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(selectedAccount && selectedAccount.id !== 'allAccounts') {
            setLoadList(true);
            setLoadOverview(true);
            dispatch(getUserAccountVisitorBehavior({ page, limit, accountId: selectedAccount?.id }));
            dispatch(getUserAccountVisitorBehavior({ page: 1, limit: 1, accountId: selectedAccount?.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);

    useEffect(() => {
        setLoadList(true);
        dispatch(getUserAccountVisitorBehavior({ page, limit, accountId: selectedAccount?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    useEffect(() => {
        if (totalResults !== topVisitedMapsCard?.totalResults) {
            setTotalResults(topVisitedMapsCard?.totalResults)
        }
        setLoadList(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topVisitedMapsCard])

    useEffect(() => {
        if (topVisitedMapsMetaCard !== undefined && loadOverview) {
            setLoadOverview(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [topVisitedMapsMetaCard])

    useEffect(() => {
        if (totalResults > 0)
            dispatch(getUserAccountVisitorBehavior({ page: 1, limit: totalResults, isCSV: true, accountId: selectedAccount?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [totalResults])

    const handleChange = (e, p) => {
        setPage(p);
        _DATA.jump(p);
    };

    const getRowData = (data) => {
        return data?.map(d => {
            return{
                mapName: d.mapName,
                publishedOn: d.publishedOn,
                mapArea: d.mapArea ? areaSQFt ? (d.mapArea) : (d.mapArea / 10.764).toFixed(2) : '--',
                mapVisits: d.mapVisits,
                avgVisitTime: d.avgVisitTime,
                totalVisitTime: d.totalVisitTime,
            }
        })
    }

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                {
                    (!topVisitedMapsMetaCard && !loadOverview) ? (
                        <h5 className="text-center analyticshome__emptyStateText">
                            {" "}
                            No Data available{" "}
                        </h5>
                    ) : loadOverview ? (
                        <h5 className="text-center analyticshome__emptyStateText">
                            <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                        </h5>
                    ) : (
                        <Grid container={true} spacing={4} style={{ marginBottom: "2rem" }}>
                            <RenderTile
                                tileSize={4}
                                color="#008080"
                                miniCardHeader={
                                    isEmpty(topVisitedMapsMetaCard)
                                        ? "--"
                                        : topVisitedMapsMetaCard?.mapName?.charAt(0).toUpperCase() +
                                        topVisitedMapsMetaCard?.mapName?.slice(1)
                                }
                                miniCardDesc="Top visited map to date"
                                svg={UniqSvg}
                            />

                            <RenderTile
                                tileSize={4}
                                color="#017EB4"
                                miniCardHeader={
                                    isEmpty(topVisitedMapsMetaCard)
                                        ? "--"
                                        : topVisitedMapsMetaCard?.visits
                                }
                                miniCardDesc="Most map visits of a Map"
                                svg={EyeSvg}
                            />

                            <RenderTile
                                tileSize={4}
                                color="#5600AC"
                                miniCardHeader={
                                    isEmpty(topVisitedMapsMetaCard)
                                        ? "--"
                                        : topVisitedMapsMetaCard?.average
                                            ? secondsToHms(topVisitedMapsMetaCard?.average)
                                            : "--"
                                }
                                miniCardDesc="Average map visit duration"
                                svg={AvgDuration}
                            />
                        </Grid>
                    )}
                <div className="row analyticshome__anCard--contentRow d-flex align-items-center">
                    <div className="col">
                        <div className="analyticshome__anCard--contentRow--header">
                            Top Visited Maps
                        </div>
                    </div>
                    <div className="col">
                        <div
                            className="analyticshome__floatright"
                            style={{ paddingRight: "35px" }}
                        >
                            {isEmpty(topVisitedMapsCSVData?.data) ? null : (
                                <CSVLink
                                    headers={topVisitedMapsHeaders.map((headerObj, index) => ({
                                        label: headerObj.label,
                                        key: Object.keys(topVisitedMapsCSVData.data[0])[index],
                                    }))}
                                    data={topVisitedMapsCSVData.data}
                                    filename={getFileNameWithTS("TopVisitedMaps")}
                                    style={{ color: "#2A4DC2" }}
                                >
                                    <Download /> Download CSV
                                </CSVLink>
                            )}
                        </div>
                    </div>
                </div>
                <hr />
                {((isEmpty(topVisitedMapsCard?.data) || isNull(_DATA.currentData()) || isUndefined(_DATA.currentData())) && !loadList) ?
                    (<h5 className="text-center analyticshome__emptyStateText pt-3">
                        {" "}
                        No Data available{" "}
                    </h5>) : loadList ? (
                        <div className='analyticshome__emptyTextCont'>
                            <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                        </div>
                    ) : (
                        <React.Fragment>
                            <MapAreaUnit
                                areaSQFt={areaSQFt}
                                setAreaSQFt={setAreaSQFt}
                            />
                            <RenderTable
                                thheaderData={topVisitedMapsHeaders}
                                rowsData={getRowData(_DATA.currentData())}
                            />
                            <div className="row analyticshome__anCard--contentRow--footer">
                                <div className="col">
                                    {isEmpty(topVisitedMapsCard?.data) ? null : (
                                        <RenderPagination
                                            count={count}
                                            page={page}
                                            limit={limit}
                                            handleChange={handleChange}
                                            cb={(page) => {
                                                setPage(page);
                                                _DATA.jump(page);
                                            }}
                                            totalResults={topVisitedMapsCard?.totalResults}
                                        />
                                    )}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
            </div>
        </div>
    );
};

export default TopVisitedMapsCard;