import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Copy, Check } from "react-feather";
import { TextField, Tooltip } from "@mui/material";
import MessageModal from "../../../common/modal/Modal";
import AppSwitch from "../../../common/toggleSwitch";

import { convertToNamedDate } from "../../../_utils/dateFormatter";

//Action
import {
  deleteApp,
  updateApp,
  updateAppStatus,
} from "../../../../features/user/developer/developerSlice";
import { setNewAlert } from "../../../../features/common/alertSlice";

const AppCard = (props) => {
  const dispatch = useDispatch();

  const deleteLog = useSelector((state) => state.developer.deleteLog);
  const devLog = useSelector((state) => state.developer.devLog);

  const [deleteModal, setDeleteModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [appName, setAppName] = useState(props.appData.name);
  const [isEnabled, setIsEnabled] = useState(props.appData.enabled);

  const index = props.index;
  const appDetails = props.appData;

  useEffect(() => {
    if (devLog === "success") {
      setIsEdit(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devLog]);

  useEffect(() => {
    if (deleteLog === "success") {
      setDeleteModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteLog]);

  useEffect(() => {
    if (isEnabled !== appDetails.enabled) {
      const reqObj = {
        enabled: isEnabled,
        appId: appDetails.id,
      };
      dispatch(updateAppStatus(reqObj));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnabled]);

  const handleDelete = () => {
    dispatch(deleteApp(appDetails.id));
  };

  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  const handlEnabledChange = (e) => {
    setIsEnabled(e.target.checked);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (appName.length > 0) {
      const reqObj = {
        name: appName,
        appId: appDetails.id,
      };
      dispatch(updateApp(reqObj));
    } else {
      dispatch(
        setNewAlert({
          msg: "App name cannot be empty string!",
          alertType: "danger",
        })
      );
    }
  };

  const obscureSecretKey = (key) => {
    let string = [...key];
    let newstr = string.map((letter, index) => {
      if (index > 2 && index < string.length - 3) return "*";
      return letter;
    });
    return newstr;
  };

  return (
    <React.Fragment>
      {!isEdit ? (
        <div className="developerhome__appCard">
          <div className="developerhome__acRow">
            <span className="developerhome__acRow--header">
              {appDetails?.name?.toLowerCase() === "default app"
                ? "ARWay App"
                : appDetails.name}
            </span>
            {index !== 0 && (
              <div className="developerhome__acEnableRow">
                <span className="developerhome__acEnableRow--text">
                  Enabled
                </span>
                <AppSwitch
                  enabled={isEnabled}
                  onEnableToggle={handlEnabledChange}
                  id="toggle-app"
                />
              </div>
            )}
          </div>
          {appDetails?.name?.toLowerCase() === "arway app" ? null : (
            <div className="developerhome__acRow">
              <span className="developerhome__acRow--label">Secret Key :</span>
              <span className="developerhome__acRow--data" id="span-secretKey">
                {obscureSecretKey(appDetails.key)}
              </span>
              <Tooltip
                title="Copied to Clipboard"
                placement="bottom"
                open={isTooltipOpen}
              >
                {isTooltipOpen ? (
                  <Check
                    className="developerhome__acRow--cpIcon"
                    size={16}
                    color="green"
                    id="button-copiedAppSecret"
                  />
                ) : (
                  <Copy
                    size={16}
                    color="#2A4DC2"
                    className="developerhome__acRow--cpIcon"
                    id="button-copyAppSecret"
                    onClick={() => {
                      setIsTooltipOpen(!isTooltipOpen);
                      setTimeout(() => setIsTooltipOpen(!isTooltipOpen), 1500);
                      navigator.clipboard.writeText(appDetails.key);
                    }}
                  />
                )}
              </Tooltip>
            </div>
          )}
          <div className="developerhome__acRow">
            <span className="developerhome__acRow--text">Created on </span>
            <span className="developerhome__acRow--text">
              {convertToNamedDate(appDetails.createdOn)}
            </span>
            <span className="developerhome__acRow--sept"> | </span>
            <span className="developerhome__acRow--text">Last Updated </span>
            <span className="developerhome__acRow--text">
              {convertToNamedDate(appDetails.modifiedOn)}
            </span>
          </div>
          <div className="developerhome__acRow">
            <div
              className="developerhome__acRow--btn"
              id="button-editApp"
              onClick={toggleEdit}
            >
              Edit
            </div>
            {index !== 0 && (
              <div
                className="developerhome__acRow--btn developerhome__acRow--red"
                onClick={() => setDeleteModal(true)}
                id="button-deleteApp"
              >
                Delete
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="developerhome__appCard">
          <div className="developerhome__acRow">
            <span className="developerhome__acRow--header">
              Edit {appDetails?.name}
            </span>
          </div>
          <form onSubmit={handleSubmit} className="developerhome__acForm">
            <TextField
              label="App Name"
              id="appName"
              value={appName}
              onChange={(e) => setAppName(e.target.value)}
              size="small"
              className="mapdetails__mapFormControl mapdetails__mapFormControl--active"
              autoComplete="off"
              inputProps={{ maxLength: 15 }}
            />
            <div className="developerhome__acFormBtnDiv">
              <button
                type="submit"
                className="developerhome__acFormBtnUpdate"
                id="button-updateApp"
              >
                Update Name
              </button>
              <button
                onClick={toggleEdit}
                className="developerhome__acFormBtnCancel"
                id="button-cancelApp"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}

      <MessageModal
        openModal={deleteModal}
        onCloseModal={() => setDeleteModal(false)}
        onSuccessModal={handleDelete}
        modalHeader="Delete App"
        modalText="Are you sure you want to delete your app?"
        modalSubText="This cannot be undone!"
        textSuccess="Delete"
        textCancel="Cancel"
      />
    </React.Fragment>
  );
};

export default AppCard;
