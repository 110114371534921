import * as THREE from 'three';

import { 
    fromPosObjectToVec3Pos,
    fromScaleObjectToVec3Scale,
    fromQuaternionObjectToQuaternion
} from '../../../threeUtils/TransformConversions';

class NavPoint{
    constructor(navProps) {
        this.navProps = navProps;
        this.position = navProps.position ? fromPosObjectToVec3Pos(navProps.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = navProps.rotation ? fromQuaternionObjectToQuaternion(navProps.rotation) : new THREE.Quaternion(0, 0, 0, 0);
        this.scale = navProps.scale ? fromScaleObjectToVec3Scale(navProps.scale) : new THREE.Vector3(1, 1, 1);
        this.pointArray = [-navProps.position.posX, navProps.position.posY, navProps.position.posZ];
        this.pathColor = 'pathColor' in navProps ? navProps.pathColor : '#07D91E';
    }
    // TODO: Methods to update & fetch
}

export { NavPoint };