import EventEmitter from "../../utils/EventEmitter";

// import UI Components
import {
    UIPanel,
    UIText
} from '../../ui/ui.lib';

class InfoPopup extends EventEmitter {

    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;

        this.initUI();

        this.editor.on('togglePopup', this.togglePopup);

    }

    initUI = () => {
        this.uiLoader = new UIPanel();
        this.uiLoader.addClass('wsInfoPopup');

        this.uiText = new UIText('', 'For best user experience, please use Chrome, or Firefox browser!', true).addClass('wsInfoPopup--text');
        this.uiLoader.addChild(this.uiText);

        this.uiLoader.setDisplay('none');
        this.dom = this.uiLoader;
    }

    togglePopup = (bFlag) => {
        if(bFlag) {
            this.dom.setDisplay('flex');
        } else {
            this.dom.setDisplay('none');
        }
    }
}

export { InfoPopup };