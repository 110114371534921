import EventEmitter from "../../utils/EventEmitter";
import { UIPanel, UINumber, UIRow, UIText } from "../ui.lib";

class FloatingMenuElevation extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;
        this.selectedNode = null;
        this.containerDiv = null;

        this.initUI();

        this.editor.on("nodeSelected", this.onNodeSelected);
        this.editor.on("nodeDeSelected", this.onNodeDeSelected);
        this.editor.on("objectSelected", this.onObjectSelected);
    }

    initUI = () => {
        this.containerDiv = document.getElementById("webStudioFloatingMenuBar");

        this.uiContainer = new UIPanel();
        this.uiContainer.addClass("wsTransformToolContainer");

        //position
        this.uiPositionCont = new UIPanel();
        this.uiPositionCont.addClass("wsPositionContainer");

        const uiColXZ = new UIPanel();
        uiColXZ.addClass("wsPositionContainer--uiCol");

        uiColXZ.addChild(
            new UIText(undefined, "Elevate Node", true).addClass(
                "wsTransformToolHeaderText"
            )
        );

        const uiRowXZ = new UIRow().setStyle("padding", "0px");

        this.objectPosY = new UINumber()
            .setPrecision(4)
            .setStyle("width", "100%")
            .setStyle("border-radius", "8px")
            .setId("transY")
            .setHelper("Y")
            .onChange(this.set)
            .onAutoSave(() => {
                if (this.editor.toAutosave) {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [false]);
                } else {
                    this.editor.trigger("toggleAutoSaveSceneFlag", [true]);
                }
            })

        uiRowXZ.addChild(this.objectPosY);
        uiColXZ.addChild(uiRowXZ);
        this.uiPositionCont.addChild(uiColXZ);

        this.uiContainer.addChild(this.uiPositionCont);

        this.dom = this.uiContainer.dom;
    };

    set = () => {
        if (this.selectedNode) {
            const value = this.objectPosY.getValue();
            this.selectedNode.position.y = value;

            this.editor.trigger("nodeElevated");
        }
    }

    onNodeSelected = (node) => {
        if (!node) return;

        if (this.containerDiv) this.containerDiv.classList.add("webstudio_wsFloatingMenuContainerElevate");
        this.uiContainer.setDisplay("");
        if (node.parent.type === "Group" && node.parent.userData.index >= 0) {
            this.selectedNode = node.parent;
        } else {
            this.selectedNode = node;
        }

        if (this.selectedNode) {
            this.objectPosY.setValue(this.selectedNode.position.y);
        }
    }

    onNodeDeSelected = () => {
        if (!this.selectedNode) return;

        this.selectedNode = null;
        if (this.uiContainer) this.uiContainer.setDisplay("none");
        if (this.containerDiv) this.containerDiv.classList.remove("webstudio_wsFloatingMenuContainerElevate");
    }

    onObjectSelected = () => {
        this.selectedNode = null;
        this.uiContainer.setDisplay("none");
        if (this.containerDiv) this.containerDiv.classList.remove("webstudio_wsFloatingMenuContainerElevate");
    };
}

export { FloatingMenuElevation };
