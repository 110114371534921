import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { cloneDeep } from 'lodash';
import { 
    Backdrop, Box, 
    Modal, Fade,
    TextField, Button
} from "@mui/material";

import { XCircle } from "react-feather";

import {
    updateVenueMap
} from '../../../features/webstudio/buildingSlice';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80% !important",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    borderRadius: "16px",
    overflowY: 'auto',
};

const RenameVenueMapModal = ({ openModal, onCloseModal, modalData }) => {

    const dispatch = useDispatch();

    const [ mapName, setMapName ] = useState('');
    const [ errName, setErrName ] = useState(null);
    const [ save, setSave ] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        if(mapName && mapName.trim().length) {
            setSave(true);
        } else {
            setErrName('Please enter map name!')
        }
    }

    const handleClose = (e, reason) => {
        if(reason && reason === 'backdropClick') return;
        setMapName('');
        setErrName(null);
        onCloseModal()
    }

    const handleChange = (e) => {
        setMapName(e.target.value);
        e.target.value.length ? setErrName(null) : setErrName('Please enter map name!');
    }

    useEffect(() => {
        if(save) {
            if(mapName === modalData.content.mapName) {
                handleClose();
            } else {
                let venueDetails = cloneDeep(modalData.venueDetails);
                let venueMaps = cloneDeep(venueDetails.maps);
                venueMaps = venueMaps.map( map => {
                    if(map.mapId === modalData.content.mapId) {
                        return {...map, mapName: mapName}
                    } else return map;
                });

                venueDetails.maps = venueMaps;
                dispatch(
                    updateVenueMap({venueDetails, mapId: modalData.buildingId, noAlert: true})
                ).then(() => {
                    handleClose();
                    setSave(false)
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ save ])

    useEffect(() => {
        if(openModal && modalData) {
            setMapName(modalData.content.mapName)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ modalData ])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={handleClose}
                        />
                        <div className='cgModal'>
                            <div className='cgModal--cgCard'>
                                <form onSubmit={handleSubmit}>
                                    <h4 className='cgModal--cgCard--title'>Edit Venue Map Name</h4>
                                    <div className='cgModal--cgCard--header'>Change the name for your map from your building. Visitors will see this map name.</div>
                                    <div className='cgModal--cgCard--renameCont'>
                                        <TextField 
                                            label="Map Name" 
                                            id="mapName"
                                            value={mapName}
                                            onChange={handleChange}
                                            size="small"
                                            fullWidth
                                            className={`settingshome__acDetailsCard--formControl settingshome__acDetailsCard--formControl--active`}
                                            autoComplete="off"
                                            inputProps={
                                                { maxLength: 50 }
                                            }
                                        />
                                        {errName ? <span className='settingshome__acDetailsCard--errorMsg'>{errName}</span> : null}
                                    </div>
                                    <div className="publishModalBtnsDiv">
                                        <Button variant='outlined' color='primary' onClick={handleClose}>Cancel</Button>
                                        <Button variant='contained' color='primaryColors' className='mapdetails__mapModalBtnText' type='submit'>Save</Button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default RenameVenueMapModal;
