import React, { useState, useEffect } from 'react';
import {
    Backdrop, Box, 
    Modal, Fade, 
    Button
} from '@mui/material';

import { Loader } from 'react-feather';

import {
    XCircle
} from 'react-feather';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

export default function MessageModal({openModal, onCloseModal, modalHeader, modalText, modalTextBold, modalSubText, textSuccess, textCancel, onSuccessModal, showLoading}) {
    
    const [ loading, setLoading ] = useState(false);

    useEffect(()=> {
        if(openModal) {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [openModal])

    const handleSuccess = () => {
        if(showLoading) {
            setLoading(true);
        }
        onSuccessModal();
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal} id="button-messageClose"/>
                        <div className="mapdetails__mapModalHeader">{modalHeader}</div>
                        <div className="mapdetails__mapModalText">{modalText} {modalTextBold && <span className="mapdetails__mapModalTextBold">{modalTextBold}</span>}</div>
                        {modalSubText && <div  className="mapdetails__mapModalText">{modalSubText}</div>}
                        <div className="mapdetails__mapModalBtnsDiv">
                            <Button variant='outlined' color='primary' onClick={onCloseModal} id="button-calcel" disabled={loading}>{textCancel}</Button>
                            <Button variant='contained' color={textSuccess === 'Replace'?'primary':'errorRed'} onClick={handleSuccess} className='mapdetails__mapModalBtnText' id="button-success">
                                {!loading ? textSuccess : <Loader size="24" color='#fff' className='mapdetails__mapModalLoader'/>}
                            </Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
