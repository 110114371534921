import moment from "moment";
import { unionBy, orderBy } from "lodash";
const lastTEDays = {
  fromDate: moment()
    .subtract(26, "days")
    .startOf("day")
    .toDate()
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, ""),
  toDate: moment()
    .subtract(0, "days")
    .endOf("day")
    .toDate()
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, ""),
};

const thisYear = {
  fromDate: moment()
    .subtract(0, "years")
    .startOf("year")
    .toDate()
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, ""),
  toDate: moment()
    .subtract(0, "years")
    .endOf("year")
    .toDate()
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, ""),
};

const lastYear = {
  fromDate: moment()
    .subtract(1, "years")
    .startOf("year")
    .toDate()
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, ""),
  toDate: moment()
    .subtract(1, "years")
    .endOf("year")
    .toDate()
    .toISOString()
    .slice(0, 10)
    .replace(/-/g, ""),
};

export const secondsToHms = (d) => {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor((d % 3600) / 60);
  var s = Math.floor((d % 3600) % 60);

  var hDisplay = h > 0 ? h + (h === 1 ? " hr, " : " hrs, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " min, " : " mins, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " sec" : " secs") : "";
  return hDisplay + mDisplay + sDisplay;
};

const getSeconds = (data) => {
  let seconds = 0;
  let val = data.match(/\d+/)[0];
  let res = data.includes("sec")
    ? val
    : data.includes("min")
      ? val * 60
      : data.includes("hr")
        ? val * 60 * 60
        : 0;
  seconds = seconds + Number(res);
  return seconds;
};

export const HmsToSeconds = (s) => {
  if (s !== "--") {
    let hms = s.split(",").filter((el) => el !== " ");
    if (hms.length === 1) {
      return getSeconds(hms[0]);
    }
    if (hms.length === 2) {
      return getSeconds(hms[0]) + getSeconds(hms[1]);
    }
    if (hms.length === 3) {
      return getSeconds(hms[0]) + getSeconds(hms[1]) + getSeconds(hms[2]);
    }
  }
  return 0;
};

export const getFileNameWithTS = (filename) => {
  let d = new Date();
  let dformat = `${d.getDate()}/${("0" + (d.getMonth() + 1)).slice(
    -2
  )}/${d.getFullYear()}-${d.getHours()}-${d.getMinutes()}`;
  return `${filename}-${dformat}.csv`;
};

export { lastTEDays, lastYear, thisYear };

export const getLDate = (dateString) => {
  let data = dateString.split(" ");
  let date = data[0];
  let dd = date.split("/")[0];
  let mm = date.split("/")[1];
  let yyyy = date.split("/")[2];
  return new Date(`${mm}/${dd}/${yyyy} ${data[1]} ${data[2]}`);
};

export const getMergeSortedByDate = (a1, a2, mergeKey, dateKey) => {
  const uniqueArray = unionBy(a1, a2, mergeKey);
  const sortedArray = orderBy(uniqueArray, dateKey, 'desc');

  return sortedArray;
};
