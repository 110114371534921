import EditorExperience from "../../2DEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";
import { UIPanel, UIRow, UISeparator } from "../../../ui/ui.lib";

// menu
import { Menu2DAmenity } from './2DMenu.Amenity';
import { Menu2DLocation } from './2DMenu.Location';
import { Menu2DLocationPin } from "./2DMenu.ListLocationPins";

class Menu2DPins extends EventEmitter {

    constructor(props) {
        super(props)
        this.editor = new EditorExperience();
        this.dom = null;

        this.amenityCats = this.editor.amenityTypes || [];

        this.menuOpen = true;

        this.initUI();

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('menuModeChanged', this.onToggleMenuActive);
    }

    initUI = () => {
        this.uiContainer = new UIPanel();
        this.uiContainer.setClass('wsMenu2DContainer')

        const uiCont = new UIRow();

        uiCont.dom.appendChild(new Menu2DLocationPin(this.editor).dom);
        uiCont.addChild(new UISeparator('ws_uiSeparatorCenter'));

        uiCont.dom.appendChild(new Menu2DLocation(this.editor).dom);

        if(this.amenityCats.length) {
            this.amenityCats.forEach(cat => {
                cat.type === 'amenity' && uiCont.dom.appendChild(new Menu2DAmenity(this.editor, cat).dom);
            })
        }

        this.uiContainer.addChild(uiCont);
        this.uiContainer.setDisplay('none');

        this.dom = this.uiContainer.dom;
    }

    onToggleMenuActive = () => {
        if(this.editor.menuConnectorsMode === 'PINS') {
            this.menuOpen = true;
            this.uiContainer.setDisplay('');
        } else {
            this.menuOpen = false;
            this.uiContainer.setDisplay('none');
        }
    }
}

export { Menu2DPins };