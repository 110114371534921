import { jsPDF } from "jspdf";

export const PDFDownloader = (dataUrl, filename, cWidth, cHeight, isDownload=true) => {
  const doc = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: [8.5, 11],
  });
  const pageWidth = doc.internal.pageSize.getWidth();
  const pageHeight = doc.internal.pageSize.getHeight();
  const widthRatio = pageWidth / cWidth;
  const heightRatio = pageHeight / cHeight;
  const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
  const canvasWidth = cWidth * ratio;
  const canvasHeight = cHeight * ratio;
  const marginX = (pageWidth - canvasWidth) / 2;
  const marginY = (pageHeight - canvasHeight) / 2;
  doc.addImage(dataUrl, "svg", marginX, marginY, canvasWidth, canvasHeight, 'ARWay_QR', 'MEDIUM');
  if(isDownload){
    doc.save(filename);
  }
  return doc.output("blob")
};
