import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../api";
import { HandleExceptionWithSecuredCatch } from "../CombineCatch";
import { setNewAlert } from "../common/alertSlice";


const getMapMetadata = createAsyncThunk(
  "webstudio/getMapMetadata",
  async (mapCode, { dispatch }) => {
    try {
      // const response = await Server.get(`/v1/mapdata/${mapCode}`);
      const response = await Server.get(`/v1/mapdata/draft/${mapCode}`);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        return dispatch(
          setNewAlert({
            msg: "Open Map API calls Limit Exceeded",
            alertType: "information-stay",
            toHold: true,
            toClose: true,
          })
        );
      } else {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  }
);

const fetchLibraryContents = createAsyncThunk(
  "webstudio/fetchLibraryContents",
  async ({ pageNumber, contentType }, { dispatch }) => {
    try {
      const response = await Server.get(
        `/v1/asset?limit=10&page=${pageNumber}&contentType=${contentType}`
      );
      return response.data;
    } catch (e) {
      return { data: [] };
    }
  }
);

const getMapVersionHistory = createAsyncThunk(
  "webstudio/getMapVersionHistory",
  async (mapId, { dispatch }) => {
    try {
      const response = await Server.get(`/v1/map/${mapId}/draft`);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const loadMapVersion = createAsyncThunk(
  "webstudio/loadMapVersion",
  async ({ mapId, reqObj }, { dispatch }) => {
    try {
      const response = await Server.post(`/v1/map/${mapId}/draft`, reqObj);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        return dispatch(
          setNewAlert({
            msg: "Open Map API calls Limit Exceeded",
            alertType: "information-stay",
            toHold: true,
            toClose: true,
          })
        );
      } else {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  }
);

const getHeatmapDraftMapData = createAsyncThunk(
  "webstudio/getHeatmapDraftMapData",
  async (mapId, { dispatch }) => {
    try {
      // const response = await Server.get(`/v2/mapdata/${mapId}`);
      const response = await Server.get(`/v1/map/${mapId}/mapDetails`);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        return dispatch(
          setNewAlert({
            msg: "Open Map API calls Limit Exceeded",
            alertType: "information-stay",
            toHold: true,
            toClose: true,
          })
        );
      } else {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  }
);

// floorplan Actons create, edit, delete
const updateFloorplan = createAsyncThunk('webstudo/updateFloorplan', async ({ floorObj, mapId }, { dispatch }) => {
  try {
    const response = await Server.put(`/v1/floorplan/${mapId}/false`, floorObj);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const deleteFloorplan = createAsyncThunk('webstudo/deleteFloorplan', async ({ floorId, mapId }, { dispatch }) => {
  try {
    const response = await Server.delete(`/v1/floorplan/${mapId}/${floorId}`);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

// Anchors Actions edit, delete
const updateAnchor = createAsyncThunk('webstudo/updateAnchor', async ({ anchorObj, mapId }, { dispatch }) => {
  try {
    const response = await Server.put(`v1/map/anchor/${mapId}`, anchorObj);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

const deleteAnchor = createAsyncThunk('webstudo/deleteAnchor', async ({ anchorId, mapId }, { dispatch }) => {
  try {
    const response = await Server.delete(`/v1/map/anchor/${mapId}`, { data: { id: anchorId } });
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

// map area
const updateMapArea = createAsyncThunk('webstudo/updateMapArea', async ({ areaObj, mapId }, { dispatch }) => {
  try {
    const response = await Server.put(`/v1/map/area/${mapId}`, areaObj);
    return response.data;
  } catch (e) { return dispatch(HandleExceptionWithSecuredCatch(e)); }
})

// get all metadata in draft
const getDraftMetadata = createAsyncThunk(
  "webstudio/getDraftMetadata",
  async (mapId, { dispatch }) => {
    try {
      const response = await Server.get(`/v1/map/${mapId}/mapDetails`);
      return response.data;
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data) {
        return dispatch(
          setNewAlert({
            msg: "Open Map API calls Limit Exceeded",
            alertType: "information-stay",
            toHold: true,
            toClose: true,
          })
        );
      } else {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  }
);

const initialState = {
  editor: {},
  mapDetails: null,
  mapDetailsPublished: null,
  mapVersions: null,
  libraryContents: null,
  webStudioLog: null,
  floorContent: null,
  updateFloorLog: null,
  deleteFloorLog: null,
  anchorContent: null,
  updateAnchorLog: null,
  deleteAnchorLog: null,
  recentLog: false
};

const webStudioSlice = createSlice({
  name: "webstudio",
  initialState,
  reducers: {
    resetStudioStore: () => {
      return { ...initialState};
    },
    updateMapDetails: (state, { payload }) => {
      return { 
        ...state,
        mapDetails: payload
      };
    },
    resetRecentLog: (state) => {
      return { 
        ...state,
        recentLog: false
      };
    },
    resetFloorStates: (state) => {
      return {
        ...state,
        floorContent: null,
        updateFloorLog: null,
        deleteFloorLog: null
      };
    },
    resetAnchorStates: (state) => {
      return {
        ...state,
        anchorContent: null,
        updateAnchorLog: null,
        deleteAnchorLog: null
      };
    }
  },
  extraReducers: {
    "common/resetState": () => {
      return { ...initialState };
    },
    [getMapMetadata.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapDetails: payload.data,
        recentLog: true,
        webStudioLog: null,
      };
    },
    [getDraftMetadata.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapDetails: payload.data,
        recentLog: true,
        webStudioLog: null,
      };
    },
    [getHeatmapDraftMapData.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapDetailsPublished: payload.data,
        webStudioLog: null,
      };
    },
    [getMapVersionHistory.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapVersions: payload,
        webStudioLog: null,
      };
    },
    [loadMapVersion.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapDetails: payload.data,
        recentLog: true,
        webStudioLog: null,
      };
    },
    [fetchLibraryContents.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        libraryContents: payload.data,
        webStudioLog: null,
      };
    },
    [updateFloorplan.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        floorContent: payload.data,
        updateFloorLog: payload.status,
        deleteFloorLog: null,
      };
    },
    [deleteFloorplan.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updateFloorLog: null,
        floorContent: payload,
        deleteFloorLog: payload.status,
      };
    },
    [updateAnchor.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        anchorContent: payload.data,
        updateAnchorLog: payload.status,
        deleteAnchorLog: null,
      };
    },
    [deleteAnchor.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        updateAnchorLog: null,
        deleteAnchorLog: payload.status,
      };
    },
    [updateMapArea.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        webStudioLog: payload.status,
      };
    },
  },
});

export {
  getMapMetadata,
  fetchLibraryContents,
  getMapVersionHistory,
  loadMapVersion,
  getHeatmapDraftMapData,
  updateFloorplan,
  deleteFloorplan,
  updateAnchor,
  deleteAnchor,
  updateMapArea,
  getDraftMetadata
};

export const {
  resetStudioStore, resetFloorStates, resetAnchorStates, updateMapDetails, resetRecentLog
} = webStudioSlice.actions;

export default webStudioSlice.reducer;
