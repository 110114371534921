// Creates waypoinbt destination and a line to draw between desired points!
import { RemoveObject } from '../../commands/RemoveObject';
import EditorExperience from '../../EditorExperience';
import EventEmitter from '../../utils/EventEmitter';
import { getRandomIdCode } from '../TransformConversions';
import NavLine from './NavLine';
import { NavPoint } from './NavPoint';
import LocationPin from './LocationPin';


class Navigation extends EventEmitter {
    constructor(wayProps, withWaypointsAndDest, withDestination, withWaypoints) {
        super();
        this.editor = new EditorExperience();
        this.wayProps = wayProps;
        this.name = wayProps instanceof Array ? "" : wayProps.name ? wayProps.name.substring(0, 20) : "Sample Waypoint";
        this.description = wayProps instanceof Array ? "" : wayProps.description || "Sample Description";
        this.id = wayProps instanceof Array ? "" : wayProps.id

        this.wayPoints = [];
        this.destination = null;

        this.withWaypointsAndDest = withWaypointsAndDest;
        this.withDestination = withDestination;
        this.withWaypoints = withWaypoints;

        if(withWaypointsAndDest) {
            this.editor.jsonWayProps.push({
                name: this.name,
                id: this.id,
                navObj: this.wayProps,
                type: "waypointAndDestination"
            });

            this.createWayPoints(0, this.wayProps.waypoints);
            this.createDestination(0);
            this.createLineGeometry(0);
            this.insertPathName(0);

        } else if(withDestination) {
            this.editor.jsonWayProps.push({
                name: this.name,
                id: this.id,
                navObj: 'pathColor' in this.wayProps ? this.wayProps : {...this.wayProps, pathColor: "#FD7A02"},
                type: "destination"
            });
            this.createDestination(1);

        } else if(withWaypoints) {
            this.name = wayProps[0].name;
            this.description = wayProps[0].description || "";
            this.id = wayProps[0].id;

            this.editor.jsonWayProps.push({
                name: this.name,
                id: this.id,
                navObj: this.wayProps,
                type: "waypoints"
            });
            // this.editor.jsonWayProps = [...new Map(this.editor.jsonWayProps.map((item) => [item["id"], item])).values()]; //BADDDD
    
            this.createWayPoints(2, this.wayProps);
            this.createLineGeometry(2);
            this.insertPathName(2);

        }

        this.editor.on('objectRemoved', this.onObjectRemoved);
    }

    insertPathName = (mode) => {
        const editIndex = this.editor.getIndex('jsonWayProps', this.id);
        let wayGroup = {...this.editor.jsonWayProps[editIndex]};

        if(mode === 0 && !('pathName' in wayGroup.navObj)) {
            const pathName = `pathName__${getRandomIdCode()}`;
            let wayObj = {...wayGroup.navObj};
            wayObj.pathName = pathName;

            let wayArray = [...wayObj.waypoints];

            for(var  i = 0; i < wayArray.length; i++) {
                wayArray[i] = {...wayArray[i], pathName};
            }

            wayObj = {
                ...wayObj,
                waypoints: wayArray
            }

            wayGroup = {
                ...wayGroup,
                navObj: wayObj
            }

            this.editor.jsonWayProps[editIndex] = wayGroup;
        } else if(mode === 2 && !('pathName' in wayGroup.navObj[0]) ){
            const pathName = `pathName__${getRandomIdCode()}`;
            let wayArray = [...wayGroup.navObj]
            
            for(var j = 0; j < wayArray.length; j++) {
                wayArray[j] = {...wayArray[j], pathName};
            }

            wayGroup = {
                ...wayGroup,
                navObj: wayArray
            }
            this.editor.jsonWayProps[editIndex] = wayGroup;
        }
    }

    createWayPoints = (mode, argWaypoints) => {
        if(argWaypoints) {
            for(const waypoint of argWaypoints) {
                this.wayPoints.push(new NavPoint(waypoint));
            }
        }
        if(mode === 1) { // Both
            this.wayPoints.push(new NavPoint(this.wayProps));
        }
    }

    createDestination = (mode) => {
        this.destination = new LocationPin(this.wayProps, mode);
    }
    
    createLineGeometry = (mode) => {
        this.wayPoints.length > 0 && (this.line = new NavLine({vertices: this.wayPoints, name: this.name, description: this.description, id: this.id}, mode));
    }

    onObjectRemoved = (object, delayAutosave) => {
        if(object && this.id === object.userData.id && object.userData.navigationMode === "waypointAndDestination" ) {
            object.userData.type === "Location Pin"  && this.editor.onCommand(new RemoveObject(this.editor, this.destination.mesh));
            object.userData.type === "Guided Tour" && this.editor.onCommand(new RemoveObject(this.editor, this.line.mesh));
        }
        if(object && object.userData.id === this.id) {
            this.editor.jsonWayProps.splice(this.editor.getIndex('jsonWayProps', object.userData.id), 1);
            delayAutosave && this.editor.toAutoSave && this.editor.trigger('autoSaveSceneGraphState');
        }
        this.editor.stop('objectRemoved', this.onObjectRemoved);
    }
}

export default Navigation;