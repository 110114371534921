import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
// import * as THREE from 'three';

import { UIButton, UIPanel, UIText, UIObjectInfoCard } from "../ui.lib";

import { getObjectByUserDataProperty } from "../../threeUtils/TransformConversions";

import { SetVisible } from "../../commands/SetVisible";
import { checkOnboardingFlag } from "../../2d-editor/threeUtils/Connectors/ConnectorUtils";

class SidebarContentGuidedTour extends EventEmitter {
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.dom = null;

        this.initUI();

        this.editor.on("toggleMenuActive", this.onToggleMenuActive);
        this.editor.on("navigationTracking", this.onNavigationTracking);
        this.editor.on(
            "newTourOperationStatusChange",
            this.onNewTourOperationChange
        );
    }

    initUI = () => {
        this.uiMyContent = new UIPanel();

        /* New Guided Tour */
        this.uiGuidedTourBtn = new UIButton("Create Guided Route").onClick(
            async () => {
                let floorplan = null;
                this.editor.scene.children.forEach((child) => {
                    if (
                        child.userData &&
                        child.userData.navHelper &&
                        child.userData.navHelper === "floorplan"
                    ) {
                        floorplan = child;
                    }
                });

                if (floorplan) {
                    if (floorplan.userData.alignmentStatus) {
                        if (checkOnboardingFlag("tourOnboarding")) {
                            this.editor.onToggleInfoModals("Tour", true);
                        }
                        this.editor.guidedTourController.initNewTour();
                    } else {
                        this.editor.callbacks.generateAlert({
                            msg: "Align the floorplan in map to create a guided tour.",
                            alertType: "information",
                        });
                    }
                } else {
                    this.editor.callbacks.generateAlert({
                        msg: "Add floorplan in map to create a guided tour.",
                        alertType: "information",
                    });
                }
            }
        );

        this.uiGuidedTourEndBtn = new UIButton("End Tour Route").onClick(
            async () => {
                this.editor.guidedTourController.endTour();
            }
        );
        this.uiGuidedTourEndBtn.addClass("ws_uibtnDisabled");

        this.guideText = new UIText(undefined, "Tour Route");

        this.uiMyContent.addChild(this.uiGuidedTourBtn);
        this.uiMyContent.addChild(this.uiGuidedTourEndBtn);
        this.uiMyContent.addChild(this.guideText);

        this.dom = this.uiMyContent;
    };

    emptyContent = () => {
        var child = this.uiMyContent.dom.lastChild;
        while (child) {
            if (child === this.guideText.dom) break;
            this.uiMyContent.dom.removeChild(child);
            child = this.uiMyContent.dom.lastChild;
        }
    };

    isWaypointsObj = (jsonProps) => {
        var bFlag = false;
        let routeObjects = [];
        jsonProps.forEach((ele) => {
            if (
                ele.type === "waypoints" ||
                ele.type === "waypointAndDestination"
            ) {
                bFlag = true;
                routeObjects.push(ele);
            }
        });

        return { bFlag, routeObjects };
    };

    updateUiData = () => {
        if (!this.editor.guidedTourController.guidedTourInstances.size) {
            this.emptyContent();
            const uiNoDataCard = new UIPanel();
            uiNoDataCard.addClass("ws_uiNoDataCard");
            const uiSubText = document.createElement("div");
            uiSubText.classList.add("ws_uiNDSubText");
            uiSubText.textContent = "You haven’t created a tour route yet.";
            uiNoDataCard.dom.appendChild(uiSubText);
            this.uiMyContent.addChild(uiNoDataCard);
        } else {
            const routeObjects = Array.from(
                this.editor.guidedTourController.guidedTourInstances.values()
            );
            const uiCardsRow = new UIPanel();
            uiCardsRow.addClass("ws_uiCardsRowFloat");
            this.emptyContent();
            routeObjects.forEach((route, index) => {
                const uiImageCont = new UIObjectInfoCard(
                    this.editor,
                    route,
                    getObjectByUserDataProperty(
                        this.editor.scene,
                        "id",
                        route.tourId
                    ),
                    this.onTourCardSelect,
                    "TOUR",
                    index
                );
                uiCardsRow.addChild(uiImageCont);
            });
            this.uiMyContent.addChild(uiCardsRow);
        }
    };

    onTourCardSelect = (content, eventType) => {
        const object = getObjectByUserDataProperty(
            this.editor.scene,
            "id",
            content.tourId
        );
        if (object) {
            if (eventType === 2) {
                this.editor.onCommand(
                    new SetVisible(
                        this.editor,
                        object,
                        !object.visible,
                        false,
                        false
                    )
                );
            } else {
                this.editor.guidedTourController.selectGuidedTour(
                    content.tourId
                );
            }
        }
    };

    refreshUI = () => {
        this.updateUiData();
    };

    onToggleMenuActive = () => { };

    onNavigationTracking = (bFlag) => {
        if (bFlag) {
            //Disable
            // this.uiGuidedTourBtn.setDisabled(true).addClass('ws_uibtnDisabled');
        } else {
            //Reset!
            // this.uiGuidedTourBtn.setDisabled(false).removeClass('ws_uibtnDisabled');
            this.refreshUI();
        }
    };

    unload = () => {
        this.emptyContent();
    };

    onNewTourOperationChange = (status) => {

        switch (status) {
            case "Started":
                this.uiGuidedTourBtn.setDisabled(true).addClass("ws_uibtnDisabled");
                this.uiGuidedTourEndBtn
                    .setDisabled(true)
                    .addClass("ws_uibtnDisabled");
                break;

            case "InProgress":
                this.uiGuidedTourEndBtn
                    .setDisabled(false)
                    .removeClass("ws_uibtnDisabled");
                break;

            case "Ended":
                this.uiGuidedTourBtn
                    .setDisabled(false)
                    .removeClass("ws_uibtnDisabled");
                this.uiGuidedTourEndBtn
                    .setDisabled(true)
                    .addClass("ws_uibtnDisabled");
                break;

            default:
                break;
        }
    };
}

export { SidebarContentGuidedTour };
