import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
//MUi
import { Box, Menu, MenuItem, Tooltip, IconButton } from "@mui/material";
import { MoreVertical, /* Check, */ Trash } from "react-feather";
//Actions
import {
  /* updateMemberRole, */ removeTeamMember,
} from "../../../../../features/user/settings/settingsSlice";

const MemberCard = (props) => {
  const dispatch = useDispatch();

  const [member, setMember] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  useEffect(() => {
    if (props && props.member) {
      setMember(props.member);
    }
  }, [props]);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  /* const handleUserRoleChange = () => {
        const reqObj = {
            email: member.email,
            role: 'user'
        }
        dispatch(updateMemberRole(reqObj));
    } */

  const handleDeleteUser = () => {
    dispatch(removeTeamMember({ email: member.email }));
  };

  if (!member) {
    return null;
  }

  return (
    <Box className="settingshome__tmCard" key={props.index}>
      <div className="settingshome__tmCard--infoCol">
        <span className="settingshome__tmCard--nameSpan">
          {member.name || "--"}{" "}
          {member.role === "owner" && (
            <span className="settingshome__tmCard--nameSubSpan">(You)</span>
          )}
        </span>
        <span className="settingshome__tmCard--emailSpan">{member.email}</span>
      </div>
      <div className="settingshome__tmCard--roleCOl">
        {member.role === "owner" ? (
          <span className="settingshome__tmCard--roleText">
            <span className="settingshome__tmCard--roleEmoji">&#11088;</span>{" "}
            Owner
          </span>
        ) : (
          <span className="settingshome__tmCard--roleText">{member.role}</span>
        )}
      </div>
      <div className="settingshome__tmCard--menuCol">
        {member.role !== "owner" && (
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="More Options">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <MoreVertical size={20} />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {/* <MenuItem key={"Profile"} onClick={handleUserRoleChange}>
                                <Check size={16} color={'#353E5A'} />
                                <span className="settingshome__tmCard--menuItemText">Set as admin</span>
                            </MenuItem> */}
              <MenuItem key={"Logout"} onClick={handleDeleteUser}>
                <Trash size={16} color={"#353E5A"} />
                <span className="settingshome__tmCard--menuItemText">
                  Remove User
                </span>
              </MenuItem>
            </Menu>
          </Box>
        )}
      </div>
    </Box>
  );
};

export default MemberCard;
