import * as React from 'react';
import {
    Backdrop, Box,
    Modal, Fade,
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

export default function MessageModal({ openModal, onCloseModal, inlineStyle, children, showClose = true }) {
    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={{ ...style, ...inlineStyle }}>
                        {showClose && <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal} id="button-modalClose"/>}
                        {children}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
