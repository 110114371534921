import React, { useState } from "react";
import {  Backdrop, Box, Modal, Fade } from "@mui/material";
import { ReactComponent as ModalCloseBtnIcon } from "../../../../../assets/svgs/ModalCloseBtnIcon.svg";
import CreateMapStepper from "../Stepper/CreateMapStepper";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  bgcolor: "#ffffff",
  boxShadow:
    "0px 2px 16px rgba(40, 41, 61, 0.12), -2px 20px 32px rgba(96, 97, 112, 0.24)",
  p: 4,
  borderRadius: "16px",
};

const CreateMapModal = ({ open, handleClose, onSuccess }) => {
  const [stepperHeader, setStepperHeader] = useState("Map Setup");
  const closeModalAfterSuccess = () => {
    handleClose();
    onSuccess();
  };

  return (
    <Modal
      aria-labelledby="arway-upload-content-modal"
      aria-describedby="arway-upload-content-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box
          sx={style}
          className="upload-content-modal"
          style={{ height: "90vh", overflowY: "auto" , width:'60vw'}}
        >
          <button className="upload-modal-close-btn" id="button-modal-close" onClick={handleClose}>
            <ModalCloseBtnIcon />
          </button>
          <div>
          <h1 style={{ paddingBottom: "30px" }}>{stepperHeader}</h1>
          <CreateMapStepper
            closeModalAfterSuccess={() => {
              closeModalAfterSuccess();
            }}
            setStepHeader={(headerValue) => {
              setStepperHeader(headerValue);
            }}
          />
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CreateMapModal;
