import { Button, Form, Input, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRef } from 'react'
import SvgIcn from '../../../assets/svgs/ARWay_Primary_Logo_Brand.svg'
import { deleteOtpResponse, resetPassword, validateOtp, validateOtpResponse } from "../../../features/auth/authSlice";

// Routes
import {
    RESET_PASSWORD,
} from '../../_utils/routes';


export const VerifyOtp = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const validateResponse = useSelector(validateOtpResponse)
    const email = localStorage.getItem('forget-pass-email')
    const [otpResent, setOtpResent] = useState()
    const [typing, setTyping] = useState(false)
    const [error, setError] = useState({err:false, errMsg:null})
    const [otp, setOtp] = useState(null)
    const [fadeoutTrigger, setFadeoutTrigger] = useState(false)
    var fadeRef = useRef()

    useEffect(() => {
        if(validateResponse&& validateResponse.code>299){
            // alert(validateResponse?.message)
            setError({err:true, errMsg:validateResponse?.message})
        }
        if(validateResponse && (!validateResponse.code)){
            // alert(validateResponse?.message)
            navigate(RESET_PASSWORD)
        }

        return () => {
            dispatch(deleteOtpResponse())
        }
        
    },[validateResponse, dispatch, navigate])

   
    
    
    const resendOtp = () => {
        // alert(email, 'asfafadf');
        dispatch(resetPassword({email: email}))
        setOtpResent(true)
        // setFadeoutTrigger(true)

        setTimeout(() => {
            // setFadeoutTrigger(false)
            setOtpResent(false)
            setFadeoutTrigger(false)
        },3500)
    }

    const handleSubmit = (e) => {
        if(!otp){
            setError({err:true, errMsg:'Enter OTP'})
        }
        dispatch(validateOtp(otp))
        e.preventDefault()
    }

    return(
        <Layout className="main">
            <Layout className="form-containers">
                {otpResent ? (
                    [
                        <div ref={fadeRef} className={fadeoutTrigger ? 'email-sent fade-out' : 'email-sent' }>
                            Email has been sent
                        </div>
                    ]
                    
                ) : null}
                <img className="brand-logo" alt="brand-logo-3" src={SvgIcn} />
                <h5 className="login-header" id="h5-verifyOtp">Forgot Password?</h5>
                <p className="verify-guide">Enter the 6 digit verification code sent to your email.<br /> Don't see it? Make sure to check spam, junk and promotional folders.</p>
                
                    <form onSubmit={handleSubmit}>
                    
                    <Layout className="input-label-container">
                        {typing?(<label className="typing-label">Verification Code</label>):null}
                        <Input 
                            type="number" 
                            className={`text-inputs ${error.err ? `error` : null } `}
                            placeholder="Verification Code"
                            onChange={(e) => {
                                setOtp(e.target.value)
                                setError({err:false, errMsg:null})
                            }}
                            onFocus={() => {
                                setTyping(true)
                                setFadeoutTrigger(true)
                            }}
                            onBlur={() => {
                                setTyping(false)

                            }}
                        />
                        {error.err?(<label className="errors" id='error-verifyOtp'>{error.errMsg}</label>):null}
                    </Layout>
                    
                    <Form.Item>
                        <Button className="signin-button" type="primary" htmlType="submit">
                            <span className="signin-button--spanText"> Next </span>
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={resendOtp} className="reset-email-button login-btn" type="primary">
                            Resend email
                        </Button>
                    </Form.Item>
                </form>
            </Layout>
        </Layout>
    )
}