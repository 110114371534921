import * as React from 'react';
import {
    Backdrop, Box,
    Modal, Fade,
    Button
} from '@mui/material';

import {
    XCircle
} from 'react-feather';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

const helperObjs = [
    {
        subHeaderTextOne: "By Switching in between versions, current version state of map would get lost!",
        subHeaderTextTwo: "To avoid this, you can publish the current version and then switch the map version.",
        switchText: "Switch Anyway"
    },
    {
        subHeaderTextOne: "Are you sure, you want to switch map version?",
        subHeaderTextTwo: "Current version state of map would update with this operation!",
        switchText: "Switch Version"
    }
]

export default function PublishModal({ openModal, onCloseModal, onSuccessModal, toPublish }) {

    const { subHeaderTextOne, subHeaderTextTwo, switchText } = toPublish ? helperObjs[0] : helperObjs[1];

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal} />
                        <div className="mapdetails__mapModalHeader">Switch Map Version</div>
                        <div className="mapdetails__mapModalText">{subHeaderTextOne}</div>
                        <div className="mapdetails__mapModalText">{subHeaderTextTwo}</div>
                        <div className="publishModalBtnsDiv">
                            <Button variant='outlined' color='primary' onClick={onCloseModal}>Cancel</Button>
                            <Button variant='contained' color='errorRed' onClick={() => onSuccessModal(false)} className='mapdetails__mapModalBtnText'>{switchText}</Button>
                            {toPublish && <Button variant='contained' color='successGreen' onClick={() => onSuccessModal(true)} className='mapdetails__mapModalBtnText'>Publish & Switch</Button>}
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
