import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../../api';
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";

const getReportedUsers = createAsyncThunk('userreports/getReportedUsers', async ( { pageNumber, pageSize }, { dispatch}) => {
    try{
        const response = await Server.get(`/v1/user/report?limit=${pageSize}&page=${pageNumber}`);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const changeUserActivityStatus = createAsyncThunk('userreports/changeUserActivityStatus', async (params, { dispatch}) => {
    try{
        const response = await Server.post('/v1/user/updatestatus', params);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
}); 

const initialState = {
    reportedUsers:[],
    totalPages: null,
    userReportsLog: null
};

const userReportsSlice = createSlice({
    name: 'userreports',
    initialState,
    reducers: {},
    extraReducers: {
        'common/resetState': () => {
            return{...initialState}
        },
        [getReportedUsers.fulfilled]: (state, {payload}) => {
            return({
                ...state,
                reportedUsers: payload.data,
                totalPages: payload.totalPages,
                userReportsLog: null
            })
        },
        [changeUserActivityStatus.fulfilled]: (state, { payload }) => {
            return({
                ...state,
                userReportsLog: payload
            })
        }
    }
});

/* export const {} = userReportsSlice.actions; */
export {
    getReportedUsers,
    changeUserActivityStatus
};

export default userReportsSlice.reducer;