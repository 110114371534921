import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  contentData,
  contentLoadingData,
  getContents,
  totalPages,
  currentPage,
  searchContents,
  searchData,
} from "../../../../features/content/contentLibrarySlice";
import Loader from "../../../common/loader";
import ContentHeader from "./ContentHeader";
import ContentItem from "./ContentItem";

const FloorplansContent = ({
  handleItemClick,
  refetchContents,
  stopRefetch,
}) => {
  const dispatch = useDispatch();
  const allContents = useSelector(contentData);
  const totalPageNumber = useSelector(totalPages);
  const currentPageNumber = useSelector(currentPage);
  const isLoading = useSelector(contentLoadingData);
  const searchList = useSelector(searchData);
  const [data, setData] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (allContents && (!query || query === "")) {
      setData([...allContents]);
    } else if (searchList) {
      setData([...searchList]);
    }
    // eslint-disable-next-line
  }, [allContents, query, searchList]);

  const handleSearch = (query, sortBy) => {
    setQuery(query);
    if((query || query !== "") || sortBy){
      dispatch(searchContents({ page: 0, query, type: "floorplans", sortBy }));
    }
  };

  useEffect(() => {
    dispatch(getContents({ type: "floorplans", page: 0 }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (refetchContents)
      dispatch(
        getContents({ type: "floorplans", page: currentPageNumber - 1 })
      );
    stopRefetch();
    // eslint-disable-next-line
  }, [refetchContents]);

  const handlePageChange = (page) =>
    dispatch(getContents({ type: "floorplans", page }));

  return (
    <ContentHeader
      onSearch={handleSearch}
      onPageChange={handlePageChange}
      data={data}
      isLoading={isLoading}
    >
      {isLoading ? (
        <Loader loaderText="Fetching Contents..." height="100%" width="100%" />
      ) : totalPageNumber === 0 || data?.length === 0 ? (
        <div className="no-content">
          <h1>
            {query
              ? `Your search - "${query}" did not match any results`
              : "You haven’t uploaded any files."}
          </h1>
          <p>
            {query
              ? "Please make sure all words are spelt correctly"
              : "Content you upload will appear here."}
          </p>
        </div>
      ) : (
        <div className="content-grid">
          {data?.map((item) => (
            <ContentItem
              type={item?.contentType}
              key={item?.id}
              item={item}
              handleItemClick={handleItemClick}
              currentTab="floorplans"
            />
          ))}
        </div>
      )}
    </ContentHeader>
  );
};

export default FloorplansContent;
