export const HOME = "/";

// AUTH Routes
export const LOGIN = "/auth/login";
export const REGISTER = "/auth/register";
export const FORGOT_PASSWORD = "/auth/forgotpassword";
export const VERIFY_OTP = "/auth/verifyotp";
export const RESET_PASSWORD = "/auth/resetpassword";
export const RESET_SUCCESS = "/auth/resetsuccess";
export const VERIFY_EMAIL = "/auth/verifyEmail";
export const VERIFY_EMAIL_SUCCESS = "/auth/verifyEmail/success";
export const VERIFY_EMAIL_LINKEXP = "/auth/verifyEmail/expired/:verificationToken";
export const VERIFY_EMAIL_ERROR = "/auth/verifyEmail/error";
export const VERIFY_2FA = "/auth/verify-2fa";

//ACCOUNT SETUP 
export const ACCOUNT_SETUP = '/account-setup';

// User Dashboard Routes
export const DASHBOARD_HOME = "/dashboard";
export const D_LANDING = "/dashboard/home";
export const D_ANALYTICS = "/dashboard/analytics";
export const D_CONTENT_LIBRARY = "/dashboard/content-library";
export const D_DEVELOPERS = "/dashboard/developers";
export const D_DOWNLOADS = "/dashboard/downloads";
export const D_SETTINGS = "/dashboard/settings";
export const D_STUDIO = "/dashboard/studio";
export const D_STUDIO_DETAILS = "/dashboard/studio/:viewMapId";
export const D_STUDIO_VENUE_DETAILS = "/dashboard/studio/venue/:venueMapId";
export const D_SUPPORT = "/dashboard/support";

//Admin routes
export const D_CONTENT_LANDING = "/dashboard/admin-home";
export const D_CONTENT_MAPS = "/dashboard/reported-maps";
export const D_CONTENT_MAPS_DETAILS = "/dashboard/reported-maps/:mapId";
export const D_CONTENT_USERS = "/dashboard/reported-users";
export const D_CONTENT_IMPERSONATE = '/dashboard/impersonate-users';
export const D_CONTENT_ANALYTICS = '/dashboard/analytics-users';
export const D_CONTENT_SDK_ANALYTICS = '/dashboard/sdk-analytics-users';
export const D_CONTENT_ANALYTICS_USER = '/dashboard/analytics-users/:userId';
export const D_CONTENT_SDK_ANALYTICS_USER = '/dashboard/sdk-analytics-users/:userId';
export const D_CONTENT_MANAGE_ACCOUNTS = '/dashboard/manage-accounts'
export const D_CONTENT_MANAGE_ACCOUNTS_USER = '/dashboard/manage-accounts/:viewAccountId';
export const D_IMPERSONATE_USER = "/impersonate/:userKey";
export const D_CONTENT_PLANS = "/dashboard/plans";

// WEB STUDIO
export const D_WEB_STUDIO = '/editor/:editMapId'

// Util Routes
export const PNF = "/404";
export const REFRESH_URL = "v1/auth/refresh-tokens/";