import EventEmitter from "../../utils/EventEmitter";
//icons
import DeleteIcon from "../../../../assets/svgs/scenes/delete_w.svg";
import { UIPanel } from "../ui.lib";
//Command
import { RemoveObject } from "../../commands/RemoveObject";

import { FloatingMenuActionAllContents } from "./FloatingMenu.Action.AllContents";
import { FloatingMenuActionGuidedTour } from "./FloatingMenu.Action.GuidedTour";

class FloatingMenuActions extends EventEmitter {
  constructor(editor) {
    super();

    this.editor = editor;
    this.dom = null;

    this.initUI();

    this.editor.on("nodeSelected", this.onNodeSelected);
    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on("deleteSelectedAsset", this.onDeleteSelectedAsset);
    this.editor.on("objectSelectedFromGuidedTour", this.onObjectSelected);
  }

  initUI = () => {
    var uiContainer = new UIPanel();
    uiContainer.addClass("wsFloatingActionContainer");

    //Delete
    this.uiDeleteContainer = document.createElement("div");
    this.uiDeleteContainer.classList.add("wsFloatingMenuBtnIsolated");
    this.uiDeleteContainer.addEventListener("click", () => {
      let object = this.editor.selectedObject;
      if (
        object !== null &&
        object.parent !== null &&
        object.userData &&
        !this.isPrimaryAccessPoint(object.userData)
      ) {
        const type = object.userData.type !== "wayPointGroup" ?  object.userData.type : "Guided Tour";
        if (type === "floorplan" && object.userData.alignmentStatus) {
          this.editor.onToggleFloorplanInfoModal(true, {
            name: object.name,
            type: "delete",
          });
        } else if (type === "qrAnchor" || type === "imageAccessPoint") {
          this.editor.onToggleInfoModals("Access", true, {
            type,
            mapCode: this.editor.mapCode,
            name: object.name,
            qrId: object.userData.id,
            link:
              type === "imageAccessPoint" ? object.userData.texture : undefined,
          });
        } else {
          this.editor.onToggleDeleteAssetModal(true, {
            type,
            name: object.name,
            showSubText: type === "Guided Tour",
          });
        }
      }
    });

    var deleteIcon = document.createElement("img");
    deleteIcon.classList.add("wsFloatingMenuIcon");
    deleteIcon.title = "Delete";
    deleteIcon.id = "img_Delete";
    deleteIcon.src = DeleteIcon;
    this.uiDeleteContainer.appendChild(deleteIcon);

    // getAllContentActionButtons
    this.uiActionRowAllContents = new FloatingMenuActionAllContents(
      this.editor
    );
    this.uiActionRowGuidedTour = new FloatingMenuActionGuidedTour(this.editor);

    uiContainer.dom.appendChild(this.uiActionRowAllContents.dom);
    uiContainer.dom.appendChild(this.uiActionRowGuidedTour.dom);

    uiContainer.dom.appendChild(this.uiDeleteContainer);

    this.dom = uiContainer.dom;
  };

  onNodeSelected = (node) => {
    if (!node) return;
    this.uiDeleteContainer.style.display = "none";
    this.uiActionRowAllContents.setDisplay("none");
    this.uiActionRowGuidedTour.setDisplay("none");
  }

  onObjectSelected = (object) => {
    if(!object) return ;

    this.uiDeleteContainer.style.display = "";

    if (
      object &&
      object.userData &&
      (object.userData.type === "wayPointGroup" ||
        object.userData.type === "Guided Tour")
    ) {
      this.uiActionRowAllContents.setDisplay("none");
      this.uiActionRowGuidedTour.setDisplay("");
    } else {
      this.editor.trigger("clickedInVoidWhileOperationOnNode");
      this.uiActionRowAllContents.setDisplay("");
      this.uiActionRowGuidedTour.setDisplay("none");
    }
    //For delete!
    if (
      object &&
      object.userData &&
      (object.userData.type === "mapMarker" ||
        this.isPrimaryAccessPoint(object.userData) ||
        object.userData.type === "floorplanPeg" ||
        object.userData.interactive2D !== undefined)
    ) {
      this.uiDeleteContainer.classList.add("wsFloatingMenuDisabled");
    } else {
      this.uiDeleteContainer.classList.remove("wsFloatingMenuDisabled");
    }
  };

  onDeleteSelectedAsset = () => {
    let object = this.editor.selectedObject;

    if (object !== null && object.parent !== null) {
      let delayAutosave =
        // "navigationMode" in object.userData ||
        "metaFloorplan" in object.userData ||
        "MetaObject" in object.userData;
        delayAutosave = !object.userData.type === "wayPointGroup";
        
      const optName =
        this.editor.history.restrictUndos.indexOf(object.userData.type) !== -1
          ? "RESTRICT_UNDO"
          : undefined;
      this.editor.onCommand(
        new RemoveObject(this.editor, object, delayAutosave),
        optName
      );
    }
  };

  isPrimaryAccessPoint = (userData) => {
    if (userData.type === "qrAnchor" && userData.primaryAnchor === true)
      return true;
    /* else if(userData.type === 'imageAccessPoint' && userData.primaryAccess === true) return true; */
    // DEFAULT IAP CAN BE DELETED
    else return false;
  };
}

export { FloatingMenuActions };
