import React, { useEffect, useState } from "react";
import { ReactComponent as SearchIcon } from "../../../../assets/svgs/search.svg";
// import { ReactComponent as ListViewOff } from "../../../../assets/svgs/ListViewOff.svg";
// import { ReactComponent as ListViewOn } from "../../../../assets/svgs/ListViewOn.svg";
/* import { ReactComponent as GridViewOff } from "../../../../assets/svgs/GridViewOff.svg";
import { ReactComponent as GridViewOn } from "../../../../assets/svgs/GridViewOn.svg"; */
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import {
  currentPage,
  totalPages,
} from "../../../../features/content/contentLibrarySlice";

const sortOptions = [
  {
    value: "",
    label: "Sort By",
  },
  {
    value: "desc",
    label: "Latest",
  },
  {
    value: "asc",
    label: "Oldest",
  },
];

const ContentHeader = ({
  children,
  onSearch,
  onPageChange,
  data,
  isLoading,
}) => {
  const [sortBy, setSortBy] = useState("");
  // const [view, setView] = useState("grid");
  const [query, setQuery] = useState("");

  const totalPagesData = useSelector(totalPages);
  const page = useSelector(currentPage);

  const handleSearch = (e) => setQuery(e.target.value);

  const handlePageClick = (page) => onPageChange(page.selected);

  useEffect(() => {
    onSearch(query, sortBy);
    // eslint-disable-next-line
  }, [query, sortBy]);

  const handleChange = (event) => {
    setSortBy(event.target.value);
  };
  return (
    <>
      <div className="content-item-header">
        <div className="content-search">
          <label htmlFor="content-search">
            <SearchIcon />
            <input
              id="content-search"
              type={"text"}
              placeholder="Search"
              onChange={handleSearch}
              value={query}
            />
          </label>
        </div>
        <div className="content-view-change">
          <div className="header-sort">
            <select
              className="form-select"
              onChange={handleChange}
              value={sortBy}
              style={{ cursor: "pointer" }}
              id="select-sortOptions"
            >
              {sortOptions.map((opt) => (
                <option key={`option_${opt.value}`} value={opt.value} id={`option-${opt.label}`}>
                  {opt.label}
                </option>
              ))}
            </select>
          </div>
          {/* <div className="header-view">
            <button onClick={() => setView("list")}>
              {view === "list" ? <ListViewOn /> : <ListViewOff />}
            </button> 
            <button onClick={() => setView("grid")}>
              {view === "grid" ? <GridViewOn /> : <GridViewOff />}
            </button>
          </div> */}
        </div>
      </div>
      {children}
      {data?.length > 0 && !isLoading && (!query || query === "") && (
        <ReactPaginate
          className="content-pagination"
          breakLabel="..."
          nextLabel="Next >"
          onPageChange={handlePageClick}
          forcePage={page - 1}
          pageRangeDisplayed={5}
          pageCount={totalPagesData}
          previousLabel="< Previous"
        />
      )}
    </>
  );
};

export default ContentHeader;
