import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../../api';
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";


const getPlans = createAsyncThunk('plans/getPlans', async ( _, {dispatch}) => {
    try{
        const response = await Server.get('v1/plan');
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const createPlan = createAsyncThunk('plans/createPlan', async ( planObj, {dispatch}) => {
    try{
        const response = await Server.post('v1/plan', planObj);
        return response.data;
    } catch(e) {
        dispatch(HandleExceptionWithSecuredCatch(e));
        return e.response;
    }
});

const updatePlan = createAsyncThunk('plans/updatePlan', async ( planObj, {dispatch}) => {
    try{
        const response = await Server.put('v1/plan', planObj);
        return response.data;
    } catch(e) {
        dispatch(HandleExceptionWithSecuredCatch(e));
        return e.response;
    }
});

const getAccountsByPlan = createAsyncThunk('plans/getAccountsByPlan', async ({ pageNumber, pageSize, planId }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/list?planId=${planId}&limit=${pageSize}&page=${pageNumber}&includePlanDetails=true`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const searchAccountsByPlan = createAsyncThunk('manageaccounts/searchAccountsByPlan', async ({searchText, pageSize, pageNumber, planId}, { dispatch}) => {
    try{
        const response = await Server.get(`/v1/account/list?planId=${planId}&limit=${pageSize}&page=${pageNumber}&q=${encodeURIComponent(searchText)}&includePlanDetails=true`);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});


const initialState = {
    plansList:[],
    planLog: null,
    planAccountList: [],
    searchedAccountList: [],
    totalResults: 0,
    createLog: null,
};

const plansSlice = createSlice({
    name: 'plans',
    initialState,
    reducers: {
        clearLog: (state) => {
            return {...state, planLog: null, createLog: null}
        },
        clearSearchedAccounts: (state) => {
            return {...state, searchedAccountList: []}
        },
        resetPlanAccountList: (state) => {
            return {...state, planAccountList: []}
        },
    },
    extraReducers: {
        'common/resetState': () => {
            return{...initialState}
        },
        [getPlans.fulfilled]: (state, {payload}) => {
            return({
                ...state,
                plansList: payload.data,
                planLog: null,
                createLog: null
            })
        },
        [createPlan.fulfilled]: (state, {payload}) => {
            return({
                ...state,
                createLog: payload.status
            })
        },
        [updatePlan.fulfilled]: (state, {payload}) => {
            return({
                ...state,
                planLog: payload.status
            })
        },
        [getAccountsByPlan.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                planAccountList: payload.data,
                totalResults: payload.totalResults,
                planLog: null
            })
        },
        [searchAccountsByPlan.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                searchedAccountList: payload.data,
                planLog: null
            })
        },
    }
});

/* export const {} = plansSlice.actions; */
export {
    getPlans,
    createPlan,
    updatePlan,
    getAccountsByPlan,
    searchAccountsByPlan,

};

export const { clearLog, clearSearchedAccounts, resetPlanAccountList } = plansSlice.actions;

export default plansSlice.reducer;