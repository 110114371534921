import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MoreVertical } from "react-feather";
import React, { useState,useRef, useEffect } from "react";
import editIcon from "../../../../assets/svgs/studio/locationPin/edit.svg";
import deleteIcon from "../../../../assets/svgs/studio/locationPin/delete.svg";
import { ChevronDown, ChevronUp } from "react-feather";

const CategoryItem = ({
  modalData,
  category,
  categories,
  index,
  handleEdit,
  handleDelete,
  categoryExpanObj,
  register,
  errors,
  getValues,
  setIsComponentVisible
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: category.id, data: { isParent: true } });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const [hover, setHover] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const editRef = useRef(null);
  useEffect(()=>{
    if(showDropdown){
      editRef.current.scrollIntoView({ behavior: "smooth",block: 'end',
      inline: 'nearest' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDropdown]);

  useEffect(()=>{
    if(modalData.isEdit && modalData.data.id === category.id){
      setIsEdit(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="d-flex">
        {isEdit ? (
          <div style={{width:'98%'}}>
            <div
              className={`border add-parent-category ${
                errors[`category${category.id}`]?.message ? "border-danger" : "border-dark"
              }`}
            >
              <input
                autoFocus={true}
                type="text"
                id="category"
                className="cat-input"
                style={{paddingLeft:"3rem"}}
                defaultValue={category.name}
                {...register(`category${category.id}`, {required:'Category is required',maxLength:{value:100, message: 'Maximum allowed characters is 100'}})}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault()
                    handleEdit(
                      { category: { ...category, name: getValues(`category${category.id}`) } },
                      true
                      );
                      setIsEdit(false);
                  }
                }}
              />
            </div>
            <span className="span-error">{errors[`category${category.id}`]?.message}</span>
          </div>
        ) : (
          <div
            className="border border-dark add-parent-category"
            onMouseEnter={(e) => {
              setHover(true);
            }}
            onMouseLeave={(e) => {
              setHover(false);
              setShowDropdown(false);
            }}
          >
            <div style={{ marginRight: hover ? "" : "" }}>
              <MoreVertical
                style={{
                  float: "left",
                  display: hover ? "block" : "none",
                }}
              />
              <MoreVertical
                style={{
                  float: "left",
                  display: hover ? "block" : "none",
                  marginLeft: "-17px",
                }}
              />
            </div>
            <div className="d-flex justify-content-between">
              <div
                className="parent-text"
                style={{ paddingLeft: hover ? "1rem" : "" }}
              >
                <abbr title={category.name}>{category.name}</abbr>
              </div>
              <div
                className="ml-auto"
                onClick={() => {
                  categoryExpanObj.setCategoryExpand(
                    !categoryExpanObj.categoryExpand
                  );
                }}
                style={{ cursor: "pointer" }}
              >
                <MoreVertical
                  style={{ float: "left", display: hover ? "block" : "none" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowDropdown(true);
                  }}
                />
                {categoryExpanObj.categoryExpand ? (
                  <ChevronUp onClick={() => setHover(false)} />
                ) : (
                  <ChevronDown onClick={() => setHover(false)} />
                )}
              </div>
            </div>

            <div
              className={`cat-modal-dropdown dropdown-menu`}
              style={{ display: showDropdown ? "block" : "none" }}
            >
              <div
                className="dropdown-item child-text"
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
                onClick={(e) => {
                  e.stopPropagation();
                  // setEditedName(category.name)
                  setIsEdit(true);
                  setIsComponentVisible(false)
                }}
              >
                <img
                  alt="editIcon"
                  src={editIcon}
                  style={{ marginRight: "1rem", marginLeft: "-1rem" }}
                ></img>
                Rename
              </div>
              <div
                className="dropdown-item child-text"
                style={{ marginBottom: "1rem", marginTop: "1rem" }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleDelete({ category }, true);
                }}
              >
                <img
                  alt="deleteIcon"
                  src={deleteIcon}
                  style={{ marginRight: "1rem", marginLeft: "-1rem" }}
                ></img>
                Delete
              </div>
              <div ref={editRef}/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryItem;
