import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

//Components
import { Box, Select, MenuItem, FormControl } from "@mui/material";
import { Input, Layout } from "antd";

import ConfirmModal from "./ConfirmationModal";
import { countries } from "../../../_utils/countries";

//Actions
import {
  updateUserProfile,
  changeSetupState,
} from "../../../../features/auth/accessSlice";

const COUNTRY_DEF_VALUE = "Select your country";

const UserInfo = () => {
  const dispatch = useDispatch();

  const [userDetails, setUserDetails] = useState({
    fName: "",
    lName: "",
    companyName: "",
    email: "",
  });
  const [country, setCountry] = useState(COUNTRY_DEF_VALUE);
  const [canSubmit, setCanSubmit] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [typing, setTyping] = useState({
    fName: false,
    lName: false,
    companyName: false,
  });
  const [errors, setErrors] = useState({
    fName: false,
    lName: false,
    companyName: false,
  });

  const profileAuthrized = useSelector(
    (state) => state.access.profileAuthrized
  );
  const userEmail = useSelector((state) => state.auth.data.user.email);
  const loggedUserId = useSelector((state) => state.auth.loggedUserId);

  useEffect(() => {
    if (profileAuthrized !== "success") {
      dispatch(changeSetupState(1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userEmail && userEmail !== userDetails.email) {
      setUserDetails((details) => ({
        ...details,
        email: userEmail,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail]);

  useEffect(() => {
    if (profileAuthrized && profileAuthrized === "success" && !confirmModal) {
      setConfirmModal(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileAuthrized]);

  useEffect(() => {
    checkComplete();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails, country]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let { fName, lName, companyName } = userDetails;
    let toSubmit = true;
    let error = errors;

    if (fName.trim().length === 0) {
      error.fName = true;
      toSubmit = false;
    }
    if (lName.trim().length === 0) {
      error.lName = true;
      toSubmit = false;
    }
    if (country === COUNTRY_DEF_VALUE) {
      error.country = true;
      toSubmit = false;
    }

    if (!toSubmit) {
      setErrors(error);
    } else {
      var params = {
        name: fName,
        lastname: lName,
        company: companyName.length > 0 ? companyName : null,
        country,
      };
      dispatch(updateUserProfile({ userId: loggedUserId, params }));
    }
  };

  const handleChange = (e) => {
    setUserDetails((details) => ({
      ...details,
      [e.target.id]: e.target.value,
    }));
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const checkComplete = () => {
    let { fName, lName } = userDetails;
    let completeCount = 0;
    if (fName.trim().length > 0) {
      completeCount++;
    }
    if (lName.trim().length > 0) {
      completeCount++;
    }
    if (country && country !== COUNTRY_DEF_VALUE) {
      completeCount++;
    }
    if (completeCount === 3) {
      setCanSubmit(true);
    } else {
      setCanSubmit(false);
    }
  };

  const renderCountryOptions = () => {
    return countries.map((country, index) => (
      <MenuItem value={country} key={country}>
        <span>{country}</span>
      </MenuItem>
    ));
  };

  const { fName, lName, companyName, email } = userDetails;

  return (
    <Box className="accesshome__contentPane">
      <Box className="accesshome__userinfoContainer">
        <div className="accesshome__uiHeader">
          Complete your account profile
        </div>
        <form onSubmit={handleSubmit}>
          <Box className="accesshome__uiFormControlRow">
            <Layout className="input-label-container-user">
              {typing.fName ? (
                <label className="typing-label">First Name*</label>
              ) : null}
              <Input
                type="text"
                id="fName"
                value={fName}
                className={`text-inputs ${errors.fName ? `error` : null}`}
                placeholder="First Name*"
                onChange={handleChange}
                onFocus={() => {
                  setTyping((typing) => ({
                    ...typing,
                    fName: true,
                  }));
                }}
                onBlur={() => {
                  setTyping((typing) => ({
                    ...typing,
                    fName: false,
                  }));
                }}
              />
              {errors.fName ? (
                <label className="errors" id="error-name">
                  Please enter valid first name
                </label>
              ) : null}
            </Layout>
            <Layout className="input-label-container-user">
              {typing.lName ? (
                <label className="typing-label">Last Name*</label>
              ) : null}
              <Input
                type="text"
                id="lName"
                value={lName}
                className={`text-inputs ${errors.lName ? `error` : null}`}
                placeholder="Last Name*"
                onChange={handleChange}
                onFocus={() => {
                  setTyping((typing) => ({
                    ...typing,
                    lName: true,
                  }));
                }}
                onBlur={() => {
                  setTyping((typing) => ({
                    ...typing,
                    lName: false,
                  }));
                }}
              />
              {errors.lName ? (
                <label className="errors" id="error-lName">
                  Please enter valid last name
                </label>
              ) : null}
            </Layout>
          </Box>

          <Box className="accesshome__uiFormControlRow">
            <Layout className="input-label-container-user">
              <Input
                type="email"
                value={email}
                className="text-inputs"
                placeholder="Email"
                readOnly
              />
            </Layout>
          </Box>
          <Box className="accesshome__uiFormControlRow">
            <Layout className="input-label-container-user">
              {typing.companyName ? (
                <label className="typing-label">Company Name (optional)</label>
              ) : null}
              <Input
                type="text"
                id="companyName"
                value={companyName}
                className={`text-inputs ${errors.companyName ? `error` : null}`}
                placeholder="Company Name (optional)"
                onChange={handleChange}
                autoComplete="off"
                onFocus={() => {
                  setTyping((typing) => ({
                    ...typing,
                    companyName: true,
                  }));
                }}
                onBlur={() => {
                  setTyping((typing) => ({
                    ...typing,
                    companyName: false,
                  }));
                }}
              />
            </Layout>
          </Box>

          <Box className="accesshome__uiFormControlRow">
            <Layout className="input-label-container-user">
              <FormControl sx={{ width: "100%" }}>
                <Select
                  className={`text-inputs ${errors.country ? `error` : null}`}
                  value={country}
                  onChange={handleCountryChange}
                >
                  <MenuItem value={COUNTRY_DEF_VALUE} key={country}>
                    <span>Select your country</span>
                  </MenuItem>
                  {renderCountryOptions()}
                </Select>
              </FormControl>
              {errors.country ? (
                <label className="errors errors--marginLess" id="error-country">
                  Please select a country
                </label>
              ) : null}
            </Layout>
          </Box>

          <Box className="accesshome__uiFormControlRow">
            <Layout className="input-label-container-user">
              <button
                className={
                  canSubmit
                    ? "accesshome__submitBtn"
                    : "accesshome__submitBtn accesshome__submitBtn--disabled"
                }
                type="submit"
                disabled={!canSubmit}
              >
                Save Profile Information
              </button>
            </Layout>
          </Box>
        </form>
      </Box>
      <ConfirmModal
        openModal={confirmModal}
        onCloseModal={() => setConfirmModal(false)}
      />
    </Box>
  );
};

export default UserInfo;
