const threeDyBlob = new URLSearchParams({
    'grant_type': 'client_credentials',
    'client_id': 'bgKLW2AKqI710RTfEYxj7T890SFzYmse',
    'client_secret': 'BYjRPb70tFSn2swCmobh0l5hfVq7EB6KJJ_pQ247LqHzzhkLGBmC44psiR-SQvup',
    'audience': 'https://pipeline-api.aarwild.com'
})

const threeDyPutBlob = {
    "cli": [
        "87"
    ],
    "tag": []
}

export { threeDyBlob, threeDyPutBlob};