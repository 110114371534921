import * as THREE from 'three';
import EditorExperience from '../../EditorExperience';

/* LINE UTILS */
import { PathGeometry } from '../../utils/pathLib/PathGeometry';
import { PathPointList } from '../../utils/pathLib/PathPointList';

class PathEdge {

    constructor(lineProps) {

        this.editor = new EditorExperience();

        this.vertices = lineProps.vertices;
        this.pathColor = lineProps.pathColor;
        this.pathStroke = lineProps.pathStroke;
        
        this.mesh = new THREE.Group();
        this.mesh.name = `${this.name}`;

        this.material = new THREE.MeshBasicMaterial({
            color: this.pathColor,
            depthWrite: false,
            side: THREE.DoubleSide,
        });

        this.setupLineGeometry();

    }

    setupLineGeometry = () => {

        const pathPointList = new PathPointList();
        pathPointList.set(this.vertices, .15, 10, undefined, false)

        this.geometry = new PathGeometry({
            pathPointList: pathPointList,
            options: {
                width: (0.2 * this.pathStroke),
                arrow: false,
                progress: 1,
                side: "both" 
            },
            usage: THREE.StaticDrawUsage 
        })


        this.line = new THREE.Mesh(this.geometry, this.material);
        this.line.frustumCulled = false;
        // this.line.position.y = this.line.position.y >= 0 ? this.line.position.y + 0.01 : this.line.position.y - 0.01;
        this.mesh.renderOrder = 1001;
        this.mesh.add(this.line);

        this.mesh.userData['transformation'] = "NO_TRANSFORM"; //only to hide transformation controls in edit mode
        this.mesh.userData['skipChild'] = true;
        this.mesh.userData['visible'] = this.visible;
        this.mesh.userData['interactive2D'] = true;
        this.mesh.visible = this.visible;
        this.mesh.position.add(new THREE.Vector3(0, 0.1, 0));

        this.editor.nonInteractiveObjects.push(this.mesh);
    }

    updateGeometryVertices = (updatedVertices) => {
        this.vertices = updatedVertices;
        this.mesh.remove(this.line);
        this.setupLineGeometry();
    }
}

export { PathEdge };