import React from 'react';
import { NavLink as Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { useDispatch } from 'react-redux';
import {
    Card, Table,
    TableBody, TableCell,
    TableHead, TableRow,
    NativeSelect, FormControl
} from '@mui/material';
import { changeReportActivtyStatus } from '../../../../features/admin/mapreports/mapReportSlice';
import { convertToShortDateNS, convertStringToDateObj } from '../../../_utils/dateFormatter';

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2)
    }
}));

const MapTable = ({maps}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleStatusChange = (e, map) => {
        const reqObj = {
            mapCode: `${map.mapCode}`,
            status: !JSON.parse(map.isActive)
        }
        dispatch(changeReportActivtyStatus(reqObj))
    }

    return (
        <Card className={classes.root}>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell className="fw600">Map Name</TableCell>
                        <TableCell className="fw600">Map Code</TableCell>
                        {/* <TableCell className="fw600">Map Description</TableCell> */}
                        <TableCell className="fw600">User Email</TableCell>
                        {/* <TableCell className="fw600">Created On</TableCell>
                        <TableCell className="fw600">Modified On</TableCell> */}
                        {/* <TableCell className="fw600">Times Reported</TableCell> */}
                        <TableCell className="fw600">Report Type</TableCell>
                        <TableCell className="fw600">Report On</TableCell>
                        {/* <TableCell className="tableHead">Report Description</TableCell> */}
                        <TableCell className="fw600 w60">Activity Status</TableCell>
                        <TableCell className="fw600" >Details</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {maps.map((map, index) => (
                        <TableRow hover key={index+map.mapCode}>
                            <TableCell>{map.mapName}</TableCell>
                            <TableCell>{map.mapCode}</TableCell>
                            {/* <TableCell>{map.mapDescription}</TableCell> */}
                            <TableCell>{map.email}</TableCell>
                            {/* <TableCell>{new Date(map.createdOn).toDateString()}</TableCell>
                            <TableCell>{new Date(map.modifiedOn).toDateString()}</TableCell> */}
                            {/* <TableCell>{map.reportsCount}</TableCell> */}
                            <TableCell>{map.reportType}</TableCell>
                            <TableCell>{convertToShortDateNS(convertStringToDateObj(map.reportedOn))}</TableCell>
                            <TableCell className="w60">
                                <FormControl fullWidth>
                                    <NativeSelect
                                        defaultValue={map.isActive}
                                        inputProps={{
                                            name: 'astatus',
                                            id: 'uncontrolled-native',
                                        }}
                                        onChange={(e) => handleStatusChange(e, map)}
                                    >
                                        <option value={"false"}>Blocked</option>
                                        <option value={"true"}>Unblocked</option>
                                    </NativeSelect>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <Link to={`/dashboard/reported-maps/${map.mapCode}`} state={map} className="linkTag">View Content</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}

export default MapTable;