import * as THREE from 'three';
import EditorExperience from '../../MinEditorExperience';

//shaders
import { heatVertex, heatFragment } from '../../miniUtils/shaders/heatShaders.js';

import { getRandomIdCode } from '../../../threeUtils/TransformConversions';

export default class HeatMap {
    constructor(heatData, heatType, sizes = null) {

        this.editor = new EditorExperience();
        
        this.heatData = heatData;
        this.heatType = heatType;
        this.sizes = {
            start: sizes?.start || 0,
            end: sizes?.end || 0,
        };

        this.mesh = null;

        this.setupHeatMap();

        if(this.heatType === 'end') {

            this.initHTML();

            this.editor.on('hotspotHovered', this.showAnnotation); 
        }

    }
    
    setupHeatMap = () => {
        const geometry = new THREE.BufferGeometry();

        const count = this.heatData.length;

        const positions = new Float32Array(count * 3);
        const colors = new Float32Array(count * 4);

        this.heatData.forEach((hData, i) => {
            
            const i3 = i * 3;
            const i4 = i * 4;

            positions[i3    ] = hData.position[0];
            positions[i3 + 1] = hData.position[1];
            positions[i3 + 2] = hData.position[2];

            let mixedColor;

            if(hData.type === 1) {
                const color = this.getComputeRGB(hData.colorVal);
                mixedColor = new THREE.Color(color[0], color[1], color[2]);
            } else if(hData.type === 2) {
                mixedColor = new THREE.Color(0.10, 0.55, 0.80);
            } else {
                mixedColor = new THREE.Color(0.97, 0.32, 0.23);
            }

            colors[i4    ] = mixedColor.r;
            colors[i4 + 1] = mixedColor.g;
            colors[i4 + 2] = mixedColor.b;
            colors[i4 + 3] = hData.weight + 0.2;
        });

        geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3));
        geometry.setAttribute('color', new THREE.BufferAttribute(colors, 4));

        const material = new THREE.ShaderMaterial({
            uniforms: {
                uSize: {value: 80 * this.editor.renderer.instance.getPixelRatio()},
                uTexture: {value: new THREE.TextureLoader().load('/static/textures/point.png')}
            },
            vertexShader: heatVertex,
            fragmentShader: heatFragment,
            // blending: THREE.AdditiveBlending,
            vertexColors: true,
            depthWrite: false,
            transparent: true,
        })
        
        this.mesh = new THREE.Points(geometry, material);
        this.mesh.name = `heatMap_${getRandomIdCode()}`
    }

    initHTML = () => {
        this.annotation = document.createElement('div');
        this.annotation.classList.add('annotationHeat');
        this.annotation.id = `start_${this.mesh.name}`;

        this.pDes = document.createElement('div');
        this.pDes.classList.add('annotationHeat--text')
        this.pDes.textContent = 'Average App Opens:';
        this.annotation.appendChild(this.pDes);

        this.pDes2 = document.createElement('span');
        this.pDes2.classList.add('annotationHeat--textSm')
        this.pDes2.textContent = ` ${this.sizes.start}`;
        this.pDes.appendChild(this.pDes2);

        this.pDes3 = document.createElement('div');
        this.pDes3.classList.add('annotationHeat--text')
        this.pDes3.textContent = 'Average App Exits:';
        this.annotation.appendChild(this.pDes3);
        
        this.pDes4 = document.createElement('span');
        this.pDes4.classList.add('annotationHeat--textSm')
        this.pDes4.textContent = ` ${this.sizes.end}`;
        this.pDes3.appendChild(this.pDes4);

        this.annotation.style.visibility = 'hidden';
        document.body.appendChild(this.annotation)
    }

    getComputeRGB = (value) => {
        const MAX = 100;
        // Color Gadient
        const colorArray = [
            [ 0,     [255, 77, 0]   ],
            [ 31.25, [237, 201, 9]  ],
            [ 48.44, [16, 223, 111] ],
            [ 69.27, [14, 255, 25]  ],
            [ 91.67, [37, 98, 255]  ],
            [ 100,   [96, 38, 255]  ]
        ];

        const getMixedColor = (color1, color2, p) => {
            var w = p * 2 - 1;
            var w1 = (w/1+1) / 2;
            var w2 = 1 - w1;
            var rgb = [Math.round(color1[0] * w1 + color2[0] * w2)/255,
                Math.round(color1[1] * w1 + color2[1] * w2)/255,
                Math.round(color1[2] * w1 + color2[2] * w2)/255];
            return rgb;
        }

        let colRange = [];
        let found = false;
        colorArray.forEach((color, index) => {
            if(value <= color[0] && !found) {
                colRange = [ index-1, index ];
                found = true;
            }
        });

        var innerColor = colorArray[colRange[0]][1];
        var outerColor = colorArray[colRange[1]][1];

        // calculate ratio/strength between two closest colors!
        var innerColor_x = MAX * (colorArray[colRange[0]][0]/100);
        var outerColor_x = MAX * (colorArray[colRange[1]][0]/100) - innerColor_x;
        var maxColor_x = MAX * (value/100) - innerColor_x;
        var strength = maxColor_x / outerColor_x;

        return getMixedColor(outerColor, innerColor, strength)
    }

    showAnnotation = (object) => {
        if(object === this.mesh) {
            this.annotation.style.visibility = 'visible';
        } else {
            this.annotation.style.visibility = 'hidden';
        }
    }
} 