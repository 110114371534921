import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//MUi
import { Button, Grid, TextField } from '@mui/material';
//Components
import Loader from '../../../../common/loader';
import MessageModal from "../../../../common/modal";
//Actions
import { getUserDetails, deleteAccount } from '../../../../../features/user/settings/settingsSlice';
import { logout } from '../../../../../features/auth/authSlice';
import { updateUserProfile, resetAccessState } from '../../../../../features/auth/accessSlice';
//Icons
import UserAvtarIcon from '../../../../../assets/icons/user_avtar.png';

const AccountHome = () => {
    const dispatch = useDispatch();

    const loggedUserId = useSelector(state => state.auth.loggedUserId);
    const user = useSelector(state => state.settings.userDetails);
    const deleteLog = useSelector(state => state.settings.deleteLog);
    const accessLog = useSelector(state => state.access.profileAuthrized);

    const [userDetails, setUserDetails] = useState(null);
    const [fetching, setFetching] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [company, setCompany] = useState('');
    const [toEdit, setToEdit] = useState(false);
    const [toDeleteAccount, setToDeleteAccount] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        setFetching(true);
        dispatch(resetAccessState());
        fetchUserDetails();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user) {
            setFetching(false);
            setUserDetails(user)
            setFirstName(user.name || '');
            setLastName(user.lastname || "lastname");
            setCompany(user.company || 'company');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    useEffect(() => {
        if (accessLog === 'success') {
            dispatch(resetAccessState());
            fetchUserDetails();
            setToEdit(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessLog]);

    useEffect(() => {
        if (deleteLog === 'success') {
            dispatch(logout());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleteLog]);

    useEffect(() => {
        toEdit && validateForm();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, lastName, company])

    const fetchUserDetails = () => {
        dispatch(getUserDetails(loggedUserId));
    }

    const toggleToEdit = () => {
        setToEdit(!toEdit)
    }

    const handleCancel = () => {
        setFirstName(userDetails.name);
        setLastName(userDetails.lastname || 'lastname');
        setCompany(userDetails.company || 'company');
        setToEdit(false);
        setErrors({});
    }

    const validateForm = () => {
        let error = errors;;
        if (firstName.trim().length === 0) {
            error['firstName'] = "First Name shouldn't be empty!";
        } else {
            delete error['firstName'];
        }

        if (lastName.trim().length === 0) {
            error['lastName'] = "Last Name shouldn't be empty!";
        } else {
            delete error['lastName'];
        }

        if (company.trim().length === 0) {
            error['company'] = "Company Name shouldn't be empty!";
        } else {
            delete error['company'];
        }
        setErrors(error);
    }

    const validateChanges = () => {
        let isChanged = false;
        if (firstName !== userDetails.name) {
            isChanged = true;
        }

        if (lastName !== userDetails.lastname) {
            isChanged = true;
        }

        if (company !== userDetails.company) {
            isChanged = true;
        }

        return isChanged;
    }

    const handeleProfileEdit = (e) => {
        e.preventDefault();
        if (validateChanges()) {
            var params = {
                name: firstName,
                lastname: lastName,
                company,
                country: userDetails.country
            }
            dispatch(updateUserProfile({ userId: loggedUserId, params }));
        } else {
            toggleToEdit();
        }
    }

    const handleDeleteAccount = () => {
        dispatch(deleteAccount(userDetails.id));
    }

    if (fetching || userDetails === null) {
        return (<Loader loaderText="Fetching User Details..." height="100%" width="100%" />);
    }

    return (
        <div className='settingshome'>
            <Grid container={true} className="settingshome__acGridContainer">
                <Grid item xs={4} className="settingshome__acCardContainer">
                    <div className="settingshome__acCard">
                        <img src={UserAvtarIcon} alt="user_avtar" className='settingshome__acCard--userAvtarImg' draggable='false' />
                        <span className='settingshome__acCard--label'>EMAIL</span>
                        <span className='settingshome__acCard--emailText'>{userDetails.email}</span>
                        <button className='settingshome__acCard--logOutButton' onClick={() => dispatch(logout())}>Log Out</button>
                        <button className='settingshome__acCard--deleteButton' onClick={() => setToDeleteAccount(true)}>Delete Account</button>
                    </div>
                </Grid>
                <Grid item xs={8} className="settingshome__acDetailsCardContainer">
                    <div className="settingshome__acDetailsCard">
                        <div className="settingshome__acDetailsCard--header">Profile Information</div>
                        <form onSubmit={handeleProfileEdit}>
                            <Grid container={true}>
                                <Grid item xs={6} className="settingshome__acDetailsCard--formInputContainer">
                                    <TextField
                                        label="First Name"
                                        id="firstName"
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                        size="small"
                                        fullWidth
                                        className={`settingshome__acDetailsCard--formControl ${toEdit ? "settingshome__acDetailsCard--formControl--active" : ""}`}
                                        autoComplete="off"
                                        inputProps={
                                            { readOnly: !toEdit }
                                        }
                                    />
                                    {errors['firstName'] ? <span className='settingshome__acDetailsCard--errorMsg'>{errors.firstName}</span> : null}
                                </Grid>
                                <Grid item xs={6} className="settingshome__acDetailsCard--formInputContainer">
                                    <TextField
                                        label="Last Name"
                                        id="lastName"
                                        value={lastName}
                                        onChange={e => setLastName(e.target.value)}
                                        size="small"
                                        fullWidth
                                        className={`settingshome__acDetailsCard--formControl ${toEdit ? "settingshome__acDetailsCard--formControl--active" : ""}`}
                                        autoComplete='off'
                                        inputProps={
                                            { readOnly: !toEdit }
                                        }
                                    />
                                    {errors['lastName'] ? <span className='settingshome__acDetailsCard--errorMsg'>{errors.lastName}</span> : null}
                                </Grid>
                            </Grid>
                            <Grid container={true}>
                                <Grid item xs={6} className="settingshome__acDetailsCard--formInputContainer">
                                    <TextField
                                        label="Company"
                                        id="company"
                                        value={company}
                                        onChange={e => setCompany(e.target.value)}
                                        size="small"
                                        fullWidth
                                        className={`settingshome__acDetailsCard--formControl ${toEdit ? "settingshome__acDetailsCard--formControl--active" : ""}`}
                                        autoComplete="off"
                                        inputProps={
                                            { readOnly: !toEdit }
                                        }
                                    />
                                    {errors['company'] ? <span className='settingshome__acDetailsCard--errorMsg'>{errors.company}</span> : null}
                                </Grid>
                            </Grid>
                            <div className="settingshome__acDetailsCard--btnContainer">
                                {!toEdit && <span className="developerhome__acRow--btn" onClick={toggleToEdit}>Edit Profile</span>}
                                {toEdit && <button type='submit' disabled={Object.keys(errors).length > 0} className="settingshome__acDetailsCard--btnSubmit">Save Changes</button>}
                                {toEdit && <button onClick={handleCancel} className="developerhome__acFormBtnCancel">Cancel</button>}
                            </div>
                        </form>
                    </div>
                </Grid>
            </Grid>
            <MessageModal
                openModal={toDeleteAccount}
                onCloseModal={() => setToDeleteAccount(false)}
                inlineStyle={{ height: "max-content", width: "45vw" }}
            >
                <div className="infomodal">
                    <div className="infomodal__mapModalHeader">Delete Account</div>
                    <div className="infomodal__mapModalText">Are you sure, you want to delete your account?</div>
                    <div className="infomodal__mapModalText">You won't be able to access this account, maps, venues, and assets from this account.</div>
                    <div className="publishModalBtnsDiv">
                        <Button variant='outlined' color='primary' onClick={() => setToDeleteAccount(false)}>Cancel</Button>
                        <Button variant='contained' color='errorRed' onClick={() => handleDeleteAccount()} className='mapdetails__mapModalBtnText'>Delete</Button>
                    </div>
                </div>
            </MessageModal>
        </div>
    )
}


export default AccountHome;