import React from 'react';
import {
    Card, Table,
    TableBody, TableCell,
    TableHead, TableRow,
} from '@mui/material';


const AppsTable = ({apps}) => {
    return (
        <Card>
            <Table stickyHeader >
                <TableHead>
                    <TableRow>
                        <TableCell className="fw600">App ID</TableCell>
                        <TableCell className="fw600">App Name</TableCell>
                        <TableCell className="fw600">Count</TableCell>
                        <TableCell className="w60 fw600">App Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {apps.map((app) => (
                        <TableRow hover key={app._id}>
                            <TableCell>{app._id}</TableCell>
                            <TableCell>{app.name}</TableCell>
                            <TableCell>{app.count}</TableCell>
                            <TableCell>{String(app.status).charAt(0).toUpperCase() + String(app.status).slice(1)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Card>
    );
}

export default AppsTable;