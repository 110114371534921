import React, { useState, useEffect } from 'react';
import ARWayImage from "../CO/ARWayImage";
import { 
    Radio
} from '@mui/material';

const FloorTab = ({ onChange, floors }) => {

    useEffect(() => {
        setSelectedFloor(null)
    }, []);

    const [ selectedFloor, setSelectedFloor ] = useState(null)

    const renderFloorCards = () => {
        return floors.map((floor, index) => (
            <div key={index} className="sfModal--floorCard__cardContainer">
                <div className="sfModal--floorCard__cardWrapper">
                    <div className="sfModal--floorCard__card">
                        <div className="sfModal--floorCard__imgCont">
                            <div className={`sfModal--floorCard__cardOverlay ${selectedFloor?.image === floor.image && 'sfModal--floorCard__cardOverlay--active'}`}>
                                <Radio 
                                    checked={selectedFloor?.image === floor.image}
                                    onChange={() => {
                                        setSelectedFloor(floor)
                                        onChange(floor)
                                    }}
                                    value={floor.image}
                                    name="radio-buttons"
                                    color='accentBlue'
                                />
                            </div>
                            <ARWayImage src={floor.image} alt={floor.name} />
                        </div>
                        <div className="sfModal--floorCard__title">
                            <abbr title={floor.name}>{floor.name}</abbr>
                        </div>
                    </div>
                </div> 
            </div>
        ));
    }

    return (
        <div className='sfModal--floorCard'>
            <div  className='sfModal--floorCard__header'>
                <span className='sfModal--floorCard__header--bold'>Note: </span>
                <span>Please choose a floor plan that closely matches the layout of your space in order to properly test any functions related to floor plan.</span>
            </div>
            {renderFloorCards()}
        </div>
    )
}

export default FloorTab;
