const heatVertex = 
`
uniform float uSize;
varying vec4 outColor;

void main() {

    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectPosition = projectionMatrix * viewPosition;

    gl_PointSize = uSize * 2.0;
    gl_PointSize *= (1.0 / -viewPosition.z);
    
    gl_Position = projectPosition;

    outColor = color;
}
`;

const heatFragment =
`
uniform sampler2D uTexture;

varying vec4 outColor;

void main() {
    vec4 color = texture2D(uTexture, gl_PointCoord);
    gl_FragColor = color * outColor; 
}
`;

export { heatVertex, heatFragment };
