import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container } from '@mui/material';
import { PlusCircle } from 'react-feather';

import { getPlans } from '../../../../features/admin/plans/plansSlice';
import PlanCard from './PlanCard';
import CreatePlanModal from './PlanCreateModal';
import UserTable from './UserTable';
import Loader from '../../../common/loader';


export default function PlanHome() {
    const dispatch = useDispatch();

    const [plans, setPlans] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [expanded, setExpanded] = useState(-1);
    const [planAccounts, setPlanAccounts] = useState(null);

    const plansList = useSelector(state => state.plans.plansList);
    const plansLog = useSelector(state => state.plans.planLog);
    const createLog = useSelector(state => state.plans.createLog);

    useEffect(() => {
        setFetching(true);
        dispatch(getPlans())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPlans(plansList);
        setFetching(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plansList]);

    useEffect(() => {
        if (plansLog === 'success' || createLog === 'success') {
            dispatch(getPlans())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plansLog, createLog]);

    const renderPlans = () => {
        if (fetching || plans.length <= 0) {
            return <Loader loaderText="Fetching Plans..." height="500px" width="100%" />
        }

        if (plans && plans.length <= 0) return (
            <div className='pmhome__plans--plansRow'>
                <span className='pmhome__plans--noPlansText'>No Plans Available!</span>
            </div>
        );

        return (
            <div className='pmhome__plans--plansRow'>
                {plans.map((plan, index) => (
                    <PlanCard
                        key={index}
                        index={index}
                        plan={plan}
                        expanded={expanded}
                        setExpanded={setExpanded}
                        setPlanAccounts={setPlanAccounts}
                    />
                ))}
            </div>
        );
    }

    if (planAccounts !== null) {
        return (
            <UserTable
                planInfo={planAccounts}
                handleBack={() => setPlanAccounts(null)}
            />
        );
    }

    return (
        <div className="pmhome">
            <Container maxWidth={false} >
                <div className="pageWrapper">
                    <h4>Manage Plans</h4>
                    <button className='pmhome__createBtn' onClick={() => setOpenModal(true)}>
                        <PlusCircle size={18} className='pmhome__createBtn--icon' />
                        Create Plan
                    </button>
                </div>
                <div className="pmhome__plans">
                    {renderPlans()}
                </div>
            </Container>
            <CreatePlanModal
                openModal={openModal}
                onCloseModal={() => setOpenModal(false)}
            />
        </div>
    )
}
