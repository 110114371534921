import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../../api";
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";
import { setNewAlert } from "../../common/alertSlice";
import { getRandomIdCode } from "../../../components/webstudio/threeUtils/TransformConversions";
import { has, isEmpty, omit } from "lodash";

const getUserMapList = createAsyncThunk(
  "studio/getUserMapList",
  async (
    { pageSize, pageNumber, mapFilter, mapsType, isAssociated },
    { dispatch }
  ) => {
    try {
      const response = await Server.get(
        `/v3/map/?limit=${pageSize}&page=${pageNumber}&mapsFilter=${mapFilter}${
          mapsType ? `&mapsType=${mapsType}` : ""
        }${isAssociated ? `&isAssociated=${isAssociated}` : ""}`
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getUserMapDetails = createAsyncThunk(
  "studio/getUserMapDetails",
  async (mapId, { dispatch }) => {
    try {
      const response = await Server.get(`/v2/map/${mapId}`);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getDefaultMapsDetails = createAsyncThunk(
  "studio/getDefaultMapsDetails",
  async (mapIds, { dispatch }) => {
    try {
      const responses = await Promise.all(
        mapIds.map((id) => Server.get(`/v2/map/${id}`))
      );
      return responses.map((res) => res.data.data.metadata);
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const deleteUserMap = createAsyncThunk(
  "studio/deleteUserMap",
  async ({ mapId }, { dispatch }) => {
    try {
      const response = await Server.delete(`/v1/map/${mapId}`);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const updateUserMap = createAsyncThunk(
  "studio/updateUserMap",
  async ({ mapId, mapDetails, noAlert }, { dispatch }) => {
    try {
      const response = await Server.put(`/v1/map/${mapId}`, mapDetails);
      !noAlert &&
        dispatch(
          setNewAlert({
            msg: response.data.message,
            alertType: "information",
          })
        );
      return response.data;
    } catch (e) {
      if (e.response.status === 422)
        return dispatch(
          setNewAlert({
            msg: "No contents in the map, you cannot publish an empty map!",
            alertType: "danger",
          })
        );
      else return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getDraftMapData = createAsyncThunk(
  "studio/getDraftMapData",
  async (mapCode, { dispatch }) => {
    try {
      const response = await Server.get(`/v1/mapdata/draft/${mapCode}`);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

// update floorplan
const putFloorplan = createAsyncThunk(
  "studio/putFloorplan",
  async ({ mapId, mapDetails, floorplanData, currFloorplan }, { dispatch }) => {
    try {
      let response;
      if (
        has(mapDetails?.metadata, "floors") &&
        mapDetails?.metadata?.floors.length > 0
      ) {
        let fpObj = {
          ...omit(currFloorplan, [
            "createdOn",
            "modifiedOn",
            "alignedResponse",
          ]),
          link: floorplanData.link,
          name: floorplanData.name,
          alignmentStatus: false,
          pegs: [],
          manualAligned: "2d_change",
          floorplanVersion: 2.7,
          position: { posX: 0, posY: 0, posZ: 0 },
          rotation: { rotX: -0.7071068, rotY: 0, rotZ: 0, rotW: 0.7071068 },
          scale: { scaX: 0.1, scaY: 0.1, scaZ: 0.1 },
        };
        response = await Server.put(`/v1/floorplan/${mapId}/false`, fpObj, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } else {
        let fpObj = {
          id: `floorplan_${getRandomIdCode()}`,
          link: floorplanData.link,
          name: floorplanData.name,
          floorplanVersion: 2.7,
        };
        response = await Server.post(`/v1/floorplan/${mapId}`, fpObj, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      }
      dispatch(
        setNewAlert({
          msg: "Map updated Successfully",
          alertType: "information",
        })
      );
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const createMap = createAsyncThunk(
  "studio/createMap",
  async (value, { dispatch }) => {
    let response;
    let thumbnailResponse = null;
    let floorplanResponse = null;

    try {
      // create thumbnail asset
      if (value.data.hasThumbnail) {
        thumbnailResponse = await Server.post(
          `/v1/asset/upload`,
          value.data.thumbnailData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }

      // create floorplan asset
      if (value.data.hasFloorplan && !value.data.isSampleFloor) {
        floorplanResponse = await Server.post(
          `/v1/asset/upload`,
          value.data.floorplanData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
      }
      // create Map
      if (
        thumbnailResponse &&
        thumbnailResponse.status === 200 &&
        floorplanResponse === null &&
        !value.data.isSampleFloor
      ) {
        response = await Server.post(
          `/v2/map`,
          {
            ...value.data.mapData,
            ...(thumbnailResponse.status === 200 && {
              thumbnailImage: thumbnailResponse.data.data.file,
            }),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      } else if (
        (thumbnailResponse &&
          thumbnailResponse.status === 200 &&
          ((floorplanResponse && floorplanResponse.status === 200) ||
            value.data.isSampleFloor)) ||
        (floorplanResponse && floorplanResponse.status === 200) ||
        value.data.isSampleFloor
      ) {
        //create map with thumbnail
        response = await Server.post(
          `/v2/map`,
          {
            ...value.data.mapData,
            ...(thumbnailResponse && {
              thumbnailImage: thumbnailResponse.data.data.file,
            }),
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        //create floorplan
        if (response.status === 200) {
          let reqObj = {};
          if (value.data.isSampleFloor) {
            reqObj["id"] = `floorplan_${getRandomIdCode()}`;
            reqObj["link"] = value.data.floorplanData.get("file");
            reqObj["name"] = value.data.floorplanData.get("name");
            reqObj["floorplanVersion"] = 2.7;
          } else {
            reqObj["id"] = `floorplan_${getRandomIdCode()}`;
            reqObj["link"] = floorplanResponse.data.data?.compressedFile || floorplanResponse.data.data?.file;
            reqObj["name"] = value.data.floorplanData.get("name");
            reqObj["floorplanVersion"] = 2.7;
          }
          await Server.post(
            `/v1/floorplan/${response.data.data.metadata.mapId}`,
            reqObj,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }
      } else {
        // create map without thumbnail/floorplan
        response = await Server.post(
          `/v2/map`,
          { ...value.data.mapData, thumbnailImage: "" },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
      }
      if (response && response.status === 200) {
        value.onSuccess();
      }
      return response.data.data;
    } catch (error) {
      return dispatch(HandleExceptionWithSecuredCatch(error));
    }
  }
);

const searchUserMaps = createAsyncThunk(
  "studio/searchUserMaps",
  async (
    { pageNumber, mapFilter, searchText, mapsType, isAssociated },
    { dispatch }
  ) => {
    const url = `/v3/map/?limit=10&page=${pageNumber}&q=${encodeURIComponent(
      searchText
    )}${mapFilter ? `&mapsFilter=${mapFilter}` : ""}${
      mapsType ? `&mapsType=${mapsType}` : ""
    }${isAssociated ? `&isAssociated=${isAssociated}` : ""}`;
    try {
      const response = await Server.get(url);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const initialState = {
  stepperMapDetails: {},
  userMaps: [],
  searchedUserMaps: [],
  mapDetailsPublished: {},
  isLoading: false,
  isUploading: false,
  mapDetails: null,
  studioLog: null,
  deleteLog: null,
  draftMapData: null,
  replaceLog: null,
  defaultMaps: [],
  totalPages: 0,
  recentData: false,
};

const studioSlice = createSlice({
  name: "studio",
  initialState,
  reducers: {
    resetMapDetails: (state, action) => {
      return { ...state, mapDetails: null };
    },
    setStepperMapDetails: (state, action) => {
      return { ...state, stepperMapDetails: action.payload };
    },
    resetStepperMapDetails: (state, action) => {
      return { ...state, stepperMapDetails: {} };
    },
    resetLogs: (state, action) => {
      return { ...state, replaceLog: null };
    },
    clearSearchedUserMaps: (state) => {
      return { ...state, searchedUserMaps: [] };
    },
    resetMapsState: () => {
      return { ...initialState };
    },
    setRecentData: (state, action) => {
      return { ...state, recentData: action.payload };
    }
  },
  extraReducers: {
    "common/resetState": () => {
      return { ...initialState };
    },
    [getDraftMapData.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        draftMapData: payload.data,
      };
    },
    [getUserMapList.fulfilled]: (state, { payload }) => {
      let userMaps = [];
      if (payload.data?.maps) {
        payload.data?.maps.forEach((el) =>
          userMaps.push({ ...el, isVenue: false })
        );
        payload.data?.buildings.forEach((el) =>
          userMaps.push({ ...el, isVenue: true, mapId: el._id })
        );
      }
      return {
        ...state,
        userMaps,
        searchedUserMaps: [],
        studioLog: null,
        deleteLog: null,
        replaceLog: null,
        totalPages: payload.totalPages,
        recentData: true
      };
    },
    [searchUserMaps.fulfilled]: (state, { payload }) => {
      let searchedUserMaps = [];
      if (payload.data?.maps) {
        payload.data?.maps.forEach((el) =>
          searchedUserMaps.push({ ...el, isVenue: false })
        );
        payload.data?.buildings.forEach((el) =>
          searchedUserMaps.push({ ...el, isVenue: true, mapId: el._id })
        );
      }
      return {
        ...state,
        searchedUserMaps,
        studioLog: null,
        deleteLog: null,
        replaceLog: null,
        totalPages: payload.totalPages,
      };
    },
    [updateUserMap.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapDetails: payload.data,
        studioLog: payload.status,
      };
    },
    [getUserMapDetails.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapDetails: payload.data,
        studioLog: null,
      };
    },
    [getDefaultMapsDetails.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        defaultMaps: payload,
      };
    },
    [putFloorplan.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        replaceLog: payload.status,
      };
    },
    [deleteUserMap.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        deleteLog: payload.status,
      };
    },
    [createMap.pending]: (state, { payload }) => {
      return {
        ...state,
        isUploading: true,
      };
    },
    [createMap.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        mapDetails: payload,
        isLoading: false,
        isUploading: false,
      };
    },
  },
});

export const {
  resetMapDetails,
  setStepperMapDetails,
  resetStepperMapDetails,
  resetLogs,
  clearSearchedUserMaps,
  resetMapsState,
  setRecentData,
} = studioSlice.actions;

export {
  getUserMapList,
  updateUserMap,
  getDraftMapData,
  getUserMapDetails,
  getDefaultMapsDetails,
  deleteUserMap,
  createMap,
  putFloorplan,
  searchUserMaps,
};

export const uploadingData = (state) => state?.studio?.isUploading;

export const mapDetails = (state) => state?.studio?.mapDetails;

export const studioLog = (state) => state?.studio?.studioLog;

export const stepperMapDetails = (state) => state?.studio.stepperMapDetails;

export const draftMapData = (state) => state?.studio.draftMapData;

export const defaultMaps = (state) => state.studio.defaultMaps;

export const isMapDetailsExist = (state) => {
  return !isEmpty(state.studio.stepperMapDetails);
};

export default studioSlice.reducer;
