import React from 'react';

const Logo = (props) => {
  var imgSrc = "/arway_logo.svg";
  return (
    <div className='topbar_logo'>
      <img alt="Logo" src={imgSrc} style={props.style} />
      <span>{props.title || "ARway"}</span>
    </div>
  )
};

export default Logo;
