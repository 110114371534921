import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
import { SetVisible } from "../../commands/SetVisible";
import { 
    UIButton,
    UIPanel,
    UIText,
    UITextHeader,
    UIObjectInfoCard,
    UIImage,
    UICol
} from "../ui.lib";

import alertIcon from '../../../../assets/svgs/scenes/alert.svg';
import { getObjectByUserDataProperty } from "../../threeUtils/TransformConversions";

class SidebarContentFloorplan extends EventEmitter{
    
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.dom = null;

        this.contentType = 'floorplans';

        this.initUI();

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
    }

    initUI = () => {
        // create invisible Input
        this.form = document.createElement('form');
        this.form.style.display = 'none';
        document.body.appendChild(this.form);

        this.fileInput = document.createElement('input');
        this.fileInput.type =  'file';
        this.fileInput.id = "FloorplanUploader";
        this.fileInput.accept = "image/jpeg, image/png";
        this.fileInput.multiple = false;

        this.fileInput.addEventListener('change', () => {
            this.editor.loader.loadFiles([...this.fileInput.files], 'floorplans');
            this.form.reset();
        });

        this.form.appendChild(this.fileInput);

        this.uiMyContent = new UIPanel();
        this.header = new UITextHeader(undefined, 'My Uploads').addClass('ws_uiTextHeader--paddedBottom');
        this.uiMyContent.addChild(this.header);

        this.dom = this.uiMyContent;
    }

    emptyContent = () => {
        var child = this.uiMyContent.dom.lastChild; 
        while (child) {
            if(child === this.header.dom) break;
            this.uiMyContent.dom.removeChild(child);
            child = this.uiMyContent.dom.lastChild;
        }
    }

    updateUIData = () => {
        if(this.editor.jsonFloors.length === 0) {
            this.emptyContent();
            const uiHeaderMsg = document.createElement('div')
            uiHeaderMsg.classList.add('ws_uiNDText');
            uiHeaderMsg.textContent = `Align the floor plan to your physical space using the ARway app. 
            View the floor plan in the studio and use it for accurate content placement.`
            this.uiMyContent.dom.appendChild(uiHeaderMsg);

            //Upload Button
            const uiUploadBtn = new UIButton('Upload').onClick(() => {
                if(this.editor.storageExceeds()) {
                    this.editor.callbacks.generateAlert({
                        msg: 'plan storage exceeded, please upgrade your storage to continue!',
                        alertType: 'danger'
                    })
                } else {
                    this.fileInput.click();
                }
            })
            uiUploadBtn.setStyle('marginBottom', '0px');
            if(this.editor.storageExceeds()) {
                uiUploadBtn.addClass('ws_uiButton--grayedButton');
            }
            this.uiMyContent.addChild(uiUploadBtn);

            // No Data Card!
            const uiNoDataCard = document.createElement('div');
            uiNoDataCard.className = 'ws_uiNoDataCard';
            const uiHeader = document.createElement('div')
            uiHeader.classList.add('ws_uiNDHeader');
            uiHeader.textContent = 'No Uploads Yet';
            const uiSubText = document.createElement('div')
            uiSubText.classList.add('ws_uiNDSubText');
            uiSubText.textContent = 'Your recently uploaded floor plans will appear here.';
            uiNoDataCard.appendChild(uiHeader);
            uiNoDataCard.appendChild(uiSubText);
            this.uiMyContent.dom.appendChild(uiNoDataCard);

            // No Data Card!
            const uiSampleFloorCard = document.createElement('div');
            uiSampleFloorCard.className = 'ws_uiSampleFloorsCard';
            const uiSampleInfoText = document.createElement('div')
            uiSampleInfoText.classList.add('ws_uiSampleFloorsCard--infoText');
            uiSampleInfoText.innerHTML = 'Not have a floor plan yet. <br/> Do you want to test with our sample ?';
            const uiChooseBtn = document.createElement('div')
            uiChooseBtn.classList.add('ws_uiSampleFloorsCard--choseBtn');
            uiChooseBtn.textContent = 'Choose a sample file';
            uiChooseBtn.addEventListener('click', (e) => this.editor.onToggleConnectorInfoModals('SampleFloorplans', true, {mapId: this.editor.activeMap}))
            uiSampleFloorCard.appendChild(uiSampleInfoText);
            uiSampleFloorCard.appendChild(uiChooseBtn);
            this.uiMyContent.dom.appendChild(uiSampleFloorCard);

        } else {
            const uiCardsRow = new UIPanel();
            uiCardsRow.addClass('ws_uiCardsRowFloat');
            this.emptyContent();
            for(const floorObj of this.editor.jsonFloors) {
                const uiFloorImgCOnt = new UIObjectInfoCard(this.editor, floorObj, getObjectByUserDataProperty(this.editor.scene, 'id', floorObj.id), this.onFloorSelect, "FLOORPLAN");
                uiCardsRow.addChild(uiFloorImgCOnt)
            }
            this.uiMyContent.addChild(uiCardsRow);

            const uifinishRoute = new UICol().addClass('ws_uiReadInfoCard');
            const uiIcon = new UIImage(alertIcon, '32px', '32px').addClass('ws_uiReadInfoCard--icon');
            const uiTextMsg = new UIText(undefined, 'Note: Open the ARway App to align the floor plan or reposition pegs').addClass('ws_uiReadInfoCard--textSpan');
            uifinishRoute.addChild(uiIcon);
            uifinishRoute.addChild(uiTextMsg);
            this.uiMyContent.addChild(uifinishRoute);
        }
    }

    onFloorSelect = (content, eventType) => {
        const object = getObjectByUserDataProperty(this.editor.scene, 'id', content.id);
        if(object) {
            if(eventType === 2) {
                this.editor.onCommand(new SetVisible(this.editor, object, !object.visible, false, false))
            } else {
                this.editor.select(object);
            }
        }
    }

    refreshUI = () => {
        this.updateUIData();
    }

    onToggleMenuActive = () => {

    }

    unload = () => {
        this.emptyContent();
    }
}

export { SidebarContentFloorplan };