import { Command } from './Command';

function SetMaterialColor( editor, object, newValue) {
    Command.call(this, editor);

    this.type = 'SetMaterialColor';
    this.name = `Set Material Color`;
    this.updatable = true;

    this.object = object;
    this.oldValue = object !== undefined && object.material ? object.material.color.getHex() : undefined;
    this.newValue = newValue;
}

SetMaterialColor.prototype = {
    execute: function() {
        this.object.material.color.setHex(this.newValue);
        this.object.material.needsUpdate = true;
        this.editor.trigger('objectChanged', [this.object]);
    },

    undo: function() {
        this.object.material.color.setHex(this.oldValue);
        this.object.material.needsUpdate = true;
        this.editor.trigger('objectChanged', [this.object]);
    },

    update: function(command) {
        this.newValue = command.newValue;
    },

    toJSON: function() {
        var output = Command.prototype.toJSON.call(this);

        output.objectUuid = this.object.uuid;
        output.oldValue = this.oldValue;
        output.newValue = this.newValue;

        return output;
    },
}

export { SetMaterialColor };