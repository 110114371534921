import React from 'react';
import Carousel from 'react-material-ui-carousel';
import {
    Backdrop, Box, 
    Modal, Fade, 
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

//Images
import CIM11Icon from '../../../../assets/pngs/studio/ConnectorPins/EscOB11.png';
import CIM12Icon from '../../../../assets/pngs/studio/ConnectorPins/EscOB12.png';
import { setOnboardingFlag } from '../../2d-editor/threeUtils/Connectors/ConnectorUtils';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

export default function EscalatorOnboardingModal({openModal, onCloseModal, modalData}) {

    const handleClose = () => {
        setOnboardingFlag('escalatorOnboarding', true);
        onCloseModal();
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={handleClose}/>
                        <div className='obModal'>
                        <Carousel autoPlay={false} height={480}>
                            <div className='obModal--obCard'>
                                <h4 className='obModal--obCard--title'>How to add, pair, and group escalator pins</h4>
                                <span className='obModal--obCard--header2'><strong>Step 1:</strong> Choose the escalator type then position the first pin at one end of the escalator. Fill in the necessary details for this escalator.</span>
                                <span className='obModal--obCard--header2'><strong>Step 2:</strong> Place the connecting pin at the opposite end of the escalator to establish the connection. Save this pair to ensure the proper linkage between the escalator ends.</span>
                                <img src={CIM11Icon} alt={CIM11Icon} draggable="false" className='obModal--obCard--img90'/>
                            </div>
                            <div className='obModal--obCard'>
                                <h4 className='obModal--obCard--title'>Grouping Pairs</h4>
                                <span className='obModal--obCard--header obModal--obCard--m10'>If you have several escalators in one area spanning multiple floors, we reccommend grouping your escalator pairs together. </span>
                                <img src={CIM12Icon} alt={CIM12Icon} draggable="false" className='obModal--obCard--img90'/>
                            </div>
                        </Carousel>
                            
                        </div>
                        <button className='obModal--btn' onClick={handleClose}>Place {modalData?.type === 'stairs' ? 'Stair' : 'Escalator'} Pin</button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}