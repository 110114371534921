import React from 'react';
import { TextField, Switch } from '@mui/material';


export default function SwitchInput({
    id,
    value,
    toggleValue,
    label,
    subLabel,
    toggleLabel,
    handleOnChange,
    handleOnToggle,
    inputType,
    enabled,
    linkedToggle,
    limitKeys
}) {

    const exceptThisSymbols = ["e", "E", "+", "-", "."];

    return (
        <div className='pInput'>
            <span className={`pInput__label pInput__label--flex ${enabled && 'pInput__label--disabled'}`}>
                <div>
                    {label}
                    { subLabel && <span className='pInput__subLabel'>({subLabel})</span>}
                </div>
                <div>
                    {toggleLabel && <span className='pInput__subToggleLabel'>{toggleLabel}</span>}
                    <Switch 
                        checked={toggleValue}
                        onChange={handleOnToggle}
                        color='accentBlue'
                        disabled={enabled}
                    />
                </div>
            </span>
            <TextField
                type={inputType}
                value={value?.toString()}
                id={id}
                onChange={handleOnChange}
                size="small"
                className={`pInput__input`}
                autoComplete="off"
                disabled={linkedToggle ? (enabled || !toggleValue) : enabled}
                onKeyDown={e => limitKeys !== undefined && exceptThisSymbols.includes(e.key) && e.preventDefault()}
            />
        </div>
    )
}
