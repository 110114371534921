import CustomModal from "../common/CustomModal";

const DefaultIAPModal = ({ modalSetter, children, saveDefaultIAP }) => {
  return (
    <div>
      <CustomModal
        openModal={modalSetter.defaultIAPModal}
        onCloseModal={() => {
          modalSetter.setDefaultIAPModal(false);
          if (!modalSetter.isPrimaryAccess) modalSetter.setDefaultIAP(false);
        }}
        onSuccessModal={() => {
          saveDefaultIAP();
        }}
        modalHeader={`Choose Default Image Access Point`}
        textSuccess="Confirm"
        textCancel="Cancel"
        successDisabled={modalSetter.successDisabled}
        modalStyle={{ marginLeft: "5vw", width: "85%" }}
      >
        {children}
      </CustomModal>
    </div>
  );
};

export default DefaultIAPModal;
