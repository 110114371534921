import React from "react";
import "./style.css";

const Success = () => {
  const handleClick = () => {
    window.location.replace("https://maps-studio.nextechar.com/auth/login");
  };
  return (
    <div className="success-box-container">
      <div className="success-box">
        <h1 className="success-header">Success!</h1>
        <p className="success-body">
          Your email has been verified! Head back to the app or 
          download from the app store to get started!</p>
        <div className="success-footer">
          <button className="success-footer-button" onClick={handleClick}>
            Open ARWay Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default Success;
