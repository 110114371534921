import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Container } from "@mui/material";
import { isEqual } from "lodash";

import { ChevronLeft, ChevronRight } from "react-feather";

import { getReportedUsers } from "../../../../features/admin/userreports/userReportSlice";
import { resetPageNumber } from "../../../../features/admin/mapreports/mapReportSlice";

import UserTable from "./UserTable";
import Loader from "../../../common/loader";

const UserReportsHome = () => {
  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportedUsers, setReportedUsers] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  const pageSize = 9;

  const reportedUserList = useSelector(
    (state) => state.userreports.reportedUsers
  );
  const totalPages = useSelector((state) => state.userreports.totalPages);

  useEffect(() => {
    setFetching(true);
    fetchReportedUsers(pageNumber);
    dispatch(resetPageNumber());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reportedUserList && !isEqual(reportedUserList, reportedUsers)) {
      setFetching(false);
      setLoading(false);
      setReportedUsers(reportedUserList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportedUserList]);

  useEffect(() => {
    fetchReportedUsers(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  const fetchReportedUsers = (pageNumber) => {
    var reqObj = {
      pageNumber,
      pageSize,
    };
    dispatch(getReportedUsers(reqObj));
  };

  const handlePageChange = (pageType) => {
    if (pageType === 1) {
      // increase
      if (pageNumber < totalPages) {
        setLoading(true);
        setPageNumber(pageNumber + 1);
      }
    } else {
      // decrease
      if (pageNumber > 1) {
        setLoading(true);
        setPageNumber(pageNumber - 1);
      }
    }
  };

  const renderUserTable = () => {
    if ((fetching && reportedUsers.length === 0) || loading) {
      return (
        <Loader loaderText="Fetching Users..." height="700px" width="100%" />
      );
    }
    if (reportedUsers.length === 0) {
      return <h2>No Reports Available!</h2>;
    }

    return <UserTable users={reportedUsers} />;
  };

  return (
    <div style={{ padding: "40px" }}>
      <Container maxWidth={false}>
        <div className="pageWrapper">
          <h4>Users</h4>
          <div>
            {pageNumber > 1 && (
              <ChevronLeft
                className="pageBar"
                onClick={() => handlePageChange(2)}
              />
            )}
            <span className="pageCount">
              {pageNumber} / {totalPages}
            </span>
            <ChevronRight
              className="pageBar"
              style={{
                visibility: totalPages !== pageNumber ? "visible" : "hidden",
              }}
              onClick={() => handlePageChange(1)}
            />
          </div>
        </div>
        <Box className="boxWrapper">{renderUserTable()}</Box>
      </Container>
    </div>
  );
};

export default UserReportsHome;
