import React, { useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
// import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useDropDown from "./useDropDown";

export default function Dropdown({
  defaultVal,
  list,
  setvalue,
  venueCreate = false,
  disabled = false,
  width = null,
}) {
  // console.log(defaultVal)
  const invokeDefault = useRef(false);

  const [data, bindData] = useDropDown(
    list[
      defaultVal ? list.indexOf(list.find((l) => l.value === defaultVal)) : 0
    ].value
  );
  React.useEffect(() => {
    if (!venueCreate) {
      if(setvalue) {
        if(invokeDefault.current) {
          setvalue(data)
        } else {
          invokeDefault.current = true;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  return (
    <div>
      <FormControl sx={{ minWidth: width ? width : 260 }}>
        <Select
          {...bindData}
          disabled={disabled}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          onBlur={() => {
            if (venueCreate) {
              setvalue(data);
            }
          }}
          // onMouseLeave={()=>{
          //   setvalue(data)
          // }}
          className="dropdownMenuItem"
          sx={{
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #484850",
            }
          }}
        >
          {list.map((item) => (
            <MenuItem value={item.value} key={item.value} className="dropdownMenuItem">{item.label}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
