import React, { useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
import {
  Grid,
  FormControl,
  MenuItem,
  Select,
  Box
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, uniqBy } from 'lodash'
import { makeStyles } from "@mui/styles";
import { 
  getMapsList 
} from "../../../../features/user/analytics/analyticsSlice";

const useStyles = makeStyles(() => ({
  iconWrapper: {
    top: '12% !important',
    marginRight: '20px',
  },
  selectWrapper: {
    paddingLeft: '20px !important',
  },
}));

const MapSelection = ( {setMap, setActiveTab} ) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [ options, setOptions ] = useState([]);
  const [ pageNumber, setPageNumber ] = useState(1);
  const pageSize = 10;

  const mapList = useSelector((state) => state.analytics.mapsList);
  const selectedMap = useSelector((state)=> state.analytics.selectedMap);
  const totalResults = useSelector((state)=> state.analytics.totalResults);

  useEffect(() => {
    dispatch(getMapsList({pageNumber, isloadMore: false, pageSize}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(mapList && options.length <= totalResults+1) {
      setOptions(options => uniqBy([...options, ...mapList], 'mapId'))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapList]);

  useEffect(() => {
    dispatch(getMapsList({pageNumber, isloadMore: true, pageSize}));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);


  const handleMapChange = (e) => {
    setActiveTab()
    setMap(options.find((m) => m.mapId === e.target.value))
  }

  const loadMoreItems = (e) => {
    const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
    if(bottom && (pageNumber * pageSize) < totalResults){
      // add your code here
      setPageNumber(page => page + 1);
    }
  }

  return (
    <div className="analyticshome__contentRow">
      <Grid container={true} spacing={2} style={{ padding: '10px 56px 10px 40px', backgroundColor: "#353E5A" }}>
        <Grid item xs={3} style={{ paddingTop: '12px', paddingLeft: '30px', color: 'white' }} >
          Map Selection
        </Grid>
        <Grid item xs={9} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
          <Box sx={{ minWidth: 120 }} bgcolor="white" borderRadius={'6px'}>
            <FormControl fullWidth>
              <Select
                style={{ fontFamily: 'poppins', color: '#2A4DC2'}}
                value={selectedMap.mapId}
                onChange={handleMapChange}
                variant="standard"
                fullWidth
                disableUnderline
                IconComponent={ChevronDown}
                classes={{
                  icon: classes.iconWrapper,
                  select: classes.selectWrapper
                }}
                MenuProps={{
                  PaperProps: {
                    onScroll: loadMoreItems,
                  },
                  style: {
                    maxHeight: 400,
                  },
                }}
              >
                {isEmpty(options) ? null : options.map((option, index) => (
                  <MenuItem key={`${option.mapId}-${index}`} value={option.mapId} style={{ fontFamily: 'poppins', color: '#2A4DC2' }}>
                    {option.mapName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default MapSelection;
