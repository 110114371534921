import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import {
    Grid, TextField, Box
} from "@mui/material";
import Autocomplete from '@mui/material/Autocomplete'
import {
    setActiveAccount, searchAccountsList,
    clearSearchedAccounts, getAccountsList
} from '../../../../features/admin/analytics/adminAnalyticsSlice';
import { uniqBy } from 'lodash';

const AccountSelection = () => {

    const dispatch = useDispatch();

    const accountList = useSelector((state) => state.adminanalytics.accountList);
    const searchedAccountList = useSelector((state) => state.adminanalytics.searchedAccountList);
    const selectedAccount = useSelector((state) => state.adminanalytics.activeAccount);
    const totalResults = useSelector((state) => state.adminanalytics.totalAccounts);

    const [inputText, setInputText] = useState("");
    const [selectedValue, setSelectedValue] = useState("");
    const [options, setOptions] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [position, setPosition] = useState(0);

    const listRef = useRef();
    const pageSize = 10;

    useEffect(() => {
        dispatch(getAccountsList({ pageNumber, isloadMore: false, pageSize }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (accountList && options.length <= totalResults + 1) {
            setOptions(options => uniqBy([...options, ...accountList], 'id'))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountList]);

    useEffect(() => {
        dispatch(getAccountsList({ pageNumber, isloadMore: true, pageSize }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    useEffect(() => {
        setSelectedValue(selectedAccount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);

    useEffect(() => {
        if (searchedAccountList.length && inputText.length) {
            setOptions(searchedAccountList)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchedAccountList]);

    useEffect(() => {
        if (position && listRef.current) listRef.current.scrollTop = position - listRef.current.offsetHeight;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    })

    useEffect(() => {
        if (inputText.length >= 3 && inputText !== 'All Accounts') {
            var filter = /^([\w,:\s@.\-_]*)$/;
            let filteredSearchText = [...inputText].map(c => filter.test(c) ? c : '').join('');
            dispatch(searchAccountsList(filteredSearchText));
        } else if (inputText.length === 0) {
            dispatch(clearSearchedAccounts())
            setOptions(accountList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputText]);

    const loadMoreItems = (e) => {
        const scrollPosition = e.target.scrollTop + e.target.clientHeight;
        // const bottom = e.target.scrollHeight === e.target.scrollTop + e.target.clientHeight;
        const bottom = e.target.scrollHeight - scrollPosition <= 1;
        console.log("SCROLL", bottom);
        if (bottom && (pageNumber * pageSize) < totalResults) {
            setPosition(scrollPosition);
            setPageNumber(page => page + 1);
        }
    }
    return (
        <div className="adanhome__selectRow">
            <Grid container={true} spacing={2} style={{ padding: '8px 56px 8px 40px', backgroundColor: "#353E5A" }}>
                <Grid item xs={3} style={{ paddingTop: '24px', paddingLeft: '30px', color: 'white' }} >
                    Account Selection
                </Grid>
                <Grid item xs={9} style={{ paddingTop: '8px', paddingBottom: '8px' }}>
                    <Box sx={{ minWidth: 120 }} bgcolor="white" borderRadius={'6px'}>
                        <Autocomplete
                            options={options}
                            autoHighlight
                            noOptionsText="No matching account found"
                            getOptionLabel={(option) => option?.accountName || ''}
                            disableClearable={!selectedAccount || selectedAccount.id === 'allAccounts'}
                            value={selectedValue}
                            inputValue={inputText}
                            onChange={(e, newValue, reason) => {
                                if (reason === 'clear') {
                                    dispatch(setActiveAccount(accountList[0]));
                                    setPageNumber(1);
                                } else {
                                    dispatch(setActiveAccount(newValue));
                                }
                            }}
                            onInputChange={(e, newValue) => {
                                setInputText(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off',
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === 'Backspace') {
                                            setInputText('');
                                        }
                                    }}
                                />
                            )}
                            ListboxProps={{
                                ref: listRef,
                                onScroll: loadMoreItems,
                                style: {
                                    maxHeight: 380,
                                },
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div>
    );
};

export default AccountSelection;