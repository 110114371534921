import { Box } from "@mui/material";
import DatePicker from "../../../../../_utils/DatePicker";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import { getMapActivation } from "../../../../../../features/user/analytics/analyticsSlice";
import { lastTEDays } from "../../../../../_utils/DateUtils";
import { isEmpty, isNull } from "lodash";
import RenderBarChart from "../../Render/RenderBarChart";

const MapActivation = () => {
  const mapActivation = useSelector((state) => state.analytics.mapActivation);
  const [labels, setLabels] = useState(null);
  const [count, setCounts] = useState(null);

  const [dateObj, setDateObj] = useState(lastTEDays);
  const mapActObj = [
    { key: null, label: "" },
    { key: null, label: "" },
    { key: "qr", label: "QR Code" },
    { key: "iap", label: "IAP" },
    { key: "mapCode", label: "Map Code" },
    { key: null, label: "" },
    { key: null, label: "" },
  ];

  useEffect(() => {
    if (!isEmpty(mapActivation)) {
      setLabels(mapActObj.map((obj) => obj["label"]));
      setCounts(
        mapActObj.map((obj) => {
          if (obj["key"] === null) {
            return 0;
          } else {
            return mapActivation[obj["key"]];
          }
        })
      );
    } else {
      setLabels(null);
      setCounts(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapActivation]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMapActivation({ ...dateObj }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col-9">
            <div className="analyticshome__anCard--contentRow--header">
              Access Point Scans
            </div>
          </div>
          <div className="col-3" style={{ paddingRight: "2.5rem" }}>
            <Box component="span" className="analyticshome__anCard--filterBox">
              <span style={{ color: "#858993" }}>Date:</span>
              <DatePicker
                cb={(dateObj) => {
                  setDateObj(dateObj);
                  dispatch(
                    getMapActivation({
                      ...dateObj,
                    })
                  );
                }}
              />
            </Box>
          </div>
        </div>
        <hr />
        {isNull(labels) || isNull(count) ? (
          <h5 className="text-center"> No Data available </h5>
        ) : (
          RenderBarChart(labels, count, dateObj, "Number of times Activated")
        )}
      </div>
    </div>
  );
};

export default MapActivation;
