import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../../api';
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";

const getUsers = createAsyncThunk('impersonate/getUsers', async ( { pageNumber, pageSize }, { dispatch}) => {
    try{
        const response = await Server.get(`/v1/user?limit=${pageSize}&page=${pageNumber}`);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const searchUsers = createAsyncThunk('impersonate/searchUsers', async (searchString, { dispatch}) => {
    try{
        const response = await Server.get(`/v1/user?limit=10&page=1&email=${encodeURIComponent(searchString)}`);
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const impersonateUser = createAsyncThunk('impersonate/impersonateUser', async ( params, { dispatch}) => {
    try{
        const response = await Server.get('/v1/auth/profile');
        return response.data;
    } catch(e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
}); 

const initialState = {
    users:[],
    searchedUsers: [],
    totalPages: null,
    currentUser: null,
    impersonateLog: null,
};

const impersonateSlice = createSlice({
    name: 'impersonate',
    initialState,
    reducers: {
        initImpersonation: (state, action) => {
            return {...state}
        },
        clearSearchedUsers: (state, action) => {
            return {...state, searchedUsers: []}
        }
    },
    extraReducers: {
        'common/resetState': () => {
            return{...initialState}
        },
        [getUsers.fulfilled]: (state, {payload}) => {
            return({
                ...state,
                users: payload.data,
                searchedUsers: [],
                totalPages: payload.totalPages,
                impersonateLog: null
            })
        },
        [searchUsers.fulfilled]: (state, {payload}) => {
            return({
                ...state,
                searchedUsers: payload.data,
                impersonateLog: null
            })
        },
        [impersonateUser.fulfilled]: (state, {payload}) => {
            sessionStorage.setItem('impersonatedUserData', JSON.stringify(payload.user));
            return({
                ...state,
                currentUser: payload.user,
                impersonateLog: null
            })
        }
    }
});

export {
    getUsers,
    impersonateUser,
    searchUsers
};

export const { initImpersonation, clearSearchedUsers } = impersonateSlice.actions;

export default impersonateSlice.reducer;