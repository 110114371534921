import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from "../../api";
import { HandleExceptionWithSecuredCatch } from "../CombineCatch";

const createLocationPin = createAsyncThunk(
  "importMaps/createLocationPin",
  async (params, { dispatch }) => {
    try {
      const response = await Server.post(`/v1/map/locationpin/`, params);
      return response.data;
    } catch (e) {
      return dispatch(HandleExceptionWithSecuredCatch(e));
    }
  }
);

const getLocationPins = createAsyncThunk(
    "importMaps/getLocationPins",
    async (mapId, { dispatch }) => {
      try {
        const response = await Server.get(`/v1/map/locationpin/${mapId}`);
        return response.data;
      } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  );

const searchLocationpins = createAsyncThunk(
    "importMaps/searchLocationpins",
    async ({ mapId, pageNumber, searchText, mapType }, { dispatch }) => {
      const url = `/v1/map/locationpin/${mapId}?limit=10&page=${pageNumber}&q=${encodeURIComponent(searchText)}&type=${mapType}`
      try {
        const response = await Server.get(url);
        return response.data;
      } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
      }
    }
  );

const initialState = {
  log: null,
  isLoading: false,
  locationPins: [],
  searchedLocationPins:[]
};

const locationSlice = createSlice({
  name: "building",
  initialState,
  reducers: {
    resetLocationPins: () => {
      return { ...initialState };
    },
    clearSearchedLocationPins: (state) => {
      return { ...state, searchedLocationPins: null };
    }
  },
  extraReducers: {
    "common/resetState": () => {
      return { ...initialState };
    },
    [createLocationPin.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        log: payload.data.message,
        isLoading: false,
      };
    },
    [getLocationPins.fulfilled]: (state, { payload }) => {
        return {
          ...state,
          locationPins: payload.data,
          log: payload.data.message,
          isLoading: false,
        };
      },
      [searchLocationpins.fulfilled]: (state, { payload }) => {
        return {
          ...state,
          searchedLocationPins:payload.data,
          log: payload.data.message,
          isLoading: false,
        };
      },
  },
});

export { createLocationPin, getLocationPins, searchLocationpins  };

export const { resetLocationPins, clearSearchedLocationPins } = locationSlice.actions;

export default locationSlice.reducer;
