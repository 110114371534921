import React from "react";
import Loader from "../../../common/loader";

function Loading({ isVenue }) {
  return (
    <div
      className="d-flex align-items-center flex-column"
      style={{ height: "100%" }}
    >
      <h2>{isVenue ? "Updating Venue" : "Creating Venue Map"}</h2>
      <Loader loaderText={isVenue ? "Updating Venue" : "Creating Venue Map"} height="100%" width="100%" />
      {/* <p className="import-maps-subtext pt-4">
        Importing Maps...
      </p> */}
    </div>
  );
}

export default Loading;
