import { RemoveObject } from "../commands/RemoveObject";
import { AddObject } from "../commands/AddObject";
import { SetVisible } from "../commands/SetVisible";
import EventEmitter from "./EventEmitter";


class History extends EventEmitter {
    constructor(editor) {
        super();
        
        this.editor = editor;

        //Arrays
        this.undos = [];
        this.redos = [];

        this.lastCmdTime = new Date();
        this.cmdIdCnt = 0;
        this.updated = false;

        this.restrictUndos = ["wayPointGroup", "Guided Tour", "qrAnchor", "imageAccessPoint", 'floorplan'];

        //Emitter listeners!
        this.editor.on('initiateVersionSwitch', () => this.clear());
        this.editor.on('initiateMapSwitch', () => this.clear());
        this.editor.on('clearAndReset', () => this.clear());
        this.editor.on('toggleUpdateState', (bFlag) => this.updated = bFlag);

    }
    
    execute = (cmd, optName = undefined) => {

        if(optName === 'RESTRICT_UNDO' || cmd instanceof AddObject || cmd instanceof RemoveObject) {
            cmd.execute();
            return;
        }

        if(cmd instanceof SetVisible && optName === 'VIEW_OPTION') {
            cmd.execute();
            return;
        }

        this.updated = true;

        var lastCmd = this.undos[ this.undos.length - 1 ];

        var timeDifference = new Date().getTime() - this.lastCmdTime.getTime();

        var isUpdatableCmd = lastCmd && 
            lastCmd.updatable &&
            cmd.updatable &&
            lastCmd.type === cmd.type &&
            lastCmd.object === cmd.object &&
            lastCmd.attribute === cmd.attribute;

        if(isUpdatableCmd && timeDifference < 500) {
            lastCmd.update(cmd);
            cmd = lastCmd;
        } else {
            if(this.undos.length === 10) {
                this.undos.shift();
            }
            this.undos.push(cmd);
            cmd.id = ++this.cmdIdCnt;
        }

        cmd.name = (optName !== undefined) ? optName : cmd.name;
        cmd.execute();

        cmd.json = cmd.toJSON();

        this.lastCmdTime = new Date();

        //clear redo and dispatch history change
        this.redos = [];
        this.editor.trigger('historyChanged', [cmd]);

    }

    undo = () => {

        var cmd = undefined;

        if(this.undos.length > 0) {
            cmd = this.undos.pop();
        }

        if(cmd !== undefined) {
            cmd.undo();
            if(this.redos.length === 10) {
                this.redos.shift();
            }
            this.redos.push(cmd);
            this.editor.trigger('historyChanged', [cmd]);
        }
    
        return cmd;
    }

    redo = () => {

        var cmd = undefined;

        if(this.redos.length > 0) {
            cmd = this.redos.pop();
        }

        if(cmd !== undefined) {
            cmd.execute();
            if(this.undos.length === 10) {
                this.undos.shift();
            }
            this.undos.push(cmd);
            this.editor.trigger('historyChanged', [cmd]);
        }

        return cmd;
    }

    clear() {

		this.undos = [];
		this.redos = [];
		this.cmdIdCnt = 0;
        this.updated = false;

        this.editor.trigger('historyChanged');
	}

    undoAllCmds = (undoCount) => {
        while(undoCount > 0) {
            let cmd = undefined;
            --undoCount;
            if(this.undos.length > 0) {
                cmd = this.undos.pop();
            }
            if(cmd !== undefined) {
                cmd.undo();
                this.editor.trigger('historyChanged', [cmd]);
            }
        }
    }

}

export { History };