import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AudioPlayIcon } from "../../../../assets/svgs/AudioPlay.svg";
import { ReactComponent as AudioPauseIcon } from "../../../../assets/svgs/AudioPause.svg";
import { ReactComponent as MoreVerticalIcon } from "../../../../assets/svgs/MoreVertical.svg";
//import { ReactComponent as RenameIcon } from "../../../../assets/svgs/RenameIcon.svg";
//import { ReactComponent as ReplaceIcon } from "../../../../assets/svgs/ReplaceIcon.svg";
import { ReactComponent as ModelIcon } from "../../../../assets/svgs/model.svg";
import { ReactComponent as DeleteIcon } from "../../../../assets/svgs/DeleteIcon.svg";
import { Alert, Fade, Popper, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteContents,
  totalPages,
  contentData,
  currentPage,
  getContents,
} from "../../../../features/content/contentLibrarySlice";
import DeleteContentModal from "./DeleteContentModal";
import { convertToNamedDate } from "../../../_utils/dateFormatter";
import ARWayImage from "../../../common/CO/ARWayImage";
import { getPlaceholderThumbnail } from "../../../_utils/AssignPlaceholder";
import LazyLoad from "react-lazyload";
//import ThumbnailModal from "./ThumbnailModal";
//import ModelViewer from "../../../common/ModalViwerFor3d/ModalViewer";
//import ErrorBoundary from "./ErrorBoundary";
//import ModelPreviewModal from "./ModelPreviewModal";

export const bytesToSize = (_byte, decimals = 2) => {
  if (!_byte) return `0 KB`;
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(_byte) / Math.log(k));
  return parseFloat((_byte / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

const ContentItem = ({ item, type, handleItemClick, currentTab }) => {
  const dispatch = useDispatch();
  const totalPageNumber = useSelector(totalPages);
  const currentPageNumber = useSelector(currentPage);
  const dataCount = useSelector(contentData);
  const [contentAudio, setContentAudio] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const moreRefEl = useRef(null);
  const imgRef = useRef(null);

  const handleMoreClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    if (item === null) return null;
  }, [item]);

  const handleAudioPlayPause = () => {
    let audio;
    if (!contentAudio) {
      audio = new Audio(item?.path);
      audio.crossOrigin = 'anonymous';
      setContentAudio(audio);
      audio.play();
      audio.addEventListener("ended", () => setPlaying(false));
      setPlaying((prevState) => !prevState);
    } else {
      if (playing === true) contentAudio.pause();
      if (playing === false) contentAudio.play();
      setPlaying((prevState) => !prevState);
    }
  };

  const handleDeleteSuccess = () => {
    setOpenDeleteModal(false);
    /* console.log({
      currentPageNumber,
      totalPageNumber,
      dataCount: dataCount.length,
    }); */
    let params = {};
    if (currentTab !== "all") params.type = currentTab;
    params.page =
      currentPageNumber === totalPageNumber && dataCount.length === 1
        ? currentPageNumber - 2
        : currentPageNumber - 1;
    // console.log(params, "params");
    dispatch(getContents({ ...params }));
  };

  const handleDeleteContent = () => {
    if (item.path)
      dispatch(
        deleteContents({
          path: item?.path,
          onSuccess: handleDeleteSuccess,
          onError: () => setSnackbarOpen(true),
        })
      );
  };

  const handleDelete = () => setOpenDeleteModal(true);

  const handleClickOutside = (e) => {
    if (!moreRefEl?.current?.contains(e.target)) {
      setOpen(false);
    }
  };

  const handleSnackbarClose = () => setSnackbarOpen(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="content-item">
      <div className="content-thumb" id="div-contentCard">
        {(type.toLowerCase() === "images" ||
          type.toLowerCase() === "floorplans") && (
          <div
            className="content-thumb-image"
            style={{
              cursor: "pointer",
            }}
            onClick={() => handleItemClick(item)}
          >
            {/* <img src={item?.path} alt="" /> */}
            <ARWayImage
              src={item?.path}
              alt=""
              ref={imgRef}
              onError={() => (imgRef.current.src = getPlaceholderThumbnail(1))}
            />
          </div>
        )}
        {type.toLowerCase() === "videos" && (
          <LazyLoad once={true}>
            <div className="content-thumb-image">
              <video
                className="ws_uiVideoCont"
                controls="controls"
                crossOrigin="anonymous"
                src={item?.path}
              />
            </div>
          </LazyLoad>
        )}
        {type.toLowerCase() === "audios" && (
          <div
            className="content-thumb-image"
            style={{
              backgroundImage: `url(${item?.thumbnail})`,
            }}
          >
            <button
              className={`content-thumb-audio ${!playing ? "play" : "pause"}`}
              onClick={handleAudioPlayPause}
            >
              {!playing ? <AudioPlayIcon /> : <AudioPauseIcon />}
            </button>
          </div>
        )}
        {type.toLowerCase() === "models" && (
          <div
            className="content-thumb-model model-background"
            onClick={() => {
              handleItemClick(item);
            }}
            style={{
              backgroundImage: `url(${item?.thumbnail})`,
              cursor: "pointer",
            }}
          >
            {!item?.thumbnail && <ModelIcon />}
          </div>
        )}
      </div>
      <div className="content-desc">
        <button className="desc-more" onClick={handleMoreClick} id="button-moreOptns">
          <MoreVerticalIcon />
        </button>
        <Popper
          open={open}
          anchorEl={anchorEl}
          placement={"right-start"}
          transition
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className="content-more-options" ref={moreRefEl}>
                <ul>
                  {/* <li>
                    <button>
                      <RenameIcon /> Rename
                    </button>
                  </li>
                  <li>
                    <button>
                      <ReplaceIcon /> Replace
                    </button>
                  </li> */}
                  <li>
                    <button onClick={handleDelete} id="button-deleteContent">
                      <DeleteIcon /> Delete
                    </button>
                  </li>
                </ul>
              </div>
            </Fade>
          )}
        </Popper>
        <h1 title={item?.name || item?.fileName || "Unknown File Name"} id="h1-contentName">
          {item?.name || item?.fileName || "Unknown File Name"}
        </h1>
        <h4 title={item?.fileName || "Unknown File Name"} id="h4-contentName">
          {item?.fileName || "Unknown File Name"}
        </h4>
        <p>
          <span>
            {item?.contentType === "models" ? "3D Model" : item?.contentType}
          </span>
          | {bytesToSize(item?.size)}
        </p>
        <p>
          Last Modified:{" "}
          {item.modifiedOn ? convertToNamedDate(item.modifiedOn) : "Never"}
        </p>
      </div>
      <DeleteContentModal
        type={type}
        item={item}
        open={openDeleteModal}
        handleClose={() => setOpenDeleteModal((prevState) => !prevState)}
        handleDeleteClick={handleDeleteContent}
      />
      {/* <ThumbnailModal
        item={item}
        open={openThumbnailModal}
        handleClose={() => setOpenThumbnailModal((prevState) => !prevState)}
      />
      <ModelPreviewModal
        item={item}
        open={openModelPreviewModal}
        onClose={() => setModelPreviewModal(false)}
      /> */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="error"
          sx={{ width: "100%" }}
          style={{ backgroundColor: "rgb(211, 47, 47)", color: "#fff" }}
          className="content-snackbar"
        >
          Item not found
        </Alert>
      </Snackbar>
    </div>
  );
};

export default ContentItem;
