// import { ChevronRight } from "react-feather";
import { Grid, Box } from "@mui/material";
import { useEffect, useState } from "react";
import RenderTile from "../../Render/RenderTile";
import UniqSvg from "../../../../../../assets/svgs/analytics/a_uniq.svg";
import EyeSvg from "../../../../../../assets/svgs/analytics/a_eye.svg";
import AvgDuration from "../../../../../../assets/svgs/analytics/a_duration.svg";
import AccTime from "../../../../../../assets/svgs/analytics/a_acc_time.svg";
import DatePicker from "../../../../../_utils/DatePicker";
import { getTopVisitedMapToDate } from "../../../../../../features/user/analytics/analyticsSlice";
import { useDispatch, useSelector } from "react-redux";
import { lastTEDays } from "../../../../../_utils/DateUtils";
import { secondsToHms } from "../../../../../_utils/DateUtils";

const VisitorBehavior = () => {
  const dispatch = useDispatch();

  const [dateObj, setDateObj] = useState(lastTEDays);

  useEffect(() => {
    dispatch(getTopVisitedMapToDate(dateObj));
    // eslint-disable-next-line
  }, []);

  const overview = useSelector((state) => state.analytics.topVisitedMapToDate);

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col">
            <div className="analyticshome__anCard--contentRow--header">
              Visitor Behaviour
            </div>
          </div>
          <div className="col-3" style={{ paddingRight: "2.5rem" }}>
            <Box component="span" className="analyticshome__anCard--filterBox">
              <span style={{ color: "#858993" }}>Date:</span>
              <DatePicker
                cb={(dateObj) => {
                  setDateObj(dateObj);
                  dispatch(getTopVisitedMapToDate(dateObj));
                }}
              />
            </Box>
            {/* } */}
          </div>
        </div>
        <hr />
        {overview ? null : (
          <h5 className="text-center analyticshome__emptyStateText">
            {" "}
            No Data available{" "}
          </h5>
        )}
        <div style={{ padding: "0px 32px 0px 32px" }}>
          <Grid container={true} spacing={4}>
            <RenderTile
              tileSize={6}
              color="#008080"
              miniCardHeader={
                overview?.topVisitedMap
                  ? overview.topVisitedMap.charAt(0).toUpperCase() +
                    overview.topVisitedMap?.slice(1)
                  : "--"
              }
              miniCardDesc="Top visited map to date"
              svg={UniqSvg}
            />
            <RenderTile
              tileSize={6}
              color="#2A4DC2"
              miniCardHeader={
                overview?.totalDuration
                  ? secondsToHms(overview.totalDuration)
                  : "--"
              }
              miniCardDesc="Total accumulated visit time"
              svg={AccTime}
            />
            <RenderTile
              tileSize={6}
              color="#5600AC"
              miniCardHeader={
                overview?.average ? secondsToHms(overview.average) : "--"
              }
              miniCardDesc="Average map visit duration"
              svg={AvgDuration}
            />
            <RenderTile
              tileSize={6}
              color="#B80290"
              miniCardHeader={
                overview?.totalVisits ? overview.totalVisits : "--"
              }
              miniCardDesc="Total map visits to date"
              svg={EyeSvg}
            />
          </Grid>
          <hr />
        </div>
        {/* <div className="row analyticshome__anCard--contentRow--footer">
          <div className="col">
            <div className="analyticshome__floatright ">
              View Visitor Behavior Report
              <ChevronRight />
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default VisitorBehavior;
