import { Grid } from "@mui/material";
// import UniqSvg from "../../../../../assets/svgs/analytics/a_uniq.svg";
import EyeSvg from "../../../../../assets/svgs/analytics/a_eye_blue.svg";
import AvgDuration from '../../../../../assets/svgs/analytics/a_duration.svg';
// import { CSVLink } from "react-csv";
// import { Download } from "react-feather";
import { useState, useEffect } from "react";
import { isEmpty, isNull, isUndefined } from "lodash";
import { getVisitorBehaviourCard } from "../../../../../features/user/analytics/analyticsSlice";
// import RenderPagination from "../RenderPagination";
import RenderTile from "../Render/RenderTile";
import RenderTable from "../Render/RenderTable";
import usePagination from "../Render/usePagination";
import { useDispatch } from "react-redux";
// import moment from "moment";

const topVisitedMapsHeaders = [
//   { key: "mapName", label: "Map Name" },
//   { key: "publishedOn", label: "Publish Date" },
//   { key: "mapVisits", label: "Visit Count" },
  { key: "avgVisitTime", label: "Average Time Visited" },
  { key: "totalVisitTime", label: "Total Time Visited" },
];

const VisitorBehaviourCard = ({ visitorBehaviourCard, visitorBehaviourMetaCard }) => {
  const dispatch = useDispatch();

  const limit = 10;
  const [page, setPage] = useState(1);
  // const count = Math.ceil(visitorBehaviourCard.totalResults / limit);
  const _DATA = usePagination(
    visitorBehaviourCard.data ? visitorBehaviourCard.data : [],
    limit
  );

  useEffect(() => {
    dispatch(getVisitorBehaviourCard({ page, limit }));
    dispatch(getVisitorBehaviourCard({ page: 1, limit: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getVisitorBehaviourCard({ page, limit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  // eslint-disable-next-line
  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };
  
  return (
    <div className="analytics">
      {/* <Grid container={true} className="analyticshome__acGridContainer"> */}
      <div className="analyticshome__anCard">
        <Grid container={true} spacing={4} style={{ marginBottom: "3rem" }}>
          {/* <Grid item xs={4}> */}

          {/* <RenderTile
            tileSize={4}
            color="#008080"
            miniCardHeader={
              isEmpty(visitorBehaviourMetaCard)
                ? "--"
                : visitorBehaviourMetaCard?.mapName?.charAt(0).toUpperCase() +
                  visitorBehaviourMetaCard?.mapName?.slice(1)
            }
            miniCardDesc="Top visited map to date"
            svg={UniqSvg}
          /> */}
          {/* </Grid> */}
          {/* <Grid item xs={4}> */}

          <RenderTile
            tileSize={4}
            color="#017EB4"
            miniCardHeader={
              isEmpty(visitorBehaviourMetaCard)
                ? "--"
                : '32 hours, 34 mins'
            }
            miniCardDesc="Total accumulated visit time"
            svg={EyeSvg}
          />

          <RenderTile
            tileSize={4}
            color="#5600AC"
            miniCardHeader={
              isEmpty(visitorBehaviourMetaCard)
                ? "--"
                : '5pm-6pm (EST)'
            }
            miniCardDesc="Most traffic during time of day"
            svg={AvgDuration}
          />

          {/* </Grid> */}
          {/* <Grid item xs={4}> */}
          {/* <RenderTile
            color="#5600AC"
            miniCardHeader="2 min, 20 sec"
            miniCardDesc="Avg, visit time"
            svg={DialSvg}
          /> */}
          {/* </Grid> */}
        </Grid>
        <div className="row analyticshome__anCard--contentRow d-flex align-items-center">
          <div className="col">
            <div className="analyticshome__anCard--contentRow--header">
                Visitor Behavior
            </div>
          </div>
          {/* <div className="col">
            <div
              className="analyticshome__floatright"
              style={{ paddingRight: "35px" }}
            >
              {isEmpty(visitorBehaviourCard.data) ? null : (
                <CSVLink
                  headers={topVisitedMapsHeaders.map((headerObj, index) => ({
                    label: headerObj.label,
                    key: Object.keys(visitorBehaviourCard.data[0])[index],
                  }))}
                  data={visitorBehaviourCard.data.map((obj) => ({...obj,publishedOn:obj.publishedOn?moment(new Date(obj.publishedOn)).format('DD-MMM-YYYY'):'--'}))}
                  filename={"TopVisitedMaps.csv"}
                  style={{ color: "#2A4DC2" }}
                >
                  <Download /> Download CSV
                </CSVLink>
              )}
            </div>
          </div> */}
        </div>
        <hr />
        {isEmpty(visitorBehaviourCard) || isNull(_DATA.currentData()) || isUndefined(_DATA.currentData())?null: (
          <RenderTable
            thheaderData={topVisitedMapsHeaders}
            rowsData={_DATA.currentData().map((row) => ({
              ...row,
            }))}
          />
        )}
        {isEmpty(visitorBehaviourCard.data) ? (
          <h5 className="text-center analyticshome__emptyStateText pt-3">
            {" "}
            No Data available{" "}
          </h5>
        ) : null}
        {/* <div className="row analyticshome__anCard--contentRow--footer">
          <div className="col">
            {isEmpty(visitorBehaviourCard.data) ? null : (
              <RenderPagination
                count={count}
                page={page}
                limit={limit}
                handleChange={handleChange}
                cb={(page) => {
                  setPage(page);
                  _DATA.jump(page);
                }}
                totalResults={visitorBehaviourCard.totalResults}
              />
            )}
          </div>
        </div> */}
      </div>
      {/* </Grid> */}
    </div>
  );
};

export default VisitorBehaviourCard;
