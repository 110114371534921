import { omit, has } from "lodash";
import * as THREE from "three";
import EditorExperience from "../EditorExperience";
import EventEmitter from "../utils/EventEmitter";

import {
    fromPosObjectToVec3Pos,
    fromQuaternionObjectToEuler,
    fromQuaternionToQuaternionObject,
    fromVec3PosToPosObject,
} from "./TransformConversions";

//import axios server
import Server from "../../../api";

// UI
import { UIPanel, UIImage, UIText, UIRow } from "../ui/ui.lib";

import expandIcon from "../../../assets/svgs/scenes/expand.svg";

export default class ImageAccessPoint extends EventEmitter {
    constructor(imgData) {
        super();
        this.editor = new EditorExperience();
        this.imgData = imgData;
        this.mesh = null;

        this.id = imgData.id;
        this.anchorType = imgData.anchorIndex;
        this.name =
            imgData.name && imgData.name.length > 0
                ? imgData.name.substring(0, 20)
                : "";
        this.description = imgData.description || "Sample Description";

        this.visible = "visible" in imgData ? imgData.visible : true;
        this.thumbnail =
            "anchorthumbnail" in imgData ? imgData.anchorthumbnail : null;
        this.isPrimary = imgData.isPrimaryAccess || false;

        this.position = imgData.position
            ? fromPosObjectToVec3Pos(imgData.position)
            : new THREE.Vector3(0, 0, 0);
        this.rotation = imgData.rotation
            ? fromQuaternionObjectToEuler(imgData.rotation)
            : new THREE.Euler(0, 0, 0);
        this.originalRotation = imgData.rotation
            ? fromQuaternionObjectToEuler(imgData.rotation)
            : new THREE.Euler(0, 0, 0);

        this.direction = imgData.direction || "--";
        this.dimension = imgData.dimension || "--";

        this.uiCont = null;
        this.bufferedChanges = {};

        if (this.isPrimary) this.editor.isPrimaryAccessEnabled = this.isPrimary;

        this.editor.jsonQRAnchors.push({
            ...imgData,
            name: this.name,
            description: this.description,
        });

        this.editor.on("MetaObjectChanged", this.onMetaObjectChanged);
        this.editor.on("SaveMetaObject", this.onSaveMetaObject);
        this.editor.on(
            "ChangePrimaryAccessToSecondary",
            this.onChangePrimaryAccess
        );
        this.editor.on("objectSelected", this.onObjectSlected);
        this.editor.on("objectRemoved", this.onObjectRemoved);
        this.editor.on("clearAndReset", this.onClearAndReset);
        this.editor.on("objectChanged", this.onObjectChanged);
        this.editor.on("saveAdjustAccessChanges", this.updateObject);

        this.setupSprite();
        this.initHTML();
    }

    initHTML = () => {
        //Init Img UI
        this.uiCont = new UIPanel();
        this.uiCont.addClass("wsIapContainer");

        const uiIapRow = new UIRow().addClass("wsIapContainer--iapHeaderRow");
        this.uiHeader = new UIText("80%", this.name).addClass(
            "wsIapContainer--iapHeaderText"
        );
        uiIapRow.addChild(this.uiHeader);
        uiIapRow.addChild(
            new UIImage(expandIcon, "25px", "25px", "expand").onClick(() =>
                this.editor.onToggleIAPModal(true, {
                    name: this.name,
                    description: this.description,
                    thumbnail: this.thumbnail,
                })
            )
        );
        this.uiCont.addChild(uiIapRow);

        this.uiThumbnail = new UIImage(this.thumbnail, "100%", "130px");
        this.uiCont.addChild(this.uiThumbnail);

        this.uiCont.setDisplay("none");
        document.body.appendChild(this.uiCont.dom);
    };

    setupSprite = () => {
        const iapModel = this.isPrimary
            ? this.editor.imgAccessMainPin.clone()
            : this.editor.imgAccessPin.clone();
        iapModel.scale.set(0.7, 0.7, 0.7);
        iapModel.children.forEach((child) => {
            if (child.name === "Direction") {
                if (
                    this.direction === "verticle" ||
                    this.direction === "vertical"
                ) {
                    child.rotateY(THREE.MathUtils.DEG2RAD * 90);
                } else if (this.direction === "--") {
                    child.visible = false;
                }
            }
        });
        iapModel.name = this.name;

        // size of the model!
        const box = new THREE.Box3().setFromObject(iapModel);
        this.size = box.getSize(new THREE.Vector3());

        this.mesh = new THREE.Group();
        this.mesh.add(iapModel);
        this.mesh.name = this.name;
        this.mesh.userData["type"] = "imageAccessPoint";
        this.mesh.userData["skipChild"] = "imageAccess";
        this.mesh.userData["id"] = this.id;
        this.mesh.userData["description"] = this.description;
        this.mesh.userData["transformation"] = "NO_TRANSFORM";
        this.mesh.userData["rotateOn"] = "Y";
        this.mesh.userData["qrAnchor"] = true; //For Save Button
        this.mesh.userData["visible"] = this.visible;
        this.mesh.userData["texture"] = this.thumbnail;
        this.mesh.userData["primaryAccess"] = this.isPrimary;
        this.mesh.userData["dimension"] = this.dimension;
        this.mesh.userData["direction"] = this.direction;
        this.mesh.userData["accessPointDimDir"] = true;
        this.mesh.userData["MetaObject"] = true;
        this.mesh.userData["accessAdjust"] = true;

        this.mesh.position.copy(this.position);
        this.mesh.position.sub(new THREE.Vector3(0, this.size.y / 2, 0));
        this.mesh.rotation.set(0, this.rotation.y, 0);

        this.mesh.visible = this.visible;
    };

    changeCurrentMesh = (toChangeType) => {
        this.mesh.remove(this.mesh.children[0]);
        let iapModel;
        if (toChangeType) {
            iapModel = this.editor.imgAccessMainPin.clone();
        } else {
            iapModel = this.editor.imgAccessPin.clone();
        }
        iapModel.scale.set(0.7, 0.7, 0.7);
        iapModel.name = this.name;
        this.mesh.add(iapModel);
    };

    onSaveMetaObject = () => {
        if (Object.keys(this.bufferedChanges).length > 0) {
            const idx = this.editor.getIndex("jsonQRAnchors", this.id);
            let reqObj = { ...this.editor.jsonQRAnchors[idx] };
            reqObj = omit(reqObj, ["createdOn", "modifiedOn"]);

            const proceedSave = () => {
                this.editor.jsonQRAnchors[idx] = reqObj;
                this.editor.onAnchorAPICalls(reqObj, "UPDATE");
            };

            if (has(this.bufferedChanges, "name")) {
                reqObj.name = this.bufferedChanges.name;
                this.name = this.bufferedChanges.name;
                this.mesh.name = this.bufferedChanges.name;
                this.uiHeader.setTextContent(this.name);
            }
            if (has(this.bufferedChanges, "description")) {
                reqObj.description = this.bufferedChanges.description;
                this.description = this.bufferedChanges.description;
                this.mesh.userData.description =
                    this.bufferedChanges.description;
            }
            if (has(this.bufferedChanges, "isPrimaryAccess")) {
                if (this.bufferedChanges.isPrimaryAccess === true) {
                    //make curent primary and other primary as secondary
                    this.editor.trigger("ChangePrimaryAccessToSecondary", [
                        this.mesh,
                    ]);
                    this.changeCurrentMesh(true);
                    reqObj.isPrimaryAccess =
                        this.bufferedChanges.isPrimaryAccess;
                    this.isPrimary = this.bufferedChanges.isPrimaryAccess;
                    this.mesh.userData.primaryAccess =
                        this.bufferedChanges.isPrimaryAccess;
                } else {
                    //only make current object change!
                    if (reqObj.isPrimaryAccess === true) {
                        //if previous was primary
                        this.changeCurrentMesh(false);
                    }
                    reqObj.isPrimaryAccess =
                        this.bufferedChanges.isPrimaryAccess;
                    this.isPrimary = this.bufferedChanges.isPrimaryAccess;
                    this.mesh.userData.primaryAccess =
                        this.bufferedChanges.isPrimaryAccess;
                }
            }
            //This to enable/disable default image access!
            if (has(this.bufferedChanges, "defaultIAPState")) {
                if (this.bufferedChanges.defaultIAPState === true) {
                    //Make current as primary IAP/ set default IAP
                    this.editor.isPrimaryAccessEnabled = true;

                    this.changeCurrentMesh(true);
                    reqObj.isPrimaryAccess =
                        this.bufferedChanges.defaultIAPState;
                    this.isPrimary = this.bufferedChanges.defaultIAPState;
                    this.mesh.userData.primaryAccess =
                        this.bufferedChanges.defaultIAPState;
                    this.editor.trigger("ChangePrimaryAccessToSecondary", [
                        this.mesh,
                    ]);
                } else {
                    // if current is primary make it secondary
                    this.editor.isPrimaryAccessEnabled = false;
                    if (this.isPrimary) {
                        this.changeCurrentMesh(false);
                        reqObj.isPrimaryAccess = false;
                        this.isPrimary = false;
                        this.mesh.userData.primaryAccess = false;
                    } //else make all secondary IAP!
                    else
                        this.editor.trigger("ChangePrimaryAccessToSecondary", [
                            this.mesh,
                        ]);
                }
            }
            if (has(this.bufferedChanges, "texture")) {
                let reqParams = new FormData();
                reqParams.append("file", this.bufferedChanges.texture);
                reqParams.append("contentType", "maps");
                reqParams.append("mapId", this.editor.mapId);
                Server.post("/v1/asset/delete", { assetUrl: this.thumbnail });
                Server.post("/v1/asset/upload", reqParams, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                    .then((response) => {
                        if (response.status === 200) {
                            const link = response.data.data.file;
                            reqObj.anchorthumbnail = link;
                            this.thumbnail = link;
                            this.mesh.userData.texture = link;
                            this.uiThumbnail.dom.src = null;
                            this.uiThumbnail.dom.src = link;
                            proceedSave();
                        }
                    })
                    .catch((e) => {
                        proceedSave();
                    })
                    .catch((e) =>
                        this.editor.callbacks.handleExceptionCatch(e)
                    );
            } else {
                proceedSave();
            }
        }
    };

    onMetaObjectChanged = (object, property, value, discardAll = false) => {
        if (object === this.mesh) {
            this.bufferedChanges[property] = value;
        } else if (discardAll) {
            this.bufferedChanges = {};
        }
    };

    onChangePrimaryAccess = (object) => {
        if (this.mesh !== object && this.isPrimary === true) {
            const idx = this.editor.getIndex("jsonQRAnchors", this.id);
            let reqObj = { ...this.editor.jsonQRAnchors[idx] };
            reqObj = omit(reqObj, ["createdOn", "modifiedOn"]);
            reqObj.isPrimaryAccess = false;
            this.editor.jsonQRAnchors[idx] = reqObj;
            this.editor.onAnchorAPICalls(reqObj, "UPDATE");
            this.changeCurrentMesh(false);
            this.isPrimary = false;
            this.mesh.userData.primaryAccess = false;
        }
    };

    onObjectRemoved = (object, delayAutosave, isVersionSwitch = false) => {
        if (!isVersionSwitch && object === this.mesh) {
            const idx = this.editor.getIndex("jsonQRAnchors", this.id);
            const anchor = this.editor.jsonQRAnchors.splice(idx, 1);
            this.editor.onAnchorAPICalls(anchor[0].id, "DELETE");
            this.onCallDestructor(object);
        }
    };

    onObjectChanged = (object) => {
        if (this.mesh === object) {
            this.position.copy(object.position);
            this.rotation.copy(object.rotation);
        }
    };

    updateObject = (object) => {
        if (this.mesh === object) {
            const idx = this.editor.getIndex("jsonQRAnchors", this.id);
            let reqObj = { ...this.editor.jsonQRAnchors[idx] };

            reqObj = omit(reqObj, ["createdOn", "modifiedOn"]);
            const euler = new THREE.Euler(
                this.originalRotation.x,
                this.originalRotation.z,
                object.rotation.y,
                "XZY"
            );
            const quat = new THREE.Quaternion().setFromEuler(euler);

            const pos = object.position.clone();
            pos.add(new THREE.Vector3(0, this.size.y / 2, 0));

            reqObj.position = fromVec3PosToPosObject(pos);
            reqObj.rotation = fromQuaternionToQuaternionObject(quat);

            this.editor.jsonQRAnchors[idx] = reqObj;
            this.editor.onAnchorAPICalls(reqObj, "UPDATE");
        }
    };

    onObjectSlected = (object) => {
        if (this.mesh === object) {
            this.uiCont.setDisplay("flex");
        } else {
            this.uiCont.setDisplay("none");
        }
    };

    onCallDestructor = (object) => {
        if (object === this.mesh) {
            this.editor.stop("MetaObjectChanged", this.onMetaObjectChanged);
            this.editor.stop("SaveMetaObject", this.onSaveMetaObject);
            this.editor.stop(
                "ChangePrimaryAccessToSecondary",
                this.onChangePrimaryAccess
            );
            this.editor.stop("objectSelected", this.onObjectSlected);
            this.editor.stop("objectRemoved", this.onObjectRemoved);
            this.editor.stop("objectChanged", this.onObjectChanged);
        }
    };

    onClearAndReset = () => {
        if (this.uiCont) {
            this.uiCont.setDisplay("none");
            document.body.removeChild(this.uiCont.dom);
            this.uiCont.dom.remove();
        }
    };
}
