import * as THREE from "three";
import EditorExperience from "../../EditorExperience";
import { omit, cloneDeep, isEmpty } from "lodash";
import {
    fromPos2dObjectToVec3Pos,
    fromPosObjectToVec3Pos,
    getTransformed2DPos,
    getTransformed2DPosV1,
    getTransformed3DPos,
    getTransformed3DPosV1,
} from "../TransformConversions";

import {
    getConnectorColorIndex,
    getConnectorSpriteTexture,
    getTypeFromName,
} from "../../2d-editor/threeUtils/Connectors/ConnectorUtils";
import { dispose } from "../../utils/Dispose.Utils";

export default class Connector {
    constructor(pinProps) {
        this.pinProps = pinProps;

        // console.log(pinProps);

        this.editor = new EditorExperience();
        this.pinType = pinProps.pinType.toLowerCase(); // this is a temp fix!

        this.toUpload = 0;
        this.imageCount = 0;
        this.imgObj = null;

        this.name = pinProps.name || "";
        this.description = pinProps.description || "";
        this.id = pinProps.id;
        this.connectorType = pinProps.connectorType;
        this.texType = getTypeFromName(this.connectorType);

        this.scaleY = 0.9;
        this.scaleXZ = 0.7;

        this.images = pinProps.images || [];
        this.pinColor = pinProps.pinColor || "#36CCE7";
        this.pinNavStyle = pinProps.navigationStyle || "default";
        this.visible = pinProps.visible || true;

        this.position = pinProps.position
            ? fromPosObjectToVec3Pos(pinProps.position)
            : new THREE.Vector3(0, 0, 0);
        this.position2d = pinProps.position2d
            ? fromPos2dObjectToVec3Pos(pinProps.position2d)
            : new THREE.Vector3(0, 0, 0);
        this.isMoved = pinProps.isMoved || false;

        if (this.isMoved) {
            ++this.editor.movedPinsCount;
        }

        this.mesh = {};

        this.editor.jsonNavPins.push(pinProps);

        this.editor.on("repositionYOfObjects", this.onRepositionYOfObjects);
        this.editor.on(
            "updateObject3DPositionsWithNewFloorMatrix",
            this.onUpdateObject3DPositions
        );
        this.editor.on("callDestructor", this.onCallDestructor);
        this.editor.on("objectRemoved", this.onCallDestructor);
        this.editor.resources.on("ready", this.onReadyToUpdate2dPosition);
        this.editor.on("floorDataReady", this.onReadyToUpdate2dPosition);

        this.setUpConnectorPin();
    }

    setUpConnectorPin = () => {
        const spriteTex = new THREE.TextureLoader().load(
            getConnectorSpriteTexture(
                this.texType,
                getConnectorColorIndex(this.pinColor)
            )
        );
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        this.spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            side: THREE.DoubleSide,
            transparent: true,
            depthTest: false,
            depthWrite: false,
        });

        this.mesh = new THREE.Sprite(this.spriteMaterial);
        this.mesh.position.copy(this.position);
        this.mesh.scale.set(this.scaleXZ, this.scaleY, this.scaleXZ);
        this.mesh.rotation.y = THREE.MathUtils.degToRad(180);
        this.mesh.visible = this.visible;

        this.mesh.name = this.name;
        this.mesh.userData["indexName"] = this.name;
        this.mesh.userData["type"] = "Connector Pin";
        this.mesh.userData["id"] = this.id;
        this.mesh.userData["description"] = this.description;
        this.mesh.userData["pinColor"] = this.pinColor;
        this.mesh.userData["navigationStyle"] = this.pinNavStyle;
        this.mesh.userData["pinType"] = this.pinType;
        this.mesh.userData["connectorType"] = this.connectorType;
        this.mesh.userData["visible"] = this.visible;
        this.mesh.userData["transformation"] = "NO_TRANSFORM"; //only to hide transformation controls in edit mode
        this.mesh.userData["skipChild"] = true;
        this.mesh.userData["interactive2D"] = true;
    };

    onRepositionYOfObjects = (yValue) => {
        this.mesh.position.y = yValue * (this.scaleY / 1.48);
    };

    onUpdateObject3DPositions = () => {
        const idx = this.editor.getIndex("jsonNavPins", this.id);
        if (idx === -1) return;
        let reqObj = { ...this.editor.jsonNavPins[idx] };
        let changeObj = {
            id: reqObj.id,
        };

        reqObj.position2d = changeObj["position2d"] = {
            posX: this.position2d.x,
            posY: Math.abs(this.position2d.y),
        };
        const pos2d = this.getTransformedPos({
            posX: this.position2d.x,
            posY: Math.abs(this.position2d.y),
        });
        reqObj.position = changeObj["position"] = pos2d;

        this.mesh.position.copy(fromPosObjectToVec3Pos(pos2d));
        this.mesh.position.y =
            (this.editor.floorY ? this.editor.floorY : this.mesh.position.y) *
            (this.pinType === "regular"
                ? this.scaleY / 1.38
                : this.scaleY / 1.48);
        this.position.copy(this.mesh.position);

        this.editor.jsonNavPins[idx] = reqObj;
        this.editor.pinReadjustObj.push(changeObj);
        this.editor.trigger("requestPinPathReadjustment");
    };

    prepareObjForAPI = (reqObj) => {
        reqObj.amenityPinTypes = reqObj.amenityPinTypes.map((type) => {
            if (typeof type === "object") return type.id;
            else return type;
        });

        return { ...reqObj };
    };

    onReadyToUpdate2dPosition = () => {
        if (this.isMoved && !isEmpty(this.editor.floorData)) {
            // update 2D pos since 3d updated from app
            const idx = this.editor.getIndex("jsonNavPins", this.id);
            if (idx === -1) return;
            let reqObj = { ...this.editor.jsonNavPins[idx] };
            reqObj = omit(reqObj, [
                "wsType",
                "type",
                "amenityType",
                "toCreate",
            ]);
            reqObj = this.prepareObjForAPI(reqObj);

            let pos2d = cloneDeep(
                this.get2dPosFrom3dPos(this.position.clone())
            );
            this.isMoved = reqObj.isMoved = false;

            reqObj.position2d = pos2d;
            this.position2d = fromPos2dObjectToVec3Pos(cloneDeep(pos2d));

            this.editor.jsonNavPins[idx] = reqObj;
            this.editor.pinMovedObj.push(reqObj);
            this.editor.trigger("requestUpdateMovedPins");
        }
    };

    getTransformedPos = (pos) => {
        const { imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6
            ? getTransformed3DPos(
                  this.editor.floorWorldMatrix,
                  imgWidth,
                  imgHeight,
                  pos,
                  true
              )
            : getTransformed3DPosV1(
                  this.editor.floorWorldMatrix,
                  imgWidth,
                  imgHeight,
                  pos,
                  true
              );
    };

    get2dPosFrom3dPos = (pos3d) => {
        const { imgWidth, imgHeight } = this.editor.floorData;
        let invWorldMatrix = this.editor.floorWorldMatrix.clone();
        invWorldMatrix = invWorldMatrix.invert();
        return this.editor.floorplanVersion !== 2.6
            ? getTransformed2DPos(
                  invWorldMatrix,
                  imgWidth,
                  imgHeight,
                  pos3d,
                  true
              )
            : getTransformed2DPosV1(
                  invWorldMatrix,
                  imgWidth,
                  imgHeight,
                  pos3d,
                  true
              );
    };

    onCallDestructor = (object) => {
        if (object === this.mesh) {
            this.editor.stop(
                "repositionYOfObjects",
                this.onRepositionYOfObjects
            );
            this.editor.stop(
                "updateObject3DPositionsWithNewFloorMatrix",
                this.onUpdateObject3DPositions
            );
            this.editor.stop("callDestructor", this.onCallDestructor);
            this.editor.stop("objectRemoved", this.onCallDestructor);
            this.editor.resources.stop("ready", this.onReadyToUpdate2dPosition);

            dispose(this.mesh);
        }
    };
}
