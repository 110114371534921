import React from 'react';
import DatePicker from '../../../../../_utils/DatePicker';

import { Box, Grid } from '@mui/material';
import RenderTile from '../../../../users/analytics/Render/RenderTile';
import Loader from '../../../../../common/loader';

// incons
import EyeSvg from '../../../../../../assets/svgs/analytics/a_eye_green.svg';
import StorageSvg from '../../../../../../assets/svgs/analytics/a_storage_blue.svg';
import AccountsSvg from '../../../../../../assets/svgs/analytics/a_accounts_voilet.svg';
import MapsSvg from '../../../../../../assets/svgs/analytics/a_maps_magenta.svg';
import SDKAccountsSvg from '../../../../../../assets/svgs/analytics/a_sdkAccounts_cyan.svg';
import SDkAppsSvg from '../../../../../../assets/svgs/analytics/a_sdkapp_red.svg';
import ActivatedMapSvg from '../../../../../../assets/svgs/analytics/a_sdk_cyan.svg';
import AlignedFloorSvg from '../../../../../../assets/svgs/analytics/a_duration.svg';

export default function OverviewCard({ overview, handleChange, loading}) {

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col">
                        <div className="analyticshome__anCard--contentRow--header">Overview</div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker
                                cb={(dateObj) => {
                                    handleChange(dateObj)
                                }}
                            />
                        </Box>
                    </div>
                </div>
                <hr />
                {(!overview && !loading) ? (
                    <div className='analyticshome__emptyTextCont'>
                        <h5 className="text-center analyticshome__emptyStateText">{" "}No Data available{" "}</h5>
                    </div>
                ) : loading ? (
                    <div className='analyticshome__emptyTextCont'>
                        <Loader loaderText={"Fetching Data..."} height="120%" width="100%"/>
                    </div>
                ) : (
                    <div style={{ padding: "0px 32px 0px 32px" }}>
                        <Grid container={true} spacing={2}>
                            <RenderTile
                                tileSize={6}
                                color="#008043"
                                miniCardHeader={overview?.totalMapVisits ? overview?.totalMapVisits : "--"}
                                miniCardDesc="Total map visits"
                                svg={EyeSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#2A4DC2"
                                miniCardHeader={overview?.totalStorage ? overview?.totalStorage + " MB" : "--"}
                                miniCardDesc="Total amount of storage currently being used"
                                svg={StorageSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#5600AC"
                                miniCardHeader={overview?.accounts ? overview?.accounts : "--"}
                                miniCardDesc="Total number of active accounts"
                                svg={AccountsSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#B80290"
                                miniCardHeader={overview?.totalMaps ? overview?.totalMaps : "--"}
                                miniCardDesc="Total maps created"
                                svg={MapsSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#017EB4"
                                miniCardHeader={overview?.totalSdkAccounts ? overview?.totalSdkAccounts : "--"}
                                miniCardDesc="Total number of active SDK accounts"
                                svg={SDKAccountsSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#C10353"
                                miniCardHeader={overview?.totalSdkApps ? overview?.totalSdkApps : "--"}
                                miniCardDesc="Total number of SDK Apps created"
                                svg={SDkAppsSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#5600AC"
                                miniCardHeader={overview?.floorMaps ? overview?.floorMaps : "--"}
                                miniCardDesc="Total number of Maps with Aligned Floorplans"
                                svg={AlignedFloorSvg}
                            />
                            <RenderTile
                                tileSize={6}
                                color="#017EB4"
                                miniCardHeader={overview?.acvitatedMaps ? overview?.acvitatedMaps : "--"}
                                miniCardDesc="Total number of Actived Maps"
                                svg={ActivatedMapSvg}
                            />
                        </Grid>
                        <hr />
                    </div>
                )
                }
            </div>
        </div>
    )
}
