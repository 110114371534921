import React, { useEffect, useState, useRef } from "react";
import MapBox from "../MapBox";
import { Button, Grid } from "@mui/material";
import { has, isNull, isUndefined, isEmpty, isEqual } from "lodash";
import blankSVG from "../../../../../assets/svgs/UploadBlankIcon.svg";
import NoLocationFG from "../../../../../assets/pngs/mapdetails/noLocationfg.png";
import MapThumbnailFG from "../../../../../assets/pngs/mapdetails/mapThumbnailfg.png";
import { ReactComponent as FloorplanIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/defaultFloorplan.svg";
import { ReactComponent as LocationIcon } from "../../../../../assets/svgs/dash-landing/mapdetails/location.svg";

import {
  uploadAsset,
  isThumbnailUploading,
  isFloorplanUploading,
  isOverviewFileUploading,
  thumbnailData,
  floorplanData,
  overviewData,
  deleteAsset,
  commonLog,
  resetMapDetailsTab,
} from "../../../../../features/common/commonSlice";
import {
  getUserMapDetails,
  // updateUserMap,
  putFloorplan,
  resetLogs,
} from "../../../../../features/user/studio/studioSlice";
import {
  getVenueDetails,
  updateVenueMap,
} from "../../../../../features/webstudio/buildingSlice";

import { useDispatch, useSelector } from "react-redux";
import ARWayImage from "../../../../common/CO/ARWayImage";
import { validateMIME } from "../../../../common/utils";
import { setNewAlert } from "../../../../../features/common/alertSlice";
import Dropdown from "../../../../webstudio/studiocommon/ImportMaps/VenuesDetails/Dropdown";
import SampleFloorplans from "../../../../common/sampleFloorplans";
import { bulkDeletePaths } from "../../../../../features/webstudio/editor2dSlice";
import { Edit2, Loader, Save } from "react-feather";

const VenueDetailsTab = ({
  state,
  handleBack,
  handletoggleModal,
  replaceLog,
  handleReplaceFP,
}) => {
  const { mapData, mapDetails, isBackClicked, isReplaceFloorplan } = state;
  const dispatch = useDispatch();
  const commonLogData = useSelector(commonLog);
  const thumbnailFileData = useSelector(thumbnailData);
  const floorplanFileData = useSelector(floorplanData);
  const overviewFileData = useSelector(overviewData);
  const prevFloorPlanFile = useRef(null);
  const prevThumbnailFile = useRef(null);
  const prevOverviewFile = useRef(null);
  const [thumbnailFileURL, setThumbnailFileURL] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [overviewFileURL, setOverviewFileURL] = useState(null);
  const [overviewFile, setOverviewFile] = useState(null);
  const [floorplanFileURL, setFloorplanFileURL] = useState(null);
  const [floorplanFile, setFloorplanFile] = useState(null);
  const [description, setDescription] = useState(state.description);
  const [isEditDescription, setisEditDescription] = useState(false);
  const thumbnailStatus = useSelector(isThumbnailUploading);
  const floorplanStatus = useSelector(isFloorplanUploading);
  const overviewFileStatus = useSelector(isOverviewFileUploading);
  const [floorplan, setFloorplan] = useState(null);
  const [level, setLevel] = useState(`0-${mapDetails?.maps[0]?.identifier}`);
  const mapD = useSelector((state) => state?.studio?.mapDetails);
  const [showFloorModal, toggleShowFloorModal] = useState(false);
  const [editContact, setEditContact] = useState(false);
  const [contactMobile, setContactMobile] = useState(mapDetails?.mobile);
  const [contactEmail, setContactEmail] = useState(mapDetails?.email);
  const [contactWebsite, setContactWebsite] = useState(mapDetails?.website);
  const [inputError, setInputError] = useState({ email: null, url: null });

  useEffect(() => {
    handleBack(false);
    setFP();
    dispatch(getUserMapDetails(mapDetails?.maps[0].mapId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getActiveMapDetails = () => {
    dispatch(
      getUserMapDetails(
        mapDetails?.maps.find(
          (m) =>
            m.mapId ===
            mapDetails?.maps.find((m, i) => `${i}-${m.identifier}` === level)[
            "mapId"
            ]
        )["mapId"]
      )
    );
  };

  const invokeBulkDelete = () => {
    dispatch(
      bulkDeletePaths({
        mapId: mapDetails?.maps.find(
          (m) =>
            m.mapId ===
            mapDetails?.maps.find((m, i) => `${i}-${m.identifier}` === level)[
            "mapId"
            ]
        )["mapId"],
        reqObj: {},
        deleteAll: true,
      })
    );
  };

  useEffect(() => {
    getActiveMapDetails();
    prevFloorPlanFile.current = null;
    prevOverviewFile.current = null;
    prevThumbnailFile.current = null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [level]);

  useEffect(() => {
    if (
      thumbnailFile === false ||
      floorplanFile === false ||
      overviewFile === false
    ) {
      dispatch(
        setNewAlert({
          msg: "File/Mime type not supported!",
          alertType: "danger",
        })
      );
      if (!thumbnailFile) {
        setThumbnailFile(null);
      }
      if (!floorplanFile) {
        setFloorplanFile(null);
      }
      if (!overviewFile) {
        setOverviewFile(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailFile, floorplanFile, overviewFile]);

  useEffect(() => {
    setThumbnailFile(null);
    setFloorplanFile(null);
    setThumbnailFileURL(null);
    setOverviewFileURL(null);
    setFloorplanFileURL(null);
    setisEditDescription(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBackClicked]);

  useEffect(() => {
    if (replaceLog === "success") {
      dispatch(getVenueDetails(mapData));
      getActiveMapDetails();
      invokeBulkDelete();
      dispatch(resetLogs());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [replaceLog]);

  useEffect(() => {
    if (isReplaceFloorplan) {
      if (floorplanFile.isUrl) {
        // init sample replace
        handleReplaceFP();
        handleSampleFloorReplace();
      } else {
        createAsset("floorplan").then(() => {
          handleReplaceFP();
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReplaceFloorplan]);

  useEffect(
    () => { },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [commonLogData]
  );

  useEffect(() => {
    setFP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapD]);

  useEffect(() => {
    if (thumbnailFileData) {
      prevThumbnailFile.current = thumbnailFile;
      dispatch(
        updateVenueMap({
          mapId: mapData,
          venueDetails: {
            ...mapDetails,
            thumbnail: thumbnailFileData.file,
          },
        })
      );
    }
    if (floorplanFileData) {
      prevFloorPlanFile.current = floorplanFile;
      dispatch(
        putFloorplan({
          mapId: mapDetails?.maps.find(
            (m) =>
              m.mapId ===
              mapDetails?.maps.find(
                (m) => m.identifier === level.split("-")[1]
              )["mapId"]
          )["mapId"],
          mapDetails: mapD,
          floorplanData: {
            link: floorplanFileData?.compressedFile || floorplanFileData.file,
            name: floorplanFile?.name?.replace(/\.[^/.]+$/, ""),
          },
          currFloorplan: floorplan,
        })
      );
    }
    if (overviewFileData) {
      prevOverviewFile.current = overviewFile;
      dispatch(
        updateVenueMap({
          mapId: mapData,
          venueDetails: {
            ...mapDetails,
            file: overviewFileData.file,
          },
        })
      );
    }
    dispatch(resetMapDetailsTab());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnailFileData, floorplanFileData, overviewFileData]);

  const setFP = () => {
    if (has(mapD?.metadata, "floors")) {
      const length = mapD?.metadata?.floors.length;
      if (length > 0) {
        const floor = mapD?.metadata?.floors[length - 1];
        setFloorplan(floor);
      }
    }
  };

  const handleSampleFloorReplace = () => {
    prevFloorPlanFile.current = floorplanFile;
    dispatch(
      putFloorplan({
        mapId: mapDetails?.maps.find(
          (m) =>
            m.mapId ===
            mapDetails?.maps.find((m) => m.identifier === level.split("-")[1])[
            "mapId"
            ]
        )["mapId"],
        mapDetails: mapD,
        floorplanData: {
          link: floorplanFile.image,
          name: floorplanFile?.name?.replace(/\.[^/.]+$/, ""),
        },
        currFloorplan: floorplan,
      })
    );
    dispatch(resetMapDetailsTab());
  };

  const handleFileChange = (e, fData) => {
    var file = e ? e.target.files[0] : fData;
    validateMIME(file, "images", (flag) => {
      if (flag) {
        if (e?.target.id === "thumbnail-input") {
          setThumbnailFileURL(URL.createObjectURL(file));
          setThumbnailFile(file);
        } else if (e?.target.id === "file-input") {
          setOverviewFileURL(URL.createObjectURL(file));
          setOverviewFile(file);
        } else {
          setFloorplanFileURL(URL.createObjectURL(file));
          setFloorplanFile(file);
        }
      } else {
        if (e?.target.id === "thumbnail-input") {
          setThumbnailFile(false);
          setThumbnailFileURL(null);
        } else if (e?.target.id === "file-input") {
          setOverviewFile(false);
          setOverviewFileURL(null);
        } else {
          setFloorplanFile(false);
          setFloorplanFileURL(null);
        }
      }
    });
  };

  const removeAsset = (url) => {
    dispatch(deleteAsset({ assetUrl: url }));
  };

  const createAsset = (assetType) => {
    let isThumbnail = assetType === "thumbnail";
    let isFile = assetType === "file";
    if (isThumbnail && mapDetails.thumbnail) {
      removeAsset(mapDetails.thumbnail);
    }
    if (isFile && mapDetails?.file) {
      removeAsset(mapDetails?.file);
    }
    let reqObj = new FormData();
    reqObj.append(
      "file",
      isFile ? overviewFile : isThumbnail ? thumbnailFile : floorplanFile
    );
    reqObj.append("contentType", isThumbnail || isFile ? "maps" : "floorplans");
    reqObj.append("mapId", mapDetails?.id);
    assetType === "floorplans" && reqObj.append("compressFile", true);
    let data = {
      reqObj,
      ...(isFile
        ? { isOverviewFileUploading: true }
        : isThumbnail
          ? { isThumbnailUploading: true }
          : { isFloorplanUploading: true }),
      assetType,
    };
    return dispatch(uploadAsset(data));
  };

  const onSampleFloorSuccess = (object) => {
    if (object) {
      if (object.type === "file") handleFileChange(null, object.data);
      else if (object.type === "url") {
        setFloorplanFileURL(object.data.image);
        setFloorplanFile({ ...object.data, isUrl: true });
      }
    }
    toggleShowFloorModal(false);
  };

  const capitalizeFirstChar = (str) => {
    return isNull(str) || isUndefined(str) || isEmpty(str)
      ? ""
      : str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleDescriptionOnclick = (e) => {
    dispatch(
      updateVenueMap({
        mapId: mapData,
        venueDetails: { ...mapDetails, description },
      })
    );
  };

  const getLocationString = () => {
    let location = '';

    if (mapDetails?.location?.city) {
      location += capitalizeFirstChar(
        mapDetails?.location?.city
      ) + ", "
    }

    if (mapDetails?.location?.region) {
      location += capitalizeFirstChar(
        mapDetails?.location?.region
      ) + ", "
    }

    if (mapDetails?.location?.country) {
      location += capitalizeFirstChar(
        mapDetails?.location?.country
      );
    }

    return location;
  }

  const isValidLocation =
    mapDetails?.gpsCoordinates?.coordinates[1] !== 0 &&
    mapDetails?.gpsCoordinates?.coordinates[0];

  return (
    <div>
      <Grid container={true} spacing={2} className={"pt-4"}>
        <Grid item xs={6} className="mapinfo__separator">
          <div className="card shadow p-3 bg-body rounded">
            <div className="card-header mapdetails__cardbg">
              <h5 className="card-title mapdetails__title">Venue Thumbnail</h5>
            </div>
            <ARWayImage
              src={
                thumbnailFileURL
                  ? thumbnailFileURL
                  : mapDetails?.thumbnail
                    ? mapDetails?.thumbnail
                    : MapThumbnailFG
              }
              className={`card-img-top ${thumbnailFileURL || mapDetails?.thumbnail
                ? "mapdetails__mapthumbnail"
                : "mapdetails__mapthumbnailbgImage"
                }`}
              alt="Venue Thumbail image"
              style={{
                borderRadius: "8px",
                objectFit:
                  thumbnailFileURL || mapDetails?.thumbnail
                    ? "contain"
                    : "none",
              }}
            />
            <div className="card-body">
              <div className="d-inline-flex align-items-center">
                <div className="mapinfo__mapImageControlOverlay m-0">
                  <label htmlFor="thumbnail-input" id="label-uploadThumbnail">
                    <span className="mapinfo__mapImageControlOverlay--text">
                      {mapDetails.isPublished
                        ? "Change Thumbnail"
                        : thumbnailStatus
                          ? "Uploading..."
                          : "Select Thumbnail"}
                    </span>
                  </label>
                  <input
                    id="thumbnail-input"
                    type="file"
                    accept=".png,.jpg,.jpeg"
                    onChange={handleFileChange}
                  />
                </div>
                {!isEqual(prevThumbnailFile.current, thumbnailFile) ? (
                  <div
                    className="ps-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      createAsset("thumbnail");
                    }}
                  >
                    <div className="mapinfo__mapImageControlOverlay m-0">
                      <label>
                        <span
                          className="mapinfo__mapImageControlOverlay--text"
                          id="thumbnail-save"
                        >
                          Save
                        </span>
                      </label>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6} className="mapinfo__separator">
          <React.Fragment>
            <div
              className="card shadow p-3 bg-body rounded"
              style={{ height: "100%" }}
            >
              <div className="card-header mapdetails__cardbg">
                <h5 className="card-title mapdetails__title">Venue Location</h5>
              </div>
              <img
                alt={isValidLocation ? "" : "No Location"}
                src={isValidLocation ? "" : NoLocationFG}
                className={`card-img-top  ${isValidLocation ? "" : "mapdetails__mapboxbgImage"
                  }`}
              />
              <div className={`card-body ${isValidLocation ? "p-0" : ""}`}>
                {isValidLocation ? (
                  <MapBox
                    lat={mapDetails?.gpsCoordinates?.coordinates[1]}
                    lon={mapDetails?.gpsCoordinates?.coordinates[0]}
                  />
                ) : null}
                {mapDetails?.location ? (
                  <div className="mt-3">
                    <label
                      className="content-type-select"
                      style={{ lineHeight: "normal" }}
                    >
                      <LocationIcon />{" "}
                      {getLocationString()}
                    </label>
                  </div>
                ) : (
                  "No Location Available"
                )}
              </div>
            </div>
          </React.Fragment>
        </Grid>
        <Grid item xs={12} className="shadow p-3 bg-body rounded">
          <div className="card-header mapdetails__cardbg">
            <div className="d-flex justify-content-between">
              <h5 className="card-title mapdetails__title">Floor Plans</h5>
              <h5 className="card-title mapdetails__title">
                <Dropdown
                  list={mapDetails?.maps.map((m, i) => {
                    return {
                      value: `${i}-${m.identifier}`,
                      label: `${m.identifier} - ${m.mapName}`,
                    };
                  })}
                  setvalue={(val) => {
                    setLevel(val);
                    setFloorplan(null);
                    setFloorplanFile(null);
                    setFloorplanFileURL(null);
                  }}
                />
              </h5>
            </div>
          </div>
          {mapD?.metadata?.floor?.length > 0 ||
            floorplanFileURL ||
            floorplan ? (
            <div className="p-3 bg-body rounded">
              <ARWayImage
                src={
                  floorplanFileURL
                    ? floorplanFileURL
                    : floorplan
                      ? floorplan.link
                      : ""
                }
                className="card-img-top mapdetails__mapFloorplan"
                alt="Floor plan image"
              />
              <div className="card-body">
                <h5 className="card-title pt-3">
                  {floorplanFile
                    ? floorplanFile?.name
                    : mapDetails?.maps?.find(
                      (m) => m.identifier === level.split("-")[1]
                    )
                      ? mapDetails?.maps?.find(
                        (m) => m.identifier === level.split("-")[1]
                      )["mapName"]
                      : ""}
                </h5>
                <div className="d-inline-flex align-items-center pt-4">
                  <div className="mapinfo__mapImageControlOverlay m-0">
                    <div
                      style={{ cursor: "pointer" }}
                      className="dashboardpage__dpIntroCard--button"
                      onClick={() => toggleShowFloorModal(true)}
                    >
                      {floorplan || floorplanFileURL
                        ? "Change Floor plan"
                        : floorplanStatus
                          ? "Uploading..."
                          : "Select Floor plan"}
                    </div>
                  </div>
                  {(floorplanFile && !prevFloorPlanFile.current) ||
                    !isEqual(prevFloorPlanFile.current, floorplanFile) ? (
                    <div
                      className="ps-3"
                      style={{ cursor: "pointer" }}
                      onClick={(e) => {
                        if (
                          mapD.metadata.isPublished &&
                          has(mapD?.metadata, "floors") &&
                          mapD?.metadata?.floors?.length > 0
                        ) {
                          handletoggleModal("replaceFloorplanModal");
                        } else {
                          if (floorplanFile.isUrl) handleSampleFloorReplace();
                          else createAsset("floorplan");
                        }
                      }}
                    >
                      <div className="mapinfo__mapImageControlOverlay m-0">
                        <label
                          style={{ cursor: "pointer" }}
                          className={`dashboardpage__dpIntroCard--button dashboardpage__dpIntroCard--button-w120 ${floorplanStatus && "dashboardpage__dpIntroCard--button--disabled"}`}
                          id="save-floorplan"
                        >
                          {floorplanStatus ?<Loader className="dashboardpage__dpIntroCard--loader" /> : "Save" }
                        </label>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          ) : (
            <div className="p-3 mb-3 bg-body rounded mapdetails__mapDefaultFloorplan">
              <div className="pb-4">Uploaded Floor plan</div>
              <FloorplanIcon />
              <label
                className="content-type-select mt-4"
                style={{ lineHeight: "normal" }}
              >
                No floor plan uploaded yet. Tap the button to upload a floor
                plan.
              </label>
              <div className="pt-4">
                <div className="mapinfo__mapImageControlOverlay m-0">
                  <div
                    style={{ cursor: "pointer" }}
                    className="dashboardpage__dpIntroCard--button"
                    onClick={() => toggleShowFloorModal(true)}
                  >
                    Upload New Floor plan
                  </div>
                </div>
              </div>
            </div>
          )}
        </Grid>
        <Grid item xs={12}>
          <div className="shadow p-3 mb-3 mt-2 bg-body rounded">
            <div className="mapinfo__mDescCont">
              <div className="mapdetails__title">
                <span className="mapinfo__mDescCont--labelSpan">
                  Description
                </span>
                {isEditDescription && (
                  <span className="mapinfo__mDescCont--inputLenHelper">
                    {description?.length}/400
                  </span>
                )}
              </div>
              <textarea
                rows={3}
                id="description"
                readOnly={!isEditDescription}
                value={description}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
                className={`mapinfo__mapFormTextArea ${isEditDescription
                  ? "mapinfo__mapFormTextArea--active"
                  : "mapinfo__mapFormTextArea--inactive"
                  }`}
                autoComplete="off"
                maxLength={400}
              />
              <div className="mapinfo__mapImageControlOverlay m-0">
                <label
                  onClick={() => {
                    setisEditDescription(!isEditDescription);
                  }}
                >
                  {isEditDescription ? (
                    <span
                      className="mapinfo__mapImageControlOverlay--text"
                      id="span-saveDescription"
                      onClick={(e) => {
                        handleDescriptionOnclick(e);
                        setisEditDescription(!isEditDescription);
                      }}
                    >
                      Save Description
                    </span>
                  ) : (
                    <span
                      className="mapinfo__mapImageControlOverlay--text"
                      id="span-editDescription"
                    >
                      Edit Description
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div className="mapinfo__mIdDataContainer">
              <div className="mapinfo__mIdDataContainer--dataCont">
                <div className="mapinfo__mIdDataContainer--label">Venue ID</div>
                <div className="mapinfo__mIdDataContainer--data">
                  {mapDetails?.id}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            className="shadow p-3 mb-3 mt-2 bg-body rounded"
            style={{ height: "220px" }}
          >
            <div className="mapinfo__mDescCont">
              <div className="mapdetails__title">
                <span className="mapinfo__mDescCont--labelSpan">
                  Overview File
                </span>
              </div>
              <div className="pt-4 pb-4">
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-start">
                      {/* <div
                      style={{
                        backgroundImage: `url(${blankSVG})`,
                      }}
                    ></div> */}
                      <div className="pe-4">
                        <img src={blankSVG} alt="React Logo" />
                      </div>
                      <div className="pt-1">
                        {`File :`}
                        <label
                          className={`p-2 ps-3`}
                          id="label-call"
                          style={{ color: "#2A4DC2" }}
                        >
                          {mapDetails?.file || overviewFile
                            ? `${overviewFile
                              ? overviewFile.name
                              : mapDetails?.file?.substring(
                                mapDetails?.file?.lastIndexOf("-") + 1,
                                mapDetails?.file?.length
                              )
                            }`
                            : "File preview"}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start">
                <div
                  className="mapinfo__mapImageControlOverlay m-0"
                  style={{ width: "20%" }}
                >
                  <div className="d-inline-flex align-items-center">
                    <div className="mapinfo__mapImageControlOverlay m-0">
                      <label htmlFor="file-input" id="label-uploadThumbnail">
                        <span className="mapinfo__mapImageControlOverlay--text">
                          {overviewFile || overviewFileURL
                            ? "Change File"
                            : overviewFileStatus
                              ? "Uploading..."
                              : "Select File"}
                        </span>
                      </label>
                      <input
                        id="file-input"
                        type="file"
                        accept=".png,.jpg,.jpeg"
                        onChange={handleFileChange}
                      />
                    </div>
                  </div>
                </div>
                {!isEqual(prevOverviewFile.current, overviewFile) ? (
                  <div
                    className="ps-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      createAsset("file");
                    }}
                  >
                    <div className="mapinfo__mapImageControlOverlay m-0">
                      <label>
                        <span
                          className="mapinfo__mapImageControlOverlay--text"
                          id="thumbnail-save"
                        >
                          Save
                        </span>
                      </label>
                    </div>
                  </div>
                ) : null}
                <div
                  className="mapinfo__mapImageControlOverlay m-0"
                  style={{ paddingLeft: "10%" }}
                >
                  {mapDetails?.file ? (
                    <label>
                      <span
                        className="mapinfo__mapImageControlOverlay--text"
                        id="span-editDescription"
                      >
                        <a
                          href={mapDetails?.file}
                          className="mapinfo__mapImageControlOverlay--text"
                          target="_blank"
                          rel="noopener noreferrer"
                          download={`${mapDetails?.file?.substring(
                            mapDetails?.file?.lastIndexOf("-") + 1,
                            mapDetails?.file?.length
                          )}`}
                        >
                          Download File
                        </a>
                      </span>
                    </label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={6}>
          <div
            className="shadow p-3 mb-3 mt-2 bg-body rounded"
            style={{ height: "220px" }}
          >
            <div className="mapinfo__mDescCont">
              <div className="mapdetails__title">
                <div className="d-flex justify-content-between">
                  <span className="mapinfo__mDescCont--labelSpan">Contact</span>
                  <div>
                    {editContact ? (
                      <Save
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // if (
                          //   contactMobile &&
                          //   contactEmail &&
                          //   contactWebsite
                          // ) {
                          if (inputError.email === false || inputError.url === false) return;
                          dispatch(
                            updateVenueMap({
                              mapId: mapData,
                              venueDetails: {
                                ...mapDetails,
                                mobile: contactMobile,
                                email: contactEmail,
                                website: contactWebsite,
                              },
                            })
                          ).then(() => {
                            setEditContact(!editContact);
                          });
                          // } else {
                          //   dispatch(
                          //     setNewAlert({
                          //       msg: "Update the missing details",
                          //       alertType: "danger",
                          //     })
                          //   );
                          // }
                        }}
                        id="button-saveContact"
                      />
                    ) : (
                      <Edit2
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEditContact(!editContact);
                        }}
                        id="button-editContact"
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <div
                  className="d-flex justify-content-start pb-2"
                  style={{ paddingLeft: "6px" }}
                >
                  <div className="p-2">Call</div>
                  {editContact ? (
                    <input
                      type="text"
                      readOnly={!editContact}
                      value={contactMobile}
                      onChange={(e) => {
                        const re = /^[0-9\b]+$/;
                        if (e.target.value === "" || re.test(e.target.value)) {
                          setContactMobile(e.target.value);
                        }
                      }}
                      className={`${editContact
                        ? isEmpty(contactMobile)
                          ? "mapinfo__mapFormInput--active ps-3"
                          : "mapinfo__mapFormInput--active ps-3"
                        : ""
                        }`}
                      style={{ width: "50%", marginLeft: "1.75rem" }}
                      autoComplete="off"
                      maxLength={15}
                      id="mobile"
                    />
                  ) : (
                    <label
                      className={`p-2 ps-4`}
                      id="label-call"
                      style={{ color: "#2A4DC2" }}
                    >
                      {contactMobile}
                    </label>
                  )}
                </div>
                <div
                  className="d-flex justify-content-start pb-2"
                  style={{ paddingLeft: "6px" }}
                >
                  <div className="p-2">Email</div>
                  {editContact ? (
                    <input
                      type="text"
                      readOnly={!editContact}
                      value={contactEmail}
                      onChange={(e) => {
                        const text = e.target.value.trim();
                        let valid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text);
                        if (text.length === 0) valid = null;
                        setInputError({ ...inputError, email: valid });
                        setContactEmail(text);
                      }}
                      className={`${editContact
                        ? isEmpty(contactEmail)
                          ? "mapinfo__mapFormInput--active ms-3 ps-3"
                          : "mapinfo__mapFormInput--active ms-3 ps-3"
                        : ""
                        } ${inputError.email === false ? "mapinfo__mapFormInput--error" : ""}`}
                      style={{ width: "50%" }}
                      autoComplete="off"
                      id="email"
                    />
                  ) : (
                    <label
                      className={`p-2 ps-3`}
                      id="label-call"
                      style={{ color: "#2A4DC2" }}
                    >
                      <a className="mapinfo__mapFormInput--wrapLongText" style={{textDecoration: "none"}} href={contactEmail ? `mailto:${contactEmail}` : ""} target="_blank" rel="noreferrer" title={contactEmail}>{contactEmail}</a>
                    </label>
                  )}
                </div>
                <div
                  className="d-flex justify-content-start pb-1"
                  style={{ paddingLeft: "6px" }}
                >
                  <div className="p-2">Visit</div>
                  {editContact ? (
                    <input
                      type="text"
                      readOnly={!editContact}
                      value={contactWebsite}
                      onChange={(e) => {
                        let text = e.target.value.trim();
                        let valid = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi.test(text);
                        if (text.length === 0) valid = null;
                        setInputError({ ...inputError, url: valid });
                        setContactWebsite(text);
                      }}
                      className={`${editContact
                        ? isEmpty(contactWebsite)
                          ? "mapinfo__mapFormInput--active ps-3"
                          : "mapinfo__mapFormInput--active 4 ps-3"
                        : ""
                        } ${inputError.url === false ? "mapinfo__mapFormInput--error" : ""}`}
                      style={{ width: "50%", marginLeft: "1.7rem" }}
                      autoComplete="off"
                      id="website"
                    />
                  ) : (
                    <label
                      className={`p-2 ps-4`}
                      id="label-call"
                      style={{ color: "#2A4DC2" }}
                    >
                      {contactWebsite ? (<span className="mapinfo__mapFormInput--wrapLongText" onClick={() => {
                        let link = contactWebsite;
                        if (contactWebsite && !contactWebsite.includes("https://")) {
                          link = `https://${contactWebsite}`;
                        }
                        window.open(link, "_blank", "noreferrer");
                      }} title={contactWebsite}>{contactWebsite}</span>) : ""}
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="shadow p-3 mb-5 bg-body rounded">
            <div className="mapdetails__title">
              <div className="d-flex justify-content-between">
                <span className="mapinfo__mDescCont--labelSpan">
                  Venue Removal
                </span>
              </div>
            </div>
            <div className="mapinfo__mDataContainer--header pt-4">
              Delete Venue
            </div>
            If you Delete this venue map, floor maps inside will appear in my
            map as a single-floor map
            <div className="mapinfo__mDeleteCont">
              <Button
                variant="outlined"
                className="mapinfo__mDeleteCont--span"
                style={{ boxShadow: "0px 0px 2px 2px red" }}
                onClick={() => handletoggleModal("deleteModal")}
                id="button-deleteMap"
              >
                <span className="mapinfo__mDescCont--labelSpan">
                  Delete Venue
                </span>
              </Button>
            </div>
          </div>
        </Grid>
      </Grid>
      <SampleFloorplans
        openModal={showFloorModal}
        onSuccess={onSampleFloorSuccess}
        onCloseModal={() => toggleShowFloorModal(false)}
        modalData={mapData}
      />
    </div>
  );
};

export default VenueDetailsTab;
