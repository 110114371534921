import * as THREE from 'three';
import EventEmitter from "../../utils/EventEmitter";
import EditorExperience from '../2DEditorExperience';

export default class CanvasFloorplan extends EventEmitter {
    constructor(floorData) {
        super();

        this.imageMesh = null;

        this.editor = new EditorExperience();

        this.type = 'floorplan'; 
        this.assetLink = floorData.link;
        this.visible = floorData.visible || true;
        this.texLoader = new THREE.TextureLoader();

        this.editor.manualAligned = floorData.manualAligned || 'no_change';
        // this.editor.manualAligned = '2d_change';

        this.setupObject();
    }
    
    setupObject = () => {
        let imgMesh
        this.texLoader.load(this.assetLink, (imgTex) => {
            imgTex.minFilter = imgTex.magFilter = THREE.LinearFilter;
            imgTex.colorSpace = THREE.SRGBColorSpace;
            imgTex.anisotropy = 4;

            let imgMat = new THREE.MeshBasicMaterial({ map: imgTex, side: THREE.DoubleSide});
            let imgGeo = new THREE.PlaneGeometry(imgTex.image.width, imgTex.image.height, 32, 32)

            imgMesh = new THREE.Mesh(imgGeo, imgMat);
            this.imageMesh = new THREE.Group();
            this.imageMesh.userData['viewType'] = this.type
            this.imageMesh.userData['navHelper'] = this.type;
            this.imageMesh.userData['skipScene'] = true;
            this.imageMesh.visible = this.visible;
            this.imageMesh.userData['visible'] = this.visible;
            this.imageMesh.add(imgMesh);

            this.imageMesh.position.set(imgTex.image.width/2, -imgTex.image.height/2, 0)

            this.editor.trigger('setFloorplan', [ this.imageMesh ]);
            this.editor.camera.controls.target.set(imgTex.image.width/2, -imgTex.image.height/2, 0)
            this.editor.camera.instance.position.set(imgTex.image.width/2, -imgTex.image.height/2, imgTex.image.height);
            // this.editor.camera.instance.lookAt(imgTex.image.width/2, -imgTex.image.height/2, 0);
            this.editor.camera.controls.update();

            // limit controls to zoom out only upto 4th times of img height!
            const maxSize = Math.max(imgTex.image.width, imgTex.image.height);
            this.editor.camera.controls.maxDistance = (maxSize); 
            this.editor.camera.instance.far = (maxSize); 
            this.editor.camera.instance.updateProjectionMatrix();

            // store image aspect ratio
            this.editor.floorData['SCALE_SIZE'] = imgTex.image.width/imgTex.image.height;
            this.editor.floorData['MAX_SIZE'] = maxSize;
            this.editor.floorData['MIN_SIZE'] = Math.min(imgTex.image.width, imgTex.image.height);

            this.editor.floorData['imgWidth'] = imgTex.image.width;
            this.editor.floorData['imgHeight'] = imgTex.image.height;

            this.editor.trigger('initiateLoading');
        });
    }
}