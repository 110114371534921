import * as THREE from 'three';

const disposeGeometry = (geometry) => geometry.dispose();

const disposeMaterial = (mat) => {
    if(mat.map) mat.map.dispose();
    mat.dispose()
};

/* 
NOTE: Cannot dispose QRAnchors, 
    IAPS, Location Pins(OLD), Guided Tours, MapMarkers
    (Since they are preloaded models closed and used at all places)
*/

const dispose = (object) => {
    if(object instanceof THREE.Group) {
        if(object.children.length) {
            object.children.forEach(child => dispose(child))
        }
    } else if(object instanceof THREE.Mesh) {
        if(object.geometry) {
            disposeGeometry(object.geometry);
        }

        if(object.material) {
            if (object.material.length) {
                for (let i = 0; i < object.material.length; ++i) {
                    disposeMaterial(object.material[i]);                             
                }
            } else {
                disposeMaterial(object.material);                       
            }
        }
        return true;
    }
    return false;
}

export { dispose };