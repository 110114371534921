import React, { useEffect, useState } from "react";
import ModelViewer from "../../../common/ModalViwerFor3d/ModalViewer";
import ErrorBoundary from "./ErrorBoundary";
import { ReactComponent as ModalCloseBtnIcon } from "../../../../assets/svgs/ModalCloseBtnIcon.svg";
import ARWayImage from "../../../common/CO/ARWayImage";

const ModelPreviewModal = ({ open, onClose, item }) => {
  const [data, setData] = useState("");
  useEffect(() => {
    if (item) {
      if (item.path !== data) {
        setData(item.path);
      }
    }
    // eslint-disable-next-line
  }, [item, item?.path]);
  return (
    <div className={`model-preview ${open ? "open" : "close"}`}>
      <div>
        <button className="btnclose" onClick={onClose}>
          <ModalCloseBtnIcon />
        </button>
      </div>
      <h1 className="model-preview-text">{item?.fileName}</h1>
      <div className="modal-preview-thumbnail">
        {(item?.contentType?.toLowerCase() === "images" || item?.contentType?.toLowerCase() === "floorplans") && (
          <ErrorBoundary color="#000">
            <div className="thumbnail-img">
              {/* <img src={item?.path} alt="" /> */}
              <ARWayImage src={item?.path} alt="" />
            </div>
          </ErrorBoundary>
        )}
        {item?.contentType?.toLowerCase() === "models" && (
          <ErrorBoundary color="#000">
            <ModelViewer scale="7.5" modelPath={data} height={400} />
          </ErrorBoundary>
        )}
      </div>
    </div>
  );
};

export default ModelPreviewModal;
