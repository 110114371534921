import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
// import * as THREE from 'three';

import { UIPanel, UILibraryCardModel, UITextHeader } from "../ui.lib";

class SidebarContent3DyModel extends EventEmitter {
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.dom = null;

        this.contentList = [];

        this.contentState = "";
        this.contentType = "models";

        this.initUI();

        this.editor.on("toggleMenuActive", this.onToggleMenuActive);
        this.editor.on("fetch3DyLibraryContentsResponse", this.updateData);
    }

    initUI = () => {
        this.uiMyContent = new UIPanel();

        this.uiLibraryHeader = new UITextHeader(undefined, "Assets Library");

        this.uiMyContent.addChild(this.uiLibraryHeader);

        this.dom = this.uiMyContent.dom;
    };

    fetchData = () => {
        this.editor.trigger("fetch3DyLibraryContentsRequest");
        this.contentState = "LOADING";
        this.updateContentLibraryUI();
    };

    updateData = (data) => {
        if (data.length === 0) {
            this.contentState = "NO DATA";
        } else {
            this.contentList.push(...data);
            this.contentList = [
                ...new Map(
                    this.contentList.map((item) => [item["job_id"], item])
                ).values(),
            ];
            this.contentState = "LOAD DATA";
        }
        this.updateContentLibraryUI();
    };

    emptyContent = () => {
        var child = this.uiMyContent.dom.lastChild;
        while (child) {
            if (child === this.uiLibraryHeader.dom) break;
            this.uiMyContent.dom.removeChild(child);
            child = this.uiMyContent.dom.lastChild;
        }
    };

    updateContentLibraryUI = () => {
        if (this.contentState === "LOADING") {
            this.emptyContent();
            const uiNoDataCard = document.createElement("div");
            uiNoDataCard.className = "ws_uiNoDataCard";

            const uiHeader = document.createElement("div");
            uiHeader.classList.add("ws_uiNDHeader");
            uiHeader.textContent = "Loading Assets...";

            uiNoDataCard.appendChild(uiHeader);

            this.uiMyContent.dom.appendChild(uiNoDataCard);
        } else if (this.contentState === "NO DATA") {
            this.emptyContent();
            const uiNoDataCard = document.createElement("div");
            uiNoDataCard.className = "ws_uiNoDataCard";

            const uiHeader = document.createElement("div");
            uiHeader.classList.add("ws_uiNDHeader");
            uiHeader.textContent = "No Assets Yet";

            const uiSubText = document.createElement("div");
            uiSubText.classList.add("ws_uiNDSubText");
            uiSubText.textContent = "Library assets will appear here!";

            uiNoDataCard.appendChild(uiHeader);
            uiNoDataCard.appendChild(uiSubText);

            this.uiMyContent.dom.appendChild(uiNoDataCard);
        } else {
            const uiCardsRow = new UIPanel();
            uiCardsRow.addClass("ws_uiCardsRowFloat");
            this.emptyContent();
            for (const model of this.contentList) {
                const uiImageCont = new UILibraryCardModel(
                    model,
                    model.files[0].signed_url,
                    model.title,
                    this.onLibray3DyModelSelect
                );
                uiCardsRow.addChild(uiImageCont);
            }

            this.uiMyContent.addChild(uiCardsRow);
        }
    };

    onLibray3DyModelSelect = (selectedContent) => {
        this.editor.loader.initObjectLoading({
            ...selectedContent,
            contentType: "3DyModels",
        });
    };

    refreshUI = () => {
        this.contentList = [];
        this.contentState = "";
        this.fetchData();
    };

    onToggleMenuActive = () => {};

    unload = () => {
        this.contentList = [];
        this.contentState = "";
        this.emptyContent();
    };
}

export { SidebarContent3DyModel };
