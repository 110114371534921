import axios from "axios";
import { REACT_APP_THREEDY_BASEURL, REACT_APP_THREEDY_AUTH_URL } from "../values/constants";
import { threeDyBlob } from "../values/threedyBlob";


const axiosInstance = axios.create({
    baseURL: REACT_APP_THREEDY_BASEURL,
    headers: {
        'Content-Type': 'application/json',
    }
});

axiosInstance.interceptors.request.use((config) => {
    const authToken = localStorage.getItem('threeDyToken');
    config.headers.Authorization = authToken ? `Bearer ${authToken}` : '';
    return config;
});


axiosInstance.interceptors.response.use(response => response, (error) => {
    const originalRequest = error.config;

    if (error.response && error.response.status === 401) {

        return axios
        .post(`${REACT_APP_THREEDY_AUTH_URL}oauth/token`, threeDyBlob)
        .then((response) => {

            localStorage.setItem('threeDyToken', response.data.access_token);

            axiosInstance.defaults.headers['Authorization'] = `Bearer ${response.data.access_token}`; 
            originalRequest.headers['Authorization'] = `Bearer ${response.data.access_token}`;

            return axiosInstance(originalRequest);
        })
        .catch(err => {
            console.log(err, err.response)
        });
    }
    return Promise.reject(error);
})

export default axiosInstance;