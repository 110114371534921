import Server from '../../../api';

class NetworkServices {

    constructor(editor) {
        this.editor = editor;
    }

    createAsset = (reObj, cbProgress, cbSuccess, cbError) => {
        Server.post('/v1/asset/upload', reObj, { onUploadProgress: data => cbProgress(data), headers: { "Content-Type": "multipart/form-data" } })
            .then(res => cbSuccess(res))
            .catch(err => cbError(err))
    }

    createFloorplan = (mapId, reObj, cbProgress, cbSuccess, cbError) => {
        Server.post(`/v1/floorplan/${mapId}`, reObj, { onUploadProgress: data => cbProgress(data) })
            .then(res => cbSuccess(res))
            .catch(err => cbError(err))
    }
}

export { NetworkServices };