import * as THREE from 'three';
import EditorExperience from "../EditorExperience";
import EventEmitter from "../utils/EventEmitter";

import { 
    fromPosObjectToVec3Pos,
    fromScaleObjectToVec3Scale,
    fromQuaternionObjectToQuaternion 
} from './TransformConversions';
import { dispose } from '../utils/Dispose.Utils';

export default class Model extends EventEmitter {
    constructor(modelData) {
        super();
        this.editor = new EditorExperience();
        this.time = this.editor.time;
        this.modelData = modelData;

        this.modelResource = modelData.model;
        this.id = modelData.id;
        this.name = modelData.name.substring(0, 20);
        this.link = modelData.link;

        this.position = modelData.position ? fromPosObjectToVec3Pos(modelData.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = modelData.rotation ? fromQuaternionObjectToQuaternion(modelData.rotation) : new THREE.Quaternion(0, 0, 0, 0);
        this.scale = modelData.scale ? fromScaleObjectToVec3Scale(modelData.scale) : new THREE.Vector3(1, 1, 1);

        this.visible = 'visible' in modelData ? modelData.visible : true;

        this.animation = {};
        this.animationNames = [];

        this.time.on('tick', this.update);
        this.editor.on('startAnimation', this.playAnimation);
        this.editor.on('stopAnimation', this.pauseAnimation);
        this.editor.on('objectRemoved', this.onObjectRemoved);

        this.setModelData();
        this.setAnimation();
    }

    setModelData = () => {
        this.gltfModel = this.modelResource.scene;
        this.gltfModel.name = this.name;
        this.gltfModel.userData['type'] = "model";
        this.gltfModel.userData['id'] = this.id;
        this.gltfModel.userData['link'] = this.link;
        this.gltfModel.userData['visible'] = this.visible;
        this.gltfModel.position.copy(this.position);
        this.gltfModel.scale.copy(this.scale);
        this.gltfModel.quaternion.copy(this.rotation);
        this.gltfModel.visible = this.visible;  
    }

    setLinkAsync = (link) => {
        this.link = link;
        this.gltfModel.userData['link'] = this.link;
    }

    setAnimation = () => {
        if(this.modelResource.animations && this.modelResource.animations.length > 0) {
            this.animation.canPlay = true;
            this.animation.mixer = new THREE.AnimationMixer(this.gltfModel);
            this.animation.actions = {};
            // Set Actions
            this.modelResource.animations.forEach((animation, index) => {
                const animationName = animation.name || `Animation_${index};`
                this.animationNames.push(animationName);
                this.animation.actions[animationName] = this.animation.mixer.clipAction(animation);
            });
            this.animation.actions.current = null;
            this.gltfModel.userData['animations'] = this.animationNames;
            // console.log(this.animation.actions);
        } else {
            this.animation.canPlay = false;
        }
    }

    playAnimation = (object, name) => {
        if(object === this.gltfModel) {
            const index = this.animationNames.indexOf(name);
            if(index !== -1) {
                const newAction = this.animation.actions[name];
                if(this.animation.actions.current) {
                    const oldAction = this.animation.actions.current;

                    newAction.reset();
                    newAction.play();
                    newAction.crossFadeFrom(oldAction, 1);
                } else {
                    this.animation.actions.current = newAction;
                    this.animation.actions.current.play();
                }
            }
        }
    }

    pauseAnimation = (object) => {
        if(object === this.gltfModel) {
            if(this.animation.actions.current) {
                this.animation.actions.current.reset();
                this.animation.actions.current.stop();
            }
        }
    }

    update = () => {
        this.animation.canPlay === true && this.animation.mixer && this.animation.mixer.update(this.time.delta * 0.001);
    }

    onObjectRemoved = (object) => {
        if(object === this.gltfModel) {
            this.time.stop('tick', this.update);
            this.editor.stop('startAnimation', this.playAnimation);
            this.editor.stop('stopAnimation', this.pauseAnimation);
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            dispose(this.gltfModel);
        }
    }
}