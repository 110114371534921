import { Button, Form, Input, Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useRef } from 'react'
import SvgIcn from '../../../assets/svgs/ARWay_Primary_Logo_Brand.svg'
import { verify2FAResponse, verify2FA, delete2faResponse, resend2FAResponse, resend2FACode, deleteResend2faResponse } from "../../../features/auth/authSlice";

// Routes
import {
    LOGIN,
} from '../../_utils/routes';


const TwoFAHome = () => {
    

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const validateResponse = useSelector(verify2FAResponse)
    const resend2faResponse = useSelector(resend2FAResponse)

    const email = localStorage.getItem('user-email')
    const [otpResent, setOtpResent] = useState()
    const [typing, setTyping] = useState(false)
    const [error, setError] = useState({err:false, errMsg:null})
    const [otp, setOtp] = useState(null)
    const [fadeoutTrigger, setFadeoutTrigger] = useState(false)
    var fadeRef = useRef()

    useEffect(() => {
        if(validateResponse && validateResponse.code>299){
            setError({err:true, errMsg:validateResponse?.message})
        }
        if(validateResponse && (!validateResponse.code)){
            navigate(LOGIN)
            dispatch(delete2faResponse())
        }
        
    },[validateResponse, dispatch, navigate])

    useEffect(() => {
        if(resend2faResponse !== null) {
            setOtpResent(true)

            setTimeout(() => {
                setOtpResent(false)
                setFadeoutTrigger(false)
                dispatch(deleteResend2faResponse())
            },3500)

        }
    }, [resend2faResponse, dispatch]);

    const resendOtp = () => {
        dispatch(resend2FACode({email: email}))
    }

    const handleSubmit = (e) => {
        if(!otp){
            setError({err:true, errMsg:'Enter OTP'})
        }
        dispatch(verify2FA(otp))
        e.preventDefault()
    }

    return(
        <Layout className="main">
            <Layout className="form-containers">
                {otpResent ? (
                    <div ref={fadeRef} className={fadeoutTrigger ? 'email-sent fade-out' : 'email-sent' }>
                        Email has been sent.
                    </div>
                ) : null}
                <img className="brand-logo" alt="brand-logo-3" src={SvgIcn} />
                <h5 className="login-header" id="h5-2faLogin">User Authentcation</h5>
                <p className="verify-guide">Enter the 6 digit verification code sent to your email.<br /> Don't see it? Make sure to check spam, junk and promotional folders.</p>
                    <form onSubmit={handleSubmit}>
                    <Layout className="input-label-container">
                        {typing?(<label className="typing-label">Verification Code</label>):null}
                        <Input 
                            type="number" 
                            className={`text-inputs ${error.err ? `error` : null } `}
                            placeholder="Verification Code"
                            maxLength={6}
                            value={otp}
                            onChange={(e) => {
                                if(e.target.value.length <= 6) {
                                    setOtp(e.target.value)
                                }
                                setError({err:false, errMsg:null})
                            }}
                            onFocus={() => {
                                setTyping(true)
                                setFadeoutTrigger(true)
                            }}
                            onBlur={() => {
                                setTyping(false)
                            }}
                        />
                        {error.err?(<label className="errors errors--errorCaps" id='error-2fa'>{error.errMsg}</label>):null}
                    </Layout>
                    <Form.Item>
                        <Button className="signin-button" type="primary" htmlType="submit">
                            <span className="signin-button--spanText"> Verify </span>
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button onClick={resendOtp} className="reset-email-button login-btn" type="primary">
                            Resend email
                        </Button>
                    </Form.Item>
                </form>
            </Layout>
        </Layout>
    )
}

export default TwoFAHome;