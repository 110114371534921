import React, { useState, useEffect, useMemo } from "react";
import { 
    Backdrop, Box, 
    Modal, Fade,
    Button
} from "@mui/material";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { XCircle } from "react-feather";
import { useDispatch, useSelector } from 'react-redux';
import AccessDone from '../../../../assets/svgs/check_done.svg';
import { setNewAlert } from "../../../../features/common/alertSlice";

import { 
    createConnectorGroups, 
    getConnectorGroups 
} from '../../../../features/webstudio/buildingSlice'
import { sortConnectorGroupDropDownOptions } from "../../2d-editor/threeUtils/Connectors/ConnectorUtils";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    maxHeight: "80% !important",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    borderRadius: "16px",
    overflowY: 'auto',
};

const ContinueConnectorModal = ({ openModal, onCloseModal, modalData, onSuccess }) => {
    const dispatch = useDispatch();

    const textData = {
        elevator: {
            successToast: 'Elevator pin successfully placed!',
            headerText: 'Add a new elevator pin to this group?',
            subHeader: 'Choose the floor map the next elevator is located on, then place the pin at the elevator location to automatically add it to this group.',
            helperText: 'Choose the floor to place more elevator pins to this elevator group'
        },
        escalator2: {
            successToast: 'First Pin placed!',
            headerText: 'Place the connecting pin',
            subHeader: 'Choose the floor map the other end of the escalator is on, then place this pin at the other end of this escalator to create the pair.',
            helperText: 'Choose the floor to place next escalator pin from this escalator pair'
        },
        escalator1: {
            successToast: 'Escalator pair successfully placed!',
            headerText: 'Link more escalators to this pair?',
            subHeader: 'Do you have more escalators you’s like to link to this pair? E.g. Escalators in the same location on additional floors',
            helperText: 'Create a group for this set, then select a floor map to add another pair to this group'
        },
        stairs2: {
            successToast: 'First Pin placed!',
            headerText: 'Place the connecting pin',
            subHeader: 'Choose the floor map the other end stairs is on, then place this pin at the other end of this stairs to create the pair.',
            helperText: 'Choose the floor to place next stairs pin from this stairs pair'
        },
        stairs1: {
            successToast: 'Stairs pair successfully placed!',
            headerText: 'Link more stairs to this pair?',
            subHeader: 'Do you have more stairs you’s like to link to this pair? E.g. Stairs in the same location on additional floors',
            helperText: 'Create a group for this set, then select a floor map to add another pair to this group'
        },
        bridge2: {
            successToast: 'First Pin placed!',
            headerText: 'Place the connecting pin',
            subHeader: 'Choose the floor map the other end bridge is on, then place this pin at the other end of this bridge to create the pair.',
            helperText: 'Choose the floor to place next bridge pin from this bridge pair'
        },
        bridge1: {
            successToast: 'Bridge pair successfully placed!',
            headerText: 'Link more bridge to this pair?',
            subHeader: 'Do you have more bridge you’s like to link to this pair? E.g. Bridge in the same location on additional floors',
            helperText: 'Create a group for this set, then select a floor map to add another pair to this group'
        }
    }

    const groupContent = useSelector(state => state.building.connectorGroups)

    const [ activeGroup, setActiveGroup ] = useState(null);
    const [ activeFloor, setActiveFloor ] = useState(null);
    const [ floorList, setFloorList ] = useState([]);
    const [ groupList, setGroupList ] = useState([]);
    const [ connectorType, setConnectorType ] = useState('elevator');
    const [ pairStep, setPairStep ] = useState(1);
    const [ hideExit, setHideExit ] = useState(false);

    // select options
    const [ selectedFloor, setSelectedFloor ] = useState({});
    const [ selectedGroup, setSelectedGroup ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDisabled, setIsDisabled ] = useState(false);
    const [ floorOptions, setFloorOptions ] = useState([]);
    const [ groupOptions, setGroupOptions ] = useState([]);

    const texts = useMemo(()=>{
        if(connectorType === 'elevator') {
            return textData[connectorType];

        } else if(connectorType === 'escalator' 
            || connectorType === 'stairs' 
            || connectorType === 'bridge') {

            return textData[`${connectorType}${pairStep}`]
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [connectorType, pairStep])

    const generateOptions = (options) => {
        const newOptn =  options.map(op => ({
            label: op.mapId === activeFloor?.mapId ? `${op.identifierName} (Current)`:  op.identifierName,
            value: op.mapId,
            disabled: !op.isFloorAligned
        }))
        return newOptn;
    }

    const generateGrpOptions = (options) => {
        const newOptn =  options.map(op => ({
            label: op.id === activeGroup?.id ? `${op.name} (Current)`:  op.name,
            value: op.id,
        }))
        return newOptn;
    }

    const handlePlace = () => {
        let sObj = {}
        if(selectedFloor.value !== activeFloor?.mapId) {
            sObj.floorData = floorList.find(f => f.mapId === selectedFloor.value);
            sObj.groupData = groupList?.find(f => f?.id === selectedGroup?.value);
            onSuccess(sObj);
        } else {
            dispatch(setNewAlert({
                msg: 'Please select different Level',
                alertType: 'information'
            }))
        }
    }

    const handleCreate = (name) => {
        if(name.length) {
            setIsLoading(true);
            setIsDisabled(true);
            dispatch(createConnectorGroups({
                buildingId: modalData.buildingId,
                connectObj: { name, connectorType }
            })).then(() => {
                setIsLoading(false);
                setIsDisabled(false);
                dispatch(getConnectorGroups({buildingId: modalData.buildingId}))
            })
        }
    }

    const handleClose = (e, reason) => {
        if(reason && reason === 'backdropClick') return
        onCloseModal()
    }

    useEffect(() => {
        // console.log(groupContent);
        if(groupContent && groupContent.length && connectorType) {
            setGroupList(sortConnectorGroupDropDownOptions(groupContent, connectorType))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ groupContent ]);

    useEffect(() => {
        if(activeFloor && floorList.length && floorList.length !== floorOptions.length) {
            setFloorOptions(generateOptions(floorList))
        } else if(activeFloor && floorList.length === floorOptions.length) {
            setSelectedFloor(floorOptions.find(f => f.value === activeFloor.mapId))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [floorList, activeFloor, floorOptions])

    useEffect(() => {
        if(activeGroup && groupList.length && groupList.length !== groupOptions.length) {
            setGroupOptions(generateGrpOptions(groupList))
        } else if(activeGroup && groupList.length === groupOptions.length) {
            setSelectedGroup(groupOptions.find(f => f.value === activeGroup.id))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupList, activeGroup, groupOptions])


    useEffect(() => {
        if(modalData && openModal) {
            // console.log(modalData);
            setActiveGroup(modalData.selectedGroup);
            setFloorList(modalData.floorList);
            setActiveFloor(modalData.activeFloor);
            setGroupList(modalData.groupList);
            setConnectorType(modalData.connectorType);
            setPairStep(modalData.pairStep);
            modalData.connectorType === 'elevator' &&  modalData.pairStep === 2 ? setIsDisabled(true) : setIsDisabled(false);
            if((modalData.connectorType === 'escalator' || modalData.connectorType === 'stairs' 
                || modalData.connectorType === 'bridge') && modalData.pairStep === 2 ) {
                    setHideExit(true);
            } else setHideExit(false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ modalData ])

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={handleClose}
                        />
                        <div className='cgModal'>
                            <div className='cgModal--cgCard'>
                                <div className='cgModal--cgCard--infoPane'>
                                    <img src={AccessDone} draggable={false} alt='ACCESS' />
                                    <span>{texts.successToast}</span>
                                </div>
                                <h4 className='cgModal--cgCard--title cgModal--cgCard--title--small'>{texts.headerText}</h4>
                                <div className='cgModal--cgCard--header cgModal--cgCard--header--small'>{texts.subHeader}</div>
                                        <div className='cgModal--cgCard--inputCont2'>
                                            <div className='cgModal--cgCard--inputCont2--subText'>{texts.helperText}</div>
                                            {!hideExit && <CreatableSelect 
                                                isDisabled={isDisabled}
                                                isLoading={isLoading}
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        marginTop: '20px',
                                                    }),
                                                }}
                                                onChange={(newValue) => setSelectedGroup(newValue)}
                                                onCreateOption={handleCreate}
                                                options={generateGrpOptions(groupList)}
                                                value={selectedGroup}
                                            
                                            />}
                                            <Select 
                                                styles={{
                                                    control: (baseStyles, state) => ({
                                                        ...baseStyles,
                                                        marginTop: '20px',
                                                    }),
                                                }}
                                                value={selectedFloor}
                                                options={generateOptions(floorList)}
                                                onChange={(e) => setSelectedFloor(e)}
                                                isOptionDisabled={(o) => o.disabled}
                                            />
                                            <div className='cgModal--cgCard--inputCont2--btnDiv'>
                                                <Button variant='contained' color='primaryColors' onClick={handlePlace}>Place it</Button>
                                            </div>
                                        </div>
                                        {!hideExit && <React.Fragment>
                                            <div className='cgModal--cgCard--textSeparator'>- or -</div>
                                            <div className='cgModal--cgCard--closeCont'>
                                                <div className='cgModal--cgCard--closeCont--text'>If you do not have any additional elevators to add to this group or if you’d like to link them later tap “done”   </div>
                                                <button onClick={handleClose}>Done</button>
                                            </div>
                                        </React.Fragment>}
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default ContinueConnectorModal;
