import React from "react";
// import useTextField from "./useTextField";

function TextField({ name, type,config }) {
  return (
    <label className="content-type-select mt-4">
      <div className="mapdetails__title">
        <span className="mapinfo__mDescCont--labelSpan">{name}*</span>
          <span className="mapinfo__mDescCont--inputLenHelper">
            {config.data?.length || 0}/{type === "input" ?'50':'400'}
          </span>
      </div>
      {type === "input" ? (
        <input
          {...config.bindData}
          type="text"
          className="form-control"
          id="inupt-venueName"
          maxLength={50}
        />
      ) : (
        <textarea
          {...config.bindData}
          rows={3}
          id="description"
          className={`mapinfo__mapFormTextArea mapinfo__mapFormTextArea--active`}
          autoComplete="off"
          maxLength={400}
        />
      )}
    </label>
  );
}

export default TextField;
