// import * as THREE from 'three';
import { isEmpty } from 'lodash';
import EventEmitter from "../../../utils/EventEmitter";
import { UIImage, UIPanel, UIRow, UISeparator, UIText } from "../../../ui/ui.lib";
import { checkOnboardingFlag } from "../../threeUtils/Connectors/ConnectorUtils";

//Icon
import LocationIcon from '../../../../../assets/svgs/scenes/menu/navigation.svg';
import WalkableIcon from '../../../../../assets/svgs/scenes/menu/walkable_path.svg';
import AllAccessIcon from '../../../../../assets/svgs/scenes/menu/allaccess_path.svg';
import ConnectIcon from '../../../../../assets/svgs/scenes/menu/connector_pin.svg';
import ChevronIcon from '../../../../../assets/svgs/scenes/chevron.svg';

class Menu2DPaths extends EventEmitter {
    constructor(editor) {
        super();

        this.types = {
            "AllAccess": {
                icon: AllAccessIcon,
                title: "All Access",
                type: 'allAccess',
                hideOnFloorMaps: false,
                mode: 'DEFAULT',
                toDisable: true,
            },
            "Walkable": {
                icon: WalkableIcon,
                title: "Walkable Only",
                type: 'walkable',
                hideOnFloorMaps: false,
                mode: 'DEFAULT',
                toDisable: true,
            },
            "Location": {
                icon: LocationIcon,
                title: 'Location Pin',
                type: 'location',
                hideOnFloorMaps: false,
                mode: 'DEFAULT',
                toDisable: false,
            },
            "Connector": {
                icon: ConnectIcon,
                title: 'Connector Pin',
                type: 'connector',
                hideOnFloorMaps: true,
                mode: 'CONNECTOR',
                toDisable: false,
            },
        }
        this.uiObjs = {};

        this.editor = editor;
        this.dom = null;
        this.name = 'Paths';
        this.isActive = false;
        this.activePathType = "AllAccess"

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('continueConnectorPlacement', this.onContinueConnectorPlacement);
        this.editor.resources.on('ready', this.onReady);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.setClass('ws2DMenuContainer ws2DPathNav');
        this.uiCont.setId('ws2DMenuPaths');  

        this.uiImg = new UIImage(this.types[this.activePathType].icon, '30px', '30px', "Path");
        this.uiCont.addChild(this.uiImg);
        this.uiCont.addChild(new UIImage(ChevronIcon, '18px', '18px').setStyle('padding', '2px'));

        // Paths UI
        this.uiPathsContainer = new UIPanel();
        this.uiPathsContainer.setClass('ws2DPathNav--panel');

        for(const path in this.types) {
            let uiPathCont = new UIRow().setClass('wsPathPanelRow').onClick(() => this.setActivePath(path));
            uiPathCont.addChild(new UIImage(this.types[path].icon, '30px', '30px', null))
            uiPathCont.addChild(new UIText('80%', this.types[path].title));
            path === 'Location' && this.uiPathsContainer.addChild(new UISeparator('wsPathPanelRow--verSeprator'));
            this.types[path].hideOnFloorMaps && this.editor.isFloorMap && uiPathCont.setDisplay('none');
            this.uiPathsContainer.addChild(uiPathCont);
            this.uiObjs[path] = uiPathCont;
        }
        
        this.uiCont.addChild(this.uiPathsContainer);

        this.dom = this.uiCont.dom;
    }

    setActivePath = (path) => {
        if(this.editor.navigationTracking) return;

        this.activePathType = path;
        this.uiImg.setSrc(this.types[path].icon);

        for(const p in this.uiObjs) p === path ? this.uiObjs[p].addClass('wsPathPanelRow--active')  : (this.uiObjs[p].removeClass('wsPathPanelRow--active') && this.types[path].toDisable && this.uiObjs[p].addClass('wsPathPanelRow--disabled'))

        const name = path === 'Connector' ? 'Connector Pins' : path;

        // start it!
        if(path === 'Location') {
            this.name = 'Pins';
            this.editor.trigger('toggleConnectorMenuMode', ['PINS']);
            // this.editor.locPinHelper.setUpControls('regular', 'location') 
        } else if(path === 'Connector') {
            this.name = 'Pins';
            this.editor.trigger('toggleConnectorMenuMode', ['CONNECTORS']);
            !this.editor.continueConnectorTracking && checkOnboardingFlag('connectorOnboarding') && this.editor.onToggleInfoModals("ConnectorOnboarding", true);
            // this.editor.trigger('2DSidebarSceneGraphChanged', [this.name]);
        } else {
            this.name = 'Paths';
            this.editor.trigger('toggleConnectorMenuMode', ['PATHS']);
            this.editor.pathController.setPathType(this.types[path].type);
        }

        this.makeActive(name);
    }

    makeActive = (path) => {
        if(!this.isActive) {
            this.isActive = true;
            this.uiCont.addClass('ws2DMenuContainer--active');        
        }
        // const pathType = this.activePathType === 'Location' ? undefined : this.activePathType; 
        this.editor.trigger('toggleMenuActive', [ this.name, path ]);
    }

    onContinueConnectorPlacement = (data) => {
        if(data) {
            this.editor.preloadConnector['pathOptn'] = 'Connector';
        }
    }

    onReady = () => {
        if(this.editor.continueConnectorTracking && !isEmpty(this.editor.preloadConnector)) {
            this.setActivePath(this.editor.preloadConnector['pathOptn'])
        } else if(this.editor.initMode === 'Connector Pins') {
            this.setActivePath('Connector');
        } else {
            this.editor.trigger('toggleConnectorMenuMode', ['DEFAULT']);
            this.setDefualtMode();
        }
    }

    onToggleMenuActive = (name, op) => { 
        if(name !== this.name && name !== 'Pens') {
            this.isActive = false;
            if(this.editor.menuConnectorsMode === 'CONNECTORS' || this.editor.menuConnectorsMode === 'PINS') {
                const currType = this.editor.menuConnectorsMode === 'CONNECTORS' ? 'Connector' : 'Location';
                this.name = 'Pins';
                this.activePathType = currType;
                this.uiImg.setSrc(this.types[currType].icon);
                for(const p in this.uiObjs) p === currType ? (this.uiObjs[p].addClass('wsPathPanelRow--active') && this.uiObjs[p].removeClass('wsPathPanelRow--disabled')) : (this.uiObjs[p].removeClass('wsPathPanelRow--active') && this.uiObjs[p].removeClass('wsPathPanelRow--disabled'))
            } else this.setDefualtMode();
            this.uiCont.removeClass('ws2DMenuContainer--active');
        } else if( name === 'Pens' && op === 'add' && !this.dom.classList.contains('ws2DMenuContainer--active')) {
            this.activePathType = 'AllAccess';
            this.name = 'Paths';
            this.uiImg.setSrc(this.types['AllAccess'].icon);
            for(const p in this.uiObjs) p === 'AllAccess' ? (this.uiObjs[p].addClass('wsPathPanelRow--active') && this.uiObjs[p].removeClass('wsPathPanelRow--disabled')) : (this.uiObjs[p].removeClass('wsPathPanelRow--active') && this.uiObjs[p].addClass('wsPathPanelRow--disabled'))
            this.uiCont.addClass('ws2DMenuContainer--active');
        }
    }

    setDefualtMode = () => {
        this.name = 'Paths';
        this.activePathType = 'AllAccess';
        this.uiImg.setSrc(this.types['AllAccess'].icon);
        for(const p in this.uiObjs) p === 'AllAccess' ? (this.uiObjs[p].addClass('wsPathPanelRow--active') && this.uiObjs[p].removeClass('wsPathPanelRow--disabled')) : (this.uiObjs[p].removeClass('wsPathPanelRow--active') && this.uiObjs[p].removeClass('wsPathPanelRow--disabled'))
        this.uiCont.removeClass('ws2DMenuContainer--active');
    }
}

export { Menu2DPaths };