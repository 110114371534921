import { Grid, Box } from "@mui/material";
import RenderTile from "../../Render/RenderTile";
import LocationSvg from "../../../../../../assets/svgs/analytics/a_location.svg";
import SearchSvg from "../../../../../../assets/svgs/analytics/a_search.svg";
import ClockSvg from "../../../../../../assets/svgs/analytics/a_clock_violet.svg";
import { CSVLink } from "react-csv";
import { Download } from "react-feather";
import { useState, useEffect } from "react";
import { getLocationSummary } from "../../../../../../features/user/analytics/analyticsSlice";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "../../../../../_utils/DatePicker";
import { getFileNameWithTS, lastTEDays } from "../../../../../_utils/DateUtils";

const Location = () => {
  const dispatch = useDispatch();

  const [dateObj, setDateObj] = useState(lastTEDays);

  const locationSummary = useSelector(
    (state) => state.analytics.locationSummary
  );

  const selectedMap = useSelector((state) => state.analytics.selectedMap);

  useEffect(() => {
    dispatch(getLocationSummary({ mapId: selectedMap.mapId, ...dateObj }));
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(getLocationSummary({ mapId: selectedMap.mapId, ...dateObj }));
    // eslint-disable-next-line
  }, [selectedMap.mapId, dateObj]);

  const getTwelveHourFormat = (hour) => {
    let h = hour % 12 || 12;
    let ampm = hour < 12 ? "am" : "pm";
    return h + ampm;
  };

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col" style={{ paddingRight: "2.5rem" }}>
            <div className="analyticshome__anCard--contentRow--header">
              <div className="row m-0 box">
                <div className="col-2">Location</div>
                <div className="col-5"></div>

                <div className="col-2" style={{ color: "#2A4DC2" }}>
                  <CSVLink
                    headers={[
                      { label: "Most popular location pin", key: "mpl" },
                      { label: "Most searched location pin", key: "msl" },
                      { label: "Most traffic during time of day", key: "mtdd" },
                    ]}
                    data={[
                      {
                        mpl: locationSummary?.popularLocation
                          ? locationSummary.popularLocation
                          : "--",
                        msl: locationSummary?.mostSearchedkeyword
                          ? locationSummary?.mostSearchedkeyword
                          : "--",
                        mtdd: locationSummary?.mostTraficTime
                          ? `${getTwelveHourFormat(
                              parseInt(locationSummary.mostTraficTime)
                            )}-${getTwelveHourFormat(
                              parseInt(locationSummary.mostTraficTime) + 1
                            )} (GMT)`
                          : "--",
                      },
                    ]}
                    filename={getFileNameWithTS(`${selectedMap.mapName}-location-overview`)}
                    style={{ color: "#2A4DC2" }}
                  >
                    <Download /> Download CSV
                  </CSVLink>
                </div>
                <Box
                  component="span"
                  className="col-3 analyticshome__anCard--filterBox"
                >
                  <span style={{ color: "#858993" }}>Date:</span>
                  <DatePicker
                    cb={(obj) => {
                      setDateObj(obj);
                      dispatch(
                        getLocationSummary({ mapId: selectedMap.mapId, ...dateObj })
                      );
                    }}
                  />
                </Box>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div style={{ padding: "0px 32px 0px 32px" }}>
          <Grid container={true} spacing={4}>
            <RenderTile
              tileSize={4}
              color="#008080"
              miniCardHeader={
                locationSummary?.popularLocation
                  ? locationSummary.popularLocation
                  : "--"
              }
              miniCardDesc="Most popular location pin"
              svg={LocationSvg}
            />

            <RenderTile
              tileSize={4}
              color="#017EB4"
              miniCardHeader={
                locationSummary?.mostSearchedkeyword
                  ? locationSummary.mostSearchedkeyword
                  : "--"
              }
              miniCardDesc="Most searched location pin"
              svg={SearchSvg}
            />

            <RenderTile
              tileSize={4}
              color="#5600AC"
              miniCardHeader={
                locationSummary?.mostTraficTime
                  ? `${getTwelveHourFormat(
                      parseInt(locationSummary.mostTraficTime)
                    )}-${getTwelveHourFormat(
                      parseInt(locationSummary.mostTraficTime) + 1
                    )} (GMT)`
                  : "--"
              }
              miniCardDesc="Most traffic during time of day"
              svg={ClockSvg}
            />
          </Grid>
          <hr />
        </div>
      </div>
    </div>
  );
};

export default Location;
