import EventEmitter from "../../utils/EventEmitter";

// import UI Components
import {
    UICol,
    UIPanel,
    UIText
} from '../ui.lib';

class TopbarObjectLoaderUI extends EventEmitter {

    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;

        this.initUI();

        this.editor.on('mountObjectLoader', this.onMountObjectLoader);
        this.editor.on('toggleLoaderProgress', this.toggleProgress);
        this.editor.on('updateProgressContent', this.updateProgress);

    }

    initUI = () => {
        var uiContainer = new UIPanel();
        uiContainer.addClass('wsObjectLoader');

        this.uiLoader = new UIPanel();
        this.uiLoader.addClass('wsObjectLoader--loaderDiv');
        this.uiLoader.addChild(new UICol().addClass('loaderContainer__loaderImg'));
        this.uiProgress = new UIText('100%', '0%', true).addClass('wsObjectLoader--loaderProgress');
        this.uiLoader.addChild(this.uiProgress);
        this.uiText = new UIText('100%', 'Loading Content...', true).addClass('wsObjectLoader--loaderText');
        this.uiLoader.addChild(this.uiText);
        uiContainer.addChild(this.uiLoader);

        uiContainer.setDisplay('none');
        this.uiProgress.setDisplay('none');
        this.dom = uiContainer;
    }

    toggleProgress = (flag, text = undefined) => {
        this.uiProgress.setDisplay(flag);

        if(text) { 
            this.uiText.setTextContent(text);
        }

        if(flag === 'none' && !text) {
            this.uiText.setTextContent('Loading Content...');
            this.uiProgress.setTextContent('0%');
        }
    }

    updateProgress = (text) => {
        this.uiProgress.setTextContent(text);
    }

    onMountObjectLoader = (bFlag) => {
        if(bFlag) {
            this.dom.setDisplay('flex');
        } else {
            this.dom.setDisplay('none');
        }
    }
}

export { TopbarObjectLoaderUI };