import * as THREE from "three";
import {
    fromPosObjectToVec3Pos,
    fromVec3PosToPosObject,
} from "../TransformConversions";
import { v4 } from "uuid";

class ComputeTourNodes {
    constructor(waypoints) {
        this.waypoints = waypoints;
        this.totalWaypoints = waypoints.length;
        this.maxSegmentLength = 2.0;
        this.maxSegmentDistance = 4.0;

        this.nodeList = [];
        this.computeNodeList = [];

        // add zeroth node!
        this.computeNodeList.unshift({
            index: 0,
            position: fromPosObjectToVec3Pos(waypoints[0].position),
        });
        this.getTurns();
        
        this.computeNodeList.push({
            index: this.totalWaypoints - 1,
            position: fromPosObjectToVec3Pos(
                waypoints[this.totalWaypoints - 1].position
            ),
            distance: 0, 
            distanceToNextTurn: 0
        });

        this.computeNodeDistances();
        this.splitLongerTurns();

        this.sortComputeNodesByIndex();

        this.getNodeList();

        this.setMissingWaypointsData();
    }

    getTurns = () => {
        // start from 1st waypoint as 0th and last waypoint would be a node by default
        for (var i = 0; i < this.totalWaypoints - 2; i++) {
            this.computeTurn(
                fromPosObjectToVec3Pos(this.waypoints[i].position),
                fromPosObjectToVec3Pos(this.waypoints[i + 1].position),
                fromPosObjectToVec3Pos(this.waypoints[i + 2].position),
                i + 1, 
                10.0
            );
        }
    };

    computeTurn = (currentWaypoint, nextWaypoint, nextNextWaypoint, index, turnAngleThreshold) => {

        // calculate directions
        const nextDir = new THREE.Vector3().subVectors(
            nextWaypoint,
            currentWaypoint
        );
        const nextNextDir = new THREE.Vector3().subVectors(
            nextNextWaypoint,
            nextWaypoint
        );

        const angle = nextDir.angleTo(nextNextDir);
        const degAngle = THREE.MathUtils.radToDeg(angle);

        if (degAngle >= turnAngleThreshold) {
            this.computeNodeList.push({index: index, position: nextWaypoint});
        }
    };

    computeNodeDistances = () => {
        const nodeLen = this.computeNodeList.length;

        for (var i = 0; i < nodeLen; i++) {
            if(i === nodeLen - 1) {
                this.computeNodeList[i].distance = this.computeNodeList[i].position.distanceTo(
                    fromPosObjectToVec3Pos(this.waypoints[this.totalWaypoints - 1].position)
                );
            } else {
                this.computeNodeList[i].distance = this.computeNodeList[i].position.distanceTo(
                    this.computeNodeList[i + 1].position
                );
            }
        }

        for (var j = 0; j < nodeLen; j++) {
            if (j === nodeLen - 1) {
                this.computeNodeList[j].distanceToNextTurn =
                    this.calculateDistance(
                        this.waypoints,
                        this.computeNodeList[j].index,
                        this.totalWaypoints - 1
                    );
            } else {
                this.computeNodeList[j].distanceToNextTurn =
                    this.calculateDistance(
                        this.waypoints,
                        this.computeNodeList[j].index,
                        this.computeNodeList[j + 1].index
                    );
            }
        }
    };

    calculateDistance = (waypoints, startIndex, endIndex) => {
        let distance = 0;
        for (var i = startIndex; i < endIndex; i++) {
            distance += fromPosObjectToVec3Pos(waypoints[i].position).distanceTo(
                fromPosObjectToVec3Pos(waypoints[i + 1].position)
            );
        }
        return distance < 1 ? 1 : distance;
    };

    splitLongerTurns = () => {
        const computeNodeList = [...this.computeNodeList];
        for (var i = 0; i < computeNodeList.length; i++) {
            if (
                computeNodeList[i] && computeNodeList[i].distanceToNextTurn &&
                computeNodeList[i].distanceToNextTurn >
                    this.maxSegmentDistance
            ) {
                const maxDistance = parseInt(this.maxSegmentLength);
                if (
                    computeNodeList[i].distanceToNextTurn >
                    maxDistance + 1
                ) {
                    const increasedBy = maxDistance;
                    const segmentIndex =
                        computeNodeList[i].index + increasedBy;
                    let newWaypoint;
                    if (
                        this.totalWaypoints > 0 &&
                        segmentIndex <= this.totalWaypoints - 1
                    ) {
                        newWaypoint = fromPosObjectToVec3Pos(
                            this.waypoints[
                                computeNodeList[i].index + increasedBy
                            ].position
                        );
                        this.computeNodeList.splice(i + 1, 0, {
                            position: newWaypoint,
                            index: segmentIndex,
                        });
                    }
                }
            }
        }
    };

    sortComputeNodesByIndex = () => {
        this.computeNodeList.sort((a, b) => a.index - b.index);
    }

    getNodeList = () => {
        const nodeLen = this.computeNodeList.length;
        for (var i = 0; i < nodeLen; i++) {
            this.nodeList.push(this.getNodeObject(this.computeNodeList[i].position));
        }
    };

    getNodeObject = (position) => {
        const adjacentNodes = [];
        const nodeLen = this.nodeList.length;

        const nodeObj = {
            id: v4(),
            order: nodeLen,
            position: fromVec3PosToPosObject(position),
        };

        if (nodeLen === 0) {
            adjacentNodes.push(null);
        } else if (nodeLen >= 1) {
            // add this node as adjacent in previous node
            this.nodeList[nodeLen - 1].adjacentNodes.push(nodeObj.id);
            adjacentNodes.push(this.nodeList[nodeLen - 1].id);
        }

        nodeObj["adjacentNodes"] = adjacentNodes;

        return { ...nodeObj };
    };

    getTourObject = () => {
        return {
            nodes: this.nodeList,
            waypoints: this.waypoints,
            isCreatedFromApp: true,
        };
    };

    setMissingWaypointsData = () => {
        let firstWaypoint = { ...this.waypoints[0] };

        firstWaypoint = {
            ...firstWaypoint,
            navinavigationStyle: "GuidedPath",
            file: null,
            images: null,
            wheelchairAccessible: false,
        };

        const points = [...this.waypoints];
        points[0] = firstWaypoint;
        this.waypoints = [...points];
    };
}


export { ComputeTourNodes };
