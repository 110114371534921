import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { REACT_APP_MAP_APIS_BASEURL } from "../../../values/constants";
import "./style.css";

const Expire = () => {
  const params = useParams();
  const [emailResponse, setEmailResponse] = useState(null);
  const [sentEmail, setSentEmail] = useState(false);

  const handleResend = async () => {
    setSentEmail(true);
    axios
      .post(
        `${REACT_APP_MAP_APIS_BASEURL}v1/auth/sendVerificationEmail`,
        {
          token: params.verificationToken,
        }
      )
      .then((response) => {
        setSentEmail(true);
        if (response.status === 200) {
          setEmailResponse("Email has been sent.");
        } else {
          setEmailResponse("Please check your email.");
        }
      })
      .catch((error) => {
        console.log(error, "error");
        setSentEmail(true);
        setEmailResponse("Something went wrong!");
      });
  };
  return (
    <>
      <div className="expire-box-container">
        <div className="expire-box max-30">
          {sentEmail && emailResponse && <p className="email-sent-expire">{emailResponse}</p>}
          <h1 className="expire-header">The verification link has expired</h1>
          <p className="expire-body">
            Oops! This verification link has expired, please resend the email and
            try again. Make sure to verify your email within 24 hours of resending
            the email.
          </p>
          <div className="expire-footer">
            <button
              className="footer-button"
              onClick={handleResend}
            >
              Resend Email
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Expire;
