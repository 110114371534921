import EditorExperience from "../../../EditorExperience";
import EventEmitter from "../../../utils/EventEmitter";

import { UIPanel } from "../../../ui/ui.lib";

import { Sidebar2DObject } from "./sidebar2D.Object";
import { Sidebar2DContent } from "./Sidebar2D.Content";

class Sidebar2D extends EventEmitter {

    constructor(props) {
        super(props)
        this.editor = new EditorExperience();
        this.parentEle = props.dom;

        this.initUI();
        // this.editor.categories = props.categories;

        this.activeContentTab = null;

        this.editor.on('sceneHierarchyChanged', this.refreshTabsUI);
        this.editor.on('toggleMenuActive', this.refreshTabWithMenuItem);
        this.editor.on('mount2DLoader', this.onMountObjectLoader);
    }

    initUI = () => {
        this.uiCont = new UIPanel();
        this.uiCont.addClass('wsSidebar2DContentsContainer');
        
        this.sidebar2DObject = new Sidebar2DObject();
        this.sidebar2DContent = new Sidebar2DContent();

        this.uiCont.dom.appendChild(this.sidebar2DObject.dom);
        this.uiCont.dom.appendChild(this.sidebar2DContent.dom);

        this.parentEle.append(this.uiCont.dom);
    }
    

    refreshTabsUI = () => {
        this.uiCont.dom.appendChild(this.sidebar2DObject.dom);
        this.uiCont.dom.appendChild(this.sidebar2DContent.dom);
    }

    refreshTabWithMenuItem = (name) => {
        if(name === this.activeContentTab) return;
        this.activeContentTab = name;
    }

    onMountObjectLoader = (bFlag) => {
        if(bFlag) {
            this.uiCont.addClass('wsSidebar2DContentsContainer--noEvnts');
        } else {
            this.uiCont.removeClass('wsSidebar2DContentsContainer--noEvnts');
        }
    }
}

export { Sidebar2D };