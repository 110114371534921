import { Grid } from "@mui/material";
import UniqSvg from "../../../../../../assets/svgs/analytics/a_uniq.svg";
import EyeSvg from "../../../../../../assets/svgs/analytics/a_eye_blue.svg";
import AvgDuration from "../../../../../../assets/svgs/analytics/a_duration.svg";
import { CSVLink } from "react-csv";
import { Download } from "react-feather";
import { useState, useEffect } from "react";
import { isEmpty, isNull, isUndefined } from "lodash";
import { getTopVisitedMapsCard, topVisitedMapsCSV } from "../../../../../../features/user/analytics/analyticsSlice";
import RenderTile from "../../Render/RenderTile";
import RenderTable from "../../Render/RenderTable";
import RenderPagination from "../../Render/RenderPagination";
import usePagination from "../../Render/usePagination";
import { useDispatch,useSelector } from "react-redux";
import { getFileNameWithTS, secondsToHms } from '../../../../../_utils/DateUtils'

const topVisitedMapsHeaders = [
  { key: "mapName", label: "Map Name" },
  { key: "publishedOn", label: "Publish Date" },
  { key: "mapArea", label: "Map Area sq. ft"},
  { key: "mapVisits", label: "Visit Count" },
  { key: "avgVisitTime", label: "Average Time Visited" },
  { key: "totalVisitTime", label: "Total Time Visited" },
];

const TopVisitedMapsCard = ({ topVisitedMapsCard, topVisitedMapsMetaCard }) => {
  const dispatch = useDispatch();
  const limit = 10;
  const [page, setPage] = useState(1);
  const [totalResults, setTotalResults] = useState(0)
  const count = Math.ceil(topVisitedMapsCard.totalResults / limit);
  const _DATA = usePagination(
    topVisitedMapsCard?.data ? topVisitedMapsCard.data : [],
    limit
  );
  const topVisitedMapsCSVData =  useSelector(topVisitedMapsCSV)

  useEffect(() => {
    dispatch(getTopVisitedMapsCard({ page, limit }));
    dispatch(getTopVisitedMapsCard({ page: 1, limit: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getTopVisitedMapsCard({ page, limit }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(()=>{
    if(totalResults!==topVisitedMapsCard?.totalResults){
      setTotalResults(topVisitedMapsCard?.totalResults)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[topVisitedMapsCard])

  useEffect(()=>{
    if(totalResults>0)
    dispatch(getTopVisitedMapsCard({ page:1, limit:totalResults, isCSV:true }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[totalResults])
  

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    
    <div className="analytics">
      <div className="analyticshome__anCard">
        {topVisitedMapsMetaCard ? null : (
          <h5 className="text-center analyticshome__emptyStateText">
            {" "}
            No Data available{" "}
          </h5>
        )}
        <Grid container={true} spacing={4} style={{ marginBottom: "2rem" }}>
          <RenderTile
            tileSize={4}
            color="#008080"
            miniCardHeader={
              isEmpty(topVisitedMapsMetaCard)
                ? "--"
                : topVisitedMapsMetaCard?.mapName?.charAt(0).toUpperCase() +
                  topVisitedMapsMetaCard?.mapName?.slice(1)
            }
            miniCardDesc="Top visited map to date"
            svg={UniqSvg}
          />

          <RenderTile
            tileSize={4}
            color="#017EB4"
            miniCardHeader={
              isEmpty(topVisitedMapsMetaCard)
                ? "--"
                : topVisitedMapsMetaCard?.visits
            }
            miniCardDesc="Most map visits of a Map"
            svg={EyeSvg}
          />

          <RenderTile
            tileSize={4}
            color="#5600AC"
            miniCardHeader={
              isEmpty(topVisitedMapsMetaCard)
                ? "--"
                : topVisitedMapsMetaCard?.average
                ? secondsToHms(topVisitedMapsMetaCard?.average)
                : "--"
            }
            miniCardDesc="Average map visit duration"
            svg={AvgDuration}
          />
        </Grid>
        <div className="row analyticshome__anCard--contentRow d-flex align-items-center">
          <div className="col">
            <div className="analyticshome__anCard--contentRow--header">
              Top Visited Maps
            </div>
          </div>
          <div className="col">
            <div
              className="analyticshome__floatright"
              style={{ paddingRight: "35px" }}
            >
              {isEmpty(topVisitedMapsCSVData.data) ? null : (
                <CSVLink
                  headers={topVisitedMapsHeaders.map((headerObj, index) => ({
                    label: headerObj.label,
                    key: Object.keys(topVisitedMapsCSVData.data[0])[index],
                  }))}
                  data={topVisitedMapsCSVData.data}
                  filename={getFileNameWithTS("TopVisitedMaps")}
                  style={{ color: "#2A4DC2" }}
                >
                  <Download /> Download CSV
                </CSVLink>
              )}
            </div>
          </div>
        </div>
        <hr />
        {isEmpty(topVisitedMapsCard) || isNull(_DATA.currentData()) || isUndefined(_DATA.currentData())? null : (
          <RenderTable
            thheaderData={topVisitedMapsHeaders}
            rowsData={_DATA.currentData()}
          />
        )}
        {isEmpty(topVisitedMapsCard?.data) ? (
          <h5 className="text-center analyticshome__emptyStateText pt-3">
            {" "}
            No Data available{" "}
          </h5>
        ) : null}
        <div className="row analyticshome__anCard--contentRow--footer">
          <div className="col">
            {isEmpty(topVisitedMapsCard.data) ? null : (
              <RenderPagination
                count={count}
                page={page}
                limit={limit}
                handleChange={handleChange}
                cb={(page) => {
                  setPage(page);
                  _DATA.jump(page);
                }}
                totalResults={topVisitedMapsCard.totalResults}
              />
            )}
          </div>
        </div>
      </div>
      {/* </Grid> */}
    </div>
  );
};

export default TopVisitedMapsCard;