import EventEmitter from "../../utils/EventEmitter";
import TranslateIcon from "../../../../assets/svgs/scenes/translate_w.svg";
import RotateIcon from "../../../../assets/svgs/scenes/rotate_w.svg";
import ScaleIcon from "../../../../assets/svgs/scenes/scale_w.svg";
import EditIcon from "../../../../assets/svgs/scenes/edit_w.svg";

class FloatingMenuActionAllContents extends EventEmitter {
  constructor(editor) {
    super();

    this.editor = editor;
    this.dom = null;

    this.initUI();

    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on("transformModeChanged", this.onTransformModeChanged);
    this.editor.on("toggleAdjustAccess", this.onToggleAdjustObject);
  }

  initUI = () => {
    this.uiTranslateContainer = document.createElement("div");
    this.uiTranslateContainer.className = "wsFloatingMenuBtn";
    this.uiTranslateContainer.addEventListener("click", () => {
      // Translate Clicked
      this.editor.trigger("transformModeChanged", ["translate", "Position"]);
    });

    var translateIcon = document.createElement("img");
    translateIcon.classList.add("wsFloatingMenuIcon");
    translateIcon.title = "Translate";
    translateIcon.id = "img_Translate";
    translateIcon.src = TranslateIcon;
    this.uiTranslateContainer.appendChild(translateIcon);

    this.uiRotateContainer = document.createElement("div");
    this.uiRotateContainer.classList.add("wsFloatingMenuBtn");
    this.uiRotateContainer.addEventListener("click", () => {
      // Rotate Clicked
      this.editor.trigger("transformModeChanged", ["rotate", "Rotation"]);
    });

    var rotateIcon = document.createElement("img");
    rotateIcon.classList.add("wsFloatingMenuIcon");
    rotateIcon.title = "Rotate";
    rotateIcon.id = "img_Rotate";
    rotateIcon.src = RotateIcon;
    this.uiRotateContainer.appendChild(rotateIcon);

    this.uiScaleContainer = document.createElement("div");
    this.uiScaleContainer.classList.add("wsFloatingMenuBtn");
    this.uiScaleContainer.addEventListener("click", () => {
      // Scale Clicked
      this.editor.trigger("transformModeChanged", ["scale", "Scale"]);
    });

    var scaleIcon = document.createElement("img");
    scaleIcon.classList.add("wsFloatingMenuIcon");
    scaleIcon.title = "Scale";
    scaleIcon.id = "img_Scale";
    scaleIcon.src = ScaleIcon;
    this.uiScaleContainer.appendChild(scaleIcon);

    this.uiEditContainer = document.createElement("div");
    this.uiEditContainer.classList.add("wsFloatingMenuBtn");
    this.uiEditContainer.addEventListener("click", () => {
      this.editor.trigger("transformModeChanged", ["edit", ""]);
      this.editor.trigger("sidebarSceneGraphChanged", [
        this.editor.selectedObject,
      ]);
      this.editor.trigger("editSidebarObjectContents", [
        this.editor.selectedObject,
      ]);
    });

    var editIcon = document.createElement("img");
    editIcon.classList.add("wsFloatingMenuIcon");
    editIcon.title = "Edit";
    editIcon.id = "img_Edit";
    editIcon.src = EditIcon;
    this.uiEditContainer.appendChild(editIcon);

    const uiActionRowAllContents = document.createElement("div");
    uiActionRowAllContents.classList.add("wsFloatingMenuActionButtons");
    uiActionRowAllContents.appendChild(this.uiTranslateContainer);
    uiActionRowAllContents.appendChild(this.uiRotateContainer);
    uiActionRowAllContents.appendChild(this.uiScaleContainer);
    uiActionRowAllContents.appendChild(this.uiEditContainer);

    this.dom = uiActionRowAllContents;
  };

  onObjectSelected = (object) => {
    if(object && object?.userData.type !== "wayPointGroup") {
      this.editor.trigger("transformModeChanged", ["translate", "Position"]);
      if (object && object.userData && "transform" in object.userData) {
        if (object.userData.transform === "NO_SCALE") {
          this.uiScaleContainer.classList.add("wsFloatingMenuDisabled");
        } else {
          this.uiRotateContainer.classList.add("wsFloatingMenuDisabled");
          this.uiScaleContainer.classList.add("wsFloatingMenuDisabled");
        }
      } else {
        this.uiRotateContainer.classList.remove("wsFloatingMenuDisabled");
        this.uiScaleContainer.classList.remove("wsFloatingMenuDisabled");
      }
    }
  };

  onToggleAdjustObject = (state, object) => {
    if (object && object.userData && "qrAnchor" in object.userData) {
      this.editor.trigger("transformModeChanged", ["translate", "Position"]);
      this.uiScaleContainer.classList.add("wsFloatingMenuDisabled");
      this.uiRotateContainer.classList.remove("wsFloatingMenuDisabled");
      this.uiTranslateContainer.classList.remove("wsFloatingMenuDisabled");
    }
  };

  onTransformModeChanged = (transformMode) => {
    this.uiTranslateContainer.classList.remove("wsFloatingMenuSelected");
    this.uiRotateContainer.classList.remove("wsFloatingMenuSelected");
    this.uiScaleContainer.classList.remove("wsFloatingMenuSelected");
    this.uiEditContainer.classList.remove("wsFloatingMenuSelected");

    switch (transformMode) {
      case "translate":
        this.uiTranslateContainer.classList.add("wsFloatingMenuSelected");
        break;
      case "rotate":
        this.uiRotateContainer.classList.add("wsFloatingMenuSelected");
        break;
      case "scale":
        this.uiScaleContainer.classList.add("wsFloatingMenuSelected");
        break;
      case "edit":
        this.uiEditContainer.classList.add("wsFloatingMenuSelected");
        break;
      default:
        break;
    }
  };

  setDisplay = (value) => {
    this.dom.style.display = value;
  };
}

export { FloatingMenuActionAllContents };
