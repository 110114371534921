import React from 'react';
import {
    TableContainer,
    Table, TableHead,
    TableRow, TableCell,
    TableBody
} from '@mui/material';
import { convertToDate } from '../../../_utils/dateFormatter';
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
        "& .MuiTableCell-root": {
            borderSpacing: '0px 15px',
            borderCollapse: 'separate',
            // border: "transparent",
        },
        "& tr": {
            backgroundColor: 'white',
            boxShadow: '0px 2px 16px 0px #28293D14',
            padding: '6px 0px !important'
        }
    }
});

export default function AccountsTable({accounts, areaUnit}) {

    const classes = useStyles();
    const navigate = useNavigate();

    const columns = [
        { id: 'email', label: 'Account', minWidth: 200, isName: true},
        { id: 'createdDate', label: 'Created On', minWidth: 100, isTime: true },
        { id: 'lastActivityDate', label: 'Last Edited', minWidth: 100, isTime: true },
        { id: 'planName', label: 'Plan', minWidth: 100 },
        { id: 'totalMapSize', label: 'Map Area', minWidth: 100, isArea: true, unit: areaUnit ? 'SqFt' : 'SqM' },
        { id: 'storageUsed', label: 'Storage Used', minWidth: 100, isNumber: true , unit: 'MB'},
        { id: 'averageViews', label: 'Avg. Map Views', minWidth: 100, isNumber: true, },
    ]

    const handleNavigate = (account) => {
        navigate(`/dashboard/manage-accounts/${account.id}`,{ state: account })
    }

    const renderNameDiv = (row) => {
        return (
            <div className='maccounts__tableName'>
                <span className='maccounts__tableName--name'>{row.name || '--'}</span>
                <span className='maccounts__tableName--email'>{row.email}</span>
            </div>
        )
    }

    return (
        <div className='maccounts__tableCont'>
            <TableContainer className={classes.table} style={{backgroundColor: 'inherit'}} /* sx={{ maxHeight: 650 }} */>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    align={'left'}
                                    style={{ minWidth: column.minWidth }}
                                >
                                    <span className='maccounts__tableHeader'>{column.label}</span>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accounts.map((account) => {
                            return (
                                <TableRow hover sx={{m:6}} key={account.id} onClick={() => handleNavigate(account)}>
                                    {columns.map(col => {
                                        const value = account[col.id]
                                        return (
                                            <TableCell key={col.id} align={'left'} className='maccounts__tableCell'>
                                                {
                                                    col.isName ? renderNameDiv(account) :
                                                    col.isTime ? value ? convertToDate(value) : '- -' :
                                                    col.isNumber ? value !== null ? `${parseFloat(value).toFixed(2)} ${col.unit ? col.unit : ''}` : '- -' :
                                                    col.isArea ? value !== null ? `${areaUnit ? Math.round(value * 10.764) : Math.round(value)} ${col.unit ? col.unit : ''}` : '- -' :
                                                    <span className='maccounts__tableText'>{value}</span>
                                                }
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}
