import RenderTable from "../../Render/RenderTable";
import { Box } from "@mui/material";
import { isEmpty, isNull, isUndefined } from "lodash";
import DatePicker from "../../../../../_utils/DatePicker";
import React, { useEffect, useState } from "react";
import RenderPagination from "../../Render/RenderPagination";
import usePagination from "../../Render/usePagination";
import { getAPWithMostActivation } from "../../../../../../features/user/analytics/analyticsSlice";
import { useSelector, useDispatch } from "react-redux";
import { lastTEDays } from "../../../../../_utils/DateUtils";

const apWithMostActivationHeaders = [
  { key: "rank", label: "Rank" },
  { key: "accessPointName", label: "Access Point Name" },
  { key: "type", label: "Type" },
  { key: "growth", label: "Growth" },
  { key: "activation", label: "Activation" },
];

const AccessPointsWithMostActivationByMap = () => {
  const dispatch = useDispatch();
  const apWithMostActivation = useSelector(
    (state) => state.analytics.apWithMostActivation
  );
  const selectedMap = useSelector((state) => state.analytics.selectedMap);
  const [totalResults, setTotalResults] = useState(0);
  const limit = 10;
  const [page, setPage] = useState(1);
  const count = Math.ceil(apWithMostActivation.totalResults / limit);
  const _DATA = usePagination(
    apWithMostActivation.data ? apWithMostActivation.data : [],
    limit
  );
  const [dateObj, setDateObj] = useState(lastTEDays);
  useEffect(() => {
    dispatch(
      getAPWithMostActivation({
        mapId: selectedMap.mapId,
        page,
        limit,
        ...dateObj,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let args = { ...dateObj, mapId: selectedMap.mapId, page, limit };
    dispatch(getAPWithMostActivation(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMap.mapId, page]);

  useEffect(() => {
    if (totalResults !== apWithMostActivation?.totalResults) {
      setTotalResults(apWithMostActivation?.totalResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apWithMostActivation]);

  const handleChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col-9">
            <div className="analyticshome__anCard--contentRow--header">
              Access Point List
            </div>
          </div>
          <div className="col-3" style={{ paddingRight: "2.5rem" }}>
            <Box component="span" className="analyticshome__anCard--filterBox">
              <span style={{ color: "#858993" }}>Date:</span>
              <DatePicker
                cb={(dateObj) => {
                  setDateObj(dateObj);
                  let args = {
                    ...dateObj,
                    mapId: selectedMap.mapId,
                    page,
                    limit,
                  };
                  dispatch(getAPWithMostActivation({ ...args }));
                }}
              />
            </Box>
            {/* } */}
          </div>
        </div>
        <hr />

        {isEmpty(apWithMostActivation.data) ||
        isNull(_DATA.currentData()) ||
        isUndefined(_DATA.currentData()) ? (
          <h5 className="text-center analyticshome__emptyStateText pt-3">
            {" "}
            No Data available{" "}
          </h5>
        ) : (
          <RenderTable
            thheaderData={apWithMostActivationHeaders}
            rowsData={_DATA.currentData()}
          />
        )}
        <div className="row analyticshome__anCard--contentRow--footer">
          <div className="col">
            {isEmpty(apWithMostActivation.data) ? null : (
              <RenderPagination
                count={count}
                page={page}
                limit={limit}
                handleChange={handleChange}
                cb={(page) => {
                  setPage(page);
                  _DATA.jump(page);
                }}
                totalResults={apWithMostActivation.totalResults}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccessPointsWithMostActivationByMap;
