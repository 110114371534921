import React from 'react';
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { linksCardData } from './linksHelper';

import { ArrowRight } from "react-feather";

//Action
import items from "../../../common/sidebar/menu";
import { retainNavState } from "../../../../features/common/commonSlice";

export default function AdminLanding() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const loggedUserType = useSelector((state) => state.auth.loggedUserType) || "user";

    const handleNavigation = (link) => {
        const findIndxArr = items[loggedUserType];
        let activeIndx = 0;
        findIndxArr.forEach((ele, index) => {
            if (link === ele.link) activeIndx = index;
        });
        navigate(link);
        dispatch(retainNavState(activeIndx));
    };

    const renderLinkCards = () => {
        return linksCardData.map((cardData, index) => (
            <div key={index} className="col-lg-6 col-xl-4 col-xxl-3 pt-3 pe-0 mx-4 my-4" style={{height:'330px', width: '330px'}}>
            <div className="dashboardpage__dpCard" key={cardData.id}>
                <div className="dashboardpage__dpCard--title">{cardData.title}</div>
                <div className="dashboardpage__dpCard--iconCont">
                    <img
                        src={cardData.icon}
                        alt={cardData.title}
                        draggable="false"
                        className="dashboardpage__dpCard--icon dashboardpage__dpCard--icon--lg"
                    />
                </div>
                <span className="dashboardpage__dpCard--content">
                    {cardData.content}
                </span>
                <div
                    className="dashboardpage__dpCard--link"
                    onClick={() =>
                        cardData.link !== "NONE" && handleNavigation(cardData.link)
                    }
                    id={`link-${cardData.linkText.replace(/\s+/g, '').toLowerCase()}`}
                >
                    {cardData.linkText}{" "}
                    {cardData.link !== "NONE" && (
                        <ArrowRight
                            size="18"
                            color="#2A4DC2"
                            className="dashboardpage__dpCard--linkArrow"
                        />
                    )}
                </div>
            </div>
            </div>
        ));
    };

    return (
        <div className='adminlanding'>
            <Box padding="40px 60px">
                <div className="adminlanding__headerRow">
                    <span className="adminlanding__headerText"> Dashboard </span>
                </div>
                <div className="adminlanding__aHeaderCard">
                    <div className="adminlanding__aHeaderCard--backImage" />
                    <div className="adminlanding__aHeaderInfo">
                        <div className="adminlanding__aHeaderInfo--header">Welcome to the ARway Admin Portal</div>
                        <span className="adminlanding__aHeaderInfo--subHeader">
                            Create and manage client accounts, view reported content, adjust user access and impersonate. Select from the actions below to begin.
                        </span>
                    </div>
                </div>
                <div className="row gx-5 me-0 mt-3">{renderLinkCards()}</div>
            </Box>
        </div>
    )
}
