import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

//MUi
import { Grid, Box, Tooltip } from "@mui/material";
import { HelpCircle, X } from "react-feather";

//COmponents
import Loader from "../../../../common/loader";
import FeaturesCard from "./FeatureCard";
import SecretKeyCard from "./SecretKeyCard";
import UsageGraph from "./UsageGraph";

//Actions
import { getUserPlanDetails } from "../../../../../features/user/settings/settingsSlice";
import GenericSwitch from "../../../../common/toggleSwitch/GenericSwitch";

const MyPlanHome = () => {

  const dispatch = useDispatch();

  const planDetails = useSelector(state => state.settings.planDetails);
  const accessAuthrized = useSelector(state => state.access.accessAuthrized);

  const [planInfo, setPlanInfo] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [areaSQFt, setAreaSQFt] = useState(true);

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setFetching(true);
    dispatch(getUserPlanDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (planDetails) {
      setFetching(false);
      setPlanInfo(planDetails);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetails]);

  useEffect(() => {
    if (accessAuthrized === 'success') {
      dispatch(getUserPlanDetails());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessAuthrized])

  const renderValidity = (planInfo) => {
    let fromDate = new Date(planInfo.planDuration.startDate);
    let toDate = new Date(planInfo.planDuration.endDate);
    return (
      <>
        <span className="settingshome__mpUsageCard--headerText m-3">{`${monthNames[fromDate.getMonth()]
          } ${fromDate.getDate()} - ${monthNames[toDate.getMonth()]
          } ${toDate.getDate()}`}</span>
        <span className="settingshome__mpPlanInfoCard--subHeader">{`( ${
          // moment(toDate).diff(moment(), 'days')
          planInfo.planDaysLeft
          } days remaining )`}</span>
      </>
    );
  }

  const toggleShowHelp = () => {
    setShowHelp(!showHelp);
  };

  if (fetching || planInfo === null) {
    return (
      <Loader
        loaderText="Fetching Plan Details..."
        height="100%"
        width="100%"
      />
    );
  }

  return (
    <div className="settingshome">
      <div className="settingshome__mpHeaderCont">
        <span className="settingshome__mpHeaderCont--header">Usage</span>
        <span className="settingshome__mpHeaderCont--planName">
          {planInfo.planName}
        </span>
        <span className="settingshome__mpHeaderCont--planName">
          {planInfo.watermark ? "ARWay Branding" : "Custom Branding"}
        </span>
        {renderValidity(planInfo)}
      </div>
      <Grid container={true} className="settingshome__myGridCOntainer">
        <Grid item xs={6}>
          <Box className="settingshome__mpUsageCard">
            <div className="settingshome__mpUsageCard--headerRow">
              <span className="settingshome__mpUsageCard--headerText">
                Map Views
              </span>
              <Tooltip title="Help">
                <HelpCircle
                  size={18}
                  color="#353E5A"
                  strokeWidth={1.5}
                  className="settingshome__mpUsageCard--helpIcon"
                  onClick={toggleShowHelp}
                />
              </Tooltip>
              {showHelp && (
                <div className="settingshome__mapHelperCont">
                  <div className="settingshome__mapHelperCont--headerRow">
                    <span>Map Views</span>
                    <X
                      size={18}
                      color="#353E5A"
                      onClick={toggleShowHelp}
                      className="settingshome__mpUsageCard--helpIcon"
                    />
                  </div>
                  <div className="settingshome__mapHelperCont--infoRow">
                    A map view is whenever you or another user opens your map
                    to view.
                    <br />
                    <br />
                    When editing, a map view will be used after tapping on
                    “Add Content” the first time in each edit session.
                  </div>
                </div>
              )}
            </div>
            <UsageGraph
              unit="Monthly"
              unitString="views"
              limit={planInfo.planApiLimit}
              usage={planInfo.planCurrentApi || 0}
              type="INT"
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Box className="settingshome__mpUsageCard">
            <div className="settingshome__mpUsageCard--headerRow">
              <span className="settingshome__mpUsageCard--headerText">
                Storage
              </span>
            </div>
            <UsageGraph
              // unit="Monthly"
              unitString="MB"
              limit={planInfo.planStorageLimit}
              usage={planInfo.planCurrentStorage || 0}
            />
          </Box>
        </Grid>
        <Grid item xs={6} mt={3}>
          <Box className="settingshome__mpUsageCard">
            <div className="settingshome__mpUsageCard--headerRow">
              <span className="settingshome__mpUsageCard--headerText">
                Map Limit
              </span>
            </div>
            <UsageGraph
              unitString={planInfo.mapsLimit.limit > 1 ? "maps" : "map"}
              limit={planInfo.mapsLimit.limit}
              usage={planInfo.mapsLimit.total || 0}
              type="INT"
            />
          </Box>
        </Grid>
        {planInfo.mapArea.enable &&
          <Grid item xs={6} mt={3}>
            <Box className="settingshome__mpUsageCard">
              <div className="settingshome__mpUsageCard--headerRow2">
                <span className="settingshome__mpUsageCard--headerText">
                  Map Area
                </span>
                <div>
                  <div className='mapAreaUnitDiv'>
                    <div className='mapAreaUnitDiv__header'>Map Area Unit: </div>
                    <div className='mapAreaUnitDiv__toggleCont'>
                      <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Mtr</div>
                      <GenericSwitch
                        enabled={areaSQFt}
                        onEnableToggle={(e) => setAreaSQFt(e.target.checked)}
                        disabled={!planInfo.mapArea.enable}
                      />
                      <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Ft</div>
                    </div>
                  </div>
                </div>
              </div>
              <UsageGraph
                unitString={areaSQFt ? "SqFt" : "SqM"}
                limit={areaSQFt ? Math.round(planInfo.mapArea.limit * 10.764) : Math.round(planInfo.mapArea.limit)}
                usage={planInfo.mapArea.total > 0 ? areaSQFt ? Math.round(planInfo.mapArea.total * 10.764) : Math.round(planInfo.mapArea.total) : 0}
                type="INT"
              />
            </Box>
          </Grid>
        }
        {planInfo.publishedMap.enable &&
          <Grid item xs={6} mt={3}>
            <Box className="settingshome__mpUsageCard">
              <div className="settingshome__mpUsageCard--headerRow">
                <span className="settingshome__mpUsageCard--headerText">
                  Published Map
                </span>
              </div>
              <UsageGraph
                unitString={planInfo.publishedMap.limit > 1 ? "maps" : "map"}
                limit={planInfo.publishedMap.limit}
                usage={planInfo.publishedMap.total || 0}
                type="INT"
              />
            </Box>
          </Grid>
        }
        {planInfo.activeMapDays.enable &&
          <Grid item xs={6} mt={3}>
            <Box className="settingshome__mpUsageCard">
              <div className="settingshome__mpUsageCard--headerRow">
                <span className="settingshome__mpUsageCard--headerText">
                  Active Map Days
                </span>
              </div>
              <UsageGraph
                unitString={planInfo.activeMapDays.limit > 1 ? "days" : "day"}
                limit={planInfo.activeMapDays.limit}
                usage={planInfo.activeMapDays.total || 0}
                type="INT"
              />
            </Box>
          </Grid>
        }
      </Grid>
      <Grid container={true} className="settingshome__myGridCOntainer">
        <Grid item xs={6}>
          <FeaturesCard myFeatures={planInfo} />
        </Grid>
        <Grid item xs={6}>
          <Box container className="settingshome__mpPlanInfoCard">
            <span className="settingshome__mpPlanInfoCard--header">
              Need extra storage space, custom branding, map views or
              additional features?
            </span>
            <span className="settingshome__mpPlanInfoCard--subHeader">
              <a
                href="https://www.arway.ai/contact-9"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="settingshome__mpPlanInfoCard--subHeaderContact">
                  Contact us
                </span>
              </a>
              to create a private plan and receive your private access code.
            </span>
          </Box>
          <SecretKeyCard />
        </Grid>
      </Grid>
    </div>
  );
}


export default MyPlanHome;
