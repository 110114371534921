import EventEmitter from "../../utils/EventEmitter";
import {
    UIImage,
    UIPanel,
    UIRangeSlider,
    UIRow,
    UIText,
    UITextHeader,
} from "../ui.lib";

import CloseIcon from "../../../../assets/icons/sidebar/cross_menu.svg";
import { MiniMapNavigator } from "../../utils/MiniMapNavigator";

class MenubarNavigator extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.strings = this.editor.strings;

        this.dom = null;

        this.zoomLevel = 100;
        // this.xRotValue = 0;
        // this.yRotValue = 0;

        this.selector = new MiniMapNavigator(this.resizeCallback);

        this.isActive = false;
        this.editor.on(
            "cameraInteractionModeChanged",
            this.onCameraInteractionModeChanged
        );
        this.editor.resources.on("ready", this.initNavigatorForMiniMap);
        this.editor.on("floorDataReady", this.initNavigatorForMiniMap);

        this.initUI();
    }

    initUI = () => {
        this.uiCont = new UIPanel();
        this.uiCont.addClass("wsNavigator");
        this.uiCont.setId("wsNavigator");
        this.uiCont.setDisplay("none");

        // this.uiCont.addChild(new UIText("100%", "Hello"))
        const uiHeaderRow = new UIRow();
        uiHeaderRow.addClass("wsNavigator--headerRow");
        uiHeaderRow.addChild(new UITextHeader(undefined, "Navigator"));
        uiHeaderRow.addChild(
            new UIImage(CloseIcon, "24px", "24px", "Close").onClick(() => {
                this.makeActive(false);
            })
        );

        this.uiCont.addChild(uiHeaderRow);

        const uiNavImgCont = new UIPanel();
        uiNavImgCont.addClass("wsNavigator--navigatorCont");

        this.uiNavigator = new UIPanel();
        this.uiNavigator.addClass("wsNavigator--navigator");

        uiNavImgCont.addChild(this.uiNavigator);

        this.uiCont.addChild(uiNavImgCont);

        const uiZoomSliderCont = new UIRow();
        uiZoomSliderCont.addClass("wsNavigator--uiSlider");
        const uiZoomTextCont = new UIPanel();
        uiZoomTextCont.addClass("wsNavigator--uiTextCont");
        this.uiZoomLevelText = new UIText(undefined, `${this.zoomLevel}%`);
        this.uiZoomLevelText.addClass("wsNavigator--uiText");
        uiZoomTextCont.addChild(this.uiZoomLevelText);
        uiZoomSliderCont.addChild(uiZoomTextCont);
        this.uiSliderZoom = new UIRangeSlider("uiSliderZoom", 1, 100, 100);
        this.uiSliderZoom.onChange((e) => {
            this.handleSliderChange("zoom", e.target.value);
            this.selector.resizeSizeBoxByPercentage(e.target.value);
        });
        uiZoomSliderCont.addChild(this.uiSliderZoom);
        this.uiCont.addChild(uiZoomSliderCont);

        /* const uiRotXCont = new UIRow();
        uiRotXCont.addClass("wsNavigator--uiSlider");
        const uiXRotTextCont = new UIPanel();
        uiXRotTextCont.addClass("wsNavigator--uiTextCont");
        this.uiXRotText = new UIText(undefined, `${this.xRotValue}`);
        this.uiXRotText.addClass("wsNavigator--uiText");
        uiXRotTextCont.addChild(this.uiXRotText);
        const uiXRotHelper = new UIText(undefined, "X");
        uiXRotHelper.addClass("wsNavigator--uiTextHelper");
        uiXRotTextCont.addChild(uiXRotHelper);
        uiRotXCont.addChild(uiXRotTextCont);
        this.uiSliderXRot = new UIRangeSlider("uiSliderXRot", 0, 180, 0);
        this.uiSliderXRot.onChange((e) => {
            this.handleSliderChange("rotX", e.target.value);
        });
        uiRotXCont.addChild(this.uiSliderXRot);
        this.uiCont.addChild(uiRotXCont); */

        /* const uiRotYCont = new UIRow();
        uiRotYCont.addClass("wsNavigator--uiSlider");
        const uiYRotTextCont = new UIPanel();
        uiYRotTextCont.addClass("wsNavigator--uiTextCont");
        this.uiYRotText = new UIText(undefined, `${this.yRotValue}`);
        this.uiYRotText.addClass("wsNavigator--uiText");
        uiYRotTextCont.addChild(this.uiYRotText);
        const uiYRotHelper = new UIText(undefined, "Y");
        uiYRotHelper.addClass("wsNavigator--uiTextHelper");
        uiYRotTextCont.addChild(uiYRotHelper);
        uiRotYCont.addChild(uiYRotTextCont);
        this.uiSliderYRot = new UIRangeSlider("uiSliderYRot", -180, 180, 0);
        this.uiSliderYRot.onChange((e) => {
            this.handleSliderChange("rotY", e.target.value)
        });
        uiRotYCont.addChild(this.uiSliderYRot);
        this.uiCont.addChild(uiRotYCont); */

        this.dom = this.uiCont;
    };

    makeActive = (bFlag = undefined) => {
        this.isActive = bFlag !== undefined ? bFlag : !this.isActive;
        this.uiCont.setDisplay(this.isActive ? "" : "none");
        this.selector.resetDOM();
        !this.isActive &&
            this.editor.trigger("cameraInteractionModeChanged", [null]);
    };

    onCameraInteractionModeChanged = (name) => {
        if (name === "navigator") {
            this.makeActive();
        } else if (this.isActive) {
            this.makeActive(false);
        }
    };

    handleSliderChange = (type, value) => {
        if (type === "zoom") {
            this.zoomLevel = value;
            this.uiZoomLevelText.setTextContent(`${this.zoomLevel}%`);
        } /* else if (type === "rotX") {
            this.xRotValue = value;
            this.uiXRotText.setTextContent(`${this.xRotValue}`);
        } else if (type === "rotY") {
            this.yRotValue = value;
            this.uiYRotText.setTextContent(`${this.yRotValue}`);
        } */
    };

    emptyContent = () => {
        var child = this.uiNavigator.dom.lastChild;
        while (child) {
            this.uiNavigator.dom.removeChild(child);
            child = this.uiNavigator.dom.lastChild;
        }
    }

    initNavigatorForMiniMap = () => {
        if ("hasFloor" in this.editor.floorData) {
            this.emptyContent();
            const uiFloor = new UIImage(
                this.editor.floorData.assetLink,
                "100%",
                "100%"
            ).addClass("wsNavigator--navFloor");
            uiFloor.dom.addEventListener("load", (e) => {
                const img = e.target;
                const containerWidth = 236,
                    containerHeight = 136;
                const imageWidth = img.naturalWidth,
                    imageHeight = img.naturalHeight;

                const containerAspectRatio = containerWidth / containerHeight;
                const imageAspectRatio = imageWidth / imageHeight;

                let scaleFactor;
                let scaledWidth;
                let scaledHeight;

                if (imageAspectRatio > containerAspectRatio) {
                    scaleFactor = containerWidth / imageWidth;
                    scaledWidth = containerWidth;
                    scaledHeight = imageHeight * scaleFactor;
                } else {
                    scaleFactor = containerHeight / imageHeight;
                    scaledWidth = imageWidth * scaleFactor;
                    scaledHeight = containerHeight;
                }

                this.selector.setUpSelector(
                    this.uiNavigator.dom,
                    containerWidth / 2 - scaledWidth / 2 + 2,
                    containerHeight / 2 - scaledHeight / 2 + 2,
                    scaledWidth,
                    scaledHeight
                );
            });

            this.uiNavigator.addChild(uiFloor);
        }
    };

    resizeCallback = (value) => {
        this.handleSliderChange('zoom', value);
        this.uiSliderZoom.dom.value = value;
    }
}

export { MenubarNavigator };
