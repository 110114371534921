import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Grid, Box
} from '@mui/material';
import { Check } from 'react-feather';
import { 
    getAccountPlanInfo, 
    resetAccountPlanState 
} from '../../../../../../../features/admin/manageaccounts/manageAccountsSlice';
import { getPlans } from '../../../../../../../features/admin/plans/plansSlice';

import Loader from '../../../../../../common/loader';
import UserAvtarIcon from '../../../../../../../assets/icons/user_avtar.png';
import { convertToNamedDate } from '../../../../../../_utils/dateFormatter';
import UsageGraph from '../../../../../users/settings/myplan/UsageGraph';
import ManagePlans from './ManagePlans';


export default function PlanInfoTab({accountDetails, accountData}) {
    const dispatch = useDispatch();

    const [ plan, setPlan ] = useState(null);
    const [ plansList, setPlansList ] = useState([]);
    const [ openModal, setOpenModal ] = useState(false);

    const planDetails = useSelector(state => state.manageaccounts.accountPlanDetails);
    const planData = useSelector(state => state.plans.plansList);
    const changeLog = useSelector(state => state.adminanalytics.analyticsLog);

    useEffect((()=>{
        dispatch(getPlans())
        dispatch(getAccountPlanInfo(accountDetails.email));
        return(() => dispatch(resetAccountPlanState()))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[]);

    useEffect((() => {
        if(planDetails && planDetails.plan) {
            setPlan(planDetails.plan)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[planDetails])

    useEffect((() => {
        if(planData) {
            setPlansList(planData)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }),[planData]);

    useEffect(() => {
        if(changeLog === 'success') {
            dispatch(getAccountPlanInfo(accountDetails.email))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [changeLog]);

    const renderPlanFeature = (label, value) => {
        return (
            <div className="accountplanhome__apFeature">
                <Check color="#2A4DC2" size="18"/>
                <span className="accountplanhome__apFeature--label">{label}</span>
                <span className="accountplanhome__apFeature--data">{value}</span>
            </div>
        )
    }

    const renderCurrentPlanDetails = (planName) => {
        const cPlan = plansList.find(p => p.name === planName);

        if(!cPlan) return null;

        return(
            <div className="accountplanhome__apDetailsCard">
                <div className="accountplanhome__apDetailsCard--header">{cPlan.name}</div>
                <div className="accountplanhome__apDetailsCard--pInfoCont">
                    <div className="accountplanhome__apDetailsCard--pHeader">
                        <span className="accountplanhome__apDetailsCard--pHeader--text">Plan Features</span>
                        <span className="accountplanhome__apDetailsCard--pHeader--price">&#36; {cPlan.price}</span>
                    </div>
                    <Grid container={true} className='accountplanhome__apFeatureGrid'>
                        <Grid item xs={6}>{renderPlanFeature("Storage:", `${cPlan.storage || 0} MB`)}</Grid>
                        <Grid item xs={6}>{renderPlanFeature("API Calls:", `${cPlan.apiCalls || 0}`)}</Grid>
                    </Grid>
                    <Grid container={true} className='accountplanhome__apFeatureGrid'>
                        <Grid item xs={6}>{renderPlanFeature("Maps Limit:", `${cPlan.mapsLimit || 0}`)}</Grid>
                        <Grid item xs={6}>{renderPlanFeature("Plan Duration:", `${cPlan.duration || 0} Days`)}</Grid>
                    </Grid>
                    <Grid container={true} className='accountplanhome__apFeatureGrid'>
                        <Grid item xs={6}>{renderPlanFeature("Recurring Plan:", `${cPlan.isRecurring ? "Yes" : "No"}`)}</Grid>
                        <Grid item xs={6}>{renderPlanFeature("Map Area Limit:", `${cPlan.mapArea.enable ? cPlan.mapArea.size + " SqFt" : "No Limits"}`)}</Grid>
                    </Grid>
                    <Grid container={true} className='accountplanhome__apFeatureGrid'>
                        <Grid item xs={6}>{renderPlanFeature("Active Map Days:", `${cPlan.activeMapDays.enable ? cPlan.activeMapDays.count + " Days" : "No Limit"}`)}</Grid>
                        <Grid item xs={6}>{renderPlanFeature("Published Map Limit:", `${cPlan.publishedMap.enable ? cPlan.publishedMap.count + " Maps" : "No Limit"}`)}</Grid>
                    </Grid>
                </div>
            </div>
        )
    }

    if(!plan) return <Loader loaderText={"Fetching Account Plan Details..."} height="500px" width="100%" />;

    if(plansList.length <= 0)return <Loader loaderText={"Fetching Account Plan Details..."} height="500px" width="100%" />;

    return (
        <div className='accountplanhome'>
            <div>
                <Grid container={true} className="settingshome__acGridContainer">
                    <Grid item xs={4} className="settingshome__acCardContainer">
                        <div className="settingshome__acCard">
                            <img src={UserAvtarIcon} alt="user_avtar" className='settingshome__acCard--userAvtarImg' draggable='false' />
                            <div className="settingshome__acCard__infoColCont">
                                <h4>{plan.planName}</h4>
                                <div className="settingshome__acCard__infoColCont--infoCol">
                                    <span className="settingshome__acCard__infoColCont--infoCol--text">Date Activated</span>
                                    <span className="settingshome__acCard__infoColCont--infoCol--data">{convertToNamedDate(plan.planDuration.startDate)}</span>
                                </div>
                                <button onClick={() => setOpenModal(true)}>Manage Plan</button>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={8} className="accountplanhome__apInfoCont">
                        {renderCurrentPlanDetails(plan.planName)}
                    </Grid>
                </Grid>
            </div>
            <div className='accountplanhome__usageContainer'>
                <Grid container={true}>
                    <Grid item xs={6}>
                        <Box className="settingshome__mpUsageCard">
                            <div className="settingshome__mpUsageCard--headerRow">
                                <span className="settingshome__mpUsageCard--headerText">
                                Map Views
                                </span>
                            </div>
                            <UsageGraph
                                unit="Monthly"
                                unitString="views"
                                limit={plan.planApiLimit}
                                usage={plan.planCurrentApi || 0}
                                type="INT"
                            />
                            <div className='accountplanhome__usageContainer--moreBtn' onClick={() => setOpenModal(true)}>Add More</div>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="settingshome__mpUsageCard">
                            <div className="settingshome__mpUsageCard--headerRow">
                                <span className="settingshome__mpUsageCard--headerText">
                                Storage
                                </span>
                            </div>
                            <UsageGraph
                                unitString="MB"
                                limit={plan.planStorageLimit}
                                usage={plan.planCurrentStorage || 0}
                            />
                            <div className='accountplanhome__usageContainer--moreBtn' onClick={() => setOpenModal(true)}>Add More</div>
                        </Box>
                    </Grid>
                    <Grid item xs={6} mt={3}>
                        <Box className="settingshome__mpUsageCard">
                            <div className="settingshome__mpUsageCard--headerRow">
                                <span className="settingshome__mpUsageCard--headerText">
                                    Map Limit
                                </span>
                            </div>
                            <UsageGraph
                                unitString={plan.mapsLimit.limit > 1 ? "maps" : "map"}
                                limit={plan.mapsLimit.limit}
                                usage={plan.mapsLimit.total || 0}
                                type="INT"
                            />
                            <div className='accountplanhome__usageContainer--moreBtn' onClick={() => setOpenModal(true)}>Add More</div>
                        </Box>
                    </Grid>
                    {plan.mapArea.enable &&
                        <Grid item xs={6} mt={3}>
                            <Box className="settingshome__mpUsageCard">
                                <div className="settingshome__mpUsageCard--headerRow">
                                    <span className="settingshome__mpUsageCard--headerText">
                                        Map Area
                                    </span>
                                </div>
                                <UsageGraph
                                    unitString="SqFt"
                                    limit={Math.round(plan.mapArea.limit * 10.764)}
                                    usage={plan.mapArea.total > 0 ?  Math.round(plan.mapArea.total * 10.764) : 0}
                                    type="INT"
                                />
                                <div className='accountplanhome__usageContainer--moreBtn' onClick={() => setOpenModal(true)}>Add More</div>
                            </Box>
                        </Grid>
                    }
                    {plan.publishedMap.enable &&
                        <Grid item xs={6} mt={3}>
                            <Box className="settingshome__mpUsageCard">
                                <div className="settingshome__mpUsageCard--headerRow">
                                    <span className="settingshome__mpUsageCard--headerText">
                                        Published Map
                                    </span>
                                </div>
                                <UsageGraph
                                    unitString={plan.publishedMap.limit > 1 ? "maps" : "map"}
                                    limit={plan.publishedMap.limit}
                                    usage={plan.publishedMap.total  || 0}
                                    type="INT"
                                />
                                <div className='accountplanhome__usageContainer--moreBtn' onClick={() => setOpenModal(true)}>Add More</div>
                            </Box>
                        </Grid>
                    }
                    {plan.activeMapDays.enable &&
                        <Grid item xs={6} mt={3}>
                            <Box className="settingshome__mpUsageCard">
                                <div className="settingshome__mpUsageCard--headerRow">
                                    <span className="settingshome__mpUsageCard--headerText">
                                        Active Map Days
                                    </span>
                                </div>
                                <UsageGraph
                                    unitString={plan.activeMapDays.limit > 1 ? "days" : "day"}
                                    limit={plan.activeMapDays.limit}
                                    usage={plan.activeMapDays.total || 0}
                                    type="INT"
                                />
                                <div className='accountplanhome__usageContainer--moreBtn' onClick={() => setOpenModal(true)}>Add More</div>
                            </Box>
                        </Grid>
                    }
                </Grid>
            </div>
            <ManagePlans 
                openModal={openModal}
                onCloseModal={() => setOpenModal(false)}
                plansList={plansList}
                currentPlan={plan}
                accountData={accountData}
            />
        </div>
    )
}
