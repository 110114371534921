import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";

export const encodeVideo = async (videoFile, cb) => {
  const ffmpeg = createFFmpeg({
    log: true,
    progress: (e) => {
      cb(e);
    },
  });
  await ffmpeg.load();
  ffmpeg.FS("writeFile", "sourceVideo.mp4", await fetchFile(videoFile));
  await ffmpeg.run(
    "-i",
    "sourceVideo.mp4",
    "encVideo.mp4",
    "-minrate",
    "500k",
    "-maxrate",
    "5000k",
    "-bufsize",
    "2500k",
    "-r",
    "24",
    "-movflags",
    "faststart",
    "-vf",
    "scale='min(1080,iw)':-1",
    "-c:v",
    "libx265",
    "-tag:v",
    "hvc1"
  );
  const data = ffmpeg.FS("readFile", "encVideo.mp4");
  return new File(
    [new Blob([data.buffer], { type: "video/mp4" })],
    videoFile.name,
    {
      type: "video/mp4",
    }
  );
};
