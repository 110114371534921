import * as THREE from 'three';
import EditorExperience from '../../2DEditorExperience';
import { isEqual, omit, has } from 'lodash';

import {
    fromPos2dObjectToVec3Pos, 
    fromPosObjectToVec3Pos, 
    fromVec3PosToPosObject,
    getTransformed2DPos,
    getTransformed2DPosV1,
    getTransformed3DPos,
    getTransformed3DPosV1,
} from '../../../threeUtils/TransformConversions';
import { dispose } from '../../../utils/Dispose.Utils';
import { 
    getConnectorColorIndex, 
    getConnectorSpriteTexture, 
    getTypeFromName,
    getConnectorPrename,
    getConnectorIdFromName
} from './ConnectorUtils';

const nullPos2dObj = {
    "posX": 0,
    "posY": 0
}

export default class Connector {
    constructor(pinProps) {
        this.pinProps = pinProps;

        // console.log(pinProps);

        this.editor = new EditorExperience();
        this.pinType = pinProps.pinType.toLowerCase(); // this is a temp fix!


        this.name = pinProps.name || "";
        this.description = pinProps.description || "";
        this.id = pinProps.id;
        this.connectorType = pinProps.connectorType;
        this.texType = getTypeFromName(this.connectorType);

        this.amenityType = pinProps.amenityPinTypes.length 
            ? pinProps.amenityPinTypes 
            : [getConnectorIdFromName([...this.editor.amenityTypes], this.connectorType)];

        this.scaleXZ = this.editor.locScales.amScaleXZ;
        this.scaleY = this.editor.locScales.scaleY;

        this.scaleXZ /= (this.scaleXZ / (this.editor.floorData.MIN_SIZE * .023));
        this.scaleY /= (this.scaleY / (this.editor.floorData.MIN_SIZE * .03));

        this.pinColor = pinProps.pinColor || '#36CCE7';
        this.pinNavStyle = pinProps.navigationStyle || 'default';
        this.visible = pinProps.visible || true;
        this.wheelchairAccessible = pinProps.wheelchairAccessible || false;
        this.connectorGroupId = pinProps.connectorGroupId || null;
        this.relatedAccessPointID = pinProps.relatedAccessPointID || null;
        this.entryMode = pinProps.entryMode || 'both';
        this.direction = pinProps.direction || 'both';
        this.toCreate = pinProps.toCreate || false

        this.position = pinProps.position2d && !isEqual(pinProps.position2d, nullPos2dObj) 
                ? fromPos2dObjectToVec3Pos(pinProps.position2d) 
                : this.get2dPosFrom3dPos(fromPosObjectToVec3Pos(pinProps.position));

        this.position3d = fromPosObjectToVec3Pos(pinProps.position);

        this.mesh = {};
        this.bufferedChanges = {};

        pinProps = omit(pinProps, ['wsType']);

        this.editor.jsonLocationPins.push(pinProps);

        this.editor.on('objectChanged', this.onObjectChanged);
        this.editor.on('meta2dObjectChanged', this.onMetaObjectChanged);
        this.editor.on('SaveMetaObject', this.onSaveMetaObject);
        this.editor.on('objectRemoved', this.onObjectRemoved);
        this.editor.on('updateConnectorPrename', this.onUpdateConnectorPrename);
        this.editor.on('updateObject3DPositionsWithNewFloorMatrix', this.onUpdateObject3DPositions);

        this.setUpConnectorPin();

    }

    setUpConnectorPin = () => {
        const spriteTex = new THREE.TextureLoader().load(getConnectorSpriteTexture(this.texType, getConnectorColorIndex(this.pinColor)));
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        this.spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            transparent: true,
            depthTest: false,
            depthWrite: false,
        });

        this.mesh = new THREE.Sprite(this.spriteMaterial);
        this.mesh.position.copy(this.position);
        this.mesh.scale.set(this.scaleXZ, this.scaleY, this.scaleXZ);
        this.mesh.rotation.y = THREE.MathUtils.degToRad(180);
        this.mesh.visible = this.visible;

        // adjust pos
        this.mesh.position.y += this.mesh.scale.y * 0.4;
        this.position.copy(this.mesh.position);

        this.mesh.name = this.name;
        this.mesh.userData['preName'] = this.editor.activeFloor ? getConnectorPrename(this.editor.activeFloor, this.connectorType): '';
        this.mesh.userData['type'] = 'Connector Pin';
        this.mesh.userData['id'] = this.id;
        this.mesh.userData['description'] = this.description;
        this.mesh.userData['pinColor'] = this.pinColor;
        this.mesh.userData['navigationStyle'] = this.pinNavStyle;
        this.mesh.userData['pinType'] = this.pinType;
        this.mesh.userData['connectorType'] = this.connectorType;
        this.mesh.userData['visible'] = this.visible;
        this.mesh.userData['viewType'] = this.pinType; 
        this.mesh.userData['wheelchairAccessible'] = this.wheelchairAccessible; 
        this.mesh.userData['connectorGroupId'] = this.connectorGroupId; 
        this.mesh.userData['relatedAccessPointID'] = this.relatedAccessPointID; 

        this.toCreate && (this.mesh.userData['toCreate'] = this.toCreate);

        if(this.connectorType === 'elevator') {
            this.mesh.userData['indexName'] = this.name;
        } else if(this.connectorType === 'escalator') {
            this.mesh.userData['entryName'] = this.name;
            this.mesh.userData['entryMode'] = this.entryMode; 
            this.entryMode !== 'exit' && (this.mesh.userData['direction'] = this.direction);
        } else if(this.connectorType === 'stairs' || this.connectorType === 'bridge') {
            this.mesh.userData['entryName'] = this.name;
            this.mesh.userData['entryMode'] = this.entryMode; 
            this.mesh.userData['direction'] = this.direction;
        }
    }

    onMetaObjectChanged = (object, property, value, discardAll = false) => {
        if (object === this.mesh) {
            'pinColor' === property && this.updatePinSkin(value);
            'entryMode' === property && this.onModifyEntryMode(value);
            this.bufferedChanges[property] = value;
        } else if (discardAll) {
            'pinColor' in this.bufferedChanges && this.updatePinSkin(this.pinColor);
            this.bufferedChanges = {};
        }
    }

    prepareObjForAPI = (reqObj) => {
        reqObj.images && (reqObj.images = reqObj.images?.map(img => ({ url: img.link?img.link:img.url, order: img.order })))
        reqObj.amenityPinTypes = reqObj?.amenityPinTypes?.length 
            ? reqObj.amenityPinTypes.map( type => {
                if(typeof type === 'object') return type.id;
                else return type
            }) 
            : this.amenityType;
        return {...reqObj};
    }

    onSaveMetaObject = async () => {
        if (Object.keys(this.bufferedChanges).length > 0) {

            // trigger loader 
            this.editor.trigger('mount2DLoader', [true]);
            this.editor.trigger('toggle2DLoaderProgress', ['', this.toCreate ? 'Creating Pin..' : 'Updating Pin...']);

            const idx = this.editor.getIndex('jsonLocationPins', this.id);
            let reqObj = { ...this.editor.jsonLocationPins[idx] }
            reqObj = omit(reqObj, ['type', 'amenityType', 'toCreate']);
            reqObj = this.prepareObjForAPI(reqObj);

            const proceedSave = () => {
                this.editor.jsonLocationPins[idx] = reqObj;
                // console.log(reqObj);
                if(this.toCreate) {
                    this.editor.conPinHelper.createPin(reqObj, this.mesh)
                } else {
                    this.editor.onPinAPICalls(reqObj, 'UPDATE', true);
                }
            }

            if (has(this.bufferedChanges, 'indexName')) {
                reqObj.name = this.bufferedChanges.indexName;
                this.name = this.bufferedChanges.indexName;
                this.mesh.name = this.bufferedChanges.indexName;
                this.mesh.userData.indexName = this.bufferedChanges.indexName;
            }

            if (has(this.bufferedChanges, 'entryName')) {
                reqObj.name = this.bufferedChanges.entryName;
                this.name = this.bufferedChanges.entryName;
                this.mesh.name = this.bufferedChanges.entryName;
                this.mesh.userData.entryName = this.bufferedChanges.entryName;
            }
            if (has(this.bufferedChanges, 'description')) {
                reqObj.description = this.bufferedChanges.description;
                this.description = this.bufferedChanges.description;
                this.mesh.userData.description = this.bufferedChanges.description;
            }
            if (has(this.bufferedChanges, 'navigationStyle')) {
                reqObj.navigationStyle = this.bufferedChanges.navigationStyle;
                this.pinNavStyle = this.bufferedChanges.navigationStyle;
                this.mesh.userData.navigationStyle = this.bufferedChanges.navigationStyle;
            }
            if (has(this.bufferedChanges, 'pinColor')) {
                reqObj.pinColor = this.bufferedChanges.pinColor;
                this.pinColor = this.bufferedChanges.pinColor;
                this.mesh.userData.pinColor = this.bufferedChanges.pinColor;
            }

            if (has(this.bufferedChanges, 'wheelchairAccessible')) {
                reqObj.wheelchairAccessible = this.bufferedChanges.wheelchairAccessible;
                this.wheelchairAccessible = this.bufferedChanges.wheelchairAccessible;
                this.mesh.userData.wheelchairAccessible = this.bufferedChanges.wheelchairAccessible;
            }

            if (has(this.bufferedChanges, 'relatedAccessPointID')) {
                reqObj.relatedAccessPointID = this.bufferedChanges.relatedAccessPointID;
                this.relatedAccessPointID = this.bufferedChanges.relatedAccessPointID;
                this.mesh.userData.relatedAccessPointID = this.bufferedChanges.relatedAccessPointID;
            }

            if (has(this.bufferedChanges, 'connectorGroupId')) {
                reqObj.connectorGroupId = this.bufferedChanges.connectorGroupId;
                this.connectorGroupId = this.bufferedChanges.connectorGroupId;
                this.mesh.userData.connectorGroupId = this.bufferedChanges.connectorGroupId;
            }

            if (has(this.bufferedChanges, 'direction')) {
                reqObj.direction = this.bufferedChanges.direction;
                this.direction = this.bufferedChanges.direction;
                this.mesh.userData.direction = this.bufferedChanges.direction;
            }

            if (has(this.bufferedChanges, 'entryMode')) {
                reqObj.entryMode = this.bufferedChanges.entryMode;
                this.entryMode = this.bufferedChanges.entryMode;
                this.mesh.userData.entryMode = this.bufferedChanges.entryMode;

                if(this.bufferedChanges.entryMode === 'both') {
                    reqObj.direction = 'both';
                    this.direction = 'both';
                    this.mesh.userData.direction = 'both';
                }
            }

            proceedSave();

        }
    }

    onObjectChanged = (object) => {
        if (this.mesh === object) {
            if(this.position.distanceTo(object.position) > 0) {
                const pos = object.position.clone();
                pos.y -= object.scale.y * 0.4;
                this.position.copy(pos);
                this.updateObject(true);
            } else if(this.visible !== object.userData.visible) {
                this.visible = object.userData.visible;
                this.updateObject();
            }
        }
    }

    onUpdateConnectorPrename = () => {
        this.mesh.userData['preName'] = this.editor.activeFloor ? getConnectorPrename(this.editor.activeFloor, this.connectorType): '';
    }

    updatePinSkin = (color) => {
        const spriteTex = new THREE.TextureLoader().load(getConnectorSpriteTexture(this.texType, getConnectorColorIndex(color)));
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        this.spriteMaterial.map = spriteTex;
        this.spriteMaterial.needsUpdate = true;
    }

    onModifyEntryMode = (value) => {
        if(this.connectorType === 'escalator' && this.entryMode !== value && !this.toCreate) {
            this.editor.onToggleConnectorInfoModals('EscalatorEntryMode', true, {prevMode: value})
        } else if(this.connectorType === 'escalator' && this.entryMode !== value 
            && this.toCreate && this.editor.conPinHelper.pairStep === 2) {
            this.editor.onToggleConnectorInfoModals('EscalatorEntryMode', true, {prevMode: value})
        }
    }

    updateObject = (flag = false) => {
        const idx = this.editor.getIndex('jsonLocationPins', this.id);
        let reqObj = { ...this.editor.jsonLocationPins[idx] }
        reqObj = omit(reqObj, ['type','amenityType']);
        reqObj = this.prepareObjForAPI(reqObj);

        // change pos & color here
        // reqObj.pinColor = this.pinColor;
        if(flag) {
            reqObj.position2d = { posX: this.position.x, posY: Math.abs(this.position.y) };
            reqObj.position = this.getTransformedPos({ posX: this.position.x, posY: Math.abs(this.position.y) });
        }
        reqObj.visible = this.visible;

        this.editor.jsonLocationPins[idx] = reqObj;
        !this.toCreate && this.editor.onPinAPICalls(reqObj, 'UPDATE');
    }

    onUpdateObject3DPositions = () => {
        // this.updateObject()
        const idx = this.editor.getIndex('jsonLocationPins', this.id);
        if(idx === -1) return;
        let reqObj = { ...this.editor.jsonLocationPins[idx] }
        reqObj = omit(reqObj, ['type','amenityType']);
        reqObj = this.prepareObjForAPI(reqObj);
        let changeObj = {
            id: reqObj.id
        }

        const pos = this.position.clone();
        pos.y -= this.mesh.scale.y * 0.4;

        if(this.editor.manualAligned === '3d_change'){
            reqObj.position2d = changeObj['position2d'] = { posX: pos.x, posY: Math.abs(pos.y) };
            reqObj.position = changeObj['position'] = this.getTransformedPos({ posX: pos.x, posY: Math.abs(pos.y) });

            // this.position3d.copy(fromPosObjectToVec3Pos(reqObj.position))
        } else if(this.editor.manualAligned === '2d_change') {
            const pos2d = this.get2dPosFrom3dPos(this.position3d.clone());
            reqObj.position2d = changeObj['position2d'] = { posX: pos2d.x, posY: Math.abs(pos2d.y) };
            reqObj.position = changeObj['position'] = fromVec3PosToPosObject(this.position3d);

            this.mesh.position.copy(pos2d);
            this.mesh.position.y += this.mesh.scale.y * 0.4;
            this.position.copy(this.mesh.position);
        }

        this.editor.jsonLocationPins[idx] = reqObj;
        this.editor.pinReadjustObj.push(changeObj);
        this.editor.trigger('requestPinPathReadjustment');
    }

    getTransformedPos = (pos) => {
        const { worldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6 
            ? getTransformed3DPos(worldMatrix, imgWidth, imgHeight, pos, true)
            : getTransformed3DPosV1(worldMatrix, imgWidth, imgHeight, pos, true);
    }

    get2dPosFrom3dPos = (pos3d) => {
        const { invWorldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6 
            ? getTransformed2DPos(invWorldMatrix, imgWidth, imgHeight, pos3d)
            : getTransformed2DPosV1(invWorldMatrix, imgWidth, imgHeight, pos3d)
    }

    onObjectRemoved = (object, delayAutosave, isSwitch) => {
        if (!isSwitch && object === this.mesh) {
            const idx = this.editor.getIndex('jsonLocationPins', this.id);
            const pin = this.editor.jsonLocationPins.splice(idx, 1);
            !this.toCreate && this.editor.onPinAPICalls(pin[0].id, 'DELETE');
            if(object && object.userData.connectorType === 'escalator' && !this.toCreate) {
                this.editor.onToggleConnectorInfoModals('DeleteEscalatorPair', true, null)
            }
        } else if(isSwitch && object === this.mesh) {
            this.onCallDestructor(object);
        }
    }

    onCallDestructor = (object) => {
        if(object === this.mesh) {
            this.editor.stop('objectChanged', this.onObjectChanged);
            this.editor.stop('meta2dObjectChanged', this.onMetaObjectChanged);
            this.editor.stop('SaveMetaObject', this.onSaveMetaObject);
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            this.editor.stop('updateConnectorPrename', this.onUpdateConnectorPrename);
            this.editor.stop('updateObject3DPositionsWithNewFloorMatrix', this.onUpdateObject3DPositions);
            dispose(this.mesh);
        }
    }

}
