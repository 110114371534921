import * as THREE from 'three';
import EditorExperience from "../EditorExperience";
import EventEmitter from "../utils/EventEmitter";
import { 
    fromPosObjectToVec3Pos, 
    fromQuaternionObjectToQuaternion,
    fromScaleObjectToVec3Scale, 
} from "./TransformConversions";
import { dispose } from '../utils/Dispose.Utils';

export default class Image extends EventEmitter {
    constructor(imgData, type) {
        super();
        this.imgData = imgData;
        this.editor = new EditorExperience();

        this.position = imgData.position ? fromPosObjectToVec3Pos(imgData.position) : new THREE.Vector3(0, 0, 0);
        this.rotation = imgData.rotation ? fromQuaternionObjectToQuaternion(imgData.rotation) : new THREE.Quaternion(0, 0, 0, 0);
        this.scale = imgData.scale ? fromScaleObjectToVec3Scale(imgData.scale) : new THREE.Vector3(1, 1, 1);
        this.imageMesh = null;

        this.type = type;
        this.name = imgData.name ? imgData.name.substring(0, 20) : '';
        this.id = imgData.id;
        this.assetLink = imgData.link;
        this.showAsIcon = imgData.showAsIcon || false;

        this.visible = 'visible' in imgData ? imgData.visible : true;
        this.autoRotate = 'autoRotate' in imgData ? imgData.autoRotate : false;
        this.canDownload = 'canDownload' in imgData ? imgData.canDownload : false;

        this.texLoader = new THREE.TextureLoader();

        this.editor.on('objectRemoved', this.onObjectRemoved);

        this.setupObject();
    }
    

    setupObject = () => {
        let imgTex = this.texLoader.load(this.assetLink, (tex) => {
            tex.minFilter = tex.magFilter = THREE.LinearFilter;
            tex.colorSpace = THREE.SRGBColorSpace;
            tex.anisotropy = 4;

            this.imgMat.map = tex;
            imgTex.needsUpdate = true;
            this.imgMat.transparent = false;
            this.imgMat.needsUpdate = true;
            //Fix Aspect Ratio
            this.imgMesh.scale.set(1.0, tex.image.height / tex.image.width, 1.0);
        });

        this.imgMat = new THREE.MeshBasicMaterial({ map: imgTex, side: THREE.DoubleSide, transparent: true});
        let imgGeo = new THREE.PlaneGeometry(1, 1, 32, 32);
        this.imgMesh = new THREE.Mesh(imgGeo, this.imgMat);

        this.type === 'image' && this.imgMesh.rotateY(THREE.MathUtils.degToRad(180));

        this.imageMesh = new THREE.Group();
        this.imageMesh.add(this.imgMesh);
        this.imageMesh.name = this.name.substring(0, 20);
        this.imageMesh.userData['id'] = this.id;
        this.imageMesh.userData['link'] = this.assetLink;
        this.imageMesh.userData['skipChild'] = this.type;
        this.imageMesh.userData['visible'] = this.visible;
        this.imageMesh.userData['showAsIcon'] = this.showAsIcon;
        this.imageMesh.userData['autoRotate'] = this.autoRotate;
        this.imageMesh.userData['canDownload'] = this.canDownload;

        this.imageMesh.userData['type'] = this.type;
        this.imageMesh.position.copy(this.position);
        this.imageMesh.scale.copy(this.scale);
        this.imageMesh.quaternion.copy(this.rotation);
        this.imageMesh.visible = this.visible;
    }

    setLink = (link) => {
        this.assetLink = link;
        this.imageMesh.userData['link'] = this.assetLink;
    }

    onObjectRemoved = (object) => {
        if(object === this.imageMesh) {
            this.editor.stop('objectRemoved', this.onObjectRemoved);
            dispose(this.imageMesh);
        }
    }
}