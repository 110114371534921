import * as THREE from 'three';
import Server from '../../../../../api';
import { omit } from 'lodash';

import EditorExperience from '../../2DEditorExperience';
import EventEmitter from '../../../utils/EventEmitter';
import Connector from './Connector';

import { 
    fromVec3Pos2dToPosObject,
    getRandomIdCode,
    getTransformed3DPos,
    getTransformed3DPosV1
} from '../../../threeUtils/TransformConversions';
import { getConnectorIdFromName, getConnectorSpriteTexture } from './ConnectorUtils';

//Command
import { AddObject } from '../../../commands/AddObject';
import { RemoveObject } from '../../../commands/RemoveObject';


class ConnectorPinHelper extends EventEmitter {
    constructor() {
        super();
        
        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;
        this.time = this.editor.time;

        this.startNavigationTrack = false;

        this.pinFeature = 'connector';
        this.connectorData = null;
        this.activeType = '';
        this.activeGroupId = '';

        // directions and entries and step in pair! (NEEDED FOR ESCALATORS)
        /* FYI
            entrymode - {enter, exit, both}
            direction - {up,down,both}
        */
        this.entryTypes = {
            'up': 'enter',
            'down': 'enter',
            'both': 'both'
        }
        this.pairStep = 1;
        this.direction = 'both';
        this.entryMode = 'both';

        this.bufferPin = null;

        this.intersectionPoint = new THREE.Vector3();
        this.raycaster = new THREE.Raycaster();

        this.raycastObjects = [];

        // this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('continueConnectorPlacement', this.onContinueConnectorPlacement);
        this.editor.on('isBufferPinDiscarded', this.onIsBufferPinDiscarded);
        this.editor.on('setConnectorDirection', this.onSetConnectorDirection)
        this.editor.on('toggleEscalatorDirectionModal', this.toggleEscalatorDirectionModal);
        this.editor.on('cancelConnectorPlacement', this.exitNavigationTracking);
    }
    
    resetState = () => {
        this.pairStep = 1;
        this.activeGroupId = '';
        this.direction = 'both';
        this.entryMode = this.entryTypes[this.direction];
    }

    getPairStep = () => this.pairStep;

    setUpControls = ( type, connectorData = null ) => {
        if(this.startNavigationTrack || this.editor.pathController.startNavigationTrack) return;

        this.scene.children.forEach((child) => {
            if(child.userData && child.userData.navHelper &&  child.userData.navHelper === 'floorplan') {
                this.raycastObjects.push(child)
            }
        })

        if(this.raycastObjects.length > 0) {
            this.startNavigationTrack = true;
            this.editor.trigger('navigationTracking', [true]);
            this.connectorData = connectorData;
            this.activeType = type;

            this.layoutNavPaths(0, type, new THREE.Vector3());
        } else {
            this.editor.callbacks.generateAlert({
                msg: "Add floor plan in map to place connectors.",
                alertType: "information"
            })
        }
    }

    layoutNavPaths = (index = -1, type, vec3Pos) => {

        this.currentHeader = this.cloneLocationHeader(index, type);
        this.currentHeader.position.copy(vec3Pos)
        this.editor.addObjects(this.currentHeader);

        this.canvas.addEventListener('mousemove', this.onMouseMove, false);
        this.canvas.addEventListener('mousedown', this.onMouseDown, false);
        document.addEventListener('keydown', this.onKeyDownExit, false);
    }

    onMouseMove = (e) => {
        if(this.startNavigationTrack) {
            let viewportDown = new THREE.Vector2();
            const rect = this.canvas.getBoundingClientRect();
            viewportDown.x =   ((( e.offsetX - rect.left) / rect.width ) * 2 ) - 1;
            viewportDown.y = - ((( e.offsetY - rect.top) / rect.height ) * 2 ) + 1;

            this.raycaster.setFromCamera(viewportDown, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(this.raycastObjects, true);

            if(intersects.length > 0) {
                const intersect = intersects[0];
                this.intersectionPoint.copy(intersect.point);
                this.currentHeader.position.set(this.intersectionPoint.x, this.intersectionPoint.y + this.currentHeader.scale.y * .4, this.intersectionPoint.z)
            }
        }
    }

    withinBounds = (e) => {
        let viewportBound = new THREE.Vector2();
        let raycasterBound = new THREE.Raycaster();
        const rect = this.canvas.getBoundingClientRect();
        viewportBound.x =   ((( e.offsetX - rect.left) / rect.width ) * 2 ) - 1;
        viewportBound.y = - ((( e.offsetY - rect.top) / rect.height ) * 2 ) + 1;

        raycasterBound.setFromCamera(viewportBound, this.camera.instance);
        const intersects = raycasterBound.intersectObjects(this.raycastObjects, true);

        return intersects.length > 0;
    }

    onMouseDown = (e) => {
        if(!this.withinBounds(e)) return;

        switch(e.which) {

            case 2:
                this.exitNavigationTracking();
            break;

            case 1:
                this.currentHeader.position.set(this.intersectionPoint.x, this.intersectionPoint.y + this.currentHeader.scale.y * .4, this.intersectionPoint.z)
                // consider tip of sprite as pos
                const tIntersectPt = this.intersectionPoint.clone();
                let wayObj = {
                    name: this.currentHeader.name,
                    position: fromVec3Pos2dToPosObject(tIntersectPt.clone()),
                    postion2D: {posX: tIntersectPt.x, posY: Math.abs(tIntersectPt.y)},
                    posTransformed: this.getTransformedPos({posX: tIntersectPt.x, posY: Math.abs(tIntersectPt.y)}),
                    id: this.currentHeader.userData['id'],
                    visible: true,
                    type: this.currentHeader.userData['type'],
                    pinFeature: this.pinFeature,
                    connectorData: this.connectorData,
                };
                // create pin call!
                this.loadPin(wayObj);
            break;

            default:
                break;
        }
    }


    onKeyDownExit = (e) => {
        if(e.keyCode === 27) {
            this.exitNavigationTracking();
        }
    }

    exitNavigationTracking = () => {
        this.editor.removeObject(this.currentHeader);
        this.startNavigationTrack = false;
        this.editor.trigger('navigationTracking', [false]);
        this.editor.trigger('isBufferPinDiscarded', [ this.editor.selectedObject ]);
        this.editor.trigger('continueConnectorPlacement', [ null ]);
        this.editor.deselect()
        this.resetState();
        this.canvas.removeEventListener('mousemove', this.onMouseMove, false);  
        this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
        document.removeEventListener('keydown', this.onKeyDownExit, false);
    }

    cloneLocationHeader = (index, type) => {
        const spriteTex = new THREE.TextureLoader().load(getConnectorSpriteTexture(type, 0));
        spriteTex.colorSpace = THREE.SRGBColorSpace;
        const spriteMaterial = new THREE.SpriteMaterial({
            map: spriteTex,
            transparent: true,
            depthTest: false,
            depthWrite: false,
        });

        let scaleXZ = type === 'location' ? this.editor.locScales.regScaleXZ: this.editor.locScales.amScaleXZ;
        let scaleY = this.editor.locScales.scaleY;

       scaleXZ /= (scaleXZ / (this.editor.floorData.MIN_SIZE * .023));
       scaleY /= (scaleY / (this.editor.floorData.MIN_SIZE * .03));

        let header = new THREE.Sprite(spriteMaterial);
        const randID = getRandomIdCode();
        header.name = `locationPin_${randID}`;
        header.userData['id'] = `pin_${getRandomIdCode()}`;
        header.userData['type'] = type;
        header.userData['zoomableScale'] = type;
        header.scale.set(scaleXZ, scaleY, scaleXZ);
        header.rotation.y = THREE.MathUtils.degToRad(180);
        return header;
    }

    getTransformedPos = (pos) => {
        const { worldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        return this.editor.floorplanVersion !== 2.6 
            ? getTransformed3DPos(worldMatrix, imgWidth, imgHeight, pos, true)
            : getTransformed3DPosV1(worldMatrix, imgWidth, imgHeight, pos, true);
    }

    onToggleMenuActive = () => {
        if(this.startNavigationTrack) {
            this.exitNavigationTracking();
        }
    }

    onContinueConnectorPlacement = (data) =>{
        if(data) {
            this.editor.preloadConnector['connectorPin'] =  this.activeType;
            this.activeGroupId = data?.groupData?.id;
        } else {
            this.resetState();
        }
    }

    onIsBufferPinDiscarded = (object) => {
        if(object && this.bufferPin && object === this.bufferPin) {
            this.editor.onCommand(new RemoveObject(this.editor, object, false), 'RESTRICT_UNDO');
            this.bufferPin = null;
            this.resetState();
        }
    }

    onSetConnectorDirection = (type) => { 
        if(type) {
            this.direction = type;
            this.entryMode = this.entryTypes[type];
        } else {
            this.exitNavigationTracking();
        }
        
    }

    toggleEscalatorDirectionModal = () => {
        if(this.activeType === 'escalator' && this.pairStep === 1) {
            this.editor.onToggleInfoModals("EscalatorDirection", true);
        } 
    }

    loadPin = (pinObj) => {

        const reqObj = {
            id: pinObj.id,
            name: "",
            description: "",
            pinType: pinObj.pinFeature,
            pinColor: "#36CCE7",
            visible: true, 
            navigationStyle: 'Default', 
            position: pinObj.posTransformed,
            position2d: pinObj.postion2D,
            categories: [],
            accessPath: [],
            advanced: {},
            connectorType: pinObj.type,
            connectorGroupId: this.activeGroupId,
            wheelchairAccessible: false,
            relatedAccessPointID: null,
            toCreate: true,
            entryMode: this.entryMode,
            direction: this.direction,
            amenityPinTypes: [getConnectorIdFromName([...this.editor.amenityTypes], pinObj.type)]
        }

        this.startNavigationTrack = false;
        this.editor.trigger('navigationTracking', [false]);
        this.editor.removeObject(this.currentHeader, true);

        const connectorPin = new Connector(reqObj);
        this.bufferPin = connectorPin.mesh;
        this.editor.onCommand(new AddObject(this.editor, connectorPin.mesh), 'RESTRICT_UNDO');

        this.currentHeader = null; 
        this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
        this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
        document.removeEventListener('keydown', this.onKeyDownExit, false);
    }

    createPin = (reqObj, object) => {
        reqObj = omit(reqObj, ['id']);
        // console.log("TO CREATE", reqObj);

        Server.post(`/v1/map/locationpin/${this.editor.editor3d.mapId}`, reqObj)
        .then((res) => {
            if(res.status === 200) {
                console.log("CREATED SUCCESSFULLY!", res.data);
                this.editor.trigger('mount2DLoader', [false]);
                this.editor.trigger('toggle2DLoaderProgress', ['none']);
                if(this.pairStep === 2) {
                    //re initiate the flow
                    this.resetState();
                } else {
                    this.pairStep++;
                    this.activeGroupId = reqObj.connectorGroupId;
                    this.direction = reqObj.direction;
                    if(this.direction !== 'both') {
                        this.entryMode = this.entryMode === 'enter' ? 'exit' : 'enter';
                    }
                }
                this.editor.onToggleContinueConnectorModal(true, this.activeGroupId, this.activeType, this.pairStep);
                this.editor.toggleContinueConnectorTracking(false);

                // remove old & add new!
                this.editor.onCommand(new RemoveObject(this.editor, object, false), 'RESTRICT_UNDO');
                this.bufferPin = null;
                const data = res.data.data;
                const locationPin = new Connector(data);
                this.editor.onCommand(new AddObject(this.editor, locationPin.mesh, false, false, false), 'RESTRICT_UNDO');
                // store things to reuse in next session
            } else {
                this.editor.editor3d.callbacks.generateAlert({msg: 'Error while creating pin!', alertType: 'information'});
            }
        }).catch(e => console.log(e.response));
    }
}

export default ConnectorPinHelper;