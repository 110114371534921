import { createAsyncThunk } from "@reduxjs/toolkit";
import { setNewAlert } from "./common/alertSlice";


export const HandleExceptionWithSecuredCatch = createAsyncThunk('common/combinedCatch', async (exception, {dispatch, getState}) => {
    console.log(exception.response);
    console.log(exception.message);
    var statusCode;
    var message = exception.response ? exception.response.data.message ?? exception.response.data : "";
    if(exception.message === "Network Error") {
        statusCode = 13;
    } else {
        statusCode = exception && exception.response ? exception.response.status : 500;
    }

    console.log(statusCode);
    switch(statusCode) {

        case 13:
            dispatch(setNewAlert({msg:"Internet got disconnected, please try again later!", alertType: 'danger'}));
        break;
        case 400:
            dispatch(setNewAlert({msg:message, alertType: 'danger'}));
        break;
        case 404:
            dispatch(setNewAlert({msg:message, alertType: 'danger'}));
        break;

        /* case 401: // Token Expired
            const isSessionStored = localStorage.getItem('storeSession');
            let tokens = getState().auth.data.tokens;
            if(differenceInDays(new Date(tokens.refresh.expires), new Date()) > 0 && isSessionStored) {
                var reqObj = {refreshToken: tokens.refresh.token}
                dispatch(refreshTokens(reqObj));
            } else {
                dispatch(logout());
            }
        break; */


        default:
            dispatch(setNewAlert({msg:"Couldn't complete your request, try again later!", alertType: 'information'}));
        break;
    }

    return exception;
})