import EventEmitter from "../../utils/EventEmitter";


// import UI Components
import LogoIcon from '../../../../assets/svgs/ARWay_Light.svg';
import Chevron from '../../../../assets/svgs/scenes/chevron.svg';

import {
    UIButton,
    UIImage,
    UIPanel,
    UIText
} from '../ui.lib';

class TopbarVersionConrtol extends EventEmitter {

    constructor(editor) {
        super();
        this.editor = editor;

        this.dom = null;
        
        this.showVersionPopup = false;
        this.mapVersionHistory = [];
        this.activeVersion = 0;
        this.prevVersion = -1;
        this.activeVersionInfo = null;
        
        this.initUI();
        this.editor.on('fetchMapVersionHistoryResponse', this.updateData);
        this.editor.on('handleVersionSwitch', this.onHandleVersionSwitch);
        this.editor.on('cancelVersionSwitch', this.onCancelVersionSwitch);
        this.editor.resources.on('ready', () => this.editor.trigger('fetchMapVersionHistory'));
    }

    initUI = () => {
        this.uiContainer = new UIPanel();
        this.uiContainer.addClass('wsVersionContainer');

        const uiLogo = new UIImage(LogoIcon, '50px', '40px', null).onClick(() => this.toggleShowVersionPopup());
        const uiChevron = new UIImage(Chevron, '50px', '58px', 'More Options').onClick(() => this.toggleShowVersionPopup());

        this.uiContainer.addChild(uiLogo);
        this.uiContainer.addChild(uiChevron);

        //Versioning UI
        this.uiVerContainer = new UIPanel();
        this.uiVerContainer.addClass('wsVerPanelContainer');

        this.uiVersionHistoryBtn = new UIPanel().setClass('wsVerPanelContainer--verBtns wsSubNav');
        this.uiVersionHistoryBtn.addChild(new UIText('80%','Version History').addClass('wsVerPanelContainer--verText'));
        this.uiVerChevron = new UIImage(Chevron, '25px', '25px', '').addClass('wsVerPanelContainer--verIcon');
        this.uiVersionHistoryBtn.addChild(this.uiVerChevron);

        this.uiVerContainer.addChild(this.uiVersionHistoryBtn);

        const uiSeparator = new UIPanel().addClass('wsVerPanelContainer--verSeprator');
        this.uiVerContainer.addChild(uiSeparator);

        const uiDashboardBtn = new UIButton('Back to Dashboard', 'wsVerPanelContainer--verBackBtn', true).onClick(this.handleBackToDashboardClick);
        this.uiVerContainer.addChild(uiDashboardBtn);

        this.uiVerContainer.setDisplay('none');
        
        this.uiContainer.addChild(this.uiVerContainer);
        this.dom = this.uiContainer;

    }

    updateData = (versions) => {
        this.mapVersionHistory = [];
        this.mapVersionHistory.push({date: "Current Version", id:"CURRENT_VERSION"});
        versions instanceof Array && versions.length > 0 && this.mapVersionHistory.push(...versions);
        this.mapVersionHistory = [...new Map(this.mapVersionHistory.map((item) => [item["id"], item])).values()];
        this.updateVersionsUI();
    }

    emptyContent = () => {
        var child = this.uiVersionHistoryBtn.dom.lastChild; 
        while (child) {
            if(child === this.uiVerChevron.dom) break;
            this.uiVersionHistoryBtn.dom.removeChild(child);
            child = this.uiVersionHistoryBtn.dom.lastChild;
        }
    }

    updateVersionsUI = () => {
        this.emptyContent();
        this.uiVerContent = new UIPanel().addClass('wsSubNav--content');
        this.uiVersionHistoryBtn.addChild(this.uiVerContent);
        this.mapVersionHistory.forEach((version, index) => {
            const uiSubNavItem = new UIPanel().addClass("wsSubNav--item").addChild(new UIText("80%", version.date).addClass('wsVerPanelContainer--verText')).onClick(() => this.handleVersionSwitchPopup(version, index));
            index === 0 && uiSubNavItem.addClass("wsSubNav--item--active");
            this.uiVerContent.addChild(uiSubNavItem);
        })
    }

    handleVersionSwitchPopup = (version, index) => {
        /* if(this.activeVersion === index) return; */
        this.toggleShowVersionPopup();
        this.prevVersion = this.activeVersion;
        if(version.id !== 'CURRENT_VERSION') {
            this.activeVersion = index;
            this.activeVersionInfo = version;
            this.editor.onToggleMapSwitchModal(true);
        }
    }

    handleBackToDashboardClick = () => {
        this.toggleShowVersionPopup();
        this.editor.onToggleInfoModals("ToDashboard", true);
    }

    onHandleVersionSwitch = () => {
        this.editor.trigger('loadMapVersionFromHistory', [this.activeVersionInfo]);
        this.toggleShowVersionPopup();
    }

    onCancelVersionSwitch = () => {
        this.activeVersion = this.prevVersion;
        this.activeVersionInfo = null;
    }

    checkParent(parent, child) {
        let node = child.parentNode;
        // keep iterating unless null
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    handleClick = (e) => { !this.checkParent(this.uiContainer.dom, e.target) && this.toggleShowVersionPopup() }

    toggleShowVersionPopup = () => {
        this.showVersionPopup = !this.showVersionPopup;
        if(this.showVersionPopup) {
            this.editor.trigger('fetchMapVersionHistory');
            this.uiVerContainer.setDisplay('block');
            window.addEventListener('click', this.handleClick, true);
        } else {
            this.uiVerContainer.setDisplay('none');
            window.removeEventListener('click', this.handleClick, true);
        }
    }
}

export { TopbarVersionConrtol }