import EditorExperience from "../../2DEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";
// import * as THREE from 'three';

//icons
import HelpIcon from '../../../../../assets/icons/sidebar/help.svg';


import { 
    UIPanel,
    UIText,
    UIObjectInfoCard,
    UIRow,
    UIImage,
    UISearchbar
} from "../../../ui/ui.lib";

import {
    getObjectByUserDataProperty
} from '../../../threeUtils/TransformConversions';

import { SetVisible } from '../../../commands/SetVisible';

class SidebarContentConnectorPins extends EventEmitter{
    
    constructor(props) {
        super(props);

        this.editor = new EditorExperience();
        this.dom = null;
        this.sortedPins = [];
        this.searchText = "";
        this.serachedPins = [];

        this.initUI();

        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('navigationTracking', this.onNavigationTracking);

    }

    initUI = () => {

        this.uiMyContent = new UIPanel();
        
        const pinText = new UIRow()
        pinText.addChild(new UIText(undefined, 'Connector Pins'));
        pinText.addChild(new UIImage(HelpIcon, '25px', '25px', 'help').setStyle('marginLeft', '10px').onClick(() => this.editor.onToggleInfoModals("ConnectorOnboarding", true)));

        this.uiSearchBar = new UISearchbar('Search Connector Pins').onChange(() => this.handlePinSearch());

        this.uiMyContent.addChild(pinText);
        this.uiMyContent.addChild(this.uiSearchBar);

        this.dom = this.uiMyContent;
    }

    emptyContent = () => {
        var child = this.uiMyContent.dom.lastChild; 
        while (child) {
            if(child === this.uiSearchBar.dom) break;
            this.uiMyContent.dom.removeChild(child);
            child = this.uiMyContent.dom.lastChild;
        }
    }

    sortConnectorPins = (pins) => {
        return pins.filter( pin => pin.pinType === 'connector');
    }

    updateUiData = () => {
        this.emptyContent();
        if(!this.sortedPins.length) {
            const uiNoDataCard = new UIPanel();
            uiNoDataCard.addClass('ws_uiNoDataCard');
            const uiSubText = document.createElement('div')
            uiSubText.classList.add('ws_uiNDSubText');
            uiSubText.textContent = 'You haven’t added a connector yet.';
            uiNoDataCard.dom.appendChild(uiSubText);
            this.uiMyContent.addChild(uiNoDataCard);
        }else if(this.searchText.length){
            if(this.serachedPins.length) {
                const uiCardsRow = new UIPanel();
                uiCardsRow.addClass('ws_uiCardsRowFloat');
                this.serachedPins.forEach((route, index) => {
                    const uiImageCont = new UIObjectInfoCard(this.editor, route, getObjectByUserDataProperty(this.editor.scene, 'id', route.id), this.onLocationPinSelect, "CONNECTOR", index);
                    uiCardsRow.addChild(uiImageCont)
                })
                this.uiMyContent.addChild(uiCardsRow);
            } else {
                const uiNoDataCard = new UIPanel();
                uiNoDataCard.addClass('ws_uiNoDataCard');
                const uiSubText = document.createElement('div')
                uiSubText.classList.add('ws_uiNDSubText');
                uiSubText.textContent = `Matching pins not found!`;
                uiNoDataCard.dom.appendChild(uiSubText);
                this.uiMyContent.addChild(uiNoDataCard);
            }
        } else {
            const uiCardsRow = new UIPanel();
            uiCardsRow.addClass('ws_uiCardsRowFloat');
            this.sortedPins.forEach((route, index) => {
                const uiImageCont = new UIObjectInfoCard(this.editor, route, getObjectByUserDataProperty(this.editor.scene, 'id', route.id), this.onLocationPinSelect, "CONNECTOR", index);
                uiCardsRow.addChild(uiImageCont)
            })
            this.uiMyContent.addChild(uiCardsRow);
        }
    }

    onLocationPinSelect = (content, eventType) => {
        const object = getObjectByUserDataProperty(this.editor.scene, 'id', content.id);
        if(object) {
            if(eventType === 2) {
                this.editor.onCommand(new SetVisible(this.editor, object, !object.visible, false, false));
                object === this.editor.selectedObject && this.editor.deselect();
            } else {
                if(!object.visible) {
                    this.editor.onCommand(new SetVisible(this.editor, object, !object.visible, false, false));
                }
                this.editor.select(object);
            }
        }
    }

    handlePinSearch = () => {
        this.searchText = this.uiSearchBar.getValue();
        this.serachedPins = this.sortedPins.filter(pin => pin.name.toLowerCase().includes(this.searchText.toLowerCase()))
        this.updateUiData();
    }

    refreshUI = () => {
        this.sortedPins = this.sortConnectorPins(this.editor.jsonLocationPins);
        this.updateUiData();
    }

    onToggleMenuActive = () => {}

    onNavigationTracking = (bFlag) => {
        if(!bFlag) {
            this.refreshUI();
        }
    }

    unload = () => {
        this.emptyContent();
    }
}

export { SidebarContentConnectorPins };