import * as THREE from "three";
import EditorExperience from "../../2DEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";
import {
    fromPos2dObjectToVec3Pos,
    fromPosObjectToVec3Pos,
    getTransformed2DPos,
    getTransformed2DPosV1,
    getTransformed3DPos,
    getTransformed3DPosV1,
} from "../../../threeUtils/TransformConversions";

class PathNode extends EventEmitter {
    constructor(nodeData) {
        super();
        this.editor = new EditorExperience();

        this.id = nodeData.id;
        this.position2d = fromPos2dObjectToVec3Pos(nodeData.position2d);
        this.position = fromPosObjectToVec3Pos(nodeData.position);
        this.nodeState = nodeData.nodeState || "existing";
        this.pathStroke = nodeData.pathStroke || 4;

        this.adjacents = new Set();
        this.edgesDrawn = new Map();

        this.nodeMesh = null;

        this.addPosZOffset(2);
        this.createNodeMesh();

        // listeners!
        this.editor.on("objectChanged", this.onObjectChanged);
    }

    updateNode = (nodeData, updateMesh = false) => {
        this.position2d = fromPos2dObjectToVec3Pos(nodeData.position2d);
        this.position = fromPosObjectToVec3Pos(nodeData.position);

        if(updateMesh) {
            this.nodeMesh.position.copy(this.position2d);
        }
    };

    addPosZOffset = (offset) => {
        this.position2d.z += offset;
    };

    addAdjacent(node) {
        this.adjacents.add(node);
    }

    removeAdjacent(node) {
        this.edgesDrawn.delete(node.id);
        return this.adjacents.delete(node);
    }

    isAdjacent(node) {
        return this.adjacents.has(node);
    }

    getAdjacents() {
        return Array.from(this.adjacents);
    }

    setNodeState = (state) => (this.nodeState = state);

    //3js functions
    createNodeMesh = () => {
        // const size = 10 + (10 * this.editor.floorData.SCALE_SIZE);
        const size = this.editor.floorData.MIN_SIZE * 0.006;
        const cylinGeo = new THREE.CylinderGeometry(size, size, 0.5, 32, 32);
        const tex = new THREE.TextureLoader().load(
            "/static/textures/placed_node.png"
        );
        tex.colorSpace = THREE.SRGBColorSpace;
        const cylinMat = new THREE.MeshBasicMaterial({
            map: tex,
            side: THREE.FrontSide,
            depthTest: false,
            depthWrite: false,
        });
        this.nodeMesh = new THREE.Mesh(cylinGeo, cylinMat);
        this.nodeMesh.rotateX(THREE.MathUtils.degToRad(90));
        this.nodeMesh.position.copy(this.position2d);
        this.nodeMesh.renderOrder = 10000;
        this.nodeMesh.userData["id"] = this.id;
        this.nodeMesh.userData["nodeMesh"] = true;
        this.nodeMesh.userData["pathStroke"] = this.pathStroke;
    };

    // listeners
    onObjectChanged = (object) => {
        if (object === this.nodeMesh) {
            if (this.position2d.distanceTo(object.position) !== 0) {
                this.position2d = object.position.clone();
                this.computeTransformed3D();
                this.nodeState = "updated";
                this.moveEdgesBetnAdjacents();
                this.editor.trigger("editor2DNodePosUpdated", [this.id]);
            }
        }
    };

    computeTransformed3D = () => {
        const { worldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        const pos = {
            posX: this.position2d.x,
            posY: Math.abs(this.position2d.y),
        };
        const pos2d =
            this.editor.floorplanVersion !== 2.6
                ? getTransformed3DPos(worldMatrix, imgWidth, imgHeight, pos)
                : getTransformed3DPosV1(worldMatrix, imgWidth, imgHeight, pos);
        this.position = pos2d.clone();
    };

    computeTransformed2D = () => {
        const { invWorldMatrix, imgWidth, imgHeight } = this.editor.floorData;
        const pos2d =
            this.editor.floorplanVersion !== 2.6
                ? getTransformed2DPos(
                      invWorldMatrix,
                      imgWidth,
                      imgHeight,
                      this.position
                  )
                : getTransformed2DPosV1(
                      invWorldMatrix,
                      imgWidth,
                      imgHeight,
                      this.position
                  );

        this.position2d = pos2d.clone();
        this.addPosZOffset(2);
        this.nodeMesh.position.copy(pos2d);
        this.moveEdgesBetnAdjacents();
    };

    updatePathStroke = (newStroke) => {
        this.pathStroke = newStroke;
        this.nodeMesh.userData.pathStroke = newStroke;
        this.adjacents.forEach((adNode) => {
            if (this.edgesDrawn.has(adNode.id)) {
                const edge = this.edgesDrawn.get(adNode.id);
                edge.updateLineStroke(newStroke);
            }
        });
    };

    moveEdgesBetnAdjacents = () => {
        this.adjacents.forEach((adNode) => {
            if (this.edgesDrawn.has(adNode.id)) {
                const edge = this.edgesDrawn.get(adNode.id);
                edge.updateGeometryVertices([
                    this.position2d.clone(),
                    adNode.position2d.clone(),
                ]);
            }
        });
    };
}

export { PathNode };
