import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { 
    Backdrop, Box, 
    Modal, Fade,
    Tabs, Tab,
    Table, TableContainer,
    TableCell, TableBody,
    TableHead, TableRow,
    Menu, MenuItem,
} from "@mui/material";
import { 
    XCircle, X, Search, PlusCircle, MoreVertical
} from "react-feather";

// actions
import {
    getConnectorGroups
} from '../../../../../features/webstudio/buildingSlice';

import ConnectorModal from './CreateGroupModal';
import DeleteGroupModal from "./DeleteGroupModal";
import EditGroupModal from "./EditGroupModal";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    height: 700,
    transform: "translate(-50%, -50%)",
    width: 900,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    border: "none !important",
    outline: "none !important",
    display: "flex",
    flexDirection: "column",
    alignItems: "center !important",
    borderRadius: "16px",
    overflowY: 'auto',
};

const ConnectorManagerModal = ({ openModal, onCloseModal, modalData }) => {
    const dispatch = useDispatch();

    const [ activeKey, setActiveKey ] = useState(0);
    const [ searchText, setSearchText ] = useState('');
    const [ groupList, setGroupList ] = useState([]);
    const [ searchedGroupList, setSearchedGroupList ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    const [ anchorEles, setAnchorEles ] = useState([]);

    const [ openCreate, setOpenCreate ] = useState(false);
    const [ openDelete, setOpenDelete ] = useState(false);
    const [ openEdit, setOpenEdit ] = useState(false);

    const [ editContent, setEditContent ] = useState(null);


    const groupContent = useSelector(state => state.building.connectorGroups);
    const buildingLog = useSelector(state => state.building.buildingLog);

    useEffect(() => {
        if(openModal && modalData && groupList.length === 0) {
            setLoading(true);
            dispatch(getConnectorGroups({buildingId: modalData.buildingId}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ modalData ]);

    useEffect(() => {
        if(groupContent) {
            setLoading(false);
            setGroupList(groupContent)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ groupContent ]);

    useEffect(() => {
        if(buildingLog && buildingLog === 'success') {
            setLoading(true);
            dispatch(getConnectorGroups({buildingId: modalData.buildingId}))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [buildingLog])

    const handleClose = (e, reason) => {
        // if(reason && reason === 'backdropClick') return;
        setGroupList([])
        setLoading(false);
        onCloseModal()
    }

    const handleEditConnector = (data, index) => {
        setEditContent({group: data, buildingId: modalData?.buildingId});
        setOpenEdit(true);
        handleMenuClose(index, null);
        handleClearSearch();
    }

    const handleDeleteConnector = (data, index) => {
        setEditContent({group: data, buildingId: modalData?.buildingId});
        setOpenDelete(true);
        handleMenuClose(index, null);
        handleClearSearch();
    }

    const handleMenuClick = (idx, e) => {
        setAnchorEles(oldEles => {
            oldEles[idx] = e.target;
            return [...oldEles];
        })
    }

    const handleMenuClose = (idx, e) => {
        setAnchorEles(oldEles => {
            oldEles[idx] = null;
            return [...oldEles];
        })
    }

    const handleChange = (e, key) => {
        setActiveKey(key);
    }

    const handleSearchChange = (e) => {
        if (e.target.value.includes(" ") && e.target.value.length === 1) {
            e.target.value = e.target.value.replace(/\s/g, "");
        }
    
        const searchText = e.target.value;
        setSearchText(searchText);
        if (searchText.trim().length ) {
            // search
            handleSearchNow(searchText);
        } else if (searchText.length <= 0) {
            handleClearSearch();
        }
    }

    const handleClearSearch = () => {
        setSearchText('');
        setSearchedGroupList([]);
    }

    const handleSearchNow = (text) => {
        if(groupList.length) {
            setSearchedGroupList(groupList.filter(g => g.name.toLowerCase().includes(text.toLowerCase())));
        }
    }

    const getConnectorsText = (pins, type) => {
        if(pins && pins.length) {
            return pins.map(p => `${type} - ${p}`).join(', ')
        } else return "--"
    }

    const a11yProps = (index) => {
        return {
            id: `mymaps-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    }

    var crossClass = "searchbar__closeIcon--hide";
    if (searchText.length > 0) {
        crossClass = "";
    }

    const renderCells = (groupArray) => {
        return (
            <TableBody>
                {groupArray.map((row, index) => (
                    <TableRow
                        key={index}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        hover
                    >
                        <TableCell component="th" scope="row" className="table-cell">{row.name}</TableCell>
                        <TableCell align="left" className="table-cell text-cap">{row.connectorType}</TableCell>
                        <TableCell align="left" className="table-cell text-cap">{getConnectorsText(row.pins, row.connectorType)}</TableCell>
                        <TableCell align="left">
                            <MoreVertical
                                size={20}
                                color="#353E5A"
                                className="cmModal--tableCont--moreIcon"
                                onClick={e => handleMenuClick(index, e)}
                            />
                            <Menu
                                id={index}
                                anchorEl={anchorEles[index]}
                                keepMounted
                                open={Boolean(anchorEles[index])}
                                onClose={e => handleMenuClose(index, e)}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                            >
                                <MenuItem className="table-cell" onClick={e => handleEditConnector(row, index)}>Edit Group Details</MenuItem>
                                <MenuItem className="table-cell" onClick={e => handleDeleteConnector(row, index)}>Delete Group</MenuItem>
                            </Menu>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        )
    }


    const renderTable = () => {
        if(loading) {
            return (
                <tbody className='cmModal--tableCont--noDataCont'>
                    <div><span>Fetching Connector Groups!</span></div> 
                </tbody>
            )
        } else if(searchText.length === 0 && groupList.length === 0 ) {
            return (
                <tbody className='cmModal--tableCont--noDataCont'>
                    <span>You haven’t grouped any connector pins or pairs together yet!</span>
                    <div onClick={() => setOpenCreate(true)} className='cmModal--tableCont--noDataCont--div'>+ Create a Connector Group</div>
                </tbody>
            )
        } else if(searchText.length && searchedGroupList.length === 0) {
            return (
                <tbody className='cmModal--tableCont--noDataCont'>
                    <div><span>No Match Found for "{searchText}"</span></div> 
                </tbody>
            )
        } else if(searchText.length && searchedGroupList.length) {
            return renderCells(searchedGroupList);
        } 
        else {
            return renderCells(groupList);
        }
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle
                            color="#353E5A"
                            strokeWidth="1.5"
                            className="mapdetails__mapModalCloseIcon"
                            onClick={handleClose}
                        />
                        <div className='cmModal'>
                            <div className='cmModal--cgCard'>
                                <h4 className='cmModal--cgCard--title'>Connector Manager</h4>
                                <div className='cmModal--cgCard--header'>Use the Connector Manager to view and adjust your groups and pairs.</div>
                                <div className='cmModal--mainRow'>
                                    <Box sx={{ borderBottom: 1, borderColor: "divider", marginBottom: '10px'}}>
                                        <Tabs
                                            value={activeKey}
                                            onChange={handleChange}
                                            aria-label="Connector Tabs"
                                        >
                                            <Tab
                                                label="Connector Groups"
                                                {...a11yProps(0)}
                                                disableRipple
                                                className={`studiohome__shTabHeader ${
                                                    activeKey === 0 ? "studiohome__shTabHeader--active" : ""
                                                }`}
                                            />
                                            {/* <Tab
                                                label="Pairs"
                                                {...a11yProps(1)}
                                                disableRipple
                                                className={`studiohome__shTabHeader ${
                                                    activeKey === 1 ? "studiohome__shTabHeader--active" : ""
                                                }`}
                                            /> */}
                                        </Tabs>
                                    </Box>
                                    <Box>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <div className="searchbar" style={{width:'75%'}}>
                                                <span className="searchbar__searchbarContainer" style={{width: "60%"}}>
                                                    <Search
                                                        size={20}
                                                        color="#353E5A"
                                                        className="searchbar__searchIcon"
                                                    />
                                                    <input
                                                        type="text"
                                                        autoComplete="off"
                                                        placeholder="Search with Name"
                                                        value={searchText}
                                                        onChange={handleSearchChange}
                                                        id="input-mapsSearch"
                                                    />
                                                    <X
                                                        size={20}
                                                        color="#353E5A"
                                                        onClick={handleClearSearch}
                                                        id="icon-clearSearch"
                                                        className={`searchbar__closeIcon ${crossClass}`}
                                                    />
                                                </span>
                                            </div>
                                            <div className='cmModal--newGrpCont' onClick={() => setOpenCreate(true)}>
                                                <PlusCircle
                                                    size={18}
                                                    color="#2A4DC2"
                                                    className="searchbar__searchIcon"
                                                />
                                                <span>Create a New Group</span>
                                            </div>
                                        </div>
                                        <div className='cmModal--tableCont'>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
                                                    <TableHead>
                                                        <TableRow className="table-row">
                                                            <TableCell className="table-head">Group</TableCell>
                                                            <TableCell align="left" className="table-head">Type</TableCell>
                                                            <TableCell align="left" className="table-head">Connector Pins</TableCell>
                                                            <TableCell align="left" className="table-head"></TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    {renderTable()}
                                                </Table>
                                                </TableContainer>
                                        </div>
                                    </Box>
                                </div>
                            </div>
                            <ConnectorModal 
                                openModal={openCreate}
                                modalData={modalData}
                                onCloseModal={() => setOpenCreate(false)}
                            />
                            <DeleteGroupModal
                                openModal={openDelete}
                                modalData={editContent}
                                onCloseModal={() => {
                                    setOpenDelete(false)
                                    setEditContent(null)
                                }}
                            />
                            <EditGroupModal
                                openModal={openEdit}
                                modalData={editContent}
                                onCloseModal={() => {
                                    setOpenEdit(false)
                                    setEditContent(null)
                                }}
                            />
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
};

export default ConnectorManagerModal;
