import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DatePicker from "../../../_utils/DatePicker";
import { Box } from "@mui/material";

import { Container } from "@mui/material";
import { ChevronLeft, Users } from "react-feather";

import {
  getSDKAccountAnalytics,
  clearSearchedUsers,
} from "../../../../features/admin/analytics/adminSDKAnalyticsSlice";
import { lastTEDays } from "../../../_utils/DateUtils";
import AppsTable from "./AppsTable";
import { isEqual } from "lodash";

export default function UserAnalyticsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [dateObj, setDateObj] = useState(lastTEDays);

  const userSDKAnalytics = useSelector(
    (state) => state.adminsdkanalytics.userSDKAnalytics
  );
  const [userData, setUserData] = useState(userSDKAnalytics);
  const userInfo = location.state;

  useEffect(() => {
    dispatch(getSDKAccountAnalytics({ accID: userInfo.id, ...dateObj }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      dispatch(clearSearchedUsers());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setUserData(userSDKAnalytics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSDKAnalytics]);

  const renderInfoCard = (Icon, data, title, accent) => {
    return (
      <div
        className="aahome__mapAnalyticsContainer--infoCard"
        style={{ borderColor: accent }}
      >
        <div
          className="aahome__mapAnalyticsContainer--infoCard--iconCont"
          style={{ backgroundColor: accent }}
        >
          <Icon width="18px" height="18px" color="#fff" strokeWidth="2.4" />
        </div>
        <div className="aahome__mapAnalyticsContainer--infoCard--infoCont">
          <div style={{ color: accent }}>{data}</div>
          <span>{title}</span>
        </div>
      </div>
    );
  };

  const renderSDKAnalytics = () => {
    return (
      <div className="aahome__mapAnalyticsContainer">
        <div className="aahome__mapAnalyticsContainer--headerRow">
          <h5>SDK Analytics</h5>
        </div>
        <div className="aahome__mapAnalyticsContainer--infoContainer">
          <div className="aahome__mapAnalyticsContainer--infoRow aahome__mapAnalyticsContainer--flexCenter">
            {renderInfoCard(
              Users,
              userData?.totalStorage
                ? isEqual("0.00", userData?.totalStorage)
                  ? "--"
                  : userData?.totalStorage + " MB"
                : "--",
              "Total Storage",
              "#B80290"
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="aahome">
      <Container maxWidth={false}>
        <div className="aahome__userAnalyticsContainer">
          <div className="aahome__userAnalyticsContainer--header">
            <ChevronLeft
              width={24}
              height={24}
              color="#353E5A"
              strokeWidth={2.5}
              onClick={() => navigate(-1)}
              className="aahome__userAnalyticsContainer--header--icon"
            />
            <span>{userInfo.name || "User"}'s Analytics</span>
            <Box
              component="span"
              className="col-4 analyticshome__anCard--filterBox"
              style={{ marginLeft: "395px" }}
            >
              <span style={{ color: "#858993" }}>Date:</span>
              <DatePicker
                cb={(obj) => {
                  setDateObj(obj);
                  dispatch(
                    getSDKAccountAnalytics({ accID: userInfo.id, ...obj })
                  );
                }}
              />
            </Box>
          </div>
          {renderSDKAnalytics()}
          {userData?.apps.length > 0 ? (
            <AppsTable apps={userData?.apps} />
          ) : null}
        </div>
      </Container>
    </div>
  );
}
