import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box } from "@mui/material";
import { isEmpty, isNull } from "lodash";

import DatePicker from "../../../../../../_utils/DatePicker";
import { lastTEDays } from "../../../../../../_utils/DateUtils";
import RenderBarChart from "../../../../../users/analytics/Render/RenderBarChart";
import Loader from '../../../../../../common/loader';

import { getUserAccountAccessPointsSummary } from "../../../../../../../features/admin/analytics/adminAnalyticsSlice";

const AccountAccessPointsCard = () => {
    const selectedAccount = useSelector((state) => state.adminanalytics.activeAccount);
    const accountAccessPointsSummary = useSelector((state) => state.adminanalytics.accountAccessPointsSummary);
    const [labels, setLabels] = useState(null);
    const [count, setCounts] = useState(null);
    const [loading, setLoading] = useState(true);

    const [dateObj, setDateObj] = useState(lastTEDays);
    const mapActObj = [
        { key: null, label: "" },
        { key: null, label: "" },
        { key: "qr", label: "QR Code" },
        { key: "iap", label: "IAP" },
        // { key: "mapCode", label: "Map Code" },
        { key: null, label: "" },
        { key: null, label: "" },
    ];

    useEffect(() => {
        if (!isEmpty(accountAccessPointsSummary)) {
            setLabels(mapActObj.map((obj) => obj["label"]));
            setCounts(
                mapActObj.map((obj) => {
                    if (obj["key"] === null) {
                        return 0;
                    } else {
                        return accountAccessPointsSummary[obj["key"]];
                    }
                })
            );
        } else {
            setLabels(null);
            setCounts(null);
        }
        setLoading(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accountAccessPointsSummary]);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getUserAccountAccessPointsSummary({ ...dateObj, accountId: selectedAccount?.id }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(selectedAccount && selectedAccount.id !== 'allAccounts') {
            setLoading(true);
            setDateObj(lastTEDays)
            dispatch(getUserAccountAccessPointsSummary({ ...lastTEDays, accountId: selectedAccount?.id }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAccount]);

    return (
        <div className="analytics">
            <div className="analyticshome__anCard">
                <div className="row analyticshome__anCard--contentRow">
                    <div className="col-9">
                        <div className="analyticshome__anCard--contentRow--header">
                            Access Points
                        </div>
                    </div>
                    <div className="col-3" style={{ paddingRight: "2.5rem" }}>
                        <Box component="span" className="analyticshome__anCard--filterBox">
                            <span style={{ color: "#858993" }}>Date:</span>
                            <DatePicker
                                cb={(dateObj) => {
                                    setDateObj(dateObj);
                                    setLoading(true);
                                    dispatch(
                                        getUserAccountAccessPointsSummary({
                                            ...dateObj,
                                            accountId: selectedAccount?.id
                                        })
                                    );
                                }}
                            />
                        </Box>
                    </div>
                </div>
                <hr />
                {((isNull(labels) || isNull(count)) && !loading) ? (
                    <h5 className="text-center"> No Data available </h5>
                ) : loading ? (
                    <div className='analyticshome__emptyTextCont'>
                        <Loader loaderText={"Fetching Data..."} height="120%" width="100%" />
                    </div>
                ) : (
                    RenderBarChart(labels, count, dateObj, "Number of times Activated")
                )}
            </div>
        </div>
    );
};

export default AccountAccessPointsCard;
