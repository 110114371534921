import * as THREE from 'three';

const fromVec3PosToPosObject = (position) => {
    return {
        posX: -position.x,
        posY: position.y,
        posZ: position.z
    }
}

const fromPosObjectToVec3Pos = (position) => {
    return new THREE.Vector3(-parseFloat(position.posX), parseFloat(position.posY), parseFloat(position.posZ));
}

const fromPosObjectToVec3PosFP = (position) => {
    return new THREE.Vector3(parseFloat(position.posX), parseFloat(position.posY), parseFloat(position.posZ));
}

const fromScaleObjectToVec3Scale = (scale) => {
    return new THREE.Vector3(parseFloat(scale.scaX), parseFloat(scale.scaY), parseFloat(scale.scaZ));
}

const fromVec3ScaleToScaleObject = (scale) => {
    return {
        scaX: scale.x,
        scaY: scale.y,
        scaZ: scale.z,
    }
}

const fromQuaternionToQuaternionObject = (rotQ) => {
    return({
        rotX: rotQ.x,
        rotY: -rotQ.y,
        rotZ: -rotQ.z,
        rotW: rotQ.w
    })
}

const fromQuaternionObjectToQuaternion = (rotation) => {
    return new THREE.Quaternion(parseFloat(rotation.rotX), -parseFloat(rotation.rotY), -parseFloat(rotation.rotZ), parseFloat(rotation.rotW));
}
const fromQuaternionObjectToQuaternionFP = (rotation) => {
    return new THREE.Quaternion(parseFloat(rotation.rotX), parseFloat(rotation.rotY), parseFloat(rotation.rotZ), parseFloat(rotation.rotW));
}

const getRandomIdCode = () => {
    return Math.floor(Math.random() * 90000) + 10000;
}

const fromPosObjToPosArray = (pos) => {
    return [-parseFloat(pos.posX), parseFloat(pos.posY), parseFloat(pos.posZ)];
}

const fromQuaternionObjectToEuler = (rotation) => {
    const quaternion = new THREE.Quaternion(parseFloat(rotation.rotX), -parseFloat(rotation.rotY), -parseFloat(rotation.rotZ), parseFloat(rotation.rotW));
    return new THREE.Euler().setFromQuaternion(quaternion, 'YXZ');
}

const fromPos2dObjectToVec3Pos = (position) => {
    return new THREE.Vector3(parseFloat(position.posX), -parseFloat(position.posY), 1);
}

const fromVec3Pos2dToPosObject = (position) => {
    return {
        posX: -position.x,
        posY: position.y,
        posZ: 1
    }
}

const getObjectByUserDataProperty = (scene, property, value) => {
    let mesh;

    scene.children.forEach((node) => {
        if (node.userData[property] === value) {
            mesh = node;
        }
    });

    return mesh;
}

// 2D & 3D conversions!
const getTransformed3DPos = (worldMatrix, imgWidth, imgHeight, pos, toObject = false) => {
    const pos2d = new THREE.Vector3(((pos.posX - (imgWidth / 2)) / imgWidth), (((imgHeight / 2) - pos.posY) / imgHeight), 0);
    pos2d.applyMatrix4(worldMatrix);
    return toObject ? fromVec3PosToPosObject(pos2d.clone()) : pos2d.clone();
}

const getTransformed2DPos = (invWorldMatrix, imgWidth, imgHeight, pos, toObject = false) => {
    const pos3d = pos.clone();
    pos3d.applyMatrix4(invWorldMatrix);
    const pos2d = {posX: Math.abs((pos3d.x * imgWidth) + imgWidth / 2), posY: Math.abs(imgHeight / 2 - (pos3d.y * imgHeight))};
    return toObject ? pos2d : fromPos2dObjectToVec3Pos(pos2d);
}
// v6 conversions!
const getTransformed3DPosV1 = (worldMatrix, imgWidth, imgHeight, pos, toObject = false) => {
    const pos2d = new THREE.Vector3((pos.posX / (imgWidth / 10)), 0, (pos.posY / (imgHeight / 10)));
    pos2d.applyMatrix4(worldMatrix);
    return toObject ? fromVec3PosToPosObject(pos2d.clone()) : pos2d.clone();
}

const getTransformed2DPosV1 = (invWorldMatrix, imgWidth, imgHeight, pos, toObject = false) => {
    const pos3d = pos.clone();
    pos3d.applyMatrix4(invWorldMatrix);
    const pos2d = {posX: Math.abs(pos3d.x * (imgWidth/10)), posY: Math.abs(pos3d.z * (imgHeight/10))};
    return toObject ? pos2d : fromPos2dObjectToVec3Pos(pos2d);
}

export {
    fromVec3PosToPosObject,
    fromScaleObjectToVec3Scale,
    fromQuaternionObjectToQuaternion,
    fromVec3ScaleToScaleObject,
    fromQuaternionToQuaternionObject,
    getRandomIdCode,
    fromPosObjectToVec3Pos,
    fromPosObjToPosArray,
    fromQuaternionObjectToEuler,
    fromPos2dObjectToVec3Pos,
    fromVec3Pos2dToPosObject,
    getObjectByUserDataProperty,
    fromQuaternionObjectToQuaternionFP,
    fromPosObjectToVec3PosFP,
    // v6
    getTransformed3DPos,
    getTransformed2DPos,
    getTransformed3DPosV1,
    getTransformed2DPosV1,
}