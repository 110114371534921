import React from "react";
import Loader from "../../../common/loader";

const ContentLoaderModel = ({ open, onClose, item }) => {
  return (
    <div className={`model-loader  ${open ? "open" : ""}`}>
      <div className="modal-preview-thumbnail">
        <div
          style={{
            marginTop: "20%",
          }}
        >
          <Loader
            loaderText="Fetching Content..."
            height="100%"
            width="100%"
            textClassName="text-white"
          />
        </div>
      </div>
    </div>
  );
};

export default ContentLoaderModel;
