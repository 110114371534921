import * as React from 'react';
import {
    Backdrop, Box, 
    Modal, Fade, 
    Button
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

import GenerateQR from '../../dashboard/users/studio/common/GenerateQR';
import ARWayImage from '../../common/CO/ARWayImage';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};


export default function AccessPointModal({openModal, onCloseModal, modalData, onSuccessModal}) {

    const generateQR = (mapCode, qrCode) => {
        return (
            <GenerateQR
                isExtId={true}
                isChecked={false}
                isAnchorQR={true}
                mapData={{
                    mapCode,
                    QRId: qrCode,
                    mapName: `Test`,
                }}
            />
        );
    }

    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal} id="button-messageClose"/>
                        <div className="mapdetails__mapModalHeader">Delete Access Point</div>
                        <div className='mapdetails__mapModalInfoDiv'>
                            {modalData?.type === "qrAnchor" ? 
                                generateQR(modalData?.mapCode, modalData?.qrId) : 
                                <ARWayImage src={modalData?.link} draggable={false} alt="ACCESS__PT" className='mapdetails__mapModalImg'/>
                            }
                        </div>
                        <div className="mapdetails__mapModalText">Delete <strong>“{modalData?.name}”</strong> from your map? Viewers will not be able to use this Access Point to access your map when deleted. Ensure you remove the physical Access Point after deleting.</div>
                        <div className="mapdetails__mapModalBtnsDiv">
                            <Button variant='outlined' color='primary' onClick={onCloseModal} id="button-calcel">Cancel</Button>
                            <Button variant='contained' color='errorRed' onClick={onSuccessModal} className='mapdetails__mapModalBtnText' id="button-success">Delete</Button>
                        </div>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}
