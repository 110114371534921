import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEqual } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import {
  getDraftMetadata,
  fetchLibraryContents,
  loadMapVersion,
  getMapVersionHistory,
  resetStudioStore,
  updateFloorplan,
  deleteFloorplan,
  resetFloorStates,
  updateAnchor,
  deleteAnchor,
  resetAnchorStates,
  updateMapArea,
  updateMapDetails,
  resetRecentLog,
} from "../../features/webstudio/webStudioSlice";
import {
  updatePath,
  deletePath,
  updatePin,
  deletePin,
  resetPinStates,
  reset2dStudioStore,
  getAmenityPinCategories,
  resetPathStates,
  getCategories,
  bulkUpdatePinsAndPaths,
  resetBulkLog,
  bulkDeletePaths,
} from "../../features/webstudio/editor2dSlice";
import {
  getVenueDetails,
  setBuildingMaps,
  resetBuilding,
  getConnectorGroups,
  updateVenueMap,
  publishVenueMap,
} from "../../features/webstudio/buildingSlice";
import { clearAlerts } from "../../features/common/alertSlice";

import { setNewAlert } from "../../features/common/alertSlice";
import { HandleExceptionWithSecuredCatch } from "../../features/CombineCatch";
import {
  getThreeDyAccessToken,
  getThreeDyModelAssets,
} from "../../features/common/commonSlice";

import EditorExperice from "./EditorExperience";
import { Sidebar } from "./ui/Sidebar/Sidebar";
import { SceneSidebar } from "./ui/SceneSidebar/SceneSidebar";
import { Menubar } from "./ui/Menubar/Menubar";
import { Topbar } from "./ui/Topbar/Topbar";
import { FloatingMenubar } from "./ui/FloatingMenubar/FloatingMenubar";

import TourModal from "./studiocommon/TourModal";
import MessageModal from "../common/modal/Modal";
import PublishModal from "./studiocommon/PublishModal";
import ContentInfoModal from "./studiocommon/ContentInfoModal";
import CustomModal from "../common/modal";
import ARWayImage from "../common/CO/ARWayImage";
import FloorplanModal from "./studiocommon/FloorplanModal";
import AccessPointModal from "./studiocommon/AccessPointsModal";
import CategoryModal from "./studiocommon/Category/CategoryModal";
import Onboarding2DModal from "./studiocommon/Onboarding2DModal";
import DeleteCategoryModal from "./studiocommon/Category/DeleteCategoryModal";
import ImportMaps from "./studiocommon/ImportMaps/ImportMaps";
import ConfirmPublishModal from "./studiocommon/ConfirmPublishModal";

//Connector Modals
import ConnectorModal from "./studiocommon/ConnectorModals/CreateConnectorGroupModal";
import ContinueConnectorModal from "./studiocommon/ConnectorModals/ContinueConnectorsModal";
import ConnectorOnboardingModal from "./studiocommon/ConnectorModals/ConnectorInfoModal";
import ElevatorOnboardingModal from "./studiocommon/ConnectorModals/ElevatorOnboardingModal";
import EscalatorOnboardingModal from "./studiocommon/ConnectorModals/EscalatorOnboardingModal";
import EscalatorDirectionModal from "./studiocommon/ConnectorModals/EscalatorDirectionModal";
import ConnectorManagerModal from "./studiocommon/ConnectorModals/ConnectorManager/ConnectorManagerModal";
import DeleteEscalatorPairModal from "./studiocommon/ConnectorModals/DeleteEscalatorPairModal";
import EscalatorPinEntryInfoModal from "./studiocommon/ConnectorModals/EscalatorPinEntryInfoModal";

//Assets
import DynamicViewIcon from "../../assets/svgs/dynamic_view.svg";
import RenameVenueMapModal from "./studiocommon/RenameMapModal";
import SampleFloorplans from "../common/sampleFloorplans";
import { setOnboardingFlag } from "./2d-editor/threeUtils/Connectors/ConnectorUtils";
import { getPlaceholderThumbnail } from "../_utils/AssignPlaceholder";

const WebStudioHome = (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const mapCodeIdArr = params.editMapId.split("-");

  let mapCode = mapCodeIdArr[0];
  let mapId = mapCodeIdArr[1];
  let buildingId = mapCodeIdArr[2] || null;

  const isFloorMap = buildingId ? false : true;

  const mapDetailsSelector = useSelector((state) => state.webstudio.mapDetails);
  const recentLog = useSelector((state) => state.webstudio.recentLog);
  const mapVersionsSelector = useSelector(
    (state) => state.webstudio.mapVersions
  );
  const libraryContentsSelector = useSelector(
    (state) => state.webstudio.libraryContents
  );
  const threeDyModelAssetsSelector = useSelector(
    (state) => state.common.threeDyAssets
  );
  const floorContentSelector = useSelector(
    (state) => state.webstudio.floorContent
  );
  const updateFloorLog = useSelector((state) => state.webstudio.updateFloorLog);
  const deleteFloorLog = useSelector((state) => state.webstudio.deleteFloorLog);
  const anchorContentSelector = useSelector(
    (state) => state.webstudio.anchorContent
  );
  const updateAnchorLog = useSelector(
    (state) => state.webstudio.updateAnchorLog
  );
  const deleteAnchorLog = useSelector(
    (state) => state.webstudio.deleteAnchorLog
  );
  const pathContentSelector = useSelector(
    (state) => state.editor2D.pathContent
  );
  const updatePathLog = useSelector((state) => state.editor2D.updatePathLog);
  const deletePathLog = useSelector((state) => state.editor2D.deletePathLog);
  const pinContentSelector = useSelector((state) => state.editor2D.pinContent);
  const updatePinLog = useSelector((state) => state.editor2D.updatePinLog);
  const deletePinLog = useSelector((state) => state.editor2D.deletePinLog);
  const amenityCategoriesSelector = useSelector(
    (state) => state.editor2D.amenityCategories
  );
  const categoriesSelector = useSelector((state) => state.editor2D.categories);
  const buildingMapsSelector = useSelector(
    (state) => state.building.buildingMaps
  );
  const venueDetailsSelector = useSelector(
    (state) => state.building.venueDetails
  );
  const bulkUpdateLog = useSelector((state) => state.editor2D.bulkUpdateLog);
  const connectorGroupsSelector = useSelector(
    (state) => state.building.connectorGroups
  );
  const bulkDeleteLog = useSelector((state) => state.editor2D.bulkDeleteLog);
  const planDetailsSelector = useSelector(
    (state) => state.settings.planDetails
  );

  const [mapDetails, setMapDetails] = useState(null);
  const [venueDetails, setVenueDetails] = useState(null);
  const [buildingMapList, setBuildingMapList] = useState(null);
  const [mapVersions, setMapVersions] = useState(null);
  const [libraryContents, setLibraryContents] = useState(null);
  const [threeDyModelAssets, setThreeDyModelAssets] = useState([]);
  const [floorContent, setFloorContent] = useState({});
  const [anchorContent, setAnchorContent] = useState({});
  const [pathContent, setPathContent] = useState({});
  const [pinContent, setPinContent] = useState({});
  // const [menuOpen, setMenuOpen] = useState(true);
  const [datafetched, setDatafetched] = useState(false);
  const [showSidebar, setShowSidebar] = useState(false);
  const [openEditor2D, setOpenEditor2D] = useState(false);
  const [show2DSidebar, setShow2DSidebar] = useState(false);
  const [amenityCategories, setAmenityCategories] = useState(null);
  const [categories, setCategories] = useState(null);
  const [planDetails, setPlanDetails] = useState(null);

  const [fetching, setFetching] = useState(false);

  const [modalHelpers, setModalHelpers] = useState({
    contentType: null,
    iapDataObj: null,
    delParams: null,
    canPublish: false,
    showTourModal: false,
    showLocationModal: false,
    showDeleteModal: false,
    showAccessModal: false,
    showToDashboardModal: false,
    showMapSwitchModal: false,
    showConfirmPublishModal: false,
    showIAPInfoModal: false,
    showDynamicViewModal: false,
    showToOnboardingModal: false,
    showConnectorOnboardingModal: false,
    showElevatorConnectorModal: false,
    showEscalatorConnectorModal: false,
    showEscalatorDirectionModal: false,
    showConnectorManagerModal: false,
    showDeleteEscalatorPairModal: false,
    showEscalatorEntryModeModal: false,
    floorModalData: null,
    categoryModalData: null,
    importMapsModalData: null,
    showFloorInfoModal: false,
    showCategoriesModal: false,
    showImportMapsModal: false,
    showConnectorGroupModal: false,
    showContinueConnectorModal: false,
    showCategoriesDeleteModal: false,
    showRenameVenueMapModal: false,
    showSampleFloorplansModal: false,
  });

  const editor = useRef(null);
  const currentContentType = useRef("");
  const uiRefs = useRef({
    sidebar: null,
    scenebar: null,
    menubar: null,
    topbar: null,
    floatingMenu: null,
  });

  const mapMeta = useRef({
    mapId: null,
    mapCode: null,
    buildingId: null
  })

  const iapImgRef = useRef(null);
  const connectorGroups = useRef(null);

  useEffect(() => {
    console.log("componentDidMount");
    mapMeta.current = {
      mapId,
      mapCode,
      buildingId
    }
    fetchMapMetadata(buildingId);
    initiateEditor();

    if (buildingId) {
      fetchBuildingData();
      fetchConnectorGroups();
    }

    process.env.REACT_APP_NODE_ENV && !localStorage.getItem("threeDyToken") && dispatch(getThreeDyAccessToken());

    return () => {
      console.log("componentWillUnMount");
      dispatch(resetStudioStore());
      dispatch(reset2dStudioStore());
      dispatch(resetBuilding());
      editor.current?.clearAndReset();
      dispatch(resetBulkLog());
      dispatch(clearAlerts());
      delete editor.current;
      editor.current = null;
      // reset cursor classes
      resetCursor();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initiateEditor = () => {
    const CanvasElement = document.getElementById("webgl-canvas");
    editor.current = new EditorExperice(
      mapDetails,
      buildingMapList,
      mapCode,
      mapId,
      amenityCategories,
      categories,
      planDetails,
      "ARway-Map",
      CanvasElement,
      {
        loadingComplete: onEditorLoadingComplete,
        generateAlert: ({ msg, alertType }) =>
          dispatch(setNewAlert({ msg, alertType })),
        handleExceptionCatch: (e) =>
          dispatch(HandleExceptionWithSecuredCatch(e)),
        onSidebarGraphChanged: toggleShowSidebar,
        fetchLibraryContentsRequest: onFetchLibraryContent,
        fetch3DyLibraryContentRequest: onFetch3DyLibraryContent,
        onToggleInfoModals: toggleInfoModals,
        onToggleIAPModal: toggleIAPInfoModal,
        onToggleDeleteModal: toggleDeleteModal,
        onToggleFloorplanInfoModal: toggleFloorplanInfoModal,
        onToggleMapSwitchModal: toggleMapSwitchModal,
        fetchMapVersions: fetchMapVersionHistory,
        loadCurrentVersion: fetchMapMetadata,
        loadSpecifiedVersion: fetchMapVersionMetadata,
        onUpdateMapArea: onUpdateMapArea,
        onFloorplanAPICalls: floorplanAPICalls,
        onResetFloorStates: () => dispatch(resetFloorStates()),
        onAnchorAPICalls: anchorAPISCalls,
        onResetAnchorStates: () => dispatch(resetAnchorStates()),
        on2DEditorSceneGraphChanged: toggleOpenEditor2D,
        onToggleCategoryModal: toggleCategoriesModal,
        onImportMapsModal: toggleImportMapsModal,
        onPinAPICalls: pinsAPISCalls,
        onResetPinStates: () => dispatch(resetPinStates()),
        onPathAPICalls: pathsAPISCalls,
        onResetPathStates: () => dispatch(resetPathStates()),
        updateMapDetails: (mapD) => dispatch(updateMapDetails(mapD)),
        loadSwitchedMapContent: onLoadSwitchedMapContent,
        onBulkUpdatePinPathsAPIRequest: bulkUpdatePinPathsAPIRequest,
        onBulkDeletePathsAPIRequest: bulkDeletePathsAPIRequest,
        onResetBulkUpdateStates: () => dispatch(resetBulkLog()),
        onFetchConnectorGroups: fetchConnectorGroups,
        onToggleConnectorGroupModal: toggleConnectorGroupModal,
        onToggleContinueConnectorModal: toggleContinueConnectorModal,
        onToggleConnectorManagerModal: toggleConnectorManagerModal,
        onToggleRenameVenueMapModal: toggleRenameVenueMapModal,
        getExtraResources: onGetExtraResources,
        onUpdateVenueDetails: updateVenueDetails,
        onPublishVenueMap: onPublishVenueMap,
        onToggleConnectorInfoModals: toggleConnectorInfoModals,
        onToggleConfirmPublishModal: toggleConfirmPublishModal,
      }
    );

    // Load UI Components
    uiRefs.current.sidebar === null &&
      (uiRefs.current.sidebar = new Sidebar({
        dom: document.getElementById("webStudioSideBar"),
      }));
    uiRefs.current.scenebar === null &&
      (uiRefs.current.scenebar = new SceneSidebar({
        dom: document.getElementById("webStudioSceneBar"),
      }));
    uiRefs.current.menubar === null &&
      (uiRefs.current.menubar = new Menubar({
        dom: document.getElementById("webStudioMenuBar"),
      }));
    uiRefs.current.topbar === null &&
      (uiRefs.current.topbar = new Topbar({
        dom: document.getElementById("webStudioTopBar"),
      }));
    uiRefs.current.floatingMenu === null &&
      (uiRefs.current.floatingMenu = new FloatingMenubar({
        dom: document.getElementById("webStudioFloatingMenuBar"),
      }));
  };

  const onFetchLibraryContent = (pageNumber, contentType) => {
    currentContentType.current = contentType;
    setLibraryContents(null);
    dispatch(fetchLibraryContents({ pageNumber, contentType }));
  };

  const onFetch3DyLibraryContent = () => {
    dispatch(getThreeDyModelAssets());
  };

  const toggleInfoModals = (idx, bFlag = undefined, type = null) => {
    const id = `show${idx}Modal`;
    setModalHelpers((modals) => ({
      ...modals,
      [id]: bFlag ? bFlag : !modals[id],
      contentType: type,
    }));
  };

  const toggleIAPInfoModal = (bFlag = undefined, infoObj = null) => {
    setModalHelpers((modals) => ({
      ...modals,
      showIAPInfoModal: bFlag ? bFlag : !modals.showIAPInfoModal,
      iapDataObj: infoObj,
    }));
  };

  const toggleMapSwitchModal = (bFlag = undefined, canPublish = false) => {
    setModalHelpers((modals) => ({
      ...modals,
      showMapSwitchModal: bFlag ? bFlag : !modals.showMapSwitchModal,
      canPublish,
    }));
  };

  const toggleConfirmPublishModal = (bFlag = undefined, canPublish = false) => {
    setModalHelpers((modals) => ({
      ...modals,
      showConfirmPublishModal: bFlag ? bFlag : !modals.showConfirmPublishModal,
      canPublish,
    }));
  };

  const toggleDeleteModal = (bFlag = undefined, delParams = null) => {
    setModalHelpers((modals) => ({
      ...modals,
      showDeleteModal: bFlag ? bFlag : !modals.showDeleteModal,
      delParams,
    }));
  };

  const toggleFloorplanInfoModal = (
    bFlag = undefined,
    floorModalData = null
  ) => {
    setModalHelpers((modals) => ({
      ...modals,
      showFloorInfoModal: bFlag ? bFlag : !modals.showFloorInfoModal,
      floorModalData,
    }));
  };

  const toggleCategoriesModal = (
    bFlag = undefined,
    categoryModalData = null
  ) => {
    if (bFlag && categoryModalData.isDelete) {
      setModalHelpers((modals) => ({
        ...modals,
        showCategoriesDeleteModal: bFlag,
        categoryModalData: {
          mapId: mapMeta.current.mapId,
          ...categoryModalData,
          showCategoriesDeleteModal: bFlag,
        },
      }));
    } else if (
      bFlag &&
      (categoryModalData.isEdit ||
        categoryModalData.isAdd ||
        categoryModalData.isAddSub)
    ) {
      setModalHelpers((modals) => ({
        ...modals,
        showCategoriesModal: bFlag,
        categoryModalData: {
          mapId: mapMeta.current.mapId,
          ...categoryModalData,
          showCategoriesModal: bFlag,
        },
      }));
    } else {
      if (categoryModalData.isDelete) {
        setModalHelpers((modals) => ({
          ...modals,
          showCategoriesDeleteModal: !modals.showCategoriesDeleteModal,
          categoryModalData: {
            mapId: mapMeta.current.mapId,
            ...categoryModalData,
            showCategoriesDeleteModal: !modals.showCategoriesDeleteModal,
          },
        }));
      }
      if (
        categoryModalData.isEdit ||
        categoryModalData.isAdd ||
        categoryModalData.isAddSub
      ) {
        setModalHelpers((modals) => ({
          ...modals,
          showCategoriesModal: !modals.showCategoriesModal,
          categoryModalData: {
            mapId: mapMeta.current.mapId,
            ...categoryModalData,
            showCategoriesModal: !modals.showCategoriesModal,
          },
        }));
      }
    }
  };

  const toggleConnectorGroupModal = (bFlag = undefined, connectorModalData) => {
    setModalHelpers((modals) => ({
      ...modals,
      showConnectorGroupModal: bFlag ? bFlag : !modals.showConnectorGroupModal,
      connectorModalData: bFlag ? { ...connectorModalData, buildingId } : null,
    }));
  };

  const toggleContinueConnectorModal = (
    bFlag = undefined,
    connectorModalData
  ) => {
    setModalHelpers((modals) => ({
      ...modals,
      showContinueConnectorModal: bFlag
        ? bFlag
        : !modals.showContinueConnectorModal,
      connectorModalData: bFlag ? { ...connectorModalData, buildingId } : null,
    }));
  };

  const toggleConnectorManagerModal = (
    bFlag = undefined,
    connectorModalData
  ) => {
    setModalHelpers((modals) => ({
      ...modals,
      showConnectorManagerModal: bFlag
        ? bFlag
        : !modals.showConnectorManagerModal,
      connectorModalData: bFlag ? { ...connectorModalData, buildingId } : null,
    }));
  };

  const toggleRenameVenueMapModal = (bFlag = undefined, connectorModalData) => {
    setModalHelpers((modals) => ({
      ...modals,
      showRenameVenueMapModal:
        bFlag !== undefined ? bFlag : !modals.showRenameVenueMapModal,
      connectorModalData:
        bFlag !== undefined
          ? {
              ...connectorModalData,
              buildingId,
              venueDetails,
            }
          : null,
    }));
  };

  const toggleConnectorInfoModals = (
    type,
    bFlag = undefined,
    connectorModalData
  ) => {
    const modalType = `show${type}Modal`;

    setModalHelpers((modals) => ({
      ...modals,
      [modalType]: bFlag !== undefined ? bFlag : !modals[modalType],
      connectorModalData: bFlag !== undefined ? connectorModalData : null,
    }));
  };

  const onSuccessContinueConnector = (connectorModalData) => {
    editor.current.onSuccessContinueConnector(connectorModalData);
    toggleContinueConnectorModal();
  };

  const toggleImportMapsModal = (
    bFlag = undefined,
    importMapsModalData = null
  ) => {
    setModalHelpers((modals) => ({
      ...modals,
      showImportMapsModal: bFlag ? bFlag : !modals.showImportMapsModal,
      importMapsModalData,
    }));
  };

  const onEscalatorDirectionSuccess = (type) => {
    editor.current.onEscalatorDirectionSuccess(type);
    toggleInfoModals("EscalatorDirection");
  };

  const handleDelete = () => {
    //Trigger Editor Function
    editor.current.onDeleteSelectedAssetSuccess();
    toggleDeleteModal();
  };

  const handleFloorSuccess = (data) => {
    //Trigger Editor Function
    editor.current.onFloorModalSuccess(data);
    toggleFloorplanInfoModal();
  };

  const handlePublishSwitch = (state) => {
    editor.current.onSwitchMapVersionSuccess(state);
    toggleMapSwitchModal();
  };

  const handlePublish = (state) => {
    editor.current.onPublishSuccess(state);
    toggleConfirmPublishModal();
  };

  const handleGoToDashboard = () => {
    toggleInfoModals("ToDashboard");
    navigate("..");
  };

  const cancelPublishSwitch = () => {
    editor.current.onCancelVersionSwitch();
    toggleMapSwitchModal();
  };

  const onEditorLoadingComplete = () => setFetching(false);

  const toggleShowSidebar = (id, flag) => {
    if (id === "show2DSidebar") {
      setShow2DSidebar(flag);
    } else {
      setShowSidebar(flag);
    }
  };

  const toggleOpenEditor2D = (flag) => {
    setOpenEditor2D(flag);
  };

  // useEffect
  useEffect(() => {
    if (
      recentLog &&
      mapDetailsSelector &&
      !isEqual(mapDetails, mapDetailsSelector)
    ) {
      !buildingId &&
        !datafetched &&
        mapDetailsSelector &&
        setMapAsBuildingMap(mapDetailsSelector);
      editor.current !== null &&
        editor.current.initResourceLoading(mapDetailsSelector, isFloorMap);

      dispatch(resetRecentLog());
      setMapDetails(mapDetailsSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapDetailsSelector, recentLog]);

  useEffect(() => {
    if (venueDetailsSelector && !isEqual(venueDetails, venueDetailsSelector)) {
      dispatch(setBuildingMaps(venueDetailsSelector.maps));

      editor.current !== null &&
        editor.current.setBuildingMaps(
          venueDetailsSelector.maps,
          venueDetailsSelector
        );

      setVenueDetails(venueDetailsSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueDetailsSelector]);

  useEffect(() => {
    if (
      buildingMapsSelector &&
      !isEqual(buildingMapList, buildingMapsSelector)
    ) {
      editor.current !== null &&
        editor.current.setBuildingMaps(buildingMapsSelector, venueDetailsSelector);

      setBuildingMapList(buildingMapsSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildingMapsSelector]);

  useEffect(() => {
    if (categoriesSelector && !isEqual(categories, categoriesSelector)) {
      editor.current.onFetchCategoriesResponse(categoriesSelector);
      setCategories(categoriesSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoriesSelector]);

  useEffect(() => {
    if (
      amenityCategoriesSelector &&
      !isEqual(amenityCategories, amenityCategoriesSelector)
    ) {
      editor.current.onFetchAmenityCategoriesResponse(
        amenityCategoriesSelector
      );
      setAmenityCategories(amenityCategoriesSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amenityCategoriesSelector]);

  useEffect(() => {
    if (planDetailsSelector && !isEqual(planDetails, planDetailsSelector)) {
      editor.current.onplanDetailsFetched(planDetailsSelector);
      setPlanDetails(planDetailsSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planDetailsSelector]);

  useEffect(() => {
    if (
      libraryContentsSelector &&
      !isEqual(libraryContents, libraryContentsSelector)
    ) {
      let contentType =
        libraryContentsSelector && libraryContentsSelector.length
          ? libraryContentsSelector[0].contentType
          : currentContentType.current;
      editor.current !== null &&
        editor.current.onFetchLibraryContentsResponse(
          libraryContentsSelector,
          contentType
        );

      setLibraryContents(libraryContentsSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libraryContentsSelector]);

  useEffect(() => {
    if (mapVersionsSelector && !isEqual(mapVersions, mapVersionsSelector)) {
      editor.current !== null &&
        editor.current.onFetchMapVersionResponse(mapVersionsSelector);
      setMapVersions(mapVersionsSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapVersionsSelector]);

  useEffect(() => {
    if (
      threeDyModelAssetsSelector &&
      !isEqual(threeDyModelAssets, threeDyModelAssetsSelector)
    ) {
      editor.current !== null &&
        editor.current.onFetch3DyLibraryContentsResponse(
          threeDyModelAssetsSelector
        );

      setThreeDyModelAssets(threeDyModelAssetsSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [threeDyModelAssetsSelector]);

  useEffect(() => {
    if (floorContentSelector && !isEqual(floorContent, floorContentSelector)) {
      const type = updateFloorLog ? "UPDATE" : deleteFloorLog ? "DELETE" : null;
      editor.current !== null &&
        editor.current.onFloorplanAPIResponse(floorContentSelector, type);

      setFloorContent(floorContentSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorContentSelector, updateFloorLog, deleteFloorLog]);

  useEffect(() => {
    if (
      anchorContentSelector &&
      !isEqual(anchorContent, anchorContentSelector)
    ) {
      const type = updateAnchorLog
        ? "UPDATE"
        : deleteAnchorLog
        ? "DELETE"
        : null;
      editor.current !== null &&
        editor.current.onAnchorAPIResponse(anchorContentSelector, type);

      setAnchorContent(anchorContentSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchorContentSelector, updateAnchorLog, deleteAnchorLog]);

  useEffect(() => {
    if (pinContentSelector && !isEqual(pinContent, pinContentSelector)) {
      const type = updatePinLog ? "UPDATE" : deletePinLog ? "DELETE" : null;
      editor.current !== null &&
        editor.current.onPinsAPIResponse(pinContentSelector, type);

      setPinContent(pinContentSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pinContentSelector, updatePinLog, deletePinLog]);

  useEffect(() => {
    if (pathContentSelector && !isEqual(pathContent, pathContentSelector)) {
      const type = updatePathLog ? "UPDATE" : deletePathLog ? "DELETE" : null;
      editor.current !== null &&
        editor.current.onPathsAPIResponse(pathContentSelector, type);

      setPathContent(pathContentSelector);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathContentSelector, updatePathLog, deletePathLog]);

  useEffect(() => {
    if (bulkUpdateLog && bulkUpdateLog === "success") {
      editor.current !== null &&
        editor.current.onBulkUpdateAPIResponse(bulkUpdateLog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkUpdateLog]);

  useEffect(() => {
    if (
      connectorGroupsSelector &&
      !isEqual(connectorGroups.current, connectorGroupsSelector)
    ) {
      editor.current !== null &&
        editor.current.onFetchConnectorGroupsResponse(connectorGroupsSelector);

      connectorGroups.current = connectorGroupsSelector;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectorGroupsSelector]);

  useEffect(() => {
    if (bulkDeleteLog && bulkDeleteLog === "success") {
      editor.current !== null &&
        editor.current.onPathBulkDeleteAPIResponse(bulkDeleteLog);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkUpdateLog]);

  const fetchMapMetadata = (buildingId) => {
    setFetching(true);
    setMapDetails(null);
    dispatch(getDraftMetadata(mapMeta.current.mapId));
    dispatch(getAmenityPinCategories());
    dispatch(getCategories(mapMeta.current.mapId));
  };

  const fetchBuildingData = () => {
    setVenueDetails(null);
    dispatch(getVenueDetails(buildingId));
  };

  const fetchMapVersionHistory = () => {
    dispatch(getMapVersionHistory(mapMeta.current.mapId));
  };

  const fetchMapVersionMetadata = (draftId) => {
    dispatch(loadMapVersion({ mapId: mapMeta.current.mapId, reqObj: { draftId } }));
    setFetching(true);
  };

  const fetchConnectorGroups = () => {
    dispatch(getConnectorGroups({ buildingId }));
  };

  const setMapAsBuildingMap = (mapDetails) => {
    if (mapDetails?.metadata) {
      setDatafetched(true);
      const { mapId, mapName, mapCode, anchorIds } = mapDetails.metadata;
      dispatch(setBuildingMaps([{ mapId, mapName, mapCode, anchorIds }]));
    }
  };

  const onLoadSwitchedMapContent = (mapData) => {
    setFetching(true);
    mapId = mapData.mapId;
    mapCode = mapData.mapCode;
    mapMeta.current.mapId = mapId;
    mapMeta.current.mapCode = mapCode;
    fetchConnectorGroups();
    dispatch(getDraftMetadata(mapData.mapId));
    dispatch(getCategories(mapData.mapId));
  };

  const onGetExtraResources = () => {
    return {
      groups: connectorGroups.current || connectorGroupsSelector,
      amenityCategories: amenityCategories || amenityCategoriesSelector,
    };
  };

  const floorplanAPICalls = (object, opType) => {
    if (opType === "UPDATE")
      dispatch(updateFloorplan({ mapId: mapMeta.current.mapId, floorObj: object }));
    else if (opType === "DELETE")
      dispatch(deleteFloorplan({ mapId: mapMeta.current.mapId, floorId: object }));
  };

  const anchorAPISCalls = (object, opType) => {
    if (opType === "UPDATE")
      dispatch(updateAnchor({ mapId: mapMeta.current.mapId, anchorObj: object }));
    else if (opType === "DELETE")
      dispatch(deleteAnchor({ mapId: mapMeta.current.mapId, anchorId: object }));
  };

  const pinsAPISCalls = (object, opType) => {
    if (opType === "UPDATE") dispatch(updatePin({ mapId: mapMeta.current.mapId, pinObj: object }));
    else if (opType === "DELETE") dispatch(deletePin({ mapId: mapMeta.current.mapId, pinId: object }));
  };

  const pathsAPISCalls = (object, opType) => {
    if (opType === "UPDATE") dispatch(updatePath({ mapId: mapMeta.current.mapId, pathObj: object }));
    else if (opType === "DELETE")
      dispatch(deletePath({ mapId: mapMeta.current.mapId, pathId: object }));
  };

  const onUpdateMapArea = (areaObj) => {
    dispatch(updateMapArea({ mapId: mapMeta.current.mapId, areaObj }));
  };

  const bulkUpdatePinPathsAPIRequest = (reqObj) => {
    dispatch(bulkUpdatePinsAndPaths({ mapId: mapMeta.current.mapId, reqObj }));
  };

  const bulkDeletePathsAPIRequest = (reqObj) => {
    dispatch(bulkDeletePaths({ mapId: mapMeta.current.mapId, reqObj, deleteAll: false }));
  };

  const updateVenueDetails = (venueDetails) => {
    dispatch(
      updateVenueMap({ mapId: buildingId, venueDetails, noAlert: true })
    );
  };

  const onPublishVenueMap = () => {
    dispatch(publishVenueMap({ buildingId, noAlert: false, isPublish: true }));
  };

  const resetCursor = () => {
    document.body.classList.remove("wsNew_cursor");
    document.body.classList.remove("wsAdd_cursor");
    document.body.classList.remove("wsRemove_cursor");
    document.body.classList.remove("wsGrab_cursor");
  };

  return (
    <React.Fragment>
      <div
        className="webstudio__loaderContainer"
        style={fetching ? { display: "flex" } : { display: "none" }}
      >
        <div className="webstudio__loaderIconsContainer">
          <span className="webstudio__loaderLogo"></span>
          <span className="webstudio__loaderCircle"></span>
        </div>
        <span className="webstudio__loaderText">Opening Map . . .</span>
      </div>
      <div style={fetching ? { display: "none" } : { display: "flex" }}>
        <div
          className="webstudio"
          style={fetching ? { display: "none" } : { display: "flex" }}
        >
          <div className="webstudio__wsSecondRow">
            <div className="webstudio__wsCanvasContainer">
              <canvas id="webgl-canvas"></canvas>
            </div>
          </div>
          <div id="webStudioTopBar"></div>
          <div
            className="webstudio__wsSidebarContainer"
            id="webStudioSideBar"
            style={showSidebar ? { width: "300px" } : { width: "0px" }}
          ></div>
          <div
            className="webstudio__wsMenuContainer"
            id="webStudioMenuBar"
          ></div>
          <div
            className="webstudio__wsSceneSidebarContainer"
            id="webStudioSceneBar"
          ></div>
          <div
            className="webstudio__wsFloatingMenuContainer"
            id="webStudioFloatingMenuBar"
          ></div>
          <div className="webstudio__viewHelper" id="webStuioViewHelper"></div>
        </div>
        <div
          className="editor2d"
          style={openEditor2D ? { display: "flex" } : { display: "none" }}
        >
          <div className="editor2d__wsCanvasContainer">
            <canvas id="webgl-2deditor-canvas"></canvas>
          </div>
          <div
            className="editor2d__ws2DMenuContainer"
            id="web2DEditorMenuBar"
          ></div>
          <div
            className="webstudio__ws2DSidebarContainer"
            id="web2dEditorSideBar"
            style={show2DSidebar ? { width: "320px" } : { width: "0px" }}
          ></div>
          <div
            className="webstudio__ws2dStudioBottomContainer"
            id="web2dEditorBottomBar"
            style={
              show2DSidebar
                ? { paddingRight: "320px" }
                : { paddingRight: "0px" }
            }
          ></div>
        </div>
      </div>

      <TourModal
        openModal={modalHelpers.showTourModal}
        onCloseModal={() =>  {
          setOnboardingFlag('tourOnboarding', true);
          toggleInfoModals("Tour")
        }}
      />

      <AccessPointModal
        openModal={modalHelpers.showAccessModal}
        onCloseModal={() => toggleInfoModals("Access")}
        modalData={modalHelpers.contentType}
        onSuccessModal={() => {
          editor.current.onDeleteSelectedAssetSuccess();
          toggleInfoModals("Access");
        }}
      />

      <ContentInfoModal
        openModal={modalHelpers.showLocationModal}
        onCloseModal={() => toggleInfoModals("Location")}
        type={modalHelpers.contentType}
      />

      <PublishModal
        openModal={modalHelpers.showMapSwitchModal}
        toPublish={modalHelpers.canPublish}
        onCloseModal={() => cancelPublishSwitch()}
        onSuccessModal={handlePublishSwitch}
      />

      <ConfirmPublishModal
        openModal={modalHelpers.showConfirmPublishModal}
        isFloorMap={modalHelpers.canPublish}
        onCloseModal={() => toggleConfirmPublishModal()}
        onSuccessModal={handlePublish}
      />

      <MessageModal
        openModal={modalHelpers.showDeleteModal}
        onCloseModal={() => toggleDeleteModal()}
        onSuccessModal={handleDelete}
        modalHeader="Delete"
        modalText={`Are you sure you want to delete ${
          modalHelpers.delParams?.type || ""
        }`}
        modalTextBold={modalHelpers.delParams?.name}
        modalSubText={
          modalHelpers.delParams?.showSubText ? "This cannot be undone!" : null
        }
        textSuccess="Delete"
        textCancel="Cancel"
      />

      <MessageModal
        openModal={modalHelpers.showToDashboardModal}
        onCloseModal={() => toggleInfoModals("ToDashboard")}
        onSuccessModal={() => handleGoToDashboard()}
        modalHeader="Switch to Dashboard"
        modalText="Are you sure you want to switch to dashboard by exiting studio?"
        textSuccess="Switch"
        textCancel="Cancel"
      />

      <FloorplanModal
        openModal={modalHelpers.showFloorInfoModal}
        onCloseModal={() => toggleFloorplanInfoModal()}
        onSuccessModal={handleFloorSuccess}
        modalData={modalHelpers.floorModalData}
      />

      <Onboarding2DModal
        openModal={modalHelpers.showToOnboardingModal}
        onCloseModal={() => toggleInfoModals("ToOnboarding")}
      />

      <ConnectorOnboardingModal
        openModal={modalHelpers.showConnectorOnboardingModal}
        modalData={modalHelpers.contentType}
        onCloseModal={() => toggleInfoModals("ConnectorOnboarding")}
      />

      <ElevatorOnboardingModal
        openModal={modalHelpers.showElevatorConnectorModal}
        onCloseModal={(isCancel = false) => {
          toggleInfoModals("ElevatorConnector");
          isCancel && editor.current.onCancelConnectorPlacement();
        }}
      />

      <EscalatorOnboardingModal
        openModal={modalHelpers.showEscalatorConnectorModal}
        modalData={modalHelpers.contentType}
        onCloseModal={() => {
          !modalHelpers.contentType &&
            editor.current.onToggleEscalatorDirectionModal();
          toggleInfoModals("EscalatorConnector");
        }}
      />

      <CustomModal
        openModal={modalHelpers.showIAPInfoModal}
        onCloseModal={() => toggleIAPInfoModal()}
      >
        {modalHelpers.iapDataObj ? (
          <div className="iapModal">
            <ARWayImage
              src={modalHelpers.iapDataObj.thumbnail}
              crossOrigin="anonymous"
              draggable={false}
              className="iapModal--img"
              ref={iapImgRef}
              onError={() => (iapImgRef.current.src = getPlaceholderThumbnail())}
            />
            <span className="iapModal--title">
              {modalHelpers.iapDataObj.name}
            </span>
            <span className="iapModal--info">
              {modalHelpers.iapDataObj.description}
            </span>
          </div>
        ) : null}
      </CustomModal>

      <CustomModal
        openModal={modalHelpers.showDynamicViewModal}
        onCloseModal={() => toggleInfoModals("DynamicView")}
        showClose={false}
        inlineStyle={{ width: 400 }}
      >
        <div className="dynamicViewModal">
          <ARWayImage
            src={DynamicViewIcon}
            crossOrigin="anonymous"
            draggable={false}
            className="dynamicViewModal--img"
          />
          <span className="dynamicViewModal--title">Dynamic Viewing</span>
          <span className="dynamicViewModal--info">
            Dynamic Viewing adjusts the direction of the content to always be
            facing the visitor.
          </span>
          <button
            onClick={() => this.toggleInfoModals("DynamicView")}
            className="dynamicViewModal--btn"
          >
            Close
          </button>
        </div>
      </CustomModal>

      <CategoryModal
        openModal={modalHelpers.showCategoriesModal}
        onCloseModal={() => {
          dispatch(getCategories(mapMeta.current.mapId));
          toggleCategoriesModal(undefined, { isEdit: true, isDelete: false });
        }}
        showClose={false}
        modalData={modalHelpers.categoryModalData}
      />

      <DeleteCategoryModal
        openModal={modalHelpers.showCategoriesDeleteModal}
        onCloseModal={() => {
          dispatch(getCategories(mapMeta.current.mapId));
          toggleCategoriesModal(undefined, { isDelete: true, isEdit: false });
        }}
        showClose={false}
        modalData={modalHelpers.categoryModalData}
      />

      <ImportMaps
        openModal={modalHelpers.showImportMapsModal}
        onCloseModal={() => {
          editor.current.toggleProcessingImportMaps(false);
          toggleImportMapsModal();
        }}
        showClose={false}
        modalData={modalHelpers.importMapsModalData}
      />

      <ConnectorModal
        openModal={modalHelpers.showConnectorGroupModal}
        onCloseModal={() => {
          fetchConnectorGroups();
          toggleConnectorGroupModal();
        }}
        modalData={modalHelpers.connectorModalData}
      />

      <ContinueConnectorModal
        openModal={modalHelpers.showContinueConnectorModal}
        onCloseModal={() => onSuccessContinueConnector(null)}
        onSuccess={onSuccessContinueConnector}
        modalData={modalHelpers.connectorModalData}
      />

      <EscalatorDirectionModal
        openModal={modalHelpers.showEscalatorDirectionModal}
        onSuccess={onEscalatorDirectionSuccess}
        onCloseModal={() => onEscalatorDirectionSuccess(null)}
      />

      <ConnectorManagerModal
        openModal={modalHelpers.showConnectorManagerModal}
        modalData={modalHelpers.connectorModalData}
        onCloseModal={toggleConnectorManagerModal}
      />

      <RenameVenueMapModal
        openModal={modalHelpers.showRenameVenueMapModal}
        onCloseModal={() => toggleRenameVenueMapModal(null)}
        modalData={modalHelpers.connectorModalData}
      />

      <DeleteEscalatorPairModal
        openModal={modalHelpers.showDeleteEscalatorPairModal}
        onCloseModal={() => toggleConnectorInfoModals("DeleteEscalatorPair")}
        modalData={modalHelpers.connectorModalData}
      />

      <EscalatorPinEntryInfoModal
        openModal={modalHelpers.showEscalatorEntryModeModal}
        onCloseModal={() => toggleConnectorInfoModals("EscalatorEntryMode")}
        modalData={modalHelpers.connectorModalData}
      />

      <SampleFloorplans
        openModal={modalHelpers.showSampleFloorplansModal}
        onCloseModal={() => toggleConnectorInfoModals("SampleFloorplans")}
        onSuccess={(data) => {
          editor.current.onSampleFloorplanSuccess(data);
          toggleConnectorInfoModals("SampleFloorplans");
        }}
        modalData={modalHelpers.connectorModalData}
        defaultTab={1}
      />
    </React.Fragment>
  );
};

export default WebStudioHome;
