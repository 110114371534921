import * as THREE from 'three';
import EventEmitter from '../../utils/EventEmitter';

// component
import CanvasFloorplan from '../threeUtils/CanvasFloorplan';

class Loader extends EventEmitter {
    constructor(editor) {
        super();
        this.editor = editor;

        this.loaders = {};
        this.loadManager = new THREE.LoadingManager();

        // this.initLoaders();
    }

    /* initLoaders = () => {
        this.loaders.texLoader = new THREE.TextureLoader(this.loadManager);
        this.loaders.texLoader.setCrossOrigin("anonymous");
    } */

    loadFloorplan = ({floorJson, worldMatrix, invWorldMatrix}) => {
        this.editor.floorData['worldMatrix'] = worldMatrix;
        this.editor.floorData['invWorldMatrix'] = invWorldMatrix;
        new CanvasFloorplan(floorJson);
    }
}

export { Loader };