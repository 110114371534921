import React, { useState, useEffect } from "react";
import { debounce, isEqual } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { Box, Container } from "@mui/material";

import UserTable from "./UserTable";
import Loader from "../../../common/loader";

import {
  getSDKAnalytics,
  searchUsers,
  clearSearchedUsers,
} from "../../../../features/admin/analytics/adminSDKAnalyticsSlice";
import { getSDKUsers } from "../../../../features/admin/analytics/adminSDKAnalyticsSlice";

import DatePicker from "../../../_utils/DatePicker";
import { lastTEDays } from "../../../_utils/DateUtils";

import {
  Users,
  UserCheck,
  Map,
  Archive,
  FileText,
  ChevronRight,
  ChevronLeft,
  Search,
  X,
} from "react-feather";

const AdminSDKAnalyticsHome = (props) => {
  const dispatch = useDispatch();

  const [fetching, setFetching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [searchedSDKUsers, setSearchedSDKUsers] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [sdkAnalytics, setSdkAnalytics] = useState([]);
  const [dateObj, setDateObj] = useState(lastTEDays);

  const pageSize = 9;

  const userList = useSelector((state) => state.adminanalytics.users);
  const sdkAnalyticsData = useSelector(
    (state) => state.adminsdkanalytics.sdkAnalytics
  );
  const totalPages = useSelector((state) => state.adminanalytics.totalPages);
  const searchedSDKUserList = useSelector(
    (state) => state.adminsdkanalytics.searchedSDKUsers
  );

  useEffect(() => {
    fetchUsers(pageNumber);
    dispatch(getSDKAnalytics(dateObj));
    dispatch(clearSearchedUsers());
    setFetching(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userList && !isEqual(userList, users)) {
      setFetching(false);
      setLoading(false);
      setUsers(userList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userList]);

  useEffect(() => {
    if (sdkAnalyticsData && !isEqual(sdkAnalyticsData, sdkAnalytics)) {
      setFetching(false);
      setLoading(false);
      setSdkAnalytics(sdkAnalyticsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sdkAnalyticsData]);

  useEffect(() => {
    if (
      searchedSDKUserList &&
      (!isEqual(searchedSDKUserList, searchedSDKUsers) ||
        searchedSDKUserList.length === 0)
    ) {
      setFetching(false);
      setLoading(false);
      setSearchedSDKUsers(searchedSDKUserList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedSDKUserList]);

  useEffect(() => {
    fetchUsers(pageNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber]);

  useEffect(() => {
    if (searchText.length >= 3) {
      searchUsersNow();
    } else if (searchText.length <= 0) {
      handleClearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleSearch = () => {
    dispatch(searchUsers(searchText));
  };

  const getSearchedUsers = debounce(handleSearch, 250);

  const fetchUsers = (pageNumber) => {
    var reqObj = {
      pageNumber,
      pageSize,
    };
    dispatch(getSDKUsers(reqObj));
  };

  const handlePageChange = (pageType) => {
    if (pageType === 1) {
      if (pageNumber < totalPages) {
        setPageNumber(pageNumber + 1);
        setLoading(true);
      }
    } else {
      if (pageNumber > 1) {
        setPageNumber(pageNumber + 1);
        setLoading(true);
      }
    }
  };

  const handleSearchChange = (e) => {
    if (e.target.value.includes(" ") && e.target.value.length === 1) {
      e.target.value = e.target.value.replace(/\s/g, "");
    }

    const sText = e.target.value;
    var filter = /^([\w,:\s@.\-_]*)$/;
    let filteredSearchText = [...sText]
      .map((c) => (filter.test(c) ? c : ""))
      .join("");
    setSearchText(filteredSearchText);
  };

  const searchUsersNow = () => {
    if (searchText.length > 0) {
      setLoading(true);
      setSearchedSDKUsers([]);
      getSearchedUsers();
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
    dispatch(clearSearchedUsers());
  };

  const renderUsers = () => {
    if ((fetching && users.length === 0) || loading) {
      return (
        <Loader
          loaderText={loading ? "Loading More Users..." : "Fetching Users..."}
          height="500px"
          width="100%"
        />
      );
    }
    if (users.length === 0) {
      return (
        <div className="illustration-container" style={{ height: "500px" }}>
          <h4>No Users Available!</h4>
        </div>
      );
    } else if (searchedSDKUsers && searchedSDKUsers.length > 0) {
      return <UserTable users={searchedSDKUsers} />;
    } else if (
      searchText.length >= 3 &&
      (!searchedSDKUsers || searchedSDKUsers.length <= 0)
    ) {
      return (
        <div className="illustration-container" style={{ height: "500px" }}>
          <h4>No matches found.</h4>
        </div>
      );
    } else {
      return <UserTable users={users} />;
    }
  };

  const renderInfoCard = (Icon, data, title, accent) => {
    return (
      <div
        className="aahome__mapAnalyticsContainer--infoCard"
        style={{ borderColor: accent }}
      >
        <div
          className="aahome__mapAnalyticsContainer--infoCard--iconCont"
          style={{ backgroundColor: accent }}
        >
          <Icon width="18px" height="18px" color="#fff" strokeWidth="2.4" />
        </div>
        <div className="aahome__mapAnalyticsContainer--infoCard--infoCont">
          <div style={{ color: accent }}>{data}</div>
          <span>{title}</span>
        </div>
      </div>
    );
  };

  const renderSDKAnalytics = () => {
    return (
      <div className="aahome__mapAnalyticsContainer">
        <div className="aahome__userAnalyticsContainer--headerRow">
          <h5>SDK Analytics</h5>
          <Box
            component="span"
            className="col-4 analyticshome__anCard--filterBox"
          >
            <span style={{ color: "#858993" }}>Date:</span>
            <DatePicker
              cb={(obj) => {
                setDateObj(obj);
                dispatch(getSDKAnalytics(obj));
              }}
            />
          </Box>
        </div>
        <div className="aahome__mapAnalyticsContainer--infoContainer">
          <div className="aahome__mapAnalyticsContainer--infoRow aahome__mapAnalyticsContainer--flexSpace">
            {renderInfoCard(
              Users,
              sdkAnalytics?.totalSdkAccounts || "--",
              "Total SDK Accounts",
              "#008080"
            )}
            {renderInfoCard(
              UserCheck,
              sdkAnalytics?.totalSdkApps || "--",
              "Total SDK Apps",
              "#017EB4"
            )}
          </div>
          <div className="aahome__mapAnalyticsContainer--infoRow aahome__mapAnalyticsContainer--flexSpace">
            {renderInfoCard(
              Map,
              sdkAnalytics?.totalSdkApiCalls || "--",
              "Total SDK API Calls",
              "#5600AC"
            )}
            {renderInfoCard(
              Archive,
              sdkAnalytics?.sdkAccountStorage
                ? isEqual("0.00", sdkAnalytics?.sdkAccountStorage)
                  ? "--"
                  : sdkAnalytics?.sdkAccountStorage + " MB"
                : "--",
              "Total Storage",
              "#2A4DC2"
            )}
          </div>
          <div className="aahome__mapAnalyticsContainer--infoRow aahome__mapAnalyticsContainer--flexCenter">
            {renderInfoCard(
              FileText,
              sdkAnalytics?.totalAccounts || "--",
              "Total Accounts",
              "#B80290"
            )}
          </div>
        </div>
      </div>
    );
  };

  var crossClass = "searchbar__closeIcon--hide";
  if (searchText.length > 0) {
    crossClass = "";
  }

  return (
    <div className="aahome">
      <Container maxWidth={false}>
        <div className="pageWrapper">
          <h4>Analytics</h4>
        </div>
        {renderSDKAnalytics()}
        <div className="aahome__userAnalyticsContainer">
          <div className="aahome__userAnalyticsContainer--headerRow">
            <h5>SDK Accounts Analytics</h5>
          </div>
          <div className="serachContRow">
            <div
              className="searchbar searchbar--padded"
              style={{ width: "50%" }}
            >
              <span className="searchbar__searchbarContainer">
                <Search
                  size={20}
                  color="#353E5A"
                  className="searchbar__searchIcon"
                />
                <input
                  type="text"
                  autoComplete="off"
                  placeholder="Search with User Email"
                  value={searchText}
                  onChange={handleSearchChange}
                  id="input-adminSDKAnalyticsSearch"
                />
                <X
                  size={20}
                  color="#353E5A"
                  onClick={handleClearSearch}
                  className={`searchbar__closeIcon ${crossClass}`}
                />
              </span>
            </div>
            {searchText.length === 0 ? (
              <div>
                {pageNumber > 1 && (
                  <ChevronLeft
                    className="pageBar"
                    onClick={() => handlePageChange(2)}
                  />
                )}
                <span className="pageCount">
                  {pageNumber} / {totalPages}
                </span>
                <ChevronRight
                  className="pageBar"
                  style={{
                    visibility:
                      totalPages !== pageNumber ? "visible" : "hidden",
                  }}
                  onClick={() => handlePageChange(1)}
                />
              </div>
            ) : null}
          </div>
          <Box className="boxWrapper">{renderUsers()}</Box>
        </div>
      </Container>
    </div>
  );
};

export default AdminSDKAnalyticsHome;
