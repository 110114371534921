import EditorExperience from "../EditorExperience";
import EventEmitter from "./EventEmitter";

//Server
import Server from "../../../api";

class AutoSave extends EventEmitter {
  constructor() {
    super();

    this.editor = new EditorExperience();

    this.editor.on("autoSaveSceneGraphState", this.onAutoSaveSceneGraphState);
    this.editor.on("invokeSaveMapState", this.onInvokeSaveMapState);
    this.editor.on(
      "handlePublishAndVersionSwitch",
      this.onHandlePublishAndVersionSwitch
    );
  }

  onInvokeSaveMapState = (saveState) => {
    this.onAutoSaveSceneGraphState(saveState === "publish", true);
  };

  onHandlePublishAndVersionSwitch = () => {
    this.onAutoSaveSceneGraphState(true, true);
    this.editor.trigger("handleVersionSwitch");
  };

  onAutoSaveSceneGraphState = (mapState = false, local = false) => {
    this.editor.inSavingState = true;
    let mapContentNew = this.editor.toJSON();
    let mapDetails = this.editor.mapData;
    let mapdata = mapDetails.mapdata
      ? { ...mapDetails.mapdata }
      : { createdBy: "", mapAddress: "" };
    mapdata = {
      ...mapdata,
      mapContent: { ...mapContentNew },
    };
    mapDetails = { mapdata };
    console.log("SAVING...", mapState, mapDetails.mapdata.mapContent);
    // Server.put(`/v1/mapdata/${this.editor.mapCode}`, mapDetails)
    Server.put(`/v2/mapdata/${this.editor.mapCode}/${mapState}`, mapDetails)
      .then((response) => {
        this.editor.inSavingState = false;
        this.editor.trigger("autoSaveComplete");
        // this.editor.callbacks.updateMapDetails({...this.editor.mapData, ...mapDetails});
        if (response.status === 200) {
          if (mapState && !this.editor.isFloorMap) {
            // venue publish
            this.publishVenueMap();
            this.editor.trigger("toggleUpdateState", [false]); // on publish reset update state
          } else {
            local
              ? this.editor.callbacks.generateAlert({
                  msg: "Map Changes Saved Successfully!",
                  alertType: "success",
                })
              : console.log("AutoSave Successful!");
            local && this.editor.trigger("toggleUpdateState", [false]);
          }
        } else {
          local
            ? this.editor.callbacks.generateAlert({
                msg: "Failed to save changes, please try again later!",
                alertType: "danger",
              })
            : console.log("AutoSave Failed!");
        }
      })
      .catch((e) => {
        this.editor.inSavingState = false;
        this.editor.trigger("autoSaveComplete");
        console.log(e, e.response);
        this.editor.callbacks.handleExceptionCatch(e);
      });
  };

  publishVenueMap = () => {
    this.editor.callbacks.onPublishVenueMap();
  };
}

export { AutoSave };
