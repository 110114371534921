import RenderLineChart from "../../Render/RenderLineChart";
import { getTotalMapVisitsOverTimeByLocation } from "../../../../../../features/user/analytics/analyticsSlice";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { lastTEDays } from "../../../../../_utils/DateUtils";
import { Box, MenuItem, Select } from "@mui/material";
import DatePicker from "../../../../../_utils/DatePicker";
import { ChevronDown } from "react-feather";
import { isEmpty, isNull } from "lodash";

const MapVisitsOvertimeByLocation = ({ totalMapVisitsOverTimeByLocation }) => {
  const dispatch = useDispatch();
  const regions = useSelector((state) => state.analytics.regions);
  const [region, setRegion] = useState("all,all");
  const [dateObj, setDateObj] = useState(lastTEDays);
  const [labels, setLabels] = useState(null)
  const [visits, setVisits] = useState(null)

  useEffect(()=>{
    if(!isEmpty(totalMapVisitsOverTimeByLocation))
    {
      setLabels(totalMapVisitsOverTimeByLocation.map((visit) => new Date(`${visit.date}`)))
      setVisits(totalMapVisitsOverTimeByLocation.map((visit) => visit.visits))
    } else{
      setLabels(null)
      setVisits(null)
    }

  },[totalMapVisitsOverTimeByLocation])

  useEffect(() => {
    let [reg, country] = region.split(",");
    let args = { ...dateObj, country, reg };
    dispatch(getTotalMapVisitsOverTimeByLocation(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let [reg, country] = region.split(",");
    let args = { ...dateObj, country, reg };
    dispatch(getTotalMapVisitsOverTimeByLocation(args));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [region]);



  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col-4">
            <div className="analyticshome__anCard--contentRow--header">
              Map Visits Over Time by Location
            </div>
          </div>
          <div className="col" style={{ paddingRight: "2.5rem" }}>
            <div className="row m-0 box">
              <div className="col-4"></div>
              <Box
                component="span"
                className="col-3 analyticshome__anCard--filterBox"
              >
                <span style={{ color: "#858993" }}>Region:</span>
                <Select
                  style={{
                    fontFamily: "poppins",
                    color: "#2A4DC2",
                    width: "70%",
                  }}
                  value={region}
                  onChange={handleRegionChange}
                  variant="standard"
                  fullWidth
                  disableUnderline
                  IconComponent={ChevronDown}
                >
                  {regions.map((option) => (
                    <MenuItem
                      key={option.value}
                      value={option.value}
                      style={{ fontFamily: "poppins", color: "#2A4DC2" }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
              <div className="col-1"></div>
              <Box
                component="span"
                className="col-4 analyticshome__anCard--filterBox"
              >
                <span style={{ color: "#858993" }}>Date:</span>
                <DatePicker
                  cb={(obj) => {
                    setDateObj(obj);
                    let [reg, country] = region.split(",");
                    let args = { ...obj, country, reg };
                    dispatch(getTotalMapVisitsOverTimeByLocation(args));
                  }}
                />
              </Box>
              {/* } */}
            </div>
            <div
              className="analyticshome__floatright"
              style={{ paddingRight: "35px" }}
            ></div>
          </div>
        </div>
        <hr />
        {isNull(labels) || isNull(visits) ? (
          <h5 className="text-center analyticshome__emptyStateText ">
            {" "}
            No Data available{" "}
          </h5>
        ) : (
          RenderLineChart(labels, visits, dateObj, "Number of Map Visits")
        )}
      </div>
    </div>
  );
};

export default MapVisitsOvertimeByLocation;
