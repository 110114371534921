import React from 'react';
import Carousel from 'react-material-ui-carousel'
import {
    Backdrop, Box, 
    Modal, Fade, 
} from '@mui/material';

import {
    XCircle
} from 'react-feather';

//Images
import OBOneImg from '../../../assets/pngs/studio/onboarding_editor2d_1.png';
import OBTwoImg from '../../../assets/pngs/studio/onboarding_editor2d_2.png';
import OBThreeImg from '../../../assets/pngs/studio/onboarding_editor2d_3.png';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    height: 'max-content',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    border: 'none !important',
    outline: 'none !important',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center !important',
    alignItems: 'center !important',
    borderRadius: '16px'
};

export default function Onboarding2DModal({openModal, onCloseModal}) {

    const renderOBCard = (title, imgSrc, header, subText) => {
        return(
            <div className='obModal--obCard'>
                <h4 className='obModal--obCard--title'>{title}</h4>
                <span className='obModal--obCard--header'>{header}</span>
                <img src={imgSrc} alt={imgSrc} draggable="false" />
                {subText && <span className='obModal--obCard--subText'>{subText}</span>}
            </div>
        )
    }


    return (
        <div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={openModal}
                onClose={onCloseModal}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 600,
                }}
            >
                <Fade in={openModal}>
                    <Box sx={style}>
                        <XCircle color='#353E5A' strokeWidth='1.5' className='mapdetails__mapModalCloseIcon' onClick={onCloseModal}/>
                        <div className='obModal'>
                            <Carousel autoPlay={false} height={560}>
                                {renderOBCard('Location Directory', OBOneImg, `To populate the location directory, complete this two step process.
                                    Add walk-able only and/or all access paths to your floor plan, proceed to anchor location pins along paths. Turn-by-turn
                                    navigation will be applied to pins anchored along paths.`, null)}

                                {renderOBCard('Accessible Paths', OBTwoImg, `Use the pen tool to create paths along the floor plan. Create walk-able only 
                                    or wheelchair/all access paths, then anchor location pins to the paths. 
                                    \n**Note: All paths must be connected.`, null)}

                                {renderOBCard('Location Pins', OBThreeImg, `Anchor location pins on paths to enable turn-by-turn navigation.  Customize pins by selecting pin colours, 
                                    navigation styles and add important information to location pins to give your visitors a robust experience while using the directory.`, 
                                    `**Note: A compass style navigation will be applied to pins anchored off of paths. Turn-by-turn navigation will be disabled. “Wheelchair Accessible” 
                                    markers will appear in the directory for pins located on blue paths.`)}
                            </Carousel>
                        </div>
                        <button className='obModal--btn' onClick={onCloseModal}>Got it!</button>
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}