import fileType from "file-type";

const fileTypes = {
  images: ".png,.jpg,.jpeg",
  models: ".glb", //".obj,.fbx,.glb,.gltf,.ply,.blend",
  audios: ".mp3,.wav,.m4a,.ogg",
  videos: ".mp4",
  floorplans: ".png,.jpg,.jpeg",
};

/* const MBINBYTES = 1048576; */

const fileLimits = {
  images: 4048576,
  models: 10485760,
  audios: 4048576,
  videos: 12582912,
  floorplans: 20971520,
};

const validateSize = (file, contentType) => {
  return file.size <= fileLimits[contentType];
};

const validateExt = (file, contentType) => {
  return fileTypes[contentType]
    .split(",")
    .includes(`.${file?.name.split(".").pop().toLowerCase()}`);
};

const validateMIME = (file, contentType , cb) => {
  if (!file) {
    return false;
  }
  const blob = file.slice(0, fileType.minimumBytes);
  const reader = new FileReader();
  var mimes = [
    {
      mime: "image/jpeg",
      pattern: [0xff, 0xd8, 0xff],
      mask: [0xff, 0xff, 0xff],
    },
    {
      mime: "image/png",
      pattern: [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a],
      mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff],
    },
    {
      mime: "image/gif",
      pattern: [0x47, 0x49, 0x46, 0x38, 0x37, 0x61],
      mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff],
    },
    // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
  ];

  const check = (bytes, mime) => {
    for (var i = 0, l = mime.mask.length; i < l; ++i) {
      if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
        return false;
      }
    }
    return true;
  };

  reader.onloadend = (e) => {
    if (e.target.readyState !== FileReader.DONE) {
      return;
    }
    const bytes = new Uint8Array(e.target.result);
    // console.log(mimes.some((mime) => check(bytes, mime), validateExt(file, contentType)))
    cb(mimes.some((mime) => check(bytes, mime) && validateExt(file, contentType)));
  };
  reader.readAsArrayBuffer(blob);
};

export { validateMIME, validateExt, validateSize, fileTypes, fileLimits };
