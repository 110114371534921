import { Button, Layout } from "antd";
import React, {useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import SvgIcn from '../../../assets/svgs/ARWay_Primary_Logo_Brand.svg';
import { resendVerificationMail } from "../../../features/auth/authSlice";

// Routes
import {
    LOGIN,
} from '../../_utils/routes';

export const VerifyEmail = () => {


    const dispatch = useDispatch()
    const [linkSent, setLinkSent] = useState(false)
    const handleResend = () => {
        dispatch(resendVerificationMail())
        setLinkSent(true)
    }
    const registerEmail = localStorage.getItem('register-email')


    return(
        <Layout className="main">
            <Layout className="form-containers">
                <img className="brand-logo" alt="brand-logo-3" src={SvgIcn} />

                <h5 className="login-header" id="h5-verify">Verify your email</h5>
                {linkSent ? (
                    <Layout className="email-sent">
                        Email has been sent.
                    </Layout>
                ) : null}
                <p className="reset-guide">We have sent you an verification link to your email at <span className="email">{registerEmail ? registerEmail : '[Email]'}</span>. Please click the verification link to activate your account.</p>
                <p className="reset-guide">Don't see it? Make sure to check spam, junk and promotional folders, or</p>

                <div onClick={linkSent ? null : handleResend} className={linkSent ? " greyed-out resend-mail" : " resend-mail"} >
                    Resend the link
                </div>
                <Button className="signin-button verify-btn" type="primary">
                    <Link  className="signin-button--spanText"  to={LOGIN} id='link-verifyBackLogin'> Back to Login</Link>
                </Button>
            </Layout>
        </Layout>
    )
}