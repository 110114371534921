import React, { useState, useCallback } from 'react';
import { useDropzone } from "react-dropzone";
import { useDispatch } from 'react-redux';
import {
    validateSize,
    validateMIME,
    fileLimits,
    fileTypes,
} from "../utils";
import { setNewAlert } from '../../../features/common/alertSlice';

const DropZoneTab = ({ onChange }) => {

    const dispatch = useDispatch();

    const [file, setFile] = useState(null);

    const MBINBYTES = 1048576;

    const checkSize = (file, contentType) => {
        if (validateSize(file, contentType)) {
            return true;
        } else {
            setFile(null);
            document.getElementById("input-content").value = null;
            dispatch(
                setNewAlert({
                msg: `File Size Limit Exceeded! [Upload Limit : ${parseInt(
                    fileLimits[contentType] / MBINBYTES
                )}MB]`,
                alertType: "danger",
                })
            );
        }
        return false;
    };

    const validateAndSetFile = (file) => {
        validateMIME(file, "floorplans", (flag) => {
            let checkSizeBool = checkSize(file, "floorplans");
            if (flag && checkSizeBool) {
                setFile(file);
                onChange(file);
            } else {
                checkSizeBool && setFile(false);
            }
        });
    };

    const handleFileChange = (e) => {
        const acceptedFile = e.target.files[0];
        validateAndSetFile(acceptedFile);
    };

    const { getRootProps, getInputProps } = useDropzone({
        minSize: 0,
        maxSize: fileLimits["floorplans"],
        onDrop: useCallback((acceptedFiles) => {
            let acceptedFile = acceptedFiles[0];
            validateAndSetFile(acceptedFile);
          // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []),
    });


    return (
        <div className='sfModal--dropCard'>
            <label className="content-upload">
                <div
                    className="content-dnd-zone"
                    {...getRootProps()}
                    onClick={(e) => e.stopPropagation()}
                >
                    <input
                    {...getInputProps()}
                    accept={fileTypes["floorplans"]}
                    onChange={handleFileChange}
                    id="input-content"
                    />
                    <div className="content-dnd-msg">
                    {file ? (
                        <p>{file.name}</p>
                    ) : (
                        <>
                        <p>Drag your content here</p>
                        <p>
                            File Size Limit:{" "}
                            {parseInt( fileLimits["floorplans"] / MBINBYTES)}MB
                        </p>
                        <p>
                            <span></span>
                            or
                            <span></span>
                        </p>
                        </>
                    )}
                    <p className="choose-file-text" id="p-upload-Thumbnail">
                        {file ? "Choose a different file" : "Choose a file"}
                    </p>
                    </div>
                </div>
            </label>
        </div>
    )
}

export default  DropZoneTab;
