import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { 
    Accordion, AccordionSummary, 
    AccordionDetails, Grid,
} from '@mui/material';
// icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Edit2, Save, Loader } from 'react-feather';
//components
import Input from './components/input';
import SwitchInput from './components/input-switch';
import { updatePlan, clearLog } from '../../../../features/admin/plans/plansSlice';
import { setNewAlert } from '../../../../features/common/alertSlice';
import { isEmpty } from 'lodash';
import GenericSwitch from '../../../common/toggleSwitch/GenericSwitch';

export default function PlanCard({
    plan,
    index,
    expanded,
    setExpanded,
    setPlanAccounts
}) {

    const dispatch = useDispatch();
    const [ isDisabled, setIsDisabled ] = useState(true);
    const [ progress, setProgress ] = useState(false);
    const [ planData, setPlanData ] = useState(plan);
    const [ planErrors, setErrors ] = useState({});
    const [areaSQFt, setAreaSQFt] = useState(false);

    const plansLog = useSelector(state => state.plans.planLog);

    useEffect(() => {
        if(plansLog !== null) {
            if(plansLog === 'success' && progress) {
                dispatch(setNewAlert({
                    msg: 'Plan Updated Successfully!',
                    alertType: 'information'
                }));
                setProgress(false);
                setIsDisabled(true)
            } else {
                setProgress(false);
                dispatch(clearLog());
            }
            
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plansLog]);

    useEffect(() => {
        if(planData) {
            validateChanges();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [planData]);

    const handleEdit = (e, type = 'text') => {
        setPlanData((planData) => ({
            ...planData,
            [e.target.id]: type === 'text' ? e.target.value :Number(e.target.value)
        }))
    }

    const handleObjectEdit = (key, id, value) => {
        setPlanData((planData) => ({
            ...planData,
            [key]: {
                ...planData[key],
                [id]: value
            }
        }))
    }

    const validateChanges = () => {
        let errFlag = true;
        let errors = {...planErrors};
        if(planData.name.trim().length <= 0) {
            errors = {
                ...errors,
                name: "Name shouldn't be empty."
            };
            errFlag = false;
        } else {
            delete errors["name"];
        }

        if(planData.price <= 0) {
            errors = {
                ...errors,
                price: "Price shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["price"];
        }

        if(planData.annualPrice <= 0) {
            errors = {
                ...errors,
                annualPrice: "Annual price shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["annualPrice"];
        }

        if(planData.duration <= 0) {
            errors = {
                ...errors,
                duration: "Duration shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["duration"];
        }

        if(planData.apiCalls <= 0) {
            errors = {
                ...errors,
                apiCalls: "API calls limit shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["apiCalls"];
        }

        if(planData.storage <= 0) {
            errors = {
                ...errors,
                storage: "Storage limit shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["storage"];
        }

        if(planData.mapsLimit <= 0) {
            errors = {
                ...errors,
                mapsLimit: "Map limit shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["mapsLimit"];
        }

        if(planData.activeMapDays.enable && planData.activeMapDays.count <= 0) {
            errors = {
                ...errors,
                activeMapDays: "Active map days shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["activeMapDays"];
        }

        if(planData.publishedMap.enable && planData.publishedMap.count <= 0) {
            errors = {
                ...errors,
                publishedMap: "Published map limit shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["publishedMap"];
        }

        if(planData.mapArea.enable && planData.mapArea.size <= 0) {
            errors = {
                ...errors,
                mapArea: "Map area size shouldn't be 0."
            };
            errFlag = false;
        } else {
            delete errors["mapArea"];
        }

        setErrors({...errors});
        return errFlag;
    }

    const handleSave = () => {
        if(validateChanges(planData)) {
            // console.log(planData);
            dispatch(updatePlan(areaSQFt ? 
                {
                    ...planData,
                    mapArea: {
                        ...planData.mapArea,
                        size: (planData.mapArea.size / 10.764).toFixed(2)
                    }
                } : planData)
            );
        }
    }

    return (
        <Accordion
            expanded={expanded === index}
            onChange={() => {
                if(expanded === index) {
                    setExpanded(-1);
                    setProgress(false);
                    setIsDisabled(true);
                }
                else setExpanded(index)
            }}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} id={index}>
                <span className='pmhome__plans--planHeader'>{planData.name}</span>
            </AccordionSummary>
            <AccordionDetails>
                <div>
                    <Grid container={true}>
                        {!isDisabled && <Grid item xs={6}>
                            <Input 
                                inputType="text"
                                label="Name"
                                id="name"
                                value={planData.name}
                                enabled={isDisabled}
                                handleOnChange={handleEdit}
                            />
                            {planErrors && planErrors.name && <span>{planErrors.name}</span>}
                        </Grid>}
                        <Grid item xs={6}>
                            <Input 
                                inputType="number"
                                label="Price"
                                subLabel="USD"
                                id="price"
                                value={planData.price}
                                enabled={isDisabled}
                                handleOnChange={(e) => handleEdit(e, 'number')}
                            />
                            {planErrors && planErrors.price && <span className='pInput__error'>{planErrors.price}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <Input 
                                inputType="number"
                                label="Annual Price"
                                subLabel="USD"
                                id="annualPrice"
                                value={planData.annualPrice}
                                enabled={isDisabled}
                                handleOnChange={(e) => handleEdit(e, 'number')}
                            />
                            {planErrors && planErrors.annualPrice && <span className='pInput__error'>{planErrors.annualPrice}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <Input 
                                inputType="number"
                                label="API Calls"
                                id="apiCalls"
                                value={planData.apiCalls}
                                enabled={isDisabled}
                                handleOnChange={(e) => handleEdit(e, 'number')}
                            />
                            {planErrors && planErrors.apiCalls && <span className='pInput__error'>{planErrors.apiCalls}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <Input 
                                inputType="number"
                                label="Storage"
                                subLabel="MB"
                                id="storage"
                                value={planData.storage}
                                enabled={isDisabled}
                                handleOnChange={(e) => handleEdit(e, 'number')}
                            />
                            {planErrors && planErrors.storage && <span className='pInput__error'>{planErrors.storage}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <Input 
                                inputType="number"
                                label="Maps Limit"
                                id="mapsLimit"
                                value={planData.mapsLimit}
                                enabled={isDisabled}
                                handleOnChange={(e) => handleEdit(e, 'number')}
                            />
                            {planErrors && planErrors.mapsLimit && <span className='pInput__error'>{planErrors.mapsLimit}</span>}
                        </Grid>
                        <Grid item xs={12}></Grid>
                        <Grid item xs={6}>
                            <SwitchInput 
                                inputType="number"
                                label="Duration"
                                subLabel="Days"
                                id="duration"
                                toggleLabel="Set Recurring"
                                value={planData.duration}
                                toggleValue={planData.isRecurring}
                                enabled={isDisabled}
                                linkedToggle={false}
                                handleOnChange={(e) => handleEdit(e, 'number')}
                                handleOnToggle={(e) => setPlanData((prev) => ({
                                    ...prev, isRecurring: e.target.checked
                                }))}
                            />
                            {planErrors && planErrors.duration && <span className='pInput__error'>{planErrors.duration}</span>}
                        </Grid>
                        <Grid item xs={6} className='absErroCont'>
                            <SwitchInput 
                                inputType="number"
                                label="Maps Area"
                                subLabel={areaSQFt ? "Sq Ft" : "Sq m"}
                                id="mapArea"
                                toggleLabel="Set Limit"
                                value={planData.mapArea.size}
                                toggleValue={planData.mapArea.enable}
                                enabled={isDisabled}
                                linkedToggle={true}
                                handleOnChange={(e) => handleObjectEdit('mapArea','size', e.target.value)}
                                handleOnToggle={(e) => handleObjectEdit('mapArea', 'enable', e.target.checked)}
                            />
                            <div className='mapAreaUnitCont' style={isDisabled || !planData.mapArea.enable ? {opacity: .4, paddingRight: '8px'} : {paddingRight: '8px'}}>
                                <div className='mapAreaUnitDiv'>
                                    <div className='mapAreaUnitDiv__header'>Map Area Unit: </div>
                                    <div className='mapAreaUnitDiv__toggleCont'>
                                        <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Mtr</div>
                                        <GenericSwitch
                                            enabled={areaSQFt}
                                            onEnableToggle={(e) => {
                                                setAreaSQFt(e.target.checked);
                                                e.target.checked 
                                                    ? handleObjectEdit('mapArea','size', (planData.mapArea.size * 10.764).toFixed(2))
                                                    : handleObjectEdit('mapArea','size', (planData.mapArea.size / 10.764))
                                            }}
                                            disabled={isDisabled || !planData.mapArea.enable}
                                        />
                                        <div className='mapAreaUnitDiv__toggleCont--label'>Sq.Ft</div>
                                    </div>
                                </div>
                            </div>
                            {planErrors && planErrors.mapArea && <span className='pInput__error absError'>{planErrors.mapArea}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <SwitchInput 
                                inputType="number"
                                label="Active Map Days"
                                subLabel="Days"
                                id="activeMapDays"
                                toggleLabel="Enable"
                                value={planData.activeMapDays.count}
                                toggleValue={planData.activeMapDays.enable}
                                enabled={isDisabled}
                                linkedToggle={true}
                                handleOnChange={(e) => handleObjectEdit('activeMapDays','count', e.target.value)}
                                handleOnToggle={(e) => handleObjectEdit('activeMapDays', 'enable', e.target.checked)}
                            />
                            {planErrors && planErrors.activeMapDays && <span className='pInput__error'>{planErrors.activeMapDays}</span>}
                        </Grid>
                        <Grid item xs={6}>
                            <SwitchInput 
                                inputType="number"
                                label="Published Maps"
                                id="publishedMap"
                                toggleLabel="Set Limit"
                                value={planData.publishedMap.count}
                                toggleValue={planData.publishedMap.enable}
                                enabled={isDisabled}
                                linkedToggle={true}
                                handleOnChange={(e) => handleObjectEdit('publishedMap','count', e.target.value)}
                                handleOnToggle={(e) => handleObjectEdit('publishedMap', 'enable', e.target.checked)}
                            />
                            {planErrors && planErrors.publishedMap && <span className='pInput__error'>{planErrors.publishedMap}</span>}
                        </Grid>
                    </Grid>
                    <div  className='pmhome__plans--editBtn'>
                        <button
                            onClick={() => setPlanAccounts(plan)}
                            className='pmhome__plans--editBtn--edit'
                            style={{marginRight: '20px'}}
                        >
                            <span>View Accounts</span>
                        </button> 
                        <div>
                            {isDisabled ? <button
                                onClick={() => {
                                    setIsDisabled(!isDisabled);
                                }}
                                className='pmhome__plans--editBtn--edit'
                            >
                                <Edit2 size={18}/> <span>Edit</span>
                            </button> : <button
                                onClick={() => {
                                    // setIsDisabled(!isDisabled);
                                    if(!progress) {
                                        setProgress(true);
                                        handleSave();
                                    }
                                }}
                                className={!isEmpty(planErrors) ? 'pmhome__plans--editBtn--editDisabled': 'pmhome__plans--editBtn--edit'}
                                disabled={!isEmpty(planErrors)}
                            >
                                { progress ?<><Loader size={18}/> <span>Saving</span></> :<><Save size={18}/> <span>Save</span></>}
                            </button>}
                            {!isDisabled && 
                                <button 
                                    className='pmhome__plans--editBtn--calcel'
                                    onClick={() => {
                                        if(!progress) {
                                            setIsDisabled(!isDisabled);
                                            setPlanData(plan)
                                        }
                                    }}
                                >
                                    Cancel 
                                </button>
                            }
                        </div>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    );
}
