import Typography from "@mui/material/Typography";

const Loader = (props) => {
  const { width, height, loaderText, value } = props;
  return (
    <div className="loaderContainer" style={{ width, height }}>
      {value ? (
        <div className="stepper__stackParent">
          <Typography className="stepper__stackParent--layerOne" variant="body2" color="text.secondary">
            <span className="stepper__SectionHeader">{`${value} %`}</span>
          </Typography>
          <span className="loaderContainer__loaderImg stepper__stackParent--layerTwo"></span>
        </div>
      ) : (
        <span className="loaderContainer__loaderImg"></span>
      )}

      <h5 className="loaderContainer__loaderText">{loaderText}</h5>
    </div>
  );
};

export default Loader;
