import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

//Icons
import SvgIcn from "../../../../assets/svgs/ARWay_Primary_Logo.svg";

import TopBar from "../../../common/topbar/TopBarAccess";
import UserInfo from "../personalinfo/UserInfo";

//Action
import { resetAccessState } from "../../../../features/auth/accessSlice";

const Landing = () => {
  const dispatch = useDispatch();

  const [header, setHeader] = useState("");
  const [subHeader, setSubHeader] = useState("");
  const [accessState, setAccessState] = useState(0);
  const [setupState, setSetupState] = useState(0);

  const activeSetupState = useSelector(
    (state) => state.access.activeSetupState
  );

  useEffect(() => {
    dispatch(resetAccessState());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeSetupState && activeSetupState !== setupState) {
      changeHeaderTexts(activeSetupState);
      setSetupState(activeSetupState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSetupState]);

  const changeHeaderTexts = (state) => {
    if (state === 0) {
      setHeader("");
      setSubHeader("");
    } else if (state === 1) {
      setHeader("Complete your account profile");
      setSubHeader("Step 1 of 2");
    }
  };

  return (
    <Box>
      <TopBar header={header} subHeader={subHeader} />
      <Box className="accesshome">
        {accessState === 0 ? (
          <div className="accesshome__landingContainer">
            <img src={SvgIcn} alt="brand logo" className="accesshome__logo" />
            <h2 className="accesshome__title">ARway</h2>
            <span className="accesshome__headerText">
              Let's finish setting up your account!
            </span>
            <span className="accesshome__headerSubText">
              Don't worry, we'll help you every step of the way.
            </span>
            <button
              className="accesshome__headerBtn"
              onClick={() => {
                setAccessState(1);
              }}
            >
              Let's get started
            </button>
          </div>
        ) : (
          <UserInfo />
        )}
      </Box>
    </Box>
  );
};

export default Landing;
