import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeAlert } from '../../../features/common/alertSlice';

const Alerts = () => {
    const dispatch = useDispatch();
    const alerts = useSelector(state => state.alerts);

    const renderAlerts = () => {
        if(alerts === null || alerts.length <= 0) {
            return null;
        } else {
            return alerts.map((alert) => (
                <div key={alert.id} className={!alert.alertType.includes('stay') ? `alertpage-alert alertpage-alert--${alert.alertType}` : `alertpage-alertnone alertpage-alert--${alert.alertType}`}>
                    <span className={`alertpage-alert-alertspan-${alert.alertType}`} />
                    <span id="arway-alert" className="alertpage-alert-msg">{alert.msg}</span>
                    <span className="alertpage-alert-close" onClick={() => dispatch(removeAlert(alert.id))}>X</span>
                </div>
            ));
        }
    }

    if(!alerts || alerts.length <= 0) {
        return null;
    }
    return (
        <div className="alertpage">
            {renderAlerts()}
        </div>
    )
}

export default Alerts;
