import EditorExperience from "../../2DEditorExperience";
import EventEmitter from "../../../utils/EventEmitter";

//Icon
import CloseIcon from "../../../../../assets/icons/sidebar/cross_menu.svg";

import { UIImage, UIPanel, UIRow, UITextHeader } from "../../../ui/ui.lib";

import { SidebarContentLocationPins } from "./Sidebar2D.Content.LocationPins";
import { SidebarContentConnectorPins } from "./Sidebar2D.Content.ConnectorPins";

class Sidebar2DContent extends EventEmitter {
  constructor(props) {
    super(props);

    this.editor = new EditorExperience();
    this.dom = null;
    this.contentType = null;
    this.contentProperties = {};
    this.objectProperties = {};

    this.initUI();

    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on("toggleMenuActive", this.onToggleMenuActive);
  }

  initUI = () => {
    this.uiCont = new UIPanel();
    this.uiCont.setDisplay("none");

    //Set Title and Hide button!
    const uiHeaderRow = new UIRow().addClass("wsFlexRowCenter");
    const uiPanel = new UIPanel();
    this.uiTitle = new UITextHeader().setStyle("fontSize", "18px");
    uiPanel.addChild(this.uiTitle);
    uiHeaderRow.addChild(uiPanel);
    uiHeaderRow.addChild(
      new UIImage(CloseIcon, "24px", "24px", "close")
        .setStyle("transform", "rotate(180deg)")
        .onClick(() => this.editor.trigger("toggleMenuActive", [null]))
    );
    this.uiCont.addChild(uiHeaderRow);

    //For Location Pins
    this.uiLocationPins = new SidebarContentLocationPins();
    this.uiCont.addChild(this.uiLocationPins.dom);
    this.contentProperties["Location Pins"] = this.uiLocationPins.dom;
    this.objectProperties["Location Pins"] = this.uiLocationPins;

    this.uiConnectorPins = new SidebarContentConnectorPins();
    this.uiCont.addChild(this.uiConnectorPins.dom);
    this.contentProperties["Connector Pins"] = this.uiConnectorPins.dom;
    this.objectProperties["Connector Pins"] = this.uiConnectorPins;

    this.dom = this.uiCont.dom;
  };

  updateUITypes = (name) => {
    for (var property in this.contentProperties) {
      var uiEl = this.contentProperties[property];
      uiEl && uiEl.setDisplay(name === property ? "" : "none");
    }
  };

  updateUIData = (name) => {
    this.uiTitle.setTextContent(name);
    for (var property in this.objectProperties) {
      let objects = this.objectProperties[property];
      if (objects) {
        if (name === property) {
          if (objects instanceof Array)
            objects.forEach((object) => object.refreshUI());
          else objects.refreshUI();
        } else {
          if (objects instanceof Array)
            objects.forEach((object) => object.unload());
          else objects.unload();
        }
      }
    }
  };

  onToggleMenuActive = (name) => {
    this.contentType = name;

    if (this.contentType in this.contentProperties) {
      this.uiCont.setDisplay("block");
      this.updateUITypes(this.contentType);
      this.updateUIData(this.contentType);
    } else {
      this.uiCont.setDisplay("none");
      this.editor.trigger("2DSidebarSceneGraphChanged", [null]);
    }
  };

  onObjectSelected = (object) => {
    if (this.contentType !== null && this.contentType !== "pathStroke") {
      this.editor.trigger("toggleMenuActive", [null]);
    }
  };
}

export { Sidebar2DContent };
