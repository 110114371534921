import EditorExperience from "../../EditorExperience";
import EventEmitter from "../../utils/EventEmitter";
// import * as THREE from 'three';

//Icon
import CloseIcon from "../../../../assets/icons/sidebar/cross_menu.svg";
import HelpIcon from "../../../../assets/icons/sidebar/help.svg";

import { UIImage, UIPanel, UIRow, UITabs, UITextHeader } from "../ui.lib";

import { SidebarContentImage } from "./Sidebar.Content.Images";
import { SidebarContentText } from "./Sidebar.Content.Text";
import { SidebarContentHotspot } from "./Sidebar.Content.Hotspot";
import { SidebarContentAudio } from "./Sidebar.Content.Audio";
import { SidebarContentFloorplan } from "./Sidebar.Content.Floorplan";
import { SidebarContentModel } from "./Sidebar.Content.Models";
import { SidebarContentGuidedTour } from "./Sidebar.Content.GuidedTour";
import { SidebarContent3DyModel } from "./Sidebar.Content.3DyModels";
// import { SidebarContentLocationPins } from "./Sidebar.Content.LocationPins";
import { SidebarContentVideos } from "./Sidebar.Content.Videos";
import { SidebarContentQRCodes } from "./Sidebar.Content.QRCode";
import { SidebarContentMapMarkers } from "./Sidebar.Content.MapMarkers";
import { SidebarContentImageAccess } from "./Sidebar.Content.ImageAccess";

class SidebarContent extends EventEmitter {
  constructor(props) {
    super(props);

    this.editor = new EditorExperience();
    this.dom = null;
    this.contentType = null;
    this.contentProperties = {};
    this.objectProperties = {};

    this.initUI();

    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on("toggleMenuActive", this.onToggleMenuActive);
    // this.editor.on('toggleSceneGraphView', this.onToggleSceneGraphView);
  }

  initUI = () => {
    this.uiCont = new UIPanel();
    this.uiCont.setDisplay("none");

    //Set Title and Hide button!
    const uiHeaderRow = new UIRow().addClass("wsFlexRowCenter");
    const uiPanel = new UIRow();
    this.uiTitle = new UITextHeader();
    this.uiHelpIcon = new UIImage(HelpIcon, "25px", "25px", "help")
      .setStyle("marginLeft", "10px")
      .setStyle("display", "none")
      .onClick(this.openHelpModal);
    uiPanel.addChild(this.uiTitle);
    uiPanel.addChild(this.uiHelpIcon);
    uiHeaderRow.addChild(uiPanel);
    uiHeaderRow.addChild(
      new UIImage(CloseIcon, "24px", "24px", "close")
        .setStyle("transform", "rotate(180deg)")
        .onClick(() => this.editor.trigger("toggleMenuActive", [null]))
    );
    this.uiCont.addChild(uiHeaderRow);

    //For Images
    this.uiTabsImages = new SidebarContentImage();
    this.uiCont.addChild(this.uiTabsImages.dom);
    this.contentProperties["Images"] = this.uiTabsImages.dom;
    this.objectProperties["Images"] = this.uiTabsImages;

    //For Videos
    this.uiTabsVideos = new SidebarContentVideos();
    this.uiCont.addChild(this.uiTabsVideos.dom);
    this.contentProperties["Videos"] = this.uiTabsVideos.dom;
    this.objectProperties["Videos"] = this.uiTabsVideos;

    //For Text
    this.uiTextContainer = new SidebarContentText();
    this.uiCont.addChild(this.uiTextContainer.dom);
    this.contentProperties["Text"] = this.uiTextContainer.dom;
    this.objectProperties["Text"] = this.uiTextContainer;

    // For Hotspot
    this.uiHotspotConianter = new SidebarContentHotspot();
    this.uiCont.addChild(this.uiHotspotConianter.dom);
    this.contentProperties["Hotspot"] = this.uiHotspotConianter.dom;
    this.objectProperties["Hotspot"] = this.uiHotspotConianter;

    //For Audio
    this.uiAudioContainer = new SidebarContentAudio();
    this.uiCont.addChild(this.uiAudioContainer.dom);
    this.contentProperties["Audio"] = this.uiAudioContainer.dom;
    this.objectProperties["Audio"] = this.uiAudioContainer;

    //For Floorplans
    this.uiFloorplanMyContent = new SidebarContentFloorplan();
    this.uiCont.addChild(this.uiFloorplanMyContent.dom);
    this.contentProperties["Floorplan"] = this.uiFloorplanMyContent.dom;
    this.objectProperties["Floorplan"] = this.uiFloorplanMyContent;

    //For Models
    this.uiTabsModels = new UITabs([0, 1], ["Library", "My Uploads"], 0);
    const uiModelLibrary = new SidebarContent3DyModel();
    const uiModelMyContent = new SidebarContentModel();
    this.uiTabsModels.setComponents([uiModelLibrary.dom, uiModelMyContent.dom]);
    this.uiCont.addChild(this.uiTabsModels);
    this.contentProperties["3D Models"] = this.uiTabsModels;
    this.objectProperties["3D Models"] = [uiModelLibrary, uiModelMyContent];

    // Guided Tours
    this.uiGuidedTourContainer = new SidebarContentGuidedTour();
    this.uiCont.addChild(this.uiGuidedTourContainer.dom);
    this.contentProperties["Guided Tours"] = this.uiGuidedTourContainer.dom;
    this.objectProperties["Guided Tours"] = this.uiGuidedTourContainer;

    /* // Location Pins [ OLD LOCATION PINS ]
        this.uiLocationPins = new SidebarContentLocationPins();
        this.uiCont.addChild(this.uiLocationPins.dom);
        this.contentProperties['Location Pins'] = this.uiLocationPins.dom;
        this.objectProperties['Location Pins'] = this.uiLocationPins; */

    this.uiQRCodes = new SidebarContentQRCodes();
    this.uiCont.addChild(this.uiQRCodes.dom);
    this.contentProperties["QR Code Access Point"] = this.uiQRCodes.dom;
    this.objectProperties["QR Code Access Point"] = this.uiQRCodes;

    this.uiMapMarkers = new SidebarContentMapMarkers();
    this.uiCont.addChild(this.uiMapMarkers.dom);
    this.contentProperties["Map Markers"] = this.uiMapMarkers.dom;
    this.objectProperties["Map Markers"] = this.uiMapMarkers;

    this.uiImgAccessPts = new SidebarContentImageAccess();
    this.uiCont.addChild(this.uiImgAccessPts.dom);
    this.contentProperties["Image Access Point"] = this.uiImgAccessPts.dom;
    this.objectProperties["Image Access Point"] = this.uiImgAccessPts;

    this.dom = this.uiCont.dom;
  };

  openHelpModal = () => {
    let modalType = null;
    if (this.contentType === this.editor.strings.getKey("menubar/navigation")) {
      modalType = "Tour";
    } else if (
      this.contentType === this.editor.strings.getKey("menubar/waypoint")
    ) {
      modalType = "Location";
    }
    this.editor.onToggleInfoModals(modalType, true);
  };

  updateUITypes = (name) => {
    for (var property in this.contentProperties) {
      var uiEl = this.contentProperties[property];
      uiEl && uiEl.setDisplay(name === property ? "" : "none");
    }
  };

  updateUIData = (name) => {
    this.uiTitle.setTextContent(name);
    name === this.editor.strings.getKey("menubar/navigation") ||
    name === this.editor.strings.getKey("menubar/waypoint")
      ? this.uiHelpIcon.setStyle("display", "")
      : this.uiHelpIcon.setStyle("display", "none");
    for (var property in this.objectProperties) {
      let objects = this.objectProperties[property];
      if (objects) {
        if (name === property) {
          if (objects instanceof Array)
            objects.forEach((object) => object.refreshUI());
          else objects.refreshUI();
        } else {
          if (objects instanceof Array)
            objects.forEach((object) => object.unload());
          else objects.unload();
        }
      }
    }
  };

  onToggleMenuActive = (name) => {
    this.contentType = name;
    if (this.contentType in this.contentProperties) {
      this.uiCont.setDisplay("block");
      this.updateUITypes(name);
      this.updateUIData(name);
      this.editor.trigger("sidebarUpdateClassList", [true]);
    } else {
      this.uiCont.setDisplay("none");
      this.editor.trigger("sidebarSceneGraphChanged", [null]);
    }
  };

  onObjectSelected = (object) => {
    this.contentType !== null &&
      this.editor.trigger("toggleMenuActive", [null]);
  };

  onToggleSceneGraphView = (flag) => {
    if (flag) {
      this.uiCont.setDisplay("none");
      this.editor.trigger("sidebarSceneGraphChanged", [null]);
    }
  };
}

export { SidebarContent };
