import { Pagination, TextField, Box } from "@mui/material";
import { ChevronRight } from "react-feather";
import { useState } from "react";
import { isEmpty } from "lodash";

export default function RenderPagination({
  count,
  page,
  limit,
  handleChange,
  cb,
  totalResults,
}) {
  const [goto, setGoto] = useState("");

  const handleGoToChange = (e) => {
    let val = e.target.value
    setGoto(
      val < 0
        ? 1
        : val> Math.ceil(totalResults / limit)
        ? Math.ceil(totalResults / limit)
        : val
    );
  };

  return (
    <div className="d-flex justify-content-around mt-3">
      <div className="p-2">
        {`Results: ${(page - 1) * limit + 1} - ${
          page * limit > totalResults ? totalResults : page * limit
        } of ${totalResults}`}
      </div>
      <div className="p-2">
        <Pagination
          count={count}
          color="primary"
          page={parseInt(page)}
          onChange={handleChange}
        />
      </div>
      <div className="row d-flex align-items-center">
        <div className="col-4">Go to Page</div>
        <div className="col-4">
          <Box component="span" className="analyticshome__anCard--filterBox">
            <TextField
              autoComplete="off"
              value={goto}
              type={"number"}
              InputProps={{
                disabled: totalResults <= limit,
                inputProps: {
                  style: { textAlign: "center", width: "40px" },
                  min: 1,
                  max: Math.ceil(totalResults / limit),
                },
                disableUnderline: true,
              }}
              onChange={handleGoToChange}
              variant="standard"
            />
          </Box>
        </div>
        <div
          className="col-4"
          style={{ cursor: totalResults <= limit ? "default" : "pointer" }}
          onClick={() =>
            totalResults <= limit || isEmpty(goto) ? null : cb(parseInt(goto))
          }
        >
          Go
          <ChevronRight />
        </div>
      </div>
    </div>
  );
}
