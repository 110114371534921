import EventEmitter from "./EventEmitter";


export default class KeyEvents extends EventEmitter {
    constructor() {
        super();
        
        this.events = {
            default: 'DEFAULT_EVENT',
            z: 'UNDO_EVENT',
            y: 'REDO_EVENT',
            escape : 'ESC_EVENT'
        }

        window.addEventListener('keydown', (e) => {
            if(e) {
                const event = e.key.toLowerCase();
                let triggerParam = this.events.default;
                if(e.ctrlKey && event in this.events) {
                    triggerParam = this.events[event];
                    this.trigger('keydownEvent', [triggerParam])
                } else if(event in this.events) {
                    triggerParam = this.events[event];
                }
            }
        })
    }
    
}
