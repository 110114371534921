import * as THREE from 'three';
import EventEmitter from "../../utils/EventEmitter";

export default class TIme extends EventEmitter {

    constructor() {
        super();
        this.start = Date.now();
        this.current = this.start;
        this.clock = new THREE.Clock();
        this.intervalDelta = 0;
        this.interval = 1 / 30; // for 30 fps

        this.elapsed = 0;
        this.delta = 16;

        window.requestAnimationFrame(() => {
            this.tick()
        })
    }

    tick = () => {
        window.requestAnimationFrame(this.tick);
        this.intervalDelta += this.clock.getDelta();

        if(this.intervalDelta > this.interval) {
            const currentTime = Date.now();
            this.delta = currentTime - this.current;
            this.current = currentTime;
            this.elapsed = this.current - this.start;

            this.intervalDelta = this.intervalDelta % this.interval;

            this.trigger('tick');
        }
    }
    
}