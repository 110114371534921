import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Server from '../../../api';
import { HandleExceptionWithSecuredCatch } from "../../CombineCatch";
import { secondsToHms } from "../../../components/_utils/DateUtils";

const getUsers = createAsyncThunk('adminanalytics/getUsers', async ({ pageNumber, pageSize }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/list?limit=${pageSize}&page=${pageNumber}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const searchUsers = createAsyncThunk('adminanalytics/searchUsers', async (searchString, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/list?limit=10&page=1&q=${encodeURIComponent(searchString)}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getPlansList = createAsyncThunk('adminanalytics/getPlansList', async (_, { dispatch }) => {
    try {
        const response = await Server.get('/v1/plan', {
        });
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getAccountsList = createAsyncThunk('adminanalytics/getAccountsList', async ({pageNumber, pageSize}, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/combo?limit=${pageSize}&page=${pageNumber}`);
        return response.data.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const searchAccountsList = createAsyncThunk('adminanalytics/searchAccountsList', async (searchString, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/account/combo?limit=10&page=1&q=${encodeURIComponent(searchString)}`);
        return response.data.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getAccountOverview = createAsyncThunk('adminanalytics/getAccountOverview', async ({ fromDate, toDate }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/map/analytics?fromDate=${fromDate}&toDate=${toDate}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getAccountRegionSummary = createAsyncThunk('adminanalytics/getAccountRegionSummary', async ({ fromDate, toDate }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/admin/analytics/accountsRegionSummary?fromDate=${fromDate}&toDate=${toDate}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getAccessPointsSummary = createAsyncThunk('adminanalytics/getAccessPointsSummary', async ({ fromDate, toDate }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/admin/analytics/accessPointsActivationsSummary?fromDate=${fromDate}&toDate=${toDate}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getMapVisitsOverview = createAsyncThunk('adminanalytics/getMapVisitsOverview', async ({ fromDate, toDate }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/admin/analytics/mapVisitsOverview?fromDate=${fromDate}&toDate=${toDate}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getTopMapVisits = createAsyncThunk('adminanalytics/getTopMapVisits', async ({ fromDate, toDate, pageNumber, pageSize }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/admin/analytics/topMapVisits?fromDate=${fromDate}&toDate=${toDate}&limit=${pageSize}&page=${pageNumber}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getVisitorBehavior = createAsyncThunk('adminanalytics/getVisitorBehavior', async ({ fromDate, toDate }, { dispatch }) => {
    try {
        const response = await Server.get(`/v1/admin/analytics/visitorBehavior?fromDate=${fromDate}&toDate=${toDate}`);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const updateUserPlan = createAsyncThunk('adminanalytics/updateUserPlan', async (params, { dispatch }) => {
    try {
        const response = await Server.post('/v1/account/updateplan', params);
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

// Account Analytics!
const getUserAccountOverview = createAsyncThunk("adminanalytics/getUserAccountOverview", async ({ fromDate, toDate, accountId }, { dispatch }) => {
    try {
        const response = await Server.get(`v1/analytics/mapsoverview?fromDate=${fromDate}&toDate=${toDate}`, {
            headers: { accountId }
        });
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getUserAccountTopFiveVisited = createAsyncThunk("adminanalytics/getUserAccountTopFiveVisited", async ({ fromDate, toDate, accountId, limit }, { dispatch }) => {
    try {
        const response = await Server.get(`v1/analytics/mapvisits?fromDate=${fromDate}&toDate=${toDate}&limit=${limit}page=1`, {
            headers: { accountId }
        });
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getUserAccountAccessPointsSummary = createAsyncThunk("adminanalytics/getUserAccountAccessPointsSummary", async ({ fromDate, toDate, accountId }, { dispatch }) => {
    try {
        const response = await Server.get(`v1/analytics/accessPointsActivationsSummary?fromDate=${fromDate}&toDate=${toDate}`, {
            headers: { accountId }
        });
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getUserAccountMapVisitsOvertime = createAsyncThunk("adminanalytics/getUserAccountMapVisitsOvertime", async ({ fromDate, toDate, accountId }, { dispatch }) => {
    try {
        const response = await Server.get(`v1/analytics/mapVisitsOverTime?fromDate=${fromDate}&toDate=${toDate}`, {
            headers: { accountId }
        });
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const getUserAccountVisitorBehavior = createAsyncThunk("adminanalytics/getUserAccountVisitorBehavior", async ({ page, limit, accountId }, { dispatch }) => {
    try {
        const response = await Server.get(`v1/analytics/mapvisits/?page=${page}&limit=${limit}`, {
            headers: { accountId }
        });
        return response.data;
    } catch (e) {
        return dispatch(HandleExceptionWithSecuredCatch(e));
    }
});

const initialState = {
    users: [],
    searchedUsers: [],
    totalPages: null,
    analyticsLog: null,
    plansList: null,
    accountList: [],
    searchedAccountList: [],
    totalAccounts: null,
    activeAccount: null,
    accountOverview: null,
    regionSummary: null,
    accessPointsSummary: null,
    mapsVisitsOverview: null,
    topMapVisits: null,
    totalResults: null,
    accountVisitorBehavior: null,
    // admin analytics
    userAccountOverview: null,
    topFiveVisited: null,
    accountAccessPointsSummary: null,
    totalMapVisitsOvertime: null,
    userAccountVisitorBehavior: [],
    userAccountVisitorBehaviorMeta: null,
    userAccountVisitorBehaviorCSV: [],
};

const adminAnalyticsSlice = createSlice({
    name: 'adminanalytics',
    initialState,
    reducers: {
        clearSearchedUsers: (state, action) => {
            return { ...state, searchedUsers: [] }
        },
        clearLog: (state, action) => {
            return { ...state, analyticsLog: null }
        },
        setActiveAccount: (state, action) => {
            return {
                ...state,
                activeAccount: action.payload
            }
        },
        clearSearchedAccounts: (state, action) => {
            return { ...state, searchedAccountList: [] }
        },
    },
    extraReducers: {
        'common/resetState': () => {
            return { ...initialState }
        },
        [getAccountsList.fulfilled]: (state, { payload }) => {
            let accountList = [];
            if(payload.data && payload.data.length) {
                accountList = [
                    { id: 'allAccounts', accountName: 'All Accounts' },
                    ...payload?.data?.map((a) => ({
                        ...a,
                        accountName: `${a.name || '--'} (${a.email})`
                    }))
                ]
            } else {
                accountList = [ { id: 'allAccounts', accountName: 'All Accounts' } ]
            }
            return ({
                ...state,
                accountList,
                activeAccount: { id: 'allAccounts', accountName: 'All Accounts' },
                totalAccounts: payload.totalResults,
                searchedAccountList: [],
                analyticsLog: null
            })
        },
        [searchAccountsList.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                searchedAccountList: [
                    ...payload?.data?.map((a) => ({
                        ...a,
                        accountName: `${a.name || '--'} (${a.email})`
                    }))
                ],
                analyticsLog: null
            })
        },
        [getUsers.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                users: payload.data,
                totalPages: payload.totalPages,
                searchedUsers: [],
                analyticsLog: null
            })
        },
        [searchUsers.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                searchedUsers: payload.data,
                analyticsLog: null
            })
        },
        [updateUserPlan.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                analyticsLog: payload.status,
            })
        },
        [getPlansList.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                plansList: payload.data,
                analyticsLog: null
            })
        },
        [getAccountOverview.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                accountOverview: payload,
                analyticsLog: null
            })
        },
        [getAccountRegionSummary.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                regionSummary: payload?.length ? payload?.sort((a, b) => {
                    if(a.count > b.count) return -1;
                    if(a.count < b.count) return 1;
                    return 0;
                }) : [],
                analyticsLog: null
            })
        },
        [getAccessPointsSummary.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                accessPointsSummary: payload.data,
                analyticsLog: null
            })
        },
        [getMapVisitsOverview.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                mapsVisitsOverview: payload.data,
                analyticsLog: null
            })
        },
        [getTopMapVisits.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                topMapVisits: payload.data,
                totalResults: payload.totalResults,
                analyticsLog: null
            })
        },
        [getVisitorBehavior.fulfilled]: (state, { payload }) => {
            return ({
                ...state,
                accountVisitorBehavior: payload,
                analyticsLog: null
            })
        },
        [getUserAccountOverview.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                userAccountOverview: payload.data,
            };
        },
        [getUserAccountTopFiveVisited.fulfilled]: (state, { payload }) => {
            let topFiveVisited = payload?.data?.map((m, index) => ({
                rank: index + 1,
                mapName: m.mapName.charAt(0).toUpperCase() + m.mapName?.slice(1),
                mapVisits: m.visits,
                mapArea: m.mapArea > 0 ? Math.round(m.mapArea * 10.764) : "--",
                growth: m.growth === "-" ? "--" : m.growth,
                country: m.country 
                    ? m.country.split(" ").length > 1
                        ? m.country
                            .split(" ")
                            .reduce((a, c) => {
                                return a + c.charAt(0).toUpperCase() + c?.slice(1) + " ";
                            }, "")
                            ?.slice(0, -1)
                        : m.country.charAt(0).toUpperCase() + m.country?.slice(1)
                    : "--",
            }));
            return {
                ...state,
                topFiveVisited,
            };
        },
        [getUserAccountAccessPointsSummary.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                accountAccessPointsSummary: payload.data,
            };
        },
        [getUserAccountMapVisitsOvertime.fulfilled]: (state, { payload }) => {
            return {
                ...state,
                totalMapVisitsOvertime: payload.data,
            };
        },
        [getUserAccountVisitorBehavior.fulfilled]: (state, { payload, meta }) => {
            let data = payload?.data?.map((m) => ({
                mapName: m.mapName.charAt(0).toUpperCase() + m.mapName?.slice(1),
                publishedOn: m.publishedOn
                    ? `${new Date(m.publishedOn).toLocaleDateString()} ${new Date(
                        m.publishedOn
                    ).toLocaleString("en-US", {
                        hour: "numeric",
                        minute: "numeric",
                        hour12: true,
                    })}`
                    : "--",
                mapArea: m.mapArea > 0 ? Math.round(m.mapArea * 10.764) : "--",
                mapVisits: m.visits,
                avgVisitTime: m.average ? secondsToHms(m.average) : "--",
                totalVisitTime: m.totalDuration ? secondsToHms(m.totalDuration) : "--",
            }));
            if (meta.arg.isCSV) {
                let userAccountVisitorBehaviorCSV = { ...payload, data };
                return { ...state, userAccountVisitorBehaviorCSV };
            }
            if (payload.page === 1 && payload.limit === 1) {
                let userAccountVisitorBehaviorMeta = {};
                userAccountVisitorBehaviorMeta = payload.data[0] || {};
                return { ...state, userAccountVisitorBehaviorMeta };
            } else {
                let userAccountVisitorBehavior = { ...payload, data };
                return {
                    ...state,
                    userAccountVisitorBehavior,
                };
            }
        },
    }
});

export {
    getUsers,
    searchUsers,
    getPlansList,
    updateUserPlan,
    getAccountsList,
    searchAccountsList,
    getAccountOverview,
    getAccountRegionSummary,
    getAccessPointsSummary,
    getMapVisitsOverview,
    getTopMapVisits,
    getVisitorBehavior,
    getUserAccountOverview,
    getUserAccountTopFiveVisited,
    getUserAccountAccessPointsSummary,
    getUserAccountMapVisitsOvertime,
    getUserAccountVisitorBehavior,
};

export const { clearSearchedUsers, clearLog, setActiveAccount, clearSearchedAccounts } = adminAnalyticsSlice.actions;

export const userAccountVisitorBehaviorCSV = (state) => state?.adminanalytics?.userAccountVisitorBehaviorCSV;

export default adminAnalyticsSlice.reducer;