import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uniqBy, debounce } from "lodash";
//MUI COmponents
import { Box, Tabs, Tab } from "@mui/material";
import Loader from "../../../common/loader";
//Actions
import {
    getUserMapList,
    resetMapDetails,
    searchUserMaps,
    clearSearchedUserMaps,
    resetMapsState,
    getDefaultMapsDetails,
    defaultMaps,
} from "../../../../features/user/studio/studioSlice";
//Icons
import NoDataIllus from "../../../../assets/svgs/nodata.svg";
import PlayStore from "../../../../assets/icons/playstore.png";
import AppStore from "../../../../assets/icons/appstore.png";
import { Search, X } from "react-feather";
import MapCard from "../../../dashboard/users/studio/MapCard";

const SelectMapsImport = ({ selectedMaps, setSelectedMaps, isVenue }) => {
    const dispatch = useDispatch();
    const [pageNumber, setPagenumber] = useState(1);
    const [searchPageNumber, setSearchPageNumber] = useState(1);
    const [loading, setLoading] = useState(true);
    const [fetching, setFetching] = useState(false);
    const [activeKey, setActiveKey] = useState(0);
    const [searchText, setSearchText] = useState("");
    const [onFocusMapname, setOnFocusMapname] = useState(false);
    const [prevY, setPrevY] = useState(0);
    const filtersList = ["", "Published", "Draft"];
    const loadingRef = useRef(null);
    const defMaps = useSelector(defaultMaps);
    const pageSize = 10;

    let userMaps = useSelector((state) => state.studio.userMaps);
    let searchedUserMaps = useSelector(
        (state) => state.studio.searchedUserMaps
    );

    const [maps, setMaps] = useState(userMaps);
    const [searchedMaps, setSearchedMaps] = useState([]);
    const prevUserMaps = useRef([]);
    const prevSearchedUserMaps = useRef([]);
    const prevMaps = useRef([]);
    const options = {
        root: null,
        rootMargin: "0px",
        threshold: 1.0,
    };

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            const y = entries[0].boundingClientRect.y;
            if (prevY > y) {
                if (searchText.length > 0) {
                    if (searchedUserMaps.length >= pageSize) {
                        let curSPage = searchPageNumber + 1;
                        searchUserMapsNow(curSPage);
                    }
                } else if (userMaps.length >= pageSize) {
                    let curPage = pageNumber + 1;
                    setPagenumber(curPage);
                    fetchUserMaps(curPage);
                }
            }
            setPrevY(y);
        }, options);

        if (observer && loadingRef.current) {
            observer.observe(loadingRef.current);
        }
        if (searchedUserMaps?.length === 0) {
            setLoading(false);
        }
        return () => {
            if (loadingRef.current) {
                // eslint-disable-next-line
                observer.unobserve(loadingRef.current);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMaps, searchedUserMaps]);

    useEffect(() => {
        if ((userMaps.length === 0 || searchedUserMaps === 0) && fetching) {
            setFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMaps, searchedUserMaps]);

    useEffect(() => {
        if (searchText.length > 0) {
            prevMaps.current = maps;
        } else {
            prevMaps.current = maps;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMaps, searchedUserMaps]);

    useEffect(() => {
        fetchUserMaps(pageNumber);
        dispatch(resetMapDetails());
        dispatch(
            getDefaultMapsDetails(
                selectedMaps?.filter((m) => m.isChecked).map((m) => m.mapId)
            )
        );
        return () => {
            dispatch(resetMapsState());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (searchText.length < 3 && searchText.length !== 0) {
            setOnFocusMapname(true);
        }
        if (searchText.trim().length >= 3) {
            searchUserMapsNow(1);
            setOnFocusMapname(false);
        } else if (searchText.length <= 0) {
            setOnFocusMapname(false);
            fetchUserMaps(1);
            handleClearSearch();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchText]);

    useEffect(() => {
        if (userMaps.length > 0 && defMaps.length > 0) {
            setMaps(
                uniqBy(
                    [
                        ...defMaps,
                        ...[
                            ...(prevUserMaps?.current
                                ? prevUserMaps?.current
                                : []),
                            ...userMaps,
                        ],
                    ],
                    "mapId"
                )
            );

            setLoading(false);
            setFetching(false);
            prevUserMaps.current = userMaps;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userMaps, defMaps]);

    useEffect(() => {
        if (searchedUserMaps?.length > 0 || defMaps.length > 0) {
            setSearchedMaps(
                uniqBy(
                    [
                        ...defMaps,
                        ...[
                            ...(prevSearchedUserMaps?.current
                                ? prevSearchedUserMaps?.current
                                : []),
                            ...(searchedUserMaps ? searchedUserMaps : []),
                        ],
                    ],
                    "mapId"
                )
            );
            // }

            setLoading(false);
            setFetching(false);
        }
        prevSearchedUserMaps.current = searchedUserMaps;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchedUserMaps, defMaps]);

    useEffect(() => {
        if (pageNumber === 1 && prevUserMaps.current?.length <= 0) {
            dispatch(resetMapsState());
            setFetching(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, prevMaps]);

    useEffect(() => {
        fetchUserMaps(1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeKey]);

    const fetchUserMaps = (pageNumber, key) => {
        // fetch data
        var reqObj = {
            pageSize,
            pageNumber,
            mapFilter: filtersList[activeKey],
            mapsType: "map",
            isAssociated: true,
        };
        setFetching(true);
        dispatch(getUserMapList(reqObj));
    };

    const handleChange = (e, activeKey) => {
        if (searchText.length) {
            clearSearchedUserMaps();
        }
        setActiveKey(activeKey);
        setSearchText("");
        prevUserMaps.current = null;
        setPagenumber(1);
    };

    const handleSearchChange = (e) => {
        if (e.target.value.includes(" ") && e.target.value.length === 1) {
            e.target.value = e.target.value.replace(/\s/g, "");
        }
        setSearchText(e.target.value);
    };

    const searchUserMapsNow = (pageNumber) => {
        setLoading(pageNumber === 1 ? true : false);
        setSearchPageNumber(pageNumber);
        dispatch(
            debounce(
                searchUserMaps({
                    mapFilter: filtersList[activeKey],
                    searchText: searchText,
                    pageNumber,
                    mapsType: "map",
                    isAssociated: true,
                })
            ),
            250
        );
    };

    const handleClearSearch = () => {
        setLoading(true);
        setSearchedMaps([]);
        setSearchText("");
        setSearchPageNumber(1);
        clearSearchedUserMaps();
    };

    const a11yProps = (index) => {
        return {
            id: `mymaps-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const renderMapCard = (maps) => {
        return maps.map((map) => (
            <div key={map.mapId} className="studiohome__mapCardContainer">
                <MapCard
                    map={map}
                    isImport={true}
                    setSelectedMaps={setSelectedMaps}
                    isCheck={
                        defMaps?.some((m) => m.mapId === map.mapId) ||
                        selectedMaps?.some(
                            (m) => m.mapId === map.mapId && m.isChecked
                        )
                    }
                    isVenue={isVenue}
                    isDefault={defMaps.some((m) => m.mapId === map.mapId)}
                    defaultName={
                        selectedMaps.find((m) => m.mapId === map.mapId)?.name
                    }
                />
            </div>
        ));
    };

    const renderUserMaps = () => {
        if ((fetching && maps.length === 0) || loading) {
            return (
                <Loader
                    loaderText="Fetching Maps..."
                    height="100%"
                    width="100%"
                />
            );
        }
        if (maps && maps.length === 0) {
            return (
                <div className="illustration-container">
                    <img src={NoDataIllus} alt="no maps!" draggable={false} />
                    <h5>Looks like you don't have any maps yet.</h5>
                    <span>Create a new map with our ARway App</span>
                    <div className="illustration-container--iconDiv">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.nextechar.armaps"
                            target="_blank"
                            rel="noopener noreferrer"
                            id="link-toPlayStore"
                        >
                            <img
                                src={PlayStore}
                                alt="PlayStoreIcon"
                                draggable={false}
                            />
                        </a>
                        <a
                            href="https://apps.apple.com/us/app/aritize-maps/id1599443392"
                            target="_blank"
                            rel="noopener noreferrer"
                            id="link-toAppStore"
                        >
                            <img
                                src={AppStore}
                                alt="AppStoreIcon"
                                draggable={false}
                            />
                        </a>
                    </div>
                </div>
            );
        } else if (
            searchText.length >= 3 &&
            searchedMaps &&
            searchedMaps.length > 0
        ) {
            return renderMapCard(searchedMaps);
        } else if (
            searchText.length >= 3 &&
            (!searchedMaps || searchedMaps.length <= 0)
        ) {
            return (
                <div className="illustration-container">
                    <img src={NoDataIllus} alt="no maps!" draggable={false} />
                    <br />
                    <h5 id="h5-noMatch">No matches found.</h5>
                </div>
            );
        } else {
            return renderMapCard(maps);
        }
    };

    const loadingCSS = {
        height: "20px",
        margin: "10px",
    };
    const loadingCSSText = {
        display: loading ? "block" : "none",
        color: "#7c7c7c",
        fontSize: "1rem",
        letterSpacing: "1px",
    };

    var crossClass = "searchbar__closeIcon--hide";
    if (searchText.length > 0) {
        crossClass = "";
    }

    return (
        <div className="studiohome" style={{ height: "65vh" }}>
            <Box>
                <div className="d-flex align-items-center flex-column">
                    <h2>Select One or More Maps to Import</h2>
                </div>

                <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <Tabs
                            value={activeKey}
                            onChange={handleChange}
                            aria-label="Map Tabs"
                        >
                            <Tab
                                label="All"
                                {...a11yProps(0)}
                                disableRipple
                                className={`studiohome__shTabHeader ${
                                    activeKey === 0
                                        ? "studiohome__shTabHeader--active"
                                        : ""
                                }`}
                            />
                            <Tab
                                label="Published"
                                {...a11yProps(1)}
                                disableRipple
                                className={`studiohome__shTabHeader ${
                                    activeKey === 1
                                        ? "studiohome__shTabHeader--active"
                                        : ""
                                }`}
                            />
                            <Tab
                                label="Drafts"
                                {...a11yProps(2)}
                                disableRipple
                                className={`studiohome__shTabHeader ${
                                    activeKey === 2
                                        ? "studiohome__shTabHeader--active"
                                        : ""
                                }`}
                            />
                        </Tabs>
                    </Box>
                    <Box className="studiohome__mainRow">
                        <div className="searchbar" style={{ width: "40%" }}>
                            <span className="searchbar__searchbarContainer">
                                <Search
                                    size={20}
                                    color="#353E5A"
                                    className="searchbar__searchIcon"
                                />
                                <input
                                    type="text"
                                    autoComplete="off"
                                    placeholder="Search with Map Name"
                                    value={searchText}
                                    onChange={handleSearchChange}
                                    onKeyPress={(e) => {
                                        e.key === "Enter" && e.preventDefault();
                                    }}
                                    id="input-mapsSearch"
                                />
                                <X
                                    size={20}
                                    color="#353E5A"
                                    onClick={handleClearSearch}
                                    id="icon-clearSearch"
                                    className={`searchbar__closeIcon ${crossClass}`}
                                />
                            </span>
                        </div>
                        {searchText.length < 3 && onFocusMapname ? (
                            <span className="p-1" style={{ color: "red" }}>
                                Minimum three character required!
                            </span>
                        ) : null}
                        <div style={{ height: "40vh", overflow: "auto" }}>
                            {renderUserMaps()}
                            <div
                                className="studiohome__mapCardContainer"
                                ref={loadingRef}
                                style={{
                                    ...loadingCSS,
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <span style={loadingCSSText}>
                                    Fetching More Maps...
                                </span>
                            </div>
                        </div>
                    </Box>
                </Box>
            </Box>
        </div>
    );
    //   }
};

export default SelectMapsImport;
