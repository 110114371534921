import { detectBrowser } from "../../../_utils/detectBrowser";
import EventEmitter from "../../utils/EventEmitter";

// import UI Components
import {
    UIPanel,
    UIText
} from '../ui.lib';

class InfoPopup extends EventEmitter {

    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;
        // Since edge is built on chromium, we are adding "edge" to the list
        this.supportedBrowsers = ['firefox', 'chrome', 'edge'];

        this.initUI();

        this.editor.resources.on('ready', this.onReady);
        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('toggleSceneGraphView', this.onToggleSceneGraphView);
        this.editor.on('objectSelected', this.onObjectSelected);

    }

    initUI = () => {
        this.uiLoader = new UIPanel();
        this.uiLoader.addClass('wsInfoPopupStudio');
        // this.uiLoader.addClass('wsInfoPopupStudio--padding');

        this.uiText = new UIText('', 'For best user experience, please use Chrome, or Firefox browser!', true).addClass('wsInfoPopup--text');
        this.uiLoader.addChild(this.uiText);

        this.uiLoader.setDisplay('none');
        this.dom = this.uiLoader;
    }

    onReady = () => {
        // Compute Browser!
        const browser = detectBrowser();
        if(this.supportedBrowsers.indexOf(browser) === -1) {
            this.togglePopup(true);
        } else {
            this.togglePopup(false);
        }
    }

    togglePopup = (bFlag) => {
        if(bFlag) {
            this.dom.setDisplay('flex');
        } else {
            this.dom.setDisplay('none');
        }
    }

    onToggleMenuActive = (name) => {
        if(name) {
            this.uiLoader.addClass('wsInfoPopupStudio--paddingExtra')
        } else {
            this.uiLoader.removeClass('wsInfoPopupStudio--paddingExtra');
        }
    }

    onToggleSceneGraphView = (flag) => {
        if(flag) {
            this.uiLoader.addClass('wsInfoPopupStudio--padding')
        } else {
            this.uiLoader.removeClass('wsInfoPopupStudio--padding');
        }
    }

    onObjectSelected = (object) => {
        if(object) {
            this.uiLoader.addClass('wsInfoPopupStudio--paddingExtra')
        } else {
            this.uiLoader.removeClass('wsInfoPopupStudio--paddingExtra');
        }
    }
}

export { InfoPopup };