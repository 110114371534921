import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { HOME } from '../../_utils/routes';

const PageNotFound = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if(location.pathname === '/404' && !localStorage['BugFixed']) {
            localStorage.clear();
            localStorage.setItem('BugFixed', true)
            navigate(HOME)
        }
    }, [location.pathname, navigate ])
    return <div>PageNotFound</div>;
}

export default PageNotFound;
