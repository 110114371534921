import { Button, Input, Layout, Checkbox } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SvgIcn from '../../../assets/svgs/ARWay_Primary_Logo_Brand.svg';
import { deleteRegisterResponse, register, registerResponse } from "../../../features/auth/authSlice";

import {
    Eye,
    EyeOff
} from 'react-feather';

// Routes
import {
    LOGIN,
    VERIFY_EMAIL
} from '../../_utils/routes';


export const Register = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const registerData = useSelector(registerResponse)

    const [data, setData] = useState({name:null, email:null, password:null, confirmPassword: null})
    // const [nameErr, setNameErr] = useState()
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [emailErr, setEmailErr] = useState()
    const [passErr, setPassErr] = useState()
    const [confirmPassErr, setConfirmPassErr] = useState()
    const [tncCheckErr, setTncCheckErr] = useState(false)
    const [tncCheck, setTncCheck] = useState(false)
    const [errType, setErrType] = useState({
        spcharErr:false,
        spcharErrMsg:'Please input at least one special charachter',
        capLetterErr:false,
        capLetterErrMsg:'Please input at least one capital letter',
        numErr:false,
        numErrMsg:'Please input at least one Number',
        eightCharErr:false,
        eightCharErrMsg:'Please input at least 8 characters',
        lowercaseErr:false,
        lowercaseErrMsg:'Please input at least one lowercase letter',
        passMissMatchErr: false,
        passMissMatchErrMsg: 'Passwords doesn\'t match'
    })
    const [typing, setTyping] = useState({/* name:false, company:false, */ email:false, password:false, confirmPassword: false})

    
    const  spCharCheck = /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
    const capLetterCheck = /^(?=.*[A-Z])/; 
    const numErrCheck = /^(?=.*[0-9])/
    const eightCharErrCheck = /^(?=.{8,}$)/
    const lowercaseErrCheck = /^(?=.*[a-z])/

    useEffect(() => {

        if(registerData&& registerData.code>299){
            alert(registerData?.message)
        }
        if(registerData && (!registerData.code)){
            navigate(VERIFY_EMAIL)
        }
        return()=>{
            dispatch(deleteRegisterResponse())
        }
    },[registerData, navigate, dispatch])

    const handleSubmit = (event) => {

        var toRegister = true;
        
        if(!data.email){
            setEmailErr(true)
            toRegister = false;
        } else {
            setEmailErr(false)
        }
        /* if(!data.name){
            setNameErr(true)
            toRegister = false;
        } else {
            setNameErr(false)
        } */
        if(!data.password){
            setPassErr(true)
            toRegister = false;
        } else {
            setPassErr(false)
        }
        if(!data.confirmPassword){
            setConfirmPassErr(true)
            toRegister = false;
        } else {
            setConfirmPassErr(false)
        }
        if(tncCheck===false || tncCheck===undefined){
            setTncCheckErr(true)
            toRegister = false;
        } else {
            setTncCheckErr(false)
        }
        event.preventDefault()
        if(spCharCheck.test(data.password)===false){
            setErrType(prev => ({...prev, spcharErr:true}))
            toRegister = false;
        } else {
            setErrType(prev => ({...prev, spcharErr:false}))
        }
        if((capLetterCheck.test(data.password))===false){
            setErrType(prev => ({...prev, capLetterErr:true}))
            toRegister = false;
        } else {
            setErrType(prev => ({...prev, capLetterErr:false}))
        }
        if(numErrCheck.test(data.password)===false){
            setErrType(prev => ({...prev, numErr:true}))
            toRegister = false;
        } else {
            setErrType(prev => ({...prev, numErr:false}))
        }
        if(eightCharErrCheck.test(data.password)===false){
            setErrType(prev => ({...prev, eightCharErr:true}))
            toRegister = false;
        } else {
            setErrType(prev => ({...prev, eightCharErr:false}))
        }
        if(lowercaseErrCheck.test(data.password)===false){
            setErrType(prev => ({...prev, lowercaseErr:true}))
            toRegister = false;
        } else {
            setErrType(prev => ({...prev, lowercaseErr:false}))
        }

        if(data.password !== data.confirmPassword) {
            setErrType(prev => ({...prev, passMissMatchErr:true}))
            toRegister = false;
        } else {
            setErrType(prev => ({...prev, passMissMatchErr:false}))
        }

        if(toRegister && emailErr === false && passErr === false && confirmPassErr === false && tncCheckErr === false && 
            errType.lowercaseErr === false && errType.capLetterErr === false && errType.eightCharErr === false && errType.spcharErr === false && errType.numErr === false
            && errType.passMissMatchErr === false){
                dispatch(register({
                        name: null,
                        email: data.email,
                        password: data.password,
                        company: null,
                        acceptedLicense:true
                    }))
                }
        }

    return(
        <Layout className="main">
            <Layout className="form-containers register-form">
                <img className="brand-logo" alt="brand-logo-3" src={SvgIcn} />
                <h5 className="login-header" id='h5-register'>Create an Account</h5>
                <div className="signupLink">
                    <span className="signupLink--spanText">Already have an account?</span>
                    <Link className="atag" to={LOGIN}>Log In.</Link>
                </div>
                <form
                    onSubmit={handleSubmit}
                >
                        {/* <Layout className="input-label-container">
                            {typing.name?(<label className="typing-label">Full Name</label>):null}
                            <Input 
                                id='name' 
                                onChange={(e => {
                                    setData(prev => ({...prev, name: e.target.value}))
                                    setNameErr(false)
                                })} 
                                className={` text-inputs ${nameErr ? `error` : null }`} 
                                placeholder="Full Name" 
                                onFocus={() => {
                                    setTyping({name:true})
                                }}
                                onBlur={() => {
                                    setTyping({name:false})
                                }}
                            />
                            {nameErr ? (<label className='errors'>Please enter full name</label>) : null}
                        </Layout> */}
                        {/* <Layout className="input-label-container">
                            {typing.company?(<label className="typing-label">Company</label>):null}
                            <Input 
                                onChange={(e => setData(prev => ({...prev,company:e.target.value})))} 
                                placeholder="Company (optional)"
                                onFocus={() => {
                                    setTyping({company:true})
                                }}
                                onBlur={() => {
                                    setTyping({company:false})
                                }} 
                            />
                        </Layout> */}
                        <Layout className="input-label-container">
                            {typing.email?(<label className="typing-label">Email</label>):null}
                            <Input 
                                type='email' 
                                onChange={(e => {
                                        setData(prev => ({...prev, email: e.target.value}))
                                        setEmailErr(false)
                                    })} className={`text-inputs ${emailErr ? `error` : null }`} placeholder="Email"
                                onFocus={() => {
                                    setTyping({email:true})
                                }}
                                onBlur={() => {
                                    setTyping({email:false})
                                }}
                                id="registerEmailInput"      
                            />
                            {emailErr ? (<label className='errors' id='error-registerEmail'>Please enter a valid email</label>) : null}
                        </Layout>
                        <Layout className="input-label-container">
                            {typing.password?(<label className="typing-label">Create Password</label>):null}
                            <Input 
                                onChange={(e => {
                                        setData(prev => ({...prev, password: e.target.value}))
                                        setPassErr(false)
                                    })} 
                                className={`text-inputs ${passErr ? `error` : null }`} 
                                type={showPassword ? "text" : "password"} 
                                placeholder="Create Password"
                                onFocus={() => {
                                    setTyping({password:true})
                                }}
                                onBlur={() => {
                                    setTyping({password:false})
                                }}
                                id="registerPasswordInput"
                            />
                            <span className="showpassCont">
                                {showPassword ? 
                                    <EyeOff strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowPassword(false)} id="button-passwordEyeOff"/> : 
                                    <Eye strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowPassword(true)} id="button-passwordEye"/>
                                }
                            </span>
                            <label className='errors' id='error-registerPass'>
                                {passErr ? 'Please Enter password ' :
                                    [errType.numErr ? errType.numErrMsg : [
                                        errType.eightCharErr ? errType.eightCharErrMsg : [
                                            errType.lowercaseErr ? errType.lowercaseErrMsg : [
                                                (errType.spcharErr)?errType.spcharErrMsg: [errType.capLetterErr ? errType.capLetterErrMsg : null
                                            ]
                                        ]
                                    ]]
                                ]}
                            </label>
                        </Layout>
                        <Layout className="input-label-container">
                            {typing.confirmPassword?(<label className="typing-label">Confirm Password</label>):null}
                            <Input 
                                onChange={(e => {
                                        setData(prev => ({...prev, confirmPassword: e.target.value}))
                                        setConfirmPassErr(false)
                                    })} 
                                className={`text-inputs ${confirmPassErr ? `error` : null }`} 
                                type={showConfirmPassword ? "text" : "password"} 
                                placeholder="Confirm Password"
                                onFocus={() => {
                                    setTyping({confirmPassword:true})
                                }}
                                onBlur={() => {
                                    setTyping({confirmPassword:false})
                                }}
                                id="registerConfirmPasswordInput"
                            />
                            <span className="showpassCont">
                                {showConfirmPassword ? 
                                    <EyeOff strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowConfirmPassword(false)} id="button-confirmEyeOff"/> : 
                                    <Eye strokeWidth={2} size={16} color={'#353E5A'} onClick={() => setShowConfirmPassword(true)} id="button-confirmEye"/>
                                }
                            </span>
                                <label className='errors' id='error-registerConfirmPass'>
                                    {confirmPassErr ? 'Please Confirm Password ' : [ errType.passMissMatchErr ? errType.passMissMatchErrMsg : null ] }
                                </label>
                        </Layout>
                        <Layout className={tncCheckErr ? `checkbox-container error-checkbox` : `checkbox-container`}>
                            <Checkbox className="checkboxInput" onChange={(e => {
                                    setTncCheck(prev => !prev)
                                })} value={tncCheck} type='checkbox' id="registerTermsCheckbox" />
                            <span className="checkbox-guide">
                                By signing up, you are agreeing to Nextech's <a className="atag" href="https://www.nextechar.com/ar-solutions-terms" target="_blank" rel="noopener noreferrer">terms and conditions.</a>
                                {tncCheckErr ? (<label className='terms-error'>Please accept Terms and Conditions</label>) : null}
                            </span>
                            
                        </Layout>
                        <Button className="signin-button create-account" type="primary" htmlType="submit" id='button-register'>
                            <span className="signin-button--spanText">Create Account</span>
                        </Button>
                </form>
            </Layout>
        </Layout>

    )
}