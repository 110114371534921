import EventEmitter from "../../utils/EventEmitter";
import TranslateIcon from "../../../../assets/svgs/scenes/translate_w.svg";
import AddIcon from "../../../../assets/svgs/scenes/menu/pen_add.svg";
import RemoveIcon from "../../../../assets/svgs/scenes/menu/pen_remove.svg";
import EditIcon from "../../../../assets/svgs/scenes/edit_w.svg";

class FloatingMenuActionGuidedTour extends EventEmitter {
  constructor(editor) {
    super();

    this.editor = editor;
    this.dom = null;

    this.initUI();

    this.editor.on("objectSelected", this.onObjectSelected);
    this.editor.on("transformModeChanged", this.onTransformModeChanged);
    this.editor.on("nodeOperationsSelected", this.onNodeOperationsSelected);
    this.editor.on("editSidebarObjectContents", this.onEditSidebarObjectContents);
    this.editor.on("clickedInVoidWhileOperationOnNode", this.onClickedInVoidWhileOperationOnNode);
  }

  initUI = () => {
    this.uiTranslateContainer = document.createElement("div");
    this.uiTranslateContainer.className = "wsFloatingMenuBtn";
    this.uiTranslateContainer.addEventListener("click", () => {
      // Translate Clicked
      this.editor.trigger("transformModeChanged", ["translate", "Position"]);
      // this.editor.trigger("nodeOperationsSelected");

    });

    var translateIcon = document.createElement("img");
    translateIcon.classList.add("wsFloatingMenuIcon");
    translateIcon.title = "Translate";
    translateIcon.id = "img_Translate";
    translateIcon.src = TranslateIcon;
    this.uiTranslateContainer.appendChild(translateIcon);

    this.uiAddNodeContainer = document.createElement("div");
    this.uiAddNodeContainer.classList.add("wsFloatingMenuBtn");
    this.uiAddNodeContainer.addEventListener("click", () => {
      this.editor.trigger("nodeOperationsSelected", ["addNode"]);
    });

    var addNodeIcon = document.createElement("img");
    addNodeIcon.classList.add("wsFloatingMenuIcon");
    addNodeIcon.title = "Add Node";
    addNodeIcon.id = "img_AddNode";
    addNodeIcon.src = AddIcon;
    this.uiAddNodeContainer.appendChild(addNodeIcon);

    this.uiRemoveNodeContainer = document.createElement("div");
    this.uiRemoveNodeContainer.classList.add("wsFloatingMenuBtn");
    this.uiRemoveNodeContainer.addEventListener("click", () => {
      this.editor.trigger("nodeOperationsSelected", ["removeNode"]);
    });

    var removeNodeIcon = document.createElement("img");
    removeNodeIcon.classList.add("wsFloatingMenuIcon");
    removeNodeIcon.title = "Remove Node";
    removeNodeIcon.id = "img_RemoveNode";
    removeNodeIcon.src = RemoveIcon;
    this.uiRemoveNodeContainer.appendChild(removeNodeIcon);

    this.uiEditContainer = document.createElement("div");
    this.uiEditContainer.classList.add("wsFloatingMenuBtn");
    this.uiEditContainer.addEventListener("click", () => {
      this.editor.trigger("transformModeChanged", ["edit", ""]);
      this.editor.trigger("sidebarSceneGraphChanged", [
        this.editor.selectedObject,
      ]);
      this.editor.trigger("editSidebarObjectContents", [
        this.editor.selectedObject,
      ]);
    });

    var editIcon = document.createElement("img");
    editIcon.classList.add("wsFloatingMenuIcon");
    editIcon.title = "Edit";
    editIcon.id = "img_Edit";
    editIcon.src = EditIcon;
    this.uiEditContainer.appendChild(editIcon);

    const uiActionRowAllContents = document.createElement("div");
    uiActionRowAllContents.classList.add("wsFloatingMenuActionButtons");
    uiActionRowAllContents.appendChild(this.uiTranslateContainer);
    uiActionRowAllContents.appendChild(this.uiAddNodeContainer);
    uiActionRowAllContents.appendChild(this.uiRemoveNodeContainer);
    uiActionRowAllContents.appendChild(this.uiEditContainer);

    this.dom = uiActionRowAllContents;
  };

  onObjectSelected = (object) => {
    if (object && object.userData && "transform" in object.userData) {
      this.editor.trigger("transformModeChanged", ["translate", "Position"]);
    }
  };

  onTransformModeChanged = (transformMode) => {
    this.clearClassList();
    
    switch (transformMode) {
      case "translate":
        this.uiTranslateContainer.classList.add("wsFloatingMenuSelected");
        break;
      case "edit":
        this.uiEditContainer.classList.add("wsFloatingMenuSelected");
        break;
      default:
        break;
    }
  };

  onEditSidebarObjectContents = () => {
    this.clearClassList();
    this.uiEditContainer.classList.add("wsFloatingMenuSelected");
  }

  onNodeOperationsSelected = (nodeOperationType) => {
    this.clearClassList();

    switch (nodeOperationType) {
      case "addNode":
        this.uiAddNodeContainer.classList.add("wsFloatingMenuSelected");
        document.body.classList.add('wsAdd_cursor');
        break;

      case "removeNode":
        this.uiRemoveNodeContainer.classList.add("wsFloatingMenuSelected");
        document.body.classList.add('wsRemove_cursor');
        break;

      default:
        break;
    }
  }

  onClickedInVoidWhileOperationOnNode = () => {
    this.clearClassList();
  }

  clearClassList = () => {
    this.uiTranslateContainer.classList.remove("wsFloatingMenuSelected");
    this.uiAddNodeContainer.classList.remove("wsFloatingMenuSelected");
    this.uiRemoveNodeContainer.classList.remove("wsFloatingMenuSelected");
    this.uiEditContainer.classList.remove("wsFloatingMenuSelected");

    document.body.classList.remove('wsAdd_cursor');
    document.body.classList.remove('wsRemove_cursor');
  }

  setDisplay = (value) => {
    this.dom.style.display = value;
  };

  setActionButtonStyle = (object) => {
    if(object && object.userData && "isCreatedFromApp" in object.userData && object.userData.isCreatedFromApp === true) {
      this.uiTranslateContainer.classList.add("wsFloatingMenuDisabled");
      this.uiAddNodeContainer.classList.add("wsFloatingMenuDisabled");
      this.uiRemoveNodeContainer.classList.add("wsFloatingMenuDisabled");
    } else {
      this.uiTranslateContainer.classList.remove("wsFloatingMenuDisabled");
      this.uiAddNodeContainer.classList.remove("wsFloatingMenuDisabled");
      this.uiRemoveNodeContainer.classList.remove("wsFloatingMenuDisabled");
    }
  }
}

export { FloatingMenuActionGuidedTour };
