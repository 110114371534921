import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dropdown from "./Dropdown";
import { isEmpty } from "lodash";
import MapThumbnail from "../../../../../assets/pngs/mapdetails/mapthumbnail.png";
import ARWayImage from "../../../../common/CO/ARWayImage";
import DndIcn from "../../../../../assets/svgs/studio/importMaps/dnd.svg";

// import {
//   SortableContext,
//   verticalListSortingStrategy,
//   sortableKeyboardCoordinates,
//   arrayMove,
// } from "@dnd-kit/sortable";
import { MapsContext } from "../ImportMaps";
// import { closestCenter, DndContext } from "@dnd-kit/core";
// import {
//   MouseSensor,
//   useSensor,
//   useSensors,
//   KeyboardSensor,
//   PointerSensor,
// } from "@dnd-kit/core";
import { Draggable, Droppable, DragDropContext } from "react-beautiful-dnd";

// import MapLayerrow from "./MapLayerrow";
// import MapName from "./Table/TextField";
import TextField from "./Table/TextField";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#353E5A",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const Nlist = [
  { value: "levels", label: "Levels" },
  { value: "floors", label: "Floors" },
  { value: "zones", label: "Zones" },
  { value: "areas", label: "Areas" },
  { value: "sections", label: "Sections" },
  { value: "parking", label: "Parking" },
];

function createData(order, name, thumbnailImg, type, identifier) {
  return { order, name, thumbnailImg, type, identifier };
}

export default function MapLayersTable({ setMapsLayers, maps }) {
  const createTable = (data) => {
    return data.map((map, i) => {
      return createData(
        map.order,
        <TextField
          name={map.name}
          key={map.mapId}
          setParent={(name) => {
            setSelectedM(
              selectedM.map((m) => {
                if (m.order === map.order) {
                  return { ...m, name };
                } else {
                  return m;
                }
              })
            );
          }}
          maxLength={30}
        />,
        map.thumbnailImg,
        <Dropdown
          list={Nlist}
          key={map.mapId}
          defaultVal={map.type}
          setvalue={(type) => {
            setSelectedM(
              selectedM.map((m) => {
                if (m.order === map.order) {
                  return { ...m, type };
                } else {
                  return m;
                }
              })
            );
          }}
          venueCreate={true}
        />,
        <TextField
          name={
            isEmpty(map.identifier) ? map.order.toString() : map.identifier
          }
          key={map.mapId}
          setParent={(identifier) => {
            setSelectedM(
              selectedM.map((m) => {
                if (m.order === map.order) {
                  return { ...m, identifier };
                } else {
                  return m;
                }
              })
            );
          }}
          maxLength={3}
        />
      );
    });
  };

  const { selectedMaps } = React.useContext(MapsContext);
  const [selectedM, setSelectedM] = React.useState(
    selectedMaps
      .filter((map) => map.isChecked)
      .map((m, i) => ({
        ...m,
        order: i + 1,
        type: maps?.find((mp) => mp.mapId === m.mapId)
          ? maps.find((mp) => mp.mapId === m.mapId)["mapNamingType"]
          : Nlist[0].value,
        identifier: maps?.find((mp) => mp.mapId === m.mapId)
          ? maps.find((mp) => mp.mapId === m.mapId)["identifier"]
          : (i + 1).toString(),
        thumbnailImg: maps?.find((mp) => mp.mapId === m.mapId)
          ? maps.find((mp) => mp.mapId === m.mapId)["thumbnailImg"]
            ? maps.find((mp) => mp.mapId === m.mapId)["thumbnailImg"]
            : maps.find((mp) => mp.mapId === m.mapId)["thumbnailImage"]
          : "",
      }))
      .sort((a, b) => a["order"] - b["order"])
  );
  // console.log(maps,selectedM,selectedMaps);
  // console.log(selectedMaps.map(m=>{
  //   return{type:maps?.find(mp=>mp.mapId===m.mapId)?maps.find(mp=>mp.mapId===m.mapId)['mapNamingType']:Nlist[0].value}
  // }),maps)

  const [rows, setRows] = React.useState(createTable(selectedM));

  React.useEffect(() => {
    setMapsLayers(selectedM);
    setRows(createTable(selectedM));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedM]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    let movedItems = reorder(
      selectedM,
      result.source.index,
      result.destination.index
    );
    let rows = movedItems.map((m, i) => ({
      ...m,
      order: i + 1,
      thumbnailImg: m.thumbnailImg,
      type: m.type,
      name: m.name,
      identifier: m.identifier,
    }));
    setSelectedM(rows);
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>ORDER</StyledTableCell>
            <StyledTableCell>NAME</StyledTableCell>
            <StyledTableCell>TYPE</StyledTableCell>
            <StyledTableCell>IDENTIFIER</StyledTableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided) => (
              <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                {/* <DndContext
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              sensors={sensors}
            >
              <SortableContext
                items={rows}
                strategy={verticalListSortingStrategy}
              > */}
                {rows.map((row, index) => (
                  <Draggable
                    draggableId={"r-" + row.order}
                    index={index}
                    key={row.order}
                  >
                    {(provided) => (
                      <StyledTableRow
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <StyledTableCell component="th" scope="row">
                          <div className="d-flex">
                            <img
                              src={DndIcn}
                              {...provided.dragHandleProps}
                              style={{ paddingRight: "10px" }}
                              alt="dndIcon"
                            />
                            {/* <div >asd</div> */}
                            {row.order}
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>
                          <div className="d-flex" style={{ width: "200px" }}>
                            <ARWayImage
                              src={
                                row.thumbnailImg || MapThumbnail
                              }
                              height={"50px"}
                              width={"60px"}
                              alt="MapImg"
                            />
                            <div className="pt-3 ps-3">{row.name}</div>
                          </div>
                        </StyledTableCell>
                        <StyledTableCell>{row.type}</StyledTableCell>
                        <StyledTableCell>{row.identifier}</StyledTableCell>
                      </StyledTableRow>
                    )}
                  </Draggable>
                ))}
                {/* </SortableContext>
            </DndContext> */}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
}
