// import * as THREE from 'three';
import EventEmitter from "../../utils/EventEmitter";
import { UIPanel, UIImage, UITooltip } from "../ui.lib";

//Icon
import NavIcon from '../../../../assets/svgs/scenes/menu/connector_pin.svg';

class MenubarConnectorPin extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.strings = this.editor.strings;
        this.dom = null;
        this.name = 'Connector Pins';
        this.isActive = false;
        this.editor.on('toggleMenuActive', this.onToggleMenuActive);
        this.editor.on('refreshUI', this.onRefreshUI);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('wsMenuContainer');
        this.uiCont.setId('wsMenuLocationPin');
        
        this.uiText = new UITooltip(this.strings.getKey('menubar/connector'));
        this.uiImg = new UIImage(NavIcon, '28px', '28px', this.strings.getKey('menubar/connector'));

        this.uiCont.addChild(this.uiImg);
        this.uiCont.addChild(this.uiText);

        this.uiCont.onClick(() => {
            this.makeActive();
        })

        this.dom = this.uiCont.dom;
    }

    makeActive = () => {
        if(!this.isActive) {
            this.isActive = true;
            this.uiCont.addClass('wsMenuContainer--active');        
        }
        this.editor.trigger('toggleMenuActive', [this.name]);
        // check if map has floorplan. 
        if(this.isActive) {
            const raycastObjects = [];
            this.editor.scene.children.forEach((child) => {
                if(child.userData && child.userData.navHelper &&  child.userData.navHelper === 'floorplan') {
                    raycastObjects.push(child)
                }
            });
            if(raycastObjects.length > 0) {
                let canOpen = false;
                raycastObjects.forEach(obj => obj.userData?.alignmentStatus === true && (canOpen = true))
                if(canOpen) {
                    this.editor.trigger('2DEditorSceneGraphChanged', [this.name]);
                } else {
                    // this.editor.trigger('2DEditorSceneGraphChanged', [this.name]);
                    this.editor.callbacks.generateAlert({
                        msg: "Align floor plan to edit paths & connector pins.",
                        alertType: "information"
                    })
                }
            } else {
                this.editor.callbacks.generateAlert({
                    msg: "Add floor plan in map to edit paths & connector pins.",
                    alertType: "information"
                })
            }
            
        }
    }

    onRefreshUI = () => {
        if(this.editor.isFloorMap) {
            this.uiCont.setDisplay('none');
        } else {
            this.uiCont.setDisplay('');
        }
    }

    onToggleMenuActive = (name) => {
        if(name !== this.name) {
            this.isActive = false;
            this.uiCont.removeClass('wsMenuContainer--active');
        }
    }
}

export { MenubarConnectorPin };