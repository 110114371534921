import formatDistance from 'date-fns/formatDistance';

const weeksdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
const monthsArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const monthsArrayShort = ["Jan", "Feb", "March", "April", "May", "June", "July", "Aug", "Sept", "Oct", "Nov", "Dec"];


const formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;    
}

const convertToLongDate = (time) =>{
    var d = new Date( typeof(time) === 'string' ?  time.replace(/\s/, 'T') : time);
    var timeVisited = formatAMPM(d);
    var day = weeksdays[d.getDay()-1];
    return `${timeVisited} • ${day} • ${d.getDate() < 10 ? "0"+ d.getDate() : d.getDate()} ${monthsArray[d.getMonth()]} ${d.getFullYear()}`    
}

const convertToShortDate = (time) => {
    var d = new Date( typeof(time) === 'string' ?  time.replace(/\s/, 'T') : time);
    var timeVisited = formatAMPM(d);
    return `${timeVisited} • ${d.getDate() < 10 ? "0"+ d.getDate() : d.getDate()} - ${d.getMonth()+1} - ${d.getFullYear()}`
}

const convertToShortDateNS = (time) => {
    var d = new Date( typeof(time) === 'string' ?  time.replace(/\s/, 'T') : time);
    var timeVisited = formatAMPM(d);
    return `${timeVisited} • ${d.getDate() < 10 ? "0"+ d.getDate() : d.getDate()}-${d.getMonth()+1}-${d.getFullYear()}`
}

const convertToNamedDate = (time) => {
    var d = new Date( typeof(time) === 'string' ?  time.replace(/\s/, 'T') : time);
    return `${monthsArray[d.getMonth()]} ${d.getDate() < 10 ? "0"+ d.getDate() : d.getDate()} ${d.getFullYear()}`
}

const convertToDate = (time, separator = '/') => {
    var d = new Date( typeof(time) === 'string' ?  time.replace(/\s/, 'T') : time);
    return `${d.getDate() < 10 ? "0"+ d.getDate() : d.getDate()}/${d.getMonth()+1}/${d.getFullYear()}`
}

const convertToShortMonthStringDate = (time, separator = '/') => {
    var d = new Date( typeof(time) === 'string' ?  time.replace(/\s/, 'T') : time);
    return `${monthsArrayShort[d.getMonth()]}, ${d.getDate() < 10 ? "0"+ d.getDate() : d.getDate()}, ${d.getFullYear()}`
}

const convertStringToDateObj = (dtStr) => {
    if (!dtStr) return null
    let dateParts = dtStr.split("/");
    let timeParts = dateParts[2].split(" ")[1].split(":");
    dateParts[2] = dateParts[2].split(" ")[0];
    return new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0], timeParts[0], timeParts[1]);
}

const convertInDuration = (time) => {
    var d = new Date( typeof(time) === 'string' ?  time.replace(/\s/, 'T') : time);
    return formatDistance(d, new Date(), {includeSeconds: true})
}

export {
    convertToLongDate,
    convertToShortDate,
    convertToShortDateNS,
    convertStringToDateObj,
    convertToNamedDate,
    convertInDuration,
    convertToDate,
    convertToShortMonthStringDate
};