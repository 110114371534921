import EventEmitter from "../../../utils/EventEmitter";
import { 
    UIPanel, 
    UIImage, 
    UIText, 
    UI2dMenuViewCard, 
    UISeparator, 
    UIRow 
} from "../../../ui/ui.lib";

//Icon
import Chevron from '../../../../../assets/svgs/scenes/chevron.svg';
import { ViewOptions2D } from "./ViewHelper";

import { SetVisible } from '../../../commands/SetVisible';

class MenuView extends EventEmitter {
    constructor(editor) {
        super();

        this.editor = editor;
        this.dom = null;
        this.name = 'View';
        this.showViewPopup = false;

        this.viewOptions = new ViewOptions2D();

        this.editor.resources.on('ready', this.onReady);
        this.editor.on('objectAdded', this.onReady);

        this.initUI();
    }

    initUI = () => {

        this.uiCont = new UIPanel();
        this.uiCont.addClass('ws2DMenuContainer');
        this.uiCont.setId('ws2DMenuView');

        const uiViewCont = new UIRow().onClick(() => this.toggleShowViewPopup());
        
        this.uiText = new UIText('100%', this.name)
        this.uiImg = new UIImage(Chevron, '24px', '24px', this.name);
        
        uiViewCont.addChild(this.uiText);
        uiViewCont.addChild(this.uiImg);
        this.uiCont.addChild(uiViewCont);

        // view UI!
        this.uiViewContainer = new UIPanel();
        this.uiViewContainer.addClass('wsViewPanelContainer');

        const uiVardCont = new UIPanel().addClass('wsViewPanelContainer--cardCont');

        this.viewOptions.toArray().forEach((view) => {
            const uiOptnRow = new UI2dMenuViewCard(this.editor, view, this.handleStateChange);
            this.viewOptions.getBreakIds().indexOf(view.id) !== -1 && uiVardCont.addChild(new UISeparator('wsViewPanelContainer--verSeprator'));
            uiVardCont.addChild(uiOptnRow);
        })
        
        this.uiViewContainer.addChild(uiVardCont);
        this.uiViewContainer.setDisplay('none');
        this.uiCont.addChild(this.uiViewContainer);

        this.dom = this.uiCont.dom;
    }

    handleStateChange = (id) => {
        this.viewOptions.setState(id);
        this.initSetViewCommand(id);
    }

    initSetViewCommand = (id) => {
        const option = this.viewOptions.getOption(id);
        this.editor.scene.children.forEach(object => {
            if(object.userData && object.userData.viewType === option.type) {
                this.editor.onCommand(new SetVisible(this.editor, object, option.state, true, true, true), 'VIEW_OPTION');
            }
        });
    }

    checkParent(parent, child) {
        let node = child.parentNode;
        // keep iterating unless null
        while (node !== null) {
            if (node === parent) {
                return true;
            }
            node = node.parentNode;
        }
        return false;
    }

    handleClick = (e) => { !this.checkParent(this.uiCont.dom, e.target) && this.toggleShowViewPopup() }

    toggleShowViewPopup = () => {
        this.showViewPopup = !this.showViewPopup;
        if(this.showViewPopup) {
            this.uiViewContainer.setDisplay('block');
            this.uiCont.addClass('ws2DMenuContainer--active');
            window.addEventListener('click', this.handleClick, true);
        } else {
            this.uiViewContainer.setDisplay('none');
            this.uiCont.removeClass('ws2DMenuContainer--active');
            window.removeEventListener('click', this.handleClick, true);
        }
    }

    onReady = () => {
        const types = this.viewOptions.getTypes();
        this.viewOptions.resetAll();
        this.editor.trigger('2dDisableViewGraph');
        this.editor.scene.children.forEach(object => {
            if(object?.userData?.viewType !== undefined) {
                let idx = types.indexOf(object?.userData?.viewType);
                if(idx !== -1) {
                    const optn = this.viewOptions.getOption(idx)
                    if(optn.enable === false) {
                        this.viewOptions.setEnable(idx);
                        this.editor.trigger('2dViewGraphChanged', [idx]);
                    }
                }
            }
        });
    }
}

export { MenuView };