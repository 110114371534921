import * as THREE from 'three';
import EditorExperience from '../EditorExperience';

import EventEmitter from '../utils/EventEmitter';
import NavigationHelperLine from './NavigationHelper.Line';
import { getRandomIdCode } from './TransformConversions';

class NavigationHelper extends EventEmitter {
    constructor() {
        super();

        this.editor = new EditorExperience();
        this.canvas = this.editor.canvas;
        this.scene = this.editor.scene;
        this.camera = this.editor.camera;

        this.startNavigationTrack = false;

        this.intersectionPoint = new THREE.Vector3();
        // this.plane = new THREE.Plane();
        this.raycaster = new THREE.Raycaster();

        this.pathColor = '#07D91E';

        this.raycastObjects = [];
        this.waypointObjects = [];

        this.editor.on('objectRemoved', this.objectRemoved);
        this.editor.on('objectChanged', this.onObjectChanged);
        this.editor.on('toggleMenuActive', this.onToggleMenuActive);

        this.s = 0.4

        this.setUpObjects();
        // this.setUpControls();
    }
    

    setUpObjects = () => {

        const cylinGeo = new THREE.CylinderGeometry(0.15, 0.15, 0.05, 64, 64);
        const cylinMat = new THREE.MeshStandardMaterial({
            color: this.pathColor,
            side: THREE.DoubleSide,
        });
        this.cylinMesh = new THREE.Mesh(cylinGeo, cylinMat);
    }

    setUpControls = () => {

        this.scene.children.forEach((child) => {
            if(child.userData && child.userData.navHelper &&  child.userData.navHelper === 'floorplan') {
                this.raycastObjects.push(child)
            }
        })

        if(this.raycastObjects.length > 0) {
            this.editor.onToggleInfoModals('Tour', true);
            this.startNavigationTrack = true;
            this.editor.trigger('navigationTracking', [true]);
            this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [false]);

            this.sessionWaypoints = [];
            this.sessionGroup = this.cloneNavGroup();
            this.sessionLine = new NavigationHelperLine();
    
            this.layoutNavPaths(0, new THREE.Vector3());
        } else {
            this.editor.callbacks.generateAlert({
                msg: "Add floorplan in map to create a guided tour.",
                alertType: "information"
            })
        }
    }

    layoutNavPaths = (index = -1, vec3Pos) => {

        this.currentHeader = this.cloneNavCylinder(index);
        this.currentHeader.position.copy(vec3Pos)
        index === 0 && this.editor.addObjects(this.sessionGroup);
        this.sessionGroup.add(this.currentHeader);

        this.canvas.addEventListener('mousemove', this.onMouseMove, false);
        this.canvas.addEventListener('mousedown', this.onMouseDown, false);
        this.canvas.addEventListener('dblclick', this.onDoubleClick, false);
        document.addEventListener('keydown', this.onKeyDownExit, false);
    }

    onMouseMove = (e) => {
        if(this.startNavigationTrack) {
            let viewportDown = new THREE.Vector2();
            const rect = this.canvas.getBoundingClientRect();
            viewportDown.x =   ((( e.offsetX - rect.left) / rect.width ) * 2 ) - 1;
            viewportDown.y = - ((( e.offsetY - rect.top) / rect.height ) * 2 ) + 1;

            this.raycaster.setFromCamera(viewportDown, this.camera.instance);
            const intersects = this.raycaster.intersectObjects(this.raycastObjects, true);

            if(intersects.length > 0) {
                const intersect = intersects[0];
                this.intersectionPoint.copy(intersect.point);/* .multiply(intersect.face.normal); */
                this.currentHeader.position.copy(this.intersectionPoint)
            }
        }
    }

    onMouseDown = (e) => {
        switch(e.which) {

            case 2:
                this.exitNavigationTracking();
            break;

            case 3:
                this.currentHeader.position.copy(this.intersectionPoint);
                let wayObj = {
                    waypointName: this.currentHeader.name,
                    position: this.intersectionPoint.clone(),
                    scale: this.currentHeader.scale.clone(),
                    rotation: this.currentHeader.quaternion.clone(),
                };
                this.sessionLine.addPoint(wayObj);
                this.sessionWaypoints.push(wayObj);
                if(this.sessionLine.points.length === 2) {
                    this.sessionGroup.add(this.sessionLine.line);
                    this.sessionLine = new NavigationHelperLine();
                    this.sessionLine.addPoint(this.sessionWaypoints[this.sessionWaypoints.length - 1]);
                }
                this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
                this.canvas.removeEventListener('mousemove', this.onMouseMove, false);
                //Detect Start!
                if(this.sessionWaypoints.length === 1) {
                    const start = this.editor.navPinStart.clone();
                    start.position.copy(this.intersectionPoint);
                    start.scale.set(this.s, this.s, this.s);
                    start.name = `${this.sessionGroup.name}__startPin`;
                    start.rotateY(THREE.MathUtils.degToRad(180));
                    start.userData['skipScene'] = true;
                    this.sessionGroup.add(start);
                }
                this.layoutNavPaths(this.sessionWaypoints.length, this.intersectionPoint); 
            break;

            default:
                break;
        }
        
    }

    endNavigationTracking = () => {
        if(this.sessionWaypoints.length < 1) return;
        this.sessionGroup.remove(this.currentHeader);

        // last waypoint
        const wayObj = this.sessionWaypoints.pop();
        wayObj.waypointName = wayObj.waypointName.replace('wayPoint', 'wayPointDestination')
        this.sessionWaypoints.push(wayObj)

        const end = this.editor.navPinEnd.clone();
        end.position.copy(this.sessionWaypoints[this.sessionWaypoints.length-1].position);
        end.scale.set(this.s, this.s, this.s);
        end.name = `${this.sessionGroup.name}__endPin`;
        end.userData['skipScene'] = true;
        end.rotateY(THREE.MathUtils.degToRad(180));
        this.sessionGroup.add(end);
        
        this.waypointObjects.push({name: this.sessionGroup.name, description: this.sessionGroup.userData.description, elements: this.sessionWaypoints});
        this.startNavigationTrack = false;
        this.editor.trigger('navigationTracking', [false]);
        !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);

        this.canvas.removeEventListener('mousemove', this.onMouseMove, false);  
        this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
        this.canvas.removeEventListener('dblclick', this.onDoubleClick, false);
        document.removeEventListener('keydown', this.onKeyDownExit, false);
        this.editor.select(this.sessionGroup);
        this.setUpTranslation();
    }

    onDoubleClick = (e) => {
        if(this.sessionWaypoints.length < 1) return;
        this.currentHeader.position.copy(this.intersectionPoint);
        let wayObj = {
            waypointName: this.currentHeader.name.replace('wayPoint', 'wayPointDestination'),
            position: this.intersectionPoint.clone(),
            scale: this.currentHeader.scale.clone(),
            rotation: this.currentHeader.quaternion.clone(),
        };
        this.sessionLine.addPoint(wayObj);
        if(this.sessionLine.points.length === 2) {
            this.sessionGroup.add(this.sessionLine.line);
        }
        this.sessionWaypoints.push(wayObj);
        this.waypointObjects.push({name: this.sessionGroup.name, description: this.sessionGroup.userData.description, elements: this.sessionWaypoints});
        
        const end = this.editor.navPinEnd.clone();
        end.position.copy(this.intersectionPoint);
        end.scale.set(this.s, this.s, this.s);
        end.name = `${this.sessionGroup.name}__endPin`;
        end.userData['skipScene'] = true;
        end.rotateY(THREE.MathUtils.degToRad(180));
        this.sessionGroup.add(end);
        
        this.startNavigationTrack = false;
        this.editor.trigger('navigationTracking', [false]);
        !this.editor.toAutoSave && this.editor.trigger('toggleAutoSaveSceneFlag', [true]);

        this.canvas.removeEventListener('mousemove', this.onMouseMove, false);  
        this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
        this.canvas.removeEventListener('dblclick', this.onDoubleClick, false);
        document.removeEventListener('keydown', this.onKeyDownExit, false);
        this.editor.select(this.sessionGroup);
        this.setUpTranslation();
    }

    setUpTranslation = () => {
        // DO Things related to transformations!
        this.sessionGroup.userData['tPosition'] = this.sessionGroup.position.clone();

        const boxGrp = new THREE.Box3().setFromObject(this.sessionGroup.clone());
        const origin = boxGrp.getCenter(this.sessionGroup.position.clone())
        origin.y = boxGrp.min.y + 0.09;
        
        this.sessionGroup.userData['tOrigin'] = origin.clone();
        this.sessionGroup.userData['tNewOrigin'] = origin.clone();

        /* const center = new THREE.Mesh(
            new THREE.SphereGeometry(0.05, 16, 16),
            new THREE.MeshBasicMaterial({color: 'red'})
        );
        center.position.copy(origin);
        this.sessionGroup.add(center); */
    }

    onKeyDownExit = (e) => {
        if(e.keyCode === 27) {
            this.exitNavigationTracking();
        }
    }

    exitNavigationTracking = () => {
        this.editor.removeObject(this.sessionGroup);
        this.startNavigationTrack = false;
        this.editor.trigger('navigationTracking', [false]);
        this.canvas.removeEventListener('mousemove', this.onMouseMove, false);  
        this.canvas.removeEventListener('mousedown', this.onMouseDown, false);
        this.canvas.removeEventListener('dblclick', this.onDoubleClick, false);
        document.removeEventListener('keydown', this.onKeyDownExit, false);
    }

    cloneNavCylinder = (index) => {
        let header = this.cylinMesh.clone();
        const randID = getRandomIdCode();
        header.name = index === 0 ? `wayPointStart_${randID}` : `wayPoint_${randID}`;
        header.userData['id'] = index === 0 ? `wayPointStart_${randID}` : `wayPoint_${randID}`;

        return header;
    }

    cloneNavGroup = () => {
        let group = new THREE.Group();
        group.name = `tour_${getRandomIdCode()}`
        group.userData['type'] = 'wayPointGroup';
        group.userData['navigationMode'] = 'waypoints';
        group.userData['udScale'] = 'waypoints';
        group.userData['udRotate'] = 'waypoints';
        group.userData['description'] = 'Sample Description';
        group.userData['visible'] = true;
        group.userData['pathColor'] = this.pathColor;
        group.userData['transform'] = "ONLY_TRANSLATE";
        return group;
    }

    objectRemoved = (object, delayAutosave) => {
        if(object.userData['type'] === 'wayPointGroup') {
            let idx = -1;
            for(var i = 0; i < this.waypointObjects.length; i++) {
                if(this.waypointObjects[i].name === object.name) {
                    idx = i;
                }
            }
            this.waypointObjects.splice(idx, 1);
            idx !== -1 && delayAutosave && this.editor.toAutoSave && this.editor.trigger('autoSaveSceneGraphState');
        }
    }

    onObjectChanged = (object, delayAutosave, objName, isView = false) => {
        if(object && object instanceof THREE.Group && objName && object.userData['type'] && object.userData['type'] === 'wayPointGroup') {
            let wayGroup = this.getWayPointObject(objName);
            if(wayGroup) {
                const index = this.waypointObjects.indexOf(wayGroup);
                this.waypointObjects[index].name = object.name;
                this.waypointObjects[index].description = object.userData.description;
                //Path Color Changes!
                if(this.pathColor !== object.userData.pathColor) {
                    this.pathColor = object.userData.pathColor;
                    object.children.forEach(child => {
                        if(child.type === "Mesh") {
                            child.material.color.set(new THREE.Color(this.pathColor));
                            child.material.needsUpdate = true;
                        } else if(child.type === "Line2") {
                            const color = new THREE.Color(this.pathColor)
                            child.geometry.setColors([color.toArray(), color.toArray()].flat());
                            child.material.needsUpdate = true;
                        }
                    })
                }
                //Tranlation Changes!
                if(!object.position.equals(object.userData.tPosition)) {
                    const boxGrp = new THREE.Box3().setFromObject( object.clone());
                    const newOrigin = boxGrp.getCenter(object.position.clone())
                    newOrigin.y = boxGrp.min.y + 0.09;
                    object.userData['tNewOrigin'] = newOrigin;
                }
                object.userData['tPosition'] = object.position.clone();

                //check origin translation
                if(!object.userData['tOrigin'].equals(object.userData['tNewOrigin'])) {
                    let wayArray = [...this.waypointObjects[index].elements];
                    for(var i = 0; i < wayArray.length; i++) {
                        let pointObj = {...wayArray[i]}
                        const localOrigin = object.userData['tOrigin'].clone();
                        const prevPos = pointObj.position.clone();
                        const ptDist = prevPos.distanceTo(localOrigin);
                        let newPos = new THREE.Vector3();
                        const dirVec = new THREE.Vector3();
                        dirVec.subVectors(prevPos, localOrigin).normalize();
                        newPos.addVectors(object.userData['tNewOrigin'].clone(), dirVec.multiplyScalar(ptDist))
                        pointObj.position = newPos.clone();
                        wayArray[i] = {...pointObj};
                    }
                    object.userData['tOrigin'].copy(object.userData['tNewOrigin']);
                    this.waypointObjects[index].elements = [...wayArray];
                }
                
                this.editor.toAutoSave && this.editor.trigger('autoSaveSceneGraphState');
            }
        }
    }

    getWayPointObject = (groupName) => {
        let retObj = {};
        for( const wayObj of this.waypointObjects) {
            if(wayObj.name === groupName) {
                retObj = wayObj;
                break;
            }
        }
        return retObj;
    }

    onToggleMenuActive = () => {
        if(this.startNavigationTrack) {
            this.exitNavigationTracking();
        }
    }
}

export default NavigationHelper;