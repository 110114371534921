import RenderTable from "../../Render/RenderTable";
import { ChevronRight } from "react-feather";
import { Box } from "@mui/material";
import { isEmpty, isNull, isUndefined } from "lodash";
import DatePicker from "../../../../../_utils/DatePicker";
import React, { useEffect, useState } from "react";
import { getTopFiveVisited } from "../../../../../../features/user/analytics/analyticsSlice";
import { useDispatch } from "react-redux";
import { lastTEDays } from "../../../../../_utils/DateUtils";

const topFiveVisitedHeaders = [
  { key: "rank", label: "Rank" },
  { key: "mapName", label: "Map Name" },
  { key: "mapVisits", label: "Visits" },
  { key: "mapArea", label: "Map Area Sq. ft"},
  { key: "growth", label: "Growth"},
  { key: "country", label: "Country" },
];

const TopFiveVisited = ({ topFiveVisited, setTab }) => {
  const dispatch = useDispatch();

  const [dateObj, setDateObj] = useState(lastTEDays);

  useEffect(() => {
    dispatch(getTopFiveVisited({ limit: 5, ...dateObj }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="analytics">
      <div className="analyticshome__anCard">
        <div className="row analyticshome__anCard--contentRow">
          <div className="col-9">
            <div className="analyticshome__anCard--contentRow--header">
              Top 5 Most Visited Map
            </div>
          </div>
          <div className="col-3" style={{ paddingRight: "2.5rem" }}>
            <Box component="span" className="analyticshome__anCard--filterBox">
              <span style={{ color: "#858993" }}>Date:</span>
              <DatePicker
                cb={(dateObj) => {
                  setDateObj(dateObj);
                  dispatch(getTopFiveVisited({ limit: 5, ...dateObj }));
                }}
              />
            </Box>
            {/* } */}
          </div>
        </div>
        <hr />
        
        {isEmpty(topFiveVisited) || isNull(topFiveVisited)||isUndefined(topFiveVisited) ? <h5 className="text-center analyticshome__emptyStateText pt-3"> No Data available </h5> : <RenderTable
            thheaderData={topFiveVisitedHeaders}
            rowsData={topFiveVisited}
          />}
        <div className="row analyticshome__anCard--contentRow--footer">
          <div className="col">
            <div className="analyticshome__floatright ">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setTab(1);
                }}
              >
                View Top Visited Maps
                <ChevronRight />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopFiveVisited;
