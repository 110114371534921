import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import CreateMapModal from "../studio/common/CreateMapModal";
import IntroOne from '../../../../assets/pngs/dash-landing/introcard/introOne.png';
import IntroTwo from '../../../../assets/pngs/dash-landing/introcard/introTwo.png';
import appSVG from '../../../../assets/svgs/dash-landing/introcard/appStore.svg';
import playSVG from '../../../../assets/svgs/dash-landing/introcard/playStore.svg';

import { resetMapDetails,resetStepperMapDetails } from "../../../../features/user/studio/studioSlice";


import { ArrowRight } from "react-feather";

//Action
import items from "../../../common/sidebar/menu";
import { retainNavState } from "../../../../features/common/commonSlice";

//Import Array
import { linksCardData } from "./linksHelper";

/* Routes */
import {
  // D_SUPPORT,
  D_STUDIO,
} from "../../../_utils/routes";
import { getUserPlanDetails } from "../../../../features/user/settings/settingsSlice";

const Maps = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [modalState, setModalState] = useState({
    openCreateMapModal: false,
    ownUpdate: false,
  });

  useEffect(() => {
    dispatch(getUserPlanDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loggedUserType =
    useSelector((state) => state.auth.loggedUserType) || "user";

  const handleNavigation = (link) => {
    const findIndxArr = items[loggedUserType];
    let activeIndx = 0;
    findIndxArr.forEach((ele, index) => {
      if (link === ele.link) activeIndx = index;
    });
    navigate(link);
    dispatch(retainNavState(activeIndx));
  };

  const renderLinkCards = () => {
    return linksCardData.map((cardData) => (
      <div className="col-lg-6 col-xl-4 col-xxl-3 pt-3 pe-0" style={{height:'330px'}} key={cardData.id}>
      <div className="dashboardpage__dpCard" key={cardData.id}>
        <div className="dashboardpage__dpCard--title">{cardData.title}</div>
        <div className="dashboardpage__dpCard--iconCont">
          <img
            src={cardData.icon}
            alt={cardData.title}
            draggable="false"
            className="dashboardpage__dpCard--icon"
          />
        </div>
        <span className="dashboardpage__dpCard--content">
          {cardData.content}
        </span>
        <div
          className="dashboardpage__dpCard--link"
          onClick={() =>
            cardData.link !== "NONE" && handleNavigation(cardData.link)
          }
          id={`link-${cardData.linkText.replace(/\s+/g, '').toLowerCase()}`}
        >
          {cardData.linkText}{" "}
          {cardData.link !== "NONE" && (
            <ArrowRight
              size="18"
              color="#2A4DC2"
              className="dashboardpage__dpCard--linkArrow"
            />
          )}
        </div>
      </div>
      </div>
    ));
  };

  return (
    <div className="dashboardpage">
      <CreateMapModal
        open={modalState.openCreateMapModal}
        handleClose={() =>{
          setModalState((prevState) => ({
            openCreateMapModal: !prevState.openCreateMapModal,
            ownUpdate: true,
          }))
          dispatch(resetStepperMapDetails())
        }
        }
        onSuccess={() => {
          resetMapDetails()
          handleNavigation(D_STUDIO)
        }}
      />
      <Box padding="40px 60px">
        <div className="dashboardpage__headerRow">
          <span className="dashboardpage__headerText">
            Welcome to the ARway Portal
          </span>
        </div>
        <div className="row">
          <div className="col-lg-10 col-xl-6 gy-3">
            <div className="card text-center shadow bg-body rounded" style={{width: "100%", height:'100%'}}>
            <div className="card-header dashboardpage__dpIntroCard--introBackImage"></div>
              <img src={IntroOne} className="card-img-top stepper-card-img-top introOne" alt="..."/>
              <div className="stepper-card-body">
                <h5 className="card-title dashboardpage__dpIntroCard--header" >Download the ARway App</h5>
                <p className="card-text dashboardpage__dpIntroCard--content">
                Create and experience AR activations with the ARway app.
                </p>
                <Grid container={true} spacing={4} className='pb-4'>
                <Grid item xs={6}>
                  <img src={playSVG} alt="playstore" style={{cursor:'pointer'}} onClick={()=>{
                    window.open('https://play.google.com/store/apps/details?id=com.nextechar.armaps')
                  }}/>
                </Grid>
                <Grid item xs={6}>
                  <img src={appSVG} alt="appstore" style={{cursor:'pointer'}} onClick={()=>{
                    window.open('https://apps.apple.com/us/app/arway-app/id1599443392')
                  }}/>
                </Grid>
                </Grid>
              </div>
            </div>
          </div>
          <div className="col-lg-10 col-xl-6 gy-3">
            <div className="card text-center shadow bg-body rounded" style={{width: "100%", height:'100%'}}>
            <div className="card-header dashboardpage__dpIntroCard--introBackImage"></div>
              <img src={IntroTwo} className="card-img-top stepper-card-img-top introTwo" alt="..."/>
              <div className="stepper-card-body">
                <h5 className="card-title dashboardpage__dpIntroCard--header">Setup a New Map</h5>
                <p className="card-text dashboardpage__dpIntroCard--content pb-2">
                Start creating and setup a new map!
                </p>
                <button
                id="button-setup-map"
                className="dashboardpage__dpContentSpan--button"
                onClick={() =>
                  setModalState((prevState) => ({
                    openCreateMapModal: !prevState.openCreateMapModal,
                    ownUpdate: true,
                  }))
                }
              >
                Set Up Map
              </button>
              </div>
            </div>
          </div>
        </div>
{/* 
        <div className="dashboardpage__dpHeaderCard">
          <span className="dashboardpage__dpImage"></span>
          <div className="dashboardpage__dpContentSpan">
            <span className="dashboardpage__dpContentSpan--header">
              Let’s build your spatial map!
            </span>
            <span className="dashboardpage__dpContentSpan--subHeader">
              Open the Studio and start adding content to your mapped location
              and engage your viewers!
            </span>
            <button
              className="dashboardpage__dpContentSpan--button"
              onClick={() => handleNavigation(D_STUDIO)}
            >
              Open Studio
            </button>
          </div>
        </div> */}
        {/* <div className="dashboardpage__dpLinkCardContainer"> */}
          <div className="row gx-5 me-0 mt-3">
          {renderLinkCards()}
          </div>
        {/* </div> */}
        {/* <div className='dashboardpage__dpCommunityCard'>
                    <div className='dashboardpage__dpCommunityInfo'>
                        <span className='dashboardpage__dpCommunityInfo--header'>Community</span>
                        <span className='dashboardpage__dpCommunityInfo--subHeader'>Visit our creator community portal to get started, inspired and supported throughout the process of mapping your physical locations and enhancing them. Join us today and ARitize your World!</span>
                        <button className='dashboardpage__dpCommunityInfo--button'>Visit Community</button>
                    </div>
                    <div className='dashboardpage__dpCommunityVideoInfo'>

                    </div>
                    <div className='dashboardpage__dpCommunityVideoInfo'>
                        <iframe className='dashboardpage__dpCommunityVideoInfo--videoIFrame' src="https://www.youtube.com/embed/HW3R_c2Jgqo?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <span className='dashboardpage__dpCommunityVideoInfo--header'>Case Study</span>
                        <span className='dashboardpage__dpCommunityVideoInfo--content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id accumsan justo.</span>
                    </div>
                    <div className='dashboardpage__dpCommunityVideoInfo'>
                        <iframe className='dashboardpage__dpCommunityVideoInfo--videoIFrame' src="https://www.youtube.com/embed/HW3R_c2Jgqo?controls=0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                        <span className='dashboardpage__dpCommunityVideoInfo--header'>Case Study</span>
                        <span className='dashboardpage__dpCommunityVideoInfo--content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque id accumsan justo.</span>
                    </div>
                </div> */}

        <div className="dashboardpage__dpFooterCard">
          <div className="dashboardpage__dpFooterCard--backImage" />
          <div className="dashboardpage__dpFooterInfo">
            <div className="dashboardpage__dpFooterInfo--header">
              Need help & support?
            </div>
            <span className="dashboardpage__dpFooterInfo--subHeader">
              We are here to help. Contact us for additional questions or help.
            </span>
          </div>
          <div className="dashboardpage__dpFooterBtns">
            <a
              href="https://www.arway.ai/contact-9"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button>Contact Us</button>
            </a>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Maps;
