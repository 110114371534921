import React, { useState, useRef, useEffect } from "react";
import { debounce, isEqual, union } from "lodash";
import { useDispatch, useSelector } from "react-redux";
//MUI COmponents
import { Box, Tabs, Tab } from "@mui/material";
// Components
import Loader from "../../../common/loader";
import Dropdown from "../../../webstudio/studiocommon/ImportMaps/VenuesDetails/Dropdown";
import MapCard from "./MapCard";
import CreateMapModal from "./common/CreateMapModal";
//Icons
import NoDataIllus from "../../../../assets/svgs/nodata.svg";
import { ReactComponent as CreateMapIcon } from "../../../../assets/svgs/dash-landing/mapdetails/createMapIcon.svg";
import PlayStore from "../../../../assets/icons/playstore.png";
import AppStore from "../../../../assets/icons/appstore.png";
import { Search, X } from "react-feather";
//Actions
import {
  getUserMapList,
  resetMapDetails,
  resetStepperMapDetails,
  searchUserMaps,
  clearSearchedUserMaps,
  resetMapsState,
  setRecentData,
} from "../../../../features/user/studio/studioSlice";
import {
  getUserPlanDetails
} from '../../../../features/user/settings/settingsSlice';
//Utils
import { getMergeSortedByDate } from "../../../_utils/DateUtils";

const StudioHome = () => {
  const dispatch = useDispatch();

  const maps = useSelector((state) => state.studio.userMaps);
  const searchedMaps = useSelector((state) => state.studio.searchedUserMaps);
  const recentData = useSelector((state) => state.studio.recentData);

  const [userMaps, setUserMaps] = useState(null);
  const [searchedUserMaps, setSearchedUserMaps] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchPageNumber, setSearchPageNumber] = useState(1);
  const [openCreateMapModal, setOpenCreateMapModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [onFocusMapname, setOnFocusMapname] = useState(false);

  const pageSize = 10;
  const filtersList = ["", "Published", "Draft"];
  const mapsTypeList = [
    { value: "", label: "All Map Types" },
    { value: "map", label: "Single Floor Maps" },
    { value: "building", label: "Venue Maps" },
  ];
  const loadingRef = useRef(null);
  const prevMaps = useRef(null);
  const prevSearchedMaps = useRef([]);
  const pageRef = useRef(pageNumber);
  const searchPageRef = useRef(searchPageNumber);
  const searchTextRef = useRef('');
  const toResetData = useRef(false);

  const mapsType = useRef("");
  const activeKey = useRef(0);
  const fetching = useRef(true);
  const loading = useRef(false);

  useEffect(() => {
    dispatch(resetMapDetails());
    dispatch(getUserPlanDetails());
    fetchUserMaps(pageNumber);
    // Intersection Observer!
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleObserver, options);
    loadingRef.current && observer.observe(loadingRef.current);

    return () => {
      dispatch(resetMapsState());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (searchedMaps && !isEqual(searchedMaps, searchedUserMaps)) {
      setSearchedUserMaps((maps) => union(maps, searchedMaps));
      prevSearchedMaps.current = searchedMaps;
      loading.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedMaps]);

  useEffect(() => {
    if (maps && !isEqual(maps, prevMaps.current) && recentData) {
      setUserMaps((prevMaps) =>
        getMergeSortedByDate(prevMaps, maps, "mapId", "modifiedOn")
      );
      dispatch(setRecentData(false));
      prevMaps.current = [...maps];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maps]);

  useEffect(() => {
    pageRef.current = pageNumber;
    searchPageRef.current = searchPageNumber;
    if (pageNumber === 1 && prevMaps.current?.length <= 0 && toResetData.current) {
      setUserMaps(null);
      toResetData.current = false;
      fetching.current = false;
      loading.current = false;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, prevMaps, searchPageNumber]);

  useEffect(() => {
    if(userMaps && fetching.current) {
      fetching.current = false;
    }
  }, [userMaps])

  /* useEffect(() => {
    if(searchedUserMaps && searchText.length && loading.current) {
      loading.current = false;
    }
  }, [searchedUserMaps]) */

  useEffect(() => {
    fetchUserMaps(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeKey.current, mapsType.current]);

  useEffect(() => {
    if (searchText.trim().length >= 3) {
      searchUserMapsNow(1);
    } else if (searchText.length <= 0) {
      handleClearSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  const handleObserver = (entities) => {
    if (entities[0].isIntersecting) {
      if (searchTextRef.current.length && prevSearchedMaps.current && prevSearchedMaps.current.length >= pageSize) {
        var curSPage = searchPageRef.current + 1;
        searchUserMapsNow(curSPage);
      } else if (prevMaps.current && prevMaps.current.length >= pageSize) {
        var curPage = pageRef.current + 1;
        fetchUserMaps(curPage);
      }
    }
  };

  const fetchUserMaps = (pageNumber, key) => {
    // fetch data
    fetching.current = true;
    setPageNumber(pageNumber);
    var reqObj = {
      pageSize,
      pageNumber,
      mapsType: mapsType.current,
      mapFilter: filtersList[activeKey.current],
    };
    dispatch(getUserMapList(reqObj));
  };

  const handleChange = (e, key) => {
    if (searchText.length) {
      dispatch(clearSearchedUserMaps());
    }
    toResetData.current = true;
    setPageNumber(1);
    activeKey.current = key;
    fetching.current = true;
    setUserMaps(null);
    setSearchedUserMaps(null);
    setSearchText("");
    searchTextRef.current = "";
    prevMaps.current = null;
    prevSearchedMaps.current = null;
  };

  const handleSearchChange = (e) => {
    if (e.target.value.includes(" ") && e.target.value.length === 1) {
      e.target.value = e.target.value.replace(/\s/g, "");
    }
    const sText = e.target.value;
    if (sText.length < 3 && sText.length !== 0) {
      setOnFocusMapname(true);
    } else {
      setOnFocusMapname(false);
    }
    setSearchText(sText);
    searchTextRef.current = sText;
  };

  const getSearchedMaps = debounce(
    (data) => dispatch(searchUserMaps(data)),
    250
  );

  const searchUserMapsNow = (pageNumber) => {
    if (pageNumber === 1) {
      loading.current = true;
      setSearchedUserMaps(null);
    }
    setSearchPageNumber(pageNumber);
    getSearchedMaps({
      mapFilter: filtersList[activeKey.current],
      searchText: searchTextRef.current,
      pageNumber,
      mapsType: mapsType.current,
    });
  };

  const handleMapType = (value) => { 
    mapsType.current = value;
    toResetData.current = true;
    setPageNumber(1);
    fetching.current = true;
    setUserMaps(null);
    setSearchedUserMaps(null);
    setSearchText("");
    searchTextRef.current = "";
    prevMaps.current = null;
    prevSearchedMaps.current = null;
  };

  const handleClearSearch = () => {
    setSearchText("");
    searchTextRef.current = "";
    setSearchedUserMaps(null);
    setSearchPageNumber(1);
    dispatch(clearSearchedUserMaps());
    prevSearchedMaps.current = null;
  };

  const a11yProps = (index) => {
    return {
      id: `mymaps-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };

  const renderUserMaps = () => {
    if ((fetching.current && !userMaps) || loading.current) {
      return (
        <Loader loaderText="Fetching Maps..." height="100%" width="100%" />
      );
    }
    else if (userMaps?.length === 0) {
      return (
        <div className="illustration-container">
          <img src={NoDataIllus} alt="no maps!" draggable={false} />
          <h5>Looks like you don't have any maps yet.</h5>
          <span>Create a new map with our ARway App</span>
          <div className="illustration-container--iconDiv">
            <a
              href="https://play.google.com/store/apps/details?id=com.nextechar.armaps"
              target="_blank"
              rel="noopener noreferrer"
              id="link-toPlayStore"
            >
              <img src={PlayStore} alt="PlayStoreIcon" draggable={false} />
            </a>
            <a
              href="https://apps.apple.com/us/app/aritize-maps/id1599443392"
              target="_blank"
              rel="noopener noreferrer"
              id="link-toAppStore"
            >
              <img src={AppStore} alt="AppStoreIcon" draggable={false} />
            </a>
          </div>
        </div>
      );
    } else if (
      searchText.length >= 3 &&
      searchedUserMaps &&
      searchedUserMaps.length > 0
    ) {
      return searchedUserMaps.map((map, index) => (
        <div key={index} className="studiohome__mapCardContainer">
          <MapCard map={map} />
        </div>
      ));
    } else if (
      searchText.length >= 3 &&
      (!searchedUserMaps || searchedUserMaps.length <= 0)
    ) {
      return (
        <div className="illustration-container">
          <img src={NoDataIllus} alt="no maps!" draggable={false} />
          <br />
          <h5 id="h5-noMatch">No matches found.</h5>
        </div>
      );
    } else {
      return userMaps.map((map, index) => (
        <div key={index} className="studiohome__mapCardContainer">
          <MapCard map={map} />
        </div>
      ));
    }
  };

  const loadingCSS = {
    height: "20px",
    margin: "10px",
  };
  const loadingCSSText = {
    display: loading ? "block" : "none",
    color: "#7c7c7c",
    fontSize: "1rem",
    letterSpacing: "1px",
  };

  var crossClass = "searchbar__closeIcon--hide";
  if (searchText.length > 0) {
    crossClass = "";
  }

  return (
    <div className="studiohome">
      <Box padding="40px 60px">
        <div className="studiohome__headerRow">
          <span className="studiohome__headerText">My Maps</span>
          <button
            className="studiohome__headerButton"
            onClick={() => setOpenCreateMapModal(true)}
          >
            <span>
              <CreateMapIcon /> &nbsp; Create New Map{" "}
            </span>
          </button>
        </div>
        <CreateMapModal
          open={openCreateMapModal}
          handleClose={() => {
            setOpenCreateMapModal(false);
            dispatch(resetStepperMapDetails());
            dispatch(resetMapDetails());
            fetchUserMaps(pageNumber);
          }}
          onSuccess={() => {
            fetchUserMaps(pageNumber);
          }}
        />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={activeKey.current}
              onChange={handleChange}
              aria-label="Map Tabs"
            >
              <Tab
                label="All"
                {...a11yProps(0)}
                disableRipple
                className={`studiohome__shTabHeader ${
                  activeKey.current === 0 ? "studiohome__shTabHeader--active" : ""
                }`}
              />
              <Tab
                label="Published"
                {...a11yProps(1)}
                disableRipple
                className={`studiohome__shTabHeader ${
                  activeKey.current === 1 ? "studiohome__shTabHeader--active" : ""
                }`}
              />
              <Tab
                label="Drafts"
                {...a11yProps(2)}
                disableRipple
                className={`studiohome__shTabHeader ${
                  activeKey.current === 2 ? "studiohome__shTabHeader--active" : ""
                }`}
              />
            </Tabs>
          </Box>
          <Box className="studiohome__mainRow">
            <div className="d-flex justify-content-end">
              <div className="searchbar">
                <span
                  className="searchbar__searchbarContainer"
                  style={{ width: "45%" }}
                >
                  <Search
                    size={20}
                    color="#353E5A"
                    className="searchbar__searchIcon"
                  />
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder="Search with Map Name"
                    value={searchText}
                    onChange={handleSearchChange}
                    id="input-mapsSearch"
                  />
                  <X
                    size={20}
                    color="#353E5A"
                    onClick={handleClearSearch}
                    id="icon-clearSearch"
                    className={`searchbar__closeIcon ${crossClass}`}
                  />
                </span>
              </div>
              <Dropdown
                list={mapsTypeList}
                setvalue={handleMapType}
                // disabled={userMaps?.length < 1}
              />
            </div>
            {searchText.length < 3 && onFocusMapname ? (
              <span className="p-1" style={{ color: "red" }}>
                Minimum three character required!
              </span>
            ) : null}
            <div>{renderUserMaps()}</div>
            <div
              ref={loadingRef}
              style={{
                ...loadingCSS,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <span style={loadingCSSText}>{(
                (searchText.length && prevSearchedMaps.current?.length >= 10) || 
                (!searchText.length && prevMaps.current?.length >= 10))? 
                "Fetching More Maps...": "" }
              </span>
            </div>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default StudioHome;
